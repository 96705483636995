/** @jsx jsx */ /** @jsxRuntime classic */
import CheckoutNote from '@/EZC/pages/cart/components/CheckoutNote';
import { jsx, css } from '@emotion/core'; // eslint-disable-line
import ChoseType from '@/EZC/pages/cart/components/ChoseType.component';
import InvoiceDetails from '@/EZC/pages/cart/components/InvoiceDetails.component';
import { ProductItem } from '@/EZC/pages/cart/components/OrderItem.component';
import useCheckoutLogic from '@/EZC/pages/cart/hooks/useCheckoutLogic';
import OrderPayment from '@/EZC/pages/orders/components/OrderPayment';
import GetDeliveryInfo from '@/EZC/views/GetDeliveryInfo/GetDeliveryInfo.view';
import RestContainer, {
    sContainer as sRestContainerExported,
} from '@/EZC/views/ProductPage/components/RestContainer';
import { Bullet, Collapsable } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import PageLoader from '@components/PageLoader/PageLoader';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import {
    CONSUMPTION_TYPE,
    CONSUMPTION_TYPE_ENUM,
} from '@constants/fromGraphql/ConsumptionType.constants';
import { useSingleCartOrderQuery } from '@graphql/generated';
import { showZlotyWithPrecision } from '@helpers/MoneyEz/utils/converters';
import { priceFormat } from '@helpers/priceFormat';
import { Grid, Hidden } from '@material-ui/core';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import PersonIcon from '@material-ui/icons/Person';
import Skeleton from '@material-ui/lab/Skeleton';
import { alpha, colors, globals, s } from '@style';
import { sGrid, sNoMarginTop, sOuter } from '@style/style';
import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { Button } from '@/componentsV2/molecules/Button/Button';
import styled from '@emotion/styled';
import { GroupIcon } from '@style/assets/GroupIcon';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { ButtonSecondary } from '@/componentsV2/atoms/ButtonSecondary/ButtonSecondary';
import { mediaQueries, mediaQueriesMaterial } from '@style/breakpoints';
import { GroupSelector } from '@/EZC/pages/cart/components/molecules/GroupSelector/GroupSelector';
import { SelectTable } from '@/componentsV2/molecules/SelectTable/SelectTable';
import { SelectDate } from '@/componentsV2/molecules/SelectDate/SelectDate';
import { CartServingTime } from '@/componentsV2/molecules/CartServingTime/CartServingTime';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { PhoneNumber } from '@/EZC/domains/Cart/molecules/PhoneNumber/PhoneNumber';
import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { IOS } from '@constants/common.constants';
import { CartAddresses } from '@/EZC/domains/Cart/molecules/CartAddresses/CartAddresses';

export default () => {
    const orderId = useParams().id;
    const history = useHistory();
    const [tableValue, setTableValue] = useState('');

    const {
        addresses,
        deliveryError,
        groupData,
        totalGroupsLength,
        fetchMoreGroups,
        initialGroupsLength,
        handleDownloadPdf,
        isLoading,
        loading,
        onChange,
        onChangeAddress,
        onChangeGroup,
        onChangeOrderType,
        onChangeTable,
        onChangeServeOnTime,
        onInvoiceDetailsChange,
        onInvoiceDetailsSave,
        onIsInvoiceChange,
        onSaveNote,
        onSendOrder,
        orderWeWorkOn,
        parentIsOpen,
        parentSetIsOpen,
        setDeliveryCost,
        setDeliveryError,
        onChangeNumber,
        setPhoneError,
    } = useCheckoutLogic({ orderId });

    useEffect(() => {
        setTableValue(orderWeWorkOn?.table);
    }, [orderWeWorkOn]);

    useEffect(() => {
        AnalyticsHelpers.createCustomEvent({
            name: 'begin_checkout',
            params: { type: 'checkout' },
        });
    }, []);

    useDebounce(
        () => {
            onChangeTable(tableValue);
        },
        300,
        [tableValue],
    );

    if (!orderWeWorkOn) return <PageLoader />;

    if (loading) return <PageLoader />;

    const {
        consumptionDetails,
        deliveryAddress,
        deliveryAddressId,
        deliveryAddressName,
        deliveryLocation,
        deliveryDetails,
        invoiceDetails,
        isInvoice,
        note,
        orderDetails,
        serveOnTime,
        deliveryCost,
        packagingCost,
        priceSum,
        phoneNumber,
    } = orderWeWorkOn;

    const packageCost =
        consumptionDetails.type === 'IN'
            ? 0
            : orderWeWorkOn?.orderDetails?.reduce(
                  (sum, curr) =>
                      curr.product.packagingPrice * curr.quantity + sum,
                  0,
              );

    const productsCost = showZlotyWithPrecision(
        orderWeWorkOn?.orderDetails?.reduce((sum, curr) => curr.price + sum, 0),
    );

    const totalProductCost = showZlotyWithPrecision(
        packageCost +
            orderWeWorkOn?.orderDetails?.reduce(
                (sum, curr) => curr.price + sum,
                0,
            ),
    );

    const isDelivery =
        consumptionDetails?.type === CONSUMPTION_TYPE_ENUM.DELIVERY;
    const onSite = consumptionDetails?.type === CONSUMPTION_TYPE_ENUM.IN;
    const toTakeAway =
        consumptionDetails?.type === CONSUMPTION_TYPE_ENUM.TAKEAWAY;
    const groupOrder = orderWeWorkOn?.orderType === 'group';
    const isRegularOrder = !toTakeAway && !groupOrder;
    const isTakeAwayGroupOrder = toTakeAway && groupOrder;
    const isTakeAwayWithoutGroupOrder = toTakeAway && !groupOrder;
    const isRegularOrderWithoutGroupOrder = !toTakeAway && groupOrder;

    return (
        // <Layout noNavbar>
        <React.Fragment>
            <TopNav
                title={orderWeWorkOn?.restaurant?.name}
                subtitle={`- Podsumowanie`}
                photo={orderWeWorkOn?.restaurant?.avatarUrl}
                textCss={{
                    [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
                }}
            />
            <div css={sOuter}>
                <Headline>Podsumowanie</Headline>
            </div>
            <Hidden mdUp>
                {orderWeWorkOn?.restaurant ? (
                    <GetRestaurantImage
                        restId={orderWeWorkOn?.restaurant.id}
                        data={orderWeWorkOn?.restaurant}
                    />
                ) : (
                    <Skeleton css={sRestContainerExported} />
                )}
            </Hidden>
            <ItemsWrapper css={[sOuter, sNoMarginTop]}>
                {orderWeWorkOn && (
                    <div className="grid-wrapper">
                        <Grid container css={[sGrid]}>
                            {/* TERMIN */}
                            <Grid item xs={12} md={6}>
                                <SelectDate
                                    additionalDescription={
                                        toTakeAway
                                            ? 'Po akceptacji zamówienia przez Restaurację wyświetlimy zegar odliczający czas do odbioru.'
                                            : ''
                                    }
                                    onChange={onChange}
                                    consumptionDetails={consumptionDetails}
                                />
                            </Grid>

                            {/* SPOSÓB DOSTAWY */}
                            <Grid item xs={12} md={6}>
                                <Collapsable
                                    left
                                    noPadding
                                    oneLiner
                                    title={
                                        CONSUMPTION_TYPE[
                                            consumptionDetails?.type
                                        ]?.name
                                    }
                                    icon={
                                        CONSUMPTION_TYPE[
                                            consumptionDetails?.type
                                        ]?.icon
                                    }
                                    description={
                                        <div>Wybierz Sposób Zamówienia</div>
                                    }
                                    extraCss={{
                                        backgroundColor: colors.gray100,
                                    }}
                                >
                                    <ChoseType
                                        consumptionType={
                                            consumptionDetails?.type
                                        }
                                        onChange={onChange}
                                        availableTypes={
                                            orderWeWorkOn?.restaurant
                                                ?.consumptionTypes || []
                                        }
                                    />
                                </Collapsable>
                            </Grid>

                            {onSite && (
                                <Grid item xs={12} md={6}>
                                    <SelectTable
                                        changeTableNumberDescription={
                                            'Kliknij , aby zobaczyć układ sali restauracji i zdecydować, gdzie chcesz usiąść.'
                                        }
                                        displayRoomLayoutDescription={
                                            'Wpisz numer stolika, jeżeli numer stolika nie zostanie wybrany, Twój kelner wybierze go dla ciebie.'
                                        }
                                        onChangeTable={(e) => {
                                            setTableValue(e.target.value);
                                        }}
                                        onDisplayRoomLayout={handleDownloadPdf}
                                        tableNumber={tableValue}
                                    />
                                </Grid>
                            )}

                            {/* DOSTAWA */}
                            {consumptionDetails?.type ===
                                CONSUMPTION_TYPE_ENUM.DELIVERY && (
                                <Grid item xs={12} md={6}>
                                    <CartAddresses
                                        setAddressError={(value) =>
                                            setDeliveryError({
                                                ...deliveryError,
                                                address: value,
                                            })
                                        }
                                        onChangeAddress={onChangeAddress}
                                        addresses={addresses}
                                        deliveryAddress={{
                                            name: deliveryAddressName,
                                            address: deliveryAddress,
                                            coordinates: [
                                                deliveryLocation
                                                    ?.coordinates[0],
                                                deliveryLocation
                                                    ?.coordinates[1],
                                            ],
                                            additionalInfo: deliveryDetails,
                                            isDefault: false,
                                            id: deliveryAddressId,
                                        }}
                                    >
                                        {deliveryAddress && (
                                            <DeliveryInfoWrapper>
                                                <GetDeliveryInfo
                                                    restId={
                                                        orderWeWorkOn.restaurant
                                                            .id
                                                    }
                                                    customCoordinates={
                                                        deliveryLocation?.coordinates
                                                    }
                                                    orderAmount={parseFloat(
                                                        totalProductCost,
                                                    )}
                                                    setDeliveryCost={
                                                        setDeliveryCost
                                                    }
                                                    setDeliveryError={
                                                        setDeliveryError
                                                    }
                                                />
                                            </DeliveryInfoWrapper>
                                        )}
                                    </CartAddresses>
                                </Grid>
                            )}
                            {/* NUMER TELEFONU */}
                            <Grid item xs={12} md={6}>
                                <PhoneNumber
                                    onChangeNumber={onChangeNumber}
                                    phoneNumber={phoneNumber}
                                    required={
                                        orderWeWorkOn?.consumptionDetails
                                            ?.type !== 'IN'
                                    }
                                    setPhoneError={setPhoneError}
                                />
                            </Grid>
                            {/* TYP ZAMóWIENIA */}
                            <Grid item xs={12} md={6}>
                                <Collapsable
                                    right={toTakeAway}
                                    left={!toTakeAway}
                                    noPadding
                                    oneLiner
                                    title={
                                        groupOrder
                                            ? 'Zamówienie grupowe'
                                            : 'Zamówienie indywidualne'
                                    }
                                    icon={<PersonIcon />}
                                    description={
                                        <div>Wybierz Typ Zamówienia</div>
                                    }
                                    extraCss={{
                                        backgroundColor: colors.gray100,
                                    }}
                                >
                                    <OrderTypeWrapper>
                                        <ButtonSecondary
                                            icon={
                                                <PersonIcon fontSize="inherit" />
                                            }
                                            variant={
                                                groupOrder ? 'default' : 'green'
                                            }
                                            title="Zamówienie indywidualne"
                                            onClick={() =>
                                                onChangeOrderType('individual')
                                            }
                                        />
                                        <ButtonSecondary
                                            icon={
                                                <GroupIcon
                                                    color={
                                                        groupOrder
                                                            ? colors.green
                                                            : colors.grayDark
                                                    }
                                                    size="18px"
                                                />
                                            }
                                            variant={
                                                groupOrder ? 'green' : 'default'
                                            }
                                            title="Zamówienie grupowe"
                                            onClick={() =>
                                                onChangeOrderType('group')
                                            }
                                        />
                                    </OrderTypeWrapper>
                                </Collapsable>
                            </Grid>
                            {/* GRUPA */}
                            {groupOrder && (
                                <Grid item xs={12} md={6}>
                                    <GroupSelector
                                        groupName={
                                            orderWeWorkOn?.group?.name ??
                                            'Wybierz Grupę'
                                        }
                                        groupData={groupData}
                                        groupId={orderWeWorkOn?.group?.id}
                                        onGroupClick={(id) => {
                                            const initialSelectedGroup =
                                                groupData.filter(
                                                    (group) => group.id === id,
                                                );
                                            onChangeGroup(
                                                ...initialSelectedGroup,
                                            );
                                        }}
                                        cartId={orderId}
                                        showMore={fetchMoreGroups}
                                        totalGroups={totalGroupsLength}
                                        initialLength={initialGroupsLength}
                                    />
                                </Grid>
                            )}
                            {/* PRODUKTY */}
                            <Grid item xs={12} md={6}>
                                <Collapsable
                                    right={
                                        isTakeAwayWithoutGroupOrder ||
                                        isRegularOrderWithoutGroupOrder
                                    }
                                    left={
                                        isRegularOrder || isTakeAwayGroupOrder
                                    }
                                    noPadding
                                    oneLiner
                                    title={'Produkty'}
                                    icon={<FastfoodIcon />}
                                    description={
                                        <div
                                            css={{
                                                overflowX: 'scroll',
                                                fontWeight: 400,
                                            }}
                                        >
                                            <span css={{ color: colors.text }}>
                                                {priceFormat.standardPrice(
                                                    totalProductCost,
                                                )}
                                            </span>{' '}
                                            zł
                                            <Bullet />
                                            <>
                                                {orderDetails.reduce(
                                                    (sum, curr) =>
                                                        curr.quantity + sum,
                                                    0,
                                                )}
                                            </>
                                            <FastfoodIcon
                                                fontSize="inherit"
                                                css={{
                                                    marginLeft: '0.25rem',
                                                    [s.md]: {
                                                        marginLeft: '0.5rem',
                                                    },
                                                }}
                                            />
                                        </div>
                                    }
                                >
                                    <>
                                        {orderDetails?.map(
                                            (detail, productId) => (
                                                <ProductItem
                                                    id={detail.product.id}
                                                    key={productId}
                                                    amount={detail.quantity}
                                                    name={detail.product.name}
                                                    price={detail.product.price}
                                                    photoUrl={
                                                        detail.product.photoUrl
                                                    }
                                                    packagingPrice={
                                                        consumptionDetails?.type !==
                                                        'IN'
                                                            ? detail.product
                                                                  .packagingPrice *
                                                              detail.quantity
                                                            : null
                                                    }
                                                />
                                            ),
                                        )}
                                        <div
                                            css={{
                                                [s.sm_down]: {
                                                    padding: '1.5rem 1rem',
                                                },
                                                [s.md]: { padding: '2rem' },
                                            }}
                                        >
                                            <Button
                                                width="100%"
                                                variant="gray"
                                                onClick={() =>
                                                    history.push(
                                                        `/restaurant/${orderWeWorkOn.restaurant.slug}?menu=true`,
                                                    )
                                                }
                                            >
                                                Edytuj produkty
                                            </Button>
                                        </div>
                                    </>
                                </Collapsable>
                            </Grid>
                            {/* CZAS PODANIA */}
                            {onSite && (
                                <CartServingTime
                                    serveOnTime={serveOnTime}
                                    onChangeServeOnTime={onChangeServeOnTime}
                                />
                            )}
                            {/* NOTATKA */}
                            <CheckoutNote
                                note={note}
                                onSaveNote={onSaveNote}
                                right={isTakeAwayGroupOrder || isRegularOrder}
                                left={
                                    isTakeAwayWithoutGroupOrder ||
                                    isRegularOrderWithoutGroupOrder
                                }
                            />
                            {/* FAKTURA */}
                            <Grid item xs={12} md={6}>
                                <Collapsable
                                    right={
                                        isTakeAwayWithoutGroupOrder ||
                                        isRegularOrderWithoutGroupOrder
                                    }
                                    left={
                                        isTakeAwayGroupOrder || isRegularOrder
                                    }
                                    noPadding
                                    oneLiner
                                    parentIsOpen={parentIsOpen}
                                    parentSetIsOpen={parentSetIsOpen}
                                    title={'Faktura'}
                                    icon={<DocumentIcon />}
                                    description={
                                        isInvoice ? (
                                            <div css={{ overflow: 'scroll' }}>
                                                {invoiceDetails?.name ? (
                                                    <>
                                                        {invoiceDetails?.name}
                                                        <Bullet />
                                                    </>
                                                ) : (
                                                    'Uzupełnij dane firmy '
                                                )}
                                                {invoiceDetails?.nip && (
                                                    <>
                                                        {invoiceDetails?.nip}
                                                        <Bullet />
                                                    </>
                                                )}
                                                {invoiceDetails?.address && (
                                                    <>
                                                        {
                                                            invoiceDetails?.address
                                                        }
                                                        <Bullet />
                                                    </>
                                                )}
                                                {invoiceDetails?.postalCode && (
                                                    <>
                                                        {
                                                            invoiceDetails?.postalCode
                                                        }
                                                        <Bullet />
                                                    </>
                                                )}
                                                {invoiceDetails?.city && (
                                                    <>
                                                        {invoiceDetails?.city}
                                                        <Bullet />
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            'Utwórz fakturę do zamówienia'
                                        )
                                    }
                                >
                                    <InvoiceDetails
                                        onInvoiceDetailsSave={
                                            onInvoiceDetailsSave
                                        }
                                        isInvoice={isInvoice}
                                        onIsInvoiceChange={onIsInvoiceChange}
                                        invoiceDetails={invoiceDetails}
                                        onInvoiceDetailsChange={
                                            onInvoiceDetailsChange
                                        }
                                        parentSetIsOpen={parentSetIsOpen}
                                    />
                                </Collapsable>
                            </Grid>
                            {/* PŁATNOŚĆ */}
                            <OrderPayment
                                isDelivery={isDelivery}
                                deliveryCost={deliveryCost}
                                showDeliveryCost={
                                    deliveryAddress &&
                                    Object.values(deliveryError).every(
                                        (value) => value !== true,
                                    )
                                }
                                productsCost={priceFormat.standardPrice(
                                    productsCost,
                                )}
                                packageCost={
                                    consumptionDetails.type !== 'IN'
                                        ? packagingCost.toFixed(2)
                                        : null
                                }
                                totalCost={priceSum}
                                left={
                                    isTakeAwayWithoutGroupOrder ||
                                    isRegularOrderWithoutGroupOrder
                                }
                                right={isTakeAwayGroupOrder || isRegularOrder}
                            />
                            <Grid item xs={12}>
                                <p
                                    css={{
                                        padding: '1.5rem 1rem 0rem',
                                        fontSize: '11px',
                                        color: colors.text.concat(alpha[60]),
                                        a: {
                                            color: colors.text,
                                            fontWeight: 700,
                                            textDecoaration: 'underline',
                                        },
                                    }}
                                >
                                    Przesyłając zamówienie, potwierdzasz
                                    zapoznanie się z{' '}
                                    <Link to="/terms">Regulaminem</Link> oraz{' '}
                                    <Link to="/privacy">
                                        Politką Prywatności
                                    </Link>
                                </p>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </ItemsWrapper>
            <Hidden smDown>
                <div css={sRestContainer}>
                    {orderWeWorkOn.restaurant && (
                        <GetRestaurantImage
                            restId={orderWeWorkOn.restaurant.id}
                            data={orderWeWorkOn.restaurant}
                        />
                    )}
                </div>
            </Hidden>
            <SendOrderBtn isLoading={isLoading} onSendOrder={onSendOrder} />
        </React.Fragment>
    );
};

export const SendOrderBtn = ({ isLoading, onSendOrder }) => {
    const { bottomNavigation } = useSafeAreaInsets();

    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    return (
        <ButtonWrapper
            bottom={bottomNavigation}
            isStatic={IOS && keyboardHeight}
        >
            <Button
                variant="greenGradient"
                onClick={onSendOrder}
                loading={isLoading}
                width={'100%'}
            >
                Prześlij zamówienie
            </Button>
        </ButtonWrapper>
    );
};

export const GetRestaurantImage = ({ restId }) => {
    const {
        data: queryData,
        loading,
        error,
    } = useSingleCartOrderQuery({
        variables: { input: { restaurantId: restId } },
        onError: (error) => errorHandling(error),
        skip: !restId,
    });

    if (loading || error)
        return (
            <div
                css={[
                    sRestContainerExported,
                    { backgroundColor: colors.gray200 },
                ]}
            />
        );

    if (queryData.restaurant)
        return <RestContainer data={queryData.restaurant} />;

    return (
        <div
            css={[sRestContainerExported, { backgroundColor: colors.gray200 }]}
        />
    );
};

export const sRestContainer = {
    [s.sm_down]: {
        marginTop: globals.topnavHeight.mobile,
        padding: '1.5rem 0',
    },
    [s.md]: { marginTop: globals.topnavHeight.desktop + 64 - 24 },
};

const ItemsWrapper = styled.div`
    padding-bottom: 50px;
    .collapsable {
        padding: 0;
    }
    .grid-wrapper > .MuiGrid-root > .MuiGrid-item:nth-child(odd) {
        padding: 12px 12px 12px 0;
        ${mediaQueriesMaterial.sm} {
            padding: 6px 0;
        }
    }
    .grid-wrapper > .MuiGrid-root > .MuiGrid-item:nth-child(even) {
        padding: 12px 0 12px 12px;
        ${mediaQueriesMaterial.sm} {
            padding: 6px 0;
        }
    }
`;

const OrderTypeWrapper = styled.div`
    display: grid;
    gap: 16px;
    padding: 32px;

    ${mediaQueries.sm} {
        padding: 24px 16px;
    }
`;

export const ButtonWrapper = styled.div(
    ({ bottom, isStatic }) => css`
        display: grid;
        grid-gap: 8px;
        width: 100%;
        padding: 0 24px;
        max-width: ${globals.maxWidthMedium}px;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 24px;
        transition:
            bottom 0.6s ease-in-out,
            filter 0.3s ease-out,
            transform 0.3s ease-out,
            opacity 0.3s ease-out;
        z-index: 200;
        bottom: ${bottom ? `${bottom}px` : '72px'};

        ${isStatic &&
        css`
            bottom: 0;
            position: relative;
        `}

        ${mediaQueries.md} {
            max-width: 720px;
        }
    `,
);

const DeliveryInfoWrapper = styled.div`
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
`;
