import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { Collapsable } from '../../../../components/Elements';
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable';
import { s, colors } from '../../../../style';
import { useRestaurantContext } from '../context/restaurant.provider';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';

const StatusActive = () => {
    const [isOpen, setIsOpen] = useState(false);

    const { restaurantInfo, setRestaurantInfo } = useRestaurantContext();

    const isActive = restaurantInfo.isActive;

    const onChangeSave = () => {
        setIsOpen(!isOpen);
        setRestaurantInfo({ ...restaurantInfo, isActive: !isActive });
    };

    if (isActive === undefined) return null;

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                right
                noPadding
                oneLiner
                title={'Widoczność'}
                icon={
                    isActive ? (
                        <LockOpenIcon fontSize="inherit" />
                    ) : (
                        <LockIcon fontSize="inherit" color={'#ff0000'} />
                    )
                }
                description={
                    isActive ? (
                        <>
                            <strong css={{ color: colors.green }}>
                                Aktywna
                            </strong>
                            &nbsp; Restauracja
                        </>
                    ) : (
                        <>
                            <strong css={{ color: colors.red }}>
                                Nieaktywna
                            </strong>
                            &nbsp; Restauracja
                        </>
                    )
                }
                parentIsOpen={isOpen}
                parentSetIsOpen={setIsOpen}
            >
                <CollapsableText>
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isActive}
                                    onChange={onChangeSave}
                                    name="isSendingClientPushes"
                                    color="primary"
                                />
                            }
                        ></FormControlLabel>
                        <span
                            css={{
                                fontWeight: 700,
                                color: colors.text,
                                fontSize: '13px',
                            }}
                        >
                            {isActive
                                ? 'Wyłącz aktywność restauracji'
                                : 'Włącz aktywność restauracji'}
                        </span>
                    </div>
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

export default StatusActive;
