export const RestaurantAvatar = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="31" cy="31" r="31" fill="#BDBDBD" />
        <g clipPath="url(#clip0_4008_44860)">
            <path
                d="M30.3668 28.2997H28.3001V21.0664H26.2334V28.2997H24.1668V21.0664H22.1001V28.2997C22.1001 30.4904 23.8154 32.2677 25.9751 32.4021V41.7331H28.5584V32.4021C30.7181 32.2677 32.4334 30.4904 32.4334 28.2997V21.0664H30.3668V28.2997ZM35.5334 25.1997V33.4664H38.1168V41.7331H40.7001V21.0664C37.8481 21.0664 35.5334 23.3811 35.5334 25.1997Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4008_44860">
                <rect
                    width="24.8"
                    height="24.8"
                    fill="white"
                    transform="translate(19 19)"
                />
            </clipPath>
        </defs>
    </svg>
);
