import { Chip, ChipProps, ChipVariant } from '@/componentsV2/atoms/Chip/Chip';
import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMemo } from 'react';

export type OptionsType = { id: string; name: string }[];

export interface ChipsBlockProps {
    defaultStep?: ChipProps['variant'];
    loading?: boolean;
    notPreffered?: OptionsType;
    onChange: (value: {
        preffered: OptionsType;
        notPreffered: OptionsType;
    }) => void;
    options: OptionsType;
    preffered?: OptionsType;
}

export const ChipsBlock = ({
    defaultStep = 'default',
    loading,
    notPreffered,
    onChange,
    options,
    preffered,
}: ChipsBlockProps) => {
    const steps = useMemo(() => {
        const steps = [defaultStep];
        preffered && steps.push('add');
        notPreffered && steps.push('remove');

        return steps;
    }, [defaultStep, preffered, notPreffered]);

    const handleChange = (
        option: OptionsType[number],
        nextVariant: ChipVariant,
        isAdd: number,
        isRemove: number,
    ) => {
        const newPreffered = preffered || [];
        const newNotPreferred = notPreffered || [];

        if (nextVariant === 'add') {
            newPreffered.push(option);
            if (isRemove >= 0) newNotPreferred.splice(isRemove, 1);
        } else if (nextVariant === 'remove') {
            newNotPreferred.push(option);
            if (isAdd >= 0) newPreffered.splice(isAdd, 1);
        } else if (nextVariant === defaultStep) {
            if (isRemove >= 0) newNotPreferred.splice(isRemove, 1);
            if (isAdd >= 0) newPreffered.splice(isAdd, 1);
        }

        onChange({
            preffered: newPreffered,
            notPreffered: newNotPreferred,
        });
    };

    if (loading)
        return (
            <ChipsWrapper>
                <Loading />
            </ChipsWrapper>
        );

    return (
        <ChipsWrapper>
            {options?.map((option) => {
                const isAdd =
                    preffered?.findIndex((item) => option.id === item.id) ?? -1;
                const isRemove =
                    notPreffered?.findIndex((item) => option.id === item.id) ??
                    -1;
                let variant: ChipVariant = defaultStep;
                if (isAdd >= 0) variant = 'add';
                if (isRemove >= 0) variant = 'remove';

                return (
                    <Chip
                        icon
                        id={option.id}
                        key={option.id}
                        label={option.name}
                        onClick={(_, nextVariant) =>
                            handleChange(option, nextVariant, isAdd, isRemove)
                        }
                        steps={steps}
                        variant={variant}
                    />
                );
            })}
        </ChipsWrapper>
    );
};

const ChipsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const Loading = styled(CircularProgress)`
    margin: 0 auto;
`;
