import React from 'react';
import { ButtonBorderNew } from '@/componentsV2/molecules/ButtonBorderNew/ButtonBorderNew';
import { colors } from '@style/index';
import { CONSUMPTION_TYPE_NAME_VALUES } from '@constants/fromGraphql/ConsumptionType.constants';
import { ConsumptionType } from '@/graphql/generated';
import { FilterButton } from '@/componentsV2/atoms/FilterButton/FilterButton';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { LocationPinIcon } from '@/svgs/LocationPin';
import { popup } from '@/AppPopups';
import { Skeleton as SkeletonElement } from '@material-ui/lab';
import { useHomeFilterHeaderQuery } from '@graphql/generated';
import CheckIcon from '@material-ui/icons/Check';
import styled from '@emotion/styled';
import { useAuthService } from '@/services/AuthService';
import {
    UserSettingsStoreState,
    useUserSettingsStoreShallow,
} from '@/EZC/services/UserSettingsStore';
import { GeolocationType } from '@/types/Geolocalization';
import { css } from '@emotion/react';
import { Switch } from '@/componentsV2/atoms/Switch/Switch';
import { Link } from 'react-router-dom';
import { xor } from 'lodash';
import {
    ServerConfigStoreState,
    useServerConfigStoreShallow,
} from '@/services/ServerConfigService/ServerConfigStore';
import { InfoShareLogo } from '@/svgs/InfoShareLogo';
import { DEFAULTS_CONSTANTS } from '@constants/defaults.constants';

interface HomeFilterHeaderInnerProps {
    activeButtons: ConsumptionType[];
    applyUserPreferences: boolean;
    distance: string | null;
    loading?: boolean;
    localization: GeolocationType;
    localizedBanner?: boolean;
    localizedBannerEnabled?: boolean;
    showBrochures: boolean;
    updateServerConfig: (value: Partial<ServerConfigStoreState>) => void;
    updateUserSettings: (value: Partial<UserSettingsStoreState>) => void;
    userName?: string;
}

export const HomeFilterHeader = () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const { localizedBanner, localizedBannerEnabled, updateServerConfig } =
        useServerConfigStoreShallow([
            'localizedBanner',
            'localizedBannerEnabled',
            'updateServerConfig',
        ]);

    const { data, loading } = useHomeFilterHeaderQuery({
        skip: !isLoggedIn,
        onError: graphqlErrorHandler(true),
    });

    const {
        consumptionTypes,
        applyUserPreferences,
        distance,
        geolocation,
        updateUserSettings,
        showBrochures,
    } = useUserSettingsStoreShallow([
        'consumptionTypes',
        'applyUserPreferences',
        'distance',
        'geolocation',
        'updateUserSettings',
        'showBrochures',
    ]);

    return (
        <HomeFilterHeaderInner
            showBrochures={showBrochures}
            activeButtons={consumptionTypes}
            applyUserPreferences={applyUserPreferences}
            distance={distance}
            loading={loading}
            localization={geolocation}
            localizedBanner={localizedBanner}
            localizedBannerEnabled={localizedBannerEnabled}
            updateServerConfig={updateServerConfig}
            updateUserSettings={updateUserSettings}
            userName={data?.user?.personal?.name}
        />
    );
};

export const HomeFilterHeaderInner = ({
    activeButtons,
    applyUserPreferences,
    distance,
    loading,
    localization,
    localizedBanner,
    localizedBannerEnabled,
    showBrochures,
    updateServerConfig,
    updateUserSettings,
    userName,
}: HomeFilterHeaderInnerProps) => {
    if (loading) {
        return <Skeleton variant="rect" animation="wave" height={145} />;
    }
    return (
        <HeaderContainer>
            <HeaderWrapper>
                {userName && (
                    <div>
                        <Greetings>Dzień dobry</Greetings>
                        <User>
                            {userName}
                            <FilterButton
                                onClick={() => {
                                    popup.show('FilterSettingsPopup');
                                }}
                                count={0}
                            />
                        </User>
                    </div>
                )}
                <FilterWrapper>
                    <FilterWrapperInner>
                        <LocationPinIcon />
                        <FilterItem>
                            {localizedBannerEnabled
                                ? DEFAULTS_CONSTANTS.infoshareAddress
                                : localization.address}
                        </FilterItem>
                        {!localizedBannerEnabled && distance && (
                            <>
                                <Bullet>&bull;</Bullet>
                                <FilterItem>{distance}km</FilterItem>
                            </>
                        )}
                    </FilterWrapperInner>
                    {!userName && (
                        <FilterButton
                            onClick={() => {
                                popup.show('FilterSettingsPopup');
                            }}
                            count={0}
                        />
                    )}
                </FilterWrapper>
                <ButtonsWrapper>
                    {CONSUMPTION_TYPE_NAME_VALUES.map((item) => {
                        const isActive = activeButtons.includes(item.id);
                        return (
                            <ButtonBorderNew
                                key={item.id}
                                icon={
                                    isActive ? (
                                        <CheckIcon fontSize="inherit" />
                                    ) : undefined
                                }
                                variant={isActive ? 'green' : 'gray'}
                                disabled={
                                    isActive && activeButtons.length === 1
                                }
                                onClick={() => {
                                    updateUserSettings({
                                        consumptionTypes: xor(activeButtons, [
                                            item.id,
                                        ]),
                                    });
                                }}
                            >
                                {item.name}
                            </ButtonBorderNew>
                        );
                    })}
                </ButtonsWrapper>
            </HeaderWrapper>
            <SwitchWrapper>
                {userName && (
                    <SwitchItem>
                        <Switch
                            checked={applyUserPreferences}
                            onChange={() => {
                                updateUserSettings({
                                    applyUserPreferences: !applyUserPreferences,
                                });
                            }}
                        />
                        <span>
                            Uwzględnij moje{' '}
                            <Link
                                to={`/account/food-preferences`}
                                css={{
                                    color: colors.text,
                                    textDecoration: 'underline',
                                }}
                            >
                                preferencje żywieniowe
                            </Link>
                        </span>
                    </SwitchItem>
                )}
                <SwitchItem>
                    <Switch
                        checked={showBrochures}
                        onChange={() => {
                            updateUserSettings({
                                showBrochures: !showBrochures,
                            });
                        }}
                    />
                    <span>Pokaż wszystkie dania i restauracje</span>
                </SwitchItem>
                {localizedBanner && (
                    <SwitchItem>
                        <Switch
                            checked={localizedBannerEnabled}
                            onChange={() => {
                                updateServerConfig({
                                    localizedBannerEnabled:
                                        !localizedBannerEnabled,
                                });
                            }}
                        />
                        <InfoShareLabel>
                            Strefa Gastro
                            <InfoShareLogo />
                        </InfoShareLabel>
                    </SwitchItem>
                )}
            </SwitchWrapper>
        </HeaderContainer>
    );
};

const HeaderContainer = styled.header(
    () => css`
        display: grid;
        grid-gap: 24px;
    `,
);

const HeaderWrapper = styled.div(
    () => css`
        background: linear-gradient(${colors.gray100}, ${colors.gray200});
        border-radius: 16px;
        display: grid;
        grid-gap: 8px;
        padding: 16px;
    `,
);

const Greetings = styled.h1`
    align-items: center;
    color: ${colors.lightSlateGray};
    display: flex;
    font-size: 12px;
    font-weight: 400;
    justify-content: space-between;
    line-height: 18px;
`;

const FilterWrapperInner = styled.div`
    align-items: center;
    gap: 4px;
    overflow: scroll;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: grid;
    grid-template-columns: repeat(4, auto);
`;

const User = styled.h2`
    align-items: center;
    display: flex;
    font-size: 22px;
    font-weight: 700;
    justify-content: space-between;
    line-height: 33px;
`;

const FilterWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    gap: 8px;
`;

const Skeleton = styled(SkeletonElement)`
    background: linear-gradient(${colors.gray100}, ${colors.gray200});
    border-radius: 16px;
`;

const FilterItem = styled.p`
    color: ${colors.baliHai};
    font-size: 12px;
    line-height: 18px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 4px;
`;

const Bullet = styled.span`
    color: ${colors.baliHai};
    font-size: 12px;
`;

const SwitchWrapper = styled.div`
    display: grid;
    gap: 16px;
`;

const SwitchItem = styled.p`
    align-items: center;
    display: flex;
    font-size: 13px;
    gap: 8px;
    line-height: 20px;
`;

const InfoShareLabel = styled.span`
    align-items: baseline;
    display: flex;
    gap: 5px;
`;
