/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { SectionHeadline } from '@components/Elements';
import { sDescription, sGrid } from '@style/style';
import { useRestaurantContext } from '@/EZR/pages/Restaurant/context/restaurant.provider';
import OpenHoursTable from '@/EZR/components/OpenHoursTable';

const DeliveryHours = () => {
    const {
        deliverySettings: { availableHours },
        handleDeliverySettings,
    } = useRestaurantContext();

    return (
        <Grid container css={sGrid}>
            <SectionHeadline noPaddingLeft>Godziny Dostaw</SectionHeadline>
            <Grid item xs={12}>
                <p css={[sDescription, { marginBottom: '1rem' }]}>
                    Określ godziny realizacji zamówień z dostawą.
                </p>
            </Grid>
            <OpenHoursTable
                setter={handleDeliverySettings('availableHours')}
                getter={availableHours}
            />
        </Grid>
    );
};

export default DeliveryHours;
