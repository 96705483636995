import React from 'react'; // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core';
import { Collapsable } from '../../../../components/Elements';
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable';
import SmsIcon from '@material-ui/icons/Sms';
import Messages from '../../../views/Chat/components/Messages.chat';

import { s } from '../../../../style';
import { useChatArchiveMessages } from '@/services/FirebaseChatService/chats/common';
import { FIREBASE_ENV } from '@/services/FirebaseChatService/FirebaseChatService.config';

export const OrderChatHistory = ({
    userId,
    orderId,
    waiter,
    noPaddingBottom,
    left,
}) => {
    const { messages, error, loading } = useChatArchiveMessages(
        `env/${FIREBASE_ENV}/chats/${orderId}`,
    );

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                noPaddingBottom={noPaddingBottom}
                noPadding
                left={left}
                oneLiner
                title={'Czat Archiwum'}
                icon={<SmsIcon />}
                description={
                    loading ? (
                        <>Wiadomości</>
                    ) : error ? (
                        <>Brak historii czatu</>
                    ) : messages?.length > 0 ? (
                        `${messages?.length} wiadomości`
                    ) : (
                        'Brak wiadomości'
                    )
                }
            >
                {messages?.length > 0 ? (
                    <div
                        css={{
                            padding: '1.5rem',
                            [s.md]: { padding: '2rem' },
                            maxHeight: '300px',
                            overflowY: 'auto',
                        }}
                    >
                        {messages && (
                            <Messages
                                messages={messages}
                                userId={userId}
                                chatHistory
                                isWaiter={waiter}
                            />
                        )}
                    </div>
                ) : (
                    <CollapsableText>
                        Nie prowadzono rozmów z{' '}
                        {waiter ? 'Klientem' : 'Restauracją'}
                    </CollapsableText>
                )}
            </Collapsable>
        </Grid>
    );
};
