import React from 'react'; // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core';
import { sOuter, sGrid, sBottomLinks } from '@style/style';
import { ButtonBorder } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import { Profile } from '@/EZC/pages/account/Account.page';

import { Tile } from '@/EZC/pages/account/wallet/Wallet.page';

import { colors, s } from '@style';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useAuthService } from '@/services/AuthService';
import { useInfoPageQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAccountCoins } from '@/EZC/hooks/useAccountCoins';
import { EuropeanFundsInfo } from '@/componentsV2/atoms/EuropeanFundsInfo/EuropeanFundsInfo';

const Info = () => {
    const { actualBalance, blockedBalance } = useAccountCoins();
    const { data, loading, error } = useInfoPageQuery({
        onError: graphqlErrorHandler(true),
    });
    const userType = useAuthService((state) => state.user?.userType);

    if (loading) return <div />;
    if (error) {
        return null;
    }

    const { email, id, personal } = data.user || {};

    const isAdmin = userType === 'Admin';
    const isRestaurateur = userType === 'Restaurateur';

    return (
        <React.Fragment>
            <TopNav
                title={'Informacje'}
                subtitle={`- ${personal.name} ${personal.surname}`}
            />
            <div css={sOuter}>
                <Headline>Informacje</Headline>
                <Grid container css={[sGrid]}>
                    <Profile
                        actualBalance={actualBalance}
                        personal={personal || {}}
                        isRestaurateur={isRestaurateur}
                        isAdmin={isAdmin}
                        linkDisabled
                    />
                    <Tile
                        subtitle={'Dostępne Środki'}
                        version="BALANCE"
                        coins={actualBalance}
                    />
                    <Tile
                        subtitle={'Blokady'}
                        version="BLOCK"
                        coins={blockedBalance}
                    />
                    <InfoContainer
                        left
                        extraCss={{ [s.sm_down]: { marginTop: '0.5rem' } }}
                    >
                        <div css={{ marginBottom: 16 }}>
                            <Header>Numer ID klienta</Header>
                            <Paragraph>{id}</Paragraph>
                        </div>
                        <div>
                            <Header>Imię i nazwisko</Header>
                            <Paragraph>
                                {personal.name} {personal.surname}
                            </Paragraph>
                        </div>
                    </InfoContainer>

                    <InfoContainer right>
                        <Header>Adres email</Header>
                        <Paragraph>{email}</Paragraph>
                    </InfoContainer>

                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/account/settings'}>
                            Ustawienia
                        </ButtonBorder>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                        <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
                    </Grid>
                    <EuropeanFundsInfo />
                </Grid>
            </div>
        </React.Fragment>
    );
};

const Header = ({ children }) => (
    <h6
        css={{
            opacity: 0.6,
            fontSize: 12,
            fontWeight: 400,
            marginBottom: '0.5rem',
        }}
    >
        {children}
    </h6>
);

const Paragraph = ({ children }) => <p css={{ fontWeight: 700 }}>{children}</p>;

const InfoContainer = ({ children, left, right, extraCss }) => (
    <Grid item xs={12} md={6}>
        <div
            css={[
                {
                    borderRadius: 16,
                    padding: '1rem',
                    marginBottom: '1rem',
                    [s.sm]: { padding: '2rem', marginBottom: '1.5rem' },
                    background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
                },
                left && { [s.md]: { marginRight: '0.75rem' } },
                right && { [s.md]: { marginLeft: '0.75rem' } },
                extraCss,
            ]}
        >
            {children}
        </div>
    </Grid>
);

export default Info;
