import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { Collapsable } from '../../../../../components/Elements';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { CollapsableText } from '../../../../../components/Elements/Collapsable/Collapsable';
import { colors } from '../../../../../style';
import { useUserUpdateMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';

const SettingsPush = ({ pushNotifications }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [userUpdate, { loading }] = useUserUpdateMutation();

    const handleChange = () => {
        userUpdate({
            variables: {
                input: {
                    payload: {
                        settings: {
                            pushNotifications: !pushNotifications,
                        },
                    },
                },
            },
            onCompleted: () => {
                toast.dark('Ustawienia zostały zaktualizowane');
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                right
                noPadding
                oneLiner
                title={'Push'}
                icon={
                    pushNotifications ? (
                        <NotificationsActiveIcon />
                    ) : (
                        <NotificationsOffIcon />
                    )
                }
                description={<>Powiadomienia aplikacji</>}
                parentIsOpen={isOpen}
                parentSetIsOpen={setIsOpen}
            >
                <CollapsableText>
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!!pushNotifications}
                                    color="primary"
                                    disabled={loading}
                                    name="isSendingClientPushes"
                                    onChange={handleChange}
                                />
                            }
                        ></FormControlLabel>
                        <span
                            css={{
                                fontWeight: 700,
                                color: colors.text,
                                fontSize: '13px',
                            }}
                        >
                            Powiadomienia Push Klienta
                        </span>
                    </div>
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

export default SettingsPush;
