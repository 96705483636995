import React, { useEffect } from 'react';
import { ButtonBorder } from '../../../components/Elements';
import { FormProvider } from 'react-hook-form';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { Grid } from '@material-ui/core';
import { ProductSpiciness } from './components/ProductSpiciness';
import { sDescription, sGrid, sOuter, sPaddingTop } from '../../../style/style';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { useParams } from 'react-router-dom';
import { useProductForm } from './hooks/useProductForm/useProductForm';
import { useRestaurantProductQuery } from '@graphql/generated';
import ButtonSave from './components/ButtonSave';
import ConsumptionTypes from './components/ConsumptionTypes';
import Details from './components/Details';
import Headline from '../../../components/Headline/Headline';
import OpenHours from './components/OpenHours';
import Price from './components/Price';
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder';
import StatusArchive from './components/StatusArchive';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { s } from '@style/index';

const ProductPage = () => {
    const productId = useParams().id;

    const {
        handleFormSubmit,
        loading: formSubmitLoading,
        methods,
    } = useProductForm({ productId, update: true });

    const { data, loading: productLoading } = useRestaurantProductQuery({
        variables: { input: { productId } },

        fetchPolicy: 'cache-and-network',
        onError: graphqlErrorHandler(true),
    });

    useEffect(() => {
        if (data?.product) {
            const ingredientsIds = data.product?.ingredients?.map(
                (item) => item.id,
            );
            methods.reset({
                ...data.product,
                availableHours: convertTimestamps.fromISO(
                    data?.product?.availableHours,
                ),
                ingredientsIds,
                photo: undefined,
            });
        }
    }, [data]);

    if (productLoading)
        return (
            <React.Fragment>
                <div css={sOuter}>
                    <Headline>Produkty</Headline>
                    <Grid container css={sGrid}>
                        <p css={sDescription}>
                            Modyfikuj szczegóły produktu, zarządzaj godzinami
                            dostępności oraz parametrami wyszukiwania.
                        </p>
                    </Grid>
                    <Grid container css={[sGrid, sPaddingTop]}>
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Grid>
                </div>
            </React.Fragment>
        );

    return (
        <FormProvider {...methods}>
            <TopNav title={'Produkt'} offset={60} />
            <div
                css={
                    (sOuter,
                    {
                        padding: '88px 0 48px',
                        [s.md]: { padding: '144px 0 48px' },
                    })
                }
            >
                <Headline
                    textWrap
                    itemChildren={
                        <ButtonBorder link={`/product/${data?.product?.id}`}>
                            <VisibilityIcon fontSize="inherit" />
                            &nbsp;&nbsp;Wyświetl
                        </ButtonBorder>
                    }
                >
                    {data?.product?.name}
                </Headline>
                <Details
                    photoUrl={data?.product?.photoUrl}
                    ingredients={data?.product?.ingredients}
                >
                    <StatusArchive
                        productId={productId}
                        isArchived={data?.product?.deleted}
                        restaurantStatus={data?.product?.restaurant?.isActive}
                    />
                </Details>
                <ProductSpiciness />
                <Price />
                <ConsumptionTypes />
                <OpenHours />
            </div>
            <ButtonSave
                loading={formSubmitLoading}
                onSubmit={handleFormSubmit}
            />
        </FormProvider>
    );
};

export default () => <ProductPage />;
