import { Button } from '@/componentsV2/molecules/Button/Button';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import styled from '@emotion/styled';
import { colors } from '@style/index';

interface GroupDeletePopupProps {
    groupId: string;
    isOwner: boolean;
    loading: boolean;
    onRejectButton: (value: boolean) => void;
    deleteButton: {
        text: string;
        action: (value: string, isOwner: boolean) => void;
    };
    text: string;
    header: string;
}
export const GroupDeletePopup = ({
    groupId,
    loading,
    isOwner,
    onRejectButton,
    deleteButton,
    text,
    header,
}: GroupDeletePopupProps) => {
    return (
        <BasePopup
            overlayBackground={false}
            children={text}
            footer={
                <PopupFooter>
                    <Button
                        variant="gray"
                        onClick={() => onRejectButton(false)}
                    >
                        Anuluj
                    </Button>
                    <Button
                        variant="red"
                        onClick={() => deleteButton.action(groupId, isOwner)}
                        loading={loading}
                    >
                        {deleteButton.text}
                    </Button>
                </PopupFooter>
            }
            header={<PopupHeader>{header}</PopupHeader>}
        />
    );
};

const PopupFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 36px;
`;

const PopupHeader = styled.h2`
    color: ${colors.text};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
`;
