import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { ButtonText } from '@/componentsV2/atoms/ButtonText/ButtonText';
import { colors } from '@/style';
import { SearchFilterPanelFields } from './SearchFilterPanelFields';
import {
    Group,
    Ingredient,
    useGetAllIngredientsQuery,
    useSearchFilterGroupListQuery,
} from '@graphql/generated';
import {
    SEARCH_FILTER_COUNTABLE_KEYS,
    SearchFilterFormSchemaValues,
    useSearchFilterFormSubmit,
} from '../../SearchFilterFormProvider/SearchFilterFormProvider';
import { useAuthService } from '@/services/AuthService';
import { useFormContext, useWatch } from 'react-hook-form';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';

export interface SearchFilterPanelInnerProps {
    groups: {
        list: Omit<Group, 'members' | 'owner'>[];
        loading: boolean;
    };
    ingredients: {
        list: Ingredient[];
        loading: boolean;
    };
    onLocalizationChange: () => void;
}

export const SearchFilterPanel = ({
    onLocalizationChange,
}: Pick<SearchFilterPanelInnerProps, 'onLocalizationChange'>) => {
    const { isLoggedIn } = useAuthService();

    const ingredients = useGetAllIngredientsQuery();
    const groups = useSearchFilterGroupListQuery({
        skip: !isLoggedIn,
    });

    return (
        <SearchFilterPanelInner
            ingredients={{
                list: ingredients?.data?.list ?? [],
                loading: ingredients?.loading,
            }}
            groups={{
                list: groups?.data?.list?.data ?? [],
                loading: groups?.loading,
            }}
            onLocalizationChange={onLocalizationChange}
        />
    );
};

export const SearchFilterPanelInner = ({
    groups,
    ingredients,
    onLocalizationChange,
}: SearchFilterPanelInnerProps) => {
    const methods = useFormContext<SearchFilterFormSchemaValues>();
    const { handleFormSubmit, handleClear } = useSearchFilterFormSubmit();
    const { loading } = useGeolocationContext();
    /**
     * Values that trigger submit. They are part of panel.
     */
    const triggerValues = useWatch({
        control: methods.control,
        name: SEARCH_FILTER_COUNTABLE_KEYS,
    });

    useEffect(() => {
        handleFormSubmit();
    }, [triggerValues]);

    return (
        <Wrapper>
            <HeaderWrapper>
                <Title>Filtry</Title>
                <ButtonText
                    loading={loading}
                    text={'Wyczyść wszystkie'}
                    onClick={() => {
                        handleClear();
                    }}
                    variant={'black'}
                    fontSize={'12px'}
                    underline
                />
            </HeaderWrapper>
            <SearchFilterPanelFields
                groups={groups}
                ingredients={ingredients}
                onLocalizationChange={onLocalizationChange}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 15px;
    position: relative;
    z-index: 1;
`;

const Title = styled.h1`
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    color: ${colors.text};
`;

const HeaderWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
