import React from 'react';
import styled from '@emotion/styled';
import { ButtonBase, CircularProgress } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { css } from '@emotion/react';
import { alpha, colors, globals } from '@style/index';
import { BOTTOM_NAVIGATION } from '@constants/layout.constants';

interface BottomCartInfoProps {
    onClick: () => void;
    show: boolean;
    loading: boolean;
    mainText: string;
    isStatic?: boolean;
}
export const BottomCartInfo = ({
    onClick,
    show,
    loading,
    mainText,
    isStatic,
}: BottomCartInfoProps) => {
    const { bottom } = useSafeAreaInsets();

    return (
        <Button
            onClick={onClick}
            isShow={show}
            bottom={bottom}
            isStatic={isStatic}
        >
            <ContentWrapper>
                <ShoppingCartIcon
                    style={{
                        width: 18,
                        marginRight: 8,
                    }}
                />
                <Amount>
                    {loading ? (
                        <CircularProgress
                            color="inherit"
                            size={'16px'}
                            thickness={5}
                        />
                    ) : (
                        mainText
                    )}
                </Amount>
            </ContentWrapper>
            <ButtonText>
                dalej
                <ArrowIcon fontSize="inherit" />
            </ButtonText>
        </Button>
    );
};

const Button = styled(ButtonBase)<{
    isShow: boolean;
    bottom: number;
    isStatic?: boolean;
}>(
    ({ isShow, bottom, isStatic }) => css`
        align-items: center;
        background: linear-gradient(
            to right,
            ${colors.yellow},
            ${colors.orange200}
        );
        border-radius: 1.5rem;

        bottom: ${isShow
            ? `calc(${BOTTOM_NAVIGATION}px + ${bottom / 2}px)`
            : `-${BOTTOM_NAVIGATION}px`};
        box-shadow: 0px 8px 24px ${colors.yellow.concat(alpha[60])};
        color: #ffffff;
        display: flex;
        font-weight: 700;
        height: 48px;
        justify-content: space-between;
        left: 50%;
        letter-spacing: 0.05em;
        max-width: 720px;
        padding: 0 32px;
        position: fixed;
        transform: translateX(-50%);
        transition:
            bottom 0.6s ease-in-out,
            filter 0.3s ease-out;
        width: 100%;
        z-index: 200;

        ${isStatic &&
        css`
            bottom: 0;
            position: relative;
        `}

        &:hover {
            filter: brightness(1.1);
        }

        @media (max-width: 600px) {
            max-width: calc(100vw - 48px);
            padding: 0 24px;
        }
    `,
);

const ContentWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-evenly;
`;

const Amount = styled.p`
    flex-grow: 1;
    font-size: 15px;
    line-height: 1;
`;

const ButtonText = styled.div`
    background-color: ${colors.white.concat(alpha[30])};
    border-radius: 16px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    height: 32px;
    padding-left: 1.5rem;
    padding: 0 1rem;
    text-transform: uppercase;
    ${globals.center}
`;

const ArrowIcon = styled(ArrowForwardIosIcon)`
    margin-left: 0.5rem;
`;
