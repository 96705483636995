import { Mark, Slider as MaterialSlider } from '@material-ui/core';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export interface RangeSliderProps {
    isDirty?: boolean;
    marks: Mark[];
    max: number;
    onChange: (value: RangeSliderProps['value']) => void;
    value?: [number, number];
}

export const RangeSlider = ({
    isDirty,
    marks,
    max,
    onChange,
    value = [0, 5],
}: RangeSliderProps) => {
    return (
        <Wrapper>
            <SliderElement
                $active={!!isDirty}
                marks={marks}
                max={max}
                onChange={(_, value) =>
                    onChange(value as RangeSliderProps['value'])
                }
                value={value}
                valueLabelDisplay="on"
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 0 18px;
`;

const SliderElement = styled(MaterialSlider)<{
    $active: boolean;
    marks: Mark[];
}>(
    ({ $active, marks }) => css`
        color: ${$active ? colors.green : colors.text};
        margin: 38px 0 0;
        width: 100%;

        .PrivateValueLabel-label-5 {
            color: ${colors.white};
            font-size: 11px;
            font-weight: 600;
            line-height: 14px;
        }

        .MuiSlider-markLabel {
            color: ${colors.text};
            font-size: 11px;
            font-weight: 700;
            line-height: 16px;
        }

        .MuiSlider-rail {
            border-radius: 40px;
            color: ${colors.platinum};
            height: 8px;
        }

        .MuiSlider-track {
            color: ${$active ? colors.green : colors.text};
            height: 8px;
        }

        .MuiSlider-mark {
            color: ${colors.gray600};
            height: 8px;
        }

        .MuiSlider-mark[data-index='${marks.length - 1}'] {
            color: transparent;
        }

        .MuiSlider-markActive {
            color: ${colors.white};
        }

        .MuiSlider-thumb {
            height: 16px;
            width: 16px;
        }

        .MuiSlider-valueLabel {
            left: -50%;
        }
    `,
);
