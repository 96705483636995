import {
    PRODUCT_LABEL,
    PRODUCT_LABEL_NAME,
} from '@constants/fromGraphql/ProductLabel.constans';
import { ProductLabel } from '@graphql/generated';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';

export interface ProductStatusBadgeProps {
    value: ProductLabel;
}
export const ProductStatusBadge = ({ value }: ProductStatusBadgeProps) => {
    return (
        <ProductStatusBadgeWrapper value={value}>
            {PRODUCT_LABEL_NAME[value]}
        </ProductStatusBadgeWrapper>
    );
};

const ProductStatusBadgeWrapper = styled.div<{ value: ProductLabel }>(
    ({ value }) => css`
        background-color: ${PRODUCT_LABEL[value].color};
        border-radius: 16px;
        color: ${colors.white};
        font-size: 11px;
        font-weight: 700;
        justify-self: end;
        line-height: 16.5px;
        padding: 2px 12px;
        width: max-content;
    `,
);
