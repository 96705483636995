import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { s } from '@style/index';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import Headline from '@components/Headline/Headline';
import { CustomerTypeChoose } from '@/EZW/domains/Orders/organisms/CustomerTypeChoose/CustomerTypeChoose';
import styled from '@emotion/styled';
import {
    CartWaiterDocument,
    useCartWaiterCreateMutation,
    useCartWaiterDecreaseQuantityItemMutation,
    useCartWaiterQuery,
    useCartWaiterUpdateMutation,
    useRestaurantMenuWaiterQuery,
} from '@graphql/generated';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import PageLoader from '@components/PageLoader/PageLoader';
import NavRestMenu from '@/EZC/views/RestaurantPage/components/NavRestMenu';
import { sGrid, sOuter } from '@/style/style';
import { Element as ElementScroll } from 'react-scroll';
import { ProdItemMenuList } from '@/EZC/components/ProdItem';
import { priceFormat } from '@helpers/priceFormat';
import { BottomCartInfo } from '@/EZW/domains/Orders/molecules/BottomCartInfo/BottomCartInfo';
import { useHistory } from 'react-router-dom';
import { dateFormat } from '@helpers/dateFormat';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { IOS } from '@constants/common.constants';

export interface CustomerProps {
    avatar?: string | null;
    email?: string;
    name: string;
    id?: string;
}

export const NewOrderPage = () => {
    const { restaurantId = '', createNewOrderData } = useWaiterService();
    const history = useHistory();
    const { geolocation } = useGeolocationContext();
    const [customer, setCustomer] = useState<CustomerProps | undefined>(
        createNewOrderData.customer,
    );
    const [isEatzonUser, setIsEatzonUser] = useState(
        createNewOrderData.isEatzonClient,
    );

    const [cartAdd, { loading: cartAddLoading }] = useCartWaiterCreateMutation({
        context: { waiter: true },
    });

    const { data } = useCartWaiterQuery({
        onError: graphqlErrorHandler(true),
        context: { waiter: true },
        fetchPolicy: 'cache-and-network',
    });

    const [decreaseQuantity, { loading: updateLoading }] =
        useCartWaiterDecreaseQuantityItemMutation({
            context: { waiter: true },
        });

    const [cartUpdate] = useCartWaiterUpdateMutation({
        onError: graphqlErrorHandler(true),
    });

    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    const onNextStep = () => {
        if (!customer?.name && isEatzonUser) {
            toast.error('Podaj email klienta');
            return;
        }
        if (!customer?.name && !isEatzonUser) {
            toast.error('Podaj opis klienta');
            return;
        }
        const futureDay = dateFormat.getFutureDate(dayjs().toDate());
        data?.cartList?.data[0]?.id &&
            cartUpdate({
                context: { waiter: true },
                variables: {
                    input: {
                        cartId: data?.cartList?.data[0]?.id,
                        payload: {
                            deliveryLocation: {
                                coordinates: [
                                    geolocation?.lng,
                                    geolocation?.lat,
                                ],
                            },
                            consumptionDetails: {
                                date: futureDay.toISOString(),
                                guests: 1,
                                type: 'IN',
                            },
                        },
                    },
                },
                onError: graphqlErrorHandler({
                    customCodeMessages: {
                        ORDER_NOT_ENOUGH_COINS_ERROR:
                            'Niewystarczająca ilość Eatcoinów na koncie klienta',
                    },
                }),
                onCompleted: () => {
                    history.push(`/waiter/new-order-checkout`);
                },
            });
    };

    const addProduct = (id: string) => {
        cartAdd({
            variables: {
                input: {
                    productId: id,
                    quantity: 1,
                },
            },
            onError: graphqlErrorHandler(true),
            refetchQueries: [CartWaiterDocument],
        });
    };

    const deleteProduct = (id: string) => {
        decreaseQuantity({
            variables: { input: { productId: id, quantity: 1 } },
            onError: graphqlErrorHandler(true),
            refetchQueries: [CartWaiterDocument],
        });
    };

    const sum = data?.cartList?.data[0]?.lines
        ?.map(({ quantity, product: { price } }) => quantity * price)
        ?.reduce((product1, product2) => product1 + product2, 0);

    const { data: { restaurant = undefined } = {}, loading } =
        useRestaurantMenuWaiterQuery({
            variables: {
                input: { restaurantId },
            },
            onError: (error) => {
                graphqlErrorHandler(true)(error);
            },
        });

    const sortedMenu =
        restaurant?.menu && restaurant?.menu?.length > 1
            ? restaurant?.menu
                  ?.slice()
                  .filter((item) => item?.order != null)
                  .sort((a, b) => (a?.order || 0) - (b?.order || 0))
            : restaurant?.menu || [];

    if (loading) return <PageLoader />;

    return (
        <>
            {/* @ts-expect-error migrate to ts */}
            <TopNav
                title={'Nowe zamówienie'}
                extraCssOuter={{ boxShadow: 'none' }}
            />
            <NavRestMenu items={sortedMenu.map((section) => section.name)} />
            {/* @ts-expect-error migrate to TS */}
            <div css={sOuter}>
                <Container>
                    {/* @ts-expect-error migrate to ts */}
                    <Headline>Nowe zamówienie</Headline>
                    <Grid container css={sGrid}>
                        <Grid item xs={12}>
                            <CustomerTypeChoose
                                setCustomer={setCustomer}
                                customer={customer}
                                isEatzonUser={isEatzonUser}
                                setIsEatzonUser={setIsEatzonUser}
                            />
                        </Grid>
                    </Grid>
                    <Wrapper>
                        {sortedMenu?.map((section, index) => (
                            <ElementScroll
                                name={encodeURIComponent(section.name)}
                                key={index}
                            >
                                <SectionWrapper key={index} index={index}>
                                    <SectionName>{section.name}</SectionName>
                                    <Grid container>
                                        {section.products.map(
                                            (product, index) => {
                                                const onAddProduct = () => {
                                                    addProduct(product?.id);
                                                };
                                                const onDeleteProduct = () => {
                                                    deleteProduct(product?.id);
                                                };
                                                return (
                                                    /* @ts-expect-error migrate to ts */
                                                    <ProdItemMenuList
                                                        id={index}
                                                        key={product?.id}
                                                        data={product}
                                                        handleAddProduct={
                                                            onAddProduct
                                                        }
                                                        handleDeleteProduct={
                                                            onDeleteProduct
                                                        }
                                                        productInOrder={data?.cartList?.data[0]?.lines?.find(
                                                            (item) =>
                                                                item?.product
                                                                    ?.id ===
                                                                product.id,
                                                        )}
                                                        noLink
                                                    />
                                                );
                                            },
                                        )}
                                    </Grid>
                                </SectionWrapper>
                            </ElementScroll>
                        ))}
                        <BottomCartInfo
                            mainText={priceFormat.standardPrice(sum)}
                            onClick={onNextStep}
                            show={!!data?.cartList?.data[0]?.id}
                            loading={cartAddLoading || updateLoading}
                            isStatic={IOS && !!keyboardHeight}
                        />
                    </Wrapper>
                </Container>
            </div>
        </>
    );
};

const Container = styled.div`
    display: grid;
    width: 100%;
    padding-bottom: 50px;
`;

const Wrapper = styled.div`
    padding: 0 24px;
`;

const SectionWrapper = styled.div<{ index: number }>(
    ({ index }) => css`
        padding-top: ${index === 1 ? '0' : '48px'};

        ${s.md} {
            padding-top: ${index === 1 ? '0' : '64'};
        }
    `,
);

const SectionName = styled.p`
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin-bottom: 24px;
    padding-left: 16px;
    white-space: nowrap;

    ${s.xs} {
        font-size: 22px;
    }

    ${s.md} {
        padding-left: 32px;
    }
`;
