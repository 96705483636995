import { CookMethod } from '@/graphql/generated';

export const COOK_METHOD_ENUM: Record<CookMethod, CookMethod> = {
    BAKE: 'BAKE',
    BOIL: 'BOIL',
    DEEPFRY: 'DEEPFRY',
    FRY: 'FRY',
    GRILL: 'GRILL',
    ROAST: 'ROAST',
    STEAM: 'STEAM',
    STEW: 'STEW',
    BLANCH: 'BLANCH',
    BREW: 'BREW',
    DRY: 'DRY',
    FLAMBE: 'FLAMBE',
    FREEZED: 'FREEZED',
    MARINATE: 'MARINATE',
    PAUSTERIZE: 'PAUSTERIZE',
    PICKLE: 'PICKLE',
    SMOKED: 'SMOKED',
    SOUS_VIDE: 'SOUS_VIDE',
    STIRFRY: 'STIRFRY',
    CHOP: 'CHOP',
    LIVE_COOKING: 'LIVE_COOKING',
    MINCE: 'MINCE',
    SEAR: 'SEAR',
};

export const COOK_METHOD: Record<CookMethod, { name: string }> = {
    BAKE: { name: 'pieczenie (ciasto)' },
    BOIL: { name: 'gotowanie' },
    DEEPFRY: { name: 'smażenie na głębokim oleju' },
    FRY: { name: 'smażenie' },
    GRILL: { name: 'grillowanie' },
    ROAST: { name: 'pieczenie (mięso)' },
    STEAM: { name: 'gotowanie na parze' },
    STEW: { name: 'duszenie' },
    BLANCH: {
        name: 'blanszowanie',
    },
    BREW: {
        name: 'parzenie',
    },
    DRY: {
        name: 'suszenie',
    },
    FLAMBE: {
        name: 'flambirowanie',
    },
    FREEZED: {
        name: 'mrożenie',
    },
    MARINATE: {
        name: 'marynowanie',
    },
    PAUSTERIZE: {
        name: 'pasteryzowanie',
    },
    PICKLE: {
        name: 'kiszenie',
    },
    SMOKED: {
        name: 'wędzenie',
    },
    SOUS_VIDE: {
        name: 'sous-vide',
    },
    STIRFRY: {
        name: 'stir-fry',
    },
    CHOP: {
        name: 'siekanie',
    },
    LIVE_COOKING: {
        name: 'live cooking',
    },
    MINCE: {
        name: 'krojenie',
    },
    SEAR: {
        name: 'opalanie',
    },
};

export const COOK_METHOD_NAME: Partial<Record<CookMethod, string>> =
    Object.entries(COOK_METHOD).reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
        {},
    );

export const COOK_METHOD_NAME_VALUES = Object.entries(COOK_METHOD_NAME).map(
    ([id, name]) => ({ id, name }),
) as { id: CookMethod; name: string }[];

export const COOK_METHOD_KEYS = Object.keys(COOK_METHOD) as CookMethod[];
