import styled from '@emotion/styled';
import { colors } from '@style/index';
import { Grid } from '@material-ui/core';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { consumptionTypeIcons, consumptionTypeNames } from '@constants/types';
import { CONSUMPTION_TYPE_ENUM } from '@constants/fromGraphql/ConsumptionType.constants';
import { TableChange } from '../TableChange/TableChange';
import React, { useState } from 'react';
import { ConsumptionType } from '@graphql/generated';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';

export interface OrderTypeProps {
    consumptionType: ConsumptionType;
    tableNumber: number;
    tableChange?: boolean;
}

export const OrderType = ({
    consumptionType,
    tableNumber,
    tableChange,
}: OrderTypeProps) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Grid item xs={12} md={6}>
            <Accordion
                collapsedPadding="24px 32px"
                headerElement={
                    <ContentWrapper>
                        <IconWrapper>
                            {consumptionTypeIcons[consumptionType]}
                        </IconWrapper>
                        <InfoWrapper>
                            <Title>
                                {consumptionTypeNames[consumptionType]}
                            </Title>
                            <Description>Sposób Zamówienia</Description>
                        </InfoWrapper>
                    </ContentWrapper>
                }
            >
                <Wrapper>
                    <div>
                        Wybrany sposób zamówienia:{' '}
                        <OrderInfo>
                            {consumptionTypeNames[consumptionType]}
                        </OrderInfo>
                    </div>
                    {consumptionType === CONSUMPTION_TYPE_ENUM.IN &&
                        tableNumber && (
                            <div>
                                Wybrany numer stolika:{' '}
                                <OrderInfo>{tableNumber}</OrderInfo>
                                {tableChange && (
                                    <ChangeButton
                                        onClick={() => setExpanded(true)}
                                    >
                                        Zmień
                                    </ChangeButton>
                                )}
                            </div>
                        )}
                    {tableChange && expanded && (
                        <>
                            <Separator />
                            <TableChange setExpanded={setExpanded} />
                        </>
                    )}
                </Wrapper>
            </Accordion>
        </Grid>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const OrderInfo = styled.strong`
    color: ${colors.text};
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const ChangeButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 12px;
    padding-left: 16px;
    text-decoration: underline;
`;
