import {
    sInput as sInputAuth,
    sInputDarker as sInputDarkerAuth,
    sBase,
} from '../../EZC/views/Auth/components/Input.component';

import { colors, s } from '../../style';

export const sInput = {
    ...sBase,
    ...sInputAuth,
    // ...sInputDarker,
    borderRadius: 16,
    fontSize: '15px',
    [s.md]: { fontSize: '15px', padding: '1.75rem 1.5rem 1rem' },
    letterSpacing: '0em',
    padding: '1.75rem 1.5rem 1rem',
    'label + .MuiInput-formControl': {
        marginTop: 0,
    },
    '.MuiInputLabel-formControl': {
        top: 10,
        left: 24,
        transform: 'translate(0, 14px) scale(1)',
        fontWeight: 700,
        color: colors.gray500,
        textAlign: 'left',
        textTransform: 'uppercase',
        fontSize: '0.875rem',
        letterSpacing: '0.1em',
        width: '100%',
    },
    '.MuiInputLabel-shrink': {
        transform: 'translate(0, 1.5px) scale(0.75)',
    },
    '.MuiAutocomplete-endAdornment': {
        top: 'calc(50% - 18px)',
    },
};

export const sInputDarker = { ...sInputDarkerAuth };
