import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';
import {
    OPERATIONS,
    useAdminIngredientDeleteMutation,
} from '@graphql/generated';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';

interface EzAdminIngredientsDeleteModalProps {
    closeModal: () => void;
    id: string;
}

export const EZAdminIngredientsDeleteModal = ({
    closeModal,
    id,
}: EzAdminIngredientsDeleteModalProps) => {
    const [ingredientDelete, { loading }] = useAdminIngredientDeleteMutation();
    const handleSubmit = () => {
        ingredientDelete({
            variables: {
                input: {
                    id: id,
                },
            },
            onCompleted: () => {
                closeModal();
                toast.dark('Składnik został usunięty');
            },
            onError: (error) => graphqlErrorHandler(true)(error),
            refetchQueries: [OPERATIONS.Query.AdminIngredientList],
        });
    };

    return (
        <ModalContentWrapper>
            <ModalTitle>Usunąć składnik?</ModalTitle>
            <ModalText>
                Czy na pewno chcesz usunąć ten składnik? Spowoduje to również
                usunięcie go ze wszystkich powiązanych miejsc.
            </ModalText>
            <ModalButtonGroup>
                <Button color="default" onClick={closeModal}>
                    Odrzuć
                </Button>
                <Button
                    color="primary"
                    disabled={loading}
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Usuń Składnik
                </Button>
            </ModalButtonGroup>
        </ModalContentWrapper>
    );
};

const ModalContentWrapper = styled.div`
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    max-width: 600px;
    padding: 32px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    ${mediaQueries.xs} {
        max-width: 80%;
    }
`;

const ModalTitle = styled.h2`
    font-size: 20px;
    margin-bottom: 24px;
    text-align: left;
`;

const ModalText = styled.span`
    font-size: 16px;
    margin-bottom: 32px;
    text-align: left;
`;

const ModalButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    width: 100%;
`;
