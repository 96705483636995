import { useEffect, useState } from 'react';

export const useScrollCondition = (afterScrollPosition: number) => {
    const [condition, setCondition] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            if (scrollPosition > afterScrollPosition) {
                setCondition(true);
            } else {
                setCondition(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return condition;
};
