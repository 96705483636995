import { Button } from '@/componentsV2/molecules/Button/Button';
import styled from '@emotion/styled';
import { ReactElement, ReactNode } from 'react';

interface PopupSection {
    button: {
        action: () => void;
        text: string;
    };
    description: ReactNode;
    icon: ReactElement;
}

export const PopupSection = ({
    button: { text, action },
    description,
    icon,
}: PopupSection) => {
    return (
        <PopupSectionWrapper>
            {icon}
            <Description>{description}</Description>
            <Button onClick={action} variant="black" width="100%">
                {text}
            </Button>
        </PopupSectionWrapper>
    );
};

const PopupSectionWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
    place-items: center;
`;

const Description = styled.p`
    display: grid;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
`;
