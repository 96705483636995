import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/core';
import { colors, s } from '@/style';
import Spinner from '@components/Spinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    center?: boolean;
    children?: ReactNode;
    loading?: boolean;
    variant: ButtonVariants;
    width?: CSSProperties['width'];
}

export type ButtonVariants =
    | 'black'
    | 'gray'
    | 'lightGray'
    | 'greenGradient'
    | 'orangeGradient'
    | 'yellowGradient'
    | 'red'
    | 'yellow'
    | 'turquiose'
    | 'navy'
    | 'redDark'
    | 'green'
    | 'orange';

export const Button = ({
    center,
    children,
    disabled,
    loading,
    type = 'button',
    width,
    ...rest
}: ButtonProps) => {
    return (
        <ButtonElement
            {...rest}
            center={center}
            disabled={loading || disabled}
            loading={loading}
            type={type}
            width={width}
        >
            {loading && (
                <SpinnerWrapper>
                    {/* @ts-expect-error fix TS*/}
                    <Spinner size={1.2} addCss={{ color: colors.white }} />
                </SpinnerWrapper>
            )}
            <ChildrenWrapper $loading={loading}>{children}</ChildrenWrapper>
        </ButtonElement>
    );
};

export const buttonVariants: Record<ButtonVariants, SerializedStyles> = {
    black: css`
        background: ${colors.text};
    `,
    gray: css`
        background: ${colors.gray};
    `,
    lightGray: css`
        background: ${colors.gray400};
    `,
    greenGradient: css`
        background: linear-gradient(
            to right,
            ${colors.green400},
            ${colors.green800}
        );
    `,
    orangeGradient: css`
        background: linear-gradient(
            to right,
            ${colors.orange200},
            ${colors.orange}
        );
    `,
    yellowGradient: css`
        background: linear-gradient(
            to right,
            ${colors.yellow200},
            ${colors.yellow300}
        );
    `,
    red: css`
        background: linear-gradient(
            to right,
            ${colors.red300},
            ${colors.red700}
        );
    `,
    yellow: css`
        background: ${colors.yellow};
    `,
    green: css`
        background: ${colors.green};
    `,
    turquiose: css`
        background: ${colors.turquiose};
    `,
    navy: css`
        background: ${colors.navy};
    `,
    redDark: css`
        background: ${colors.redDark};
    `,
    orange: css`
        background: ${colors.orange};
    `,
};

const ButtonElement = styled.button<{
    center?: boolean;
    loading?: boolean;
    variant: ButtonVariants;
    width?: CSSProperties['width'];
}>`
    ${({ center, variant, width, loading }) => css`
        align-items: center;
        border-radius: 32px;
        border: 0;
        color: ${colors.white};
        cursor: pointer;
        display: flex;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 700;
        height: 46px;
        justify-content: center;
        line-height: 18px;
        opacity: 1;
        outline: none;
        padding: 0 60px;
        position: relative;
        text-transform: uppercase;
        transition: all ease-in-out 0.3s;
        width: ${width ? width : 'auto'};

        ${variant && buttonVariants[variant]};
        ${center && 'margin: 0 auto'};

        &:hover {
            ${s.hover} {
                filter: brightness(1.1);
            }
        }

        &:disabled {
            ${!loading && `background: ${colors.gray};`}
            cursor: default;
            pointer-events: none;
        }
    `}
`;

const SpinnerWrapper = styled.div`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ChildrenWrapper = styled.div<{ $loading?: boolean }>(
    ({ $loading }) => css`
        visibility: ${$loading ? 'hidden' : 'visible'};
    `,
);
