import React, { createContext, useContext } from 'react';

import useDeliverySettings, {
    initDeliverySettings,
} from '../hooks/useDeliverySettings';
import useImages from '../hooks/useImages';
import useOpenningHours, { initOpenningHours } from '../hooks/useOpenningHours';
import useRestaurantInfo, {
    initRestaurantInfo,
} from '../hooks/useRestaurantInfo';

function noop() {}
export const RestaurantContext = createContext({
    // useDeliverySettings
    deliverySettings: initDeliverySettings,
    setDeliverySettings: noop,
    handleDeliverySettings: noop,
    onDeleteDeliveryCost: noop,
    onAddDeliveryCost: noop,

    // useImages
    logoUrl: '',
    setLogoUrl: noop,

    logoFile: null,
    logoPreview: null,
    handleAddLogo: noop,

    backgroundUrl: '',
    setBackgroundUrl: noop,

    backgroundFile: null,
    backgroundPreview: null,
    handleAddBackground: noop,

    // useOpenningHours
    openningHours: initOpenningHours,
    setOpenningHours: noop,
    handleOpenningHours: noop,

    // useRestaurantInfo
    restaurantInfo: initRestaurantInfo,
    setRestaurantInfo: noop,
    handleRestaurantInfo: noop,
    handleChangeAddres: noop,
    handleSelectAddress: noop,
});

export const useRestaurantContext = () => useContext(RestaurantContext);

export default ({ children }) => (
    <RestaurantContext.Provider
        value={{
            ...useDeliverySettings(),
            ...useImages(),
            ...useOpenningHours(),
            ...useRestaurantInfo(),
        }}
    >
        {children}
    </RestaurantContext.Provider>
);
