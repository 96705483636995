import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors } from '@/style';
import { ConsumptionType } from '@graphql/generated';
import { Spinner } from '../Spinner/Spinner';

export interface ButtonOrderingMethodProps {
    active: boolean;
    disabled?: boolean;
    icon: ReactNode;
    loading?: boolean;
    onClick: (variant: ConsumptionType) => void;
    text: string;
    variant: ConsumptionType;
}

export const ButtonOrderingMethod = ({
    active,
    disabled,
    icon,
    loading,
    onClick,
    text,
    variant,
}: ButtonOrderingMethodProps) => {
    return (
        <ButtonOrderingMethodElement
            active={active}
            disabled={disabled}
            onClick={() => onClick(variant)}
        >
            {loading && <Spinner size={24} spinnerVariant="absolute" />}
            <ChildrenWrapper $loading={loading} active={active}>
                {icon}
                {text}
            </ChildrenWrapper>
        </ButtonOrderingMethodElement>
    );
};

const ButtonOrderingMethodElement = styled.button<{ active: boolean }>(
    ({ active }) => css`
        align-items: center;
        background: transparent;
        border-radius: 16px;
        border: 1px solid ${colors.gray700};
        color: ${colors.gray700};
        cursor: pointer;
        display: flex;
        font-size: 11px;
        font-weight: 700;
        justify-content: center;
        height: 68px;
        line-height: 24px;
        outline: none;
        position: relative;
        transition: all ease-in-out 0.3s;
        width: 100%;

        ${active &&
        css`
            color: ${colors.green};
            border: 1px solid ${colors.green};
        `}

        &:hover {
            background: ${colors.bubbles};
            opacity: 0.8;
        }
    `,
);

const ChildrenWrapper = styled.div<{ $loading?: boolean; active: boolean }>(
    ({ $loading, active }) => css`
        align-items: center;
        justify-items: center;
        display: grid;
        visibility: ${$loading ? 'hidden' : 'visible'};
        fill: ${active ? colors.green : colors.gray700};

        svg {
            font-size: 20px;
        }
    `,
);
