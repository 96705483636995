import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuthSignInMutation, useSignUpMutation } from '@graphql/generated';
import { useHistory } from 'react-router-dom';
import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export const storageName = 'ordersNoAuth';

export default () => {
    const [ordersNoAuth, setOrdersNoAuth] = useState([]);
    const setTokens = useAuthService((state) => state.setTokens);
    const [authSignIn] = useAuthSignInMutation();
    const history = useHistory();
    const [authSignUp] = useSignUpMutation();

    const setOrdersToLocalStorage = (data) =>
        localStorage.setItem(storageName, JSON.stringify(data));
    const getOrdersFromLocalStorage = () =>
        JSON.parse(localStorage.getItem(storageName));

    const setToStateAndLocalStorage = (data) => {
        setOrdersToLocalStorage(data);
        setOrdersNoAuth(data);
    };

    const onCreateOrderAddProdNoAuth = (_, __, prodData, restData) => {
        const q = () => Math.random().toString(36).substring(2, 15);
        const orderDummyId = '' + q() + q() + 'new';

        const newOrder = {
            id: orderDummyId,
            restId: restData,
            type: prodData.type,
            guests: 1,
            serveOnTime: prodData.serveOnTime,
            lines: [
                {
                    product: prodData,
                    quantity: 1,
                },
            ],
        };

        setToStateAndLocalStorage([...ordersNoAuth, newOrder]);
    };

    const onUpdateOrderNoAuth = (data, __) => {
        const newOrders = ordersNoAuth.map((order) =>
            order.id === data.id ? data : order,
        );
        setToStateAndLocalStorage(newOrders);
    };

    const onAddProdNoAuth = (data, __, prodData) => {
        const { orderId, prodId } = data;

        const findOrder = ordersNoAuth.find((el) => el.id === orderId);
        if (!findOrder) return console.log("can't find order...");
        const userOrder = { ...findOrder };

        if (!userOrder.lines.length) {
            const { price, name, photoUrl, packagingPrice } = prodData;
            userOrder.lines.push({
                product: { id: prodId, price, name, photoUrl, packagingPrice },
                quantity: 1,
            });
        } else {
            const userOrderProduct = userOrder.lines.find(
                (el) => el.product.id === prodId,
            );
            if (userOrderProduct) {
                userOrderProduct.quantity++;
            } else {
                const { price, name, photoUrl, packagingPrice } = prodData;
                userOrder.lines.push({
                    product: {
                        id: prodId,
                        price,
                        name,
                        photoUrl,
                        packagingPrice,
                    },
                    quantity: 1,
                });
            }
        }

        const newOrders = ordersNoAuth.map((order) =>
            order.id === orderId ? userOrder : order,
        );
        setToStateAndLocalStorage(newOrders);
    };

    const onDeleteProdNoAuth = (data, __) => {
        const { orderId, prodId, isDeleteAllProds } = data;

        const findOrder = ordersNoAuth.find((el) => el.id === orderId);
        if (!findOrder) return console.log("can't find order...");
        const userOrder = { ...findOrder };

        if (isDeleteAllProds) {
            userOrder.lines = userOrder.lines.filter(
                (el) => el.product.id !== prodId,
            );
        } else {
            const userOrderProduct = userOrder.lines.find(
                (el) => el.product.id === prodId,
            );
            if (userOrderProduct.quantity >= 2) {
                userOrderProduct.quantity--;
            } else if (userOrderProduct.quantity === 1) {
                userOrder.lines = userOrder.lines.filter(
                    (el) => el.product.id !== prodId,
                );
            } else {
                console.log('error, wrong number');
            }
        }

        let newOrders;

        if (userOrder.lines.length === 0) {
            // delete order
            newOrders = ordersNoAuth.filter((order) => order.id !== orderId);
        } else {
            newOrders = ordersNoAuth.map((order) =>
                order.id === orderId ? userOrder : order,
            );
        }

        setToStateAndLocalStorage(newOrders);
    };

    const onDeleteOrderNoAuth = (data, __) => {
        const { orderId } = data;
        const newOrders = ordersNoAuth.filter((order) => order.id !== orderId);
        setToStateAndLocalStorage(newOrders);
    };

    const onSendOrdersNoAuthSignup = (data, cb) => {
        const { email, password, name, surname } = data;
        try {
            authSignUp({
                variables: { input: { email, password, name, surname } },
                onCompleted: () => {
                    toast.success(
                        `Na podany adres email wysłaliśmy link do potwierdzenia konta 😎`,
                        {
                            autoClose: false,
                        },
                        history.push('/login'),
                    );
                },
                onError: graphqlErrorHandler(true),
            });
        } catch (error) {
            console.error(error);
            if (typeof cb === 'function') cb(error);
        }
    };

    const onSendOrdersNoAuthLogin = async (data, cb) => {
        const { email, password } = data;

        try {
            const { data } = await authSignIn({
                variables: {
                    input: {
                        email: email.trim(),
                        password: password.trim(),
                    },
                },
            });

            setTokens({
                refreshToken: data?.authSignIn.refreshToken,
                sessionToken: data?.authSignIn.sessionToken,
            });

            if (typeof cb === 'function') cb();
        } catch (err) {
            graphqlErrorHandler({
                customCodeMessages: {
                    VALIDATION_FAILED: 'Email lub hasło nie są poprawne',
                },
            })(err);
            if (typeof cb === 'function') cb(err);
        }
    };

    useEffect(() => {
        const data = getOrdersFromLocalStorage();
        if (data) setOrdersNoAuth(data);
    }, []);

    return {
        ordersNoAuth,
        onCreateOrderAddProdNoAuth,
        onUpdateOrderNoAuth,
        onAddProdNoAuth,
        onDeleteProdNoAuth,
        onDeleteOrderNoAuth,
        setOrdersNoAuth,

        onSendOrdersNoAuthSignup,
        onSendOrdersNoAuthLogin,
    };
};
