import { DeepPartial } from 'src/types/definitions/common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { InAppNotification, InAppNotificationsType } from '@graphql/generated';
import { getUserInAppNotification } from './UserInAppNotifications.helpers';
import { popup } from '@/AppPopups';

export const useUserInAppNotification = () => {
    const history = useHistory();

    const NOTIFICATION_TYPE: Record<
        InAppNotificationsType,
        {
            toast: (notification: DeepPartial<InAppNotification>) => void;
        } | null
    > = {
        ORDER_CREATED: null,
        ORDER_CANCELED: null,
        ORDER_ACCEPTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_ACCEPTED',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_MOVED_TO_READY: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_MOVED_TO_READY',
                );
                toast.success(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_MOVED_TO_PREPARED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_MOVED_TO_READY',
                );
                toast.success(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_MOVED_TO_CLOSED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_MOVED_TO_CLOSED',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_REJECTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_REJECTED',
                );
                toast.error(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        NEW_ORDER_PAYMENT_REQUEST: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'NEW_ORDER_PAYMENT_REQUEST',
                );

                toast.dark(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_PAYMENT_REQUEST_ACCEPTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_PAYMENT_REQUEST_ACCEPTED',
                );

                toast.success(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_PAYMENT_REQUEST_REJECTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_PAYMENT_REQUEST_REJECTED',
                );

                toast.error(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_PRODUCT_ADDED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_PRODUCT_ADDED',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        GROUP_ADDED_TO_GROUP: {
            toast: (notification) => {
                popup.show('YouHaveBeenAddedToGroupPopup', {
                    groupId: notification.group?.id,
                });
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'GROUP_ADDED_TO_GROUP',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_NOTIFY_ARRIVAL: null,
        PAYOFF_ACCEPTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'PAYOFF_ACCEPTED',
                );

                toast.success(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        PAYOFF_REJECTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'PAYOFF_REJECTED',
                );

                toast.error(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        PAYOFF_REQUESTED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'PAYOFF_REQUESTED',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        CART_APPROVAL_REQUEST: {
            toast: (notification) => {
                popup.show('AcceptCartPopup', {
                    cartId: notification.cart?.id,
                });
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'CART_APPROVAL_REQUEST',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_PARTIAL_ACCEPTANCE: {
            toast: (notification) => {
                popup.show('AcceptOrderChangesPopup', {
                    orderId: notification.order?.id,
                });

                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_PARTIAL_ACCEPTANCE',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_TABLE_CHANGED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_TABLE_CHANGED',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_ADDITION: null,
        ORDER_ADDITION_USER_ACCEPTED: null,
        ORDER_ADDITION_USER_REJECTED: null,
        ORDER_ADDITION_WAITER_ACCEPTED: null,
        ORDER_ADDITION_WAITER_REJECTED: null,
        ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE: null,
        ORDER_ADDITION_FROM_USER: null,
        ORDER_ADDITION_FROM_WAITER: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_ADDITION_FROM_WAITER',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_ETA_CHANGED: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_ETA_CHANGED',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        NEW_CHAT_MESSAGE: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'NEW_CHAT_MESSAGE',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        ORDER_MOVED_TO_DELIVERY: {
            toast: (notification) => {
                const { message, redirectUrl } = getUserInAppNotification(
                    notification,
                    'ORDER_MOVED_TO_DELIVERY',
                );
                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                });
            },
        },
        CLEAR_NOTIFICATION_SOUNDS: null,
        ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED: null,
    };
    return {
        NOTIFICATION_TYPE,
    };
};
