import { useEffect, useState } from 'react';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAuthService } from '@/services/AuthService';
import {
    usePushNotificationUserUpdateMutation,
    usePushNotificationWaiterUpdateMutation,
} from '@graphql/generated';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { PushNotifications } from '@capacitor/push-notifications';

export const usePushNotificationInitialize = () => {
    const { isLoggedIn: isUserLoggedIn, fcmToken: fcmTokenUser } =
        useAuthService((state) => state);
    const { isLoggedIn: isWaiterLoggedIn, fcmToken: fcmTokenWaiter } =
        useWaiterService((state) => state);
    const [userUpdate] = usePushNotificationUserUpdateMutation();
    const [waiterUpdate] = usePushNotificationWaiterUpdateMutation();

    const [hasPermissions, setHasPermissions] = useState(false);

    const registerNotifications = async () => {
        try {
            let permStatus = await PushNotifications.checkPermissions();

            if (permStatus.receive === 'prompt') {
                permStatus = await PushNotifications.requestPermissions();
            }

            if (permStatus.receive !== 'granted') {
                throw new Error('User denied permissions!');
            }
            await PushNotifications.register();

            setHasPermissions(true);
        } catch (error) {
            setHasPermissions(false);
            console.error(error);
        }
    };

    useEffect(() => {
        registerNotifications();
    }, []);

    useEffect(() => {
        if (isUserLoggedIn && fcmTokenUser) {
            userUpdate({
                variables: {
                    input: {
                        payload: {
                            fcmToken: fcmTokenUser,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
        }
        if (isWaiterLoggedIn && fcmTokenWaiter) {
            waiterUpdate({
                context: { waiter: true },
                variables: {
                    input: {
                        payload: {
                            fcmToken: fcmTokenWaiter,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
        }
    }, [isUserLoggedIn, isWaiterLoggedIn, fcmTokenUser, fcmTokenWaiter]);

    return {
        hasPermissions,
    };
};
