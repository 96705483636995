/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Navbar from './Navbar';
import TopNavFixed from '../../components/TopNavFixed/TopNavFixed';
import { sMain } from '../../EZC/components/Layout/Layout.component';

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <TopNavFixed />
            <main css={sMain}>{children}</main>
            <Navbar />
        </React.Fragment>
    );
};

export default Layout;
