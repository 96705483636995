import React from 'react';
import { useRoutesEzc } from '../../../routes';
import { OrderInCartByRestaurantIdProvider } from '../../context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider';
import NoAuthOrdersContextProvider from '../../context/NoAuthOrders/NoAuthOrders.provider';
import { ActiveOrdersListenerProvider } from '../../context/ActiveOrdersListener/ActiveOrdersListener.context';

import Layout from '../../components/Layout/Layout.component';
import { useAuthService } from '@/services/AuthService';
import { UserPollingProvider } from '@/EZC/services/UserPollingService/UserPollingProvider';
import { useFirebaseChatServiceLogin } from '@/services/FirebaseChatService/useFirebaseChatServiceLogin';

export default () => {
    useFirebaseChatServiceLogin();

    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const routes = useRoutesEzc(isLoggedIn);

    return (
        <NoAuthOrdersContextProvider>
            <OrderInCartByRestaurantIdProvider>
                {/* //TODO EAT-333 add proper ActiveOrdersListener */}
                <ActiveOrdersListenerProvider isAuth={isLoggedIn}>
                    <Layout>{routes}</Layout>
                    {isLoggedIn && <UserPollingProvider />}
                </ActiveOrdersListenerProvider>
            </OrderInCartByRestaurantIdProvider>
        </NoAuthOrdersContextProvider>
    );
};
