import React, { useEffect } from 'react';
import { ButtonBorder } from '@components/Elements';
import { ChipPreferencesAccordion } from '@/EZC/domains/Account/organisms/ChipPreferencesAccordion/ChipPreferencesAccordion';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { RangePreferencesAccordion } from '@/EZC/domains/Account/organisms/RangePreferencesAccordion/RangePreferencesAccordion';
import { sGrid, sOuter } from '@style/style';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import Headline from '@components/Headline/Headline';
import PageLoader from '@components/PageLoader/PageLoader';
import { BlockOutlined } from '@material-ui/icons';
import useFoodPreferences from './useFoodPreferences';
import { useFoodPreferencesData } from './useFoodPreferencesChips';
import { SPICINESS_NAMES } from '@constants/spicinessLevels';
import styled from '@emotion/styled';
import { mediaQueries, mediaQueriesMaterial } from '@style/breakpoints';

export const FoodPreferences = () => {
    const {
        allergies,
        ingredients,
        handleResetUserPreferences,
        handleUpdateUserPreferences,
        loading,
        personal,
        preferences,
        updateLoading,
    } = useFoodPreferences();

    const {
        allergiesChips,
        cuisineChips,
        dietChips,
        flavorChips,
        ingredientsChips,
        spicinessRange,
    } = useFoodPreferencesData({
        allergiesData: allergies.data?.list.data,
        ingredientsData: ingredients.data?.list.data,
        preferences,
    });

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    if (loading) return <PageLoader />;

    return (
        <>
            {/* @ts-expect-error migrate to TS */}
            <TopNav
                title={'Preferencje żywieniowe'}
                subtitle={`- ${personal?.name} ${personal?.surname}`}
            />
            <div
                /* @ts-expect-error migrate to TS */
                css={sOuter}
            >
                {/* @ts-expect-error migrate to TS */}
                <Headline
                    itemChildren={
                        /* @ts-expect-error migrate to TS */
                        <ButtonBorder action={handleResetUserPreferences}>
                            Resetuj
                        </ButtonBorder>
                    }
                    extraCss={{ flexWrap: 'unset', alignItems: 'start' }}
                >
                    Preferencje żywieniowe
                </Headline>

                <ContainerGrid container css={sGrid} spacing={isMd ? 3 : 0}>
                    <Grid item xs={12} md={6}>
                        <ChipPreferencesAccordion
                            chips={dietChips}
                            chipSteps={['add', 'default']}
                            description="Wybierz typ diety, która odpowiada Tobie najbardziej."
                            loading={loading}
                            loadingSave={updateLoading}
                            onSave={(value, callback) =>
                                handleUpdateUserPreferences({
                                    name: 'diet',
                                    value,
                                    callback,
                                })
                            }
                            title="Preferowana dieta"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ChipPreferencesAccordion
                            chips={cuisineChips}
                            description="Wybierz kuchnię, którą lubisz najbardziej i zablokuj niechcianą."
                            loading={loading}
                            loadingSave={updateLoading}
                            onSave={(value, callback) =>
                                handleUpdateUserPreferences({
                                    name: 'cuisine',
                                    value,
                                    callback,
                                })
                            }
                            title="Kuchnia"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ChipPreferencesAccordion
                            chips={flavorChips}
                            description="Zaznacz smaki, które preferujesz i których nie lubisz."
                            loading={loading}
                            loadingSave={updateLoading}
                            onSave={(value, callback) =>
                                handleUpdateUserPreferences({
                                    name: 'flavor',
                                    value,
                                    callback,
                                })
                            }
                            title="Smak"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ChipPreferencesAccordion
                            chips={ingredientsChips}
                            chipSteps={['default', 'add', 'remove']}
                            description="Zaznacz składniki, które preferujesz i których nie lubisz."
                            loading={loading || ingredients.loading}
                            loadingSave={updateLoading}
                            onSave={(value, callback) =>
                                handleUpdateUserPreferences({
                                    name: 'ingredients',
                                    value,
                                    callback,
                                })
                            }
                            searchProps={{
                                onSearch: ingredients.setIngredientsInput,
                                placeholder: 'Wyszukaj składnik',
                                value: ingredients.ingredientsInput,
                            }}
                            title="Składniki"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ChipPreferencesAccordion
                            chips={allergiesChips}
                            chipSteps={['default', 'remove']}
                            description="Zaznacz składniki, na które masz uczulenie."
                            loading={loading || allergies.loading}
                            loadingSave={updateLoading}
                            onSave={(value, callback) =>
                                handleUpdateUserPreferences({
                                    callback,
                                    isAllergies: true,
                                    name: 'allergies',
                                    value,
                                })
                            }
                            searchProps={{
                                onSearch: allergies.setAllergiesInput,
                                placeholder: 'Wyszukaj składnik',
                                value: allergies.allergiesInput,
                            }}
                            title="Alergie"
                            icon={<BlockOutlined color="error" />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RangePreferencesAccordion
                            description="Wybierz stopień ostrości Twoich dań."
                            loading={loading}
                            loadingSave={updateLoading}
                            onSave={(value, callback) =>
                                handleUpdateUserPreferences({
                                    name: 'spiciness',
                                    value,
                                    callback,
                                })
                            }
                            title="Skala ostrości"
                            range={spicinessRange}
                            rangeLabels={SPICINESS_NAMES}
                        />
                    </Grid>
                </ContainerGrid>
            </div>
        </>
    );
};

const ContainerGrid = styled(Grid)`
    margin: 0;
    max-width: 100%;

    ${mediaQueriesMaterial.sm} {
        gap: 16px;
    }
`;
