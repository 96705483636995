import { popup } from '@/AppPopups';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    OPERATIONS,
    useProductRateAcceptMutation,
    useProductRateRejectMutation,
    useProductRequestsPageLazyQuery,
    useUserAvailableCoinsQuery,
} from '@graphql/generated';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { priceFormat } from '@helpers/priceFormat';
import { toast } from 'react-toastify';
import { WalletChargePopupProps } from '@/EZC/domains/Account/organisms/WalletChargePopup/WalletChargePopup';

export const useProductRequestsPage = () => {
    const [productRequests, { data, loading }] =
        useProductRequestsPageLazyQuery();

    const { data: userData } = useUserAvailableCoinsQuery();
    const userAvailableCoins = Math.floor(userData?.user?.coinsAvailable ?? 0);

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            productRequests({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        isRecipient: true,
                        isRequester: false,
                        statuses: ['PENDING', 'ACCEPTED', 'REJECTED'],
                    },
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.list.metadata,
    });

    const [paymentReject, { loading: paymentRejectLoading }] =
        useProductRateRejectMutation();

    const [paymentAccept, { loading: paymentAcceptLoading }] =
        useProductRateAcceptMutation();

    const handlePaymentReject = (payoffId: string) => {
        paymentReject({
            variables: {
                input: {
                    payoffId,
                },
            },
            onCompleted: () => {
                toast.error('Płatność odrzucona');
            },
            onError: graphqlErrorHandler(true),
            refetchQueries: [OPERATIONS.Query.UnsettledPayoffs],
        });
    };

    const handlePaymentAccept = (payoffId: string, productPrice: string) => {
        const amount = parseFloat(priceFormat.standardPrice(productPrice));

        paymentAccept({
            variables: {
                input: {
                    payoffId,
                },
            },
            onCompleted: () => {
                toast.success('Płatność rozliczona 👍');
            },
            onError: (error) => {
                graphqlErrorHandler({
                    callbackOnErrorCode: {
                        NOT_ENOUGH_COINS_ERROR_CODE: () =>
                            setTimeout(() => {
                                popup.show('WalletChargePopup', {
                                    defaultValue: Math.ceil(
                                        amount - userAvailableCoins,
                                    ).toString(),
                                    chargeTitle: `Rozlicz płatność`,
                                    chargeDescription:
                                        'Przelej środki, aby rozliczyć płatność',
                                    popupTitle: 'Rozlicz płatność',
                                    popupDescription:
                                        'Obecnie nie masz wystarczającej ilości środków, aby zrealizować rozliczenie',
                                } as WalletChargePopupProps);
                            }, 1500),
                    },
                })(error);
            },
            refetchQueries: [
                OPERATIONS.Query.UnsettledPayoffs,
                OPERATIONS.Query.UserAvailableCoins,
            ],
        });
    };

    return {
        data,
        getMoreData,
        handlePaymentAccept,
        handlePaymentReject,
        hasMore,
        loading,
        paymentAcceptLoading,
        paymentRejectLoading,
    };
};
