import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { transparentize } from 'polished';
import { EatzonIcon as Icon } from '@/svgs/EatzonIcon';

interface EatzonIconProps {
    isInactive: boolean;
    size: number;
    space: number;
    position?:
        | 'default'
        | 'absoluteLeft'
        | 'absoluteRight'
        | 'restaurantAbsoluteLeft';
}

export const EatzonIcon = ({
    isInactive,
    size,
    space,
    position = 'default',
}: EatzonIconProps) => {
    return (
        <EatzonLogo
            isInactive={isInactive}
            size={size}
            position={position}
            space={space}
        >
            <Icon color={colors.white} size={`${size}px`} />
        </EatzonLogo>
    );
};

const EatzonLogo = styled.div<
    Pick<EatzonIconProps, 'size' | 'space' | 'isInactive' | 'position'>
>(
    ({ isInactive, size, space, position }) => css`
        background-color: ${isInactive
            ? transparentize(0.5, colors.gray600)
            : colors.orange};
        border-radius: 50%;
        align-items: center;
        display: flex;
        height: calc(${size}px + ${space}px);
        justify-content: center;
        width: calc(${size}px + ${space}px);
        will-change: transform;
        z-index: 9;

        ${position === 'absoluteRight' &&
        css`
            right: 10px;
            position: absolute;
            top: 10px;
        `}

        ${position === 'absoluteLeft' &&
        css`
            left: 10px;
            position: absolute;
            top: 10px;
        `}

        ${position === 'restaurantAbsoluteLeft' &&
        css`
            left: 14px;
            position: absolute;
            top: 14px;
        `}
    `,
);
