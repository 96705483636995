import React, { useState } from 'react';
import Spinner from '../../../components/Spinner';
import { useApolloClient } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { Input } from '../../../EZC/views/Auth/components';
import { s } from '../../../style';
import {
    sBottomLinks,
    sContainerOuter,
    sDescription,
    sGrid,
    sOuter,
    sPaddingTop,
} from '../../../style/style';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import Headline from '../../../components/Headline/Headline';
import { ButtonBorder, Button } from '../../../components/Elements';
import { toast } from 'react-toastify';
import { useWaiterSignInMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import Navbar from '@/EZW/components/Navbar';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { IOS } from '@constants/common.constants';

const initValues = {
    login: '',
    password: '',
};

const LoginPage = () => {
    const [inputValues, setInputValues] = useState(initValues);
    const [showPassword, setShowPassword] = useState(false);
    const client = useApolloClient();
    const setTokens = useWaiterService((state) => state.setTokens);

    const [waiterSignIn, { loading }] = useWaiterSignInMutation();

    const onChange = (e) =>
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });

    const onSubmit = async () => {
        waiterSignIn({
            variables: {
                input: inputValues,
            },

            onCompleted: async ({ tokens: { refreshToken, sessionToken } }) => {
                await client.resetStore();

                setTokens({ refreshToken, sessionToken });

                toast.dark('Witaj w Panelu Kelnera Eatzon! ✋');

                if (IOS) {
                    SavePassword.promptDialog({
                        username: inputValues.login,
                        password: inputValues.password,
                    });
                }
            },
            onError: graphqlErrorHandler({
                customCodeMessages: {
                    VALIDATION_FAILED: 'Login lub hasło nie są poprawne',
                },
            }),
        });
    };

    const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit();

    return (
        <React.Fragment>
            <TopNav title={'Logowanie'} subtitle=" - Kelner" offset={60} />
            <div css={sOuter}>
                <Headline
                    extraCss={{ color: '#FF8000' }}
                    // itemChildren={<ButtonBorder link={`/account`}>Klient</ButtonBorder>}
                >
                    Panel Kelnera
                </Headline>
                <Grid container css={sGrid}>
                    <p css={sDescription}>
                        Zaloguj się do konta kelnera Eatzon i obsługuj klientów
                        restauracji z łatwością
                    </p>
                </Grid>
            </div>

            <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
                <div css={[sFormContainer]}>
                    <Input
                        onChange={onChange}
                        name="login"
                        type="text"
                        value={inputValues.login}
                        placeholder="Login"
                        onKeyUp={onEnterSubmit}
                        autocomplete="username"
                    />

                    <Input
                        onChange={onChange}
                        name="password"
                        value={inputValues.password}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Hasło"
                        onKeyUp={onEnterSubmit}
                        sInputExtra={{ paddingRight: '50px' }}
                        autocomplete="current-password"
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                    />

                    <Button w100 action={loading ? () => {} : onSubmit}>
                        {loading ? (
                            <Spinner size={1.5} addCss={{ color: '#fff' }} />
                        ) : (
                            'Zaloguj'
                        )}
                    </Button>
                </div>
                <Grid
                    item
                    xs={12}
                    css={[
                        sBottomLinks,
                        { [s.sm_down]: { justifyContent: 'flex-end' } },
                    ]}
                >
                    <ButtonBorder link="/login">
                        Panel Restauratora
                    </ButtonBorder>
                    <ButtonBorder link="/account">Panel Klienta</ButtonBorder>
                </Grid>
            </Grid>
            <Navbar />
        </React.Fragment>
    );
};

const sFormContainer = {
    [s.sm_down]: { paddingBottom: '2rem' },
    paddingBottom: '1.5rem',
};

export default LoginPage;
