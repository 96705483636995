/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom';
import { colors, s, alpha } from '@style';
import { Bullets, StatusLarge } from '@components/Elements';
import useDeliveryInfo from '@/EZC/hooks/useDeliveryInfo';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';
import { RestaurantAvatar } from '@/svgs/RestaurantAvatar';
import { EatzonIcon } from '@/componentsV2/atoms/EatzonIcon/EatzonIcon';

const RestItemLarge = ({
    data,
    id,
    last,
    searchView,
    restView,
    restViewActive,
    removeMargin,
}) => {
    const {
        address,
        avatarUrl,
        description,
        distance: distCalculated = 0,
        isActive,
        name,
        photoUrl,
        isBrochure,
    } = data;

    const addressShrinked = address
        ? address
              .split(',')
              .filter((_, id) => id < 2)
              .reverse()
        : address;
    const distance = (distCalculated / 1000).toFixed(1) + ' km';

    const details = [].concat(distance, addressShrinked);

    return (
        <React.Fragment>
            <div css={[{ scrollSnapAlign: 'start', position: 'relative' }]}>
                <Wrapper
                    restView={restView}
                    to={`/restaurant/${data?.slug}`}
                    extraCss={[
                        sContainer,
                        id === 0 && {
                            [s.sm_down]: { marginLeft: '1.5rem' },
                        },
                        id === 0 &&
                            removeMargin && {
                                marginLeft: '0',
                                [s.sm_down]: { marginLeft: '0' },
                            },
                        searchView && sContainerSearchView,
                        restView && sContainerRestView(id % 2 === 0),
                        !isActive && { opacity: 0.3 },
                    ]}
                >
                    <div
                        css={[
                            sImageContainer,
                            !photoUrl && {
                                background: `linear-gradient(to bottom right, ${colors.gray200}, ${colors.gray600})`,
                                borderRadius: 'inherit',
                                height: '100%',
                            },
                        ]}
                    >
                        <EatzonIcon
                            isInactive={isBrochure}
                            size={10}
                            position="restaurantAbsoluteLeft"
                            space={4}
                        />
                        {photoUrl && (
                            <img
                                className="image-scale"
                                src={photoUrl}
                                alt={name}
                                loading="lazy"
                            />
                        )}
                    </div>
                    <div css={sTextContainer}>
                        {photoUrl && (
                            <img
                                css={[
                                    sImageContainer.img,
                                    {
                                        objectPosition: 'center bottom',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        borderBottomLeftRadius: 'inherit',
                                        borderBottomRightRadius: 'inherit',
                                    },
                                ]}
                                src={photoUrl}
                                alt={name}
                                loading="lazy"
                            />
                        )}
                        <div
                            css={{
                                position: 'relative',
                                background: `linear-gradient(to bottom, ${colors.text.concat(
                                    alpha[16],
                                )}, ${colors.text.concat(alpha[80])})`,
                                height: '100%',
                                backdropFilter: 'blur(12px)',
                                borderBottomLeftRadius: 'inherit',
                                borderBottomRightRadius: 'inherit',
                                padding: '0.75rem 1.5rem',
                            }}
                        >
                            <h3 css={sTextContainer.title}>{name}</h3>
                            <p css={sTextContainer.categories}>{description}</p>
                            <div css={sTextContainer.details}>
                                {(distCalculated === null ||
                                    distCalculated === undefined) && (
                                    <GetDistanceToUser restId={data?.id} />
                                )}
                                <Bullets array={details} />
                            </div>
                        </div>
                    </div>
                    {avatarUrl ? (
                        <img
                            className="image-scale-down"
                            src={avatarUrl}
                            loading="lazy"
                            alt={name}
                            css={[
                                {
                                    transition: 'transform 0.3s ease-out',
                                    position: 'absolute',
                                    right: 12,
                                    top: 12,
                                    width: 64,
                                    height: 64,
                                    borderRadius: 32,
                                    zIndex: 3,
                                },
                            ]}
                        />
                    ) : (
                        <AvatarWrapper>
                            <RestaurantAvatar />
                        </AvatarWrapper>
                    )}
                </Wrapper>
                <StatusWrapper id={id}>
                    {restViewActive && (
                        <StatusLarge color={colors.orange}>Wybrana</StatusLarge>
                    )}
                    {isActive === false && (
                        <StatusLarge color={colors.red600}>
                            Nieaktywna
                        </StatusLarge>
                    )}
                </StatusWrapper>
            </div>
            {last === 1 && <div css={sLast} />}
        </React.Fragment>
    );
};

const Wrapper = ({ restView, extraCss, to, children }) =>
    restView ? (
        <div css={extraCss}>{children}</div>
    ) : (
        <Link to={to} css={extraCss}>
            {children}
        </Link>
    );

const GetDistanceToUser = ({ restId }) => {
    const { distanceToUser } = useDeliveryInfo({ restId });

    return (
        <React.Fragment>{(distanceToUser / 1000).toFixed(2)} km</React.Fragment>
    );
};

const StatusWrapper = styled.div(
    ({ id }) => css`
        display: flex;
        gap: 4px;
        left: ${parseInt(id) % 2 === 0 ? '40px' : '56px'};
        position: absolute;
        top: 12px;
        z-index: 3;

        ${mediaQueries.xs} {
            left: 38px;
        }
    `,
);

const AvatarWrapper = styled.div`
    height: 64px;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 64px;
    z-index: 3;
`;

const sContainer = {
    width: 352,
    height: 212,
    backgroundColor: colors.gray200,
    borderRadius: 24,
    flexShrink: 0,
    marginLeft: 12,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',

    '&:hover': {
        '.image-scale': { transform: 'scale(1.1)', filter: 'brightness(1.2)' },
        '.image-scale-down': { transform: 'scale(0.9)' },
    },
    [s.xs]: {
        width: 'calc(100vw - 72px)',
        height: 'calc(9 * calc(100vw - 72px) / 16)',
    },
};

const sContainerSearchView = {
    margin: '0 1rem 1rem 0',
    [s.xs]: {
        width: 'calc(100vw - 3rem)',
        margin: '0 0 1rem',
    },
};

const sContainerRestView = (odd) => ({
    // pointerEvents: 'none',
    margin: 0,
    width: '100%',
    [s.md]: {
        margin: odd ? '0 1.5rem 0 0' : '0 0 0 1rem',
        width: odd ? 'calc(100% - 0.75rem)' : 'calc(100% - 0.75rem)',
    },
    [s.xs]: {
        width: '100%',
        margin: 0,
    },
});

const sImageContainer = {
    width: '100%',
    height: 120,
    position: 'relative',
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    overflow: 'hidden',
    willChange: 'transform',
    [s.xs]: {
        height: 'calc(calc(10 * calc(100vw - 72px) / 16) * 0.66)',
    },
    img: {
        transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
    },
};

const sTextContainer = {
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    position: 'absolute',
    overflow: 'hidden',

    left: 0,
    bottom: 0,
    zIndex: 2,
    // background: `linear-gradient(to bottom, ${colors.white.concat(
    //   alpha[60]
    // )}, ${colors.gray200.concat(alpha[60])})`,

    width: '100%',
    height: 92,
    color: colors.white,

    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        fontWeight: 700,
        fontSize: '18px',
        letterSpacing: '-0.025em',
    },

    categories: {
        marginTop: 2,
        opacity: 0.6,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        fontSize: '13px',
    },

    details: {
        opacity: 0.4,
        fontSize: '12px',
        marginTop: '0.5rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
    },
};

const sLast = {
    display: 'flex',
    flexShrink: 0,
    width: 364,
    height: 1,
    [s.xs]: { width: 60 },
};

export default RestItemLarge;
