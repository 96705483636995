import { globals } from './config/globals';
import { colors } from './config/colors';
import { s } from './constants/sizes';
import { alpha } from './constants/alpha';

export const sOuter = {
    position: 'relative',
    zIndex: 1,
    width: '100vw',
    maxWidth: globals.maxWidthMedium,
    margin: '0 auto',
    marginTop: globals.topnavHeight.mobile,
    [s.md]: { marginTop: globals.topnavHeight.desktop + 64 },
};

export const sOuterPadding = {
    position: 'relative',
    zIndex: 1,
    width: '100vw',
    maxWidth: globals.maxWidthMedium,
    margin: '0 auto',
    paddingTop: globals.topnavHeight.mobile,
    [s.md]: { paddingTop: globals.topnavHeight.desktop + 64 },
};

export const sGrid = {
    padding: '0 1.5rem',
    [s.md]: { padding: '0 2rem' },
};

export const sGridSpacing = {
    '.MuiGrid-item': {
        [s.xs]: { paddingBottom: '4px !important' },
    },
};

export const sDescription = {
    color: colors.text.concat(alpha[30]),
    lineHeight: '1.618em',
    fontSize: '17px',
    [s.sm_down]: { paddingBottom: '1rem' },
    [s.md]: { paddingBottom: '1.5rem' },
};

export const sContainerOuter = {
    width: '100vw',
    maxWidth: globals.maxWidthMedium,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
};

export const sPaddingTop = {
    [s.sm_down]: {
        paddingTop: '1rem',
    },
    [s.md]: {
        paddingTop: '1.5rem',
    },
};

export const sNoMarginTop = {
    [s.sm_down]: {
        marginTop: 0,
    },
    [s.md]: {
        marginTop: 0,
    },
};

export const sNoPaddingTop = {
    [s.sm_down]: {
        paddingTop: 0,
    },
    [s.md]: {
        paddingTop: 0,
    },
};

export const sPaddingBottom = {
    [s.sm_down]: {
        paddingBottom: '1rem',
    },
    [s.md]: {
        paddingBottom: '1.5rem',
    },
};

export const sPaddingBottom2 = {
    [s.sm_down]: {
        paddingBottom: '4rem',
    },
    [s.md]: {
        paddingBottom: '4rem',
    },
};

export const sBottomLinks = {
    display: 'flex',
    flexWrap: 'wrap',
    [s.sm_down]: {
        // padding: '0.5rem 0',
        padding: '1rem 0',
    },
    [s.md]: {
        padding: '1.5rem 0',
    },
    gap: 16,
};
