/* eslint-disable prefer-spread */
import { FieldFunctionOptions, FieldPolicy } from '@apollo/client';
import { PaginationMetadata } from '@graphql/generated';

type KeyArgs = FieldPolicy<any>['keyArgs'];

type BackendData = { data: unknown[]; metadata?: PaginationMetadata };

// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
export function showMorePagination(
    keyArgs: KeyArgs = false,
): FieldPolicy<BackendData> {
    return {
        keyArgs,
        // @ts-expect-error FIXME
        merge(
            existing?: Readonly<BackendData>,
            incoming?: Readonly<BackendData>,
            options?: FieldFunctionOptions<
                Record<string, any>,
                Record<string, any>
            >,
        ) {
            const incomingData = incoming?.data ?? [];
            const existingData = existing?.data ?? [];
            const mergedData = existingData ? existingData.slice(0) : [];

            const page = options?.args?.pagination?.page ?? 1;
            const pageSize = options?.args?.pagination?.pageSize ?? 10;
            const offset = (page - 1) * pageSize;

            if (incomingData) {
                if (options?.args) {
                    for (let i = 0; i < incomingData.length; ++i) {
                        mergedData[offset + i] = incomingData[i];
                    }
                } else {
                    // It's unusual (probably a mistake) for a paginated field not
                    // to receive any arguments, so you might prefer to throw an
                    // exception here, instead of recovering by appending incomingData
                    // onto the existing array.

                    mergedData.push.apply(mergedData, incomingData);
                }
            }
            return {
                data: mergedData,
                metadata: {
                    ...(existing?.metadata ?? {}),
                    ...(incoming?.metadata ?? {}),
                },
            };
        },
        read(
            existing?: Readonly<BackendData>,
            options?: FieldFunctionOptions<
                Record<string, any>,
                Record<string, any>
            >,
        ) {
            if (existing) {
                const page = options?.args?.pagination?.page ?? 1;
                const pageSize = options?.args?.pagination?.pageSize ?? 10;

                return {
                    ...existing,
                    data: existing.data.slice(0, page * pageSize),
                };
            }
        },
    };
}
