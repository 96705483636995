import { useEffect } from 'react';

const DisableNumInputScroll = () => {
    const handleWheel = (event: WheelEvent) => {
        if (
            event.target instanceof HTMLInputElement &&
            event.target.type === 'number'
        ) {
            event.preventDefault();
        }
    };
    useEffect(() => {
        document.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return null;
};

export default DisableNumInputScroll;
