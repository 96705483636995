import { ReactNode } from 'react';
import HistoryIcon from '@material-ui/icons/History';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { ProductLabel } from '@graphql/generated';

type ExtendedProductLabel = ProductLabel | 'NOTSET';

export const PRODUCT_LABEL_ORDER: Record<number, ExtendedProductLabel> = {
    1: 'PENDING',
    2: 'PREPARING',
    3: 'KITCHEN',
    4: 'BAR',
    5: 'READY',
    6: 'HANDED_OUT',
    7: 'CANCELED',
    8: 'NOTSET',
};

export const PRODUCT_LABEL: Record<
    ExtendedProductLabel,
    { color: string; icon: ReactNode; name: string }
> = {
    PENDING: {
        color: '#FFD500',
        icon: <HistoryIcon fontSize="inherit" />,
        name: 'Oczekuje',
    },
    PREPARING: {
        color: '#FF8000',
        icon: <HistoryIcon fontSize="inherit" />,
        name: 'W przygotowaniu',
    },
    KITCHEN: {
        color: '#FF8000',
        icon: <HistoryIcon fontSize="inherit" />,
        name: 'Kuchnia',
    },
    BAR: {
        color: '#FF8000',
        icon: <HistoryIcon fontSize="inherit" />,
        name: 'Bar',
    },
    READY: {
        color: '#0D9488',
        icon: <DoneIcon fontSize="inherit" />,
        name: 'Przygotowane',
    },
    HANDED_OUT: {
        color: '#00B30F',
        icon: <DoneAllIcon fontSize="inherit" />,
        name: 'Wydane',
    },
    CANCELED: {
        color: '#800000',
        icon: <CancelOutlinedIcon fontSize="inherit" />,
        name: 'Odrzucone',
    },
    NOTSET: {
        color: '#E4E6EA',
        icon: null,
        name: 'Wybierz status',
    },
};

export const PRODUCT_LABEL_NAME: Partial<Record<ExtendedProductLabel, string>> =
    Object.entries(PRODUCT_LABEL).reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
        {},
    );

export const PRODUCT_LABEL_NAME_VALUES = Object.entries(PRODUCT_LABEL_NAME).map(
    ([id, name]) => ({ id, name }),
) as {
    id: ExtendedProductLabel;
    name: string;
}[];
