import { useEffect } from 'react';
import jingielLong from '@/assets/sounds/waiter_order_new.mp3';

/**
 * added due to browser policy regarding audio playback
 */
export const usePossibilityToPlaySounds = () => {
    useEffect(() => {
        const handleClick = () => {
            const sound = new Audio(jingielLong);
            sound.muted = true;
            sound
                .play()
                .then(() => {})
                .catch((error) => {
                    console.error(
                        'Wystąpił błąd podczas odtwarzania pliku audio:',
                        error,
                    );
                });
        };

        window.addEventListener('click', handleClick, { once: true });

        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);
};
