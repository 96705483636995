import { useFirstRestaurantQuery } from '@graphql/generated';
import { useEffect } from 'react';
import { useAuthService } from '@/services/AuthService';

export const useCheckActiveRestaurant = () => {
    const { restaurantId, setRestaurantId, isLoggedIn } = useAuthService(
        (state) => state,
    );

    const { data, loading } = useFirstRestaurantQuery({
        skip: !!restaurantId,
        fetchPolicy: 'no-cache',
    });

    useEffect(() => {
        if (
            data?.restaurator?.restaurants?.length &&
            !restaurantId &&
            isLoggedIn
        ) {
            setRestaurantId(data?.restaurator?.restaurants[0].id);
        }
    }, [data, isLoggedIn]);

    return { loading };
};
