import { s, colors } from '../index';

export const globals = {
    maxWidthMedium: 1280 + 170 + 30,
    maxWidthMediumCarousel: 1456,
    topnavHeight: {
        mobile: 88,
        desktop: 80,
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sIcon: {
        width: 32,
        height: 32,
        [s.md]: {
            width: 40,
            height: 40,
        },
        borderRadius: 20,
        fontSize: 30,
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s ease-out',
        '&:hover': {
            backgroundColor: colors.gray300,
        },
    },
};

// 1280 + 170 + 30
