/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
// import { breakpoint } from '../../../constants/style'

const CategoryList = ({ categories, small, blackTheme, opacity }) => {
    if (!categories) return null;
    return (
        <ul
            css={[
                styles,
                blackTheme
                    ? {
                          li: {
                              color: '#fff',
                              opacity: '.6',
                              span: { backgroundColor: '#ffffff99' },
                          },
                      }
                    : {},
                small ? { margin: 0, li: { fontSize: 13 } } : {},
                opacity ? { li: { opacity } } : {},
            ]}
        >
            {categories.map(({ style, name, Component, type }, index) => {
                if (type === 'DAYTIME' || type === 'DIET' || type === 'CUISINE')
                    return null;
                if (Component)
                    return <LiContainer key={index}>{Component}</LiContainer>;
                return (
                    <LiContainer key={index} style={style ? style : {}}>
                        {name}
                    </LiContainer>
                );
            })}
        </ul>
    );
};

const LiContainer = ({ children, ...otherProps }) => (
    <li {...otherProps}>
        {children}
        <span />
    </li>
);

const styles = {
    padding: 0,
    margin: '8px 0',

    li: {
        listStyleType: 'none',
        display: 'inline-block',
        marginRight: '1rem',
        position: 'relative',
        fontWeight: 700,
        fontSize: '15px',
        opacity: '.3',
        color: '#292929',
        // letterSpacing: '-.5px',

        span: {
            display: 'inline-block',
            position: 'absolute',
            right: '-10px',
            top: '50%',
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: '#29292980',
        },

        '&:last-of-type': {
            span: {
                display: 'none',
            },
        },
    },
};

export default CategoryList;
