import styled from '@emotion/styled';
import { RangeBlock } from '@/EZC/domains/Account/molecules/RangeBlock/RangeBlock';
import {
    PreferencesBlock,
    PreferencesBlockProps,
} from '@/EZC/domains/Account/molecules/PreferencesBlock/PreferencesBlock';
import { RangeSliderProps } from '@/componentsV2/molecules/RangeSlider/RangeSlider';

export interface RangePreferencesBlockProps
    extends Omit<PreferencesBlockProps, 'children'>,
        Pick<RangeSliderProps, 'value' | 'onChange' | 'isDirty'> {
    loading?: boolean;
}

export const RangePreferencesBlock = ({
    isDirty,
    loading,
    onChange,
    value,
    ...props
}: RangePreferencesBlockProps) => {
    return (
        <PreferencesBlock {...props}>
            <RangeWrapper>
                <RangeBlock
                    loading={loading}
                    spicinessRangeProps={{
                        onChange,
                        value,
                        isDirty,
                    }}
                />
            </RangeWrapper>
        </PreferencesBlock>
    );
};

const RangeWrapper = styled.div`
    margin: 24px 0;
`;
