import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { usePaymentStartMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { MOBILE } from '@constants/common.constants';
import { useHandleMobileTransaction } from '@hooks/useHandleMobileTransaction';

const usePayment = ({ defaultValue, mobileTransactionFinishedCallback }) => {
    const [amount, setAmount] = useState(defaultValue || '');
    const [paymentStart, { loading }] = usePaymentStartMutation();

    useEffect(() => {
        onValueChange(defaultValue);
    }, [defaultValue]);

    function onValueChange(e) {
        const val = e && e.target ? e.target.value : e; // use this function with different args
        setAmount(val);
    }

    const { openBrowser } = useHandleMobileTransaction(
        mobileTransactionFinishedCallback,
    );

    async function onSubmit() {
        const parsedAmount = parseFloat(amount);

        if (!parsedAmount)
            return toast.error('W celu doładowania konta należy podać kwotę');
        else if (typeof parsedAmount !== 'number')
            return toast.error('Kwota doładowania musi być liczbą');
        else if (parsedAmount < 1)
            return toast.error(
                'Kwota doładowania musi być większa od 1 złotych',
            );
        else if (countDecimals(parsedAmount) > 2)
            return toast.error(
                'Nieprawidłowa liczba. Za dużo cyfr po przycinku.',
            );
        else
            paymentStart({
                variables: {
                    input: {
                        amount: parsedAmount,
                        continueUrl: `${MOBILE ? '' : window.location.href}`,
                    },
                },
                onCompleted: ({ payment }) => {
                    if (MOBILE) {
                        openBrowser(payment.redirectUrl);
                    } else {
                        window.location.href = payment.redirectUrl;
                    }
                },
                onError: graphqlErrorHandler(true),
            });
    }

    function countDecimals(value) {
        if (typeof value === 'undefined') return;
        if (Math.floor(value) === parseFloat(value)) return 0;
        return value.toString().split('.')[1].length || 0;
    }

    return {
        amount,
        isLoading: loading,
        onValueChange,
        onSubmit,
    };
};

export default usePayment;
