export const replaceLastSlash = (link: string, replacement: string): string =>
    link.replace(/\/(?=[^\/]*$)/, replacement);

export const getFileNameFromUrl = (url?: string) => url?.split('/').pop();

export const groupDescription = (length: number) => {
    switch (length) {
        case 1:
            return `${length} osoba`;
        case 2:
        case 3:
        case 4:
            return `${length} osoby`;
        default:
            return `${length} osób`;
    }
};
