import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom';

import OrderChatView from '../../../EZC/views/Chat/OrderChat.view';
import { sDescription, sGrid, sOuter } from '../../../style/style';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import Headline from '../../../components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { useOrdersChatWaiterQuery } from '@graphql/generated';
import PageLoader from '@components/PageLoader/PageLoader';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

const ChatPage = () => {
    const history = useHistory();
    const orderId = useParams().id;

    const { data, error, loading } = useOrdersChatWaiterQuery({
        context: { waiter: true },
        variables: { input: { orderId } },
        onError: graphqlErrorHandler(false),
    });

    const owner = data?.order?.owner?.personal;
    const status = data?.order.status;

    useEffect(() => {
        (status === 'REJECTED' ||
            status === 'CANCELED' ||
            status === 'CLOSED') &&
            history.push(`/waiter/order/${orderId}`);
    }, [status]);

    if (!orderId) {
        history.push('/waiter/notifications');
    }

    if (loading) return <PageLoader />;

    if (error) {
        return (
            <React.Fragment>
                <TopNav title={'Chat'} />
                <div css={sOuter}>
                    <Headline>Chat</Headline>
                    <Grid container css={sGrid}>
                        <p css={sDescription}>
                            Czat nie został odnaleziony. Powiązane zamówienie
                            prawdopodobnie przeniesione do Historii
                        </p>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    return (
        <OrderChatView
            orderId={orderId}
            name={`${owner?.name ?? ''} ${owner?.surname ?? ''}`.trim()}
            secondUser={data?.order.restaurant.name}
            avatarUrl={owner?.avatarUrl}
            onCloseChat={() => history.push(`/waiter/order/${orderId}`)}
            isWaiter
        />
    );
};

export default ChatPage;
