import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/core';
import { colors } from '@/style';
import Spinner from '@components/Spinner';

export interface ButtonBorderProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: ReactNode;
    loading?: boolean;
    variant?: ButtonBorderVariants;
    width?: CSSProperties['width'];
}

export type ButtonBorderVariants = 'gray' | 'green' | 'red';

export const ButtonBorder = ({
    children,
    disabled,
    loading,
    type = 'button',
    variant = 'gray',
    width,
    ...rest
}: ButtonBorderProps) => {
    return (
        <ButtonBorderElement
            {...rest}
            disabled={loading || disabled}
            type={type}
            variant={variant}
            width={width}
        >
            {loading && (
                <SpinnerWrapper>
                    {/* @ts-expect-error fix TS*/}
                    <Spinner size={0.6} addCss={{ color: colors.gray600 }} />
                </SpinnerWrapper>
            )}
            <ChildrenWrapper $loading={loading}>{children}</ChildrenWrapper>
        </ButtonBorderElement>
    );
};

export const buttonBorderVariants: Record<
    ButtonBorderVariants,
    SerializedStyles
> = {
    gray: css`
        border: 3px solid ${colors.gray400};
        color: ${colors.gray600};
    `,
    green: css`
        border: 3px solid ${colors.green300};
        color: ${colors.green};
    `,
    red: css`
        border: 3px solid ${colors.red400};
        color: ${colors.red};
    `,
};

const ButtonBorderElement = styled.button<{
    variant: ButtonBorderVariants;
    width?: CSSProperties['width'];
}>`
    ${({ variant, width }) => css`
        align-items: center;
        background: ${colors.white};
        border-radius: 12px;
        cursor: pointer;
        display: flex;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 700;
        height: 30px;
        justify-content: center;
        line-height: 18px;
        opacity: 1;
        outline: none;
        padding: 0 10px;
        position: relative;
        text-transform: none;
        transition: all ease-in-out 0.3s;
        width: ${width ? width : 'auto'};

        ${variant && buttonBorderVariants[variant]};

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            cursor: default;
            pointer-events: none;
        }
    `}
`;

const SpinnerWrapper = styled.div`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
`;

const ChildrenWrapper = styled.div<{ $loading?: boolean }>(
    ({ $loading }) => css`
        visibility: ${$loading ? 'hidden' : 'visible'};
    `,
);
