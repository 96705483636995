import { useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { MOBILE } from '@constants/common.constants';
import { useKeyboardStoreShallow } from './KeyboardStore';

export const useKeyboardListener = () => {
    const { setKeyboardHeight } = useKeyboardStoreShallow([
        'setKeyboardHeight',
    ]);

    useEffect(() => {
        if (!MOBILE) return;

        Keyboard.addListener('keyboardDidShow', (info) => {
            setKeyboardHeight(info.keyboardHeight);
        });
        Keyboard.addListener('keyboardDidHide', () => {
            setKeyboardHeight(0);
        });

        return () => {
            Keyboard.removeAllListeners();
        };
    }, []);
};
