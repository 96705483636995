import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { Button } from '@/componentsV2/molecules/Button/Button';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';
import { colors } from '@style/index';
import { InputTextNew } from '@/componentsV2/atoms/InputTextNew/InputTextNew';
import { GeolocationType } from '@/types/Geolocalization';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';
import { DEFAULTS_CONSTANTS } from '@constants/defaults.constants';
import { useDebounce } from '@hooks/useDebounce';
import { toast } from 'react-toastify';

export interface YourLocalizationBoxProps {
    onChange: (value: GeolocationType) => void;
    value: GeolocationType;
}

export const YourLocalizationBox = ({
    onChange,
    value,
}: YourLocalizationBoxProps) => {
    const [inputValue, setInputValue] = useState<GeolocationType>(value);
    const [isFocused, setIsFocused] = useState(false);
    const [error, setError] = useState(false);

    const debounce = useDebounce(inputValue, 500);

    const { handleLocation, loading } = useGeolocationContext();

    const { localizedBannerEnabled } = useServerConfigStoreShallow([
        'localizedBannerEnabled',
    ]);

    const inputRef = useRef<HTMLInputElement>(null);

    const handleSelect = async (address: string) => {
        try {
            const results = await geocodeByAddress(address);
            const { lng, lat } = await getLatLng(results[0]);

            onChange({ address, lng, lat });
            setInputValue({ address, lng, lat });
            setError(false);

            inputRef.current?.blur();
        } catch (error) {
            console.error(error);

            onChange({ address, lng: 0, lat: 0 });
            setError(true);
        }
    };

    const handleChange = async (address: string) => {
        try {
            const results = await geocodeByAddress(address);
            const { lng, lat } = await getLatLng(results[0]);

            setInputValue({ address, lng, lat });
            setError(false);
        } catch (error) {
            console.error(error);

            setInputValue({ address, lng: 0, lat: 0 });
            setError(true);
        }
    };

    useEffect(() => {
        if (debounce && !isFocused) {
            onChange(debounce);
            if (debounce.lat === 0 && debounce.lng === 0 && error) {
                toast.error('Podany adres nie istnieje');
            }
        }
    }, [debounce, isFocused, error]);

    return (
        <Wrapper>
            <PlacesAutocomplete
                value={
                    localizedBannerEnabled
                        ? DEFAULTS_CONSTANTS.infoshareAddress
                        : inputValue.address
                }
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <InputContainer>
                        <InputTextNew
                            {...getInputProps({
                                onFocus: () => setIsFocused(true),
                                onBlur: (event) => {
                                    const target =
                                        event.relatedTarget as HTMLElement;
                                    setIsFocused(
                                        target &&
                                            target.id === 'localizationButton',
                                    );
                                },
                            })}
                            placeholder={'Podaj adres'}
                            required
                            ref={inputRef}
                        />
                        <DropdownContainer className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: colors.gray300 }
                                    : { cursor: 'pointer' };

                                return (
                                    <SugestionContainer
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={suggestion.index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </SugestionContainer>
                                );
                            })}
                        </DropdownContainer>
                    </InputContainer>
                )}
            </PlacesAutocomplete>
            <Button
                id="localizationButton"
                variant="black"
                width="100%"
                onClick={() => {
                    //@ts-expect-error migrate to ts
                    handleLocation(true, (geolocation) => {
                        onChange(geolocation);
                        setInputValue(geolocation);
                    });
                }}
                loading={loading}
                disabled={loading || localizedBannerEnabled}
            >
                <ButtonContentWrapper>
                    <LocationSearchingIcon fontSize="small" />
                    Zlokalizuj mnie
                </ButtonContentWrapper>
            </Button>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: grid;
    gap: 16px;
`;

const InputContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div`
    padding: 0 0;
    width: 100%;
    position: absolute;
    z-index: 9;
    top: 100%;
    background: ${colors.white};
`;

const SugestionContainer = styled.div`
    border-radius: 32px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 16px;
    width: 100%;

    &:last-child {
        margin-bottom: 20px;
    }
`;

const ButtonContentWrapper = styled.span`
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
`;
