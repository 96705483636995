import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import jwt_decode from 'jwt-decode';
import { filterKeysFromObject } from '@helpers/filterKeysFromObject';
import { SessionToken, TokenUser } from '@/types/Tokens';
import { AuthSignOutDocument } from '@graphql/generated';
import { CustomerProps } from '@/EZW/domains/pages/NewOrderPage/NewOrderPage';

interface WaiterService {
    orders: {
        activeCount: number;
        attentionCount: number;
    };
    isLoggedIn: boolean;
    refreshToken?: string;
    restaurantId?: string;
    sessionToken?: string;
    fcmToken?: string;
    user?: TokenUser;
    createNewOrderData: {
        customer: CustomerProps;
        isEatzonClient: boolean;
    };
    waiterView: boolean;
    orderIdFromWaiter: string;
    isNewOrdersPopupOpen: boolean;
    isScannerActive: boolean;
    initialize: (token: string) => void;
    logout: (client: any, sentry: any) => void;
    setOrders: (data: {
        attentionOrders: number;
        activeOrders: number;
    }) => void;
    setWaiterView: (waiterView: boolean) => void;
    setRestaurantId: (restaurantId: string) => void;
    setTokens: (
        tokens: Pick<WaiterService, 'sessionToken' | 'refreshToken'>,
    ) => void;
    setFcmToken: (fcmToken: string) => void;
    setCreateNewOrderData: (
        customer: CustomerProps,
        isEatzonClient: boolean,
    ) => void;
    setOrderIdFromWaiter: (id: string) => void;
    setIsNewOrdersPopupOpen: (isOpen: boolean) => void;
    setIsScannerActive: (isActive: boolean) => void;
}

export const useWaiterService = create<WaiterService>()(
    devtools(
        persist(
            (set, get) => ({
                orders: {
                    activeCount: 0,
                    attentionCount: 0,
                },
                isLoggedIn: false,
                waiterView: false,
                refreshToken: undefined,
                restaurantId: undefined,
                sessionToken: undefined,
                user: undefined,
                createNewOrderData: {
                    customer: { name: '' },
                    isEatzonClient: true,
                },
                orderIdFromWaiter: '',
                isNewOrdersPopupOpen: false,
                isScannerActive: false,
                initialize: async (token) => {
                    const tokenData = jwt_decode<SessionToken>(token);
                    const user = filterKeysFromObject<SessionToken, TokenUser>(
                        tokenData,
                        ['exp', 'iat', 'tokenType'],
                    );

                    set({
                        user,
                        isLoggedIn: true,
                        waiterView: true,
                    });
                },
                logout: async (client: any, sentry) => {
                    try {
                        if (get().fcmToken)
                            await client.mutate({
                                mutation: AuthSignOutDocument,
                                context: { waiter: true },
                                variables: {
                                    input: { fcmToken: get().fcmToken },
                                },
                            });
                        sentry.clearScope();
                    } catch (error) {
                        console.error('logout ::', error);
                    } finally {
                        set({
                            isLoggedIn: false,
                            refreshToken: undefined,
                            restaurantId: undefined,
                            sessionToken: undefined,
                            user: undefined,
                        });
                    }
                },
                setOrders: (data) => {
                    set({
                        orders: {
                            activeCount:
                                data?.attentionOrders + data?.activeOrders,
                            attentionCount: data?.attentionOrders,
                        },
                    });
                },
                setRestaurantId: (restaurantId) => {
                    set({
                        restaurantId,
                    });
                },
                setWaiterView: (waiterView) => {
                    set({ waiterView });
                },
                setTokens: ({ refreshToken, sessionToken }) => {
                    set({
                        refreshToken: refreshToken || get().refreshToken,
                        sessionToken: sessionToken || get().sessionToken,
                    });
                    sessionToken && get().initialize(sessionToken);
                },
                setFcmToken: (fcmToken) => {
                    set({
                        fcmToken,
                    });
                },
                setCreateNewOrderData: (customer, isEatzonClient) => {
                    set({
                        createNewOrderData: {
                            customer,
                            isEatzonClient,
                        },
                    });
                },
                setOrderIdFromWaiter: (id) => {
                    set({ orderIdFromWaiter: id });
                },
                setIsNewOrdersPopupOpen: (isNewOrdersPopupOpen) => {
                    set({ isNewOrdersPopupOpen });
                },
                setIsScannerActive: (isScannerActive) => {
                    set({ isScannerActive });
                },
            }),
            {
                name: 'waiter',
                onRehydrateStorage: () => (state) =>
                    state?.sessionToken &&
                    state?.initialize(state.sessionToken),
                partialize: (state) =>
                    filterKeysFromObject(state, [
                        'user',
                        'isLoggedIn',
                        'orders',
                    ]),
            },
        ),
    ),
);

export const WaiterService = useWaiterService.getState;
