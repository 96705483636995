export const ClockIcon = () => (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 2.167a5.833 5.833 0 1 0 0 11.666A5.833 5.833 0 0 0 8 2.167ZM1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm6.806-3.889c.322 0 .583.261.583.583v2.917H9.75a.583.583 0 1 1 0 1.167H7.806a.583.583 0 0 1-.584-.584v-3.5c0-.322.261-.583.584-.583Z"
            fill="#98ACC5"
        />
    </svg>
);
