import React from 'react'; // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sOuter } from '../../../style/style';
import RestaurantProvider, {
    useRestaurantContext,
} from './context/restaurant.provider';
import Details from './components/Details';
import ConsumptionTypes from './components/ConsumptionTypes';
import OpenHours from './components/OpenHours';
import { Collapse } from '@material-ui/core';
import DeliverySettings from './components/DeliverySettings';
import DeliveryCosts from './components/DeliveryCosts';
import DeliveryHours from './components/DeliveryHours';
import ButtonAdd from './components/ButtonAdd';
import { s } from '@style/index';

const RestaurantNewPage = () => {
    const { restaurantInfo } = useRestaurantContext();

    return (
        <React.Fragment>
            <TopNav title={'Nowa Restauracja'} offset={60} />
            <div
                css={
                    (sOuter,
                    {
                        padding: '88px 0 48px',
                        [s.md]: { padding: '144px 0 48px' },
                    })
                }
            >
                <Headline>Nowa Restauracja</Headline>
                <Details isNewRestaurant />
                <ConsumptionTypes />
                <OpenHours />
                <Collapse
                    in={restaurantInfo.consumptionTypes.includes('DELIVERY')}
                >
                    <DeliverySettings />
                    <DeliveryCosts />
                    <DeliveryHours />
                </Collapse>
            </div>
            <ButtonAdd />
        </React.Fragment>
    );
};

export default () => (
    <RestaurantProvider>
        <RestaurantNewPage />
    </RestaurantProvider>
);
