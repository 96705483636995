import { isInteger, isNumber } from './type-check';

export function groshiToZloty(groshi) {
    groshi = nullUndefinedToZero(groshi);
    isInteger(groshi);
    const q = groshi / 10 / 10;
    const decimals = q.toString().split('.')[1];
    const zloty = decimals && decimals.length < 3 ? q : groshi / 100;

    return zloty;
}

export function zlotyToGroshi(zloty) {
    zloty = nullUndefinedToZero(zloty);
    const q = zloty * 10 * 10;
    const decimals = q.toString().split('.')[1];

    let groshi = decimals && decimals.length < 3 ? q : zloty * 100;

    // if we still have decimals
    if (groshi.toString().split('.')[1]) {
        const groshiString = showZlotyWithPrecision(zloty).split('.').join('');
        groshi = parseFloat(groshiString);
    }

    isInteger(groshi);
    return groshi;
}

export function showZlotyWithPrecision(zloty) {
    zloty = nullUndefinedToZero(zloty);
    isNumber(zloty);

    if (zloty === 0) return '0.00';

    const str = zloty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const arr = str.split('.');

    if (arr.length === 1) return str.concat('.00');

    return arr[1].length === 1 ? str.concat('0') : str;
}

export function nullUndefinedToZero(money) {
    if (money === null || money === undefined) return 0;
    else return money;
}
