import React from 'react';
import { Link } from 'react-router-dom';

import {
    consumptionTypeIcons,
    consumptionTypeNames,
} from '../../../../constants/types';
import { dateFc } from '../../../../helpers/date-format';

import { Grid, ButtonBase, useTheme, useMediaQuery } from '@material-ui/core';
import { s, colors, alpha } from '../../../../style';
import {
    sImage,
    sText,
} from '../../../../components/Elements/Collapsable/Collapsable';
import { StatusLarge, Badge } from '../../../../components/Elements';
import { ORDER_STATUS } from '@constants/fromGraphql/OrderStatus.constants';
import { dateFormat } from '@helpers/dateFormat';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mediaQueries, mediaQueriesMaterial } from '@style/breakpoints';
import { ButtonText } from '@/componentsV2/atoms/ButtonText/ButtonText';
import { popup } from '@/AppPopups';

export default ({
    consumptionType,
    date,
    delivery,
    id,
    isInvoice,
    isOrderHistory,
    link,
    needAttention,
    orderId,
    priceSum,
    restaurantAvatar,
    restaurantName,
    shortId,
    status,
    statusLog,
    w100,
    waiter,
    waiterHistory,
}) => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const withoutTimer =
        consumptionType !== 'TAKEAWAY' ||
        (consumptionType === 'TAKEAWAY' &&
            status !== 'PREPARING' &&
            status !== 'ACCEPTED');

    return (
        <Grid
            item
            xs={12}
            md={waiter ? 12 : w100 ? 12 : 6}
            lg={waiter ? 6 : w100 ? 12 : 6}
            css={{
                animation: `fadeIn 0.3s ease-in-out`,
            }}
        >
            <Link
                to={
                    link
                        ? link
                        : waiter
                          ? `/waiter/order/${orderId}`
                          : isOrderHistory
                            ? `/order/${orderId}`
                            : `/order/${orderId}`
                }
                css={[
                    sContainerOuter,
                    id % 2 === 1 &&
                        !waiter && { [s.md]: { marginLeft: '0.75rem' } },
                    id % 2 === 0 &&
                        !waiter && { [s.md]: { marginRight: '0.75rem' } },
                    id % 2 === 1 &&
                        waiter && { [s.lg]: { marginLeft: '0.75rem' } },
                    id % 2 === 0 &&
                        waiter && { [s.lg]: { marginRight: '0.75rem' } },
                    {
                        marginBottom: '1.5rem',
                        [s.sm_down]: { marginBottom: '1rem' },
                    },
                ]}
            >
                <ButtonBase css={[sContainerInner]}>
                    <div
                        css={[
                            sTopContainer,
                            waiter && !waiterHistory && sTopContainerWaiter,
                        ]}
                    >
                        <div
                            css={[
                                sLeft,
                                waiter && !waiterHistory && sLeftWaiter,
                            ]}
                        >
                            <div
                                css={[
                                    sImage,
                                    {
                                        position: 'relative',
                                        backgroundColor: colors.gray300,
                                    },
                                ]}
                            >
                                {restaurantAvatar}
                                <Badge
                                    small
                                    left
                                    color={colors.orange}
                                    extraCss={{
                                        boxShadow: `0px 0px 0px 3px ${colors.gray200}`,
                                    }}
                                    show={needAttention}
                                    showAnimation
                                />
                            </div>
                            <div css={[sText]}>
                                <p
                                    css={[
                                        sText.title,
                                        sText.oneLiner,
                                        !waiter && sLimitText,
                                    ]}
                                >
                                    {waiter ? shortId : restaurantName}
                                    {waiter && (
                                        <span
                                            css={{
                                                // color: colors.gray600,
                                                // fontWeight: 500,
                                                opacity: 0.25,
                                            }}
                                        >
                                            &nbsp;-&nbsp;{restaurantName}
                                        </span>
                                    )}
                                </p>
                                <p
                                    css={[
                                        sText.subtitle,
                                        sText.oneLiner,
                                        { fontSize: '12px' },
                                    ]}
                                >
                                    {!waiter && (
                                        <DateWrapper>
                                            {dateFormat.shortDateWithTime(date)}
                                        </DateWrapper>
                                    )}
                                    {waiter && dateFormat.standardTime(date)}
                                    <Bullet>&bull;</Bullet>
                                    {waiter && dateFormat.fullDate(date)}
                                    <Bullet>&bull;</Bullet>
                                    <>
                                        {`${priceSum.toFixed(2)}`.slice(-2) ===
                                        '00'
                                            ? priceSum.toFixed(0)
                                            : priceSum.toFixed(2)}{' '}
                                        zł
                                    </>
                                    <Bullet>&bull;</Bullet>
                                    {consumptionTypeIcons[consumptionType]}
                                </p>
                            </div>
                        </div>
                        <BottomTileInfo
                            isInvoice={isInvoice}
                            isWaiter={waiter && !waiterHistory}
                            withoutTimer={withoutTimer}
                        >
                            {waiter &&
                                !waiterHistory &&
                                consumptionType === 'TAKEAWAY' &&
                                (status === 'ACCEPTED' ||
                                    status === 'PREPARING') && (
                                    <TimeInfoWrapper>
                                        <TimeWrapper isSm={isSm}>
                                            <Text>Godzina odbioru:</Text>
                                            <span>
                                                {dateFormat.standardTime(
                                                    delivery?.eta,
                                                )}
                                            </span>
                                        </TimeWrapper>
                                        <TimeLeftWrapper>
                                            <TimeLeft>
                                                {dateFormat.calculateTimeLeft(
                                                    delivery?.eta,
                                                )}
                                            </TimeLeft>
                                            {isSm && (
                                                <ButtonText
                                                    text="Aktualizuj"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        popup.show(
                                                            'SetDeliveryTimePopup',
                                                            {
                                                                order: {
                                                                    id: orderId,
                                                                    delivery,
                                                                    statusLog,
                                                                },
                                                                loading: false,
                                                                isTakeaway:
                                                                    consumptionType ===
                                                                    'TAKEAWAY',
                                                                update: true,
                                                            },
                                                        );
                                                    }}
                                                    variant="black"
                                                    underline
                                                    fontSize="12px"
                                                />
                                            )}
                                        </TimeLeftWrapper>
                                    </TimeInfoWrapper>
                                )}
                            <BadgeWrapper withoutTimer={withoutTimer}>
                                {waiter && isInvoice && !waiterHistory && (
                                    <StatusLarge
                                        color={colors.gray600}
                                        extraCss={{
                                            [s.lg]: {
                                                fontSize: '11px',
                                            },
                                        }}
                                    >
                                        Faktura
                                    </StatusLarge>
                                )}
                                <StatusLarge
                                    extraCss={{
                                        [s.lg]: {
                                            fontSize: '11px',
                                        },
                                    }}
                                    color={ORDER_STATUS[status]?.color}
                                >
                                    {ORDER_STATUS[status]?.name}
                                </StatusLarge>
                            </BadgeWrapper>
                        </BottomTileInfo>
                    </div>
                    {needAttention && (
                        <div
                            css={[
                                sIsNotPaid,
                                {
                                    background: `linear-gradient(to right, ${colors.orange}, ${colors.orange800})`,
                                },
                            ]}
                        >
                            Wymaga uwagi
                        </div>
                    )}
                </ButtonBase>
            </Link>
        </Grid>
    );
};

const DateWrapper = styled.span`
    text-transform: capitalize;
`;

const sContainerOuter = {
    display: 'flex',
};

export const sContainerInner = {
    position: 'relative',
    transition: 'background-color 0.3s ease-out',
    backgroundColor: colors.gray200,
    borderRadius: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    '&:hover': {
        [s.hover]: {
            backgroundColor: colors.gray300.concat(alpha[100]),
            img: {
                transform: 'scale(1.1)',
                filter: 'brightness(1.1)',
            },
        },
    },
};

export const sLeft = {
    display: 'flex',
    width: '75%',

    [mediaQueriesMaterial.sm]: {
        width: 'unset',
    },

    [s.sss]: {
        marginRight: 0,
    },
};

export const sLeftWaiter = {
    [s.xs]: {
        marginBottom: '1rem',
    },
};

export const sTopContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '1rem',
    gap: '20px',

    [s.md]: {
        padding: '32px 16px',
    },

    [mediaQueries.sm]: {
        gap: '56px',
    },

    [s.sm]: {
        gap: '16px',
    },

    [s.xs]: {
        gap: '16px',
        flexWrap: 'wrap',
    },
};

export const sTopContainerWaiter = {
    gap: '36px',
    [s.orders_list_only]: {
        gap: '20px',
    },
    [s.md_down]: {
        gap: '56px',
    },

    [s.xs]: {
        gap: '0',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
};

const sIsNotPaid = {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#ffffff',
    alignItems: 'center',
    background: `linear-gradient(to right, ${colors.red600}, ${colors.red800})`,
    width: '100%',
    borderRadius: '0 0 16px 16px',
    padding: '0 1rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '0.2em',
    height: 24,
    fontSize: '12px',
    [s.md]: {
        padding: '0 2rem',
        paddingLeft: '6rem',
        left: 0,
        bottom: -12,
        position: 'absolute',
    },
    [s.sm_down]: {
        paddingLeft: 16 + 12 + 40,
    },
};

export const sLimitText = {
    [s.xs]: {
        maxWidth: 'calc(100vw - 243px)',
    },
    [s.sss]: {
        maxWidth: 'calc(100vw - 212px)',
    },
};

export const Text = styled.span`
    text-wrap: nowrap;

    ${s.lg_1280} {
        text-wrap: wrap;
    }

    ${mediaQueries.sm} {
        text-wrap: wrap;
    }
`;

const Bullet = styled.span`
    margin: 0 4px;
`;

const BadgeWrapper = styled.div(
    ({ withoutTimer }) => css`
        display: grid;
        justify-items: flex-end;
        gap: 4px;
        min-width: 116px;

        ${mediaQueriesMaterial.sm} {
            flex: unset;
        }

        ${mediaQueriesMaterial.xs} {
            display: flex;
            flex: unset;
            flex-direction: ${withoutTimer ? 'row' : 'column'};
            align-items: flex-end;
            justify-content: flex-end;
            width: 100%;
        }
    `,
);

const TimeInfoWrapper = styled.div`
    display: flex;
    justify-items: flex-end;
    font-size: 12px;
    line-height: 18px;
    gap: 0 30px;
    flex-wrap: wrap;
    justify-content: flex-end;
    color: ${colors.blueZodiac};

    ${s.lg_1280} {
        gap: 6px;
    }

    ${mediaQueriesMaterial.md} {
        display: flex;
        gap: 66px;
        width: unset;
        justify-content: space-between;
    }

    ${mediaQueries.md} {
        gap: 56px;
    }

    ${mediaQueriesMaterial.sm} {
        align-items: center;
        display: flex;
        gap: 40px;
    }

    ${mediaQueriesMaterial.xs} {
        display: grid;
        gap: 0;
        justify-items: flex-start;
    }
`;

export const TimeLeftWrapper = styled.p`
    text-align: end;
`;

const TimeWrapper = styled.p(
    ({ isSm }) => css`
        display: flex;
        gap: 2px;
        text-align: end;

        ${s.lg_1280} {
            text-align: end;
        }

        ${mediaQueriesMaterial.md} {
            text-align: start;
        }

        ${isSm &&
        css`
            display: grid;
            gap: 0;
            text-align: start;
            color: ${colors.text};
        `}
    `,
);

const BottomTileInfo = styled.div(
    ({ isWaiter, withoutTimer }) => css`
        display: flex;
        align-items: center;
        gap: 36px;
        justify-content: end;
        width: 100%;

        ${s.orders_list_only} {
            gap: 20px;
        }

        ${s.md_down} {
            gap: 66px;
        }

        ${isWaiter &&
        css`
            ${mediaQueriesMaterial.sm} {
                gap: 16px 56px;
                flex-wrap: wrap;
            }

            ${mediaQueriesMaterial.xs} {
                justify-content: space-between;
                width: 100%;
                gap: 0;
                align-items: center;
                flex-direction: row;
            }
        `}

        ${withoutTimer &&
        css`
            ${mediaQueriesMaterial.sm} {
                justify-content: space-between;
                width: unset;
            }
        `};

        ${mediaQueriesMaterial.xs} {
            justify-content: space-between;
            width: 100%;
        }
    `,
);

const TimeLeft = styled.span`
    font-weight: 700;
    color: ${colors.text};
    font-size: 16px;
    line-height: 24px;

    ${mediaQueries.sm} {
        font-size: 14px;
        line-height: 18px;
    }

    ${mediaQueriesMaterial.xs} {
        font-size: 12px;
        line-height: 18px;
    }
`;
