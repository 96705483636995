import React from 'react';
import { colors } from '@style/index';
import { Grid, TextField } from '@material-ui/core';
import { sGrid, sOuter } from '@style/style';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import Headline from '@components/Headline/Headline';
import styled from '@emotion/styled';
import { Controller } from 'react-hook-form';
import { sInput } from '@/EZR/style/input.style';
import { UserFriendInfo } from '@/EZC/domains/Account/molecules/UserFriendInfo/UserFriendInfo';
import { useNewGroupPage } from './useNewGroupPage';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { FindAndAddUser } from '@/componentsV2/molecules/FindAndAddUser/FindAndAddUser';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { usePhoneBookInit } from '@/services/PhoneBookService/usePhoneBookInit';
import { css } from '@emotion/core';

export const NewGroupPage = () => {
    const {
        control,
        friends,
        handleAddToGroup,
        handleFormSubmit,
        inviteLoading,
        loading,
        handleSendInvitation,
        open,
        setOpen,
    } = useNewGroupPage();

    const { bottomNavigation } = useSafeAreaInsets();

    usePhoneBookInit();

    return (
        <Wrapper>
            {/* @ts-expect-error migrate to TS */}
            <TopNav title={'Nowa grupa'} />
            <div
                /* @ts-expect-error migrate to TS */
                css={sOuter}
            >
                {/* @ts-expect-error migrate to TS */}
                <Headline
                    extraCss={{ flexWrap: 'unset', alignItems: 'center' }}
                >
                    Nowa grupa
                </Headline>

                <Grid container css={sGrid}>
                    <Grid item xs={12} md={12}>
                        <Controller
                            name="groupName"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    label="Nazwa grupy"
                                    fullWidth
                                    /* @ts-expect-error migrate to TS */
                                    css={sInput}
                                />
                            )}
                        />
                    </Grid>
                    <UserFindWrapper>
                        <FindAndAddUser
                            open={open}
                            onClick={handleAddToGroup}
                            setOpen={setOpen}
                            buttonText="Dodaj do grupy"
                            title="Dodaj znajomych do grupy"
                            description="Znajdź i dodaj znajomych do grupy, wpisując ich adresy e-mail. Dzięki temu wspólnie będziecie mogli szukać jedzenia i restauracji, zgodnie z preferencjami kulinarnymi Twoich znajomych. Do grup można zapraszać tylko aktywnych użytkowników aplikacji."
                            placeholder="Wyszukaj znajomego przez e-mail"
                            notFoundElement={(email) => {
                                return (
                                    <>
                                        <UserNotFoundText>
                                            <ErrorOutlineIcon /> Nie znaleziono
                                            użytkownika o podanym adresie
                                            e-mail. Sprawdź poprawność adresu
                                            lub wyślij zaproszenie do aplikacji
                                            Eatzon dla tej osoby.
                                        </UserNotFoundText>{' '}
                                        <Button
                                            loading={inviteLoading}
                                            variant="black"
                                            onClick={() => {
                                                handleSendInvitation(
                                                    email ?? '',
                                                );
                                            }}
                                            width="100%"
                                        >
                                            WYŚLIJ ZAPROSZENIE
                                        </Button>
                                    </>
                                );
                            }}
                        />
                    </UserFindWrapper>
                    {!!friends.length && (
                        <Grid item xs={12}>
                            <Text>Osoby w grupie</Text>
                            <UserFriendsWrapper>
                                {friends.map((friend) => {
                                    return (
                                        <UserFriendWrapper>
                                            <UserFriendInfo {...friend} />
                                        </UserFriendWrapper>
                                    );
                                })}
                            </UserFriendsWrapper>
                        </Grid>
                    )}
                    <ButtonWrapper bottom={bottomNavigation}>
                        <Button
                            variant="black"
                            onClick={handleFormSubmit}
                            loading={loading}
                            width="100%"
                        >
                            Dodaj grupę
                        </Button>
                    </ButtonWrapper>
                </Grid>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin-bottom: 72px;
`;

const UserFindWrapper = styled.div`
    margin-top: 40px;
`;

const ButtonWrapper = styled.div<{ bottom?: number }>(
    ({ bottom = 0 }) => css`
        bottom: ${bottom}px;
        left: 0;
        padding: 14px 24px;
        position: fixed;
        width: 100%;
        z-index: 1001;

        @media (min-width: 1480px) {
            left: 50%;
            transform: translateX(-50%);
            width: 1480px;
        }
    `,
);

const Text = styled.p`
    color: ${colors.text};
    font-size: 16px;
    font-weight: 700;
    margin: 40px 0 16px;
`;

const UserFriendWrapper = styled.div`
    background: ${colors.gray100};
    padding: 16px;
    border-radius: 16px;
`;

const UserFriendsWrapper = styled.div`
    margin: 16px 0 0;
    display: grid;
    grid-gap: 16px;
`;

const UserNotFoundText = styled.p`
    align-items: center;
    color: ${colors.red};
    display: flex;
    font-size: 12px;
    gap: 8px;
`;
