import { ReactElement, useEffect, useState } from 'react';
import { Collapsable, Button } from '@components/Elements';
import CloseIcon from '@material-ui/icons/Close';
import { Chip, ChipProps } from '../../../../../componentsV2/atoms/Chip/Chip';
import styled from '@emotion/styled';
import { SearchInput } from '../../../../../componentsV2/molecules/SearchInput/SearchInput';
import { useFieldArray, useForm } from 'react-hook-form';
import { sortBy } from 'lodash';
import { css } from '@emotion/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mediaQueriesMaterial } from '@style/breakpoints';

export type AccordionChipType = Pick<ChipProps, 'variant'> & {
    value: string;
    label: string;
};

export interface ChipPreferencesAccordionProps {
    chips?: AccordionChipType[];
    chipSteps?: ChipProps['variant'][];
    description: string;
    icon?: ReactElement;
    loading?: boolean;
    loadingSave?: boolean;
    onSave: (value: AccordionChipType[], onCompleted: () => void) => void;
    searchProps?: {
        placeholder?: string;
        onSearch: (value: string) => void;
        value: string;
    };
    title: string;
}

export const ChipPreferencesAccordion = ({
    chips,
    chipSteps,
    description,
    icon,
    loading,
    loadingSave,
    onSave,
    searchProps,
    title,
}: ChipPreferencesAccordionProps) => {
    const [open, setOpen] = useState(false);

    const sortedChips = sortBy(chips, [
        (chip) => chip.variant !== 'add',
        (chip) => chip.variant !== 'remove',
    ]);

    const { control, reset, handleSubmit } =
        useForm<ChipPreferencesAccordionValues>({
            defaultValues: {
                chips: sortedChips,
            },
        });

    const { fields, update } = useFieldArray({
        control,
        name: 'chips',
    });

    const handleReset = () => {
        searchProps?.onSearch('');
        setOpen(false);
        reset({
            chips: sortedChips,
        });
    };

    const handleFormSubmit = handleSubmit(
        ({ chips }) =>
            chips &&
            onSave(chips, () => {
                searchProps?.onSearch('');
                setOpen(false);
            }),
    );

    useEffect(() => {
        reset({
            chips: sortedChips,
        });
    }, [chips, open]);

    return (
        /* @ts-expect-error migrate to TS */
        <Collapsable
            additionalComponent={
                !open && (
                    <ChipsWrapper
                        $padding={`${
                            !loading &&
                            !sortedChips.some(
                                (item) => item.variant !== 'default',
                            )
                                ? '0'
                                : '24px 0 0 0'
                        }`}
                    >
                        {loading ? (
                            <Loading />
                        ) : (
                            sortedChips?.map((item) => {
                                if (item.variant === 'default') return null;

                                return (
                                    <Chip
                                        label={item.label}
                                        id={item.value}
                                        variant={item.variant}
                                    />
                                );
                            })
                        )}
                    </ChipsWrapper>
                )
            }
            alignStart
            description={description}
            iconBehindTitle={icon}
            noPadding
            noPaddingBottom
            parentIsOpen={open}
            parentSetIsOpen={setOpen}
            title={title}
            sTextCss={{ marginLeft: '0' }}
        >
            <ContentWrapper>
                {!!searchProps && (
                    <SearchInput
                        onChange={searchProps.onSearch}
                        onSubmit={searchProps.onSearch}
                        placeholder={searchProps.placeholder}
                        value={searchProps.value}
                    />
                )}
                <ChipsWrapper
                    $padding={!!searchProps ? '16px 0 24px 0' : '0 0 24px 0'}
                >
                    {loading ? (
                        <Loading />
                    ) : (
                        fields
                            ?.filter(
                                ({ label }) =>
                                    !searchProps ||
                                    label
                                        .toLowerCase()
                                        .includes(
                                            searchProps?.value.toLowerCase(),
                                        ),
                            )
                            .map((item) => {
                                const fieldIndex = fields.findIndex(
                                    ({ id }) => id === item.id,
                                );

                                return (
                                    <Chip
                                        steps={chipSteps}
                                        icon
                                        id={item.id}
                                        key={item.id}
                                        label={item.label}
                                        onClick={(_, nextVariant) => {
                                            update(fieldIndex, {
                                                ...item,
                                                variant: nextVariant,
                                            });
                                        }}
                                        variant={item.variant}
                                    />
                                );
                            })
                    )}
                </ChipsWrapper>
                <ButtonsWrapper>
                    {/* @ts-expect-error migrate to TS */}
                    <Button size48 dimmed action={handleReset}>
                        <CloseIcon />
                    </Button>
                    {/* @ts-expect-error migrate to TS */}
                    <Button
                        action={handleFormSubmit}
                        loading={loadingSave || loading}
                        loadingSpinner={loadingSave}
                        w100
                        w100Desktop
                    >
                        Zapisz
                    </Button>
                </ButtonsWrapper>
            </ContentWrapper>
        </Collapsable>
    );
};

export type ChipPreferencesAccordionValues = {
    chips: ChipPreferencesAccordionProps['chips'];
};

const ContentWrapper = styled.div`
    padding: 24px 32px;

    ${mediaQueriesMaterial.sm} {
        padding: 16px 24px 24px;
    }
`;

const ChipsWrapper = styled.div<{
    $padding: string;
}>(
    ({ $padding }) => css`
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding: ${$padding};
    `,
);

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const Loading = styled(CircularProgress)`
    margin: 0 auto;
`;
