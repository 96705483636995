/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import Input from '../../../views/Auth/components/Input.component';
import { Button } from '../../../../components/Elements';

import { s } from '../../../../style';
import { MOBILE } from '@constants/common.constants';
import { Keyboard } from '@capacitor/keyboard';

export default ({ note, onSaveNote, parentSetIsOpen }) => {
    const [componentsNote, setComponentsNote] = useState('');
    const onChange = (e) => setComponentsNote(e.target.value);
    const onSave = () => {
        onSaveNote(componentsNote);
        parentSetIsOpen(false);
    };

    useEffect(() => {
        if (note) setComponentsNote(note);
        return () => setComponentsNote('');
    }, []);

    return (
        <div
            css={{
                [s.sm_down]: { padding: '1.5rem 1rem' },
                [s.md]: { padding: '2rem' },
            }}
        >
            <Input
                darker
                placeholder="Treść notatki"
                value={componentsNote}
                onChange={onChange}
                onFocus={(e) => {
                    let val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                }}
            />
            <Button w100 action={onSave}>
                Zapisz notatkę
            </Button>
        </div>
    );
};
