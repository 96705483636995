import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import Headline from '../../../../components/Headline/Headline';
import { sOuter, sGrid } from '../../../../style/style';
import { ButtonBorder } from '../../../../components/Elements';
import { Grid } from '@material-ui/core';
import { Tile } from '../wallet/Wallet.page';
import Transaction from './Transaction';
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder';
import { useHistory } from 'react-router-dom';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';

import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed';
import {
    useTransactionListLazyQuery,
    useTransactionsPageQuery,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAccountCoins } from '@/EZC/hooks/useAccountCoins';
import { useTransactionsGa } from './hooks/useTransactionsGa';

const Transactions = () => {
    useTransactionsGa();

    return (
        <React.Fragment>
            <TopNav title={'Transakcje'} />

            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder link={`/account/wallet`}>
                            Portfel
                        </ButtonBorder>
                    }
                >
                    Transakcje
                </Headline>
                <AccountBalance />
                <TransactionsGrid />
            </div>
        </React.Fragment>
    );
};

const AccountBalance = () => {
    const history = useHistory();

    const { actualBalance, blockedBalance } = useAccountCoins();
    const { data, loading } = useTransactionsPageQuery({
        onError: graphqlErrorHandler(true),
    });

    if (loading) return <div />;

    if (!data?.user) {
        history.push('/account/transactions');
    }

    const { personal } = data.user || {};

    return (
        <React.Fragment>
            <TopNav
                title={'Transakcje'}
                subtitle={personal && `- ${personal.name} ${personal.surname}`}
            />
            <Grid container css={[sGrid]} justifyContent="space-between">
                <Tile
                    subtitle={'Dostępne Środki'}
                    version="BALANCE"
                    coins={actualBalance}
                />
                <Tile
                    subtitle={'Blokady'}
                    version="BLOCK"
                    coins={blockedBalance}
                />
            </Grid>
        </React.Fragment>
    );
};

const TransactionsGrid = () => {
    const [getTransactions, { data, loading }] = useTransactionListLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getTransactions({
                variables: {
                    pagination,
                    input: {
                        types: [
                            'BLOCKED',
                            'UN_BLOCKED_PARTIAL',
                            'PAYOFF_REQUEST_PAID_REQUESTER',
                            'PAYOFF_REQUEST_PAID_RECIPIENT',
                            'ORDER_PAYMENT',
                            'DEPOSIT',
                        ],
                    },
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.transactionList.metadata,
    });

    return (
        <>
            <Grid container css={sGrid} justifyContent="space-between">
                {data?.transactionList?.data.map((data, index) => (
                    <Transaction id={index} data={data} key={data.id} />
                ))}
                {loading && (
                    <>
                        <SkeletonOrder />
                        <SkeletonOrder />
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </>
                )}
            </Grid>
            <Grid container css={sGrid}>
                {!loading && hasMore && (
                    <ButtonBorder action={getMoreData}>
                        Pokaż więcej
                    </ButtonBorder>
                )}
            </Grid>
        </>
    );
};

export default Transactions;
