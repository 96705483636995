import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    useCartQuery,
    useOrderCreateMutation,
    useOrderUpdateUserMutation,
    useCartUpdateMutation,
    OPERATIONS,
    useUserOrderGroupListLazyQuery,
    useUserCartDataQuery,
    CartDocument,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { dateFormat } from '@helpers/dateFormat';
import { toast } from 'react-toastify';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { popup } from '@/AppPopups';
import { showZlotyWithPrecision } from '@helpers/MoneyEz/utils/converters';
import { priceFormat } from '@helpers/priceFormat';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { useWebTransactionFinished } from './useWebTransactionFinished';

const _storageName = (orderId) => 'cartOrderData'.concat(orderId);

const INITIAL_GROUPS_LENGTH = 6;

export default ({ orderId }) => {
    // TODO: remove
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [orderWeWorkOn, setOrderWeWork] = useState(null);
    const [parentIsOpen, parentSetIsOpen] = useState(false);
    const [deliveryError, setDeliveryError] = useState({});
    const [phoneError, setPhoneError] = useState(undefined);

    const setOrderWeWorkOn = (data) => {
        localStorage.setItem(_storageName(orderId), JSON.stringify(data));
        setOrderWeWork(data);
    };

    const { data: userData } = useUserCartDataQuery();
    const phoneNumber = userData?.user?.personal?.phone;
    const userDefaultAddress = userData?.user?.addresses?.find(
        (address) => address?.isDefault,
    );

    const { data, loading, error } = useCartQuery({
        variables: { input: { cartId: orderId } },
        skip: !orderId,
        onError: () => {
            history.replace('/cart');
        },
        fetchPolicy: 'cache-and-network',
    });

    const [getGroups, { data: groupData }] = useUserOrderGroupListLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const totalGroupsLength = groupData?.list.metadata.totalCount;

    const userPhoneSet = /^\+48\d{9}$/.test(userData?.user?.personal?.phone)
        ? userData?.user?.personal?.phone
        : null;

    const [isExpanded, setIsExpanded] = useState(false);

    const { getMoreData } = useShowMorePagination({
        metadata: groupData?.list.metadata,
        pageSize: isExpanded ? totalGroupsLength : INITIAL_GROUPS_LENGTH,
        callback: (pagination) =>
            getGroups({
                variables: {
                    pagination,
                    input: { isOwner: true },
                },
                onError: graphqlErrorHandler(true),
            }),
    });

    const showMoreGroups = async () => {
        setIsExpanded((prevState) => !prevState);
    };

    useEffect(() => {
        getMoreData();
    }, [isExpanded, setIsExpanded]);

    const [cartUpdate, { error: cartUpdateError, loading: cartUpdateLoading }] =
        useCartUpdateMutation({
            onError: graphqlErrorHandler(true),
        });

    const history = useHistory();

    const handleDownloadPdf = () => {
        const url = orderWeWorkOn.restaurant.layoutPdfUrl;
        url
            ? window.open(url, '_blank')
            : toast.warning('Nie ma możliwości podglądu układu sali');
    };

    const [orderCreate, { loading: orderCreateLoading }] =
        useOrderCreateMutation();
    const [updateUser] = useOrderUpdateUserMutation();

    const cartDetail = {
        consumptionDetails: {
            date: orderWeWorkOn?.consumptionDetails?.date,
            guests: orderWeWorkOn?.consumptionDetails?.guests,
            type: orderWeWorkOn?.consumptionDetails?.type,
        },
        deliveryAddress: orderWeWorkOn?.deliveryAddress,
        deliveryDetails: orderWeWorkOn?.deliveryDetails,
        deliveryAddressName: orderWeWorkOn?.deliveryAddressName,
        deliveryAddressId: orderWeWorkOn?.deliveryAddressId ?? null,
        deliveryLocation: {
            coordinates: [
                orderWeWorkOn?.deliveryLocation?.coordinates[0] ?? 0,
                orderWeWorkOn?.deliveryLocation?.coordinates[1] ?? 0,
            ],
        },
        note: orderWeWorkOn?.note,
        hasInvoice: orderWeWorkOn?.isInvoice,
        invoiceDetails: data?.cart?.invoiceDetails ?? undefined,
        table: orderWeWorkOn?.table,
        groupId: orderWeWorkOn?.group?.id,
        serveOnTime: orderWeWorkOn?.serveOnTime,
        clientPhoneNumber: orderWeWorkOn?.phoneNumber,
    };

    useEffect(() => {
        const storageData = JSON.parse(
            localStorage.getItem(_storageName(orderId)),
        );
        if (storageData && data?.cart) {
            setOrderWeWork({
                ...storageData,
                deliveryLocation: data?.cart?.deliveryLocation,
                deliveryAddress: data?.cart?.deliveryAddress,
                deliveryDetails: data?.cart?.deliveryDetails,
                deliveryAddressName: data?.cart?.deliveryAddressName,
                deliveryAddressId: data?.cart?.deliveryAddressId ?? null,
                deliveryCost: data?.cart?.deliveryCost,
                packagingCost: data?.cart?.packagingCost,
                priceSum: data?.cart?.priceSum,
                invoiceDetails: data?.cart?.invoiceDetails,
                orderDetails: data?.cart?.lines,
                restaurant: data?.cart?.restaurant,
                table: data?.cart?.table,
                group: data?.cart?.group,
                orderType: data?.cart?.group ? 'group' : 'individual',
                serveOnTime: data?.cart?.serveOnTime,
                phoneNumber: data?.cart?.clientPhoneNumber ?? userPhoneSet,
            });
        }

        if (data?.cart && !orderWeWorkOn && !storageData) {
            const futureDay = dateFormat.getFutureDate(
                data?.cart?.consumptionDetails?.date,
                {
                    time:
                        orderWeWorkOn?.consumptionDetails?.type === 'TAKEAWAY'
                            ? 5
                            : 30,
                    timeUnit: 'minutes',
                },
            );
            setOrderWeWorkOn({
                invoiceDetails: data?.cart?.invoiceDetails,
                isFinished: false,
                isInvoice: data?.cart?.hasInvoice,
                orderDetails: data?.cart?.lines,
                note: data?.cart?.note || '',
                deliveryCost: data?.cart?.deliveryCost,
                packagingCost: data?.cart?.packagingCost,
                priceSum: data?.cart?.priceSum,
                deliveryLocation: {
                    coordinates: [
                        data?.cart?.deliveryLocation?.coordinates[0] ||
                            userDefaultAddress?.coordinates?.[0],
                        data?.cart?.deliveryLocation?.coordinates[1] ||
                            userDefaultAddress?.coordinates?.[1],
                    ],
                },
                deliveryAddress:
                    data?.cart?.deliveryAddress || userDefaultAddress?.address,
                deliveryDetails:
                    data?.cart?.deliveryDetails ||
                    userDefaultAddress?.additionalInfo,
                deliveryAddressName:
                    data?.cart?.deliveryAddressName || userDefaultAddress?.name,
                deliveryAddressId: data?.cart?.deliveryAddress
                    ? data?.cart?.deliveryAddressId
                    : userDefaultAddress?.id,
                consumptionDetails: {
                    date: futureDay,
                    type: data?.cart?.restaurant?.consumptionTypes?.includes(
                        data?.cart?.consumptionDetails?.type,
                    )
                        ? data?.cart?.consumptionDetails?.type
                        : data?.cart?.restaurant?.consumptionTypes[0],
                    guests: data?.cart?.consumptionDetails?.guests || 1,
                },
                restaurant: data?.cart?.restaurant,
                table: data?.cart?.table,
                group: data?.cart?.group,
                orderType: data?.cart?.group ? 'group' : 'individual',
                serveOnTime: data?.cart?.serveOnTime,
                phoneNumber: data?.cart?.clientPhoneNumber ?? userPhoneSet,
            });
        }
    }, [data]);

    const onDeleteCartOrderFromLocalStorage = (_orderId) => {
        localStorage.removeItem(_storageName(_orderId));
    };

    const handleOrderCreate = () => {
        if (orderWeWorkOn?.orderType === 'group' && !orderWeWorkOn?.group) {
            toast.error('Grupa nie została wybrana');
            return;
        }

        if (
            orderWeWorkOn?.consumptionDetails?.type === 'DELIVERY' &&
            deliveryError.distance
        ) {
            toast.error('Restauracja jest zbyt daleko');
            return;
        }
        if (
            orderWeWorkOn?.consumptionDetails?.type === 'DELIVERY' &&
            deliveryError.minAmount
        ) {
            toast.error('Minimalna kwota dostawy nie została osiągnięta');
            return;
        }
        if (
            orderWeWorkOn?.consumptionDetails?.type === 'DELIVERY' &&
            !orderWeWorkOn?.deliveryAddress
        ) {
            toast.error('Wprowadź adres dostawy');
            return;
        }
        if (orderWeWorkOn?.isInvoice && !data?.cart?.invoiceDetails) {
            toast.error('Podaj dane do faktury');
            return;
        }

        if (!!phoneError) {
            toast.error(
                phoneError === 'required'
                    ? 'Podaj numer telefonu'
                    : 'Niepoprawny format numeru telefonu. Proszę wprowadź poprawny format numeru telefonu (9 cyfr)',
            );
            return;
        }

        const userAvailableCoins = userData?.user?.coinsAvailable;

        const totalProductCost = showZlotyWithPrecision(
            data?.cart?.lines?.reduce((sum, curr) => curr.price + sum, 0),
        );
        const packageCost = orderWeWorkOn?.orderDetails.reduce(
            (sum, curr) => curr.product.packagingPrice * curr.quantity + sum,
            0,
        );
        const totalCost =
            parseFloat(priceFormat.standardPrice(totalProductCost)) +
            (orderWeWorkOn?.consumptionDetails?.type === 'DELIVERY'
                ? deliveryCost
                : 0) +
            packageCost;

        !phoneNumber &&
            updateUser({
                variables: {
                    input: {
                        payload: {
                            personal: {
                                phone: orderWeWorkOn?.phoneNumber,
                            },
                        },
                    },
                },
                onError: graphqlErrorHandler(false),
            });

        !cartUpdateError &&
            orderCreate({
                variables: {
                    input: { cartId: data?.cart.id },
                },
                onCompleted: ({ order }) => {
                    onDeleteCartOrderFromLocalStorage(order.id);
                    toast.info(
                        'Zamówienie zostało wysłane do restauracji i oczekuje na akceptację.',
                    );
                    AnalyticsHelpers.createCustomEvent({
                        name: 'in_app_purchase',
                        params: { type: 'order' },
                    });
                    history.push(`/order/${order.id}`);
                },
                onError: (error) => {
                    graphqlErrorHandler({
                        omitErrorCodes: ['ORDER_NOT_ENOUGH_COINS_ERROR'],
                        ...(orderWeWorkOn?.consumptionDetails.type === 'IN' && {
                            customCodeMessages: {
                                ORDER_RESTAURANT_CLOSED_ERROR_CODE:
                                    'W tym okresie, którym rezerwujesz stolik, restauracja jest zamknięta. Akceptacja zamówienia oraz rezerwacji stolika zostanie możliwa po jej otwarciu',
                            },
                        }),
                        callbackOnErrorCode: {
                            ORDER_NOT_ENOUGH_COINS_ERROR: () =>
                                popup.show('WalletChargePopup', {
                                    defaultValue: Math.ceil(
                                        totalCost - userAvailableCoins,
                                    ).toString(),
                                    chargeTitle: 'Opłać zamówienie',
                                    chargeDescription:
                                        'Przelej środki, aby zrealizować zamówienie.',
                                    mobileTransactionFinishedCallback:
                                        handleOrderCreate,
                                    popupTitle: 'Opłać zamówienie',
                                    popupDescription:
                                        'Obecnie nie masz wystarczającej ilości środków, aby złożyć zamówienie.',
                                }),
                            ORDER_RESTAURANT_IS_BROCHURE_ERROR: () => {
                                onDeleteCartOrderFromLocalStorage(
                                    data?.cart.id,
                                );
                                history.replace('/cart');
                            },
                        },
                    })(error);
                },
                refetchQueries: [
                    OPERATIONS.Query.CartCount,
                    OPERATIONS.Query.CartByRestaurant,
                ],
            });
    };

    useWebTransactionFinished({ handleOrderCreate, loading });

    return {
        addresses: userData?.user?.addresses,
        isLoading: orderCreateLoading || cartUpdateLoading,
        loading,
        error: error || cartUpdateError,
        orderWeWorkOn,
        groupData: groupData?.list.data,
        totalGroupsLength,
        fetchMoreGroups: showMoreGroups,
        initialGroupsLength: INITIAL_GROUPS_LENGTH,
        deliveryCost,
        deliveryError,
        setDeliveryCost,
        setDeliveryError,
        handleDownloadPdf,
        onDeleteCartOrderFromLocalStorage,
        parentIsOpen,
        parentSetIsOpen,
        setPhoneError,

        onChange: (e) => {
            if (!e.target.value) return;

            if (e.target.name === 'time' || e.target.name === 'date') {
                const date =
                    e.target.name === 'date'
                        ? e.target.value
                        : dateFormat.standardDate(
                              orderWeWorkOn.consumptionDetails.date,
                          );

                const time =
                    e.target.name === 'time'
                        ? e.target.value
                        : dateFormat.standardTime(
                              orderWeWorkOn.consumptionDetails.date,
                          );

                const dateTimeToIso = dateFormat.dateTimeToIso(date, time);

                const currentDateTime = dateFormat.addHoursToDate(
                    orderWeWorkOn?.consumptionDetails?.type === 'TAKEAWAY'
                        ? 5 / 60
                        : 30 / 60,
                );
                const selectedDateTime = new Date(dateTimeToIso);
                if (selectedDateTime < currentDateTime) {
                    toast.error(
                        `Minimalny czas do zamówienia lub rezerwacji to ${orderWeWorkOn?.consumptionDetails?.type === 'TAKEAWAY' ? '5' : '30'} minut`,
                    );
                    return;
                }

                setOrderWeWorkOn({
                    ...orderWeWorkOn,
                    consumptionDetails: {
                        ...orderWeWorkOn.consumptionDetails,
                        date: dateTimeToIso,
                    },
                });
                cartUpdate({
                    variables: {
                        input: {
                            cartId: orderId,
                            payload: {
                                ...cartDetail,
                                consumptionDetails: {
                                    ...cartDetail.consumptionDetails,
                                    date: dateTimeToIso,
                                },
                            },
                        },
                    },
                });
            } else {
                setOrderWeWorkOn({
                    ...orderWeWorkOn,
                    consumptionDetails: {
                        ...orderWeWorkOn.consumptionDetails,
                        [e.target.name]: e.target.value,
                        date: dateFormat.addHoursToDate(
                            e.target.value === 'TAKEAWAY' ? 5 / 60 : 30 / 60,
                        ),
                    },
                });
                cartUpdate({
                    variables: {
                        input: {
                            cartId: orderId,
                            payload: {
                                ...cartDetail,
                                consumptionDetails: {
                                    ...cartDetail.consumptionDetails,
                                    [e.target.name]: e.target.value,
                                    date: dateFormat.addHoursToDate(
                                        e.target.value === 'TAKEAWAY'
                                            ? 5 / 60
                                            : 30 / 60,
                                    ),
                                },
                            },
                        },
                    },
                });
            }
        },

        onChangeTable(table) {
            if (error || loading) return;
            setOrderWeWorkOn({ ...orderWeWorkOn, table: table });
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            table: !!table?.length ? table : null,
                        },
                    },
                },
                onError: (error) => {
                    graphqlErrorHandler({
                        customCodeMessages: {
                            VALIDATION_FAILED:
                                "Pole 'numer stołu' powinno zawierać max. 5 symboli",
                        },
                        omitErrorCodes: ['CART_NOT_EXISTS_ERROR_CODE'],
                    })(error);
                },
            });
        },

        onChangeNumber(phoneNumber) {
            if (
                error ||
                loading ||
                (!!phoneNumber.length && !phoneNumber?.match(/^\+48\d{9}$/))
            )
                return;
            setOrderWeWorkOn({
                ...orderWeWorkOn,
                phoneNumber: !!phoneNumber.length ? phoneNumber : null,
            });
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            clientPhoneNumber: !!phoneNumber.length
                                ? phoneNumber
                                : null,
                        },
                    },
                },
                onError: (error) => {
                    graphqlErrorHandler({
                        customCodeMessages: {
                            VALIDATION_FAILED:
                                'Niepoprawny format numeru telefonu. Proszę wprowadź poprawny format numeru telefonu (9 cyfr)',
                        },
                    })(error);
                },
            });
        },

        onChangeOrderType(type) {
            if (error || loading) return;
            if (type === 'individual') {
                setOrderWeWorkOn({
                    ...orderWeWorkOn,
                    group: null,
                    orderType: type,
                });
                cartUpdate({
                    variables: {
                        input: {
                            cartId: orderId,
                            payload: {
                                ...cartDetail,
                                groupId: null,
                            },
                        },
                    },
                    onError: graphqlErrorHandler(true),
                });
            } else {
                setOrderWeWorkOn({ ...orderWeWorkOn, orderType: type });
            }
        },

        onChangeServeOnTime(serveOnTime) {
            if (error || loading) return;
            setOrderWeWorkOn({
                ...orderWeWorkOn,
                serveOnTime,
            });
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            serveOnTime,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
        },

        onChangeGroup(group) {
            if (error || loading) return;
            setOrderWeWorkOn({ ...orderWeWorkOn, group: group });
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            groupId: group.id,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
        },

        onChangeAddress(address) {
            setOrderWeWorkOn({
                ...orderWeWorkOn,
                deliveryAddress: address.address,
                deliveryLocation: {
                    coordinates: address.coordinates,
                },
                deliveryDetails: address.additionalInfo,
                deliveryAddressId: address.id ?? null,
                deliveryAddressName: address.name,
            });
            setDeliveryError({ ...deliveryError, address: false });
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            deliveryAddress: address.address,
                            deliveryLocation: {
                                coordinates: address.coordinates,
                            },
                            deliveryDetails: address.additionalInfo,
                            deliveryAddressId: address.id ?? null,
                            deliveryAddressName: address.name,
                        },
                    },
                },
            });
        },

        onSaveNote(note) {
            setOrderWeWorkOn({ ...orderWeWorkOn, note });
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            note: note,
                        },
                    },
                },
            });
        },

        onIsInvoiceChange(invoice) {
            if (invoice) {
                setOrderWeWorkOn({
                    ...orderWeWorkOn,
                    isInvoice: invoice,
                });
                cartUpdate({
                    variables: {
                        input: {
                            cartId: orderId,
                            payload: {
                                ...cartDetail,
                                invoiceDetails: undefined,
                                hasInvoice: invoice,
                            },
                        },
                    },
                });
            } else {
                setOrderWeWorkOn({
                    ...orderWeWorkOn,
                    invoiceDetails: undefined,
                    isInvoice: invoice,
                });
                cartUpdate({
                    variables: {
                        input: {
                            cartId: orderId,
                            payload: {
                                ...cartDetail,
                                invoiceDetails: undefined,
                                hasInvoice: invoice,
                            },
                        },
                    },
                });
            }
        },

        onInvoiceDetailsChange(e) {
            setOrderWeWorkOn({
                ...orderWeWorkOn,
                invoiceDetails: {
                    ...orderWeWorkOn.invoiceDetails,
                    [e.target.name]: e.target.value || undefined,
                },
            });
        },
        onInvoiceDetailsSave(data) {
            cartUpdate({
                variables: {
                    input: {
                        cartId: orderId,
                        payload: {
                            ...cartDetail,
                            invoiceDetails: {
                                ...orderWeWorkOn?.invoiceDetails,
                                ...data,
                            },
                        },
                    },
                },
                refetchQueries: [CartDocument],
            });
        },
        onSendOrder: handleOrderCreate,
    };
};
