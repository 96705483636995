/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { s } from '../../../style';

const SectionHeadline = ({ children, noPaddingLeft, noPaddingTop, sOuter }) => (
    <Grid item xs={12} css={sOuter}>
        <p
            css={[
                {
                    fontSize: '22px',
                    fontWeight: 700,
                    whiteSpace: 'nowrap',
                    letterSpacing: '-0.05em',
                    [s.xs]: { fontSize: '22px' },
                    [s.sm_down]: { padding: '3rem 0rem 0.75rem 0' },
                    [s.md]: { padding: '4rem 0rem 1.5rem 2rem' },
                },
                noPaddingTop && {
                    [s.xs]: { paddingTop: 0 },
                    [s.sm_down]: { paddingTop: 0 },
                    [s.md]: { paddingTop: 0 },
                },
                noPaddingLeft && {
                    [s.xs]: { paddingLeft: 0 },
                    [s.sm_down]: { paddingLeft: 0 },
                    [s.md]: { paddingLeft: 0 },
                },
            ]}
        >
            {children}
        </p>
    </Grid>
);

export default SectionHeadline;
