/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useHistory, useParams } from 'react-router-dom';
import AuthWidget from '@/EZC/views/AuthWidget/AuthWidget.view';
import GetDeliveryInfo from '@/EZC/views/GetDeliveryInfo/GetDeliveryInfo.view';
import useCheckoutNoAuth from '@/EZC/pages/cart/hooks/useCheckoutNoAuth';
import MoneyEz from '@helpers/MoneyEz';
import {
    SendOrderBtn,
    GetRestaurantImage,
    sRestContainer,
} from './SingleCartOrder';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import ChoseType from '@/EZC/pages/cart/components/ChoseType.component';
import InputFields from '@/EZC/pages/cart/components/InputFields.component';
import InvoiceDetails from '@/EZC/pages/cart/components/InvoiceDetails.component';

import { sContainer as sRestContainerExported } from '@/EZC/views/ProductPage/components/RestContainer';
import Headline from '@components/Headline/Headline';
import { TableChairIcon } from '@/svgs/TableChairIcon';
import { s, colors, alpha } from '@style';
import { sOuter, sGrid, sNoMarginTop } from '@style/style';
import { Grid, Hidden, Collapse } from '@material-ui/core';
import { Collapsable, Bullet, Button } from '@components/Elements';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import { dateFc } from '@helpers/date-format';
import PersonPinIcon from '@material-ui/icons/PersonPinCircle';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';
import Input from '@/EZC/views/Auth/components/Input.component';
import { ProductItem } from '@/EZC/pages/cart/components/OrderItem.component';
import PageLoader from '@components/PageLoader/PageLoader';
import OrderPayment from '@/EZC/pages/orders/components/OrderPayment';
import CheckoutNote from '@/EZC/pages/cart/components/CheckoutNote';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useSingleCartOrderQuery } from '@graphql/generated';
import {
    CONSUMPTION_TYPE_ENUM,
    CONSUMPTION_TYPE,
} from '@constants/fromGraphql/ConsumptionType.constants';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { CartServingTime } from '@/componentsV2/molecules/CartServingTime/CartServingTime';
import styled from '@emotion/styled';
import { InputTextNew } from '@/componentsV2/atoms/InputTextNew/InputTextNew';
import PlacesAutocomplete from 'react-places-autocomplete';

export default () => {
    const [isWidgetOpen, setIsWidgetOpen] = useState(false);
    const setIsWidgetOpenTrue = () => setIsWidgetOpen(true);

    const orderId = useParams().id;
    const history = useHistory();
    const {
        loading,
        orderWeWorkOn,
        onChange,
        handleSelectAddress,
        onChangeAddres,
        onIsInvoiceChange,
        onSaveNote,
        setDeliveryCost,
        parentIsOpen,
        parentSetIsOpen,
        deliveryCost,
        handleDownloadPdf,
        onChangeTable,
        onChangeServeOnTime,
        onInvoiceDetailsSave,
    } = useCheckoutNoAuth({ orderId });

    const { data } = useSingleCartOrderQuery({
        variables: { input: { restaurantId: orderWeWorkOn?.restId?.id } },
        onError: graphqlErrorHandler(true),
        skip: !orderWeWorkOn?.restId?.id,
    });

    useEffect(() => {
        if (!orderWeWorkOn && !loading) {
            history.replace('/cart');
        }
    }, [loading, orderWeWorkOn]);

    if (!orderWeWorkOn) {
        return null;
    }

    if (loading) return <PageLoader />;

    const {
        date,
        guests,
        time,
        type,
        deliveryAddress,
        deliveryDetails,
        note,
        isInvoice,
        invoiceDetails,
        restId,
        table,
        serveOnTime,
    } = orderWeWorkOn;

    const productsCost = MoneyEz(0)
        .sumProductArray(
            orderWeWorkOn.lines.map((line) => ({
                amount: line.quantity,
                price: line.product.price,
            })),
        )
        .getAmount();

    const isDelivery = type === CONSUMPTION_TYPE_ENUM.DELIVERY;
    const onSite = type === CONSUMPTION_TYPE_ENUM.IN;
    const toTakeAway = type === CONSUMPTION_TYPE_ENUM.TAKEAWAY;

    const packageCost = onSite
        ? 0
        : orderWeWorkOn.lines.reduce(
              (acc, line) =>
                  acc + (line.product.packagingPrice ?? 0) * line.quantity,
              0,
          );

    return (
        <React.Fragment>
            <TopNav
                title={orderWeWorkOn?.restId?.name}
                subtitle={`- Podsumowanie`}
                photo={orderWeWorkOn?.restId?.avatarUrl}
                textCss={{
                    [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
                }}
            />
            <div css={sOuter}>
                <Headline>Podsumowanie</Headline>
            </div>
            <Hidden mdUp>
                {orderWeWorkOn?.restId ? (
                    <GetRestaurantImage
                        restId={orderWeWorkOn?.restId.id}
                        data={orderWeWorkOn?.restId}
                    />
                ) : (
                    <Skeleton css={sRestContainerExported} />
                )}
            </Hidden>

            <div css={[sOuter, sNoMarginTop, { paddingBottom: 60 }]}>
                {orderWeWorkOn && (
                    <Grid container css={[sGrid]}>
                        {/* TERMIN */}
                        <Grid item xs={12} md={6}>
                            <Collapsable
                                left
                                noPadding
                                oneLiner
                                title={'Termin'}
                                icon={<EventIcon />}
                                description={
                                    <div
                                        css={{
                                            overflowX: 'scroll',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {dateFc(date)}
                                        <Bullet />
                                        {time}
                                        <Bullet />
                                        {guests}
                                        <PersonIcon
                                            fontSize="inherit"
                                            css={{ marginLeft: '0.25rem' }}
                                        />
                                    </div>
                                }
                                extraCss={{
                                    backgroundColor: colors.gray100,
                                }}
                            >
                                {toTakeAway && (
                                    <DescriptionWrapper>
                                        Po akceptacji zamówienia przez
                                        Restaurację wyświetlimy zegar
                                        odliczający czas do odbioru.
                                    </DescriptionWrapper>
                                )}
                                <InputFields
                                    onChange={onChange}
                                    consumptionDetails={{
                                        consumptionDate: date,
                                        consumptionPersons: guests,
                                        consumptionTime: time,
                                    }}
                                />
                            </Collapsable>
                        </Grid>

                        {/* SPOSÓB DOSTAWY */}
                        <Grid item xs={12} md={6}>
                            <Collapsable
                                right
                                noPadding
                                oneLiner
                                title={CONSUMPTION_TYPE[type]?.name}
                                icon={CONSUMPTION_TYPE[type]?.icon}
                                description={
                                    <div css={{ overflowX: 'scroll' }}>
                                        Wybierz Sposób Zamówienia
                                    </div>
                                }
                                extraCss={{ backgroundColor: colors.gray100 }}
                            >
                                <ChoseType
                                    consumptionType={type}
                                    onChange={onChange}
                                    availableTypes={
                                        data?.restaurant?.consumptionTypes || []
                                    }
                                />
                            </Collapsable>
                        </Grid>

                        {onSite && (
                            <Grid item xs={12} md={6}>
                                <Collapse in={onSite}>
                                    <Collapsable
                                        left
                                        noPadding
                                        oneLiner
                                        title={'Stolik'}
                                        icon={<TableChairIcon />}
                                        description={
                                            <div>
                                                Wybierz preferowane miejsce
                                            </div>
                                        }
                                        extraCss={{
                                            backgroundColor: colors.gray100,
                                        }}
                                    >
                                        <div
                                            css={{
                                                padding: '24px',
                                            }}
                                        >
                                            <p
                                                css={{
                                                    color: colors.blueZodiac,
                                                    fontSize: '14px',
                                                }}
                                            >
                                                Kliknij , aby zobaczyć układ
                                                sali restauracji i zdecydować,
                                                gdzie chcesz usiąść.
                                            </p>
                                            <Button
                                                w100
                                                action={() =>
                                                    handleDownloadPdf(
                                                        data?.restaurant
                                                            ?.layoutPdfUrl,
                                                    )
                                                }
                                                css={{
                                                    padding: '24px 0 32px',
                                                }}
                                            >
                                                zobacz układ sali
                                            </Button>
                                            <p
                                                css={{
                                                    color: colors.blueZodiac,
                                                    fontSize: '14px',
                                                    paddingBottom: '32px',
                                                }}
                                            >
                                                Wpisz numer stolika, jeżeli
                                                numer stolika nie zostanie
                                                wybrany, Twój kelner wybierze go
                                                dla ciebie.
                                            </p>
                                            <Input
                                                darker
                                                placeholder="Numer stołu"
                                                value={table}
                                                onChange={onChangeTable}
                                            />
                                        </div>
                                    </Collapsable>
                                </Collapse>
                            </Grid>
                        )}

                        {/* DOSTAWA */}
                        {type === CONSUMPTION_TYPE_ENUM.DELIVERY && (
                            <Grid item xs={12} md={6}>
                                <Collapse
                                    in={type === CONSUMPTION_TYPE_ENUM.DELIVERY}
                                >
                                    <Collapsable
                                        left
                                        noPadding
                                        oneLiner
                                        openAlready={
                                            type ===
                                            CONSUMPTION_TYPE_ENUM.DELIVERY
                                        }
                                        title={'Adres'}
                                        icon={<PersonPinIcon />}
                                        description={
                                            <div
                                                css={{
                                                    overflowX: 'scroll',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {deliveryAddress ? (
                                                    <>
                                                        {`${deliveryAddress}`}
                                                        {deliveryDetails && (
                                                            <>
                                                                <Bullet />
                                                                {
                                                                    deliveryDetails
                                                                }
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    'Podaj adres dostawy'
                                                )}
                                            </div>
                                        }
                                        extraCss={{
                                            backgroundColor: colors.gray100,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <AddressWrapper>
                                            <Description>
                                                Wypełnij adres dostawy.
                                                Obowiązkowe pola to nazwa
                                                adresu, ulica i numer budynku. W
                                                polu „Numer mieszkania,
                                                piętro...” możesz również podać
                                                dodatkowe informacje. Lub
                                                zaloguj się i wybierz adres z
                                                już zapisanych.
                                            </Description>
                                            <PlacesAutocomplete
                                                value={deliveryAddress || ''}
                                                onChange={onChangeAddres}
                                                onSelect={handleSelectAddress}
                                            >
                                                {({
                                                    getInputProps,
                                                    suggestions,
                                                    getSuggestionItemProps,
                                                }) => (
                                                    <InputContainer>
                                                        <InputTextNew
                                                            {...getInputProps()}
                                                            label={
                                                                'ULICA I NUMER BUDYNKU'
                                                            }
                                                            required
                                                            inputVariant={
                                                                'dark'
                                                            }
                                                        />
                                                        <DropdownContainer className="autocomplete-dropdown-container">
                                                            {suggestions.map(
                                                                (
                                                                    suggestion,
                                                                ) => {
                                                                    const className =
                                                                        suggestion.active
                                                                            ? 'suggestion-item--active'
                                                                            : 'suggestion-item';
                                                                    // inline style for demonstration purpose
                                                                    const style =
                                                                        suggestion.active
                                                                            ? {
                                                                                  backgroundColor:
                                                                                      colors.gray300,
                                                                              }
                                                                            : {
                                                                                  cursor: 'pointer',
                                                                              };

                                                                    return (
                                                                        <SugestionContainer
                                                                            {...getSuggestionItemProps(
                                                                                suggestion,
                                                                                {
                                                                                    className,
                                                                                    style,
                                                                                },
                                                                            )}
                                                                            key={
                                                                                suggestion.index
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    suggestion.description
                                                                                }
                                                                            </span>
                                                                        </SugestionContainer>
                                                                    );
                                                                },
                                                            )}
                                                        </DropdownContainer>
                                                    </InputContainer>
                                                )}
                                            </PlacesAutocomplete>
                                            <InputTextNew
                                                name="deliveryDetails"
                                                onChange={onChange}
                                                value={deliveryDetails}
                                                label="NUMER MIESZKANIA, PIĘTRO..."
                                                fullWidth
                                                inputVariant={'dark'}
                                            />
                                        </AddressWrapper>
                                        <GetDeliveryInfo
                                            restId={restId.id}
                                            orderAmount={
                                                productsCost + packageCost
                                            }
                                            setDeliveryCost={setDeliveryCost}
                                        />
                                    </Collapsable>
                                </Collapse>
                            </Grid>
                        )}
                        {/* CZAS PODANIA */}
                        {onSite && (
                            <CartServingTime
                                padding="0 0 16px 12px"
                                serveOnTime={serveOnTime}
                                onChangeServeOnTime={onChangeServeOnTime}
                            />
                        )}

                        {/* PRODUKTY */}
                        <Grid item xs={12} md={6}>
                            <Collapsable
                                right={!toTakeAway && !onSite}
                                left={toTakeAway || onSite}
                                noPadding
                                oneLiner
                                title={'Produkty'}
                                icon={<FastfoodIcon />}
                                description={
                                    <div
                                        css={{
                                            overflowX: 'scroll',
                                            fontWeight: 400,
                                        }}
                                    >
                                        <span css={{ color: colors.text }}>
                                            {(
                                                productsCost + packageCost
                                            ).toFixed(2)}
                                        </span>{' '}
                                        zł
                                        <Bullet />
                                        <>
                                            {orderWeWorkOn.lines.reduce(
                                                (sum, curr) =>
                                                    curr.quantity + sum,
                                                0,
                                            )}
                                        </>
                                        <FastfoodIcon
                                            fontSize="inherit"
                                            css={{
                                                marginLeft: '0.25rem',
                                                [s.md]: {
                                                    marginLeft: '0.5rem',
                                                },
                                            }}
                                        />
                                    </div>
                                }
                            >
                                <>
                                    {orderWeWorkOn.lines.map(
                                        (line, productId) => (
                                            <ProductItem
                                                id={line.product.id}
                                                key={productId}
                                                amount={line.quantity}
                                                name={line.product.name}
                                                price={line.product.price}
                                                photoUrl={line.product.photoUrl}
                                                packagingPrice={
                                                    type !== 'IN' &&
                                                    line.product.packagingPrice
                                                        ? line.product
                                                              .packagingPrice *
                                                          line.quantity
                                                        : null
                                                }
                                            />
                                        ),
                                    )}
                                    <div
                                        css={{
                                            [s.sm_down]: {
                                                padding: '1.5rem 1rem',
                                            },
                                            [s.md]: { padding: '2rem' },
                                        }}
                                    >
                                        <Button
                                            dimmed
                                            w100
                                            link={`/restaurant/${orderWeWorkOn.restId.slug}?menu=true`}
                                        >
                                            Edytuj produkty
                                        </Button>
                                    </div>
                                </>
                            </Collapsable>
                        </Grid>

                        {/* NOTATKA */}
                        <CheckoutNote
                            note={note}
                            onSaveNote={onSaveNote}
                            toTakeAway={toTakeAway || onSite}
                        />

                        {/* FAKTURA */}
                        <Grid item xs={12} md={6}>
                            <Collapsable
                                right={!toTakeAway && !onSite}
                                left={toTakeAway || onSite}
                                noPadding
                                oneLiner
                                parentIsOpen={parentIsOpen}
                                parentSetIsOpen={parentSetIsOpen}
                                title={'Faktura'}
                                icon={<DocumentIcon />}
                                description={
                                    isInvoice ? (
                                        <div css={{ overflow: 'scroll' }}>
                                            {invoiceDetails?.name ? (
                                                <>
                                                    {invoiceDetails?.name}
                                                    <Bullet />
                                                </>
                                            ) : (
                                                'Uzupełnij dane firmy '
                                            )}
                                            {invoiceDetails?.nip && (
                                                <>
                                                    {invoiceDetails?.nip}
                                                    <Bullet />
                                                </>
                                            )}
                                            {invoiceDetails?.address && (
                                                <>
                                                    {invoiceDetails?.address}
                                                    <Bullet />
                                                </>
                                            )}
                                            {invoiceDetails?.postalCode && (
                                                <>
                                                    {invoiceDetails?.postalCode}
                                                    <Bullet />
                                                </>
                                            )}
                                            {invoiceDetails?.city && (
                                                <>
                                                    {invoiceDetails?.city}
                                                    <Bullet />
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        'Utwórz fakturę do zamówienia'
                                    )
                                }
                            >
                                <InvoiceDetails
                                    onInvoiceDetailsSave={onInvoiceDetailsSave}
                                    isInvoice={isInvoice}
                                    onIsInvoiceChange={onIsInvoiceChange}
                                    invoiceDetails={invoiceDetails}
                                    onInvoiceDetailsChange={() => {}}
                                    parentSetIsOpen={parentSetIsOpen}
                                />
                            </Collapsable>
                        </Grid>

                        {/* PŁATNOŚĆ */}
                        <OrderPayment
                            isDelivery={isDelivery}
                            deliveryCost={isDelivery && deliveryCost}
                            showDeliveryCost={typeof deliveryCost === 'number'}
                            productsCost={productsCost.toFixed(2)}
                            packageCost={
                                type !== 'IN' ? packageCost.toFixed(2) : null
                            }
                            totalCost={
                                isDelivery
                                    ? (
                                          packageCost +
                                          productsCost +
                                          (deliveryCost ?? 0)
                                      ).toFixed(2)
                                    : (packageCost + productsCost).toFixed(2)
                            }
                            toTakeAway={toTakeAway || onSite}
                        />
                        <Grid item xs={12}>
                            <p
                                css={{
                                    padding: '1.5rem 1rem 0rem',
                                    fontSize: '11px',
                                    color: colors.text.concat(alpha[60]),
                                    a: {
                                        color: colors.text,
                                        fontWeight: 700,
                                        textDecoaration: 'underline',
                                    },
                                }}
                            >
                                Przesyłając zamówienie, potwierdzasz zapoznanie
                                się z <Link to="/terms">Regulaminem</Link> oraz{' '}
                                <Link to="/privacy">Politką Prywatności</Link>
                            </p>
                        </Grid>
                    </Grid>
                )}
            </div>

            <Hidden smDown>
                <div css={sRestContainer}>
                    {restId && (
                        <GetRestaurantImage restId={restId.id} data={restId} />
                    )}
                </div>
            </Hidden>

            <SendOrderBtn
                onSendOrder={() => {
                    orderWeWorkOn?.isInvoice
                        ? orderWeWorkOn?.invoiceDetails
                            ? setIsWidgetOpenTrue()
                            : toast.error('Podaj dane do faktury')
                        : setIsWidgetOpenTrue();
                }}
            />

            <AuthWidget
                isWidgetOpen={isWidgetOpen}
                setIsWidgetOpen={setIsWidgetOpen}
            />
        </React.Fragment>
    );
};

const DescriptionWrapper = styled.p`
    padding: 24px 24px 0;
    color: ${colors.gray};
    font-size: 13px;
`;

const AddressWrapper = styled.div`
    display: grid;
    gap: 16px;

    ${s.sm_down} {
        padding: 24px;
    }

    ${s.md} {
        padding: 32px 32px;
    }
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
`;

const InputContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div`
    margin-top: 2px;
    padding: 0 0;
    width: 100%;
    position: absolute;
    z-index: 9;
    top: 100%;
    background: ${colors.white};
`;

const SugestionContainer = styled.div`
    border-radius: 32px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 16px;
    width: 100%;

    &:last-child {
        margin-bottom: 20px;
    }
`;
