import { colors } from '@style/index';
import styled from '@emotion/styled';
import NiceModal from '@ebay/nice-modal-react';
import { usePopup } from '@/AppPopups';
import { useDeleteAddressMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { BasePopupNew } from '@/componentsV2/organisms/BasePopupNew/BasePopupNew';

export interface AddressDeletePopupProps {
    id: string;
}

export const AddressDeletePopup = NiceModal.create(
    ({ id }: AddressDeletePopupProps) => {
        const popup = usePopup();
        const [deleteAddress, { loading }] = useDeleteAddressMutation();

        return (
            <BasePopupNew
                overlay
                contentPadding="0 24px"
                header={<Header>Usuń adres</Header>}
                footer={
                    <ButtonsWrapper>
                        <Button
                            width="100%"
                            variant="lightGray"
                            onClick={() => popup.remove()}
                        >
                            Anuluj
                        </Button>
                        <Button
                            width="100%"
                            variant="red"
                            onClick={() => {
                                deleteAddress({
                                    variables: { input: { addressId: id } },
                                    onError: graphqlErrorHandler(true),
                                    onCompleted: () => {
                                        popup.remove();
                                        toast.dark('Adres został usunięty');
                                    },
                                    update: (cache) => {
                                        const normalizedId = cache.identify({
                                            id,
                                            __typename: 'UserAddress',
                                        });
                                        cache.evict({ id: normalizedId });
                                        cache.gc();
                                    },
                                });
                            }}
                            loading={loading}
                        >
                            USUŃ ADRES
                        </Button>
                    </ButtonsWrapper>
                }
            >
                <PopupDescription>
                    Czy napewno chcesz usunąć adres?
                </PopupDescription>
            </BasePopupNew>
        );
    },
);

const Header = styled.h2`
    color: ${colors.text};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding: 48px 24px 24px;
`;

const PopupDescription = styled.p`
    color: ${colors.text};
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    padding-bottom: 40px;
`;

const ButtonsWrapper = styled.div`
    display: grid;
    gap: 13px;
    padding: 24px 24px 45px;
`;
