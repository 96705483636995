import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { filterKeysFromObject } from '@helpers/filterKeysFromObject';

interface CommonStore {
    browsingHistory: { products: string[]; searchPhrases: string[] };
    addProductsBrowsingHistory: (productId: string) => void;
    addSearchPhrasesBrowsingHistory: (productId: string) => void;
    clearSearchPhraseBrowsingHistory: (productId?: string) => void;
    isPwaChecked: boolean;
    setIsPwaChecked: (isChecked: boolean) => void;
}

const SEARCH_PHRASES_LENGTH = 100;

export const useCommonStore = create<CommonStore>()(
    devtools(
        persist(
            (set, get) => ({
                filterIngredients: [],
                isPwaChecked: false,
                setIsPwaChecked: (isPwaChecked) => {
                    set({
                        isPwaChecked,
                    });
                },
                browsingHistory: { products: [], searchPhrases: [] },
                addProductsBrowsingHistory: (productId) => {
                    set(
                        ({
                            browsingHistory: {
                                products,
                                ...restBrowsingHistory
                            },
                        }) => {
                            if (products?.includes(productId)) {
                                const newProductsBrowsingHistory =
                                    products.filter(
                                        (item) => item !== productId,
                                    );
                                return {
                                    browsingHistory: {
                                        ...restBrowsingHistory,
                                        products: [
                                            productId,
                                            ...newProductsBrowsingHistory,
                                        ],
                                    },
                                };
                            }
                            const updatedProducts = [productId, ...products];

                            if (updatedProducts.length > 20) {
                                updatedProducts.pop();
                            }

                            return {
                                browsingHistory: {
                                    ...restBrowsingHistory,
                                    products: updatedProducts,
                                },
                            };
                        },
                    );
                },
                addSearchPhrasesBrowsingHistory: (phrase) => {
                    set(
                        ({
                            browsingHistory: {
                                searchPhrases,
                                ...restBrowsingHistory
                            },
                        }) => {
                            if (searchPhrases?.includes(phrase))
                                return {
                                    browsingHistory: {
                                        ...restBrowsingHistory,
                                        searchPhrases: [
                                            phrase,
                                            ...searchPhrases?.filter(
                                                (item) => item !== phrase,
                                            ),
                                        ],
                                    },
                                };

                            const updatedPhrases = [phrase, ...searchPhrases];

                            if (updatedPhrases.length > SEARCH_PHRASES_LENGTH) {
                                updatedPhrases.pop();
                            }

                            return {
                                browsingHistory: {
                                    ...restBrowsingHistory,
                                    searchPhrases: updatedPhrases,
                                },
                            };
                        },
                    );
                },
                clearSearchPhraseBrowsingHistory: (phrase) => {
                    set(
                        ({
                            browsingHistory: {
                                searchPhrases,
                                ...restBrowsingHistory
                            },
                        }) => {
                            if (!phrase) {
                                return {
                                    browsingHistory: {
                                        ...restBrowsingHistory,
                                        searchPhrases: [],
                                    },
                                };
                            }

                            return {
                                browsingHistory: {
                                    ...restBrowsingHistory,
                                    searchPhrases: searchPhrases.filter(
                                        (item) => item !== phrase,
                                    ),
                                },
                            };
                        },
                    );
                },
            }),
            {
                name: 'store',
                partialize: (state) =>
                    filterKeysFromObject(state, ['filterIngredients']),
            },
        ),
    ),
);

export const CommonStore = useCommonStore.getState;
