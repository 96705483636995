import React, { useEffect, useMemo, useState } from 'react'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Collapsable } from '@components/Elements';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { CollapsableText } from '../../../../../components/Elements/Collapsable/Collapsable';
import Input from '../../../../views/Auth/components/Input.component';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
    useUserFigureImageUploadLazyQuery,
    useUserUpdateMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { getDirtyValues } from '@helpers/getDirtyValues';
import { toast } from 'react-toastify';
import { parseFormValuesToNull } from '@helpers/parseFormValuesToNull';
import { useAuthService } from '@/services/AuthService';
import { UploadFigureImage } from '@/EZC/domains/Account/molecules/UploadFigureImage/UploadFigureImage';
import { Button } from '@/componentsV2/molecules/Button/Button';
import styled from '@emotion/styled';
import { useUploadImage } from '@hooks/useUploadImage';
import { imagePreview as getFile } from '@helpers/image-preview';
import { MAX_IMAGE_SIZE } from '@constants/files';
import { CLIENT_ERROR_MESSAGES } from '@constants/errors/clientErrorMessages';
import { getFileType } from '@helpers/getFileType';
import { checkImageExtension } from '@helpers/image.helpers';

const SettingsPersonal = ({ email, userPersonal }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState(undefined);

    const { user } = useAuthService((state) => state);
    const [userUpdate, { loading }] = useUserUpdateMutation();

    const [userFigureImageUpload] = useUserFigureImageUploadLazyQuery();

    const { uploadImage, loading: uploadImageLoading } = useUploadImage(
        userFigureImageUpload,
    );

    const getFormValues = (email, userPersonal) => ({
        address: userPersonal.address || '',
        email: email || '',
        figureUrl: null,
        height: userPersonal.height || '',
        iban: userPersonal.iban || '',
        name: userPersonal.name || '',
        phone: userPersonal.phone || '',
        surname: userPersonal.surname || '',
        targetWeight: userPersonal.targetWeight || '',
        weight: userPersonal.weight || '',
    });

    const defaultValues = useMemo(
        () => getFormValues(email, userPersonal),
        [userPersonal, email],
    );

    const isRestaurateur = user?.userType === 'Restaurateur';

    const {
        setValue,
        register,
        reset,
        handleSubmit,
        formState: { dirtyFields, isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(SettingsPersonalSchema),
        defaultValues,
    });

    const onReset = () => {
        reset(defaultValues);
        setIsOpen(false);
        setImagePreview(undefined);
    };

    const onImageChange = (event) => {
        const file = event.target.files?.[0];

        if (file) {
            getFile(event.target.files?.[0])
                .then((file) => {
                    setImagePreview(file);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleFormSubmit = handleSubmit(
        async (formData) => {
            if (loading) return;
            if (!isDirty) return setIsOpen(false);

            const imageIsChange = formData.figureUrl !== userPersonal.figureUrl;
            const file = formData.figureUrl?.[0];
            const figureUrl =
                file && imageIsChange
                    ? await uploadImage(file, '240x240')
                    : undefined;

            const { email, ...personal } = parseFormValuesToNull(
                getDirtyValues(dirtyFields, formData),
            );

            userUpdate({
                variables: {
                    input: {
                        payload: {
                            email,
                            personal: { ...personal, figureUrl },
                        },
                    },
                },
                onCompleted: (data) => {
                    reset(getFormValues(data.user.email, data.user.personal));
                    setIsOpen(false);
                    setImagePreview(undefined);

                    toast.dark('Dane użytkownika zostały zaktualizowane');
                    if (email)
                        toast.dark(
                            'Link do zmiany adresu email został wysłany na podany adres mailowy.',
                        );
                },
                onError: graphqlErrorHandler(true),
            });
        },
        (errors) =>
            Object.values(errors).forEach((err) => toast.error(err.message)),
    );

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                left
                noPadding
                oneLiner
                title={'Użytkownik'}
                icon={<AccountCircleIcon />}
                description={`Informacje personalne`}
                parentIsOpen={isOpen}
                parentSetIsOpen={setIsOpen}
            >
                <CollapsableText>
                    <Input {...register('name')} placeholder="Imię" darker />
                    <Input
                        {...register('surname')}
                        placeholder="Nazwisko"
                        darker
                    />
                    <Input
                        {...register('email')}
                        type="email"
                        placeholder="Email"
                        darker
                    />
                    <Input
                        {...register('address')}
                        placeholder="Adres"
                        darker
                    />
                    <Input
                        {...register('phone', {
                            onBlur: (event) => {
                                const currentValue = event.target.value;
                                if (currentValue?.length < 4) {
                                    setValue('phone', '');
                                }
                                return event;
                            },
                        })}
                        onFocus={(event) => {
                            const currentValue = event.target.value;
                            if (!currentValue) {
                                setValue('phone', '+48');
                            }
                            return event;
                        }}
                        placeholder="Numer telefonu"
                        darker
                    />
                    {isRestaurateur && (
                        <Input
                            {...register('iban')}
                            placeholder="Numer konta bankowego"
                            darker
                        />
                    )}
                    <Input
                        {...register('weight')}
                        darker
                        placeholder="Masa ciała"
                        type="number"
                    />
                    <Input
                        {...register('targetWeight')}
                        darker
                        placeholder="Docelowa masa ciała"
                        type="number"
                    />
                    <Input
                        {...register('height')}
                        darker
                        placeholder="Wzrost"
                        type="number"
                    />
                    <UploadFigureImage
                        {...register('figureUrl', {
                            onChange: onImageChange,
                        })}
                        image={imagePreview || userPersonal?.figureUrl}
                        loading={uploadImageLoading}
                    />
                    <ButtonsWrapper>
                        <CircleButton
                            variant="gray"
                            loading={loading}
                            onClick={onReset}
                            disabled={loading}
                        >
                            <CloseIcon />
                        </CircleButton>
                        <Button
                            width="100%"
                            variant="black"
                            loading={loading}
                            onClick={handleFormSubmit}
                        >
                            Zapisz
                        </Button>
                    </ButtonsWrapper>
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

const CircleButton = styled(Button)`
    height: 48px;
    padding: 0;

    div {
        display: flex;
    }
`;

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 48px 1fr;
    grid-gap: 16px;
`;

export default SettingsPersonal;

export const SettingsPersonalSchema = yup.object({
    name: yup.string().trim().required('Wprowadź imię'),
    surname: yup.string().trim().required('Wprowadź nazwisko'),
    email: yup
        .string()
        .trim()
        .test('isValidEmail', 'Wprowadź prawidłowy email', (value) => {
            return /^[A-Za-z0-9+_.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
                value,
            );
        })
        .required('Wprowadź prawidłowy email'),
    address: yup.string(),
    phone: yup
        .string()
        .trim()
        .test(
            'isValidPhone',
            'Niepoprawny format numeru telefonu. Wprowadź numer telefonu w formacie 9 cyfr.',
            (value) => {
                if (!value) return true;
                if (typeof value === 'string') {
                    return /^\+48\d{9}$/.test(value);
                }
                return false;
            },
        ),
    iban: yup.string().trim().iban('Wprowadź prawidłowy numer konta bankowego'),
    weight: yup
        .number()
        .nullable()
        .transform((_, val) => (!isNaN(Number(val)) ? Number(val) : null)),
    targetWeight: yup
        .number()
        .nullable()
        .transform((_, val) => (!isNaN(Number(val)) ? Number(val) : null)),
    height: yup
        .number()
        .nullable()
        .transform((_, val) => (!isNaN(Number(val)) ? Number(val) : null)),
    figureUrl: yup
        .mixed()
        .nullable()
        .test(
            'fileSize',
            CLIENT_ERROR_MESSAGES.LONG_MAX_IMAGE_SIZE,
            (value) => {
                if (!value?.[0]) return true;

                return value[0].size <= MAX_IMAGE_SIZE;
            },
        )
        .test(
            'imageType',
            CLIENT_ERROR_MESSAGES.INCORRECT_IMAGE_TYPE,
            (value) => {
                if (!value?.[0]) return true;

                const type = getFileType(value[0]);
                return checkImageExtension(type);
            },
        ),
});
