/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useContext, useEffect, useState, useCallback } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';

// Components
import PageLoader from '@components/PageLoader/PageLoader';
// import Layout from '../../components/Layout'
// import { ProdRestContainer } from '../../../components/Containers'
import OrderInfo from '@/EZC/components/BottomOrderInfo/BottomOrderInfo.component';

import { s, globals } from '@style';

// Views
import { RestaurantMenuView } from '@/EZC/views/RestaurantPage';
import { useOrderInCartByRestaurant } from '@/EZC/context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider';
import { Grid } from '@material-ui/core';
import BackgroundBlur from '@/EZC/views/ProductPage/components/BackgroundBlur';
import Intro from '@/EZC/views/ProductPage/components/Intro';
import Location from '@/EZC/views/ProductPage/components/Location';
import ConsumptionTypes from '@/EZC/views/ProductPage/components/ConsumptionTypes';
import Availability from '@/EZC/views/ProductPage/components/Availability';
import Delivery from '@/EZC/views/ProductPage/components/Delivery';
import InActivity from '@/EZC/views/ProductPage/components/InActivity';
import NavRestMenu from '@/EZC/views/RestaurantPage/components/NavRestMenu';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useRestaurantPageQuery } from '@graphql/generated';
import { priceFormat } from '@helpers/priceFormat';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { RestaurantAvatar } from '@/svgs/RestaurantAvatar';
import { Metadata } from '@/componentsV2/atoms/Metadata/Metadata';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import { dateFormat } from '@helpers/dateFormat';
import { EatzonIcon } from '@/componentsV2/atoms/EatzonIcon/EatzonIcon';
import InstagramIcon from '@material-ui/icons/Instagram';
import { FacebookIcon } from '@/svgs/SocialIcon';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { mediaQueriesMaterial } from '@style/breakpoints';

const Restaurant = () => {
    const restId = useParams().id;
    const history = useHistory();
    const searchParams = useLocation().search;
    const { geolocation } = useGeolocationContext();

    const { data: { restaurant = undefined } = {}, loading } =
        useRestaurantPageQuery({
            variables: {
                input: { slug: restId },
                distance: {
                    coordinates: [geolocation.lng, geolocation.lat],
                },
            },
            onError: (error) => {
                graphqlErrorHandler(true)(error);
                history.push('/404');
            },
        });

    const { setRestId, loadingOrders, order } = useOrderInCartByRestaurant();

    const [menuDiv, setMenuDiv] = useState(null);
    const measuredRef = useCallback((node) => {
        if (node !== null) setMenuDiv(node);
    }, []);

    useEffect(() => {
        setRestId(restaurant?.id);
    }, [restaurant]);

    const isMenuSections = !!restaurant?.menu?.length;

    useEffect(() => {
        if (isMenuSections && searchParams && menuDiv) {
            const isScrollToMenu =
                searchParams
                    .slice(1)
                    .split('&')
                    .find((el) => el.includes('menu'))
                    .split('=')[1] === 'true';

            if (isScrollToMenu) {
                setTimeout(() => {
                    menuDiv.scrollIntoView();
                }, 10);
            }
        }
    }, [isMenuSections, searchParams, menuDiv]);

    if (loading || loadingOrders) return <PageLoader />;

    if (!restaurant) {
        return null;
    }

    const {
        isBrochure,
        website,
        instagram,
        facebook,
        menu,
        name,
        photoUrl,
        avatarUrl,
        description,
        openingHours,
        consumptionTypes,
        deliverySettings,
    } = restaurant;

    const menuDate = menu[0]?.updatedAt;

    const sum = order?.lines
        ?.map(({ quantity, product: { price } }) => quantity * price)
        ?.reduce((product1, product2) => product1 + product2, 0);

    const sortedMenu = menu.slice().sort((a, b) => a?.order - b?.order);

    return (
        <React.Fragment>
            <Metadata
                title={`Eatzon - ${name}`}
                description={description}
                ogImage={avatarUrl}
            />
            <TopNav
                title={name}
                photo={avatarUrl}
                textCss={{ maxWidth: `calc(100vw - ${48 + 32 + 30 + 36}px)` }}
                extraCssOuter={{ boxShadow: 'none' }}
            >
                <EatzonIcon isInactive={isBrochure} size={18} space={6} />
            </TopNav>
            <NavRestMenu items={sortedMenu.map((section) => section.name)} />
            <BackgroundBlur title={name} photo={photoUrl} />
            <Intro
                title={name}
                photo={photoUrl}
                photoAvatar={
                    avatarUrl ? (
                        <img src={avatarUrl} alt={name} loading="lazy" />
                    ) : (
                        <RestaurantAvatar />
                    )
                }
                description={description}
                isBrochure={isBrochure}
                address={restaurant.address}
            >
                {!restaurant.isActive && (
                    <InActivity>Restauracja tymczasowo nieaktywna</InActivity>
                )}
            </Intro>
            <Grid container css={sTabs}>
                <GridWithOrder item xs={12} md={6} order={2}>
                    <Availability
                        left={!isBrochure}
                        right={isBrochure}
                        hours={convertTimestamps.fromISO(openingHours)}
                        type="restaurant"
                        isRestActive={restaurant.isActive}
                    />
                </GridWithOrder>
                {!isBrochure && (
                    <GridWithOrder item xs={12} md={6} order={2}>
                        <Delivery
                            deliveryData={deliverySettings}
                            restId={restaurant?.id}
                            isDelivery={consumptionTypes.includes('DELIVERY')}
                            isRestActive={restaurant.isActive}
                        />
                    </GridWithOrder>
                )}

                <GridWithOrder item xs={12} md={6} order={2}>
                    <ConsumptionTypes consumptionTypes={consumptionTypes} />
                </GridWithOrder>
                <GridWithOrder item xs={12} md={6} order={isBrochure ? 1 : 2}>
                    <Location
                        id={restaurant?.id}
                        address={restaurant.address}
                        location={restaurant.location}
                        right={!isBrochure}
                        left={isBrochure}
                    />
                </GridWithOrder>
                {isBrochure && (
                    <RestauratorBrochureInfo item xs={12} md={6}>
                        <Accordion
                            headerElement={
                                <ContentWrapper>
                                    <IconWrapper>
                                        <RestaurantIcon />
                                    </IconWrapper>
                                    <InfoWrapper>
                                        <Title>Restaurator Eatzon</Title>
                                        <Description>
                                            Jesteś właścicielem tej restauracji,
                                            dodają ją do swojego konta Eatzon.
                                        </Description>
                                    </InfoWrapper>
                                </ContentWrapper>
                            }
                        >
                            <Wrapper>
                                Zgłoś własność wysyłając maila na{' '}
                                <b>info@eatzon.pl</b>
                            </Wrapper>
                        </Accordion>
                    </RestauratorBrochureInfo>
                )}
            </Grid>
            <RestaurantMenuView
                measuredRef={measuredRef}
                menuSections={sortedMenu}
                restaurant={restaurant}
                isBrochure={isBrochure}
                menuDate={
                    <MenuAddedDate>
                        <b>Data dodania menu:</b>
                        {dateFormat.dayMonthYearDate(menuDate)}
                    </MenuAddedDate>
                }
            />
            {isBrochure && (
                <SocialsWrapper>
                    {website && (
                        <a
                            href={website}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {website.replace(/^https:\/\//, '')}
                        </a>
                    )}
                    <SocialsIconsWrapper>
                        {instagram && (
                            <a
                                href={instagram}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <InstagramIcon />
                            </a>
                        )}
                        {facebook && (
                            <a
                                href={facebook}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FacebookIcon size={'22'} />
                            </a>
                        )}
                    </SocialsIconsWrapper>
                </SocialsWrapper>
            )}
            {!isBrochure && (
                <OrderInfo
                    mainText={priceFormat.standardPrice(sum)}
                    btnOnClick={() =>
                        history.push(`/cart-checkout/${order?.id}`)
                    }
                    isShow={order}
                />
            )}
        </React.Fragment>
    );
};

const sTabs = {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    maxWidth: globals.maxWidthMedium,
    margin: '0 auto',
    paddingBottom: 0,
    [s.md]: { flexDirection: 'row', paddingTop: '2.5rem' },
};

const MenuAddedDate = styled.span`
    background-color: ${colors.text};
    border-radius: 16px;
    color: ${colors.white};
    display: flex;
    font-size: 11px;
    justify-content: space-between;
    padding: 8px 12px;
    width: 100%;
`;

const Wrapper = styled.div`
    color: ${colors.gray600};
    font-size: 13px;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const RestauratorBrochureInfo = styled(Grid)`
    margin-bottom: 16px;
    padding-left: 12px;
    order: 3;

    ${mediaQueriesMaterial.sm} {
        padding-left: 0;
    }
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 12px;
`;

const SocialsWrapper = styled.div`
    display: grid;
    font-size: 13px;
    font-weight: 500;
    grid-template-columns: 1fr 60px;
    line-height: 20px;
    padding: 0 24px 24px;
`;

const SocialsIconsWrapper = styled.span`
    display: flex;
    gap: 10px;
`;

const GridWithOrder = styled(Grid)(
    ({ order }) => css`
        order: ${order};
    `,
);

export default Restaurant;
