/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { lightFormat } from 'date-fns';

import {
    FindRestaurant,
    Button,
    PopupContainer,
    SearchResultsRestaurants,
} from '@/EZAdmin/components';
import { useCalendarPopup } from './useCalendarPopup';
import CsvCalendar from './views/CsvCalendar';
import { useFindRestaurant } from '@/EZAdmin/hooks/useFindRestaurant';
import { useTransactionsCsvDataLazyQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import styled from '@emotion/styled';
import { generateCsvFile } from '@helpers/generateCsvFile';

const EzAdminTransactionsByRestaurants = () => {
    const {
        startDate,
        endDate,
        state,
        setState,
        onPopupOpen,
        isPopupOpen,
        onPopupClose,
    } = useCalendarPopup();

    const [selectedRestaurantId, setSelectedRestaurantId] = useState('');

    const {
        getRestaurants,
        data,
        loading,
        hasMore,
        setSearch,
        findRestaurant,
    } = useFindRestaurant(false);

    const [getTransactionsCsv, { data: csvData, loading: isCsvDataLoading }] =
        useTransactionsCsvDataLazyQuery();

    const ItemHelperComponent = ({ restaurantId }) => (
        <Button
            onClick={() => {
                setSelectedRestaurantId(restaurantId);
                onPopupOpen();
            }}
        >
            Wybierz
        </Button>
    );

    const onGenerateCsv = () => {
        getTransactionsCsv({
            variables: {
                input: {
                    restaurantId: selectedRestaurantId,
                },
                startDate,
                endDate,
            },
            onCompleted: ({ restaurant: { id, profitCsvData } }) => {
                const filename = `${id}-${startDate}-${endDate}`;
                generateCsvFile(profitCsvData, filename);
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return (
        <React.Fragment>
            <ListWrapper>
                <FindRestaurant
                    getRestaurant={getRestaurants}
                    loading={loading}
                    findRestaurant={findRestaurant}
                    setSearch={setSearch}
                    isActive={false}
                />
                <SearchResultsRestaurants
                    restaurants={data?.restaurantList?.data}
                    ItemHelperComponent={ItemHelperComponent}
                />
                {hasMore && (
                    <ButtonShowMore onClick={() => getRestaurants()}>
                        Pokaż więcej
                    </ButtonShowMore>
                )}
            </ListWrapper>

            <PopupContainer
                isOpen={isPopupOpen}
                onClose={onPopupClose}
                headerText="Podaj zakres dat i pobierz plik"
                btnText={`Pobierz plik csv 
          od ${startDate} 
          do ${endDate}`}
                btnOnClick={onGenerateCsv}
                css={{ 'div.MuiDialog-paperWidthSm': { maxWidth: 700 } }}
            >
                <CsvCalendar state={state} setState={setState} />
            </PopupContainer>
        </React.Fragment>
    );
};

const ListWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 70%;
`;

const ButtonShowMore = styled(Button)`
    margin: 16px 0 32px;
`;

export default EzAdminTransactionsByRestaurants;
