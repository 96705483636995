/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import { Button, FindUserByEmail, UserItem } from '@/EZSuperAdmin/components';
import {
    useAdminFindUsersLazyQuery,
    useAdminUpdateUserMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

const EzAdminMakeRestaurateur = () => {
    const [adminFindUsers, { data, error, refetch, loading }] =
        useAdminFindUsersLazyQuery();
    const [adminUpdateUser, { loading: updateUserLoading }] =
        useAdminUpdateUserMutation();

    const handleGetUser = (email) => {
        adminFindUsers({
            variables: { input: { query: { email } } },
            onError: graphqlErrorHandler(true),
        });
    };

    if (error) return null;

    const handleChangeRole = async () => {
        try {
            const { id } = data.adminFindUsers.data[0];
            await adminUpdateUser({
                variables: {
                    input: {
                        userId: id,
                        payload: { type: 'Admin' },
                    },
                },
                onCompleted: () => {
                    toast.info('Rola użytkownika została zmieniona');
                    refetch();
                },
                onError: graphqlErrorHandler(true),
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <FindUserByEmail getUser={handleGetUser} loading={loading} />
            {data?.adminFindUsers && (
                <UserItem
                    {...data.adminFindUsers.data[0]}
                    helperComponent={
                        <Button
                            onClick={handleChangeRole}
                            disabled={updateUserLoading}
                        >
                            Zmień rolę
                        </Button>
                    }
                />
            )}
        </div>
    );
};

export default EzAdminMakeRestaurateur;
