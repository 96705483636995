import React from 'react'; // eslint-disable-line no-unused-vars
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sDescription, sGrid, sOuter } from '../../../style/style';
import Headline from '../../../components/Headline/Headline';
import { ButtonBorder, SectionHeadline } from '../../../components/Elements';
import { Grid } from '@material-ui/core';
import { s } from '../../../style';
import ActiveOrderItem from '../../../EZC/pages/orders/components/ActiveOrderItem';
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder';

import { useOrdersHistoryLazyQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import PersonIcon from '@material-ui/icons/Person';

const HistoryPage = () => {
    const { restaurantId = '' } = useWaiterService();

    const [getOrdersClosed, { data: dataClosed, loading: loadingClosed }] =
        useOrdersHistoryLazyQuery();
    const { getMoreData: getMoreClosedData, hasMore: hasMoreClosed } =
        useShowMorePagination({
            callback: (pagination) =>
                getOrdersClosed({
                    context: { waiter: true },
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        input: {
                            restaurantId,
                            filters: {
                                statuses: ['CLOSED'],
                            },
                        },
                        pagination,
                    },
                    onError: graphqlErrorHandler(true),
                }),
            metadata: dataClosed?.orders.metadata,
        });

    const [getRejected, { data: dataRejected, loading: loadingRejected }] =
        useOrdersHistoryLazyQuery();
    const { getMoreData: getMoreRejectedData, hasMore: hasMoreRejected } =
        useShowMorePagination({
            callback: (pagination) =>
                getRejected({
                    context: { waiter: true },
                    fetchPolicy: 'cache-and-network',
                    variables: {
                        input: {
                            restaurantId,
                            filters: {
                                statuses: ['REJECTED', 'CANCELED'],
                            },
                        },
                        pagination,
                    },
                    onError: graphqlErrorHandler(true),
                }),
            metadata: dataRejected?.orders.metadata,
        });

    return (
        <React.Fragment>
            <TopNav title={'Historia'} offset={60} />
            <div css={sOuter}>
                <div
                    css={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        [s.sm_down]: {
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                        },
                    }}
                >
                    <Headline>Historia</Headline>
                    {/* TODO: EAT-383 */}
                    {/* <Grid
                        container
                        css={[
                            sGrid,
                            {
                                width: '100%',
                                [s.md]: { justifyContent: 'flex-end' },
                            },
                        ]}
                    >
                        <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            css={{
                                // [s.sm_down]: { marginBottom: '2rem' },
                                paddingRight: '0.75rem',
                            }}
                        >
                            <Input
                                darker
                                type="date"
                                label="Od"
                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                onChange={(e) => setStartDate(e.target.value)}
                                value={startDate}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            css={{
                                // [s.sm_down]: { marginBottom: '2rem' },
                                paddingLeft: '0.75rem',
                            }}
                        >
                            <Input
                                darker
                                type="date"
                                label="Do"
                                pattern="\d{1,2}/\d{1,2}/\d{4}"
                                onChange={(e) => setEndDate(e.target.value)}
                                value={endDate}
                            />
                        </Grid>
                    </Grid> */}
                </div>
                <Grid container css={sGrid}>
                    {!dataClosed?.orders.data.length && (
                        <p
                            css={[
                                sDescription,
                                {
                                    animation:
                                        'fadeIn 1s ease-out 1s both alternate',
                                },
                            ]}
                        >
                            Nie odnaleziono zakończonych zamówień
                        </p>
                    )}
                    {/* TODO: EAT-383 */}
                    {/* {ordersF?.length === 0 && (
                        <p css={sDescription}>
                            Nie odnaleziono Zakończonych Zamówień w wybranym
                            przedziale czasowym
                        </p>
                    )} */}
                    {dataClosed?.orders.data.map((order, index) => (
                        <ActiveOrderItem
                            id={index}
                            key={order.id}
                            orderId={order.id}
                            date={order.details.date}
                            consumptionType={order.details.type}
                            restaurantAvatar={
                                order?.owner?.personal.avatarUrl ? (
                                    <img
                                        src={order?.owner?.personal.avatarUrl}
                                        alt={'user avatar'}
                                        loading="lazy"
                                    />
                                ) : (
                                    <PersonIcon />
                                )
                            }
                            restaurantName={`${
                                order?.owner?.personal.name ?? ''
                            } ${order?.owner?.personal.surname ?? ''}`.trim()}
                            shortId={order.shortId}
                            isInvoice={order.hasInvoice}
                            priceSum={order.priceSum}
                            status={order.status}
                            waiter
                            link={`/waiter/order/${order.id}`}
                        />
                    ))}
                    {loadingClosed && (
                        <>
                            <SkeletonOrder />
                            <SkeletonOrder />
                        </>
                    )}
                    {hasMoreClosed && (
                        <Grid item xs={12}>
                            <ButtonBorder
                                action={getMoreClosedData}
                                disable={loadingClosed}
                            >
                                Pokaż więcej
                            </ButtonBorder>
                        </Grid>
                    )}
                </Grid>
                <Grid container css={sGrid}>
                    <div css={{ paddingBottom: '1rem', width: '100%' }}>
                        <SectionHeadline noPaddingLeft>
                            Anulowane i odrzucone
                        </SectionHeadline>
                    </div>
                </Grid>
                <Grid container css={sGrid}>
                    {!dataRejected?.orders.data.length && (
                        <p
                            css={[
                                sDescription,
                                {
                                    animation:
                                        'fadeIn 1s ease-out 1s both alternate',
                                },
                            ]}
                        >
                            Nie odnaleziono anulowanych zamówień
                        </p>
                    )}
                    {/* TODO: EAT-383 */}
                    {/* {ordersD?.length === 0 && (
                        <p css={sDescription}>
                            Nie odnaleziono Anulowanych Zamówień w wybranym
                            przedziale czasowym
                        </p>
                    )} */}
                    {dataRejected?.orders.data.map((order, index) => (
                        <ActiveOrderItem
                            id={index}
                            key={order.id}
                            orderId={order.id}
                            date={order.details.date}
                            consumptionType={order.details.type}
                            restaurantAvatar={
                                order?.owner?.personal.avatarUrl ? (
                                    <img
                                        src={order?.owner?.personal.avatarUrl}
                                        alt={'user avatar'}
                                        loading="lazy"
                                    />
                                ) : (
                                    <PersonIcon />
                                )
                            }
                            restaurantName={`${
                                order?.owner?.personal.name ?? ''
                            } ${order?.owner?.personal.surname ?? ''}`.trim()}
                            shortId={order.shortId}
                            isInvoice={order.hasInvoice}
                            priceSum={order.priceSum}
                            status={order.status}
                            waiter
                            link={`/waiter/order/${order.id}`}
                        />
                    ))}
                    {loadingRejected && (
                        <>
                            <SkeletonOrder />
                            <SkeletonOrder />
                        </>
                    )}
                    {hasMoreRejected && (
                        <Grid item xs={12}>
                            <ButtonBorder
                                action={getMoreRejectedData}
                                disable={loadingRejected}
                            >
                                Pokaż więcej
                            </ButtonBorder>
                        </Grid>
                    )}
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default HistoryPage;
