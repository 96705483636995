import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import NiceModal from '@ebay/nice-modal-react';
import { popup } from '@/AppPopups';
import {
    useCartWaiterQuery,
    useWaiterCartDeleteMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useHistory } from 'react-router-dom';

type CancelOrderPopupProps = {
    onCancel: () => void;
};

export const CancelOrderPopup = NiceModal.create(() => {
    const [cartDelete] = useWaiterCartDeleteMutation();
    const history = useHistory();

    const { data } = useCartWaiterQuery({
        onError: graphqlErrorHandler(true),
        context: { waiter: true },
        fetchPolicy: 'cache-and-network',
    });

    return (
        <CancelOrderPopupInner
            onCancel={() =>
                cartDelete({
                    context: { waiter: true },
                    variables: {
                        input: { cartId: data?.cartList?.data[0]?.id || '' },
                    },
                    onCompleted: () => {
                        history.replace('/waiter');
                        popup.remove('CancelOrderPopup');
                    },
                    onError: graphqlErrorHandler(true),
                })
            }
        />
    );
});

export const CancelOrderPopupInner = ({ onCancel }: CancelOrderPopupProps) => {
    return (
        <BasePopup
            header={
                <PopupHeader>
                    <Title>Anulować zamówienie?</Title>
                </PopupHeader>
            }
            footer={
                <ButtonsWrapper>
                    <Button
                        variant="gray"
                        width={'100%'}
                        onClick={() => popup.remove('CancelOrderPopup')}
                    >
                        WRÓĆ DO ZAMÓWIENIA
                    </Button>
                    <Button variant="black" width={'100%'} onClick={onCancel}>
                        ANULUJ ZAMÓWIENIE
                    </Button>
                </ButtonsWrapper>
            }
            children={
                <Description>
                    Czy na pewno chcesz anulować składanie tego zamówienia?
                </Description>
            }
        ></BasePopup>
    );
};

const PopupHeader = styled.header`
    display: grid;
    grid-gap: 24px;
`;
const Title = styled.h2`
    font-size: 28px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
