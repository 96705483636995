import styled from '@emotion/styled';
import { colors } from '@style/index';
import PersonIcon from '@material-ui/icons/Person';

export interface UserFriendInfoProps {
    avatar?: string | null;
    email: string;
    name: string;
    id: string;
    onDelete?: () => void;
}

export const UserFriendInfo = ({
    name,
    email,
    avatar,
    onDelete,
}: UserFriendInfoProps) => {
    return (
        <UserInfoWrapper>
            <ContentWrapper>
                {avatar ? (
                    <Avatar src={avatar} alt="user avatar" />
                ) : (
                    <IconWrapper>
                        <PersonIcon />
                    </IconWrapper>
                )}
                <div>
                    <UserName>{name}</UserName>
                    <UserEmail>{email}</UserEmail>
                </div>
            </ContentWrapper>
            {!!onDelete && <DeleteButton onClick={onDelete}>Usuń</DeleteButton>}
        </UserInfoWrapper>
    );
};

const Avatar = styled.img`
    border-radius: 50%;
    height: 38px;
    width: 38px;
`;

const UserInfoWrapper = styled.div`
    display: flex;
    gap: 12px;
    justify-content: space-between;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 12px;
`;

const UserName = styled.p`
    color: ${colors.text};
    font-size: 14px;
    font-weight: 700;
`;

const UserEmail = styled.p`
    color: ${colors.grayDark};
    font-size: 12px;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const DeleteButton = styled.button`
    align-items: center;
    background-color: transparent;
    border: none;
    color: ${colors.red};
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: end;
    outline: none;
    text-decoration: underline;
`;
