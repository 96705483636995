import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { LazyQueryExecFunction } from '@apollo/client';
import { CLIENT_ERROR_MESSAGES } from '@constants/errors/clientErrorMessages';
import { ImageFormats } from '@constants/files';
import {
    Exact,
    ImgUploadUrlInput,
    MediaImgUrlsResponse,
} from '@graphql/generated';
import { getFileType } from '@helpers/getFileType';
import { replaceLastSlash } from '@helpers/string.helpers';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

const putFileToS3 = async (awsS3url: string, file: File) =>
    axios.put(awsS3url, file, {
        headers: {
            'Content-Type': file.type,
        },
    });

export const useUploadImage = (
    lazyQuery: LazyQueryExecFunction<
        { image: MediaImgUrlsResponse },
        Exact<{
            input: ImgUploadUrlInput;
        }>
    >,
) => {
    const [loading, setLoading] = useState(false);

    const uploadImage = async (
        file: File,
        format: ImageFormats,
    ): Promise<string | undefined> => {
        if (!file) return;

        const type = getFileType(file);

        try {
            setLoading(true);

            const { data } = await lazyQuery({
                fetchPolicy: 'no-cache',
                variables: { input: { ext: type } },
                onError: graphqlErrorHandler(true),
            });

            if (!data) return;

            await putFileToS3(data.image.urlToUpload, file);

            return replaceLastSlash(
                data.image.urlToDownload ?? '',
                `/${format}/`,
            );
        } catch (error) {
            toast.error(CLIENT_ERROR_MESSAGES.IMAGE_UPLOAD);
        } finally {
            setLoading(false);
        }
    };

    return {
        uploadImage,
        loading,
    };
};
