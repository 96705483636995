import { Button } from '@/EZAdmin/components';
import styled from '@emotion/styled';
import { Modal } from '@material-ui/core';
import { mediaQueries } from '@style/breakpoints';
import { colors } from '@style/index';
import { useState } from 'react';
import { EZAdminIngredientsCreateModal } from '../components/EZAdminIngredientsCreateModal/EZAdminIngredientsCreateModal';
import { EZAdminIngredientsTable } from '../components/EZAdminIngredientsTable/EZAdminIngredientsTable';

export const EZAdminIngredientsPage = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    return (
        <Wrapper>
            <HeaderWrapper>
                <Title>Składniki</Title>
                <Button
                    color="primary"
                    onClick={() => setCreateModalOpen(true)}
                    variant="contained"
                >
                    Dodaj składnik
                </Button>
            </HeaderWrapper>
            <EZAdminIngredientsTable />
            <Modal
                onClose={() => setCreateModalOpen(false)}
                open={createModalOpen}
            >
                <EZAdminIngredientsCreateModal
                    closeModal={() => setCreateModalOpen(false)}
                />
            </Modal>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 820px;
    width: 100%;
`;

const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    width: 100%;

    ${mediaQueries.xs} {
        padding: 32px 24px 0 24px;
    }
`;

const Title = styled.h3`
    color: ${colors.text};
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
`;
