import React from 'react';
import { Bullet } from '@components/Elements';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import { colors } from 'src/style';
import { dateFormat } from '@helpers/dateFormat';
import { TransactionType } from '@graphql/generated';
import styled from '@emotion/styled';

export const TRANSACTION_TYPES: Record<
    TransactionType,
    {
        paymentInfo: ({
            date,
            orderId,
            recipient,
        }: {
            date: Date;
            orderId: string;
            recipient?: { name: string; surname: string };
        }) => JSX.Element;
        paymentBadgeColor: string;
    } | null
> = {
    DEPOSIT: {
        paymentInfo: ({ date, orderId }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <span>Zrealizowana</span>
            </>
        ),
        paymentBadgeColor: colors.green,
    },
    ORDER_PAYMENT: {
        paymentInfo: ({ date, orderId }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <span>Zrealizowana</span>
            </>
        ),
        paymentBadgeColor: colors.red600,
    },
    BLOCKED: {
        paymentInfo: ({ date, orderId }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <span>Nowa</span>
            </>
        ),
        paymentBadgeColor: colors.gray600,
    },
    UN_BLOCKED: {
        paymentInfo: ({ date, orderId }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <span>Oczekująca</span>
            </>
        ),
        paymentBadgeColor: colors.orange,
    },
    UN_BLOCKED_PARTIAL: {
        paymentInfo: ({ date, orderId }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <span>Oczekująca</span>
            </>
        ),
        paymentBadgeColor: colors.orange,
    },
    RESTAURANT_PROFIT: null,
    RESTAURANT_PAYOUT: null,
    PAYOFF_REQUEST_PAID_REQUESTER: {
        paymentInfo: ({ date, orderId }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <span>Rozliczenie</span>
            </>
        ),
        paymentBadgeColor: colors.red900,
    },
    PAYOFF_REQUEST_PAID_RECIPIENT: {
        paymentInfo: ({ date, orderId, recipient }) => (
            <>
                <InfoWrapper>
                    <Icon fontSize="inherit" />
                    {orderId}
                    <Bullet />
                    {dateFormat.standardDate(date)}
                </InfoWrapper>
                <RicipientInfoWrapper>
                    Rozliczenie
                    {recipient?.name && (
                        <>
                            <Bullet />
                            {`${recipient?.name} ${recipient?.surname}`}
                        </>
                    )}
                </RicipientInfoWrapper>
            </>
        ),
        paymentBadgeColor: colors.navy,
    },
};

const InfoWrapper = styled.span`
    display: flex;
    align-items: center;
`;

const RicipientInfoWrapper = styled.span`
    overflow-x: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Icon = styled(LocalDiningIcon)`
    margin-right: 3px;
`;
