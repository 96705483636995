import React from 'react';
import { jsx } from '@emotion/react'; // eslint-disable-line no-unused-vars
import styled from '@emotion/styled';
import MoneyEz from '@helpers/MoneyEz';
import { css } from '@emotion/core';
import { mediaQueriesMaterial } from '@style/breakpoints';

interface RestaurantItemProps {
    avatarUrl?: string;
    name: string;
    address?: string;
    coins: number;
    helperComponent?: React.ReactNode;
}

export const RestaurantItem = ({
    avatarUrl,
    name,
    address,
    coins,
    helperComponent,
}: RestaurantItemProps) => (
    <Wrapper>
        {avatarUrl && <Avatar avatarUrl={avatarUrl} />}
        <Content>
            <h4>{name}</h4>
            {address && <p>{address}</p>}
            <p>Saldo: {MoneyEz(coins).getAmountWithFullPrecisionAndZl()}</p>
        </Content>
        {helperComponent && (
            <HelperComponentWrapper>{helperComponent}</HelperComponentWrapper>
        )}
    </Wrapper>
);

const Wrapper = styled.div`
    align-items: center;
    border-radius: 8px;
    border: 1px solid #777;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    margin-bottom: 16px;
    max-width: 720px;
    padding: 16px 12px;
    width: 100%;

    ${mediaQueriesMaterial.sm} {
        display: grid;
    }
`;

const HelperComponentWrapper = styled.div`
    margin-left: 12px;

    ${mediaQueriesMaterial.sm} {
        margin-left: 0;
    }
`;

const Avatar = styled.div<{ avatarUrl: string }>(
    ({ avatarUrl }) => css`
        background-image: url(${avatarUrl});
        background-position: 50%;
        background-size: cover;
        border-radius: 50%;
        flex: none;
        height: 40px;
        margin-right: 12px;
        width: 40px;
    `,
);

const Content = styled.div`
    flex-grow: 1;
`;
