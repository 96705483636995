import { CLIENT_ERROR_MESSAGES } from '@constants/errors/clientErrorMessages';
import { MAX_IMAGE_SIZE } from '@constants/files';
import { useMediaRestaurantUrlLazyQuery } from '@graphql/generated';
import axios from 'axios';

export const useUploadImg = () => {
    const [mediaImg] = useMediaRestaurantUrlLazyQuery();

    const uploadImgAndGetURL = async (
        imgFile: File,
        type: string,
        format: string,
    ) => {
        if (!imgFile) return null;

        isCorrectImgType(type);
        isCorrectImgSize(imgFile.size);

        try {
            const result = await mediaImg({
                fetchPolicy: 'no-cache',
                variables: { input: { ext: type } },
                onCompleted: (data) => {
                    putImgToS3(data.mediaImg.urlToUpload, imgFile);
                },
                onError: () => {
                    throw new Error(CLIENT_ERROR_MESSAGES.IMAGE_UPLOAD);
                },
            });
            const splitUrl =
                result.data?.mediaImg.urlToDownload.split('restaurants') ?? [];
            return `${splitUrl[0]}restaurants${format}${splitUrl[1]}`;
        } catch (error) {
            console.error(error);
        }
    };

    return {
        uploadImgAndGetURL,
    };
};

function isCorrectImgType(type: string) {
    const acceptedImgTypes = ['png', 'jpeg'];
    const isInvalidType = !acceptedImgTypes.includes(type);

    if (isInvalidType)
        throw new Error(CLIENT_ERROR_MESSAGES.INCORRECT_IMAGE_TYPE);
}

function isCorrectImgSize(size: number) {
    const isInvalidType = size > MAX_IMAGE_SIZE;

    if (isInvalidType) throw new Error(CLIENT_ERROR_MESSAGES.MAX_IMAGE_SIZE);
}

async function putImgToS3(awsS3url: string, imgFile: File) {
    await axios.put(awsS3url, imgFile, {
        headers: {
            'Content-Type': imgFile.type,
        },
    });
}
