import styled from '@emotion/styled';
import { colors } from '@/style';
import {
    ButtonIcon,
    ButtonIconProps,
} from '@/componentsV2/atoms/ButtonIcon/ButtonIcon';
import {
    ButtonText,
    ButtonTextProps,
} from '@/componentsV2/atoms/ButtonText/ButtonText';

export interface PopupHeaderSecondaryProps {
    iconButton?: ButtonIconProps;
    text: string;
    textButton?: ButtonTextProps;
}

export const PopupHeaderSecondary = ({
    iconButton,
    text,
    textButton,
}: PopupHeaderSecondaryProps) => {
    return (
        <Header>
            <div>{iconButton && <ButtonIcon {...iconButton} />}</div>
            <Title>{text}</Title>
            <ButtonWrapper>
                {textButton && <ButtonText {...textButton}>Wyczyść</ButtonText>}
            </ButtonWrapper>
        </Header>
    );
};

const Header = styled.div`
    align-items: center;
    background: ${colors.white};
    border-bottom: 1px solid ${colors.porcelainGray};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 20px 24px;
    width: 100%;
`;

const Title = styled.h1`
    color: ${colors.text};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
`;

const ButtonWrapper = styled.div`
    justify-self: flex-end;
    text-align: right;
`;
