import React, { useEffect } from 'react';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import { Controller, useForm } from 'react-hook-form';
import { DELIVERY_DURATION } from '@constants/deliveryDuration.constans';
import { CircularProgress, InputLabel, MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from '@emotion/styled';
import NiceModal from '@ebay/nice-modal-react';
import { usePopup } from '@/AppPopups';
import { dateFormat } from '@helpers/dateFormat';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    OPERATIONS,
    Order,
    useOrderChangeStatusMutation,
    useOrderEtaChangeMutation,
} from '@graphql/generated';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';

interface SetDeliveryTimePopupProps {
    order: Pick<Order, 'id' | 'delivery' | 'statusLog'>;
    loading: boolean;
    isTakeaway?: boolean;
    update: boolean;
}

export const SetDeliveryTimePopup = NiceModal.create(
    ({ order, loading, isTakeaway, update }: SetDeliveryTimePopupProps) => {
        const popup = usePopup();
        const [changeStatus] = useOrderChangeStatusMutation();

        const methods = useForm({
            resolver: yupResolver(DeliveryDurationSchema),
        });

        useEffect(() => {
            if (order?.delivery?.eta) {
                const minutes = dateFormat.calculateHowManyMinutes(
                    order?.delivery?.eta,
                    order?.statusLog?.find(
                        (item) => item?.status === 'ACCEPTED',
                    )?.date,
                );

                if (minutes) {
                    methods.setValue('deliveryDuration', minutes);
                }
            }
        }, [order]);

        const [orderEtaChange] = useOrderEtaChangeMutation({
            onError: graphqlErrorHandler(true),
            context: { waiter: true },
        });

        const handleFormSubmit = methods.handleSubmit(
            async (values) => {
                try {
                    const futureDate = dateFormat.addMinutesToDate(
                        values?.deliveryDuration,
                        new Date(),
                    );

                    if (!update) {
                        await changeStatus({
                            context: {
                                waiter: true,
                            },
                            variables: {
                                input: {
                                    orderId: order?.id,
                                    status: 'ACCEPTED',
                                    payload: {},
                                },
                            },
                            onCompleted: () => {
                                toast.info(
                                    `Zamówienie zostało zaakceptowane, czas ${
                                        isTakeaway ? 'odbioru' : 'dostawy'
                                    } został ustawiony`,
                                );
                            },
                            onError: graphqlErrorHandler(true),
                            refetchQueries: [OPERATIONS.Query.ActivePage],
                        });

                        await orderEtaChange({
                            variables: {
                                input: { orderId: order?.id, eta: futureDate },
                            },
                            refetchQueries: [OPERATIONS.Query.OrderPage],
                        });
                    } else {
                        const acceptedDate = dateFormat.calculateHowManyMinutes(
                            order?.delivery?.eta ?? futureDate,
                            order?.statusLog?.find(
                                (item) => item?.status === 'ACCEPTED',
                            )?.date,
                        );

                        const newValue =
                            values?.deliveryDuration - acceptedDate;

                        const newEta = dateFormat.addMinutesToDate(
                            newValue,
                            order?.delivery?.eta ?? futureDate,
                        );
                        await orderEtaChange({
                            variables: {
                                input: { orderId: order?.id, eta: newEta },
                            },
                            onCompleted: () =>
                                toast.info(
                                    `Czas ${
                                        isTakeaway ? 'odbioru' : 'dostawy'
                                    } został zaktualizowany`,
                                ),
                            refetchQueries: [
                                OPERATIONS.Query.OrderPage,
                                OPERATIONS.Query.OrdersPage,
                            ],
                        });
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    popup.remove();
                }
            },
            (errors) => {
                Object.entries(errors).map(([, error]) => {
                    toast.error(error.message);
                });
            },
        );

        return (
            <BasePopup
                header={
                    <PopupHeader>
                        <HeaderWrapper>
                            <Title>
                                {order?.delivery?.eta
                                    ? `Aktualizacja czasu ${
                                          isTakeaway ? 'odbioru' : 'dostawy'
                                      }`
                                    : `Czas ${isTakeaway ? 'odbioru' : 'dostawy'}`}
                            </Title>

                            <ButtonBorder onClick={() => popup.remove()}>
                                Pomiń
                            </ButtonBorder>
                        </HeaderWrapper>
                        <Description>
                            {order?.delivery?.eta
                                ? 'Proszę wprowadzić łączny zaktualizowany czas realizacji zamówienia.'
                                : `Proszę wprowadzić czas przygotowania i ${
                                      isTakeaway ? 'odbioru' : 'dostawy'
                                  } dla danego zamówienia.`}
                        </Description>
                    </PopupHeader>
                }
                footer={
                    <DeliveryTimeButton
                        variant="black"
                        width={'100%'}
                        onClick={handleFormSubmit}
                        loading={loading}
                    >
                        {order?.delivery?.eta
                            ? isTakeaway
                                ? 'ZAKTUALIZUJ CZAS ODBIORU'
                                : 'ZAKTUALIZUJ CZAS DOSTAWY'
                            : isTakeaway
                              ? 'USTAW CZAS ODBIORU'
                              : 'USTAW CZAS DOSTAWY'}
                    </DeliveryTimeButton>
                }
            >
                {loading ? (
                    <SpinnerWrapper>
                        <CircularProgress color="inherit" thickness={5} />
                    </SpinnerWrapper>
                ) : (
                    <SelectWrapper>
                        <Label id="select-label">
                            {isTakeaway ? 'Czas odbioru' : 'Czas dostawy'}
                        </Label>
                        <Controller
                            name="deliveryDuration"
                            control={methods.control}
                            defaultValue={
                                order?.delivery?.eta
                                    ? dateFormat.calculateHowManyMinutes(
                                          order?.delivery?.eta,
                                          order?.statusLog?.find(
                                              (item) =>
                                                  item?.status === 'ACCEPTED',
                                          )?.date,
                                      )
                                    : undefined
                            }
                            render={({ field }) => {
                                const fieldValue = field.value;

                                const updatedDeliveryDuration = [
                                    ...DELIVERY_DURATION,
                                ];

                                if (
                                    fieldValue &&
                                    !updatedDeliveryDuration.includes(
                                        fieldValue,
                                    )
                                ) {
                                    updatedDeliveryDuration.push(fieldValue);
                                }

                                updatedDeliveryDuration.sort((a, b) => a - b);

                                return (
                                    <TimeSelect
                                        SelectDisplayProps={{
                                            style: {
                                                padding: '34px 24px 12px',
                                            },
                                        }}
                                        {...field}
                                        MenuProps={{
                                            style: { zIndex: 9999 },
                                        }}
                                    >
                                        {updatedDeliveryDuration.map(
                                            (value) => (
                                                <MenuItem
                                                    key={value}
                                                    value={value}
                                                >
                                                    <p>{value} minut</p>
                                                </MenuItem>
                                            ),
                                        )}
                                    </TimeSelect>
                                );
                            }}
                        />
                    </SelectWrapper>
                )}
            </BasePopup>
        );
    },
);

export const PopupHeader = styled.header`
    display: grid;
    grid-gap: 24px;
`;
export const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

export const Title = styled.h2`
    font-size: 28px;
`;

const DeliveryTimeButton = styled(Button)`
    padding: 0 8px;
`;

export const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const SelectWrapper = styled(FormControl)`
    background-color: ${colors.gray200};
    border-radius: 16px;
    width: 100%;

    &:hover {
        background-color: ${colors.gray300};
    }

    &:focus {
        background-color: ${colors.gray400};
    }

    & label + .MuiInput-formControl {
        margin: 0;
    }
`;

const Label = styled(InputLabel)`
    color: ${colors.gray};
    font-size: 14px;
    font-weight: 700;
    padding: 0 0 0 24px;
    text-transform: uppercase;
    z-index: 9;

    &.MuiFormLabel-root.Mui-focused {
        color: ${colors.gray};
        padding: 14px 0 0 30px;
    }

    &.MuiInputLabel-shrink {
        padding: 14px 0 0 30px;
    }
`;

const TimeSelect = styled(Select)`
    background-color: transparent;
    border-radius: 40px;
    font-size: 13px;
    font-weight: 700;

    &:focus {
        &.MuiInput-underline:before,
        &.MuiInput-underline:after {
            border: none;
        }
    }

    &:hover {
        &.MuiInput-underline:before,
        &.MuiInput-underline:after {
            border: none;
        }
    }

    &.MuiInput-underline:before,
    &.MuiInput-underline:after {
        border: none;
    }

    &.MuiInputBase-root .MuiSelect-icon {
        right: 24px;
    }

    &.MuiInputBase-root .MuiSelect-select:focus {
        background-color: unset;
    }
`;

export const DeliveryDurationSchema = yup.object({
    deliveryDuration: yup.number().nullable().required('Ustaw czas dostawy'),
});
