type UnknownObject = Record<string, unknown>;
type UnknownArrayOrObject = unknown[] | UnknownObject;

export const getDirtyValues = <
    D = UnknownArrayOrObject,
    V = UnknownArrayOrObject,
>(
    dirtyFields: D | boolean | unknown,
    allValues: V | unknown,
): Partial<V> => {
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        // @ts-expect-error fix it
        return allValues;
    }

    const dirtyFieldsObject = dirtyFields as UnknownObject;
    const allValuesObject = allValues as UnknownObject;
    // @ts-expect-error fix it
    return Object.fromEntries(
        Object.keys(dirtyFieldsObject).map((key) => [
            key,
            getDirtyValues(dirtyFieldsObject[key], allValuesObject[key]),
        ]),
    );
};
