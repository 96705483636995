import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { colors } from '@style/index';
import { css } from '@emotion/core';

export interface OrderCounterProps {
    className?: string;
    disabled?: boolean;
    max: number;
    onChange: (value: number, event: 'minus' | 'plus') => void;
    onDisabledClick?: () => void;
    value: number;
}

export const OrderCounter = forwardRef<HTMLDivElement, OrderCounterProps>(
    ({ className, disabled, max, onChange, onDisabledClick, value }, ref) => {
        if (!value)
            return (
                <InitialButton
                    aria-label="plus"
                    disabled={!!disabled}
                    onClick={() => onChange(value + 1, 'plus')}
                >
                    <InitialAddIcon />
                </InitialButton>
            );

        return (
            <OrderCounterWrapper className={className} ref={ref}>
                <ActionButton
                    aria-label="minus"
                    onClick={() => onChange(value - 1, 'minus')}
                >
                    <RemoveIcon />
                </ActionButton>
                <OrderCounterContent>
                    <CounterNumber>{value}</CounterNumber>
                </OrderCounterContent>
                <ActionButton
                    aria-label="plus"
                    disabledStyles={!!disabled || value >= max}
                    disabled={!onDisabledClick && (!!disabled || value >= max)}
                    onClick={() =>
                        disabled
                            ? onDisabledClick?.()
                            : onChange(value + 1, 'plus')
                    }
                >
                    <AddIcon />
                </ActionButton>
            </OrderCounterWrapper>
        );
    },
);

export const OrderCounterWrapper = styled.div`
    align-items: center;
    background: ${colors.littleDipper};
    border-radius: 16px;
    display: flex;
    gap: 4px;
    height: 32px;
    justify-content: space-between;
    padding: 0 4px;
    width: 90px;
`;

const ActionButton = styled(IconButton)<{
    disabledStyles?: boolean;
}>(
    ({ disabledStyles }) => css`
        color: ${colors.text};
        height: 24px;
        padding: 0;
        width: 24px;

        ${disabledStyles &&
        css`
            background: ${colors.littleDipper};
            color: ${colors.gray400};
        `}
    `,
);

export const OrderCounterContent = styled.div`
    text-align: center;
    width: 34px;
`;

export const CounterNumber = styled.span`
    color: ${colors.text};
    font-size: 15px;
    font-weight: 700;
`;

// INITIAL STATE
const InitialButton = styled(ActionButton)`
    background: ${colors.littleDipper};
    font-size: 2px;
    height: 32px;
    width: 32px;
`;

const InitialAddIcon = styled(AddIcon)`
    height: 16px;
    width: 16px;
`;
