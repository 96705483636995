import React from 'react';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalMallIcon from '@material-ui/icons/LocalMall';

const ChairIcon = () => (
    <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="chair"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
    >
        <path
            fill="currentColor"
            d="M112 128c0-29.5 16.2-55 40-68.9V256h48V48h48v208h48V59.1c23.8 13.9 40 39.4 40 68.9v128h48V128C384 57.3 326.7 0 256 0h-64C121.3 0 64 57.3 64 128v128h48zm334.3 213.9l-10.7-32c-4.4-13.1-16.6-21.9-30.4-21.9H42.7c-13.8 0-26 8.8-30.4 21.9l-10.7 32C-5.2 362.6 10.2 384 32 384v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V384h256v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V384c21.8 0 37.2-21.4 30.3-42.1z"
        ></path>
    </svg>
);

/**
 * @deprecated use src/constants
 */
export const flavors = {
    SWEET: { pl: 'słodki', en: 'sweet' },
    SOUR: { pl: 'kwaśny', en: 'sour' },
    SALTY: { pl: 'słony', en: 'salty' },
    BITTER: { pl: 'gorzki', en: 'bitter' },
    UMAMI: { pl: 'mięsny', en: 'umami' },
    PUNGENT: { pl: 'pikantny', en: 'pungent' },
    FATTY: { pl: 'oleisty', en: 'fatty' },
};

/**
 * @deprecated use src/constants
 */
export const cookMethods = {
    ROAST: { pl: 'pieczenie (mięso)', en: 'roast' },
    BAKE: { pl: 'pieczenie (ciasto)', en: 'bake' },
    BOIL: { pl: 'gotowanie', en: 'boil' },
    STEAM: { pl: 'gotowanie na parze', en: 'steam' },
    FRY: { pl: 'smażenie', en: 'fry' },
    DEEPFRY: { pl: 'smażenie na głębokim oleju', en: 'deep fry' },
    GRILL: { pl: 'grillowannie', en: 'grill' },
    STEW: { pl: 'duszenie', en: 'stew' },
};

/**
 * @deprecated use src/constants
 */
export const consumptionTypeTypes = {
    IN: 'IN',
    TAKEAWAY: 'TAKEAWAY',
    DELIVERY: 'DELIVERY',
};

/**
 * @deprecated use src/constants
 */
export const consumptionTypeNames = {
    [consumptionTypeTypes.IN]: 'Na miejscu',
    [consumptionTypeTypes.TAKEAWAY]: 'Odbiór',
    [consumptionTypeTypes.DELIVERY]: 'Dostawa',
};

/**
 * @deprecated use src/constants
 */
export const consumptionTypeIcons = {
    [consumptionTypeTypes.IN]: <ChairIcon fontSize="inherit" />,
    [consumptionTypeTypes.TAKEAWAY]: <LocalMallIcon fontSize="inherit" />,
    [consumptionTypeTypes.DELIVERY]: <DriveEtaIcon fontSize="inherit" />,
};
