import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import { colors } from '@style/index';
import { useController, useForm } from 'react-hook-form';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTableChangeMutation } from '@graphql/generated';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export const TableNumberSchema = yup.object({
    tableNumber: yup.string().required('Podaj numer stolika'),
});

interface TableChangeProps {
    setExpanded: (value: boolean) => void;
}
export const TableChange = ({ setExpanded }: TableChangeProps) => {
    const methods = useForm({ resolver: yupResolver(TableNumberSchema) });
    const orderId = useParams<{ id: string }>().id;

    const [orderTableChange] = useTableChangeMutation({
        context: { waiter: true },
        onError: graphqlErrorHandler(true),
    });

    const {
        field: { onChange, value },
    } = useController({
        control: methods.control,
        name: 'tableNumber',
    });

    const handleFormSubmit = methods.handleSubmit(async (values) => {
        orderTableChange({
            variables: {
                input: { orderId, table: values.tableNumber },
            },
            update: (cache) => {
                const orderCacheId = cache.identify({
                    __typename: 'Order',
                    id: orderId,
                });
                const order = cache.readFragment({
                    id: orderCacheId,
                    fragment: gql`
                        fragment OrderTable on Order {
                            details {
                                table
                            }
                        }
                    `,
                });

                if (order) {
                    const updatedOrder = {
                        ...order,
                        table: values.tableNumber,
                    };
                    cache.writeFragment({
                        id: orderCacheId,
                        fragment: gql`
                            fragment UpdatedOrderTable on Order {
                                details {
                                    table
                                }
                            }
                        `,
                        data: updatedOrder,
                    });
                }
            },
        });
        setExpanded(false);
    });

    return (
        <Wrapper autoComplete="off">
            <Title>Zmień stolik</Title>
            <Description>Wpisz numer stolika</Description>
            <TextFieldElement
                id="input-label"
                label="Numer stołu"
                onChange={onChange}
                value={value}
            />
            <Button
                variant="gray"
                width={'100%'}
                onClick={() => setExpanded(false)}
            >
                ODRZUĆ
            </Button>
            <Button
                variant="black"
                width={'100%'}
                onClick={handleFormSubmit}
                disabled={!value}
            >
                Zapisz
            </Button>
        </Wrapper>
    );
};

const Wrapper = styled.form`
    display: grid;
    grid-gap: 24px;
`;

const Title = styled.h3`
    color: ${colors.text};
    font-size: 18px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const TextFieldElement = styled(TextField)`
    background-color: ${colors.littleDipper};
    border-bottom: none;
    border-radius: 16px;
    padding: 12px 24px;

    & label {
        color: ${colors.gray};
        font-weight: 700;
        padding-left: 24px;
        text-transform: uppercase;
    }

    & label + .MuiInput-formControl {
        margin-top: 14px;
    }

    &:hover {
        background-color: ${colors.gray200};
    }

    &.MuiFormControl-root .MuiInput-underline:before,
    &.MuiFormControl-root .MuiInput-underline:after {
        border-bottom: none;
    }

    &.MuiFormControl-root .MuiInputLabel-shrink {
        padding: 14px 0 0 30px;
        color: ${colors.gray};
    }

    &.MuiFormControl-root .MuiInputBase-input {
        font-weight: 700;
        padding: 6px 0 0;
    }
`;
