import { useOrderChatCreateMutation } from '@graphql/generated';

import { useEffect } from 'react';
import { graphqlErrorHandler } from '../../GraphqlErrorService';

import { useChatMessages } from './common';
import { FIREBASE_ENV } from '../FirebaseChatService.config';
import { FirebaseEnv } from '../FirebaseChatService.types';

const useChatOrderCreate = (orderId: string, waiter: boolean) => {
    const [chatCreate, result] = useOrderChatCreateMutation();

    useEffect(() => {
        chatCreate({
            context: { waiter },
            onError: graphqlErrorHandler(false),
            variables: { input: { orderId } },
        });
    }, []);

    return { ...result };
};

export const useOrderChat = (orderId: string, waiter: boolean) => {
    const env: FirebaseEnv = `env/${FIREBASE_ENV}/chats/${orderId}`;

    const { error, data } = useChatOrderCreate(orderId, waiter);
    const { loading, messages } = useChatMessages(env, !!(data || error));

    return { data, error, loading, messages };
};
