import { useEffect, useState } from 'react';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { Dialog } from '@capacitor/dialog';
import { useHandleBackFromBackground } from '@hooks/useHandleBackFromBackground';
import { ForceUpdateHelpers } from './useForceUpdate.helpers';
import { MOBILE, __DEV__ } from '@constants/common.constants';

export const useForceUpdate = () => {
    const [shouldCheckNewUpdate, setShouldCheckNewUpdate] = useState(true);

    const handleForceUpdate = async () => {
        if (!MOBILE || __DEV__) return;

        const result = await AppUpdate.getAppUpdateInfo({ country: 'pl' });

        if (
            ForceUpdateHelpers.isCurrentVersionLoverThanAvailable(
                result.currentVersionName,
                result.availableVersionName ?? '',
            )
        ) {
            try {
                await Dialog.alert({
                    message: `Dostępna jest nowa wersja aplikacji. Kliknij i zaktualizuj w celu poprawnego działania platformy.`,
                    buttonTitle: 'Zaktualizuj',
                });
                await AppUpdate.openAppStore({ country: 'pl' });
            } catch (error) {
                console.error(error);
            } finally {
                setShouldCheckNewUpdate(false);
            }
        }
    };

    useHandleBackFromBackground(() => setShouldCheckNewUpdate(true));

    useEffect(() => {
        if (shouldCheckNewUpdate) {
            handleForceUpdate();
        }
    }, [shouldCheckNewUpdate]);
};
