/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { default as LoginView } from '../../views/Auth/Login.view';
// import Layout from '../../components/Layout'

const Login = () => {
    return (
        // <Layout>
        <LoginView />
        // </Layout>
    );
};

export default Login;
