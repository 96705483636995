/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { MenuItem, Select, Grid } from '@material-ui/core';
import { daysOfWeek } from '../../constants/daysOfWeek';
import {
    sInput,
    sInputDarker,
} from '../../EZC/views/Auth/components/Input.component';
import { colors, alpha, s } from '../../style';

const openningHoursValues = {
    0: '0:00',
    // ... openningHoursValuesFc()
    1440: '24:00',
};
const openningHoursValuesFc = () => {
    let i = '0:00';
    for (let index = 0; index < 1441; index += 30) {
        openningHoursValues[index] = i;
        let b = i.split(':');
        if (parseInt(b[1], 10) === 30) {
            b[0]++;
            b[1] = '00';
        } else {
            b[1] = '30';
        }
        i = b.join(':');
    }
};

openningHoursValuesFc();
const daysOpenInit = {};
Object.keys(daysOfWeek).forEach(
    (day) => (daysOpenInit[day] = { open: false, close: false }),
);

const OpenHoursTable = ({ getter, setter }) => {
    const [daysOpen, setDaysOpen] = useState(daysOpenInit);

    const handleDayOpen = (day, type) => {
        setDaysOpen({ ...daysOpen, [day]: { ...daysOpen[day], [type]: true } });
    };

    const handleDayClose = (day, type) => {
        setDaysOpen({
            ...daysOpen,
            [day]: { ...daysOpen[day], [type]: false },
        });
    };

    return (
        <Grid item xs={12} md={6} css={sOuter}>
            {Object.keys(daysOfWeek).map((day) => {
                const isClosed = !getter[day]?.open && !getter[day].close;
                return (
                    <div
                        key={day}
                        css={[
                            sContainer,
                            isClosed && {
                                [s.sm]: {
                                    '&:nth-of-type(1n), &:nth-of-type(2n)': {
                                        backgroundColor: colors.red.concat(
                                            alpha[16],
                                        ),
                                    },
                                },
                            },
                        ]}
                    >
                        <p css={isClosed && { color: colors.red }}>
                            {daysOfWeek[day]}
                        </p>
                        <div css={{ display: 'flex' }}>
                            <Select
                                labelId={`open ${day}`}
                                open={daysOpen[day].open}
                                onClose={() => handleDayClose(day, 'open')}
                                onOpen={() => handleDayOpen(day, 'open')}
                                value={getter[day].open}
                                onChange={(e) => setter(e, 'open')}
                                name={day}
                                css={[
                                    sInput,
                                    sInputDarker,
                                    {
                                        marginRight: '1rem',
                                        width: 90,
                                        padding: 0,
                                        minHeight: '50px',
                                        [s.md]: {
                                            fontSize: '1rem',
                                            padding: '0',
                                        },
                                        '.MuiSelect-root': {
                                            padding: '1rem 1.5rem',
                                        },
                                        '.MuiSelect-select:focus': {
                                            borderRadius: 24,
                                            backgroundColor: 'transparent',
                                        },
                                        '::before, ::after': {
                                            display: 'none !important',
                                        },
                                    },
                                    isClosed && {
                                        color: colors.red,
                                        backgroundColor: colors.red.concat(
                                            alpha[16],
                                        ),
                                        [s.touch]: {
                                            color: colors.red,
                                            backgroundColor: colors.red.concat(
                                                alpha[16],
                                            ),
                                        },
                                    },
                                ]}
                            >
                                {Object.keys(openningHoursValues).map(
                                    (value) => (
                                        <MenuItem key={value} value={value}>
                                            {openningHoursValues[value]}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                            <Select
                                labelId={`close ${day}`}
                                open={daysOpen[day].close}
                                onClose={() => handleDayClose(day, 'close')}
                                onOpen={() => handleDayOpen(day, 'close')}
                                value={getter[day].close}
                                onChange={(e) => setter(e, 'close')}
                                name={day}
                                css={[
                                    sInput,
                                    sInputDarker,
                                    {
                                        width: 90,
                                        padding: 0,
                                        minHeight: '50px',
                                        [s.md]: {
                                            fontSize: '1rem',
                                            padding: '0',
                                        },
                                        '.MuiSelect-root': {
                                            padding: '1rem 1.5rem',
                                        },
                                        '.MuiSelect-select:focus': {
                                            borderRadius: 24,
                                            backgroundColor: 'transparent',
                                        },
                                        '::before, ::after': {
                                            display: 'none !important',
                                        },
                                    },
                                    isClosed && {
                                        color: colors.red,
                                        backgroundColor: colors.red.concat(
                                            alpha[16],
                                        ),
                                        [s.touch]: {
                                            color: colors.red,
                                            backgroundColor: colors.red.concat(
                                                alpha[16],
                                            ),
                                        },
                                    },
                                ]}
                            >
                                {Object.keys(openningHoursValues).map(
                                    (value) => (
                                        <MenuItem key={value} value={value}>
                                            {openningHoursValues[value]}
                                        </MenuItem>
                                    ),
                                )}
                            </Select>
                        </div>
                    </div>
                );
            })}
        </Grid>
    );
};

const sOuter = {
    borderRadius: '1.5rem',
    overflow: 'hidden',
    // [s.md]: {
    //   backgroundColor: colors.gray100,
    // },
};

const sContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 0',
    [s.sm]: {
        padding: '1rem 2rem',
        '&:nth-of-type(1n)': {
            backgroundColor: colors.gray100,
        },
        '&:nth-of-type(2n)': {
            backgroundColor: colors.gray200,
        },
    },
    p: {
        fontSize: '13px',
        fontWeight: 700,
    },
};

export default OpenHoursTable;
