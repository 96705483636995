import {
    Chip as MaterialChip,
    ChipProps as MaterialChipProps,
} from '@material-ui/core';
import { css } from '@emotion/core';
import AddIcon from '@material-ui/icons/Add';
import styled from '@emotion/styled';
import RemoveIcon from '@material-ui/icons/Remove';

export type ChipVariant =
    | 'add'
    | 'addDark'
    | 'default'
    | 'defaultLight'
    | 'remove'
    | 'transparentAdd'
    | 'transparentAddDark'
    | 'transparentRemove';

export interface ChipProps {
    clickable?: MaterialChipProps['clickable'];
    icon?: MaterialChipProps['icon'] | boolean;
    id: string;
    label: MaterialChipProps['label'];
    onClick?: (id: string, nextVariant: ChipProps['variant']) => void;
    variant: ChipVariant;
    steps?: ChipProps['variant'][];
}

export const Chip = ({
    clickable,
    icon,
    id,
    label,
    onClick,
    variant,
    steps = ['default', 'add', 'remove'],
}: ChipProps) => {
    const { background, color, defaultIcon } = variants[variant];

    const chipColors = { background, color };

    const nextVariant = (steps: ChipProps['variant'][]) => {
        const currentIndex = steps.findIndex((step) => step === variant);
        const nextStep = (currentIndex + 1) % steps.length;
        return steps[nextStep];
    };

    return (
        <ChipElement
            $background={chipColors.background}
            $color={chipColors.color}
            clickable={clickable}
            icon={typeof icon === 'boolean' ? defaultIcon : icon}
            label={label}
            onClick={() => onClick?.(id, nextVariant(steps))}
        />
    );
};

const variants: Record<
    ChipVariant,
    {
        background: string;
        color: string;
        defaultIcon?: MaterialChipProps['icon'];
    }
> = {
    add: {
        // TODO: add colors from theme
        background: '#D5FED9',
        color: '#00B30F',
        defaultIcon: <AddIcon style={{ color: '#00B30F', fontSize: 16 }} />,
    },
    addDark: {
        background: '#343340',
        color: '#FFFFFF',
        defaultIcon: <div style={{ width: 16, height: 16 }} />,
    },
    default: {
        background: '#E4E6EA',
        color: '#343340',
        defaultIcon: <div style={{ width: 16, height: 16 }} />,
    },
    defaultLight: {
        background: '#F0F1F5',
        color: '#A2A4AF',
        defaultIcon: <div style={{ width: 16, height: 16 }} />,
    },
    remove: {
        background: '#FFD5D5',
        color: '#FF0000',
        defaultIcon: <RemoveIcon style={{ color: '#FF0000', fontSize: 16 }} />,
    },
    transparentAdd: {
        background: '#00b30f29',
        color: '#00B30F',
        defaultIcon: <AddIcon style={{ color: '#00B30F', fontSize: 16 }} />,
    },
    transparentAddDark: {
        background: '#A3A5B059',
        color: '#FFFFFF',
        defaultIcon: <div style={{ width: 16, height: 16 }} />,
    },
    transparentRemove: {
        background: '#ff000029',
        color: '#FF0000',
        defaultIcon: <RemoveIcon style={{ color: '#FF0000', fontSize: 16 }} />,
    },
};

const ChipElement = styled(MaterialChip)<{
    $background: string;
    $color: string;
}>(
    ({ $background, $color }) => css`
        background-color: ${$background};
        color: ${$color};
        font-size: 11px;
        font-weight: 600;
        max-width: 100%;
        padding: 0 10px;

        &:hover,
        &:focus {
            background-color: ${$background};
            color: ${$color};
        }

        &:active {
            box-shadow: none;
        }

        .MuiChip-label {
            padding: 0;
        }

        .MuiChip-icon {
            margin: 0 2px 0 0;
        }
    `,
);
