import React, { createContext, useContext } from 'react';
import useNoAuthOrders from './useNoAuthOrders';

function noop() {}
export const NoAuthOrdersContext = createContext({
    ordersNoAuth: [],
    onCreateOrderAddProdNoAuth: noop,
    onUpdateOrderNoAuth: noop,
    onAddProdNoAuth: noop,
    onDeleteProdNoAuth: noop,
    onDeleteOrderNoAuth: noop,

    onSendOrdersNoAuthSignup: noop,
    onSendOrdersNoAuthLogin: noop,
});

export const useNoAuthOrdersContext = () => useContext(NoAuthOrdersContext);

export default ({ children }) => (
    <NoAuthOrdersContext.Provider value={{ ...useNoAuthOrders() }}>
        {children}
    </NoAuthOrdersContext.Provider>
);
