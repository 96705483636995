import React, { SyntheticEvent, forwardRef } from 'react'; // eslint-disable-line no-unused-vars
import { Avatar, Grid } from '@material-ui/core';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import styled from '@emotion/styled';

export interface UploadFigureImageProps {
    image?: string;
    loading: boolean;
    onChange: (event: SyntheticEvent) => void;
}

export const UploadFigureImage = forwardRef<
    HTMLInputElement,
    UploadFigureImageProps
>(({ image, loading, onChange, ...props }, ref) => {
    return (
        <Grid item xs={12}>
            <Description>Dodaj zdjęcie swojej sylwetki</Description>
            <Wrapper>
                <Avatar
                    src={image}
                    alt="figure image"
                    css={{ width: 48, height: 48 }}
                />
                <Button width="100%" variant="gray" loading={loading}>
                    <ImageInput
                        {...props}
                        ref={ref}
                        accept="image/*"
                        type="file"
                        placeholder="figure image"
                        onChange={onChange}
                    />
                    Wybierz plik
                </Button>
            </Wrapper>
        </Grid>
    );
});

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0 24px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
`;

const ImageInput = styled.input`
    appearance: none;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;
