import React from 'react'; // eslint-disable-line no-unused-vars
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sDescription, sGrid, sOuter } from '../../../style/style';
import Headline from '../../../components/Headline/Headline';
import { ButtonBorder } from '../../../components/Elements';
import { Grid } from '@material-ui/core';
import { s } from '../../../style';
import ActiveOrderItem from '../../../EZC/pages/orders/components/ActiveOrderItem';
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder';
import { useOrdersPageLazyQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import PersonIcon from '@material-ui/icons/Person';

const OrdersPage = () => {
    const { restaurantId = '' } = useWaiterService();

    const [getOrders, { data: { orders } = {}, loading }] =
        useOrdersPageLazyQuery();
    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getOrders({
                context: { waiter: true },
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        restaurantId,
                        filters: {
                            statuses: [
                                'ACCEPTED',
                                'COMPLETED',
                                'DELIVERY',
                                'PARTIAL_ACCEPTANCE',
                                'PENDING',
                                'PREPARED',
                                'PREPARING',
                                'SENT_TO_RESTAURANT',
                                'VERIFICATION',
                            ],
                        },
                    },
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: orders?.metadata,
    });

    return (
        <React.Fragment>
            <TopNav title={'Zamówienia'} offset={60} />
            <div css={[sOuter, { paddingBottom: 20 }]}>
                <Headline
                    itemChildren={
                        <ButtonBorder link="/waiter/notifications">
                            Nowe
                        </ButtonBorder>
                    }
                >
                    Zamówienia
                </Headline>
                <Grid container css={sGrid}>
                    {!orders?.data.length && (
                        <>
                            <SkeletonOrder />
                            <SkeletonOrder />
                            <p
                                css={[
                                    sDescription,
                                    {
                                        animation:
                                            'fadeIn 1s ease-out 1s both alternate',
                                    },
                                ]}
                            >
                                Szukamy dla Ciebie klientów...
                            </p>
                        </>
                    )}
                    {orders?.data.map((order, index) => (
                        <ActiveOrderItem
                            id={index}
                            key={order.id}
                            orderId={order.id}
                            date={order.details.date}
                            delivery={order?.delivery}
                            consumptionType={order.details.type}
                            restaurantAvatar={
                                order.owner?.personal.avatarUrl ? (
                                    <img
                                        src={order.owner?.personal.avatarUrl}
                                        alt={'user avatar'}
                                        loading="lazy"
                                    />
                                ) : (
                                    <PersonIcon />
                                )
                            }
                            restaurantName={
                                !!order.owner
                                    ? `${order.owner?.personal.name ?? ''} ${
                                          order.owner?.personal.surname ?? ''
                                      }`.trim()
                                    : order.waiterNote
                            }
                            shortId={order.shortId}
                            isInvoice={order.hasInvoice}
                            priceSum={order.priceSum}
                            status={order.status}
                            statusLog={order.statusLog}
                            waiter
                            // TODO: add chat new messages
                            needAttention={order.status === 'PENDING'}
                        />
                    ))}
                    {hasMore && (
                        <Grid item xs={12}>
                            <ButtonBorder
                                action={getMoreData}
                                disable={loading}
                            >
                                Pokaż więcej
                            </ButtonBorder>
                        </Grid>
                    )}
                    <Grid item xs={12} css={sButtons}>
                        <ButtonBorder link={'/waiter/history'}>
                            Historia Zamówień
                        </ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};
const sButtons = {
    display: 'flex',
    [s.sm_down]: {
        paddingTop: '0.5rem',
    },
    [s.md]: {
        paddingTop: '1.5rem',
    },
};

export default OrdersPage;
