import AccessPage from './Access/AccessPage';
import HomePage from './Home/HomePage';
import MenuPage from './Menu/MenuPage';
import ModifierPage from './Modifiers/ModifierPage';
import ModifiersPage from './Modifiers/ModifiersPage';
import PaymentsPage from './Payments/PaymentsPage';
import ProductNewPage from './Products/ProductNewPage';
import ProductPage from './Products/ProductPage';
import ProductsPage from './Products/ProductsPage';
import RestaurantNewPage from './Restaurant/RestaurantNewPage';
import RestaurantPage from './Restaurant/RestaurantPage';

export const EZRRoutes = [
    {
        path: '/rest',
        component: HomePage,
        exact: true,
    },
    {
        path: '/rest/home',
        component: HomePage,
        exact: true,
    },
    {
        path: '/rest/menu',
        component: MenuPage,
    },
    {
        path: '/rest/modifiers',
        component: ModifiersPage,
        exact: true,
    },
    {
        path: '/rest/modifiers/:id',
        component: ModifierPage,
        exact: true,
    },
    {
        path: '/rest/payments',
        component: PaymentsPage,
    },
    {
        path: '/rest/access',
        component: AccessPage,
    },
    {
        path: '/rest/products',
        component: ProductsPage,
        exact: true,
    },
    {
        path: '/rest/products/:id',
        component: ProductPage,
        exact: true,
    },
    {
        path: '/rest/product/new',
        component: ProductNewPage,
        exact: true,
    },
    {
        path: '/rest/restaurant',
        component: RestaurantPage,
        exact: true,
    },
    {
        path: '/rest/restaurant/new',
        component: RestaurantNewPage,
        exact: true,
    },
];
