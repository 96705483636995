import React, { memo } from 'react';
import { OPERATIONS, useWaiterPollingServiceQuery } from '@graphql/generated';
import { useEffect } from 'react';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { graphqlErrorHandler } from '../../../services/GraphqlErrorService';
import { WaiterInAppNotificationProvider } from '../WaiterInAppNotificationService/WaiterInAppNotificationProvider';
import { client } from '@/boot/client';

export const WaiterPollingProvider = memo(() => {
    const { restaurantId, setOrders } = useWaiterService((state) => state);

    const { data, previousData, startPolling, stopPolling } =
        useWaiterPollingServiceQuery({
            context: { waiter: true },
            fetchPolicy: 'network-only',
            onError: graphqlErrorHandler(true),
            onCompleted: ({ activeOrders, attentionOrders }) => {
                setOrders({
                    activeOrders: activeOrders.metadata.totalCount,
                    attentionOrders: attentionOrders.metadata.totalCount,
                });
            },
            skip: !restaurantId,
            variables: {
                restaurantId: restaurantId ?? '',
            },
            notifyOnNetworkStatusChange: true,
        });

    const attentionCount = data?.attentionOrders?.metadata.totalCount;
    const previousAttentionCount =
        previousData?.attentionOrders?.metadata.totalCount;
    const refreshQueries = !!(
        attentionCount &&
        previousAttentionCount &&
        attentionCount !== previousAttentionCount
    );

    useEffect(() => {
        startPolling(2000);

        return () => {
            stopPolling();
        };
    }, []);

    useEffect(() => {
        if (refreshQueries) {
            client.refetchQueries({
                include: [
                    OPERATIONS.Query.ActivePage,
                    OPERATIONS.Query.OrdersHistory,
                    OPERATIONS.Query.OrdersPage,
                    OPERATIONS.Query.PopupWaiter,
                ],
            });
        }
    }, [refreshQueries]);

    return (
        <WaiterInAppNotificationProvider
            notifications={data?.notifications?.data ?? []}
        />
    );
});
