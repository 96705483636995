/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Collapsable, Bullet, Row } from '../../../../components/Elements';
import { colors } from '../../../../style';
import { dateFormat } from '@helpers/dateFormat';
import { getUserInAppNotification } from '@/EZC/services/UserInAppNotificationService/UserInAppNotifications.helpers';

const Notification = ({ data, id }) => {
    const { notifications } = data;
    const newest = notifications[0];
    const notisL = notifications.length;

    const { title, redirectUrl } = getUserInAppNotification(
        newest,
        newest.type,
    );

    return (
        <Grid item xs={12} md={6}>
            <div>
                <Collapsable
                    noPadding
                    oneLiner
                    left={id % 2 === 0}
                    right={id % 2 !== 0}
                    title={newest.header}
                    image={newest.restaurant.avatarUrl}
                    imageLink={redirectUrl}
                    description={
                        <div
                            css={{
                                overflowX: 'scroll',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <span>{title}</span>
                            <Bullet />
                            {dateFormat.getTimeDiff(newest.createdAt)}
                            <Bullet />
                            {notisL}{' '}
                            {notisL === 1
                                ? 'powiadomienie'
                                : notisL < 5
                                  ? 'powiadomienia'
                                  : 'powiadomień'}
                        </div>
                    }
                >
                    {notifications.map((notification, id) => {
                        const { message, title, icon } =
                            getUserInAppNotification(
                                notification,
                                notification.type,
                            );
                        return (
                            <Row
                                extraCss={{
                                    '.row-children': {
                                        flexShrink: 0,
                                        color: colors.gray600,
                                        fontSize: '12px',
                                        paddingLeft: '0.5rem',
                                    },
                                }}
                                key={id}
                                desc={
                                    <div>
                                        <p
                                            css={{
                                                fontWeight: 'bold',
                                                color: colors.text,
                                            }}
                                        >
                                            {title}
                                        </p>
                                        <p css={{ fontSize: '12px' }}>
                                            {message}
                                        </p>
                                    </div>
                                }
                                icon={icon}
                            >
                                <p>
                                    {dateFormat.standardDate(
                                        notification.createdAt,
                                    )}
                                </p>
                                <p>
                                    {dateFormat.standardTime(
                                        notification.createdAt,
                                    )}
                                </p>
                            </Row>
                        );
                    })}
                </Collapsable>
            </div>
        </Grid>
    );
};

export default Notification;
