import { FilterSettingsSchemaValues } from '@/EZC/domains/Home/organisms/FilterSettingsPopup/FilterSettingsFormProvider';
import { popup } from '@/AppPopups';
import { toast } from 'react-toastify';
import { useController, useFormContext } from 'react-hook-form';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';

export const useFilterSettingsPopup = () => {
    const { handleSubmit, control } =
        useFormContext<FilterSettingsSchemaValues>();

    const { updateUserSettings } = useUserSettingsStoreShallow([
        'updateUserSettings',
    ]);

    const { field: localizationField } = useController({
        name: 'geolocation',
        control: control,
    });

    const { field: distanceField } = useController({
        name: 'distance',
        control: control,
    });

    const { field: preferencesField } = useController({
        control: control,
        name: 'consumptionTypes',
    });

    const handleFormSubmit = handleSubmit(
        async (values) => {
            updateUserSettings({
                consumptionTypes: values.consumptionTypes,
                distance: values.distance,
                geolocation: values.geolocation,
            });

            popup.hide('FilterSettingsPopup');
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );
    return {
        fields: { localizationField, distanceField, preferencesField },
        handleFormSubmit,
    };
};
