import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import PersonIcon from '@material-ui/icons/Person';
import { ChairIcon } from '@/svgs/ChairIcon';

interface CustomerInfoBoxProps {
    name: string;
    avatar?: string;
    onClick?: () => void;
}

export const CustomerInfoBox = ({
    name,
    avatar,
    onClick,
}: CustomerInfoBoxProps) => {
    return (
        <BoxWrapper>
            {avatar ? (
                <Avatar src={avatar} alt={'avatar'} />
            ) : (
                <IconWrapper>
                    <PersonIcon />
                </IconWrapper>
            )}
            <NoAuthUserInfo>
                {name}
                <ConsumptionType>
                    <ChairIcon font-size="inherit" /> Na miejscu
                </ConsumptionType>
            </NoAuthUserInfo>
            {onClick && (
                <ChangeButton onClick={() => onClick()}>Zmień</ChangeButton>
            )}
        </BoxWrapper>
    );
};

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    color: ${colors.white};
    display: flex;
    height: 38px;
    justify-content: center;
    width: 38px;
`;

const Avatar = styled.img`
    border-radius: 50%;
    height: 38px;
    width: 38px;
`;

const NoAuthUserInfo = styled.div`
    align-items: center;
    display: grid;
    font-size: 18px;
    font-weight: 700;
    grid-gap: 4px;
`;

export const ChangeButton = styled.button`
    border: none;
    color: ${colors.text};
    font-size: 12px;
    outline: none;
    text-decoration: underline;
`;

export const ConsumptionType = styled.p`
    align-items: center;
    color: ${colors.blueZodiac};
    display: grid;
    font-size: 12px;
    grid-gap: 2px;
    grid-template-columns: 12px 1fr;
`;

export const BoxWrapper = styled.div`
    align-items: center;
    background: linear-gradient(90deg, #f7f8fa 0%, #f0f2f5 100%);
    border-radius: 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 40px 1fr 42px;
    padding: 16px;
`;
