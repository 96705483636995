import EzAdminChat from '@/EZAdmin/pages/chat/ezadmin-chat.page';
import EzAdminTransactionsByRestaurants from '@/EZAdmin/pages/transactions-by-restaurants/ezadmin-transactions-by-restaurants.page';
import { EZAdminIngredientsPage } from '@/EZAdmin/domains/Ingredients/pages/EZAdminIngredientsPage';

export const ezSupportRoutes = [
    {
        path: '/support/chat',
        component: EzAdminChat,
    },
    {
        path: '/support/transactions-by-restaurants',
        component: EzAdminTransactionsByRestaurants,
    },
    {
        path: '/support/ingredients',
        component: EZAdminIngredientsPage,
    },
];
