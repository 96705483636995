export const EmptyResultsIcon = () => {
    return (
        <svg
            width="33"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.43 5.576c-.845-1.436-3.023-1.436-3.869 0L4.29 23.03c-.788 1.338.169 3.125 1.934 3.125H26.77c1.766 0 2.722-1.787 1.935-3.125L18.43 5.576ZM12.409 4.31c1.812-3.079 6.364-3.079 8.176 0L30.857 21.76c1.842 3.13-.538 6.892-4.088 6.892H6.223c-3.55 0-5.93-3.762-4.088-6.892L12.408 4.31Zm4.088 4.354c.69 0 1.25.56 1.25 1.25v5.83a1.25 1.25 0 0 1-2.5 0v-5.83c0-.69.56-1.25 1.25-1.25Zm0 9.995c.69 0 1.25.56 1.25 1.25v2.498a1.25 1.25 0 0 1-2.5 0v-2.498c0-.69.56-1.25 1.25-1.25Z"
                fill="#FFD500"
            />
        </svg>
    );
};
