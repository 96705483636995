import { StatusBar, Style } from '@capacitor/status-bar';
import { MOBILE, ANDROID } from '@constants/common.constants';

export const useStatusBar = () => {
    const setStatusBarStyleLight = async () => {
        if (ANDROID) {
            await StatusBar.setBackgroundColor({ color: '#ffffff' });
        }
        await StatusBar.setStyle({ style: Style.Light });
    };

    if (MOBILE) {
        setStatusBarStyleLight();
    }
};
