import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';
import { PartialPick } from '@/types/CustomUtilityTypes';
import { filterKeysFromObject } from '@helpers/filterKeysFromObject';

export interface ServerConfigStoreState {
    googleSsoStatus?: boolean;
    initialized: boolean;
    localizedBanner?: boolean;
    restaurantsForLocalizedHomepage?: string[];
    /**
     * Probably temporary, TODO: remove after event
     */
    localizedBannerEnabled?: boolean;
}

interface ServerConfigStore extends ServerConfigStoreState {
    initialize: (value: Partial<ServerConfigStoreState>) => Promise<void>;
    updateServerConfig: (value: Partial<ServerConfigStoreState>) => void;
}

export const ServerConfigStoreDeafultState: ServerConfigStoreState = {
    initialized: false,
    localizedBannerEnabled: false,
};

/**
 * For performance optimization, it is recommended to use `useServerConfigStoreShallow` instead,
 * which returns a shallow copy of the state and prevents unnecessary re-renders.
 */
export const useServerConfigStore = create<ServerConfigStore>()(
    devtools(
        persist(
            (set) => ({
                ...ServerConfigStoreDeafultState,
                initialize: async (state) => {
                    set((prevState) => ({
                        ...prevState,
                        ...state,
                        initialized: true,
                        // NOTE: if localizedBanner is `false` localizedBannerEnabled always should be `false`
                        localizedBannerEnabled: state.localizedBanner
                            ? prevState.localizedBannerEnabled
                            : false,
                    }));
                },
                updateServerConfig: (value) => {
                    set((prevState) => ({ ...prevState, ...value }));
                },
            }),
            {
                name: 'ServerConfigStore',
                partialize: (state) =>
                    filterKeysFromObject(state, [
                        'googleSsoStatus',
                        'localizedBanner',
                        'restaurantsForLocalizedHomepage',
                    ]),
            },
        ),
    ),
);

/**
 * A hook that returns a shallow copy of the ServerConfigStore state.
 * Shallow copies are used to optimize performance by preventing unnecessary re-renders in Zustand.
 *
 * @param keys - An array of keys to be included in the returned state.
 * @returns - A shallow copy of the ServerConfigStore state that only includes the specified keys.
 */
export const useServerConfigStoreShallow = <K extends keyof ServerConfigStore>(
    keys: K[],
): PartialPick<ServerConfigStore, K> =>
    useServerConfigStore(
        useShallow((state) =>
            keys.reduce(
                (prevObject, currentKey) => ({
                    ...prevObject,
                    [currentKey]: state[currentKey],
                }),
                {} as PartialPick<ServerConfigStore, K>,
            ),
        ),
    );

/**
 * A utility function to get the current state of the ServerConfigStore.
 * This function is particularly useful when you need to access the state outside of a React component.
 *
 * @returns - The current state of the ServerConfigStore.
 */
export const getServerConfigStore = useServerConfigStore.getState;
