import React from 'react'; // eslint-disable-line no-unused-vars
import { s, colors, alpha, globals } from '@style';
import { Link } from 'react-router-dom';
import { Bullet } from '@components/Elements';
import useDeliveryInfo from '@/EZC/hooks/useDeliveryInfo';
import { ButtonBase } from '@material-ui/core';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';
import { RestaurantAvatar } from '@/svgs/RestaurantAvatar';
import { EatzonIcon } from '@/componentsV2/atoms/EatzonIcon/EatzonIcon';

const RestContainer = ({ data, isPaid, children, extraCss }) => {
    const {
        address,
        avatarUrl,
        description,
        id,
        name,
        photoUrl,
        slug,
        isBrochure,
    } = data;

    return (
        <Link
            to={`/restaurant/${slug}`}
            css={[
                sContainer,
                isPaid !== undefined && {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
                extraCss,
            ]}
        >
            <div
                css={[
                    sImageContainer,
                    !photoUrl && { backgroundColor: colors.gray200 },
                    isPaid !== undefined && {
                        [s.xs]: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                        [s.sm]: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        },
                    },
                ]}
            >
                <EatzonIcon
                    isInactive={isBrochure}
                    size={10}
                    position="restaurantAbsoluteLeft"
                    space={4}
                />
                {photoUrl && (
                    <img
                        className="image-scale"
                        src={photoUrl}
                        alt={name}
                        loading="lazy"
                        css={sImageContainer.photo}
                    />
                )}
                <div
                    css={[
                        sGradient,
                        isPaid !== undefined && {
                            [s.xs]: {
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            },
                            [s.sm]: {
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                            },
                        },
                    ]}
                />
                {avatarUrl ? (
                    <img
                        className="image-scale-down"
                        src={avatarUrl}
                        alt={name}
                        loading="lazy"
                        css={sAvatar}
                    />
                ) : (
                    <AvatarWrapper>
                        <RestaurantAvatar />
                    </AvatarWrapper>
                )}
                <div css={sTextContainer}>
                    <ContainerWrapper>
                        <h2 className="product-restaurant-title">{name}</h2>
                        <div
                            className="product-restaurant-categories"
                            css={sOneLiner}
                        >
                            {description}
                        </div>
                        <div
                            className="product-restaurant-details"
                            css={sOneLiner}
                        >
                            <GetDistanceToUser restId={id} />
                            <Bullet />
                            {address}
                        </div>
                    </ContainerWrapper>
                </div>
                <ButtonBase
                    css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 6,
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        borderRadius: 'inherit',
                        color: '#ffffff',
                        boxShadow: '-4px 8px 32px rgb(0 0 0 / 16%)',
                    }}
                />
            </div>
            {isPaid !== undefined && (
                <div css={styleIsPaid(isPaid)}>
                    {isPaid ? 'OPŁACONE' : 'NIEOPŁACONE'}
                </div>
            )}
            {children}
        </Link>
    );
};

const GetDistanceToUser = ({ restId }) => {
    const { distanceToUser } = useDeliveryInfo({ restId });
    return (
        <React.Fragment>{(distanceToUser / 1000).toFixed(2)} km</React.Fragment>
    );
};

const ContainerWrapper = styled.div`
    position: relative;
    width: 100%;
    ${mediaQueries.xs} {
        width: 80%;
    }
`;

const AvatarWrapper = styled.div`
    position: absolute;
    width: 96px;
    height: 96px;
    z-index: 4;
    top: 32px;

    ${s.sm_down} {
        width: 64px;
        height: 64px;
        right: 16px;
        top: 16px;
    }

    ${s.md} {
        left: 32px;
    }
`;

export const sContainer = {
    animation: 'fadeIn 0.6s ease-in-out',
    // '@keyframes fadeIn': {
    //   from: { opacity: 0 },
    //   to: { opacity: 1 },
    // },
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: globals.maxWidthMedium,
    height: 400,
    borderRadius: 32,
    flexShrink: 0,
    // overflow: 'hidden',
    marginBottom: '1.5rem',
    '&:hover': {
        [s.hover]: {
            '.image-scale': {
                transform: 'scale(1.1)',
                filter: 'brightness(1.2)',
            },
            '.image-scale-down': { transform: 'scale(0.9)' },
        },
    },
    [s.xs]: {
        width: 'calc(100vw - 48px)',
        height: 'calc(9 * calc(100vw - 48px) / 16)',
        borderRadius: 24,
    },
    [s.sm]: { padding: '1.5rem', height: 400 },
    [s.md]: { padding: '1.5rem 2rem', height: 416 },
    [s.lg]: { height: 400 },
};
const sImageContainer = {
    width: '100%',
    height: '100%',
    position: 'relative',
    [s.xs]: { borderRadius: 24 },
    [s.sm]: { borderRadius: 32 },
    color: '#ffffff',
    overflow: 'hidden',
    photo: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 'inherit',
        objectFit: 'cover',
        objectPosition: 'center center',
        zIndex: 2,
        transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
    },
};

const sGradient = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `linear-gradient(to right, ${colors.text.concat(
        alpha[90],
    )} 33%, ${colors.text.concat(alpha[0])} 66%)`,
    [s.xs]: {
        borderRadius: 24,
        background: `linear-gradient(to bottom, ${colors.text.concat(
            alpha[0],
        )}, ${colors.text.concat(alpha[90])} 66%)`,
    },
    [s.sm]: { borderRadius: 32 },
    zIndex: 3,
};

const sAvatar = {
    width: 96,
    height: 96,
    position: 'absolute',
    zIndex: 4,
    top: 32,
    borderRadius: '50%',
    boxShadow: '2px 4px 16px rgba(0,0,0,0.16)',
    transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
    [s.sm_down]: {
        width: 64,
        height: 64,
        right: 16,
        top: 16,
    },
    [s.md]: { left: 32 },
};

const sTextContainer = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 5,
    color: '#ffffff',
    padding: '2rem',
    width: '100%',
    [s.xs]: { borderRadius: 24 },
    [s.sm]: { borderRadius: 32, padding: '2.5rem' },
    [s.sm_down]: { padding: '1.5rem' },
    '.product-restaurant-title': {
        letterSpacing: '-0.05em',
        lineHeight: '44px',
        fontSize: '38px',
        [s.sm_down]: { fontSize: '18px', lineHeight: '20px' },
    },
    '.product-restaurant-categories': {
        fontSize: '13px',
        opacity: 0.8,
        marginTop: '0.5rem',
        [s.md]: {
            opacity: 0.6,
            fontSize: '18px',
            marginTop: '0.5rem',
        },
    },
    '.product-restaurant-details': {
        fontSize: '12px',
        opacity: 0.6,
        marginTop: '0.5rem',
        [s.md]: {
            opacity: 0.6,
            fontSize: '15px',
            marginTop: '2rem',
        },
    },
};

const sOneLiner = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 'calc(100vw - 100px)',
    [s.md]: {
        maxWidth: 'calc(100vw - 140px)',
    },
};

export const styleIsPaid = (isPaid) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#ffffff',
    alignItems: 'center',
    background: isPaid
        ? `linear-gradient(to right, ${colors.green}, ${colors.green800})`
        : `linear-gradient(to right, ${colors.red600}, ${colors.red800})`,
    width: '100%',
    borderRadius: '0 0 16px 16px',
    padding: '1rem 1rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '0.2em',
    height: 24,
    fontSize: '0.75rem',
    [s.md]: {
        padding: '1rem 2rem',
        paddingLeft: '2.5rem',
    },
    [s.sm_down]: {
        paddingLeft: '1.5rem',
    },
});

export default RestContainer;
