/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Line } from 'react-chartjs-2';

import { Grid } from '@material-ui/core';
import { dateFormat } from '@helpers/dateFormat';

export function dataParse(data) {
    const { dayAndMonth } = dateFormat;

    const labels = data.map((el) => dayAndMonth(new Date(el.label)));
    const values = data.map((el) => el.value);

    return {
        labels,
        values,
    };
}

const ChartSales = ({ sales, loading, error }) => {
    if (loading) return null;
    if (error) return null;

    const { labels, values } = dataParse(sales ?? []);

    return (
        <Grid item xs={12} lg={6}>
            <Line
                data={dataLine(labels, values)}
                options={options}
                height={300}
            />
        </Grid>
    );
};

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    callback: (value) => `${value} zł`,
                    min: 0,
                },
            },
        ],
    },
};

const dataLine = (labels, data) => ({
    labels,
    datasets: [
        {
            legend: { display: false },
            label: 'Sprzedaż',
            data,
            fill: true,
            lineTension: 0.25,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 3,
            pointRadius: 1,
            pointHitRadius: 20,
        },
    ],
});

export default ChartSales;
