import React, { useRef, useState, useEffect } from 'react';
import { useVisibleComponent } from '@/hooks/useVisibleComponent';

interface VisibleComponent {
    onEnter: (element: IntersectionObserverEntry) => void;
    onLeave: (element: IntersectionObserverEntry) => void;
    rootMargin?: number;
    children: React.ReactNode;
}

export const VisibleComponent = ({
    onEnter,
    onLeave,
    rootMargin = 0,
    children,
}: VisibleComponent) => {
    const triggerRef = useRef(null);
    const visibleElement = useVisibleComponent(triggerRef, `${rootMargin}px`);

    useEffect(() => {
        if (visibleElement)
            visibleElement?.isIntersecting
                ? onEnter(visibleElement)
                : onLeave(visibleElement);
    }, [visibleElement]);

    return <div ref={triggerRef}>{children}</div>;
};
