import React from 'react';

export const CarIcon = () => (
    <svg
        width="25"
        height="24"
        fill="inherit"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M23 10.5h-1.012l-2.605-5.86a1.5 1.5 0 0 0-1.37-.89H6.986a1.5 1.5 0 0 0-1.37.89L3.013 10.5H2A.75.75 0 1 0 2 12h.75v7.5a1.5 1.5 0 0 0 1.5 1.5H6.5A1.5 1.5 0 0 0 8 19.5V18h9v1.5a1.5 1.5 0 0 0 1.5 1.5h2.25a1.5 1.5 0 0 0 1.5-1.5V12H23a.75.75 0 1 0 0-1.5ZM6.988 5.25h11.024l2.334 5.25H4.654l2.333-5.25ZM6.5 19.5H4.25V18H6.5v1.5Zm12 0V18h2.25v1.5H18.5Zm2.25-3H4.25V12h16.5v4.5Zm-15-2.25a.75.75 0 0 1 .75-.75H8A.75.75 0 1 1 8 15H6.5a.75.75 0 0 1-.75-.75Zm10.5 0a.75.75 0 0 1 .75-.75h1.5a.75.75 0 1 1 0 1.5H17a.75.75 0 0 1-.75-.75Z"
            fill="inherit"
        />
    </svg>
);
