/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';
import { Collapse } from '@material-ui/core';
import Input from '@/EZC/views/Auth/components/Input.component';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors, s } from '@style/index';
import { CollapsableText } from '@components/Elements/Collapsable/Collapsable';
import { DefaultWalletAmounts } from '@/EZC/domains/Account/molecules/DefaultWalletAmounts/DefaultWalletAmounts';
import styled from '@emotion/styled';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';

export interface WalletChargeProps {
    amount?: string | number;
    description: string;
    isLoading?: boolean;
    onValueChange: (value: string | number) => void;
    onSubmit?: () => void;
    expanded?: boolean;
    preventCollapse?: boolean;
    title: string;
}

export const WalletCharge = ({
    expanded,
    description,
    preventCollapse,
    amount,
    isLoading,
    onValueChange,
    onSubmit,
    title,
}: WalletChargeProps) => {
    return (
        <Accordion
            preventCollapse={preventCollapse}
            isOpen={expanded}
            removeExpandIcon={preventCollapse}
            collapsedPadding={'0'}
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <SwapVerticalCircleIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>{title}</Title>
                        <Description>{description}</Description>
                    </InfoWrapper>
                </ContentWrapper>
            }
        >
            <CollapsableText>
                <div css={{ position: 'relative' }}>
                    <Input
                        // @ts-expect-error migrate to ts
                        darker
                        placeholder="Kwota"
                        onChange={onValueChange}
                        value={amount}
                        type="number"
                        sBaseExtra={{
                            marginBottom: 0,
                            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
                                {
                                    appearance: 'none',
                                    margin: 0,
                                },
                            'input[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        }}
                    />
                    <Currency changeOpacity={!amount}>PLN</Currency>
                </div>
                <DefaultAmountsWrapper>
                    <DefaultWalletAmounts
                        onValueChange={onValueChange}
                        amount={amount ?? ''}
                    />
                </DefaultAmountsWrapper>
                {onSubmit && (
                    <SubmitButton
                        width={'100%'}
                        variant={amount ? 'black' : 'gray'}
                        onClick={onSubmit}
                        loading={isLoading}
                    >
                        Zapłać
                    </SubmitButton>
                )}
                <Collapse in={!!amount}>
                    <InfoWithTerms>
                        Przechodząc do bramki płatniczej, potwierdzasz
                        zapoznanie się z{' '}
                        <Link
                            to="/terms"
                            css={{
                                color: colors.text,
                                fontWeight: 700,
                                textDecoration: 'underline',
                            }}
                        >
                            Regulaminem
                        </Link>
                    </InfoWithTerms>
                </Collapse>
            </CollapsableText>
        </Accordion>
    );
};

const Currency = styled.span<{ changeOpacity: boolean }>(
    ({ changeOpacity }) => css`
        color: ${colors.text};
        font-size: 13px;
        font-weight: 700;
        opacity: ${changeOpacity ? 0 : 1};
        position: absolute;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
        transition: opacity 0.3s ease-out;
    `,
);

const DefaultAmountsWrapper = styled.div`
    margin: 24px 0 0;

    ${s.md} {
        margin: 32px 0 0;
    }
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-gap: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    align-items: center;
    display: grid;
    margin-right: 16px;
    width: 100%;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const InfoWithTerms = styled.p`
    padding-top: 24px;
    font-size: 11px;
`;

const SubmitButton = styled(Button)`
    margin-top: 24px;
`;
