import { Contacts } from '@capacitor-community/contacts';

export const usePhoneBookService = () => {
    const {
        checkPermissions,
        requestPermissions,
        getContacts: getContactsList,
    } = Contacts;

    const getContacts = async () => {
        try {
            let permissionStatus = await checkPermissions();

            if (permissionStatus.contacts === 'prompt') {
                permissionStatus = await requestPermissions();
            }

            if (permissionStatus.contacts !== 'granted') {
                throw new Error('User denied permissions!');
            }

            const contacts = await getContactsList({
                projection: {
                    name: true,
                    phones: true,
                },
            });

            const contactsList = contacts.contacts.map((contact) => ({
                name: contact.name?.display || '',
                phone: contact.phones?.[0].number || '',
            }));

            return contactsList;
        } catch (error) {
            console.error(error);
        }
    };

    return {
        getContacts,
    };
};
