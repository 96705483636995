import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style';
import {
    SplitPaymentUser,
    SplitPaymentUserProps,
} from '@/componentsV2/atoms/SplitPaymentUser/SplitPaymentUser';
import X from '@material-ui/icons/Clear';
import Arrow from '@material-ui/icons/ArrowRightAlt';
import { SplitPaymentStatus } from '@/componentsV2/atoms/SplitPaymentStatus/SplitPaymentStatus';
import { OrderItemProductPayoffFragment } from '@graphql/generated';

interface DetailsOrderContentProps {
    payoffRequests?: OrderItemProductPayoffFragment['payoffRequest'][];
    productId: string;
    users?: SplitPaymentUserProps[];
}

export const DetailsOrderContent = ({
    payoffRequests,
    productId,
    users,
}: DetailsOrderContentProps) => {
    return (
        <>
            <SplitOrderTitle>Szczegóły przydzielenia płatności</SplitOrderTitle>
            <SplitOrderDescription>
                Sprawdz obecny status rozliczenia przydzielonych produktów
            </SplitOrderDescription>
            <SplitPaymentList>
                {users?.map((user) => {
                    const paymentRequest = payoffRequests?.find(
                        (payoffRequest) =>
                            payoffRequest?.recipient.id === user.id,
                    );
                    const quantity =
                        paymentRequest?.products.filter(
                            (product) => product.id === productId,
                        ).length ?? 0;
                    const price = paymentRequest?.products[0]?.price ?? 0;
                    const amount = price * quantity;

                    if (paymentRequest)
                        return (
                            <SplitPaymentItem key={user.id}>
                                <SplitPaymentUser {...user} />
                                <ProductPriceWrapper>
                                    <ProductPrice>
                                        {quantity > 0 && (
                                            <>
                                                {quantity}{' '}
                                                <IconWrapper>
                                                    <X />
                                                </IconWrapper>
                                            </>
                                        )}
                                        {price.toFixed(2)} <span>&nbsp;zł</span>
                                        {quantity > 1 && (
                                            <>
                                                <IconWrapper>
                                                    <Arrow />
                                                </IconWrapper>
                                                {amount?.toFixed(2)}{' '}
                                                <span>&nbsp;zł</span>
                                            </>
                                        )}
                                    </ProductPrice>
                                    <SplitPaymentStatus
                                        status={paymentRequest.status}
                                    />
                                </ProductPriceWrapper>
                            </SplitPaymentItem>
                        );
                })}
            </SplitPaymentList>
        </>
    );
};

const SplitOrderTitle = styled.h2`
    color: ${colors.text};
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
`;

const SplitOrderDescription = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
    line-height: 16px;
`;

const SplitPaymentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
`;

const SplitPaymentItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const ProductPriceWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const ProductPrice = styled.p`
    align-items: center;
    color: ${colors.text};
    display: flex;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;

    span {
        opacity: 0.3;
    }
`;

const IconWrapper = styled.span`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 0.25rem;

    svg {
        font-size: 14px;
    }
`;
