import { DeepPartial } from 'src/types/definitions/common';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { IN_APP_NOTIFICATION_WAITER } from '@constants/fromGraphql/InAppNotificationsType.constants';
import {
    ConsumptionType,
    InAppNotification,
    InAppNotificationsType,
} from '@graphql/generated';
import { TOAST_TIME_AUTO_CLOSE } from '@constants/notifications.constans';
import { useSoundService } from '@/services/SoundService/SoundService';
import waiterOrderNew from '@/assets/sounds/waiter_order_new.mp3';
import waiterOrderAddProduct from '@/assets/sounds/waiter_order_add_product.mp3';
import waiterOrderCancel from '@/assets/sounds/waiter_order_cancel.mp3';

export const useWaiterInAppNotification = () => {
    const history = useHistory();
    const { playSound, stopPlayingSound } = useSoundService();

    const NOTIFICATION_TYPE: Record<
        InAppNotificationsType,
        {
            toast: (notification: DeepPartial<InAppNotification>) => void;
        } | null
    > = {
        ORDER_CANCELED: {
            toast: ({ order, id = '' }) => {
                toast.info(
                    IN_APP_NOTIFICATION_WAITER['ORDER_CANCELED']?.common,
                    {
                        onClick: () => {
                            history.push(`/waiter/order/${order?.id}`);
                        },
                        autoClose: TOAST_TIME_AUTO_CLOSE,
                        onClose: () => stopPlayingSound(id),
                    },
                );
                playSound(waiterOrderCancel, id);
            },
        },
        ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED: {
            toast: ({ order }) => {
                toast.success(
                    IN_APP_NOTIFICATION_WAITER[
                        'ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED'
                    ]?.custom({
                        type: order?.details?.type ?? ('IN' as ConsumptionType),
                    }),
                    {
                        onClick: () => {
                            history.push(`/waiter/order/${order?.id}`);
                        },
                    },
                );
            },
        },
        ORDER_CREATED: {
            toast: ({ order, id = '' }) => {
                toast.success(
                    IN_APP_NOTIFICATION_WAITER['ORDER_CREATED']?.common,
                    {
                        onClick: () => {
                            history.push(`/waiter/order/${order?.id}`);
                        },
                        autoClose: TOAST_TIME_AUTO_CLOSE,
                        onClose: () => stopPlayingSound(id),
                    },
                );
                playSound(waiterOrderNew, id);
            },
        },
        ORDER_NOTIFY_ARRIVAL: {
            toast: ({ order }) => {
                toast.info(
                    IN_APP_NOTIFICATION_WAITER['ORDER_NOTIFY_ARRIVAL']?.common,
                    {
                        onClick: () => {
                            history.push(`/waiter/order/${order?.id}`);
                        },
                        autoClose: TOAST_TIME_AUTO_CLOSE,
                    },
                );
            },
        },
        ORDER_PRODUCT_ADDED: {
            toast: ({ order, id = '' }) => {
                toast.info(
                    IN_APP_NOTIFICATION_WAITER['ORDER_PRODUCT_ADDED']?.common,
                    {
                        onClick: () => {
                            history.push(`/waiter/order/${order?.id}`);
                        },
                        autoClose: TOAST_TIME_AUTO_CLOSE,
                        onClose: () => stopPlayingSound(id),
                    },
                );
                playSound(waiterOrderAddProduct, id);
            },
        },
        ORDER_ADDITION: null,
        CART_APPROVAL_REQUEST: null,
        GROUP_ADDED_TO_GROUP: null,
        NEW_ORDER_PAYMENT_REQUEST: null,
        ORDER_ACCEPTED: null,
        ORDER_MOVED_TO_CLOSED: null,
        ORDER_MOVED_TO_READY: null,
        ORDER_MOVED_TO_PREPARED: null,
        ORDER_PARTIAL_ACCEPTANCE: null,
        ORDER_PAYMENT_REQUEST_ACCEPTED: null,
        ORDER_PAYMENT_REQUEST_REJECTED: null,
        ORDER_REJECTED: null,
        PAYOFF_ACCEPTED: null,
        PAYOFF_REJECTED: null,
        PAYOFF_REQUESTED: null,
        ORDER_TABLE_CHANGED: null,
        ORDER_ADDITION_USER_ACCEPTED: null,
        ORDER_ADDITION_USER_REJECTED: null,
        ORDER_ADDITION_WAITER_ACCEPTED: null,
        ORDER_ADDITION_WAITER_REJECTED: null,
        ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE: null,
        ORDER_ADDITION_FROM_USER: {
            toast: ({ order, id = '' }) => {
                const message = IN_APP_NOTIFICATION_WAITER[
                    'ORDER_ADDITION_FROM_USER'
                ]?.custom({
                    order: order?.shortId ?? '',
                });
                const redirectUrl = `/waiter/order/${order?.id}`;

                toast.info(message, {
                    onClick: () => history.push(redirectUrl),
                    autoClose: TOAST_TIME_AUTO_CLOSE,
                    onClose: () => stopPlayingSound(id),
                });
                playSound(waiterOrderAddProduct, id);
            },
        },
        ORDER_ADDITION_FROM_WAITER: null,
        ORDER_ETA_CHANGED: null,
        NEW_CHAT_MESSAGE: {
            toast: ({ order }) => {
                toast.info(
                    IN_APP_NOTIFICATION_WAITER['NEW_CHAT_MESSAGE']?.custom({
                        orderId: order?.shortId || '',
                    }),
                    {
                        onClick: () => {
                            history.push(`/waiter/chat/${order?.id}`);
                        },
                        autoClose: TOAST_TIME_AUTO_CLOSE,
                    },
                );
            },
        },
        ORDER_MOVED_TO_DELIVERY: null,
        CLEAR_NOTIFICATION_SOUNDS: null,
    };
    return {
        NOTIFICATION_TYPE,
    };
};
