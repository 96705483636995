import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { ReactNode } from 'react';

interface ButtonSecondaryProps {
    icon: ReactNode;
    variant: 'green' | 'default';
    small?: boolean;
    disabled?: boolean;
    title: string;
    onClick: () => void;
}

export const ButtonSecondary = ({
    variant,
    disabled = false,
    icon,
    title,
    onClick,
    small,
}: ButtonSecondaryProps) => {
    return (
        <Button
            variant={variant}
            onClick={onClick}
            disabled={disabled}
            small={small}
        >
            {icon} {title}
        </Button>
    );
};

const Button = styled.button<{
    disabled: boolean;
    small?: boolean;
    variant: string;
}>(
    ({ disabled, variant, small }) => css`
        align-items: center;
        border-radius: 24px;
        cursor: pointer;
        display: flex;
        font-size: 15px;
        font-weight: 700;
        gap: 8px;
        height: 56px;
        padding: 16px 24px;
        border: none;
        width: 100%;

        ${variant === 'default' &&
        `
        color: ${colors.grayDark}; 
        background: ${colors.gray900}; 

        &:hover {
            background: ${colors.textDimmed};
        }`}

        ${variant === 'green' &&
        `
            background: ${colors.green500};
            color: ${colors.green};
            
            &:hover {
                background: ${colors.greenDimmed};
            }
        `}

        ${small &&
        `
            font-size: 13px;
            height: auto;
            text-align: unset;
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-gap: 8px;
        `}

        ${disabled &&
        `
            cursor: default;
            pointer-events: none;
        `}
    `,
);
