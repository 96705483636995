import React, { CSSProperties, ReactNode } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from '@emotion/styled';
import { AverageRating } from '@/componentsV2/atoms/AverageRating/AverageRating';
import { SplitPaymentStatus } from '@/componentsV2/atoms/SplitPaymentStatus/SplitPaymentStatus';
import { colors } from '@/style';
import { css } from '@emotion/core';
import { mediaQueriesMaterial } from '@style/breakpoints';
import { Payoff } from '@graphql/generated';
import Arrow from '@material-ui/icons/ArrowRightAlt';
import X from '@material-ui/icons/Clear';
import { ProductAvatar } from '@/svgs/ProductAvatar';
import { ProductStatusBadge } from '@/componentsV2/atoms/ProductStatusBadge/ProductStatusBadge';
import { ProductLabel } from '@graphql/generated';
import { Badge } from '@components/Elements';

export interface ProductTileProps {
    average: number;
    categories: string[];
    height?: CSSProperties['height'];
    imageUrl: string;
    loading?: boolean;
    name: string;
    onClick?: () => void;
    payoffRequests?: Pick<Payoff, 'status'>[];
    price?: number;
    productLabel?: ProductLabel;
    showBagde?: boolean;
    productPrice: number;
    quantity?: number;
    ratings: number;
    rightElement?: ReactNode;
    splitPayment?: () => void;
}

export const ProductTile = ({
    average,
    categories,
    height,
    imageUrl,
    loading,
    name,
    onClick,
    payoffRequests,
    price,
    productLabel,
    productPrice,
    quantity = 0,
    ratings,
    rightElement,
    splitPayment,
    showBagde,
}: ProductTileProps) => {
    const showStatus = payoffRequests?.every(
        (payoffRequest) =>
            payoffRequest.status === payoffRequests[0].status &&
            payoffRequest.status !== 'PENDING',
    );
    const isPending = payoffRequests?.find(
        (payoffRequest) => payoffRequest.status === 'PENDING',
    );
    const status = payoffRequests?.[0]?.status;

    if (loading)
        return (
            <ProductTileWrapper height={height}>
                <Skeleton
                    animation="wave"
                    height={'100%'}
                    variant="rect"
                    width={'calc(100% - 100px)'}
                />
                <Skeleton
                    animation="wave"
                    height={70}
                    variant="circle"
                    width={70}
                />
            </ProductTileWrapper>
        );

    return (
        <ProductTileWrapper
            height={height}
            onClick={onClick}
            pointer={!!onClick}
        >
            <ProductContentWrapper>
                <ProductDetailsWrapper>
                    <ProductDetails>
                        <ProductName>{name}</ProductName>
                        <AverageRating average={average} ratings={ratings} />
                        <ProductCategories>
                            {categories?.join(', ')}
                        </ProductCategories>
                    </ProductDetails>
                </ProductDetailsWrapper>
                <ProductSideWrapper>
                    {productLabel && (
                        <ProductStatusBadge value={productLabel} />
                    )}
                    {imageUrl ? (
                        <>
                            <ProductImage imageUrl={imageUrl} />
                            {showBagde && (
                                /* @ts-expect-error migrate to ts */
                                <Badge color={colors.red} show showAnimation />
                            )}
                        </>
                    ) : (
                        <ProductIconWrapper>
                            <ProductAvatar />
                            {showBagde && (
                                /* @ts-expect-error migrate to ts */
                                <Badge color={colors.red} show showAnimation />
                            )}
                        </ProductIconWrapper>
                    )}
                </ProductSideWrapper>
            </ProductContentWrapper>
            <ProductPayment
                displayGrid={!!splitPayment}
                marginTop={!!rightElement}
            >
                <ProductPrice>
                    {quantity > 1 && (
                        <>
                            {quantity}{' '}
                            <IconWrapper>
                                <X />
                            </IconWrapper>
                        </>
                    )}
                    {productPrice.toFixed(2)} <span>&nbsp;zł</span>
                    {quantity > 1 && (
                        <>
                            <IconWrapper>
                                <Arrow />
                            </IconWrapper>
                            {price?.toFixed(2)} <span>&nbsp;zł</span>
                        </>
                    )}
                </ProductPrice>
                {!!splitPayment && !status && productLabel !== 'CANCELED' && (
                    <SplitPayment onClick={splitPayment}>
                        Przydziel płatność
                    </SplitPayment>
                )}
                {!!splitPayment &&
                    (status || isPending) &&
                    productLabel !== 'CANCELED' && (
                        <StatusDetails>
                            {status && showStatus && (
                                <SplitPaymentStatus status={status} />
                            )}
                            {isPending && (
                                <SplitPaymentStatus status={'PENDING'} />
                            )}
                            {status && (
                                <SplitPayment onClick={splitPayment}>
                                    Sprawdź szczegóły
                                </SplitPayment>
                            )}
                        </StatusDetails>
                    )}
                {rightElement && <RightElement>{rightElement}</RightElement>}
            </ProductPayment>
        </ProductTileWrapper>
    );
};

const ProductTileWrapper = styled.div<{
    height?: CSSProperties['height'];
    pointer?: boolean;
}>(
    ({ height, pointer }) => css`
        background: linear-gradient(
            to right,
            ${colors.gray100},
            ${colors.gray200}
        );
        border-radius: 16px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 1fr auto;
        padding: 32px;
        min-height: ${height ?? 'auto'};
        cursor: ${pointer ? 'pointer' : 'default'};

        ${mediaQueriesMaterial.sm} {
            height: auto;
            min-height: unset;
            padding: 16px;
        }
    `,
);

const ProductContentWrapper = styled.div`
    display: flex;
    gap: 24px;
    justify-content: space-between;
`;

const IconWrapper = styled.span`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 0.25rem;

    svg {
        font-size: 14px;
    }
`;

const ProductPrice = styled.p`
    align-items: center;
    color: ${colors.text};
    display: flex;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;

    span {
        opacity: 0.3;
    }
`;

const ProductDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    overflow: hidden;
`;

const ProductSideWrapper = styled.div`
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
`;

const StatusDetails = styled.p`
    display: flex;
    gap: 16px;
    width: 100%;
`;

const ProductDetails = styled.div`
    display: grid;
`;

const ProductName = styled.span`
    color: ${colors.text};
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 4px;
`;

const ProductCategories = styled.span`
    color: ${colors.gray800};
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 12px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ProductPayment = styled.div<{
    displayGrid?: boolean;
    marginTop?: boolean;
}>(
    ({ displayGrid, marginTop }) => css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        margin-top: ${marginTop ? 8 : 0}px;

        ${mediaQueriesMaterial.xs} {
            display: ${displayGrid ? 'grid' : 'flex'};
        }
    `,
);

const SplitPayment = styled.span`
    color: ${colors.text};
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
`;

const ProductImage = styled.div<{ imageUrl: string }>(
    ({ imageUrl }) => css`
        border-radius: 50%;
        background-image: url(${imageUrl});
        background-size: contain;
        background-position: center center;
        height: 70px;
        overflow: hidden;
        width: 70px;
    `,
);

const ProductIconWrapper = styled.div`
    height: 70px;
    width: 70px;
`;

const RightElement = styled.div`
    margin-left: auto;
`;
