/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { sDescription, sGrid } from '../../../../style/style';
import { Grid, TextField } from '@material-ui/core';
import { useRestaurantContext } from '../context/restaurant.provider';
import { sInput } from '../../../style/input.style';
import GooglePlacesAutocomplete from '../../../../components/GooglePlacesAutocomplete';
import StatusActive from './StatusActive';
import ImageLogo from './ImageLogo';
import ImageBackground from './ImageBackground';
import { s } from '../../../../style';
import { RestaurantTableUpload } from './RestaurantTableUpload/RestaurantTableUpload';

const Details = ({ isNewRestaurant }) => {
    const {
        restaurantInfo,
        handleRestaurantInfo,
        handleChangeAddres,
        handleSelectAddress,
    } = useRestaurantContext();

    return (
        <Grid container css={sGrid}>
            <p css={sDescription}>
                Modyfikuj szczegóły restauracji, aktywuj kolejne typy zamówień,
                zarządzaj godzinami otwarcia oraz ustawieniami dostaw.
            </p>
            <Grid item xs={12} md={12} css={{ marginTop: '2rem' }}>
                <TextField
                    required
                    label="Nazwa"
                    name="name"
                    value={restaurantInfo.name}
                    onChange={handleRestaurantInfo}
                    fullWidth
                    css={sInput}
                />
            </Grid>
            <Grid item xs={12}>
                <GooglePlacesAutocomplete
                    required
                    value={restaurantInfo.address}
                    onChange={handleChangeAddres}
                    onSelect={handleSelectAddress}
                    label={'Adres'}
                    restaurantView
                />
            </Grid>
            <Grid item xs={12} css={{ [s.xs]: { marginTop: '0.5rem' } }}>
                <TextField
                    label="Opis"
                    name="description"
                    required
                    value={restaurantInfo.description}
                    onChange={handleRestaurantInfo}
                    fullWidth
                    css={sInput}
                    multiline
                    rowsMax={10}
                    rows={2}
                />
            </Grid>
            <ImageLogo />
            <ImageBackground />
            <RestaurantTableUpload isNewRestaurant={isNewRestaurant} />
            <StatusActive />
        </Grid>
    );
};

export default Details;
