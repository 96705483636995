/** @jsx jsx */ /** @jsxRuntime classic */
import _, { sortBy } from 'lodash';
import React, { useState, useMemo } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { ButtonBorder, Button } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import {
    sGrid,
    sOuter,
    sNoMarginTop,
    sNoPaddingTop,
    sDescription,
    sPaddingTop,
} from '@style/style';
import { sRestSkeleton, sRestSkeletonInner } from '@/EZW/pages/Home/HomePage';
import { RestContainerWithSaldo } from '@/EZR/pages/Home/HomePage';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import {
    Grid,
    Hidden,
    Collapse,
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@material-ui/core';
import Transaction from './Transaction';
import { colors, s } from '@style';
import WidgetContainer from '@components/Widget/Widget.component';
import { sGridOverride, sHeadline } from '@/EZW/pages/Popups/styles';
import { Input } from '@/EZC/views/Auth/components';
import ChartSales from './ChartSales';
import ChartOrders from './ChartOrders';
import {
    usePaymentsPageQuery,
    usePaymentsPageTransactionsListLazyQuery,
    useProfitCsvDataLazyQuery,
} from '@graphql/generated';
import { Button as ShowMoreButton } from '@/EZAdmin/components';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { dateFormat } from '@helpers/dateFormat';
import { useAuthService } from '@/services/AuthService';
import { PaymentsHelpers } from './Payments.helpers';
import { generateCsvFile } from '@helpers/generateCsvFile';
import styled from '@emotion/styled';

const PaymentsPage = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);

    const [sortKey, setSortKey] = useState('date');
    const [sortDir, setSortDir] = useState('DESC');
    const [sortOpen, setSortOpen] = useState(false);

    const [startDate, setStartDate] = useState(
        firstDay.toISOString().slice(0, 10),
    );
    const [endDate, setEndDate] = useState(lastDay.toISOString().slice(0, 10));
    const [raportOpen, setRaportOpen] = useState(false);

    const restaurantId = useAuthService((state) => state.restaurantId);

    const { getDateBeforeDays } = dateFormat;
    const dateFromTwoWeeks = getDateBeforeDays(14);
    const dateFromToday = getDateBeforeDays(0);
    const {
        data: dataRestaurant,
        loading: dataRestaurantLoading,
        error: dataRestaurantError,
    } = usePaymentsPageQuery({
        variables: {
            input: { restaurantId },
            startDate: dateFromTwoWeeks,
            endDate: dateFromToday,
        },
        skip: !restaurantId,
        onError: graphqlErrorHandler(true),
    });

    const [getTransactions, { data, loading }] =
        usePaymentsPageTransactionsListLazyQuery();

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination, input) =>
            getTransactions({
                variables: {
                    input,
                    pagination,
                },

                onError: graphqlErrorHandler(true),
                fetchPolicy: 'cache-and-network',
            }),
        metadata: data?.transactionRestaurantList.metadata,
        input: {
            restaurantId,
            sort: {
                [sortKey]: sortDir,
            },
        },
    });
    const [getProfitCsvData, { loading: isCsvDataLoading }] =
        useProfitCsvDataLazyQuery();

    const onDownload = () =>
        getProfitCsvData({
            variables: {
                input: {
                    restaurantId,
                },
                startDate,
                endDate,
            },
            onCompleted: ({ restaurant: { profitCsvData } }) => {
                const filename = `${dataRestaurant.restaurant.name}-${startDate}-${endDate}`;
                generateCsvFile(profitCsvData, filename);
            },
            onError: graphqlErrorHandler(true),
        });

    const handleSort = (event) => {
        setSortKey(event.target.value);
        setSortOpen(false);
    };

    const handleSortDir = (event) => {
        setSortDir(event.target.value);
        setSortOpen(false);
    };

    const { getFormattedStatisticsData } = PaymentsHelpers;

    const sales = useMemo(
        () =>
            getFormattedStatisticsData(
                dataRestaurant?.restaurant.profitStats.map(
                    ({ date, totalAmount }) => ({
                        label: date,
                        value: totalAmount,
                    }),
                ) || [],
            ),
        [dataRestaurant?.restaurant.profitStats],
    );

    const orders = useMemo(
        () =>
            getFormattedStatisticsData(
                dataRestaurant?.restaurant.ordersStats.map(
                    ({ date, count }) => ({
                        label: date,
                        value: count,
                    }),
                ) || [],
            ),
        [dataRestaurant?.restaurant.ordersStats],
    );

    // Converts your Array<Object> to a CsvOutput string based on the configs

    return (
        <React.Fragment>
            <TopNav title={'Transakcje'} offset={60} />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <div css={{ display: 'flex' }}>
                            <ButtonBorder action={() => setSortOpen(!sortOpen)}>
                                Sortuj
                            </ButtonBorder>
                            <Hidden smDown>
                                <div css={{ padding: '0 0.5rem' }} />
                                <ButtonBorder
                                    action={() => setRaportOpen(!raportOpen)}
                                >
                                    Raport CSV
                                </ButtonBorder>
                            </Hidden>
                        </div>
                    }
                >
                    Transakcje
                </Headline>
            </div>
            {dataRestaurant ? (
                <RestContainerWithSaldo
                    dataRestaurant={dataRestaurant}
                    saldoChildren={
                        <ButtonBorder action={() => setRaportOpen(!raportOpen)}>
                            CSV
                        </ButtonBorder>
                    }
                />
            ) : (
                <div css={sRestSkeleton}>
                    <div css={sRestSkeletonInner} />
                </div>
            )}
            <div css={[sOuter, sNoMarginTop, sNoPaddingTop]}>
                <Grid
                    container
                    css={[
                        sGrid,
                        {
                            [s.sm_down]: { marginTop: '1.5rem' },
                            [s.md]: { marginTop: '1.5rem' },
                        },
                    ]}
                    justifyContent="space-between"
                >
                    {data?.transactionRestaurantList?.data.map(
                        (data, index) => (
                            <Transaction
                                id={index}
                                data={data}
                                key={index + data.id}
                            />
                        ),
                    )}
                    {loading && (
                        <>
                            <SkeletonOrder />
                            <SkeletonOrder />
                            <SkeletonOrder />
                            <SkeletonOrder />
                        </>
                    )}
                </Grid>
                <Grid
                    container
                    css={[
                        sGrid,
                        {
                            [s.sm_down]: { marginTop: '1.5rem' },
                            [s.md]: { marginTop: '1.5rem' },
                        },
                    ]}
                >
                    {hasMore && (
                        <ShowMoreButton onClick={getMoreData}>
                            Pokaż więcej
                        </ShowMoreButton>
                    )}
                </Grid>
                <Grid
                    container
                    css={[
                        sGrid,
                        {
                            paddingBottom: '48px',
                            [s.sm_down]: { marginTop: '1.5rem' },
                            [s.md]: { marginTop: '1.5rem' },
                        },
                    ]}
                >
                    <ChartSales
                        sales={sales}
                        loading={dataRestaurantLoading}
                        error={dataRestaurantError}
                    />
                    <ChartOrders
                        orders={orders}
                        loading={dataRestaurantLoading}
                        error={dataRestaurantError}
                    />
                </Grid>
            </div>
            <WidgetContainer
                isWidgetOpen={sortOpen}
                setIsWidgetOpen={setSortOpen}
            >
                <Collapse in={sortOpen}>
                    <Headline extraCss={sHeadline}>Sortowanie</Headline>
                    <div css={[sGrid, sGridOverride]}>
                        <p css={[sDescription, { fontSize: '13px' }]}>
                            Wybierz właściwość, względem której lista transakcji
                            zostanie posortowana. Zaznacz kierunek rosnący lub
                            malejący
                        </p>
                    </div>
                    <Grid
                        container
                        css={[
                            sGrid,
                            sGridOverride,
                            {
                                justifyContent: 'space-between',
                                paddingTop: '1rem',
                                [s.xs]: { paddingBottom: '4rem' },
                            },
                        ]}
                    >
                        <Grid item xs={6}>
                            <RadioGroup
                                aria-label="sort key"
                                name="sort key"
                                value={sortKey}
                                onChange={handleSort}
                                css={sRadio}
                            >
                                <FormControlLabel
                                    value="date"
                                    control={<Radio />}
                                    label="Data"
                                />
                                <FormControlLabel
                                    value="amount"
                                    control={<Radio />}
                                    label="Kwota"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <RadioGroup
                                aria-label="sort direction"
                                name="sort direction"
                                value={sortDir}
                                onChange={handleSortDir}
                                css={sRadio}
                            >
                                <FormControlLabel
                                    value="DESC"
                                    control={<Radio />}
                                    label="Malejąco"
                                />
                                <FormControlLabel
                                    value="ASC"
                                    control={<Radio />}
                                    label="Rosnąco"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </Collapse>
            </WidgetContainer>

            <WidgetContainer
                isWidgetOpen={raportOpen}
                setIsWidgetOpen={setRaportOpen}
            >
                <Collapse in={raportOpen}>
                    <Headline extraCss={sHeadline}>Raport CSV</Headline>
                    <div css={[sGrid, sGridOverride]}>
                        <p css={[sDescription]}>
                            Wybierz zakres daty z jakich pobrać transakcje, a
                            następnie wygeneruj dokument w formacie .csv
                        </p>
                    </div>
                    <Grid
                        container
                        css={[
                            sGrid,
                            sGridOverride,
                            {
                                justifyContent: 'space-between',
                                paddingTop: '1rem',
                            },
                        ]}
                    >
                        <GenerateCsvInputWrapper container spacing={1}>
                            <Grid item md={5} xs={6}>
                                <Input
                                    darker
                                    type="date"
                                    label="Od"
                                    pattern="\d{1,2}/\d{1,2}/\d{4}"
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                    value={startDate}
                                />
                            </Grid>
                            <Grid item md={5} xs={6}>
                                <Input
                                    darker
                                    type="date"
                                    label="Od"
                                    pattern="\d{1,2}/\d{1,2}/\d{4}"
                                    onChange={(e) => setEndDate(e.target.value)}
                                    value={endDate}
                                />
                            </Grid>
                        </GenerateCsvInputWrapper>
                        <Grid item xs={12}>
                            <div css={{ padding: '0 0 2rem' }}>
                                <Button
                                    w100
                                    w100Mobile
                                    w100Dektop
                                    action={onDownload}
                                    loading={isCsvDataLoading}
                                    loadingSpinner={isCsvDataLoading}
                                >
                                    Generuj
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Collapse>
            </WidgetContainer>
        </React.Fragment>
    );
};

const sRadio = {
    '.MuiRadio-colorSecondary.Mui-checked': {
        color: colors.orange,
    },
    '.MuiTypography-body1': {
        fontWeight: 400,
        letterSpacing: '-0.025em',
        color: colors.text,
    },
};

const GenerateCsvInputWrapper = styled(Grid)`
    justify-content: space-between;
`;

export default PaymentsPage;
