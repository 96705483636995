import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { ReactNode } from 'react';

export interface ButtonIconProps {
    icon: ReactNode;
    className?: string;
    onClick: () => void;
}

export const ButtonIcon = ({ icon, className, onClick }: ButtonIconProps) => {
    return (
        <Button className={className} onClick={onClick}>
            {icon}
        </Button>
    );
};

const Button = styled.button(
    ({}) => css`
        background: none;
        border: none;
        color: ${colors.text};
        cursor: pointer;
        display: flex;
        line-height: 20px;
        outline: none;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: ${colors.gray};
        }
    `,
);
