import React, { useRef } from 'react'; // eslint-disable-line no-unused-vars

import Headline from '../../../../components/Headline/Headline';
import { sOuter, sGrid } from '../../../../style/style';
import { ButtonBorder } from '../../../../components/Elements';
import { Grid } from '@material-ui/core';
import { TopNav } from '../../../../components/TopNavFixed/TopNavFixed';
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder';
import Notification from './Notification';
import { useNotificationsPageLazyQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';

const NotificationsPage = () => {
    return (
        <React.Fragment>
            <TopNav title={'Powiadomienia'} />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder link={`/orders`}>Zamówienia</ButtonBorder>
                    }
                >
                    Powiadomienia
                </Headline>
                <Notifications />
            </div>
        </React.Fragment>
    );
};

const Notifications = () => {
    const containerRef = useRef(null);

    const [getNotifications, { data, loading }] = useNotificationsPageLazyQuery(
        {
            fetchPolicy: 'cache-and-network',
        },
    );

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getNotifications({
                variables: {
                    pagination,
                },

                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.inAppNotificationListGroupedByOrder.metadata,
    });

    return (
        <>
            <Grid
                ref={containerRef}
                container
                css={[sGrid]}
                justifyContent="space-between"
            >
                {data?.inAppNotificationListGroupedByOrder?.data.map(
                    (notification) => (
                        <Notification
                            id={notification.id}
                            key={notification.id}
                            data={notification}
                        />
                    ),
                )}
                {loading && (
                    <Grid container css={sGrid} justifyContent="space-between">
                        <SkeletonOrder />
                        <SkeletonOrder />
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Grid>
                )}
            </Grid>
            {hasMore && (
                <div css={[sGrid, { margin: '1rem 0.75rem 0 0.75rem' }]}>
                    <ButtonBorder action={getMoreData}>
                        Pokaż więcej
                    </ButtonBorder>
                </div>
            )}
            <div
                css={[
                    sGrid,
                    {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '1rem 0 0 0.75rem',
                    },
                ]}
            >
                <p
                    css={{
                        fontSize: '14px',
                        color: '#A3A5B0',
                        marginRight: 10,
                    }}
                >
                    Kliknij przycisk "Ustawienia", aby wybrać preferowany sposób
                    otrzymywania powiadomień.
                </p>
                <ButtonBorder link={'/account/settings'}>
                    Ustawienia
                </ButtonBorder>
            </div>
        </>
    );
};

export default NotificationsPage;
