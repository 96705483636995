import { useState } from 'react';
import {
    OrderItem,
    ProductFragment,
    UserPollingServiceQuery,
} from '@graphql/generated';
import { uniqBy } from 'lodash';

type NotificationData =
    UserPollingServiceQuery['notifications']['data'][number];

export const useRatingPopupHandler = () => {
    const [productList, setProductList] = useState<ProductFragment[]>([]);

    const handleRatingPopup = (notification: NotificationData) => {
        const shouldSkipNotification = (notification: NotificationData) =>
            notification.type !== 'ORDER_MOVED_TO_CLOSED';

        const isAcceptedPayoffRequest = (currentValue: OrderItem) =>
            currentValue.payoffRequest?.status === 'ACCEPTED' &&
            currentValue.payoffRequest?.products.some(
                (item) => item.id === currentValue?.product.id,
            );

        const shouldSkipProduct = (currentValue: OrderItem) =>
            currentValue.status === 'CANCELED' ||
            isAcceptedPayoffRequest(currentValue);

        if (shouldSkipNotification(notification)) {
            return;
        }

        if (notification.order?.status === 'CLOSED') {
            const products = notification.order.items.reduce(
                (acc, currentValue) => {
                    if (shouldSkipProduct(currentValue as OrderItem)) {
                        return acc;
                    }

                    return [...acc, currentValue.product];
                },
                [] as ProductFragment[],
            );

            setProductList((prevState) =>
                uniqBy([...prevState, ...products], 'id'),
            );
        }
    };

    return {
        handleRatingPopup,
        productList,
        setProductList,
    };
};
