import React, { useState } from 'react';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { Grid } from '@material-ui/core';
import { sOuter } from '@style/style';
import { useMyGroupsPage } from './useMyGroupsPage';
import { GroupDeletePopup } from '@/EZC/domains/Account/organisms/GroupDeletePopup/GroupDeletePopup';
import { GroupAccordion } from '@/EZC/domains/Account/organisms/GroupAccordion/GroupAccordion';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useHistory } from 'react-router-dom';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import styled from '@emotion/styled';
import { s } from '@style/index';

export const MyGroupsPage = () => {
    const history = useHistory();
    const [selectedGroup, setSelectedGroup] = useState({
        groupId: '',
        isOwner: false,
    });

    const {
        data,
        deleteLoading,
        getMoreData,
        handleDeleteGroup,
        hasMore,
        loading,
        openPopup,
        setOpenPopup,
        handleSendInvitation,
        userId,
    } = useMyGroupsPage();

    return (
        <>
            <div>
                {/* @ts-expect-error migrate to TS */}
                <TopNav title={'Moje grupy'} />
                <div
                    /* @ts-expect-error migrate to TS */
                    css={sOuter}
                >
                    {/* @ts-expect-error migrate to TS */}
                    <Headline
                        itemChildren={
                            <ButtonBorder
                                onClick={() =>
                                    history.push(`/account/my-groups/new`)
                                }
                            >
                                Dodaj grupę
                            </ButtonBorder>
                        }
                        extraCss={{ flexWrap: 'unset', alignItems: 'center' }}
                    >
                        Moje grupy
                    </Headline>

                    <GroupsWrapper container>
                        <>
                            {data?.list?.data?.map((group) => (
                                <GroupAccordion
                                    key={group?.id}
                                    groupId={group?.id}
                                    userId={userId ?? ''}
                                    users={[
                                        group?.owner,
                                        ...group?.members,
                                    ]?.map((user) => ({
                                        name:
                                            user?.personal?.name +
                                            ' ' +
                                            user?.personal?.surname,
                                        id: user?.id,
                                        avatar: user?.personal?.avatarUrl,
                                        email: user?.email,
                                        isOwner: group?.owner?.id === user?.id,
                                    }))}
                                    name={group?.name}
                                    onClick={() => {
                                        setOpenPopup(true);
                                        setSelectedGroup({
                                            groupId: group?.id,
                                            isOwner:
                                                group?.owner?.id === userId,
                                        });
                                    }}
                                    notFoundUserAction={handleSendInvitation}
                                    isCurrentUserIsOwner={
                                        userId === group?.owner?.id
                                    }
                                    loadingList={loading}
                                />
                            ))}
                            {loading && (
                                <>
                                    <SkeletonOrder />
                                    <SkeletonOrder />
                                    <SkeletonOrder />
                                    <SkeletonOrder />
                                </>
                            )}
                            {!loading && hasMore && (
                                <Grid item xs={12}>
                                    <ButtonBorder
                                        disabled={loading}
                                        loading={loading}
                                        onClick={getMoreData}
                                    >
                                        Pokaż więcej
                                    </ButtonBorder>
                                </Grid>
                            )}
                        </>
                    </GroupsWrapper>
                </div>
            </div>
            {openPopup && (
                <GroupDeletePopup
                    deleteButton={{
                        text: `${
                            selectedGroup.isOwner ? 'Usuń grupę' : 'OPUŚĆ GRUPĘ'
                        }`,
                        action: handleDeleteGroup,
                    }}
                    onRejectButton={setOpenPopup}
                    groupId={selectedGroup.groupId}
                    isOwner={selectedGroup.isOwner}
                    loading={deleteLoading}
                    text={
                        selectedGroup.isOwner
                            ? 'Czy napewno chcesz usunąć grupę?'
                            : 'Czy napewno chcesz opuścić grupę?'
                    }
                    header={
                        selectedGroup.isOwner ? 'Usuń grupę' : 'OPUŚĆ GRUPĘ'
                    }
                />
            )}
        </>
    );
};

const GroupsWrapper = styled(Grid)`
    padding: 0 24px 48px;
    ${s.md} {
        padding: 0 32px 48px;
    }
`;
