import { useWaiterService } from './useWaiterService';
import { useInitializeWaiterQuery } from '@graphql/generated';

export const useInitializeWaiter = () => {
    const { isLoggedIn, sessionToken, setRestaurantId } = useWaiterService(
        (state) => state,
    );

    const { loading } = useInitializeWaiterQuery({
        onCompleted: ({ waiter }) => setRestaurantId(waiter.restaurant),
        skip: !sessionToken,
        context: { waiter: true },
    });

    return { loading, isLoggedIn };
};
