import styled from '@emotion/styled';
import { colors } from '@style/index';
import {
    Checkbox as MuiCheckbox,
    FormControlLabel,
    FormHelperText,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { css } from '@emotion/core';

export interface PrivacyPolicyPopupProps {
    setIsOpen: (value: boolean) => void;
}

export const PrivacyPolicyContent = () => {
    const {
        control,
        formState: { errors, dirtyFields },
    } = useFormContext();
    return (
        <div>
            <Description>
                Zanim przejdziesz do Panelu Restauratora, prosimy o akceptację
                naszej Polityki Prywatności i Regulaminu. Twoja ochrona danych
                jest dla nas priorytetem.
            </Description>
            <CheckboxWrapper>
                <div>
                    <Controller
                        name="privacyPolicy"
                        control={control}
                        render={({ field }) => (
                            <Label
                                control={
                                    <Checkbox
                                        {...field}
                                        color="default"
                                        $isChecked={dirtyFields.privacyPolicy}
                                        $error={!!errors?.privacyPolicy}
                                    />
                                }
                                label={
                                    <p>
                                        Zaznaczając to pole, potwierdzam, że
                                        zapoznałem(am) się i akceptuję{' '}
                                        <CheckboxLink href={'/privacy'}>
                                            Politykę Prywatności.
                                        </CheckboxLink>
                                    </p>
                                }
                            />
                        )}
                    />
                    {errors?.privacyPolicy && (
                        <ErrorMessage error>
                            <ErrorOutlineIcon />{' '}
                            {errors.privacyPolicy.message as string}
                        </ErrorMessage>
                    )}
                </div>
                <div>
                    <Controller
                        name="terms"
                        control={control}
                        render={({ field }) => (
                            <Label
                                control={
                                    <Checkbox
                                        {...field}
                                        color="default"
                                        $isChecked={dirtyFields.terms}
                                        $error={!!errors.terms}
                                    />
                                }
                                label={
                                    <p>
                                        Zaznaczając to pole, potwierdzam, że
                                        zapoznałem(am) się i akceptuję{' '}
                                        <CheckboxLink
                                            href={'/terms-restaurateur'}
                                        >
                                            Regulamin.
                                        </CheckboxLink>
                                    </p>
                                }
                            />
                        )}
                    />
                    {errors?.terms && (
                        <ErrorMessage error>
                            <ErrorOutlineIcon />{' '}
                            {errors?.terms.message as string}
                        </ErrorMessage>
                    )}
                </div>
            </CheckboxWrapper>
        </div>
    );
};

const CheckboxWrapper = styled.div`
    display: grid;
    grid-gap: 24px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 24px;
`;

const CheckboxLink = styled.a`
    font-weight: 700;
`;

const ErrorMessage = styled(FormHelperText)`
    align-items: center;
    display: flex;
    gap: 8px;
`;

const Label = styled(FormControlLabel)`
    .MuiTypography-body1 {
        font-size: 15px;
    }
    .MuiCheckbox-root {
        padding: 3px 16px 16px 9px;
    }
    .MuiIconButton-root:hover {
        background-color: transparent;
    }
    align-items: start;
`;

const Checkbox = styled(MuiCheckbox)<{
    $error?: boolean;
    $isChecked?: boolean;
}>(
    ({ $error, $isChecked }) => css`
        position: relative;

        .MuiSvgIcon-root {
            fill: ${$error
                ? colors.red
                : $isChecked
                  ? colors.orange
                  : colors.gray};

            height: 18px;
            width: 18px;
        }

        ::before {
            content: '*';
            font-size: 12px;
            position: absolute;
            right: 8px;
            width: 2px;
            top: 1px;
        }
    `,
);
