import React from 'react'; // eslint-disable-line no-unused-vars

import ActiveOrderItem from '../../orders/components/ActiveOrderItem';
import { ButtonBorder, SectionHeadline } from '../../../../components/Elements';
import { sDescription } from '../../../../style/style';
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useOrdersHistoryFinishedAndDeclinedLazyQuery } from '@graphql/generated';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { Grid } from '@material-ui/core';
import { RestaurantAvatar } from '@/svgs/RestaurantAvatar';

export const OrdersHistoryFinished = () => {
    const [getOrdersHistory, { data, loading }] =
        useOrdersHistoryFinishedAndDeclinedLazyQuery();
    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getOrdersHistory({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters: {
                            statuses: ['CLOSED'],
                        },
                    },
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.orders.metadata,
    });

    const orders = data?.orders.data;

    if (!loading && !orders?.length)
        return (
            <div css={[sDescription]}>
                Brak zrealizowanych zamówień. Wyszukaj to, na co masz ochotę i
                rozpocznij pierwsze zamówienie!
            </div>
        );

    return (
        <>
            {orders?.map((order, index) => (
                <ActiveOrderItem
                    isOrderHistory
                    id={index}
                    key={order?.id}
                    orderId={order?.id}
                    date={
                        order?.statusLog.find(
                            (item) => item.status === 'CLOSED',
                        ).date
                    }
                    consumptionType={order?.details.type}
                    restaurantAvatar={
                        order.restaurant.avatarUrl ? (
                            <img
                                src={order?.restaurant.avatarUrl}
                                alt={order?.restaurant.name}
                                loading="lazy"
                            />
                        ) : (
                            <RestaurantAvatar />
                        )
                    }
                    restaurantName={order.restaurant.name}
                    priceSum={order.priceSum}
                    isPaid={order.isPaid}
                    status={order.status}
                />
            ))}
            {loading && (
                <>
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <SkeletonOrder />
                </>
            )}
            {hasMore && (
                <Grid item xs={12}>
                    <ButtonBorder action={getMoreData} disable={loading}>
                        Pokaż więcej
                    </ButtonBorder>
                </Grid>
            )}
        </>
    );
};

export const OrdersHistoryDeclined = () => {
    const [getOrdersHistory, { data, loading }] =
        useOrdersHistoryFinishedAndDeclinedLazyQuery();
    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getOrdersHistory({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters: {
                            statuses: ['REJECTED', 'CANCELED'],
                        },
                    },
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.orders.metadata,
    });

    const orders = data?.orders.data;

    if (!loading && !orders?.length) return null;

    return (
        <>
            <div css={{ paddingBottom: '1rem', width: '100%' }}>
                <SectionHeadline>Anulowane i odrzucone</SectionHeadline>
            </div>
            {orders?.map((order, index) => (
                <ActiveOrderItem
                    isOrderHistory
                    id={index}
                    key={order.id}
                    orderId={order.id}
                    date={order?.statusLog[order?.statusLog?.length - 1].date}
                    consumptionType={order.details.type}
                    restaurantAvatar={
                        order.restaurant.avatarUrl ? (
                            <img
                                src={order.restaurant.avatarUrl}
                                alt={order.restaurant.name}
                                loading="lazy"
                            />
                        ) : (
                            <RestaurantAvatar />
                        )
                    }
                    restaurantName={order.restaurant.name}
                    priceSum={order.priceSum}
                    isPaid={order.isPaid}
                    status={order.status}
                />
            ))}
            {loading && (
                <>
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <SkeletonOrder />
                </>
            )}
            {hasMore && (
                <Grid item xs={12}>
                    <ButtonBorder action={getMoreData} disable={loading}>
                        Pokaż więcej
                    </ButtonBorder>
                </Grid>
            )}
        </>
    );
};
