import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { s } from '@style/index';
import Headline from '@/components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { sGrid } from '@style/style';
import { mediaQueriesMaterial } from '@style/breakpoints';
import { globals } from '@style/config/globals';
import { PaymentMethod } from '@/componentsV2/molecules/PaymentMethod/PaymentMethod';
import { SelectTable } from '@/componentsV2/molecules/SelectTable/SelectTable';
import { SelectDate } from '@/componentsV2/molecules/SelectDate/SelectDate';
import OrderPaymentLarge from '@/EZC/pages/orders/components/OrderPaymentLarge';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { popup } from '@/AppPopups';
import {
    useWaiterOrderCreateMutation,
    useWaiterCartCreateMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useOrderCheckout } from './useOrderCheckout';
import { CustomerTypeChoose } from '@/EZW/domains/Orders/organisms/CustomerTypeChoose/CustomerTypeChoose';
import { CustomerProps } from '@/EZW/domains/pages/NewOrderPage/NewOrderPage';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useHistory } from 'react-router-dom';
import PageLoader from '@components/PageLoader/PageLoader';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { IOS } from '@constants/common.constants';

export const OrderCheckoutPage = () => {
    const history = useHistory();

    const { createNewOrderData, setCreateNewOrderData, setOrderIdFromWaiter } =
        useWaiterService();
    const [customer, setCustomer] = useState<CustomerProps | undefined>(
        createNewOrderData.customer,
    );
    const [isEatzonUser, setIsEatzonUser] = useState(
        createNewOrderData.isEatzonClient,
    );
    const [paymentMethod, setPaymentMethod] = useState('in');

    const [createOrder, { loading: createOrderLoading }] =
        useWaiterOrderCreateMutation({
            onError: graphqlErrorHandler(true),
        });

    const [createCart, { loading: createCartLoading }] =
        useWaiterCartCreateMutation({
            onError: graphqlErrorHandler(true),
        });

    const { bottomNavigation } = useSafeAreaInsets();

    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    const {
        cartId,
        consumptionDetails,
        handleDownloadPdf,
        onChangeDate,
        onChangeTable,
        price,
        table,
        loading,
    } = useOrderCheckout();

    useEffect(() => {
        !cartId && history.push('/waiter');
    }, [cartId]);

    useEffect(() => {
        !isEatzonUser && setPaymentMethod('out');
    }, [isEatzonUser]);

    const onSendOrder = () => {
        if (createNewOrderData.isEatzonClient) {
            createCart({
                context: { waiter: true },
                variables: {
                    input: {
                        cartId,
                        approverId: createNewOrderData.customer.id || '',
                        paymentType:
                            paymentMethod === 'in' ? 'EATCOIN' : 'CURRENCY',
                    },
                },
                onCompleted: () => {
                    history.push(`/waiter`);
                },
                onError: graphqlErrorHandler(true),
            });
        } else {
            createOrder({
                context: { waiter: true },
                variables: {
                    input: {
                        cartId,
                        paymentType:
                            paymentMethod === 'in' ? 'EATCOIN' : 'CURRENCY',

                        isEatzonClient: false,
                        waiterNote: createNewOrderData.customer.name,
                    },
                },
                onCompleted: (data) => {
                    setOrderIdFromWaiter(data.order.id);
                    setCreateNewOrderData({ name: '' }, true);
                    history.push(`/waiter/order/${data.order.id}`);
                },
                onError: graphqlErrorHandler(true),
            });
        }
    };

    if (loading) {
        return <PageLoader />;
    }
    return (
        <>
            {/* @ts-expect-error migrate to TS */}
            <TopNav
                title={'Podsumowanie'}
                subtitle={'- Emma Stone'}
                photo={undefined}
                textCss={{
                    [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
                }}
            />
            <HeadlineWrapper>
                {/* @ts-expect-error migrate to TS */}
                <Headline textWrap={false}>Podsumowanie</Headline>
            </HeadlineWrapper>
            <ContentWrapper>
                <CustomerInfoWrapper>
                    <CustomerTypeChoose
                        setCustomer={setCustomer}
                        customer={customer}
                        isEatzonUser={isEatzonUser}
                        setIsEatzonUser={setIsEatzonUser}
                    />
                </CustomerInfoWrapper>
                <Wrapper>
                    <Grid container css={[sGrid]} className="gridWrapper">
                        <Grid item xs={12} md={6}>
                            <SelectTable
                                onChangeTable={onChangeTable}
                                onDisplayRoomLayout={handleDownloadPdf}
                                displayRoomLayoutDescription={
                                    'Kliknij , aby zobaczyć układ sali restauracji'
                                }
                                changeTableNumberDescription={
                                    'Wpisz numer stolika'
                                }
                                tableNumber={table ?? ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectDate
                                consumptionDetails={consumptionDetails}
                                onChange={onChangeDate}
                            />
                        </Grid>
                        <PaymentMethod
                            paymentMethod={paymentMethod}
                            setPaymentMethod={(paymentMethod) =>
                                setPaymentMethod(paymentMethod)
                            }
                            inPaymentDisabled={!isEatzonUser}
                        />
                        {/* @ts-expect-error migrate to ts */}
                        <OrderPaymentLarge
                            priceSum={price}
                            paidSum={price}
                            deliveryCost={0}
                            isDelivery={consumptionDetails.type === 'DELIVERY'}
                        />
                    </Grid>
                </Wrapper>
            </ContentWrapper>
            <ButtonsWrapper
                css={[
                    { bottom: bottomNavigation },
                    {
                        ...(IOS &&
                            keyboardHeight && {
                                position: 'relative',
                                bottom: 0,
                            }),
                    },
                ]}
            >
                <Button
                    variant={'gray'}
                    width={'100%'}
                    onClick={() => popup.show('CancelOrderPopup')}
                    loading={createOrderLoading || createCartLoading}
                >
                    Anuluj
                </Button>
                <Button
                    variant={'orange'}
                    width={'100%'}
                    onClick={onSendOrder}
                    loading={createOrderLoading || createCartLoading}
                    disabled={!createNewOrderData.customer.name}
                >
                    Stwórz Zamówienie
                </Button>
            </ButtonsWrapper>
        </>
    );
};

const Wrapper = styled.div`
    padding: 32px 32px 100px;
    ${mediaQueriesMaterial.sm} {
        padding: 32px 24px 100px;
    }
    .gridWrapper {
        padding: 0;
    }
    .gridWrapper > .MuiGrid-item:nth-child(odd) {
        padding: 12px 12px 12px 0;
        ${mediaQueriesMaterial.sm} {
            padding: 6px 0;
        }
    }
    .gridWrapper > .MuiGrid-item:nth-child(even) {
        padding: 12px 0 12px 12px;
        ${mediaQueriesMaterial.sm} {
            padding: 6px 0;
        }
    }
`;
const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: fixed;
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 100;
    padding: 0 32px;
    max-width: ${globals.maxWidthMedium}px;
    ${mediaQueriesMaterial.sm} {
        padding: 0 24px;
    }
`;

const CustomerInfoWrapper = styled.div`
    padding: 0 32px;
    ${mediaQueriesMaterial.sm} {
        padding: 0 24px 60px;
    }
`;

const HeadlineWrapper = styled.div`
    position: relative;
    width: 100vw;
    max-width: ${globals.maxWidthMedium}px;
    margin: 0 auto;

    margin-top: ${globals.topnavHeight.desktop + 64}px;

    ${mediaQueriesMaterial.sm} {
        margin-top: ${globals.topnavHeight.mobile}px;
    }
`;

const ContentWrapper = styled(HeadlineWrapper)`
    margin-top: 0px;

    ${mediaQueriesMaterial.sm} {
        margin-top: 0px;
    }
`;
