/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { StatusLarge, Badge } from '../../../../components/Elements';
import { colors, s } from '../../../../style';
import { Grid } from '@material-ui/core';
import {
    sImage,
    sText,
} from '../../../../components/Elements/Collapsable/Collapsable';
import {
    sContainerInner,
    sTopContainer,
} from '../../orders/components/ActiveOrderItem';
import eatzonIcon from '../../../../style/assets/eatzonIcon.svg';
import { TRANSACTION_TYPES } from './Transaction.helpers';
import styled from '@emotion/styled';

const Transaction = ({ id, data }) => {
    const { type, amount, restaurant, createdAt, order, recipientId } = data;

    return (
        <Grid
            item
            xs={12}
            md={6}
            css={{
                animation: `fadeIn 0.3s ease-in-out`,
            }}
        >
            <div
                css={[
                    id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
                    id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
                    {
                        display: 'flex',
                        marginBottom: '1.5rem',
                        [s.sm_down]: { marginBottom: '1rem' },
                    },
                ]}
            >
                <div css={[sContainerInner]}>
                    <div css={sTopContainer}>
                        <Wrapper>
                            <div css={[sImage, { position: 'relative' }]}>
                                <img
                                    src={
                                        type === 'DEPOSIT'
                                            ? eatzonIcon
                                            : restaurant?.avatarUrl
                                    }
                                    alt={
                                        type === 'DEPOSIT'
                                            ? 'Eatzon'
                                            : restaurant?.name
                                    }
                                    loading="lazy"
                                />

                                <Badge
                                    small
                                    left
                                    color={colors.orange}
                                    extraCss={{
                                        boxShadow: `0px 0px 0px 3px ${colors.gray200}`,
                                    }}
                                    show={type === 'BLOCKED'}
                                    showAnimation
                                />
                            </div>
                            <div css={sText}>
                                <div
                                    css={[
                                        sText.title,
                                        {
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            display: 'flex',
                                        },
                                    ]}
                                >
                                    {type === 'DEPOSIT'
                                        ? 'EatCoin'
                                        : restaurant?.name}
                                    <StatusLarge
                                        color={
                                            TRANSACTION_TYPES[type]
                                                .paymentBadgeColor
                                        }
                                    >
                                        {![
                                            'DEPOSIT',
                                            'ORDER_PAYMENT_REQUEST_PAID_ORDER_OWNER',
                                            'PAYOFF_REQUEST_PAID_RECIPIENT',
                                            'UN_BLOCKED_PARTIAL',
                                        ].includes(type)
                                            ? '-'
                                            : '+'}{' '}
                                        {amount.toFixed(2)} zł
                                    </StatusLarge>
                                </div>
                                <p
                                    css={[
                                        sText.subtitle,
                                        {
                                            display: 'grid',
                                        },
                                    ]}
                                >
                                    {TRANSACTION_TYPES[type].paymentInfo({
                                        date: createdAt,
                                        orderId: order?.shortId,
                                        recipient: recipientId?.personal,
                                    })}
                                </p>
                            </div>
                        </Wrapper>
                    </div>
                </div>
            </div>
        </Grid>
    );
};

const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

export default Transaction;
