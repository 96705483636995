import React from 'react'; // eslint-disable-line no-unused-vars

import { Collapse } from '@material-ui/core';
import Headline from '../Headline/Headline';
import WidgetContainer from '../Widget/Widget.component';
import { sGrid, sDescription } from '../../style/style';
import { sGridOverride, sHeadline } from '../../EZW/pages/Popups/styles';
import StatusLarge from '../Elements/StatusLarge/StatusLarge';

import { SectionHeadline } from '../Elements';
import {
    ORDER_STATUS_ENUM,
    ORDER_STATUS,
} from '@constants/fromGraphql/OrderStatus.constants';

const ORDER_STATUS_GUIDE = [
    ORDER_STATUS[ORDER_STATUS_ENUM.PENDING],
    ORDER_STATUS[ORDER_STATUS_ENUM.ACCEPTED],
    ORDER_STATUS[ORDER_STATUS_ENUM.PREPARING],
    ORDER_STATUS[ORDER_STATUS_ENUM.PREPARED],
    ORDER_STATUS[ORDER_STATUS_ENUM.COMPLETED],
    ORDER_STATUS[ORDER_STATUS_ENUM.DELIVERY],
    ORDER_STATUS[ORDER_STATUS_ENUM.COMPLETED].delivery,
    ORDER_STATUS[ORDER_STATUS_ENUM.CLOSED],
    ORDER_STATUS[ORDER_STATUS_ENUM.REJECTED],
];

const StatusGuide = ({ open, setOpen }) => {
    return (
        <React.Fragment>
            <WidgetContainer isWidgetOpen={open} setIsWidgetOpen={setOpen}>
                <Collapse in={open}>
                    <div>
                        <Headline extraCss={sHeadline}>Statusy</Headline>
                        <div css={[sGrid, sGridOverride]}>
                            <p css={[sDescription, { fontSize: '13px' }]}>
                                Sprawdź co oznaczają poszczególne statusy
                                zamówień i poznaj jak działa system Eatzon
                            </p>
                        </div>
                        <div css={[sGrid, sGridOverride]}>
                            <SectionHeadline
                                noPaddingLeft
                                noPaddingTop
                                sOuter={{ margin: '1rem 0' }}
                            >
                                Zamówienia
                            </SectionHeadline>
                            {ORDER_STATUS_GUIDE.map((status, index) => (
                                <div key={index}>
                                    <div>
                                        <StatusLarge color={status?.color}>
                                            {status?.name}
                                        </StatusLarge>
                                    </div>
                                    <p css={[sDescription, sDescription2]}>
                                        {status?.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </Collapse>
            </WidgetContainer>
        </React.Fragment>
    );
};

const sDescription2 = { fontSize: '11px', margin: '1rem 0 1rem' };

export default StatusGuide;
