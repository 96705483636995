export const Fastfood = () => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity="0.4" clipPath="url(#clip0_962_17135)">
                <path
                    d="M30.6 38.3165H33.3666C34.7666 38.3165 35.9166 37.2498 36.0833 35.8832L38.8333 8.4165H30.5V1.6665H27.2166V8.4165H18.9333L19.4333 12.3165C22.2833 13.0998 24.95 14.5165 26.55 16.0832C28.95 18.4498 30.6 20.8998 30.6 24.8998V38.3165ZM2.16663 36.6498V34.9998H27.2166V36.6498C27.2166 37.5665 26.4666 38.3165 25.5333 38.3165H3.84996C2.91663 38.3165 2.16663 37.5665 2.16663 36.6498ZM27.2166 24.9832C27.2166 11.6498 2.16663 11.6498 2.16663 24.9832H27.2166ZM2.19996 28.3332H27.2V31.6665H2.19996V28.3332Z"
                    fill="url(#paint0_linear_962_17135)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_962_17135"
                    x1="20.5"
                    y1="1.6665"
                    x2="20.5"
                    y2="38.3165"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#F3A93C" />
                    <stop offset="1" stop-color="#EC5128" />
                </linearGradient>
                <clipPath id="clip0_962_17135">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
