import React from 'react';
import { BasePopup } from '../BasePopup/BasePopup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { ButtonBase, useMediaQuery, useTheme } from '@material-ui/core';
import { colors } from '@style/index';
import { EatzonPopupLogo } from '@/svgs/EatzonPopupLogo';
import { Fastfood } from '@/svgs/Fastfood';
import { PopupSection } from '@/componentsV2/molecules/PopupSection/PopupSection';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';
import { useAuthService } from '@/services/AuthService';
import { useHistory } from 'react-router-dom';
import { usePopup } from '@/AppPopups';
import CloseIcon from '@material-ui/icons/Close';
import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';

export const JoinToEatzonPopup = NiceModal.create(() => {
    const setJoinToEatzon = useAuthService((state) => state.setJoinToEatzon);
    const popup = usePopup();
    const history = useHistory();

    const handleClickPopup = (path?: string) => {
        path && history.push(path);
        popup.remove();
        setJoinToEatzon(false);
    };

    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <BasePopup
            containerPadding={isLarge ? '78px' : '32px 24px 12px'}
            header={
                <HeaderWrapper>
                    <CloseButton
                        fontSize={isLarge ? 'large' : 'medium'}
                        onClick={() => handleClickPopup()}
                        style={{
                            right: isLarge ? '-40px' : 0,
                            top: isLarge ? '-40px' : '-10px',
                        }}
                    />
                    <PopupHeader>
                        <PopupTitle>Dołącz do</PopupTitle>
                        <EatzonPopupLogo />
                    </PopupHeader>
                </HeaderWrapper>
            }
        >
            <PopupContent>
                <PopupSection
                    icon={<Fastfood />}
                    description={
                        <>
                            <span>
                                Zarejestruj się jako użytkownik, dodaj swoje
                                preferencje żywieniowe i buduj razem z nami
                                społeczność! Dzięki Eatzon już zawsze będziesz
                                mógł znaleźć to na co masz ochotę.
                            </span>
                        </>
                    }
                    button={{
                        text: 'ZAREJESTRUJ SIĘ',
                        action: () => {
                            handleClickPopup('/signup');
                        },
                    }}
                />
                <div onClick={() => handleClickPopup()}>
                    <ButtonBaseElement>Zarejestruj później</ButtonBaseElement>
                </div>
                <Separator />
                <Button
                    onClick={() => {
                        handleClickPopup('/login');
                    }}
                    variant="black"
                    width="100%"
                >
                    ZALOGUJ SIĘ
                </Button>
            </PopupContent>
        </BasePopup>
    );
});

const HeaderWrapper = styled.div`
    position: relative;
`;

const CloseButton = styled(CloseIcon)`
    position: absolute;
`;

const PopupContent = styled.div`
    display: grid;
    grid-gap: 24px;
    max-width: 750px;
    margin: 0 auto;
`;

const ButtonBaseElement = styled(ButtonBase)`
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    width: 100%;
`;

const PopupTitle = styled.h1`
    color: ${colors.text};
    font-size: 30px;
    line-height: 45px;
    margin-right: 6px;
`;

const PopupHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 16px;
`;
