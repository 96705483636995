import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField';
import { useSendNotification } from './useSendNotification';
import { Button } from '@/EZAdmin/components';
import styled from '@emotion/styled';

export const EzAdminSendNotification = () => {
    const { handleOnSubmit, loading, titleControl, bodyControl } =
        useSendNotification();

    return (
        <Wrapper>
            <Title>Powiadomienie</Title>
            <TextFieldElement
                {...titleControl.field}
                label={'Tytuł'}
                error={!!titleControl.fieldState.error}
                helperText={titleControl.fieldState.error?.message}
            />
            <TextAreaElement
                {...bodyControl.field}
                placeholder={'Podaj tekst powiadomienia'}
                multiline
                rows={7}
                error={!!bodyControl.fieldState.error}
                helperText={bodyControl.fieldState.error?.message}
            />

            <ButtonWrapper>
                <Button
                    onClick={handleOnSubmit}
                    color="primary"
                    variant="contained"
                    disabled={
                        loading ||
                        titleControl.fieldState.error ||
                        bodyControl.fieldState.error
                    }
                >
                    Wyślij powiadomienie
                </Button>
            </ButtonWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    width: 100%;
    gap: 30px;
    padding-top: 50px;
    max-width: 640px;
`;

const Title = styled.h3`
    size: 24px;
    font-weight: 400;
    line-height: 36px;
`;

const TextFieldElement = styled(TextField)`
    max-width: 400px;
`;

const TextAreaElement = styled(TextField)`
    padding: 8px;
    border: 1px solid black;
    margin-bottom: 10px;
    border-radius: 4px;

    .MuiInput-underline::before {
        display: none;
    }
    .MuiInput-underline::after {
        display: none;
    }

    .MuiFormHelperText-root {
        transform: translateY(30px);
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
