import * as yup from 'yup';
import { notEmpty } from './notEmpty';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

yup.addMethod(yup.array, 'unique', function (message: string, key: string) {
    return this.test('unique', message, (value, context) => {
        const values = value?.map((item) => item?.[key]);

        const errors =
            value
                ?.map((element, index) => {
                    const isDuplicated =
                        values &&
                        values.filter((item) => item === element?.[key])
                            .length > 1;

                    if (!isDuplicated || !element?.[key]) return null;

                    return new yup.ValidationError(
                        message,
                        element[key],
                        `${context.path}.${index}.${key}`,
                        'DUPLICATE',
                    );
                })
                .filter(notEmpty) ?? [];

        return errors?.length ? new yup.ValidationError(errors) : true;
    });
});

yup.addMethod(yup.string, 'iban', function (message: string) {
    this.withMutation(() => {
        this.transform((value: string | undefined) => {
            if (!this.isType(value)) return value;

            return electronicFormatIBAN(value);
        });
    });

    return this.test('iban', message, (value) => {
        if (!value) return true;
        if (!this.isType(value)) return value;

        return isValidIBAN(value);
    });
});
