import React from 'react';
import { DeepPartial } from '@/types/definitions/common';
import { InAppNotification, InAppNotificationsType } from '@graphql/generated';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import AcceptIcon from '@material-ui/icons/CheckCircle';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { IN_APP_NOTIFICATION_USER } from '@constants/fromGraphql/InAppNotificationsType.constants';
import { useAuthService } from '@/services/AuthService';

interface NotificationInfo {
    title: string;
    message: string;
    redirectUrl: string;
    icon: React.ReactNode;
}

export const getUserInAppNotification = (
    notification: DeepPartial<InAppNotification>,
    type: InAppNotificationsType,
): NotificationInfo => {
    const fromUser = notification?.payoff?.requester;
    const toUser = notification?.payoff?.recipient;
    const productName = notification?.payoff?.products?.[0]?.name || '';
    const productPrice = notification?.payoff?.products?.[0]?.price || 0;

    switch (type) {
        case 'ORDER_CREATED':
            return {
                title: IN_APP_NOTIFICATION_USER['ORDER_CREATED']?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_CREATED']?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <AddCircleIcon fontSize="inherit" />,
            };

        case 'ORDER_CANCELED':
            return {
                title: IN_APP_NOTIFICATION_USER['ORDER_CANCELED']?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_CANCELED']?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <CancelIcon fontSize="inherit" />,
            };

        case 'ORDER_ACCEPTED':
            return {
                title: IN_APP_NOTIFICATION_USER['ORDER_ACCEPTED']?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_ACCEPTED']?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <AcceptIcon fontSize="inherit" />,
            };

        case 'ORDER_MOVED_TO_READY':
            let message = '';

            switch (notification?.order?.details?.type) {
                case 'IN':
                    message =
                        IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_READY'].in;
                    break;
                case 'DELIVERY':
                    message =
                        IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_READY']
                            .delivery;
                    break;
                case 'TAKEAWAY':
                    message =
                        IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_READY']
                            .takeaway;
                    break;
                default:
                    break;
            }
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_READY']?.title ||
                    '',
                message: message,
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <FastfoodIcon fontSize="inherit" />,
            };

        case 'ORDER_MOVED_TO_CLOSED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_CLOSED']?.title ||
                    '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_CLOSED']?.common ||
                    '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <AssignmentTurnedInIcon fontSize="inherit" />,
            };

        case 'ORDER_REJECTED':
            return {
                title: IN_APP_NOTIFICATION_USER['ORDER_REJECTED']?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_REJECTED']?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <CancelIcon fontSize="inherit" />,
            };

        case 'NEW_ORDER_PAYMENT_REQUEST':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['NEW_ORDER_PAYMENT_REQUEST']
                        ?.title || '',
                message: IN_APP_NOTIFICATION_USER[
                    'NEW_ORDER_PAYMENT_REQUEST'
                ]?.custom({
                    name: fromUser?.personal?.name ?? '',
                    price: productPrice,
                    productName,
                    surname: fromUser?.personal?.surname ?? '',
                }),
                redirectUrl: `/account/payment-requests`,
                icon: null,
            };

        case 'ORDER_PAYMENT_REQUEST_ACCEPTED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_PAYMENT_REQUEST_ACCEPTED']
                        ?.title || '',
                message: IN_APP_NOTIFICATION_USER[
                    'ORDER_PAYMENT_REQUEST_ACCEPTED'
                ]?.custom({
                    name: toUser?.personal?.name ?? '',
                    productName,
                    surname: toUser?.personal?.surname ?? '',
                }),
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: '',
            };

        case 'ORDER_PAYMENT_REQUEST_REJECTED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_PAYMENT_REQUEST_REJECTED']
                        ?.title || '',
                message: IN_APP_NOTIFICATION_USER[
                    'ORDER_PAYMENT_REQUEST_REJECTED'
                ]?.custom({
                    name: toUser?.personal?.name ?? '',
                    productName,
                    surname: toUser?.personal?.surname ?? '',
                }),
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: null,
            };

        case 'ORDER_PRODUCT_ADDED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_PRODUCT_ADDED']?.title ||
                    '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_PRODUCT_ADDED']?.common ||
                    '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <UpdateIcon fontSize="inherit" />,
            };

        case 'ORDER_ADDITION':
            return {
                title: IN_APP_NOTIFICATION_USER['ORDER_ADDITION']?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_ADDITION']?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <UpdateIcon fontSize="inherit" />,
            };

        case 'ORDER_TABLE_CHANGED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_TABLE_CHANGED']?.title ||
                    '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_TABLE_CHANGED']?.custom({
                        orderId: notification?.order?.id ?? '',
                    }) || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <UpdateIcon fontSize="inherit" />,
            };

        case 'ORDER_PARTIAL_ACCEPTANCE':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_PARTIAL_ACCEPTANCE']
                        ?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_PARTIAL_ACCEPTANCE']
                        ?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <UpdateIcon fontSize="inherit" />,
            };

        case 'PAYOFF_REQUESTED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['PAYOFF_REQUESTED']?.title || '',
                message: IN_APP_NOTIFICATION_USER['PAYOFF_REQUESTED']?.custom({
                    name: fromUser?.personal?.name ?? '',
                    productName: productName,
                    surname: fromUser?.personal?.surname ?? '',
                    price: productPrice,
                }),
                redirectUrl: `/account/payment-requests`,
                icon: '',
            };

        case 'PAYOFF_ACCEPTED':
            return {
                title: IN_APP_NOTIFICATION_USER['PAYOFF_ACCEPTED']?.title || '',
                message: IN_APP_NOTIFICATION_USER['PAYOFF_ACCEPTED']?.custom({
                    name: toUser?.personal?.name ?? '',
                    productName,
                    surname: toUser?.personal?.surname ?? '',
                }),
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: '',
            };

        case 'PAYOFF_REJECTED':
            return {
                title: IN_APP_NOTIFICATION_USER['PAYOFF_REJECTED']?.title || '',
                message: IN_APP_NOTIFICATION_USER['PAYOFF_REJECTED']?.custom({
                    name: toUser?.personal?.name ?? '',
                    productName,
                    surname: toUser?.personal?.surname ?? '',
                }),
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: null,
            };

        case 'ORDER_ADDITION_FROM_WAITER':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_ADDITION_FROM_WAITER']
                        ?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_ADDITION_FROM_WAITER']
                        ?.common || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: <UpdateIcon fontSize="inherit" />,
            };
        case 'GROUP_ADDED_TO_GROUP':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['GROUP_ADDED_TO_GROUP']?.title ||
                    '',
                message: IN_APP_NOTIFICATION_USER[
                    'GROUP_ADDED_TO_GROUP'
                ].custom({ groupName: notification.group?.name || '' }),
                redirectUrl: `/account/my-groups`,
                icon: null,
            };

        case 'ORDER_ETA_CHANGED':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_ETA_CHANGED']?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_ETA_CHANGED'].custom({
                        orderId: notification?.order?.shortId ?? '',
                    }) || '',
                redirectUrl: `/order/${notification?.order?.id}`,
                icon: null,
            };

        case 'CART_APPROVAL_REQUEST':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['CART_APPROVAL_REQUEST']?.title ||
                    '',
                message:
                    IN_APP_NOTIFICATION_USER['CART_APPROVAL_REQUEST']?.common ||
                    '',
                redirectUrl: `/cart-checkout/${notification.cart?.id}`,
                icon: null,
            };

        case 'NEW_CHAT_MESSAGE':
            const isAdminChat = !notification.order;
            const redirectUrl = !isAdminChat
                ? `/orders-chat/${notification.order?.id}`
                : useAuthService.getState().user?.userType === 'Admin'
                  ? '/admin/chat'
                  : useAuthService.getState().user?.userType === 'Support'
                    ? '/support/chat'
                    : '/account/ez-chat';
            return {
                title:
                    IN_APP_NOTIFICATION_USER['NEW_CHAT_MESSAGE']?.title || '',
                message: isAdminChat
                    ? IN_APP_NOTIFICATION_USER['NEW_CHAT_MESSAGE'].common || ''
                    : IN_APP_NOTIFICATION_USER['NEW_CHAT_MESSAGE'].custom({
                          orderId: notification?.order?.shortId || '',
                      }),
                redirectUrl,
                icon: null,
            };

        case 'ORDER_MOVED_TO_DELIVERY':
            return {
                title:
                    IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_DELIVERY']
                        ?.title || '',
                message:
                    IN_APP_NOTIFICATION_USER['ORDER_MOVED_TO_DELIVERY']
                        ?.common || '',
                redirectUrl: `/order/${notification.order?.id}`,
                icon: null,
            };

        default:
            return {
                title: '',
                message: '',
                redirectUrl: '',
                icon: null,
            };
    }
};
