import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useIngredientPreferencesListQuery } from '@graphql/generated';
import { useState } from 'react';

export const usePreferencesIngredients = () => {
    const [allergiesInput, setAllergiesInput] = useState('');
    const [ingredientsInput, setIngredientsInput] = useState('');

    const ingredientsResult = useIngredientPreferencesListQuery({
        onError: graphqlErrorHandler(true),
        variables: {
            input: {
                payload: {
                    name: ingredientsInput,
                },
            },
        },
    });
    const allergiesResult = useIngredientPreferencesListQuery({
        onError: graphqlErrorHandler(true),
        variables: {
            input: {
                payload: {
                    name: allergiesInput,
                },
            },
        },
    });

    return {
        allergies: {
            ...allergiesResult,
            allergiesInput,
            setAllergiesInput,
        },
        ingredients: {
            ...ingredientsResult,
            ingredientsInput,
            setIngredientsInput,
        },
    };
};
