import { Button } from '@/componentsV2/molecules/Button/Button';
import { EmptyResultsIcon } from '@/svgs/EmptyResultsIcon';
import styled from '@emotion/styled';
import { colors } from '@style/index';

interface SearchEmptyResultsProps {
    onClick: () => void;
    isDesktop: boolean;
}

export const SearchEmptyResults = ({
    onClick,
    isDesktop,
}: SearchEmptyResultsProps) => {
    return (
        <Wrapper>
            {isDesktop && <EmptyResultsIcon />}
            <Text>Nie znaleziono wyników</Text>
            <Description>Spróbuj usunąć kilka filtrów powyżej</Description>
            {!isDesktop && (
                <ActionButton variant="green" onClick={onClick}>
                    Edytuj filtry
                </ActionButton>
            )}
        </Wrapper>
    );
};

const Text = styled.h2`
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
`;

const Description = styled.p`
    font-size: 12px;
    line-height: 18px;
    color: ${colors.lightGray};
`;

const Wrapper = styled.div`
    display: grid;
    justify-items: center;
    gap: 4px;
    padding: 24px 0;
`;

const ActionButton = styled(Button)`
    border-radius: 16px;
    padding: 10px 24px;
    margin-top: 8px;
    line-height: 21px;
    font-weight: 600;
    text-transform: unset;
`;
