import { useServerConfigStoreShallow } from './ServerConfigStore';
import { useServerConfigInitializeQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '../GraphqlErrorService';

export const useServerConfigInitialize = () => {
    const { initialize } = useServerConfigStoreShallow(['initialize']);

    useServerConfigInitializeQuery({
        onCompleted: ({ serverConfig }) => {
            initialize({
                googleSsoStatus: serverConfig.googleSsoStatus ?? undefined,
                localizedBanner: serverConfig.localizedBanner ?? undefined,
                restaurantsForLocalizedHomepage:
                    serverConfig.restaurantsForLocalizedHomepage ?? undefined,
            });
        },
        onError: graphqlErrorHandler(false),
    });
};
