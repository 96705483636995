import { App, URLOpenListenerEvent } from '@capacitor/app';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useDeeplinkListener = () => {
    const history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            if (event.url.includes('/user-confirm-email/')) {
                const substringFromUserConfirmEmail = event.url.substring(
                    event.url.indexOf('/user-confirm-email/'),
                );
                history.push(substringFromUserConfirmEmail);
            }
        });

        return () => {
            App.removeAllListeners();
        };
    }, []);
};
