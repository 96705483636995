import {
    PRODUCT_LABEL,
    PRODUCT_LABEL_NAME,
} from '@constants/fromGraphql/ProductLabel.constans';
import { ProductLabel } from '@graphql/generated';
import styled from '@emotion/styled';
import { CircularProgress } from '@material-ui/core';

export interface ProductStatusWithIconProps {
    loading: boolean;
    value: ProductLabel;
}
export const ProductStatusWithIcon = ({
    loading,
    value,
}: ProductStatusWithIconProps) => {
    return (
        <ProductStatusWrapper>
            <IconWrapper>
                {loading ? (
                    <CircularProgressIcon size={'16px'} thickness={5} />
                ) : (
                    PRODUCT_LABEL[value].icon
                )}
            </IconWrapper>
            {PRODUCT_LABEL_NAME[value]}
        </ProductStatusWrapper>
    );
};

const ProductStatusWrapper = styled.div`
    align-items: center;
    border-radius: 16px;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    gap: 4px;
    width: max-content;
`;

const IconWrapper = styled.span`
    display: flex;
    font-size: 16px;
`;

const CircularProgressIcon = styled(CircularProgress)`
    padding: 2px;
`;
