import { dateFormat } from '@helpers/dateFormat';

const getFormattedStatisticsData = (
    dataArr: { label: string; value: number }[],
) => {
    const { getDateBeforeDays } = dateFormat;
    const statisticsData = [];

    for (let i = 14; i >= 1; i--) {
        if (dataArr.some((item) => item.label === getDateBeforeDays(i))) {
            statisticsData.push(
                dataArr.find((item) => item.label === getDateBeforeDays(i)),
            );
        } else {
            statisticsData.push({
                label: getDateBeforeDays(i),
                value: 0,
            });
        }
    }
    return statisticsData;
};

export const PaymentsHelpers = {
    getFormattedStatisticsData,
};
