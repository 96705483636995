import React from 'react';
import { Grid } from '@material-ui/core';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { sGrid, sOuter } from '@style/style';
import styled from '@emotion/styled';
import { SplitOrderRequest } from '@/EZC/domains/Orders/organisms/SplitOrderRequest/SplitOrderRequest';
import Headline from '@components/Headline/Headline';
import PageLoader from '@components/PageLoader/PageLoader';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useProductRequestsPage } from '@/EZC/domains/Account/pages/PaymentRequestsPage/useProductRequestsPage';
import { BOTTOM_NAVIGATION } from '@constants/layout.constants';
import { PayoffStatus, Product } from '@graphql/generated';

const ProductRequestsPage = () => {
    const {
        data,
        getMoreData,
        handlePaymentAccept,
        handlePaymentReject,
        hasMore,
        loading,
        paymentAcceptLoading,
        paymentRejectLoading,
    } = useProductRequestsPage();

    if (!data?.list.data.length && loading) return <PageLoader />;

    return (
        <>
            {/* @ts-expect-error migrate to TS */}
            <TopNav title={'Prośby o rozliczenie'} />
            <ProductRequestsContent
                /* @ts-expect-error migrate to TS */
                css={sOuter}
            >
                {/* @ts-expect-error migrate to TS */}
                <Headline>Prośby o rozliczenie</Headline>
                <Grid container css={[sGrid]}>
                    <Grid item xs={12}>
                        <ProductRequestsList>
                            {data?.list.data.map(({ orderId, payoffs }) => {
                                const order = payoffs[0].order;
                                const products = payoffs.reduce<
                                    (Pick<
                                        Product,
                                        'id' | 'price' | 'photoUrl' | 'name'
                                    > & {
                                        payoffId: string;
                                        status: PayoffStatus;
                                    })[]
                                >((prev, current) => {
                                    const products = current.products.map(
                                        (product) => ({
                                            ...product,
                                            status: current.status,
                                            payoffId: current.id,
                                        }),
                                    );

                                    return [...prev, ...products];
                                }, []);

                                return (
                                    <SplitOrderRequest
                                        key={orderId}
                                        loading={loading}
                                        paymentLoading={
                                            paymentRejectLoading ||
                                            paymentAcceptLoading
                                        }
                                        order={{
                                            badge: products?.some(
                                                (item) =>
                                                    item?.status === 'PENDING',
                                            ),
                                            date:
                                                order?.statusLog?.[0].date ||
                                                '',
                                            imageUrl:
                                                order.restaurant.photoUrl ?? '',
                                            name: order.shortId,
                                            personRequest: `${order.owner?.personal.name} ${order.owner?.personal.surname} `,
                                            price: products
                                                .reduce(
                                                    (priceSum, payment) =>
                                                        priceSum +
                                                        payment.price,
                                                    0,
                                                )
                                                .toFixed(2),
                                        }}
                                        onCancel={handlePaymentReject}
                                        onSettle={handlePaymentAccept}
                                        products={products.map(
                                            ({
                                                id,
                                                name,
                                                price,
                                                photoUrl,
                                                payoffId,
                                                status,
                                            }) => ({
                                                id,
                                                imageUrl: photoUrl || '',
                                                name,
                                                price: price.toFixed(2),
                                                paymentId: payoffId,
                                                status,
                                            }),
                                        )}
                                    />
                                );
                            })}
                            {hasMore && (
                                <Grid item xs={12}>
                                    <ButtonBorder
                                        disabled={loading}
                                        loading={loading}
                                        onClick={getMoreData}
                                    >
                                        Pokaż więcej
                                    </ButtonBorder>
                                </Grid>
                            )}
                        </ProductRequestsList>
                    </Grid>
                </Grid>
            </ProductRequestsContent>
        </>
    );
};

export default ProductRequestsPage;

const ProductRequestsContent = styled.div`
    padding-bottom: ${BOTTOM_NAVIGATION}px;
`;

const ProductRequestsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
