import NiceModal from '@ebay/nice-modal-react';
import { popup } from '@/AppPopups';
import { BasePopupNew } from '@/componentsV2/organisms/BasePopupNew/BasePopupNew';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { PopupFooterSticky } from '@/componentsV2/organisms/BasePopupNew/PopupFooterSticky';
import { PopupHeaderSecondary } from '@/componentsV2/organisms/BasePopupNew/PopupHeaderSecondary';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useState } from 'react';
import {
    ListAlphabetically,
    ListAlphabeticallyProps,
} from '@/componentsV2/molecules/ListAlphabetically/ListAlphabetically';
import styled from '@emotion/styled';

export interface SearchFilterIngredientsPopupProps {
    defaultChecked: string[];
    listAlphabetically: Pick<
        ListAlphabeticallyProps,
        'emptyResultText' | 'ingredients' | 'loading'
    >;
    onSave: (value: string[]) => void;
    title: string;
}

export const SearchFilterIngredientsPopup = NiceModal.create(
    (props: SearchFilterIngredientsPopupProps) => {
        return (
            <SearchFilterIngredientsPopupInner
                {...props}
                onCancel={() => {
                    popup.hide('SearchFilterIngredientsPopup');
                }}
                listAlphabetically={{
                    ...props.listAlphabetically,
                    initialLength: 11,
                    type: 'mobile',
                    variant: 'all',
                }}
            />
        );
    },
);

export interface SearchFilterIngredientsPopupInner
    extends SearchFilterIngredientsPopupProps {
    listAlphabetically: Omit<ListAlphabeticallyProps, 'onChange' | 'value'>;
    onCancel: () => void;
}

export const SearchFilterIngredientsPopupInner = ({
    defaultChecked,
    listAlphabetically,
    onCancel,
    onSave,
    title,
}: SearchFilterIngredientsPopupInner) => {
    const [checkedItems, setCheckedItems] = useState<string[]>(defaultChecked);

    return (
        <BasePopupNew
            animation="fromLeft"
            header={
                <PopupHeaderSecondary
                    text={title}
                    iconButton={{
                        icon: <ChevronLeft />,
                        onClick: onCancel,
                    }}
                    textButton={{
                        text: 'Wyczyść',
                        onClick: () => {
                            setCheckedItems([]);
                        },
                        variant: 'black',
                    }}
                />
            }
            footer={
                <PopupFooterSticky>
                    <Button
                        variant="black"
                        width="100%"
                        onClick={() => onSave(checkedItems)}
                    >
                        Zapisz
                    </Button>
                </PopupFooterSticky>
            }
            alwaysFullHeight
            stickyFooter
            stickyHeader
            contentPadding={'0 24px 20px 24px'}
        >
            <Wrapper>
                <ListAlphabetically
                    {...listAlphabetically}
                    onChange={setCheckedItems}
                    value={checkedItems}
                />
            </Wrapper>
        </BasePopupNew>
    );
};

const Wrapper = styled.div`
    padding-top: 20px;
`;
