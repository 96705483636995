import styled from '@emotion/styled';
import { colors } from '@/style';
import { ReactNode } from 'react';

export interface PopupFooterPrimaryProps {
    children: ReactNode;
}

export const PopupFooterPrimary = ({ children }: PopupFooterPrimaryProps) => {
    return <Footer>{children}</Footer>;
};

const Footer = styled.div`
    align-items: center;
    background: ${colors.white};
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    padding: 0 24px;
    width: 100%;
`;
