import * as yup from 'yup';

export const restaurantNameSchema = yup
    .string()
    .test(
        'valid-regexp',
        'Nazwa restauracji zawiera niedozwolone znaki',
        (value) => {
            if (typeof value === 'string') {
                try {
                    const pattern = new RegExp(value);
                    return !!pattern;
                } catch (error) {
                    return false;
                }
            }
            return false;
        },
    );
