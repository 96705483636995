import React from 'react';

export const CheckIcon = ({ color = '#000' }: { color?: string }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="1.25"
            fill={color}
        />
        <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="1.25"
            stroke={color}
            strokeWidth="1.5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2094 5.47641C14.4576 5.71632 14.4643 6.11199 14.2244 6.36017L8.18273 12.6102C8.06345 12.7336 7.89869 12.8025 7.72709 12.8007C7.55548 12.799 7.39214 12.7268 7.27536 12.6011L4.56703 9.68439C4.33215 9.43145 4.3468 9.03599 4.59975 8.80112C4.85269 8.56624 5.24815 8.58089 5.48302 8.83383L7.74248 11.2671L13.3257 5.49139C13.5656 5.24321 13.9612 5.23651 14.2094 5.47641Z"
            fill="white"
        />
    </svg>
);
