import { imagePreview } from '@helpers/image-preview';
import ImagePicker from '../../../components/ImagePicker';
import { useFormContext } from 'react-hook-form';
import React, { useState } from 'react';

const ImageLogo = ({ imageUrl }) => {
    const { register } = useFormContext();
    const [image, setImage] = useState();

    return (
        <React.Fragment>
            <ImagePicker
                {...register('photo', {
                    onChange: (event) => {
                        const file = event.target.files?.[0];

                        if (file)
                            imagePreview(event.target.files?.[0])
                                .then((file) => setImage(file))
                                .catch((e) => console.error(e));
                    },
                })}
                left
                image={image}
                imageUrl={imageUrl}
                title="Zdjęcie"
                desc="Fotografia produktu"
                name="photo"
            />
        </React.Fragment>
    );
};

export default ImageLogo;
