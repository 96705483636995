import React, { useState } from 'react';
import { Input } from './components';
import { useApolloClient } from '@apollo/client';

import { Grid, Hidden } from '@material-ui/core';
import Headline from '../../../components/Headline/Headline';
import {
    sGrid,
    sOuter,
    sDescription,
    sContainerOuter,
    sPaddingTop,
    sBottomLinks,
} from '../../../style/style';
import { colors, s } from '../../../style';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import {
    LoginDocument,
    useAuthSignInMutation,
    useGetAuthGoogleOauthRedirectUrlQuery,
} from '@graphql/generated';
import { toast } from 'react-toastify';
import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { delay } from 'lodash';
import { popup } from '@/AppPopups';
import { TwoActionButtons } from '@/componentsV2/molecules/TwoActionButtons/TwoActionButtons';
import styled from '@emotion/styled';
import { GoogleIcon } from '@/svgs/GoogleIcon';
import { ButtonBorder } from '@components/Elements';
import { mediaQueriesMaterial } from '@style/breakpoints';
import { MOBILE, IOS } from '@constants/common.constants';
import { useLoginWithGoogle } from '@/EZC/pages/LoginWithGoogle/useLoginWithGoogle';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { SavePassword } from 'capacitor-ios-autofill-save-password';

const initValues = {
    email: '',
    password: '',
};

const Login = () => {
    const { setTokens, setRestaurateurPrivacyPolicyPopup } = useAuthService(
        (state) => state,
    );

    const [inputValues, setInputValues] = useState(initValues);
    const [showPassword, setShowPassword] = useState(false);

    const [authSignIn, { loading }] = useAuthSignInMutation();
    const { data, loading: googleRedirectLoading } =
        useGetAuthGoogleOauthRedirectUrlQuery();

    const client = useApolloClient();

    const onChange = (e) =>
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });

    const { handleLoginWithGoogle } = useLoginWithGoogle();
    const { setWaiterView, isLoggedIn } = useWaiterService((state) => state);

    const handleSubmit = async () => {
        try {
            await authSignIn({
                variables: {
                    input: inputValues,
                },
                onCompleted: async ({
                    authSignIn: { refreshToken, sessionToken },
                }) => {
                    setTokens({ refreshToken, sessionToken });
                    setWaiterView(false);

                    toast.dark('Witamy ponownie! 🖐 ');

                    if (IOS) {
                        SavePassword.promptDialog({
                            username: inputValues.email,
                            password: inputValues.password,
                        });
                    }
                },
                onError: graphqlErrorHandler({
                    customCodeMessages: {
                        VALIDATION_FAILED: 'Email lub hasło nie są poprawne',
                    },
                }),
            });

            await client.resetStore();

            const { data } = await client.query({
                query: LoginDocument,
            });

            if (data && data.user.type !== 'Support') {
                const {
                    consents,
                    preferences: { __typename, spiciness, ...categories },
                } = data.user;

                const areCategoriesDefault = Object.values(categories).every(
                    ({ preffered, notPreffered }) => {
                        return !preffered.length && !notPreffered.length;
                    },
                );

                const isSpicinessDefault =
                    spiciness.min === 0 && spiciness.max === 5;

                if (areCategoriesDefault && isSpicinessDefault) {
                    delay(() => popup.show('PreferencesPopup'), 500);
                }

                if (consents.restaurateurPrivacyPolicy) {
                    setRestaurateurPrivacyPolicyPopup(true);
                }
            }
        } catch (error) {
            console.error('handleSubmit :: ', { error });
        }
    };
    const onEnterSubmit = (e) => e.key === 'Enter' && handleSubmit();

    return (
        <React.Fragment>
            <TopNav title={'Logowanie'} offset={60} />
            <div css={sOuter}>
                <Headline
                    extraCss={{
                        [`${mediaQueriesMaterial.md}`]: {
                            display: 'grid',
                        },
                    }}
                    itemChildren={
                        <ButtonWrapper>
                            <ButtonBorder link={`/signup`}>
                                Zarejestruj
                            </ButtonBorder>
                        </ButtonWrapper>
                    }
                >
                    <p>
                        Logowanie do panelu <ClientText>Klienta</ClientText>
                        <Separator>i</Separator>
                        <RestauratorText>Restauratora</RestauratorText>
                    </p>
                </Headline>
                <Grid container css={sGrid}>
                    <p css={sDescription}>
                        Zaloguj się do swojego konta Eatzon i poznaj kulinarny
                        świat
                    </p>
                </Grid>
            </div>

            <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
                <div css={[sFormContainer]}>
                    <Input
                        onChange={onChange}
                        name="email"
                        type="email"
                        value={inputValues.email}
                        placeholder="Email"
                        onKeyUp={onEnterSubmit}
                        autocomplete="email"
                    />

                    <Input
                        onChange={onChange}
                        name="password"
                        value={inputValues.password}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Hasło"
                        onKeyUp={onEnterSubmit}
                        sInputExtra={{ paddingRight: '50px' }}
                        autocomplete="current-password"
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                    />

                    <TwoActionButtons
                        buttonFirst={{
                            onClick: handleSubmit,
                            disabled: loading,
                            loading: loading,
                            children: 'Zaloguj',
                            variant: 'black',
                        }}
                        buttonSecond={{
                            onClick: async () => {
                                setWaiterView(false);
                                if (MOBILE) {
                                    handleLoginWithGoogle();
                                } else {
                                    window.location.href = data?.url;
                                }
                            },
                            children: (
                                <GoogleButtonContent>
                                    <GoogleIcon /> Zaloguj przez Google
                                </GoogleButtonContent>
                            ),
                            disabled: !data,
                            loading: googleRedirectLoading,
                            variant: 'black',
                        }}
                        separatorText="lub"
                    />
                </div>
                <Grid
                    item
                    xs={12}
                    css={[
                        sBottomLinks,
                        { [s.sm_down]: { justifyContent: 'flex-end' } },
                    ]}
                >
                    <Hidden smDown>
                        <ButtonBorder link={`/signup`}>
                            Załóż Konto
                        </ButtonBorder>
                    </Hidden>
                    <ButtonBorder link="/reset-password">
                        Nie pamiętasz hasła?
                    </ButtonBorder>
                    <ButtonBorder
                        link="/waiter"
                        color="#FF8000"
                        onClick={() => setWaiterView(isLoggedIn)}
                    >
                        Panel Kelnera
                    </ButtonBorder>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const sFormContainer = {
    [s.sm_down]: { paddingBottom: '2rem' },
    paddingBottom: '1.5rem',
};

export const GoogleButtonContent = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const ClientText = styled.span`
    color: ${colors.green};
`;

const RestauratorText = styled.span`
    color: ${colors.orange};
`;

const Separator = styled.span`
    margin: 0 8px;
`;

const ButtonWrapper = styled.div`
    ${mediaQueriesMaterial.md} {
        padding-top: 12px;
    }
`;

export default Login;
