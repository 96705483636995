/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { ButtonBorder } from '../../../components/Elements';
import Headline from '../../../components/Headline/Headline';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sOuter } from '../../../style/style';

const ModifierPage = () => {
    return (
        <React.Fragment>
            <TopNav title={'Modyfikator'} offset={60} />
            <div css={sOuter}>
                <Headline
                // itemChildren={<ButtonBorder>Przycisk</ButtonBorder>}
                >
                    Modyfikator
                </Headline>
            </div>
        </React.Fragment>
    );
};

export default ModifierPage;
