import React, { useEffect } from 'react';
import { useState } from 'react';
import { Collapsable, Button } from '@components/Elements';
import CloseIcon from '@material-ui/icons/Close';
import styled from '@emotion/styled';
import { useController, useForm } from 'react-hook-form';
import { css } from '@emotion/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { mediaQueriesMaterial } from '@style/breakpoints';
import { SPICINESS_LEVELS } from '@constants/spicinessLevels';
import { RangeSlider } from '@/componentsV2/molecules/RangeSlider/RangeSlider';

type RangeInputType = {
    min: number;
    max: number;
};

export interface RangePreferencesAccordionProps {
    rangeLabels: string[];
    description: string;
    loading?: boolean;
    loadingSave?: boolean;
    onSave: (value: RangeInputType, onCompleted: () => void) => void;
    range?: [number, number];
    title: string;
}

export const RangePreferencesAccordion = ({
    rangeLabels,
    description,
    loading,
    loadingSave,
    onSave,
    range,
    title,
}: RangePreferencesAccordionProps) => {
    const [open, setOpen] = useState(false);

    const { control, reset, handleSubmit } =
        useForm<RangePreferencesAccordionValues>({
            defaultValues: {
                range,
            },
        });

    const { field } = useController({ control, name: 'range' });

    const isSingleRange = range?.[0] === range?.[1];
    const isSingleLabel =
        range && rangeLabels[range[0]] === rangeLabels[range[1]];

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    const handleFormSubmit = handleSubmit(
        ({ range }) =>
            range &&
            onSave(
                {
                    min: range[0],
                    max: range[1],
                },
                () => {
                    reset({
                        range,
                    });
                    setOpen(false);
                },
            ),
    );

    useEffect(() => {
        range &&
            reset({
                range,
            });
    }, [range, open]);

    return (
        /* @ts-expect-error migrate to TS */
        <Collapsable
            additionalComponent={
                !open && (
                    <InnerWrapper $padding="24px 0 0 0">
                        {/* TODO: add dynamic label */}
                        {loading ? (
                            <Loading />
                        ) : range ? (
                            <AccordionLabel>
                                {isSingleRange ? (
                                    <>
                                        <span>{rangeLabels[range[0]]}</span>
                                        <span>{range[0]}</span>
                                    </>
                                ) : (
                                    <>
                                        <span>
                                            {rangeLabels[range[0]]}{' '}
                                            {!isSingleLabel
                                                ? ` - ${rangeLabels[range[1]]}`
                                                : ''}
                                        </span>
                                        <span>
                                            {range[0]}-{range[1]}
                                        </span>
                                    </>
                                )}
                            </AccordionLabel>
                        ) : null}
                    </InnerWrapper>
                )
            }
            description={description}
            noPadding
            sTextCss={{ margin: 0 }}
            alignStart
            noPaddingBottom
            parentIsOpen={open}
            parentSetIsOpen={setOpen}
            title={title}
        >
            <ContentWrapper>
                <InnerWrapper $padding="0 0 34px 0">
                    {loading ? (
                        <Loading />
                    ) : (
                        <RangeSlider
                            isDirty
                            onChange={field.onChange}
                            value={field.value}
                            marks={SPICINESS_LEVELS}
                            max={5}
                        />
                    )}
                </InnerWrapper>
                <ButtonsWrapper>
                    {/* @ts-expect-error migrate to TS */}
                    <Button size48 dimmed action={handleClose}>
                        <CloseIcon />
                    </Button>
                    {/* @ts-expect-error migrate to TS */}
                    <Button
                        action={handleFormSubmit}
                        loading={loadingSave || loading}
                        loadingSpinner={loadingSave}
                        w100
                        w100Desktop
                    >
                        Zapisz
                    </Button>
                </ButtonsWrapper>
            </ContentWrapper>
        </Collapsable>
    );
};

export type RangePreferencesAccordionValues = {
    range: RangePreferencesAccordionProps['range'];
};

const ContentWrapper = styled.div`
    padding: 32px 32px 24px;

    ${mediaQueriesMaterial.sm} {
        padding: 32px 24px 24px;
    }
`;

const InnerWrapper = styled.div<{
    $padding: string;
}>(
    ({ $padding }) => css`
        width: 100%;
        padding: ${$padding};
        display: grid;
    `,
);

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
`;

const Loading = styled(CircularProgress)`
    margin: 0 auto;
`;

const AccordionLabel = styled.p`
    display: flex;
    font-weight: 700;
    gap: 12px;
`;
