import React, { useEffect } from 'react';
import { colors } from '@style/index';
import { useSearchAutoCompleteQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import Skeleton from '@material-ui/lab/Skeleton/Skeleton';
import { Chip, ChipVariant } from '@/componentsV2/atoms/Chip/Chip';
import styled from '@emotion/styled';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { SearchListItem } from '../SearchListItem/SearchListItem';
import { uniqBy } from 'lodash';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';

export interface SearchAutoCompleteProps {
    applyUserPreferences: boolean;
    showBrochures: boolean;
    query: string;
    filteredIngredients: {
        id: string;
        variant: ChipVariant;
    }[];
    setFilteredIngredients: (
        value: SearchAutoCompleteProps['filteredIngredients'],
    ) => void;
    onChipClick?: () => void;
}

export const SearchAutoComplete = ({
    applyUserPreferences,
    query,
    filteredIngredients,
    setFilteredIngredients,
    showBrochures,
    onChipClick,
}: SearchAutoCompleteProps) => {
    const { localizedBannerEnabled, restaurantsForLocalizedHomepage } =
        useServerConfigStoreShallow([
            'restaurantsForLocalizedHomepage',
            'localizedBannerEnabled',
        ]);

    const { data, loading, previousData } = useSearchAutoCompleteQuery({
        variables: {
            productList: {
                payload: {
                    name: query,
                    applyUserPreferences,
                    isBrochure: showBrochures,
                },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
            ingredientList: { payload: { name: query } },
            restaurantList: {
                payload: { name: query, isBrochure: showBrochures },
                restaurantIds: localizedBannerEnabled
                    ? restaurantsForLocalizedHomepage
                    : undefined,
            },
        },
        onError: graphqlErrorHandler(true),
        skip: !query,
    });

    const products = data?.productList?.data;
    const restaurants = data?.restaurantList?.data;
    const ingredients = data?.ingredientList?.data;

    useEffect(() => {
        if (products?.length !== previousData?.productList?.data.length) {
            AnalyticsHelpers.createCustomEvent({
                name: 'view_search_results',
                params: { type: 'product' },
            });
        }
    }, [data, previousData]);

    return (
        <Wrapper>
            {!!products?.length && (
                <InnerContainer>
                    <Label>Produkty</Label>
                    {products?.map((product) => (
                        <SearchListItem
                            image={product?.photoUrl ?? ''}
                            name={product?.name}
                            price={product?.price}
                            key={product.id}
                            to={`/product/${product.id}`}
                        />
                    ))}
                </InnerContainer>
            )}
            {!!restaurants?.length && (
                <InnerContainer>
                    <Label>Restauracje</Label>
                    {restaurants?.map((restaurant) => (
                        <SearchListItem
                            image={restaurant?.photoUrl ?? ''}
                            name={restaurant?.name}
                            key={restaurant.id}
                            to={`/restaurant/${restaurant.slug}`}
                        />
                    ))}
                </InnerContainer>
            )}
            {!!ingredients?.length && (
                <InnerContainer>
                    <Label>Składniki</Label>
                    <ChipsWrapper>
                        {ingredients?.map((item) => (
                            <Chip
                                key={item.id}
                                clickable
                                icon
                                id={item.id}
                                label={item.name}
                                onClick={(_, nextVariant) => {
                                    onChipClick?.();

                                    setFilteredIngredients(
                                        uniqBy(
                                            [
                                                {
                                                    id: item.id,
                                                    variant: nextVariant,
                                                },
                                                ...filteredIngredients,
                                            ],
                                            'id',
                                        ),
                                    );
                                }}
                                variant={
                                    filteredIngredients.find(
                                        (ingredient) =>
                                            ingredient.id === item.id,
                                    )?.variant ?? 'default'
                                }
                                steps={['default', 'add', 'remove']}
                            />
                        ))}
                    </ChipsWrapper>
                </InnerContainer>
            )}
            {loading && (
                <>
                    <SkeletonWrapper variant="rect" />
                    <SkeletonWrapper variant="rect" />
                    <SkeletonWrapper variant="rect" />
                </>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: grid;
    gap: 16px;
`;

const InnerContainer = styled.div`
    width: 100%;
    display: grid;
    gap: 16px;
`;

const Label = styled.span`
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.36px;
    padding: 0;
    color: ${colors.gray500};
    font-weight: 800;
    line-height: 18px;
`;

const SkeletonWrapper = styled(Skeleton)`
    border-radius: 16px;
    height: 50px;
    width: 100%;
`;

const ChipsWrapper = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;
