import { useHistory } from 'react-router-dom';
import { toast, ToastContent } from 'react-toastify';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { PushNotificationsType } from './PushNotificationService.types';
import { useAuthService } from '../AuthService';
import { useSoundService } from '../SoundService/SoundService';
import waiterOrderNew from '@/assets/sounds/waiter_order_new.mp3';
import waiterOrderAddProduct from '@/assets/sounds/waiter_order_add_product.mp3';
import waiterOrderCancel from '@/assets/sounds/waiter_order_cancel.mp3';
import { PushNotifications } from '@capacitor/push-notifications';
import { popup } from '@/AppPopups';
import { useClearNotificationSoundsMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '../GraphqlErrorService';
import { FCM } from '@capacitor-community/fcm';
import { resolveEnv } from '@helpers/resolveEnv';

type AppClosedNotificationData = {
    aps: {
        alert: {
            title: string;
            body: string;
        };
    };
    order: string;
    type: PushNotificationsType;
    user: string;
    ownerId: string;
    sender: string;
    restaurant: string;
    groupId: string;
    cartId: string;
};

const NOTIFICATION_CHANNEL = {
    ORDER_NEW: {
        id: 'waiter_new_order',
        sound: 'waiter_order_new.wav',
        name: 'order new',
    },
    ORDER_ADDITION_FROM_USER: {
        id: 'waiter_order_add_product',
        sound: 'waiter_order_add_product.wav',
        name: 'order add product',
    },
    ORDER_CANCEL: {
        id: 'waiter_order_cancel',
        sound: 'waiter_order_cancel.wav',
        name: 'order cancel',
    },
};

export const useHandleNotificationsService = () => {
    const history = useHistory();

    const { playSound, stopPlayingSound } = useSoundService();

    const [clearNotificationSounds] = useClearNotificationSoundsMutation({
        context: {
            waiter: true,
        },
        onError: graphqlErrorHandler(true),
    });

    const isWaiterActive = (restaurantId?: string) => {
        return (
            window.location.pathname.includes('/waiter') &&
            useWaiterService.getState().isLoggedIn &&
            restaurantId === useWaiterService.getState().restaurantId
        );
    };

    const NOTIFICATION_TYPE: Record<
        PushNotificationsType,
        {
            redirect: ({
                orderId,
                ownerId,
                senderId,
                groupId,
                cartId,
            }: {
                orderId: string;
                ownerId: string;
                senderId: string;
                groupId: string;
                cartId: string;
            }) => string;
            showInfo: ({
                body,
                orderId,
                ownerId,
                senderId,
                restaurantId,
                groupId,
                cartId,
            }: {
                body: ToastContent;
                orderId: string;
                ownerId?: string;
                senderId?: string;
                restaurantId?: string;
                groupId?: string;
                cartId?: string;
            }) => void;
        }
    > = {
        ORDER_CREATED: {
            redirect: ({ orderId }) => {
                clearNotificationSounds();
                return `/waiter/order/${orderId}`;
            },
            showInfo: ({ body, orderId, restaurantId }) => {
                if (!isWaiterActive(restaurantId)) return;
                playSound(waiterOrderNew, orderId);
                useWaiterService.setState({ isNewOrdersPopupOpen: true });
                toast.success(body, {
                    onClick: () => {
                        stopPlayingSound(orderId);
                        history.push(`/waiter/order/${orderId}`);
                    },
                });
            },
        },
        ORDER_CANCELED: {
            redirect: ({ orderId }) => {
                clearNotificationSounds();
                return useWaiterService.getState().isLoggedIn
                    ? `/waiter/order/${orderId}`
                    : `/order/${orderId}`;
            },
            showInfo: ({ body, orderId }) => {
                playSound(waiterOrderCancel, orderId);
                toast.info(body, {
                    onClick: () => {
                        stopPlayingSound(orderId);
                        history.push(
                            useWaiterService.getState().isLoggedIn
                                ? `/waiter/order/${orderId}`
                                : `/order/${orderId}`,
                        );
                    },
                });
            },
        },
        ORDER_NOTIFY_ARRIVAL: {
            redirect: ({ orderId }) => {
                clearNotificationSounds();
                return `/waiter/order/${orderId}`;
            },
            showInfo: ({ body, orderId }) => {
                toast.info(body, {
                    onClick: () => {
                        stopPlayingSound(orderId);
                        history.push(`/waiter/order/${orderId}`);
                    },
                });
            },
        },
        ORDER_ACCEPTED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId, restaurantId }) => {
                if (isWaiterActive(restaurantId)) return;
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_MOVED_TO_READY: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId, restaurantId }) => {
                if (isWaiterActive(restaurantId)) return;
                return toast.success(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_MOVED_TO_PREPARED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId, restaurantId }) => {
                if (isWaiterActive(restaurantId)) return;
                return toast.success(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_REJECTED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId, restaurantId }) => {
                if (isWaiterActive(restaurantId)) return;
                return toast.error(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_MOVED_TO_CLOSED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId, restaurantId }) => {
                if (isWaiterActive(restaurantId)) return;
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_PRODUCT_ADDED: {
            redirect: ({ orderId }) => {
                clearNotificationSounds();
                return useWaiterService.getState().isLoggedIn
                    ? `/waiter/order/${orderId}`
                    : `/order/${orderId}`;
            },
            showInfo: ({ body, orderId, restaurantId }) => {
                if (useWaiterService.getState().restaurantId === restaurantId) {
                    playSound(waiterOrderAddProduct, orderId);
                }

                toast.info(body, {
                    onClick: () => {
                        stopPlayingSound(orderId);
                        history.push(
                            useWaiterService.getState().isLoggedIn
                                ? `/waiter/order/${orderId}`
                                : `/order/${orderId}`,
                        );
                    },
                });
            },
        },
        NEW_ORDER_PAYMENT_REQUEST: {
            redirect: () => `/account/payment-requests`,
            showInfo: ({ body }) =>
                toast.dark(body, {
                    onClick: () => history.push(`/account/payment-requests`),
                }),
        },
        ORDER_PAYMENT_REQUEST_ACCEPTED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) =>
                toast.success(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                }),
        },
        ORDER_PAYMENT_REQUEST_REJECTED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) =>
                toast.error(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                }),
        },
        NEW_CHAT_MESSAGE: {
            redirect: ({ orderId, ownerId, senderId }) => {
                if (!orderId) {
                    if (ownerId !== senderId) {
                        return '/account/ez-chat';
                    } else {
                        return '/admin/chat';
                    }
                }

                return useAuthService.getState().user?.userId !== ownerId
                    ? `/waiter/chat/${orderId}`
                    : senderId === ownerId
                      ? `/waiter/chat/${orderId}`
                      : `/orders-chat/${orderId}`;
            },
            showInfo: ({ body, orderId, ownerId, senderId }) => {
                if (!orderId) {
                    if (ownerId !== senderId) {
                        return toast.info(body, {
                            onClick: () => history.push(`/account/ez-chat`),
                        });
                    } else {
                        return toast.info(body, {
                            onClick: () => history.push('/admin/chat'),
                        });
                    }
                }

                return toast.info(body, {
                    onClick: () =>
                        useAuthService.getState().user?.userId !== ownerId
                            ? history.push(`/waiter/chat/${orderId}`)
                            : senderId === ownerId
                              ? history.push(`/waiter/chat/${orderId}`)
                              : history.push(`/orders-chat/${orderId}`),
                });
            },
        },
        PAYOFF_ACCEPTED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) =>
                toast.success(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                }),
        },
        PAYOFF_REJECTED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) =>
                toast.error(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                }),
        },
        PAYOFF_REQUESTED: {
            redirect: () => `/account/payment-requests`,
            showInfo: ({ body }) =>
                toast.dark(body, {
                    onClick: () => history.push(`/account/payment-requests`),
                }),
        },
        CART_APPROVAL_REQUEST: {
            redirect: ({ cartId }) => {
                popup.show('AcceptCartPopup', { cartId });
                return `/cart-checkout/${cartId}`;
            },
            showInfo: ({ body, cartId }) => {
                popup.show('AcceptCartPopup', { cartId });
                return toast.info(body, {
                    onClick: () => history.push(`/cart-checkout/${cartId}`),
                });
            },
        },
        ORDER_PARTIAL_ACCEPTANCE: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) => {
                popup.show('AcceptOrderChangesPopup', {
                    orderId,
                });
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_TABLE_CHANGED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) => {
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_ADDITION: {
            redirect: () => ``,
            showInfo: () => {},
        },
        ORDER_ADDITION_USER_ACCEPTED: {
            redirect: () => ``,
            showInfo: () => {},
        },
        ORDER_ADDITION_USER_REJECTED: {
            redirect: () => ``,
            showInfo: () => {},
        },
        ORDER_ADDITION_WAITER_ACCEPTED: {
            redirect: () => ``,
            showInfo: () => {},
        },
        ORDER_ADDITION_WAITER_REJECTED: {
            redirect: () => ``,
            showInfo: () => {},
        },
        GROUP_ADDED_TO_GROUP: {
            redirect: ({ groupId }) => {
                popup.show('YouHaveBeenAddedToGroupPopup', { groupId });
                return `/account/my-groups`;
            },
            showInfo: ({ body, groupId }) => {
                popup.show('YouHaveBeenAddedToGroupPopup', {
                    groupId,
                });
                return toast.info(body, {
                    onClick: () => history.push(`/account/my-groups`),
                });
            },
        },
        ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE: {
            redirect: () => ``,
            showInfo: () => {},
        },
        ORDER_ADDITION_FROM_USER: {
            redirect: ({ orderId }) => {
                clearNotificationSounds();
                return useWaiterService.getState().isLoggedIn
                    ? `/waiter/order/${orderId}`
                    : `/order/${orderId}`;
            },
            showInfo: ({ body, orderId, restaurantId }) => {
                if (!isWaiterActive(restaurantId)) return;

                if (useWaiterService.getState().restaurantId === restaurantId) {
                    playSound(waiterOrderAddProduct, orderId);
                }

                toast.info(body, {
                    onClick: () => {
                        stopPlayingSound(orderId);
                        history.push(
                            useWaiterService.getState().isLoggedIn
                                ? `/waiter/order/${orderId}`
                                : `/order/${orderId}`,
                        );
                    },
                });
            },
        },
        ORDER_ADDITION_FROM_WAITER: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) => {
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_ETA_CHANGED: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) => {
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        ORDER_MOVED_TO_DELIVERY: {
            redirect: ({ orderId }) => `/order/${orderId}`,
            showInfo: ({ body, orderId }) => {
                return toast.info(body, {
                    onClick: () => history.push(`/order/${orderId}`),
                });
            },
        },
        CLEAR_NOTIFICATION_SOUNDS: {
            redirect: () => ``,
            showInfo: () => {},
        },
        ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED: {
            redirect: ({ orderId }) => `/waiter/order/${orderId}`,
            showInfo: ({ body, orderId }) => {
                return toast.info(body, {
                    onClick: () => history.push(`/waiter/order/${orderId}`),
                });
            },
        },
    };

    const handleAppClosedNotification = (data: AppClosedNotificationData) => {
        history.push(
            NOTIFICATION_TYPE[data.type].redirect({
                orderId: data.order,
                ownerId: data.ownerId,
                senderId: data.sender,
                groupId: data.groupId,
                cartId: data.cartId,
            }),
        );
    };

    const handleAppOpenNotification = ({
        type,
        body,
        orderId,
        ownerId,
        senderId,
        restaurantId,
        groupId,
        cartId,
    }: {
        type: PushNotificationsType;
        body: ToastContent;
        orderId: string;
        ownerId?: string;
        senderId?: string;
        restaurantId?: string;
        groupId?: string;
        cartId?: string;
    }) => {
        NOTIFICATION_TYPE[type].showInfo({
            body,
            orderId,
            ownerId,
            senderId,
            restaurantId,
            groupId,
            cartId,
        });
    };

    const addNotificationsChannels = async () => {
        try {
            await PushNotifications.createChannel({
                id: NOTIFICATION_CHANNEL.ORDER_NEW.id,
                sound: NOTIFICATION_CHANNEL.ORDER_NEW.sound,
                name: NOTIFICATION_CHANNEL.ORDER_NEW.name,
                importance: 4,
                vibration: true,
                visibility: 1,
            });
            await PushNotifications.createChannel({
                id: NOTIFICATION_CHANNEL.ORDER_ADDITION_FROM_USER.id,
                sound: NOTIFICATION_CHANNEL.ORDER_ADDITION_FROM_USER.sound,
                name: NOTIFICATION_CHANNEL.ORDER_ADDITION_FROM_USER.name,
                importance: 4,
                vibration: true,
                visibility: 1,
            });
            await PushNotifications.createChannel({
                id: NOTIFICATION_CHANNEL.ORDER_CANCEL.id,
                sound: NOTIFICATION_CHANNEL.ORDER_CANCEL.sound,
                name: NOTIFICATION_CHANNEL.ORDER_CANCEL.name,
                importance: 4,
                vibration: true,
                visibility: 1,
            });
        } catch (error) {
            console.error('create channel error ::', error);
        }
    };

    const handleNotificationsTopics = async () => {
        await FCM.setAutoInit({ enabled: true });

        const currentEnv = resolveEnv('REACT_APP_NODE_ENV').toLowerCase();

        if (useAuthService.getState().hasAccountAlready) {
            await FCM.unsubscribeFrom({
                topic: `${currentEnv}-register-reminder`,
            });
        } else {
            await FCM.subscribeTo({ topic: `${currentEnv}-register-reminder` });
        }
    };

    return {
        handleAppClosedNotification,
        handleAppOpenNotification,
        addNotificationsChannels,
        handleNotificationsTopics,
    };
};
