import { useRoutesEZR } from '@/routes';
import Layout from '@/EZR/components/Layout';
import { useAuthService } from '@/services/AuthService';
import { useCheckActiveRestaurant } from './useCheckActiveRestaurant';
import { useFirebaseChatServiceLogin } from '@/services/FirebaseChatService/useFirebaseChatServiceLogin';

const IndexPage = () => {
    useFirebaseChatServiceLogin();

    const { isLoggedIn, user } = useAuthService((state) => state);
    const userType = user?.userType;

    const routes = useRoutesEZR(isLoggedIn && userType === 'Restaurateur');

    const { loading } = useCheckActiveRestaurant();

    if (loading) return null;

    return <Layout>{routes}</Layout>;
};

export default () => <IndexPage />;
