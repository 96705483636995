/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { colors, s, alpha, globals } from '../../style';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

const Widget = ({ isWidgetOpen, setIsWidgetOpen, children }) => {
    // console.log(isWidgetOpen)
    const { bottom } = useSafeAreaInsets();

    return (
        <React.Fragment>
            <div
                onClick={() => setIsWidgetOpen(false)}
                css={[
                    sBackground,
                    isWidgetOpen
                        ? { opacity: 1 }
                        : { opacity: 0, pointerEvents: 'none' },
                ]}
            />

            <div
                css={[
                    sContainer,
                    isWidgetOpen
                        ? { transform: 'translate(-50%, 0%)' }
                        : {
                              transform: 'translate(-50%, 100%)',
                              pointerEvents: 'none',
                          },
                    { bottom: bottom / 2 },
                ]}
            >
                {children}
            </div>
        </React.Fragment>
    );
};

export const sBackground = {
    width: '100vw',
    height: '100vh',
    transition: 'opacity 0.3s ease-out',
    backgroundColor: colors.text.concat(alpha[90]),
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    cursor: 'pointer',
};

export const sContainer = {
    maxWidth: 960,
    width: '100vw',
    transition: 'transform 0.5s cubic-bezier(0.22, 1, 0.36, 1)',
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, -0%)',
    backgroundColor: colors.white,
    borderRadius: '24px 24px 0 0',
    // padding: '1.5rem',
    paddingBottom: 72,
    [s.md]: {
        // padding: '2rem',
        borderRadius: '32px 32px 0 0',
    },

    zIndex: 1000,
    maxHeight: `calc(100vh - ${globals.topnavHeight.mobile}px)`,
    overflowY: 'scroll',

    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

export default Widget;
