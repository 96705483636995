/** @jsx jsx */
/** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx } from '@emotion/react'; // eslint-disable-line no-unused-vars
import { useHistory, useLocation } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ChatIcon from '@material-ui/icons/Chat';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useRoutesEZS } from '@/routes';
import { useAuthService } from '@/services/AuthService';
import { useFirebaseChatServiceLogin } from '@/services/FirebaseChatService/useFirebaseChatServiceLogin';
import styled from '@emotion/styled';
import { client } from '@/boot/client';
import { sentry } from '@/services/SentryService';
import { UserPollingProvider } from '@/EZC/services/UserPollingService/UserPollingProvider';

export const EzSupportContainer = () => {
    const { pathname } = useLocation();
    const [value, setValue] = useState(pathname);
    const history = useHistory();
    const { top } = useSafeAreaInsets();

    useEffect(() => {
        setValue(pathname);
    }, [pathname]);

    useFirebaseChatServiceLogin();

    const routes = useRoutesEZS();

    const { user, logout, isLoggedIn } = useAuthService();

    useEffect(() => {
        if (user?.userType !== 'Support' && isLoggedIn) {
            history.push('/404');
        }
    }, [user, isLoggedIn, history]);

    useEffect(() => {
        if (!isLoggedIn) {
            history.push('/login');
        }
    }, [isLoggedIn, history]);

    return (
        <Wrapper>
            <Spacer />
            <BottomNavigation
                value={value}
                onChange={(e, newValue) => setValue(newValue)}
                showLabels
                style={{
                    borderRadius: 8,
                    flexWrap: 'wrap',
                    height: 'auto',
                    margin: '0 auto',
                    maxWidth: 700,
                    minHeight: 80,
                    width: '100%',
                    paddingTop: top,
                }}
            >
                <BottomNavigationAction
                    onClick={() =>
                        history.push('/support/transactions-by-restaurants')
                    }
                    value={'/support/transactions-by-restaurants'}
                    label="Raporty transakcji restauracji"
                    icon={<LocationOnIcon />}
                />
                <NavigationItem
                    onClick={() => history.push('/support/ingredients')}
                    value={'/support/ingredients'}
                    label="Składniki"
                    icon={<ListAltIcon />}
                />
                <NavigationItem
                    onClick={() => history.push('/support/chat')}
                    value={'/support/chat'}
                    label="Czat"
                    icon={<ChatIcon />}
                />
                <NavigationItem
                    onClick={() => logout(client, sentry)}
                    value={'logout'}
                    label="Wyloguj"
                    icon={<ExitToAppIcon />}
                />
            </BottomNavigation>{' '}
            <Spacer />
            {routes}
            {isLoggedIn && <UserPollingProvider />}
        </Wrapper>
    );
};

const Spacer = styled.span`
    display: block;
    height: 16px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NavigationItem = styled(BottomNavigationAction)`
    flex: 0;
`;
