/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import { s } from '../../../style';

const StatusLarge = ({ color, children, frontColor, noPadding, extraCss }) => {
    return (
        <div
            css={[
                sStatusLarge,
                { backgroundColor: color },
                frontColor && { color: frontColor },
                noPadding && {
                    paddingTop: 0,
                    paddingBottom: 0,
                    fontWeight: 400,
                },
                extraCss,
            ]}
        >
            {children}
        </div>
    );
};

const sStatusLarge = {
    height: 'max-content',
    width: 'min-content',
    lineHeight: '1em',
    padding: '5px 8px 4px',
    textAlign: 'center',
    fontWeight: 700,
    letterSpacing: '-0.025em',
    color: '#ffffff',
    fontSize: '11px',
    borderRadius: '0.75rem',
    whiteSpace: 'nowrap',
    [s.ss]: { wordBreak: 'break-word' },
    [s.sss]: {
        fontSize: '9px',
    },
    [s.lg]: {
        fontSize: '13px',
    },
};

export default StatusLarge;
