import { ReactNode } from 'react';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
import {
    ConsumptionType,
    CookMethod,
    Cuisine,
    Diet,
    Dish,
    Flavor,
    MealTime,
} from '@graphql/generated';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';
import {
    DEFAULTS_CONSTANTS,
    SPECIAL_CHARS_REGEX,
} from '@constants/defaults.constants';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty, pickBy } from 'lodash';
import qs from 'qs';
import { toast } from 'react-toastify';
import { popup } from '@/AppPopups';
import { useCommonStore } from '@/CommonStore';
import * as yup from 'yup';
import { CALORIES_LEVEL_KEYS, CaloriesLevel } from '@constants/caloriesLevels';
import { COOKING_TIME_KEYS, CookingTime } from '@constants/cookingTimeLevels';
import {
    SPICINESS_LEVEL_KEYS,
    SpicinessLevel,
} from '@constants/spicinessLevels';
import { PRICE_LEVEL_KEYS, PriceLevel } from '@constants/priceLevels';
import { MEAL_TIME_KEYS } from '@constants/fromGraphql/MealTime.constants';
import { FLAVOUR_KEYS } from '@constants/fromGraphql/Flavor.constants';
import { DISH_KEYS } from '@constants/fromGraphql/Dish.constants';
import { DIET_KEYS } from '@constants/fromGraphql/Diet.constants';
import { CUISINE_KEYS } from '@constants/fromGraphql/Cuisine.constants';
import { COOK_METHOD_KEYS } from '@constants/fromGraphql/CookMethod.constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';

interface SearchFilterFormProviderProps {
    children: ReactNode;
}

export const SearchFilterFormProvider = ({
    children,
}: SearchFilterFormProviderProps) => {
    const { search } = useLocation();

    const {
        geolocation,
        consumptionTypes,
        applyUserPreferences,
        showBrochures,
    } = useUserSettingsStoreShallow([
        'geolocation',
        'consumptionTypes',
        'applyUserPreferences',
        'showBrochures',
    ]);

    const methods = useForm<SearchFilterFormSchemaValues>({
        resolver: yupResolver(SearchFilterFormSchema),
        defaultValues: {
            ...SEARCH_FILTER_FORM_DEFAULT_VALUES,
            ...getSearchFilterValuesAndValidate(search),
            geolocation,
            consumptionTypes,
            applyUserPreferences,
            showBrochures,
        },
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
};

export const useSearchFilterFormSubmit = () => {
    const { search } = useLocation();
    const history = useHistory();
    const { handleLocation } = useGeolocationContext();

    const { handleSubmit, reset } =
        useFormContext<SearchFilterFormSchemaValues>();

    const {
        geolocation,
        consumptionTypes,
        applyUserPreferences,
        showBrochures,
    } = useUserSettingsStoreShallow([
        'geolocation',
        'consumptionTypes',
        'applyUserPreferences',
        'showBrochures',
    ]);

    const { updateUserSettings } = useUserSettingsStoreShallow([
        'updateUserSettings',
    ]);

    const addSearchPhrasesBrowsingHistory = useCommonStore(
        (state) => state.addSearchPhrasesBrowsingHistory,
    );

    const handleReset = () => {
        reset({
            ...SEARCH_FILTER_FORM_DEFAULT_VALUES,
            ...getSearchFilterValuesAndValidate(search),
            geolocation,
            consumptionTypes,
            applyUserPreferences,
            showBrochures,
        });
    };

    const handleClear = () => {
        //@ts-expect-error migrate to ts
        handleLocation(true, (geolocation) =>
            reset({ ...SEARCH_FILTER_FORM_DEFAULT_VALUES, geolocation }),
        );
    };

    const handleFormSubmit = handleSubmit(
        async (values) => {
            values.query &&
                addSearchPhrasesBrowsingHistory(
                    values.query.replace(SPECIAL_CHARS_REGEX, ''),
                );

            // @ts-expect-error FIXME
            document?.activeElement?.blur();

            updateUserSettings({
                applyUserPreferences: values.applyUserPreferences,
                showBrochures: values.showBrochures,
                consumptionTypes: values.consumptionTypes,
                geolocation: values.geolocation,
            });

            history.push({
                pathname: '/search',
                search: setSearchFilterValues(values),
            });

            popup.hide('SearchFilterPopup');
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );

    return {
        handleClear,
        handleFormSubmit,
        handleReset,
    };
};

export const getSearchFilterValuesAndValidate = (
    search: string,
): Partial<SearchFilterFormSchemaValues> => {
    const values: Partial<SearchFilterQueryParamsValues> = qs.parse(search, {
        depth: 1,
        ignoreQueryPrefix: true,
    });

    const isValid = SearchFilterQueryParamsSchema.isValidSync(values, {
        stripUnknown: true,
    });

    const validatedValues = isValid ? values : {};

    if (!isValid) window.history.pushState('', '', '?');

    return { ...validatedValues };
};

export const setSearchFilterValues = (
    values: Partial<SearchFilterFormSchemaValues>,
): string => {
    const filteredValues = pickBy(values, (value, key) => {
        // @ts-expect-error FIXME
        return SEARCH_FILTER_QUERY_PARAMS_KEYS.includes(key) && !isEmpty(value);
    });

    return qs.stringify(filteredValues);
};

const SearchFilterQueryParamsSchema = yup.object({
    query: yup.string().default(''),
    allergy: yup.array().of(yup.string().defined()).default([]),
    calories: yup
        .mixed<CaloriesLevel>()
        .oneOf(CALORIES_LEVEL_KEYS)
        .nullable()
        .default(null),
    cookingTime: yup
        .mixed<CookingTime>()
        .oneOf(COOKING_TIME_KEYS)
        .nullable()
        .default(null),
    cookMethod: yup
        .array(yup.mixed<CookMethod>().oneOf(COOK_METHOD_KEYS).defined())
        .default([]),
    cuisine: yup
        .array(yup.mixed<Cuisine>().oneOf(CUISINE_KEYS).defined())
        .default([]),
    diet: yup.array(yup.mixed<Diet>().oneOf(DIET_KEYS).defined()).default([]),
    dish: yup.array(yup.mixed<Dish>().oneOf(DISH_KEYS).defined()).default([]),
    flavour: yup
        .array(yup.mixed<Flavor>().oneOf(FLAVOUR_KEYS).defined())
        .default([]),
    groups: yup.string().nullable().default(null),
    ingredients: yup.array().of(yup.string().defined()).default([]),
    mealTime: yup
        .array(yup.mixed<MealTime>().oneOf(MEAL_TIME_KEYS).defined())
        .default([]),
    notPreffered: yup.array().of(yup.string().defined()).default([]),
    price: yup
        .mixed<PriceLevel>()
        .oneOf(PRICE_LEVEL_KEYS)
        .nullable()
        .default(null),
    spiciness: yup
        .array(
            yup.mixed<SpicinessLevel>().oneOf(SPICINESS_LEVEL_KEYS).defined(),
        )
        .default([]),
});

const SearchFilterFormSchema = yup
    .object({
        distance: yup.string().nullable().default(null),
        geolocation: yup
            .object({
                address: yup.string().defined(),
                lng: yup.number().defined(),
                lat: yup.number().defined(),
            })
            .test(
                'valid-geolocation',
                'Nie udało się określić lokalizacji, wpisz swój adres.',
                (value) => !!value.address && !!value.lng && !!value.lat,
            ),
        consumptionTypes: yup
            .array()
            .of(yup.string<ConsumptionType>().defined())
            .default(DEFAULTS_CONSTANTS.consumptionTypes),
        applyUserPreferences: yup.boolean().default(false),
        showBrochures: yup.boolean().default(false),
    })
    .concat(SearchFilterQueryParamsSchema);

export const SEARCH_FILTER_COUNTABLE_KEYS = Object.keys(
    SearchFilterFormSchema.pick([
        'allergy',
        'calories',
        'cookMethod',
        'cookingTime',
        'cuisine',
        'diet',
        'distance',
        'dish',
        'flavour',
        'groups',
        'ingredients',
        'mealTime',
        'notPreffered',
        'price',
        'spiciness',
    ]).fields,
) as Partial<keyof SearchFilterFormSchemaValues>[];

export const SEARCH_FILTER_QUERY_PARAMS_KEYS = [
    ...SEARCH_FILTER_COUNTABLE_KEYS,
    ...Object.keys(SearchFilterFormSchema.pick(['query']).fields),
] as Partial<keyof SearchFilterFormSchemaValues>[];

export const SEARCH_FILTER_FORM_DEFAULT_VALUES =
    SearchFilterFormSchema.getDefault();

export interface SearchFilterQueryParamsValues
    extends yup.InferType<typeof SearchFilterQueryParamsSchema> {}

export interface SearchFilterFormSchemaValues
    extends yup.InferType<typeof SearchFilterFormSchema> {}
