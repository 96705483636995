import { ConsumptionType, InAppNotificationsType } from '@graphql/generated';

export type InAppNotificationsWaiterType = Extract<
    InAppNotificationsType,
    'ORDER_CANCELED' | 'ORDER_CREATED' | 'ORDER_ADDITION_FROM_USER'
>;

export type InAppNotificationsUserType = Exclude<
    InAppNotificationsType,
    'ORDER_CREATED' | 'ORDER_CANCELED' | 'ORDER_ADDITION_FROM_WAITER'
>;

export const IN_APP_NOTIFICATION_ENUM: Record<
    InAppNotificationsType,
    InAppNotificationsType
> = {
    CART_APPROVAL_REQUEST: 'CART_APPROVAL_REQUEST',
    GROUP_ADDED_TO_GROUP: 'GROUP_ADDED_TO_GROUP',
    NEW_ORDER_PAYMENT_REQUEST: 'NEW_ORDER_PAYMENT_REQUEST',
    ORDER_ACCEPTED: 'ORDER_ACCEPTED',
    ORDER_CANCELED: 'ORDER_CANCELED',
    ORDER_CREATED: 'ORDER_CREATED',
    ORDER_MOVED_TO_CLOSED: 'ORDER_MOVED_TO_CLOSED',
    ORDER_MOVED_TO_READY: 'ORDER_MOVED_TO_READY',
    ORDER_NOTIFY_ARRIVAL: 'ORDER_NOTIFY_ARRIVAL',
    ORDER_PARTIAL_ACCEPTANCE: 'ORDER_PARTIAL_ACCEPTANCE',
    ORDER_PAYMENT_REQUEST_ACCEPTED: 'ORDER_PAYMENT_REQUEST_ACCEPTED',
    ORDER_PAYMENT_REQUEST_REJECTED: 'ORDER_PAYMENT_REQUEST_REJECTED',
    ORDER_PRODUCT_ADDED: 'ORDER_PRODUCT_ADDED',
    ORDER_REJECTED: 'ORDER_REJECTED',
    PAYOFF_ACCEPTED: 'PAYOFF_ACCEPTED',
    PAYOFF_REJECTED: 'PAYOFF_REJECTED',
    PAYOFF_REQUESTED: 'PAYOFF_REQUESTED',
    ORDER_TABLE_CHANGED: 'ORDER_TABLE_CHANGED',
    ORDER_ADDITION: 'ORDER_ADDITION',
    ORDER_ADDITION_USER_ACCEPTED: 'ORDER_ADDITION_USER_ACCEPTED',
    ORDER_ADDITION_USER_REJECTED: 'ORDER_ADDITION_USER_REJECTED',
    ORDER_ADDITION_WAITER_ACCEPTED: 'ORDER_ADDITION_WAITER_ACCEPTED',
    ORDER_ADDITION_WAITER_REJECTED: 'ORDER_ADDITION_WAITER_REJECTED',
    ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE:
        'ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE',
    ORDER_ADDITION_FROM_USER: 'ORDER_ADDITION_FROM_USER',
    ORDER_ADDITION_FROM_WAITER: 'ORDER_ADDITION_FROM_WAITER',
    ORDER_ETA_CHANGED: 'ORDER_ETA_CHANGED',
    NEW_CHAT_MESSAGE: 'NEW_CHAT_MESSAGE',
    ORDER_MOVED_TO_DELIVERY: 'ORDER_MOVED_TO_DELIVERY',
    CLEAR_NOTIFICATION_SOUNDS: 'CLEAR_NOTIFICATION_SOUNDS',
    ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED:
        'ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED',
    ORDER_MOVED_TO_PREPARED: 'ORDER_MOVED_TO_PREPARED',
};

type InAppNotificationsWaiter = {
    [key in InAppNotificationsType]: { common?: string } | null;
} & {
    ORDER_ADDITION_FROM_USER: {
        custom: (data: { order: string }) => string;
    };
    NEW_CHAT_MESSAGE: {
        custom: (data: { orderId: string }) => string;
    };
    ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED: {
        custom: (data: { type: ConsumptionType }) => string;
    };
};

export const IN_APP_NOTIFICATION_WAITER: InAppNotificationsWaiter = {
    CART_APPROVAL_REQUEST: null,
    GROUP_ADDED_TO_GROUP: null,
    NEW_ORDER_PAYMENT_REQUEST: null,
    ORDER_ACCEPTED: null,
    ORDER_TABLE_CHANGED: null,
    ORDER_CANCELED: { common: 'Zamówienie zostało anulowane' },
    ORDER_CREATED: { common: 'Nowe zamówienie!' },
    ORDER_MOVED_TO_CLOSED: null,
    ORDER_MOVED_TO_READY: null,
    ORDER_PAYMENT_REQUEST_ACCEPTED: null,
    ORDER_PAYMENT_REQUEST_REJECTED: null,
    ORDER_PRODUCT_ADDED: null,
    ORDER_NOTIFY_ARRIVAL: { common: 'Klient przybył do restauracji' },
    ORDER_PARTIAL_ACCEPTANCE: null,
    ORDER_REJECTED: null,
    PAYOFF_ACCEPTED: null,
    PAYOFF_REJECTED: null,
    PAYOFF_REQUESTED: null,
    ORDER_ADDITION: null,
    ORDER_ADDITION_USER_ACCEPTED: null,
    ORDER_ADDITION_USER_REJECTED: null,
    ORDER_ADDITION_WAITER_ACCEPTED: null,
    ORDER_ADDITION_WAITER_REJECTED: null,
    ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE: null,
    ORDER_ADDITION_FROM_USER: {
        custom: ({ order }) =>
            `Zamówienie ${order}:  Klient dodał produkty do zamówienia. Sprawdź, czy możesz go w pełni realizować`,
    },
    ORDER_ADDITION_FROM_WAITER: null,
    ORDER_ETA_CHANGED: null,
    NEW_CHAT_MESSAGE: {
        custom: ({ orderId }) =>
            `Masz nową wiadomość na czacie w zamówieniu ${orderId}`,
    },
    ORDER_MOVED_TO_DELIVERY: null,
    CLEAR_NOTIFICATION_SOUNDS: null,
    ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED: {
        custom: ({ type }) =>
            `Klient zaakceptował zmiany w zamówieniu. ${type !== 'IN' ? 'Ustaw czas realizacji zamówienia' : ''}`,
    },
    ORDER_MOVED_TO_PREPARED: null,
};

type InAppNotificationsUser = {
    [key in InAppNotificationsType]: { title?: string; common?: string } | null;
} & {
    NEW_ORDER_PAYMENT_REQUEST: {
        custom: (data: {
            name: string;
            price: number;
            productName: string;
            surname: string;
        }) => string;
    };
    ORDER_PAYMENT_REQUEST_ACCEPTED: {
        custom: (data: {
            name: string;
            productName: string;
            surname: string;
        }) => string;
    };
    ORDER_PAYMENT_REQUEST_REJECTED: {
        custom: (data: {
            name: string;
            productName: string;
            surname: string;
        }) => string;
    };
    ORDER_TABLE_CHANGED: {
        custom: (data: { orderId: string }) => string;
    };
    ORDER_MOVED_TO_READY: {
        in: string;
        delivery: string;
        takeaway: string;
    };
    PAYOFF_REQUESTED: {
        custom: (data: {
            name: string;
            price: number;
            productName: string;
            surname: string;
        }) => string;
    };
    PAYOFF_ACCEPTED: {
        custom: (data: {
            name: string;
            productName: string;
            surname: string;
        }) => string;
    };
    PAYOFF_REJECTED: {
        custom: (data: {
            name: string;
            productName: string;
            surname: string;
        }) => string;
    };
    GROUP_ADDED_TO_GROUP: {
        custom: (data: { groupName: string }) => string;
    };
    ORDER_ETA_CHANGED: {
        custom: (data: { orderId: string }) => string;
    };
    NEW_CHAT_MESSAGE: {
        custom: (data: { orderId: string }) => string;
    };
};

export const IN_APP_NOTIFICATION_USER: InAppNotificationsUser = {
    NEW_ORDER_PAYMENT_REQUEST: {
        title: 'Prośba o przydzieleniu zamówienia',
        custom: ({ name, price, productName, surname }) =>
            `Użytkownik ${name} ${surname} poprosił o płatność ${price}zł za ${productName}`,
    },
    ORDER_ACCEPTED: {
        title: 'Zamówienie zaakceptowane',
        common: 'Zamówienie zostało przyjęte przez restauracje',
    },
    ORDER_CANCELED: {
        title: 'Zamówienie anulowane',
        common: 'Zamówienie zostało anulowane',
    },
    ORDER_CREATED: {
        title: 'Nowe zamówienie',
        common: 'Nowe zamówienie!',
    },
    ORDER_MOVED_TO_CLOSED: {
        title: 'Zamówienie zakończone',
        common: 'Twoje zamówienie zostało zakończone',
    },
    ORDER_MOVED_TO_READY: {
        title: 'Zamówienie gotowe',
        in: 'Twoje zamówienie jest gotowe',
        delivery:
            'Twoje zamówienie jest gotowe i zostało odebrane przez kuriera',
        takeaway: 'Twoje zamówienie jest gotowe do odbioru',
    },
    ORDER_PAYMENT_REQUEST_ACCEPTED: {
        title: 'Przydzielenie zamówienia zaakceptowane',
        custom: ({ name, productName, surname }) =>
            `Użytkownik ${name} ${surname} rozliczył płatność za ${productName}`,
    },
    ORDER_PAYMENT_REQUEST_REJECTED: {
        title: 'Przydzielenie zamówienia odrzucone',
        custom: ({ name, productName, surname }) =>
            `Użytkownik ${name} ${surname} odrzucił płatność za ${productName}`,
    },
    ORDER_PRODUCT_ADDED: {
        title: '',
        common: 'Kelner dodał produkty do Twojego zamówienia. Sprawdź, aby upewnić się, że wszystko jest zgodne z Twoimi oczekiwaniami.',
    },
    ORDER_REJECTED: {
        title: 'Zamówienie odrzucone',
        common: 'Zamówienie zostało odrzucone przez restauracje',
    },
    GROUP_ADDED_TO_GROUP: {
        title: 'Zostałeś dodany do grupy',
        custom: ({ groupName }) => `Zostałeś dodany do grupy ${groupName}`,
    },
    ORDER_NOTIFY_ARRIVAL: {
        title: 'Klient przybył',
        common: 'Klient przybył do restauracji',
    },
    PAYOFF_ACCEPTED: {
        title: 'Przydzielenie zamówienia zaakceptowane',
        custom: ({ name, productName, surname }) =>
            `Użytkownik ${name} ${surname} rozliczył płatność za ${productName}`,
    },
    PAYOFF_REJECTED: {
        title: 'Przydzielenie zamówienia odrzucone',
        custom: ({ name, productName, surname }) =>
            `Użytkownik ${name} ${surname} odrzucił płatność za ${productName}`,
    },
    PAYOFF_REQUESTED: {
        title: 'Prośba o przydzieleniu zamówienia',
        custom: ({ name, price, productName, surname }) =>
            `Użytkownik ${name} ${surname} poprosił o płatność ${price}zł za ${productName}`,
    },
    CART_APPROVAL_REQUEST: {
        title: 'Zatwierdź koszyk',
        common: 'Koszyk oczekuje na zatwierdzenie',
    },
    ORDER_PARTIAL_ACCEPTANCE: {
        title: 'Zamówienie częściowo zaakceptowane',
        common: 'Restauracja częściowo zaakceptowała twoje zamówienie',
    },
    ORDER_TABLE_CHANGED: {
        title: '', //TODO add proper title
        custom: ({ orderId }) =>
            `Zamówieniu ${orderId}: został zmieniony numer stolika`,
    },
    ORDER_ADDITION: {
        title: '',
        common: '',
    },
    ORDER_ADDITION_USER_ACCEPTED: {
        title: '', //TODO add proper title
        common: '',
    },
    ORDER_ADDITION_USER_REJECTED: {
        title: '', //TODO add proper title
        common: '',
    },
    ORDER_ADDITION_WAITER_ACCEPTED: {
        title: '', //TODO add proper title
        common: '',
    },
    ORDER_ADDITION_WAITER_REJECTED: {
        title: '', //TODO add proper title
        common: '',
    },
    ORDER_ADDITION_WAITER_PARTIAL_ACCEPTANCE: {
        title: '', //TODO add proper title
        common: '',
    },
    ORDER_ADDITION_FROM_USER: {
        title: '', //TODO add proper title
        common: '',
    },
    ORDER_ADDITION_FROM_WAITER: {
        title: '',
        common: 'Kelner dodał produkty do Twojego zamówienia. Sprawdź, aby upewnić się, że wszystko jest zgodne z Twoimi oczekiwaniami.',
    },
    ORDER_ETA_CHANGED: {
        title: '',
        custom: ({ orderId }) =>
            `Zamówienie ${orderId}: przewidywany czas dostawy został zmieniony`,
    },
    NEW_CHAT_MESSAGE: {
        title: 'Nowa wiadomość',
        custom: ({ orderId }) =>
            `Masz nową wiadomość na czacie w zamówieniu ${orderId}`,
        common: 'Na czacie pojawiła się nowa wiadomość',
    },
    ORDER_MOVED_TO_DELIVERY: {
        title: '',
        common: 'Twoje zamówienie jest gotowe i zostało odebrane przez kuriera',
    },
    CLEAR_NOTIFICATION_SOUNDS: {
        title: '',
        common: 'Twoje zamówienie jest gotowe i zostało odebrane przez kuriera',
    },
    ORDER_PARTIAL_ACCEPTANCE_USER_ACCEPTED: {
        title: '',
        common: '',
    },
    ORDER_MOVED_TO_PREPARED: {
        title: '',
        common: 'Twoje zamówienie jest gotowe do odbioru',
    },
};
