import styled from '@emotion/styled';
import { colors } from '@/style';

export interface SplitPaymentUserProps {
    id: string;
    avatar?: string;
    email: string;
    name: string;
}

export const SplitPaymentUser = ({
    avatar,
    email,
    name,
}: SplitPaymentUserProps) => (
    <SplitPaymentUserWrapper>
        {avatar && (
            <SplitPaymentUserAvatar>
                <img src={avatar} alt={'avatar'} />
            </SplitPaymentUserAvatar>
        )}

        <SplitPaymentUserInfo>
            <SplitPaymentUserName>{name}</SplitPaymentUserName>
            <SplitPaymentUserEmail>{email}</SplitPaymentUserEmail>
        </SplitPaymentUserInfo>
    </SplitPaymentUserWrapper>
);

const SplitPaymentUserWrapper = styled.div`
    align-items: center;
    gap: 16px;
    display: grid;
    grid-template-columns: auto 1fr;
`;

const SplitPaymentUserAvatar = styled.div`
    display: flex;
    border-radius: 50%;
    height: 38px;
    overflow: hidden;
    width: 38px;

    img {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }
`;

const SplitPaymentUserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    white-space: nowrap;
`;

const SplitPaymentUserName = styled.h3`
    color: ${colors.text};
    font-size: 14px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const SplitPaymentUserEmail = styled.span`
    color: ${colors.blueZodiac};
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
`;
