/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import Collapsable, {
    CollapsableText,
} from '../../../../components/Elements/Collapsable/Collapsable';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { colors } from '../../../../style';

const OrderNote = ({ note }) => {
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                noPadding
                oneLiner
                title={'Notatka'}
                icon={<PostAddIcon />}
                description={
                    <div
                        css={{
                            overflowX: 'scroll',
                        }}
                    >
                        {note}
                    </div>
                }
            >
                <CollapsableText>
                    Notatka dla restauracji:{' '}
                    <strong css={{ color: colors.text }}>{note}</strong>
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

export default OrderNote;
