/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useHistory, useLocation } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ChatIcon from '@material-ui/icons/Chat';
import NotificationIcon from '@material-ui/icons/NotificationsActive';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RestoreIcon from '@material-ui/icons/Restore';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import styled from '@emotion/styled';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

import { ezAdminRoutePrefix } from '../../pages/settings';

const Layout = ({ children }) => (
    <div
        css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
        <span css={{ display: 'block', height: 16 }} />
        <Navbar />
        <span css={{ display: 'block', height: 16 }} />
        {children}
    </div>
);

const Navbar = () => {
    const createUrl = (url) => ezAdminRoutePrefix.concat(url);
    const { pathname } = useLocation();
    const [value, setValue] = useState(pathname);
    const history = useHistory();
    const { top } = useSafeAreaInsets();

    useEffect(() => {
        setValue(pathname);
    }, [pathname]);

    return (
        <BottomNavigation
            value={value}
            onChange={(e, newValue) => setValue(newValue)}
            showLabels
            css={{
                borderRadius: 8,
                flexWrap: 'wrap',
                height: 'auto',
                margin: '0 auto',
                maxWidth: 700,
                minHeight: 80,
                width: '100%',
                paddingTop: top,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                justifyItems: 'center',
            }}
        >
            <BottomNavigationAction
                onClick={() => history.push(createUrl('/make-restaurateur'))}
                value={createUrl('/make-restaurateur')}
                label="Nadaj restauratora"
                icon={<FavoriteIcon />}
            />
            <BottomNavigationAction
                onClick={() =>
                    history.push(createUrl('/transactions-by-restaurants'))
                }
                value={createUrl('/transactions-by-restaurants')}
                label="Raporty transakcji restauracji"
                icon={<LocationOnIcon />}
            />
            <BottomNavigationAction
                onClick={() => history.push(createUrl('/charge-restaurant'))}
                value={createUrl('/charge-restaurant')}
                label="Obciąż restaurację"
                icon={<RestoreIcon />}
            />
            <BottomNavigationAction
                onClick={() => history.push(createUrl('/brochure-restaurants'))}
                value={createUrl('/brochure-restaurants')}
                label="Restauracje wizytówki"
                icon={<CreditCardIcon />}
            />
            <BottomNavigationAction
                onClick={() =>
                    history.push(createUrl('/change-restaurant-owner'))
                }
                value={createUrl('/change-restaurant-owner')}
                label="Zmień właściciela restauracji"
                icon={<LocationOnIcon />}
            />
            <BottomNavigationAction
                onClick={() => history.push(createUrl('/export-restaurant'))}
                value={createUrl('/export-restaurant')}
                label="Eksportowanie restauracji"
                icon={<LocationOnIcon />}
            />
            <BottomNavigationActionStyled
                onClick={() => history.push(createUrl('/ingredients'))}
                value={createUrl('/ingredients')}
                label="Składniki"
                icon={<ListAltIcon />}
            />
            <BottomNavigationActionStyled
                onClick={() => history.push(createUrl('/notifications'))}
                value={createUrl('/notifications')}
                label="Powiadomienie"
                icon={<NotificationIcon />}
            />
            <BottomNavigationActionStyled
                onClick={() => history.push(createUrl('/chat'))}
                value={createUrl('/chat')}
                label="Czat"
                icon={<ChatIcon />}
            />
        </BottomNavigation>
    );
};

export default Layout;

const BottomNavigationActionStyled = styled(BottomNavigationAction)`
    flex: 0;
`;
