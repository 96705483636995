import styled from '@emotion/styled';
import { colors } from '@style/index';
import { Grid } from '@material-ui/core';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { ServingTime } from '@/svgs/ServingTime';

export interface OrderServingTimeProps {
    serveOnTime: 'immediately' | 'sinceComing';
}

export const OrderServingTime = ({ serveOnTime }: OrderServingTimeProps) => {
    return (
        <Grid item xs={12} md={6}>
            <Accordion
                headerElement={
                    <ContentWrapper>
                        <IconWrapper>
                            <ServingTime />
                        </IconWrapper>
                        <InfoWrapper>
                            <Title>Czas podania</Title>
                            <Description>
                                {serveOnTime
                                    ? 'Gotowe na konkretną godzinę'
                                    : 'Po przybyciu na miejsce'}
                            </Description>
                        </InfoWrapper>
                    </ContentWrapper>
                }
            >
                <Wrapper>
                    <div>
                        Wybrany czas podania:{' '}
                        <OrderInfo>
                            {serveOnTime
                                ? 'Gotowe na konkretną godzinę'
                                : 'Rozpoczęcie przygotowania po przybyciu na miejsce'}
                        </OrderInfo>
                    </div>
                </Wrapper>
            </Accordion>
        </Grid>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const OrderInfo = styled.strong`
    color: ${colors.text};
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;
