import styled from '@emotion/styled';
import { colors } from '@/style';
import { CSSProperties, ReactNode, useEffect } from 'react';
import { css } from '@emotion/core';
import { CSSTransition } from 'react-transition-group';
import React from 'react';
import { usePopup } from '@/AppPopups';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

export interface BasePopupNewProps {
    alwaysFullHeight?: boolean;
    animation?: 'fromBottom' | 'fromLeft';
    children: ReactNode;
    className?: string;
    containerMaxWidth?: CSSProperties['maxWidth'];
    contentPadding?: CSSProperties['padding'];
    footer?: ReactNode;
    header?: ReactNode;
    overlay?: boolean;
    stickyFooter?: boolean;
    stickyHeader?: boolean;
    topSpace?: CSSProperties['paddingTop'];
}

// TODO: add desktop version (waiting fo designs)
export const BasePopupNew = ({
    alwaysFullHeight = false,
    animation = 'fromBottom',
    children,
    className,
    containerMaxWidth = '100%',
    contentPadding = '20px 24px',
    footer,
    header,
    overlay = false,
    stickyFooter,
    stickyHeader,
    topSpace = 0,
}: BasePopupNewProps) => {
    const popup = usePopup();

    const handleExited = () => {
        popup.remove();
    };

    const { top, bottom } = useSafeAreaInsets();

    useEffect(() => {
        document.body.style.overflowY = 'hidden';

        return () => {
            document.body.style.overflowY = 'unset';
        };
    }, []);

    return (
        <CSSTransition
            timeout={300}
            in={popup.visible}
            classNames={animation}
            onExited={handleExited}
        >
            <BasePopupWrapper overlay={overlay} paddingTop={topSpace}>
                <BasePopupContainer
                    className={className}
                    maxWidth={containerMaxWidth}
                    fullHeight={alwaysFullHeight}
                    paddingTop={top}
                    paddingBottom={bottom}
                >
                    {header && <Header sticky={stickyHeader}>{header}</Header>}
                    <Content padding={contentPadding}>{children}</Content>
                    {footer && <Footer sticky={stickyFooter}>{footer}</Footer>}
                </BasePopupContainer>
            </BasePopupWrapper>
        </CSSTransition>
    );
};

const BasePopupWrapper = styled.div<{
    overlay: boolean;
    paddingTop: CSSProperties['paddingTop'];
}>(
    ({ overlay, paddingTop }) => css`
        ${overlay && `background-color: ${colors.black}50`};
        align-content: flex-end;
        display: grid;
        justify-items: center;
        height: 100vh;
        padding-top: ${paddingTop};
        position: absolute;
        top: 0;
        width: 100%;
        /* becouse toast has z-index: 9999 */
        z-index: 9998;
        transition: opacity 300ms;

        .fromBottom-enter&,
        .fromLeft-enter& {
            opacity: 0;
        }
        .fromBottom-enter-active&,
        .fromLeft-enter-active& {
            opacity: 1;
        }
        .fromBottom-exit&,
        .fromLeft-exit& {
            opacity: 1;
        }
        .fromBottom-exit-active&,
        .fromLeft-exit-active& {
            opacity: 0;
        }
    `,
);

const BasePopupContainer = styled.div<{
    maxWidth: CSSProperties['maxWidth'];
    fullHeight: boolean;
    paddingTop: number;
    paddingBottom: number;
}>(
    ({ maxWidth, fullHeight, paddingTop, paddingBottom }) => css`
        background-color: ${colors.white};
        border-radius: 32px 32px 0 0;
        box-shadow: 0 -4px 24px 0 rgba(50, 50, 50, 0.08);
        display: flex;
        flex-direction: column;
        max-width: ${maxWidth};
        min-height: ${fullHeight ? '100vh' : 'auto'};
        overflow: clip;
        width: 100%;
        transition: transform 300ms;
        padding-top: ${paddingTop}px;
        padding-bottom: ${paddingBottom / 2}px;

        .fromBottom-enter & {
            transform: translateY(100vh);
        }
        .fromBottom-enter-active & {
            transform: translateY(0);
        }
        .fromBottom-exit & {
            transform: translateY(0);
        }
        .fromBottom-exit-active & {
            transform: translateY(100vh);
        }

        .fromLeft-enter & {
            transform: translateX(-100vw);
        }
        .fromLeft-enter-active & {
            transform: translateX(0);
        }
        .fromLeft-exit & {
            transform: translateX(0);
        }
        .fromLeft-exit-active & {
            transform: translateX(-100vw);
        }
    `,
);

const Header = styled.div<{ sticky?: boolean }>(
    ({ sticky }) => css`
        top: 0;
        position: ${sticky ? 'sticky' : 'static'};
        z-index: 9;
    `,
);

const Content = styled.div<{ padding: CSSProperties['padding'] }>(
    ({ padding }) => css`
        font-size: 16px;
        padding: ${padding};
        width: 100%;
        flex: 1;
        overflow-y: auto;
    `,
);

const Footer = styled.div<{ sticky?: boolean }>(
    ({ sticky }) => css`
        bottom: 0;
        position: ${sticky ? 'sticky' : 'static'};
        z-index: 99;
    `,
);
