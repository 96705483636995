import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import {
    Auth,
    getAuth,
    signInWithCustomToken,
    initializeAuth,
    indexedDBLocalPersistence,
} from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { FirebaseLoginTyped } from '@graphql/generated';
import jwt_decode from 'jwt-decode';
import { FirebaseToken } from '@/types/Tokens';
import { FIREBASE_CONFIG } from './FirebaseChatService.config';
import { MOBILE } from '@constants/common.constants';

interface FirebaseChatService {
    app?: FirebaseApp;
    auth?: Auth;
    firestore?: Firestore;
    isLoggedIn: boolean;
    userId?: string;
    initialize: (config: FirebaseOptions) => void;
    login: (
        client: ApolloClient<NormalizedCacheObject>,
        options: { waiter?: boolean },
    ) => Promise<void>;
    logout: () => Promise<void>;
}

export const useFirebaseChatService = create<FirebaseChatService>()(
    devtools((set, get) => ({
        app: undefined,
        auth: undefined,
        firestore: undefined,
        isLoggedIn: false,
        userId: undefined,
        initialize: () => {
            const app = initializeApp(FIREBASE_CONFIG);

            if (MOBILE) {
                initializeAuth(app, {
                    persistence: indexedDBLocalPersistence,
                });
            }

            const auth = getAuth(app);
            const firestore = getFirestore();

            set({
                app,
                auth,
                firestore,
            });
        },
        login: async (client, { waiter }) => {
            try {
                const { data } = await client.mutate({
                    mutation: FirebaseLoginTyped,
                    context: { waiter },
                });

                const token = data?.firebaseLogin.token;
                const tokenData = !!token && jwt_decode<FirebaseToken>(token);
                const auth = get().auth;

                if (!auth || !tokenData)
                    throw new Error(
                        'useFirebaseChatService : login :: no token or auth',
                    );

                await signInWithCustomToken(auth, token);

                set({
                    isLoggedIn: true,
                    userId: tokenData.uid,
                });
            } catch (error) {
                console.error('useFirebaseChatService : login ::', error);

                set({
                    isLoggedIn: false,
                });
            }
        },
        logout: async () => {
            try {
                await get().auth?.signOut();
            } catch (error) {
                console.error('useFirebaseChatService : logout ::', error);
            } finally {
                set({
                    isLoggedIn: false,
                });
            }
        },
    })),
);

export const FirebaseChatService = useFirebaseChatService.getState();
