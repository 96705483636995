import React from 'react';
import { colors } from '@style/index';
import { css } from '@emotion/core';
import { useDeliveryOrderTime } from './useDeliveryOrderTime';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import styled from '@emotion/styled';
import { OrderStatus } from '@graphql/generated';
import { dateFormat } from '@helpers/dateFormat';
import { mediaQueries } from '@style/breakpoints';

export interface DeliveryOrderTimeProps {
    deliveryTime: string;
    orderDoneTime: string;
    loading?: boolean;
    status: OrderStatus;
    isTakeaway?: boolean;
}
export const DeliveryOrderTime = ({
    deliveryTime,
    orderDoneTime,
    loading,
    status,
    isTakeaway,
}: DeliveryOrderTimeProps) => {
    const { timeUp, timeLeft, progress } = useDeliveryOrderTime(
        deliveryTime,
        orderDoneTime,
    );

    const counterItems = timeLeft.split(':');

    if (loading) return <Loading size={'115px'} thickness={5} />;

    return (
        <Container>
            {timeUp ? (
                <InfoWrapper columns>
                    <TimeUpIcon fontSize="medium" />
                    <Info small>
                        Czas {isTakeaway ? 'odbioru' : 'dostawy'} minął, ale
                        jeśli wciąż nie otrzymałeś zamówienia,{' '}
                        {isTakeaway
                            ? 'skontaktuj się z obsługą.'
                            : 'skorzystaj z czatu, aby bezpośrednio skontaktować się z kelnerem i uzyskać pomoc.'}
                    </Info>
                </InfoWrapper>
            ) : (
                <>
                    <DescriptionContainer>
                        {status !== 'DELIVERY' && !isTakeaway && (
                            <InfoWrapper columns>
                                <TimeUpIcon fontSize="medium" />
                                <Info small>
                                    Przewidywany czas przygotowania i dostawy{' '}
                                    <strong>
                                        {dateFormat.calculateHowManyMinutes(
                                            deliveryTime,
                                            orderDoneTime,
                                        )}{' '}
                                        minut.{' '}
                                    </strong>
                                    Jeśli nie akceptujesz lub masz jakiekolwiek
                                    pytania, skorzystaj z naszego czatu, aby
                                    bezpośrednio skontaktować się z kelnerem.
                                </Info>
                            </InfoWrapper>
                        )}
                        {isTakeaway ? (
                            <InfoWrapper>
                                <Info>
                                    {status === 'PREPARING' &&
                                        'Twoje zamówienie będzie gotowe za:'}
                                    {status === 'PREPARED' &&
                                        'Twoje zamówienie jest gotowe do odbioru. '}
                                </Info>
                            </InfoWrapper>
                        ) : (
                            <InfoWrapper>
                                <Info>
                                    {status !== 'DELIVERY'
                                        ? 'Twoje zamówienie jest w przygotowaniu.'
                                        : 'Twoje zamówienie jest w drodze.'}
                                </Info>
                                <Info>Szacowany czas dostawy za:</Info>
                            </InfoWrapper>
                        )}
                    </DescriptionContainer>
                    {!(isTakeaway && status !== 'PREPARING') && (
                        <>
                            <Counter>
                                {counterItems.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <CounterItem
                                                first={
                                                    counterItems.length > 2 &&
                                                    index === 0
                                                }
                                            >
                                                {item}
                                            </CounterItem>
                                            {index !==
                                                counterItems.length - 1 && (
                                                <span>:</span>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </Counter>
                            <DeliveryProgress
                                variant="determinate"
                                value={progress}
                            />
                        </>
                    )}
                    {isTakeaway && status === 'PREPARING' && (
                        <InfoWrapper>
                            <Info>
                                Obserwuj statusy zamówienia. Czas przygotowania
                                może ulec zmianie.
                            </Info>
                        </InfoWrapper>
                    )}
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: grid;
    grid-gap: 8px;
    justify-items: center;

    ${mediaQueries.sm} {
        padding: 16px 0;
    }
`;

const DescriptionContainer = styled.div`
    display: grid;
    grid-gap: 24px;
`;

const TimeUpIcon = styled(ErrorOutlineIcon)`
    padding-top: 4px;
`;

const Loading = styled(CircularProgress)`
    display: block;
    margin: 0 auto;
`;

const InfoWrapper = styled.div<{ columns?: boolean }>(
    ({ columns }) => css`
        color: ${colors.blueZodiac};
        display: grid;
        font-size: 15px;
        justify-items: center;
        line-height: 24px;
        text-align: center;

        ${columns &&
        css`
            grid-gap: 8px;
            grid-template-columns: 16px 1fr;
        `}
    `,
);

const Info = styled.p<{ small?: boolean }>(
    ({ small }) => css`
        font-size: ${small ? '12px' : '15px'};
        line-height: 24px;
        color: ${colors.blueZodiac};
    `,
);

const DeliveryProgress = styled(LinearProgress)`
    border-radius: 4px;
    height: 8px;
    width: 100%;

    &.MuiLinearProgress-root .MuiLinearProgress-bar1Determinate {
        border-radius: 4px;
    }

    &.MuiLinearProgress-root .MuiLinearProgress-barColorPrimary {
        background-color: ${colors.green};
    }

    &.MuiLinearProgress-colorPrimary {
        background-color: ${colors.emptyStar};
    }
`;

const Counter = styled.div`
    display: flex;
    font-size: 30px;
    justify-content: space-around;
    font-weight: 700;
    width: max-content;
`;

const CounterItem = styled.p<{ first?: boolean }>(
    ({ first }) => css`
        width: ${first ? '22px' : '44px'};
        display: flex;
        justify-content: center;
    `,
);
