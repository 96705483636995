import { useFirebaseChatService } from '../FirebaseChatService';
import { useEffect, useState } from 'react';
import {
    ChatDocumentData,
    ChatMessage,
    FirebaseEnv,
} from '../FirebaseChatService.types';
import {
    arrayUnion,
    doc,
    getDoc,
    onSnapshot,
    updateDoc,
} from 'firebase/firestore';
import { FirebaseChatServiceHelpers } from '../FirebaseChatService.helpers';
import { Unsubscribe } from 'firebase/auth';

export const useChatMessages = (env: FirebaseEnv, chatCreated = false) => {
    const { firestore, isLoggedIn } = useFirebaseChatService();

    const [loading, setLoading] = useState<boolean>(true);
    const [messages, setMessages] = useState<ChatMessage[]>([]);

    useEffect(() => {
        let unsubscribe: Unsubscribe;

        if (firestore && isLoggedIn && chatCreated) {
            unsubscribe = onSnapshot(doc(firestore, env), (doc) => {
                setLoading(false);

                setMessages(doc.data()?.messages);
            });
        }

        return () => {
            unsubscribe?.();
        };
    }, [firestore, isLoggedIn, chatCreated]);

    return { messages, loading };
};

export const useChatArchiveMessages = (env: FirebaseEnv) => {
    const { firestore, isLoggedIn, userId } = useFirebaseChatService();

    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const getArchiveMessages = async () => {
        if (firestore && isLoggedIn && userId) {
            const docRef = doc(firestore, env);

            try {
                const docSnapshot = await getDoc(docRef);
                const data = docSnapshot.data() as ChatDocumentData;

                setMessages(data.messages);
                setError(false);
            } catch (error) {
                console.error(
                    'useChatArchiveMessages : getArchiveMessages ::',
                    error,
                );

                setError(true);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        getArchiveMessages();
    }, [firestore, isLoggedIn, userId]);

    return { messages, error, loading };
};

export const useChatUnreadMessages = (env: FirebaseEnv) => {
    const { firestore, isLoggedIn, userId } = useFirebaseChatService();
    const { messages } = useChatMessages(env, true);

    const [unreadMessages, setUnreadMessages] = useState<number>(0);
    const [allMessages, setAllMessages] = useState<number>(0);
    const [interlocutorUnreadMessages, setInterlocutorUnreadMessages] =
        useState<number>(0);

    const getUnreadMessages = async () => {
        if (firestore && isLoggedIn && userId) {
            const docRef = doc(firestore, env);

            try {
                const docSnapshot = await getDoc(docRef);
                const data = docSnapshot.data() as ChatDocumentData;

                setAllMessages(data.messages.length);

                setInterlocutorUnreadMessages(
                    data.messages.filter(
                        (item) => item.sender === userId && !item.read,
                    ).length,
                );

                setUnreadMessages(
                    FirebaseChatServiceHelpers.getUnreadMessages(
                        data.messages,
                        userId,
                    ),
                );
            } catch (error) {
                console.error(
                    'useChatUnreadMessages : getUnreadMessages ::',
                    error,
                );
            }
        }
    };

    useEffect(() => {
        getUnreadMessages();
    }, [firestore, isLoggedIn, userId, messages]);

    return { allMessages, unreadMessages, interlocutorUnreadMessages };
};

export const useChatSendMessage = (env: FirebaseEnv) => {
    const { firestore, userId } = useFirebaseChatService();

    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');

    const sendMessage = async () => {
        if (!firestore) return;

        setLoading(true);

        const documentRef = doc(firestore, env);

        const newMessage: ChatMessage = {
            createdAt: new Date().toISOString(),
            message,
            sender: userId ?? '',
            read: false,
        };

        try {
            await updateDoc(documentRef, {
                messages: arrayUnion(newMessage),
                finishedAt: null,
            });
        } catch (error) {
            console.error('useOrderChat : sendMessage ::', error);
        } finally {
            setLoading(false);
        }
    };

    return { loading, message, sendMessage, setMessage };
};

export const useChatMarkAsReadMessage = (
    env: FirebaseEnv,
    messages: ChatMessage[],
) => {
    const { firestore, userId } = useFirebaseChatService();

    const markAsRead = async () => {
        if (!firestore) return;

        const documentRef = doc(firestore, env);

        try {
            const docSnapshot = await getDoc(documentRef);
            const data = docSnapshot.data() as ChatDocumentData;

            await updateDoc(documentRef, {
                messages: data.messages.map((item) => {
                    return item.sender !== userId
                        ? { ...item, read: true }
                        : item;
                }),
            });
        } catch (error) {
            console.error('useOrderChat : markAsReadMessage ::', error);
        }
    };

    useEffect(() => {
        markAsRead();
    }, [messages]);
};
