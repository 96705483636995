import { useEffect } from 'react';
import { useAuthService } from '../AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useFirebaseChatService } from './FirebaseChatService';
import { client } from '@/boot/client';

export const useFirebaseChatServiceLogin = (waiter?: boolean) => {
    const { login, logout } = useFirebaseChatService();

    const { sessionToken: sessionTokenUser } = useAuthService((state) => state);
    const { sessionToken: sessionTokenWaiter } = useWaiterService(
        (state) => state,
    );

    useEffect(() => {
        if (sessionTokenUser || (sessionTokenWaiter && waiter)) {
            login(client, { waiter });
        } else {
            logout();
        }
    }, [sessionTokenUser, sessionTokenWaiter, waiter]);
};
