import {
    CartConsumptionDetails,
    useCartWaiterQuery,
    useCartWaiterUpdateMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';
import { dateFormat } from '@helpers/dateFormat';
import dayjs from 'dayjs';
import { useState } from 'react';
import { toast } from 'react-toastify';

export const useOrderCheckout = () => {
    const [consumptionDetails, setConsumptionDetails] =
        useState<CartConsumptionDetails>({
            date: dateFormat.getFutureDate(dayjs().toDate()).toISOString(),
            guests: 1,
            type: 'IN',
        });
    const [table, setTable] = useState<string | null>(null);

    const { geolocation } = useGeolocationContext();

    const { data, loading } = useCartWaiterQuery({
        onError: graphqlErrorHandler(true),
        context: { waiter: true },
        fetchPolicy: 'cache-and-network',
    });

    const currentCart = data?.cartList?.data[0];

    const [cartUpdate] = useCartWaiterUpdateMutation({
        onError: graphqlErrorHandler(true),
    });

    const onChangeTable = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTable(event.target.value);

        currentCart?.id &&
            cartUpdate({
                context: { waiter: true },
                variables: {
                    input: {
                        cartId: currentCart.id,
                        payload: {
                            deliveryLocation: {
                                coordinates: [
                                    geolocation?.lng,
                                    geolocation?.lat,
                                ],
                            },
                            consumptionDetails,
                            table: event.target.value,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
    };

    const handleDownloadPdf = () => {
        const url = currentCart?.restaurant.layoutPdfUrl;

        url
            ? window.open(url, '_blank')
            : toast.warning('Nie ma możliwości podglądu układu sali');
    };

    const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const date =
            e.target.name === 'date'
                ? e.target.value
                : dateFormat.standardDate(consumptionDetails.date);

        const time =
            e.target.name === 'time'
                ? e.target.value
                : dateFormat.standardTime(consumptionDetails.date);

        const dateTimeToIso = dateFormat.dateTimeToIso(date, time);
        const currentDateTime = dateFormat.addHoursToDate(0.5);

        if (dateTimeToIso < currentDateTime.toISOString()) {
            toast.error(
                'Minimalny czas do zamówienia lub rezerwacji to 30 minut',
            );
            return;
        }

        setConsumptionDetails({
            date:
                e.target.name === 'time' || e.target.name === 'date'
                    ? dateFormat.dateTimeToIso(date, time)
                    : consumptionDetails.date,
            guests:
                e.target.name === 'guests'
                    ? parseInt(e.target.value)
                    : consumptionDetails.guests,
            type: 'IN',
        });

        data?.cartList?.data[0]?.id &&
            cartUpdate({
                context: { waiter: true },
                variables: {
                    input: {
                        cartId: data?.cartList?.data[0]?.id,
                        payload: {
                            deliveryLocation: {
                                coordinates: [
                                    geolocation?.lng,
                                    geolocation?.lat,
                                ],
                            },
                            consumptionDetails: {
                                date:
                                    e.target.name === 'time' ||
                                    e.target.name === 'date'
                                        ? dateFormat.dateTimeToIso(date, time)
                                        : consumptionDetails.date,
                                guests:
                                    e.target.name === 'guests'
                                        ? parseInt(e.target.value)
                                        : consumptionDetails.guests,
                                type: 'IN',
                            },
                            table,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
    };

    return {
        handleDownloadPdf,
        onChangeTable,
        onChangeDate,
        consumptionDetails,
        table,
        loading,
        cartId: data?.cartList?.data[0]?.id || '',
        price: data?.cartList?.data[0]?.lines[0].price || 0,
    };
};
