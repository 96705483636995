export const colors = {
    white: '#FFFFFF',
    gray: '#B9BCC3',
    gray100: '#F7F8FA', // 220 1 98
    gray200: '#F0F2F5', // 216 2 96
    gray250: '#EDEFF2', // 220 2 94
    gray300: '#E4E6EB', // 223 3 92
    gray400: '#D3D7E0', // 222 6 88
    gray500: '#B9BCC4', // 224 6 77
    gray600: '#A3A5B0', // 231 7 69
    gray700: '#838695', // 231 8 55
    gray800: '#808087',
    gray900: '#E3E4E7',

    textDimmed: '#3433404D', // colors.text.concat(alpha[30])
    textTransparent: '#343340CC',
    text: '#343340',
    black: '#000000',
    border: '#D1D5DF',

    lime: '#AAFF00',

    turquiose: '#0D9488',

    navy: '#2563EB',

    green100: '#EBFFEC',
    green200: '#D6FFDA',
    green300: '#BDE7BB',
    green400: '#00CC11',
    green500: '#D3EBD5',
    green: '#00B30F',
    green800: '#006609',
    greenDimmed: '#00B30F4D', // colors.green.concat(alpha[30])

    yellow100: '#FFFCEB',
    yellow200: '#F8D648',
    yellow300: '#FFA515',
    yellow: '#FFD500',
    yellowStar: '#FDE047',
    emptyStar: '#D9D9D9',
    currency: '#BCBCC1',
    yellowDimmed: '#FFD5004D', // colors.yellow.concat(alpha[30])

    orange100: '#FFF5EB',
    orange150: '#ffebd6',
    orange200: '#FFAA00',
    orange: '#FF8000',
    orange800: '#6a4017',
    orangeDimmed: '#FF80004D', // colors.orange.concat(alpha[30])

    red100: '#FFEBEB',
    red200: '#FFD6D6',
    red300: '#E74C3C',
    red400: '#FFD5D5',
    red: '#FF0000',
    red600: '#D32E38',
    red700: '#5C120A',
    red800: '#6A171C',
    red900: '#F87171',
    redDimmed: '#FF00004D', // colors.red.concat(alpha[30])
    redDark: '#800000',

    blue100: '#EBF8FF',
    blue: '#00AAFF',
    blueDimmed: '#00AAFF4D', // colors.blue.concat(alpha[30])
    hawkesBlue: '#D4D7DF',
    littleDipper: '#E4E6EA',
    platinum: '#E4E5E8',
    blueZodiac: '#9C9DA8',
    alto: '#e0e0e0',
    dust: '#C1C1C5',
    frenchGray: '#B9BCC3',
    grayDark: '#7F7E85',
    blueRomance: '#D2F8D6',
    greenBlue: '#0284C7',
    lightGray: '#8693A0',
    solitude: '#EAEDF0',
    poloBlue: '#98ACC5',
    bubbles: '#EBF9F0',
    lightSlateGray: '#7E8C9A',
    baliHai: '#8693A0',
    porcelainGray: '#F4F5F8',
    titanWhite: '#DFE1E4',
    ghost: '#B8B8BD',
};
