import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
    SearchInput,
    SearchInputProps,
} from '@/componentsV2/molecules/SearchInput/SearchInput';
import { useState } from 'react';
import { colors } from '@style/index';
import {
    ChipsBlock,
    ChipsBlockProps,
} from '@/EZC/domains/Account/molecules/ChipsBlock/ChipsBlock';
import {
    PreferencesBlock,
    PreferencesBlockProps,
} from '@/EZC/domains/Account/molecules/PreferencesBlock/PreferencesBlock';
import { Chip, ChipVariant } from '@/componentsV2/atoms/Chip/Chip';

export interface SearchablePreferencesBlockProps
    extends Omit<PreferencesBlockProps, 'children'>,
        Pick<SearchInputProps, 'placeholder' | 'value'> {
    chipsProps: ChipsBlockProps;
    onSearch: (value: string) => void;
    prefferedText: string;
}

export const SearchablePreferencesBlock = ({
    chipsProps,
    onSearch,
    placeholder,
    value,
    prefferedText,
    ...props
}: SearchablePreferencesBlockProps) => {
    const [isFocused, setIsFocused] = useState(false);

    const isEmptyInput = value === '';

    const prefferedItems = [
        ...(chipsProps?.preffered || []),
        ...(chipsProps?.notPreffered || []),
    ];

    const handleChange = (isAdd: number, isRemove: number) => {
        const newPreffered = chipsProps?.preffered || [];
        const newNotPreferred = chipsProps?.notPreffered || [];

        if (isRemove >= 0) newNotPreferred.splice(isRemove, 1);
        if (isAdd >= 0) newPreffered.splice(isAdd, 1);

        chipsProps.onChange({
            preffered: newPreffered,
            notPreffered: newNotPreferred,
        });
    };

    return (
        <PreferencesBlock {...props}>
            <SearchWrapper $focused={isFocused}>
                <SearchInput
                    onBlur={() => setIsFocused(false)}
                    onChange={onSearch}
                    onFocus={() => setIsFocused(true)}
                    onSubmit={onSearch}
                    placeholder={placeholder}
                    value={value}
                />
                <ChipsWrapper>
                    {isEmptyInput && (
                        <PrefferedWrapper>
                            {!!prefferedItems.length && (
                                <PrefferedItems>
                                    {prefferedItems.map((option) => {
                                        const isAdd =
                                            chipsProps.preffered?.findIndex(
                                                (item) => option.id === item.id,
                                            ) ?? -1;
                                        const isRemove =
                                            chipsProps.notPreffered?.findIndex(
                                                (item) => option.id === item.id,
                                            ) ?? -1;
                                        let variant: ChipVariant = 'default';
                                        if (isAdd >= 0) variant = 'add';
                                        if (isRemove >= 0) variant = 'remove';

                                        return (
                                            <Chip
                                                icon
                                                id={option.id}
                                                key={option.id}
                                                label={option.name}
                                                onClick={() =>
                                                    handleChange(
                                                        isAdd,
                                                        isRemove,
                                                    )
                                                }
                                                variant={variant}
                                            />
                                        );
                                    })}
                                </PrefferedItems>
                            )}

                            {chipsProps.options.length ? (
                                <PrefferedText>{prefferedText}</PrefferedText>
                            ) : (
                                <EmptyText>
                                    Wszystkie sugerowane składniki zostały
                                    wybrane. Aby dodać kolejne, wpisz nazwę lub
                                    fragment nazwy składnika w polu
                                    wyszukiwania.
                                </EmptyText>
                            )}
                        </PrefferedWrapper>
                    )}
                    {!!chipsProps.options.length && (
                        <ChipsBlock
                            {...chipsProps}
                            options={chipsProps.options}
                        />
                    )}
                </ChipsWrapper>
            </SearchWrapper>
        </PreferencesBlock>
    );
};

const ChipsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
`;

const PrefferedWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const PrefferedItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const PrefferedText = styled.p`
    color: ${colors.gray};
    font-size: 12px;
    letter-spacing: 0.36px;
    line-height: 14px;
    font-weight: 700;
`;

const EmptyText = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
`;

const SearchWrapper = styled.div<{ $focused: boolean }>(
    ({ $focused }) => css`
        background-color: ${$focused ? colors.gray200 : colors.gray100};
        border-radius: 24px;
    `,
);
