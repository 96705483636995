/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useRoutesSadm } from '../../../routes';
import { useFirebaseChatServiceLogin } from '@/services/FirebaseChatService/useFirebaseChatServiceLogin';

const SadmContainer = () => {
    useFirebaseChatServiceLogin();

    const routes = useRoutesSadm();
    return (
        <div
            css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {routes}
        </div>
    );
};

export default SadmContainer;
