import React from 'react';
import { Collapsable } from '@components/Elements';
import { CollapsableText } from '@components/Elements/Collapsable/Collapsable';
import { Grid } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import styled from '@emotion/styled';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { groupDescription } from '@helpers/string.helpers';
import { FindAndAddUser } from '@/componentsV2/molecules/FindAndAddUser/FindAndAddUser';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { colors } from '@style/index';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';
import { UserFriendInfo as UserType } from '@/EZC/domains/Account/pages/NewGroupPage/useNewGroupPage';
import { UserFriendInfo } from '@/EZC/domains/Account/molecules/UserFriendInfo/UserFriendInfo';
import { useGroupAccordion } from './useGroupAccordion';
import { Skeleton } from '@material-ui/lab';
import { GroupDeletePopup } from '../GroupDeletePopup/GroupDeletePopup';

interface GroupAccordion {
    name: string;
    groupId: string;
    isCurrentUserIsOwner: boolean;
    loadingList: boolean;
    userId: string;
    onClick: () => void;
    users: UserType[];
    notFoundUserAction: (email: string) => void;
}

export const GroupAccordion = ({
    name,
    onClick,
    users,
    groupId,
    isCurrentUserIsOwner,
    notFoundUserAction,
    loadingList,
}: GroupAccordion) => {
    const {
        deleteLoading,
        expanded,
        handleAddToGroup,
        handleRemoveFromGroup,
        loading,
        open,
        setExpanded,
        setOpen,
        setUserToDelete,
        userToDelete,
    } = useGroupAccordion({ groupId });

    const isLoading = loading || loadingList || deleteLoading;

    return (
        <Grid item xs={12} md={6}>
            {/* @ts-expect-error migrate to TS */}
            <Collapsable
                right
                noPadding
                oneLiner
                title={name}
                icon={<GroupIcon />}
                description={`W grupie ${groupDescription(users?.length)}`}
                parentIsOpen={expanded}
                parentSetIsOpen={setExpanded}
            >
                <CollapsableText>
                    {isCurrentUserIsOwner && (
                        <>
                            <FindAndAddUser
                                open={open}
                                onClick={handleAddToGroup}
                                setOpen={setOpen}
                                buttonText="Dodaj do grupy"
                                title="Dodaj znajomych do grupy"
                                placeholder="Wpisz e-mail "
                                backgroundColor={'transparent'}
                                notFoundElement={(email) => {
                                    return (
                                        <>
                                            <UserNotFoundText>
                                                <ErrorOutlineIcon /> Nie
                                                znaleziono użytkownika o podanym
                                                adresie e-mail. Sprawdź
                                                poprawność adresu lub wyślij
                                                zaproszenie do aplikacji Eatzon
                                                dla tej osoby.
                                            </UserNotFoundText>{' '}
                                            <Button
                                                variant="black"
                                                onClick={() => {
                                                    notFoundUserAction(
                                                        email ?? '',
                                                    );
                                                }}
                                                width="100%"
                                            >
                                                WYŚLIJ ZAPROSZENIE
                                            </Button>
                                        </>
                                    );
                                }}
                            />
                            <Separator margin={open ? '0 0 24px' : '24px 0'} />
                        </>
                    )}
                    <FriendsWrapper>
                        {users?.map((user) => {
                            return (
                                <UserFriendInfo
                                    key={user?.id}
                                    {...user}
                                    {...(!user?.isOwner &&
                                        isCurrentUserIsOwner && {
                                            onDelete: () =>
                                                setUserToDelete(user?.id),
                                        })}
                                />
                            );
                        })}
                        {isLoading && (
                            <SkeletonWrapper>
                                <Skeleton variant="circle" height={42} />
                                <Skeleton variant="rect" height={42} />
                            </SkeletonWrapper>
                        )}
                        <Button
                            center
                            width="100%"
                            variant="red"
                            onClick={onClick}
                        >
                            {isCurrentUserIsOwner
                                ? 'USUŃ GRUPĘ'
                                : 'OPUŚĆ GRUPĘ'}
                        </Button>
                    </FriendsWrapper>
                </CollapsableText>
            </Collapsable>
            {userToDelete && (
                <GroupDeletePopup
                    deleteButton={{
                        text: 'USUŃ OSOBĘ',
                        action: () => handleRemoveFromGroup(userToDelete),
                    }}
                    onRejectButton={() => setUserToDelete('')}
                    groupId={groupId}
                    isOwner={isCurrentUserIsOwner}
                    loading={isLoading}
                    text="Czy napewno chcesz usunąć tę osobę z grupy?"
                    header="Usuń osobę"
                />
            )}
        </Grid>
    );
};

const FriendsWrapper = styled.div`
    display: grid;
    grid-gap: 24px;
`;

const UserNotFoundText = styled.p`
    align-items: center;
    color: ${colors.red};
    display: flex;
    font-size: 12px;
    gap: 8px;
`;

const SkeletonWrapper = styled.div`
    display: grid;
    grid-gap: 12px;
    grid-template-columns: 42px 1fr;
`;
