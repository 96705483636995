import { EmptyResultsIcon } from '@/svgs/EmptyResultsIcon';
import styled from '@emotion/styled';
import { colors } from '@style/index';

interface SearchFilterEmptyProps {
    className?: string;
    text: string;
}

export const SearchFilterEmpty = ({
    className,
    text,
}: SearchFilterEmptyProps) => {
    return (
        <EmptyResultsWrapper className={className}>
            <EmptyResultsIcon />
            <EmptyResultsText>{text}</EmptyResultsText>
            <EmptyResultsDescription>Sprawdź pisownię</EmptyResultsDescription>
        </EmptyResultsWrapper>
    );
};

const EmptyResultsWrapper = styled.div`
    display: grid;
    grid-gap: 4px;
    justify-items: center;
`;

const EmptyResultsText = styled.p`
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
`;

const EmptyResultsDescription = styled.span`
    color: ${colors.baliHai};
    font-size: 12px;
    line-height: 18px;
`;
