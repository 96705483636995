import React from 'react'; // eslint-disable-line no-unused-vars
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import GroupIcon from '@material-ui/icons/Group';
import NiceModal from '@ebay/nice-modal-react';
import { usePopup } from '@/AppPopups';
import {
    useUserLeaveGroupPopupMutation,
    useAddedGroupQuery,
} from '@graphql/generated';
import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

type YouHaveBeenAddedToGroupPopupProps = {
    groupId: string;
};
type YouHaveBeenAddedToGroupPopupInnerProps = {
    invitingUser?: string;
    groupId: string;
    onClose: () => void;
};

export const YouHaveBeenAddedToGroupPopup = NiceModal.create(
    ({ groupId }: YouHaveBeenAddedToGroupPopupProps) => {
        const { data } = useAddedGroupQuery({
            variables: { input: { groupId } },
        });

        const popup = usePopup();

        return (
            <YouHaveBeenAddedToGroupPopupInner
                invitingUser={`${data?.group.owner.personal.name} ${data?.group.owner.personal.surname}`}
                groupId={groupId}
                onClose={() => popup.remove()}
            />
        );
    },
);

export const YouHaveBeenAddedToGroupPopupInner = ({
    invitingUser,
    groupId,
    onClose,
}: YouHaveBeenAddedToGroupPopupInnerProps) => {
    const [groupLeave] = useUserLeaveGroupPopupMutation();

    const handleGroupLeave = () => {
        groupLeave({
            variables: {
                input: {
                    groupId,
                },
            },
            onCompleted: () => {
                toast.dark(`Opuściłeś grupę`);
                onClose();
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return (
        <BasePopup
            header={
                <ModalHeader>
                    <Header>Zostałeś dodany do grupy!</Header>
                    <ButtonBorder onClick={onClose}>Pomiń</ButtonBorder>
                </ModalHeader>
            }
            footer={
                <ButtonsWrapper>
                    <Button width={'100%'} variant={'black'} onClick={onClose}>
                        OK, AKCEPTUJĘ
                    </Button>
                    <LeaveGroupButton onClick={handleGroupLeave}>
                        Opuść grupę
                    </LeaveGroupButton>
                </ButtonsWrapper>
            }
            children={
                <PopupDescription>
                    Użytkownik <UserName>{invitingUser}</UserName> dodał Cię do{' '}
                    <Group>
                        <AddToGroupIcon /> grupy.
                    </Group>{' '}
                    Możesz teraz wybrać, czy chcesz dołączyć lub opuścić grupę.
                </PopupDescription>
            }
        />
    );
};

const Header = styled.h2`
    color: ${colors.text};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
`;

const ModalHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const PopupDescription = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    padding-bottom: 40px;
`;

const UserName = styled.span`
    font-weight: bold;
`;

const Group = styled.span`
    display: inline-block;
`;

const AddToGroupIcon = styled(GroupIcon)`
    height: 18px;
    transform: translateY(4px);
    width: 18px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

const LeaveGroupButton = styled.button`
    color: ${colors.red};
    border: none;
    background-color: inherit;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
`;
