import React from 'react'; // eslint-disable-line no-unused-vars
import { colors, globals, s } from '@style/index';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { ProdItemMiniV2 } from '@/EZC/components/ProdItem/ProdItemMiniV2.component';
import { useCommonStore } from '@/CommonStore';
import { useLastBrowsingCarouselQuery } from '@graphql/generated';
import Carousel from '@/EZC/components/Carousel/Carousel.component';
import { Skeleton as MaterialSkeleton } from '@material-ui/lab';
import styled from '@emotion/styled';

export const LastBrowsingCarousel = () => {
    const {
        browsingHistory: { products },
    } = useCommonStore();

    const { data, loading } = useLastBrowsingCarouselQuery({
        variables: {
            input: {
                productsIds: products,
                restaurantsIds: [],
            },
        },
        skip: !products.length,
        onError: graphqlErrorHandler(true),
    });

    if (!products.length) return null;

    if (loading)
        return (
            <SkeletonWrapper>
                {new Array(10).fill(0).map((e, id) => (
                    <Skeleton variant="rect" key={id} />
                ))}
            </SkeletonWrapper>
        );

    return (
        <CarouselContainer>
            <CarouselHeader>Ostatnio przeglądane</CarouselHeader>
            <CarouselWrapper>
                {/* @ts-expect-error migrate */}
                <Carousel
                    scrollButtons={364}
                    data={data?.searchHistory?.products}
                    CarouselItem={ProdItemMiniV2}
                    link="/product/"
                    carouselItemProps={{ removeMargin: true }}
                />
            </CarouselWrapper>
        </CarouselContainer>
    );
};

const Skeleton = styled(MaterialSkeleton)`
    width: 170px;
    height: 196px;
    background-color: ${colors.gray200};
`;

const CarouselContainer = styled.div`
    margin: 0 auto;
    max-width: ${globals.maxWidthMedium}px;
    width: 100%;
`;

const CarouselWrapper = styled.div`
    margin: 0 auto;
    max-width: ${globals.maxWidthMediumCarousel}px;
`;

const CarouselHeader = styled.h2`
    font-size: 12px;
    padding: 0 0 22px;
    line-height: 18px;
    font-weight: 800;
    text-transform: uppercase;
    color: ${colors.gray};
`;

const SkeletonWrapper = styled.div`
    display: flex;
    overflow-x: scroll;
    padding: 56px 0 24px;
    width: 100%;

    span {
        margin-left: 12px;
        border-radius: 12px;
        flex-shrink: 0;
    }

    ${s.md} {
        padding: 56px 0 32px;
    }
`;
