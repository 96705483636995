import React, { useState } from 'react';
import { FindAndAddUser } from '@/componentsV2/molecules/FindAndAddUser/FindAndAddUser';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { css } from '@emotion/core';
import { AddNoAuthUserInfo } from '@/componentsV2/molecules/AddNoAuthUserInfo/AddNoAuthUserInfo';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CustomerInfoBox } from '@/EZW/domains/Orders/molecules/CustomerInfoBox/CustomerInfoBox';
import { CustomerProps } from '@/EZW/domains/pages/NewOrderPage/NewOrderPage';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

interface CustomerTypeChooseProps {
    setCustomer: (value?: CustomerProps) => void;
    customer?: CustomerProps;
    setIsEatzonUser: (value: boolean) => void;
    isEatzonUser: boolean;
}

export const CustomerTypeChoose = ({
    setCustomer,
    customer,
    setIsEatzonUser,
    isEatzonUser = true,
}: CustomerTypeChooseProps) => {
    const [open, setOpen] = useState(false);

    const { setCreateNewOrderData, createNewOrderData } = useWaiterService();

    const setCustomerData = (value?: CustomerProps) => {
        setCustomer(value);

        if (value) {
            setCreateNewOrderData(value, createNewOrderData.isEatzonClient);
        } else {
            setCreateNewOrderData({ name: '' }, true);
        }
    };

    const setEatzonUserInfo = (isEatzonUser: boolean) => {
        setIsEatzonUser(isEatzonUser);

        setCreateNewOrderData(createNewOrderData.customer, isEatzonUser);
    };

    return (
        <>
            {!customer?.name && (
                <Label
                    control={
                        <Checkbox
                            onChange={() => setEatzonUserInfo(!isEatzonUser)}
                            color="default"
                            checked={!isEatzonUser}
                        />
                    }
                    label={
                        <p>
                            Klient nie jest użytkownikiem{' '}
                            <strong>Eatzon</strong>
                        </p>
                    }
                />
            )}
            <Text>Właściciel zamówienia</Text>
            {isEatzonUser && !customer?.name && (
                <FindAndAddUser
                    open={open}
                    onClick={setCustomerData}
                    setOpen={setOpen}
                    buttonText="DODAJ DO ZAMÓWIENIA"
                    description="Wpisz e-mail klienta, aby odnaleźć jego dane konta w Eatzon i
                        dodać do zamówienia."
                    placeholder="Wyszukaj użytkownika przez e-mail"
                    notFoundElement={() => {
                        return (
                            <UserNotFoundText>
                                <ErrorOutlineIcon /> Nie znaleziono użytkownika
                                o podanym adresie e-mail
                            </UserNotFoundText>
                        );
                    }}
                    waiter
                />
            )}
            {!isEatzonUser && !customer?.name && (
                <AddNoAuthUserInfo
                    open={open}
                    setOpen={setOpen}
                    description="Podaj opis klienta, aby ułatwić identyfikację i obsługę (maks. 50 znaków)."
                    onClick={setCustomerData}
                />
            )}
            {customer?.name && (
                <CustomerInfoBox
                    name={customer?.name}
                    avatar={customer?.avatar ?? ''}
                    onClick={() => setCustomerData(undefined)}
                />
            )}
        </>
    );
};

const Label = styled(FormControlLabel)`
    .MuiTypography-body1 {
        font-size: 15px;
    }
    .MuiCheckbox-root {
        padding: 2px 16px 0 9px;
        color: ${colors.gray};
    }
    .Mui-checked {
        color: ${colors.orange};
    }
    .MuiIconButton-root:hover {
        background-color: transparent;
    }
    align-items: start;
    margin-bottom: 24px;
`;

const Checkbox = styled(MuiCheckbox)(
    () => css`
        position: relative;

        .MuiSvgIcon-root {
            height: 18px;
            width: 18px;
        }
    `,
);

const Text = styled.p`
    color: ${colors.text};
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 16px;
`;

const UserNotFoundText = styled.p`
    align-items: center;
    color: ${colors.red};
    display: flex;
    font-size: 12px;
    gap: 8px;
`;
