import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { s, colors, alpha } from '../../../../style';
import { ButtonBase, IconButton } from '@material-ui/core';
import { mergeRefs } from 'react-merge-refs';
import styled from '@emotion/styled';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default React.forwardRef(
    (
        { sBaseExtra, sInputExtra, refCustom, darker, numberOfRows, ...props },
        ref,
    ) => (
        <ButtonBase css={[sBase, sBaseExtra]} tabIndex="-1">
            {!!numberOfRows ? (
                <textarea
                    {...props}
                    rows={numberOfRows ?? '1'}
                    css={[sInput, sInputExtra, darker && sInputDarker]}
                    ref={mergeRefs([ref, refCustom])}
                    tabIndex="0"
                />
            ) : (
                <>
                    <input
                        {...props}
                        css={[sInput, sInputExtra, darker && sInputDarker]}
                        ref={mergeRefs([ref, refCustom])}
                        tabIndex="0"
                    />
                    {props.setShowPassword && (
                        <EyeButton
                            tabIndex="-1"
                            aria-label="toggle password visibility"
                            onClick={() =>
                                props.setShowPassword(!props.showPassword)
                            }
                        >
                            {props.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </EyeButton>
                    )}
                </>
            )}
        </ButtonBase>
    ),
);

export const sBase = {
    width: '100%',
    margin: '0 0 1.5rem',
    borderRadius: 24,
    position: 'relative',
    'input:-internal-autofill-selected': {
        appearance: 'none',
        fontWeight: '700 !important',
    },
    'input:-webkit-autofill': {
        fontWeight: 700,
        fontSize: '13px',
        fontFamily: 'Poppins',
        [s.md]: { fontSize: '15px' },
    },
    'input[type="password"]': {
        letterSpacing: '0.25em',
        '::placeholder': {
            letterSpacing: '0.025em',
        },
    },
    '.MuiTouchRipple-ripple': {
        opacity: '0.08 !important',
    },
    'input[type="date"]::-webkit-calendar-picker-indicator': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        opacity: 0,
    },
    'input[type="time"]::-webkit-calendar-picker-indicator': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        opacity: 0,
    },
    'input[type="number"]': {
        MozAppearance: 'textfield',
    },
    'input::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
    },
    'input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
    },
    select: {
        appearance: 'none',
    },
    '.MuiInput-underline:before, .MuiInput-underline:after, &:hover .MuiInput-underline:before':
        {
            border: 'none',
        },
};

export const sInput = {
    '.MuiInput-underline:before, input .MuiInput-underline:before, .MuiInput-underline:after, input .MuiInput-underline:after, input:focus .MuiInput-underline:before, input:focus .MuiInput-underline:after':
        {
            border: 'none !important',
        },
    '.MuiInputBase-input': {
        fontWeight: 700,
        margin: '-6px 0 -7px',
        fontSize: '13px',
    },
    appearance: 'none',
    transition:
        'opacity 0.25s ease-out, background-color 0.25s ease-out, color 0.25s ease-out, filter 0.3s ease-out, padding 0.3s ease-out',
    display: 'flex',
    border: 'none',
    letterSpacing: '0.025em',
    width: '100%',
    padding: '1rem 1.5rem',
    fontWeight: 700,
    fontSize: '13px',
    borderRadius: 24,
    fontFamily: 'Poppins',
    [s.md]: { fontSize: '15px', padding: '1rem 1.5rem' },
    // color: colors.text.concat(alpha[30]),
    // backgroundColor: colors.text.concat(alpha[4]),
    backgroundColor: colors.gray200,
    [s.touch]: {
        color: colors.text.concat(alpha[100]),
        backgroundColor: colors.gray200,
    },
    '&:hover': {
        [s.hover]: {
            color: colors.text.concat(alpha[100]),
            backgroundColor: colors.gray300,
        },
        '::placeholder': {
            opacity: 1,
        },
    },
    ':focus': {
        '::placeholder': {
            opacity: 1,
            color: colors.gray600,
        },
        outline: 'none',
        [s.touch]: {
            color: colors.text.concat(alpha[100]),
            backgroundColor: colors.gray300,
        },
        svg: {
            opacity: 0,
        },
    },
    '::placeholder': {
        transition: 'opacity 0.25s ease-out',
        fontWeight: 700,
        opacity: 1,
        color: colors.gray700,
        // letterSpacing: 0,
        // [s.lg]: {
        //   opacity: 0.3,
        // },
    },
};

export const sInputDarker = {
    backgroundColor: colors.gray250,
    [s.touch]: {
        backgroundColor: colors.gray300,
    },
    ':focus': {
        backgroundColor: colors.gray400,
        [s.touch]: {
            backgroundColor: colors.gray400,
        },
    },
    ':hover': {
        [s.hover]: {
            // filter: 'brigtness(0.95)',
            backgroundColor: colors.gray300,
        },
    },
};

const EyeButton = styled(IconButton)`
    padding: 0;
    position: absolute;
    right: 24px;
`;
