import {
    Box,
    FilledInput,
    FilledInputProps,
    IconButton,
} from '@material-ui/core';
import { colors } from '@style/index';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useDebounce } from '@hooks/useDebounce';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

export interface SearchInputProps
    extends Omit<
        FilledInputProps,
        'onChange' | 'value' | 'onSubmit' | 'color'
    > {
    onChange: (value: string) => void;
    onReset?: () => void;
    onSubmit?: (value: string) => void;
    value?: string;
    variant?: 'light' | 'default';
}

export const SearchInput = ({
    onChange,
    onReset,
    onSubmit,
    placeholder,
    value = '',
    variant = 'default',
    ...rest
}: SearchInputProps) => {
    const [searchValue, setSearchValue] = useState(value);
    const debounce = useDebounce(searchValue, 200);

    const handleEnterSubmit = (e: KeyboardEvent) =>
        e.key === 'Enter' && onSubmit?.(searchValue);

    const handleReset = () => {
        setSearchValue('');
        onReset?.();
    };

    useEffect(() => {
        onChange(debounce);
    }, [debounce]);

    useEffect(() => {
        setSearchValue(value);
    }, [value]);

    return (
        <SearchWrapper>
            <TextFieldElement
                {...rest}
                autoComplete={'off'}
                disableUnderline
                fullWidth
                placeholder={placeholder}
                value={searchValue}
                variant={variant}
                onChange={(event) => setSearchValue(event.target.value)}
                onKeyUp={handleEnterSubmit}
                endAdornment={
                    <Box display={'flex'}>
                        {searchValue && (
                            <IconButton
                                onClick={() => {
                                    handleReset();
                                }}
                            >
                                <CloseIcon style={{ fill: '#343340' }} />
                            </IconButton>
                        )}
                        <IconButton
                            onClick={() => {
                                onSubmit?.(searchValue);
                            }}
                        >
                            <SearchIcon style={{ fill: '#343340' }} />
                        </IconButton>
                    </Box>
                }
            />
        </SearchWrapper>
    );
};

const SearchWrapper = styled.div`
    position: relative;
    z-index: 2;
`;

const TextFieldElement = styled(FilledInput)<{ variant: string }>(
    ({ variant }) => css`
        background-color: ${variant === 'light'
            ? colors.gray200
            : colors.littleDipper};
        border-radius: 40px;
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        padding-right: 0;

        &:focus {
            background-color: ${variant === 'light'
                ? colors.littleDipper
                : colors.hawkesBlue};
        }

        &:hover {
            background-color: ${variant === 'light'
                ? colors.gray300
                : colors.hawkesBlue};
        }

        .MuiFilledInput-input {
            height: ${variant === 'light' ? '52px' : '48px'};
            padding: 0 0 0 24px;
        }
    `,
);
