import { useParams } from 'react-router-dom';
import {
    useOrderPageQuery,
    useAcceptOrderMutation,
    OrderItem,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useFirebaseChatService } from '@/services/FirebaseChatService/FirebaseChatService';
import { dateFormat } from '@helpers/dateFormat';
import { DeepPartial } from '@/types/definitions/common';

export const useOrderPage = () => {
    const userId = useFirebaseChatService((state) => state.userId);
    const orderId = useParams<{ id: string }>().id;

    const {
        data,
        error: orderPageError,
        loading: orderPageLoading,
    } = useOrderPageQuery({
        context: { waiter: true },
        fetchPolicy: 'cache-and-network',
        onError: graphqlErrorHandler(true),
        variables: { input: { orderId } },
    });

    const [acceptOrder, { loading: acceptOrderLoading }] =
        useAcceptOrderMutation();

    const order = data?.order;

    const {
        delivery,
        shortId,
        status,
        statusLog,
        id,
        owner,
        details: orderDetails,
        waiterNote,
    } = order || {};

    const { deliveryAddress, deliveryCost, deliveryDetails } = delivery || {};

    const priceSum =
        status === 'REJECTED'
            ? 0
            : data?.order.items.reduce(
                  (acc, item) =>
                      item.status !== 'CANCELED'
                          ? acc + item.product.price
                          : acc + 0,
                  0,
              );

    type ProductsGroupedById = {
        [productId: string]: DeepPartial<OrderItem>[];
    };

    const productsGroupedById =
        data?.order?.items.reduce((result, obj) => {
            const productId = obj.product.id;
            if (!result[productId]) {
                result[productId] = [];
            }
            result[productId].push(obj);
            return result;
        }, {} as ProductsGroupedById) || {};

    const productsIds = Object.keys(productsGroupedById || {});
    const timeLeft = dateFormat.calculateHowManyMinutes(
        order?.delivery?.eta || '',
        order?.statusLog?.find((item) => item.status === 'ACCEPTED')?.date,
    );

    const canChangeStatus =
        data?.order.details.type === 'IN' &&
        (data?.order.status === 'PREPARING' ||
            data?.order.status === 'COMPLETED' ||
            data?.order.status === 'ACCEPTED' ||
            data?.order.status === 'PREPARED');

    return {
        acceptOrder,
        acceptOrderLoading,
        canChangeStatus,
        deliveryAddress,
        deliveryCost,
        deliveryDetails,
        details: orderDetails,
        id,
        order,
        orderId,
        orderPageError,
        orderPageLoading,
        owner,
        priceSum,
        productsGroupedById,
        productsIds,
        shortId,
        status,
        statusLog,
        timeLeft,
        userId,
        waiterNote,
    };
};
