/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import { Button, InputText } from '@/EZAdmin/components/index';
import { restaurantNameSchema } from '@/EZR/pages/Restaurant/helpers/restaurantNameSchema';
import { toast } from 'react-toastify';
import { PAGE_SIZE } from '@/EZAdmin/hooks/useFindRestaurant';
import styled from '@emotion/styled';

const FindRestaurant = ({ loading, setSearch, findRestaurant, isActive }) => {
    const [query, setQuery] = useState('');
    const handleQuery = (e) => setQuery(e.target.value);

    const handleSubmit = () => {
        if (!restaurantNameSchema.isValidSync(query)) {
            toast.error('Nazwa restauracji zawiera niedozwolone znaki');
            return;
        }
        setSearch(query);
        findRestaurant({
            variables: {
                input: { payload: { name: query, isActive } },
                pagination: { page: 1, pageSize: PAGE_SIZE },
            },
        });
    };
    const onEnterSubmit = (e) => e.key === 'Enter' && handleSubmit();

    return (
        <Wrapper>
            <InputText
                label="Podaj nazwę restauracji"
                value={query}
                onChange={handleQuery}
                onKeyUp={onEnterSubmit}
            />
            <Button
                css={{ marginLeft: 8 }}
                onClick={handleSubmit}
                disabled={loading}
            >
                Szukaj
            </Button>
        </Wrapper>
    );
};

FindRestaurant.propTypes = {
    getRestaurant: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

const Wrapper = styled.div`
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    max-width: 720px;
    padding: 0 40px;
    width: 100%;
`;

export default FindRestaurant;
