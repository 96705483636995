/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
// import Layout from '../../components/Layout'
import { default as SignUpView } from '../../views/Auth/Signup.view';

const Signup = () => {
    return (
        // <Layout>
        <SignUpView />
        // </Layout>
    );
};

export default Signup;
