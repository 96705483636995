/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    FindRestaurant,
    Button,
    SearchResultsRestaurants,
} from '@/EZAdmin/components';
import { useExportRestaurant } from './useExportRestaurant';
import { useFindRestaurant } from '@/EZAdmin/hooks/useFindRestaurant';
import styled from '@emotion/styled';
import Loader from '@components/Spinner';
import {
    ENVIRONMENT_NAME_VALUES,
    SOURCE_ENVIRONMENT,
} from '@constants/fromGraphql/Environment.constants';
import { Environment } from '@graphql/generated';

export const EzAdminExportRestaurantPage = () => {
    const { handleMoveRestaurant, restaurantsState, handleRestaurantState } =
        useExportRestaurant();

    const {
        getRestaurants,
        data,
        loading,
        hasMore,
        findRestaurant,
        setSearch,
    } = useFindRestaurant(true);

    return (
        <React.Fragment>
            <ListWrapper>
                <FindRestaurant
                    getRestaurant={getRestaurants}
                    loading={loading}
                    findRestaurant={findRestaurant}
                    setSearch={setSearch}
                    isActive
                />
                <SearchResultsRestaurants
                    restaurants={data?.restaurantList.data}
                    // @ts-expect-error FIXME
                    ItemHelperComponent={({ restaurantId }) => {
                        const restaurantState =
                            restaurantsState?.[restaurantId];

                        return restaurantState?.loading ? (
                            // @ts-expect-error FIXME
                            <Loader size={1.5} />
                        ) : (
                            <ExportWrapper>
                                <select
                                    value={restaurantState?.value}
                                    onChange={(event) =>
                                        handleRestaurantState(restaurantId, {
                                            value: event.target
                                                .value as Environment,
                                        })
                                    }
                                >
                                    <option disabled selected value={undefined}>
                                        -- wybierz środowisko --
                                    </option>
                                    {ENVIRONMENT_NAME_VALUES.map((value) => (
                                        <option
                                            value={value.id}
                                            disabled={
                                                SOURCE_ENVIRONMENT() ===
                                                value.id
                                            }
                                        >
                                            {value.name}
                                            {SOURCE_ENVIRONMENT() ===
                                                value.id && ' (aktualny ENV)'}
                                        </option>
                                    ))}
                                </select>
                                <Button
                                    onClick={() =>
                                        handleMoveRestaurant(
                                            restaurantId,
                                            restaurantState?.value as Environment,
                                        )
                                    }
                                    disabled={!restaurantState?.value}
                                >
                                    Eksportuj
                                </Button>
                            </ExportWrapper>
                        );
                    }}
                />
                {hasMore && (
                    <ButtonShowMore onClick={() => getRestaurants()}>
                        Pokaż więcej
                    </ButtonShowMore>
                )}
            </ListWrapper>
        </React.Fragment>
    );
};

const ListWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 70%;
`;

const ButtonShowMore = styled(Button)`
    margin: 16px 0 32px;
`;

const ExportWrapper = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`;
