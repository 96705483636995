import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';
import { MOBILE } from '@constants/common.constants';
import { LoginDocument, useLoginWithGoogleMutation } from '@graphql/generated';
import { toast } from 'react-toastify';
import { useAuthService } from '@/services/AuthService';
import { popup } from '@/AppPopups';
import { delay } from 'lodash';

interface PreferencesCategories {
    preffered: string[];
    notPreffered: string[];
}

export const useLoginWithGoogle = () => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const code = params.get('code') || '';
    const {
        setTokens,
        setRestaurateurPrivacyPolicyPopup,
        setIsLoggedWithGoogle,
    } = useAuthService((state) => state);

    const client = useApolloClient();

    const [loginWithGoogle] = useLoginWithGoogleMutation();

    useEffect(() => {
        if (MOBILE) {
            GoogleAuth.initialize();
        }
    }, []);

    const handleLoginWithGoogle = async () => {
        try {
            const response: Partial<User> = MOBILE
                ? await GoogleAuth.signIn()
                : {};

            await loginWithGoogle({
                variables: {
                    input: {
                        accessToken: response.authentication?.accessToken,
                        code,
                    },
                },
                onCompleted: async ({
                    tokens: { refreshToken, sessionToken },
                }) => {
                    setTokens({
                        refreshToken,
                        sessionToken,
                    });

                    if (MOBILE) setIsLoggedWithGoogle(true);

                    history.push('/');
                    toast.dark('Witamy! 🖐 ');
                },
            });

            await client.resetStore();

            const { data } = await client.query({
                query: LoginDocument,
            });

            if (data) {
                const {
                    consents,
                    preferences: { __typename, spiciness, ...categories },
                } = data.user;

                const areCategoriesDefault = Object.values(
                    categories as Record<string, PreferencesCategories>,
                ).every((category: PreferencesCategories) => {
                    return (
                        !category.preffered.length &&
                        !category.notPreffered.length
                    );
                }) as boolean;

                const isSpicinessDefault =
                    spiciness.min === 0 && spiciness.max === 5;

                if (areCategoriesDefault && isSpicinessDefault) {
                    delay(() => popup.show('PreferencesPopup'), 500);
                }

                if (consents.restaurateurPrivacyPolicy) {
                    setRestaurateurPrivacyPolicyPopup(true);
                }
            }
        } catch (error) {
            console.error('handleLoginWithGoogle :: ', { error });
        }
    };

    return {
        handleLoginWithGoogle,
    };
};
