import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { InAppBrowser } from '@capgo/inappbrowser';
import { MOBILE } from '@constants/common.constants';
import { useAccountCoinsQuery } from '@graphql/generated';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { useEffect } from 'react';

export const useHandleMobileTransaction = (
    onBrowserCloseCallback?: () => void,
) => {
    const { data, previousData, refetch } = useAccountCoinsQuery({
        onError: graphqlErrorHandler(true),
    });

    const openBrowser = (url: string) => {
        InAppBrowser.openWebView({ url, title: '' });
    };

    const handleBrowserClose = async () => {
        if (
            data?.user.coinsAvailable !== previousData?.user.coinsAvailable ||
            data?.user.coinsBlocked !== previousData?.user.coinsBlocked
        ) {
            AnalyticsHelpers.createCustomEvent({
                name: 'new_payment',
                params: { type: 'payment' },
            });
            onBrowserCloseCallback?.();
        }
    };

    useEffect(() => {
        if (!MOBILE) return;

        InAppBrowser.addListener('urlChangeEvent', async (browserData) => {
            if (browserData.url.includes('eatzon')) {
                try {
                    await InAppBrowser.close();
                    await refetch();
                    await handleBrowserClose();
                } catch (error) {
                    console.error('handle transaction error:: ', error);
                }
            }
        });

        InAppBrowser.addListener('closeEvent', async () => {
            try {
                await refetch();
                await handleBrowserClose();
            } catch (error) {
                console.error('handle transaction error:: ', error);
            }
        });

        InAppBrowser.openWebView;

        return () => {
            InAppBrowser.removeAllListeners();
        };
    }, [data, previousData]);

    return {
        openBrowser,
    };
};
