import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import Headline from '@components/Headline/Headline';
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { colors, globals } from '@style/index';
import { SearchInput } from '@/componentsV2/molecules/SearchInput/SearchInput';
import { FilterButton } from '@/componentsV2/atoms/FilterButton/FilterButton';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { ButtonOrderingMethod } from '@/componentsV2/molecules/ButtonOrderingMethod/ButtonOrderingMethod';
import {
    CONSUMPTION_TYPE_NAME_VALUES,
    CONSUMPTION_TYPE,
} from '@constants/fromGraphql/ConsumptionType.constants';
import { Switch } from '@/componentsV2/atoms/Switch/Switch';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LastBrowsingCarousel } from '@/EZC/domains/Search/organisms/LastBrowsingCarousel/LastBrowsingCarousel';
import { RecentlySearchedList } from '@/componentsV2/molecules/RecentlySearchedList/RecentlySearchedList';
import {
    SearchAutoComplete,
    SearchAutoCompleteProps,
} from '@/EZC/domains/Search/molecules/SearchAutoComplete/SearchAutoComplete';
import { css } from '@emotion/react';
import { SearchResults } from '@/EZC/domains/Search/organisms/SearchResults/SearchResults';
import {
    SearchTabs,
    TabValueType,
} from '@/EZC/domains/Search/molecules/SearchTabs/SearchTabs';
import { SearchEmptyResults } from '@/EZC/domains/Search/molecules/SearchEmptyResults/SearchEmptyResults';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSearchPage } from './useSearchPage';
import {
    SearchFilterFormProvider,
    SearchFilterFormSchemaValues,
    useSearchFilterFormSubmit,
} from '@/EZC/domains/Search/SearchFilterFormProvider/SearchFilterFormProvider';
import { useController, useFormContext } from 'react-hook-form';
import { SearchFilterChips } from '@/EZC/domains/Search/molecules/SearchFilterChips/SearchFilterChips';
import { useAuthService } from '@/services/AuthService';
import { xor } from 'lodash';
import { SearchFilterPanel } from '@/EZC/domains/Search/organisms/SearchFilterPanel/SearchFilterPanel';
import { mediaQueries } from '@style/breakpoints';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { ChipVariant } from '@/componentsV2/atoms/Chip/Chip';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';
import { InfoShareLogo } from '@/svgs/InfoShareLogo';

export const SearchPage = () => {
    return (
        <SearchFilterFormProvider>
            <SearchPageInner />
        </SearchFilterFormProvider>
    );
};

type SearchPageState =
    | 'default'
    | 'focus'
    | 'autocomplete'
    | 'filled'
    | 'empty'
    | 'loading';

export const SearchPageInner = () => {
    const methods = useFormContext<SearchFilterFormSchemaValues>();
    const { handleFormSubmit } = useSearchFilterFormSubmit();
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    const {
        localizedBanner,
        localizedBannerEnabled,
        updateServerConfig,
        initialized,
    } = useServerConfigStoreShallow([
        'localizedBanner',
        'localizedBannerEnabled',
        'updateServerConfig',
        'initialized',
    ]);

    const history = useHistory();
    const { search } = useLocation();
    const [activeTabs, setActiveTabs] = useState<TabValueType>('1');

    const [filteredIngredients, setFilteredIngredients] = useState<
        SearchAutoCompleteProps['filteredIngredients']
    >([]);

    const [lastEvent, setLastEvent] = useState<'chipsClick' | null>(null);

    const [state, setState] = useState<SearchPageState>('default');

    const {
        filtersCount,
        getData,
        getProductsData,
        getRestaurantsData,
        handleOpenModal,
        loading,
        productsQuery,
        restaurantsQuery,
    } = useSearchPage();

    const { field: queryField } = useController({
        name: 'query',
        control: methods.control,
    });

    const { field: applyUserPreferencesField } = useController({
        name: 'applyUserPreferences',
        control: methods.control,
    });

    const { field: showBrochuresField } = useController({
        name: 'showBrochures',
        control: methods.control,
    });

    const { field: consumptionTypesField } = useController({
        name: 'consumptionTypes',
        control: methods.control,
    });

    const [isFocused, setIsFocused] = useState(false);
    const query = queryField.value;

    const restaurants = restaurantsQuery.data?.restaurantList?.data;
    const restaurantsMetadata = restaurantsQuery.data?.restaurantList?.metadata;
    const products = productsQuery.data?.productList?.data;
    const productsMetadata = productsQuery.data?.productList?.metadata;

    const { top } = useSafeAreaInsets();

    useEffect(() => {
        if (loading || !initialized) setState('loading');
        else if (
            search &&
            !products?.length &&
            !restaurants?.length &&
            !isFocused
        )
            setState('empty');
        else if (search && !isFocused) setState('filled');
        else if (query) setState('autocomplete');
        else if (isFocused) setState('focus');
        else setState('default');
    }, [isFocused, query, search, loading, products, restaurants, initialized]);

    useEffect(() => {
        setFilteredIngredients([
            ...methods
                .getValues('ingredients')
                .map((id) => ({ id, variant: 'add' as ChipVariant })),
            ...methods
                .getValues('notPreffered')
                .map((id) => ({ id, variant: 'remove' as ChipVariant })),
        ]);
    }, [filtersCount]);

    return (
        <Container paddingTop={top}>
            {/* @ts-expect-error migrate to TS */}
            <TopNav title={''} extraCssOuter={{ boxShadow: 'none' }} disable />
            <ArrowWrapper>
                <ArrowBackIcon
                    onClick={() => {
                        history.goBack();
                    }}
                    fontSize="inherit"
                />
            </ArrowWrapper>
            <ChangingFocusBackground onClick={() => setIsFocused(false)} />
            <PageContainer isDesktop={isDesktop}>
                <PageWrapper>
                    {/* @ts-expect-error migrate to TS */}
                    <SearchHeadline>Szukaj</SearchHeadline>
                    <SearchWrapper isDesktop={isDesktop} top={top}>
                        <SearchInput
                            {...queryField}
                            onFocus={() => setIsFocused(true)}
                            placeholder="Na co masz ochotę?"
                            onSubmit={() => {
                                lastEvent === 'chipsClick' &&
                                    methods.setValue('query', '');

                                methods.setValue(
                                    'ingredients',
                                    filteredIngredients
                                        .filter(
                                            (ingridient) =>
                                                ingridient.variant === 'add',
                                        )
                                        .map((ingridient) => ingridient.id),
                                );

                                methods.setValue(
                                    'notPreffered',
                                    filteredIngredients
                                        .filter(
                                            (ingridient) =>
                                                ingridient.variant === 'remove',
                                        )
                                        .map((ingridient) => ingridient.id),
                                );
                                handleFormSubmit();
                                setIsFocused(false);
                            }}
                            onReset={() => {
                                queryField.onChange('');
                                handleFormSubmit();
                            }}
                            onChange={(event) => {
                                queryField.onChange(event);

                                !!lastEvent && setLastEvent(null);
                            }}
                            inputRef={queryField.ref}
                        />
                        {!isDesktop && (
                            <FilterButton
                                onClick={handleOpenModal}
                                count={filtersCount}
                            />
                        )}
                    </SearchWrapper>
                    <TypeWrapper>
                        {CONSUMPTION_TYPE_NAME_VALUES.map((item) => {
                            const active = consumptionTypesField.value.includes(
                                item.id,
                            );
                            const disabled =
                                active &&
                                consumptionTypesField.value.length <= 1;

                            return (
                                <ButtonOrderingMethod
                                    key={item.id}
                                    active={active}
                                    disabled={
                                        active &&
                                        consumptionTypesField.value.length <= 1
                                    }
                                    icon={
                                        CONSUMPTION_TYPE[item.id].iconSecondary
                                    }
                                    onClick={() => {
                                        if (!disabled) {
                                            consumptionTypesField.onChange(
                                                xor(
                                                    consumptionTypesField.value,
                                                    [item.id],
                                                ),
                                            );
                                            getData({ initialPage: true });
                                        }
                                    }}
                                    text={CONSUMPTION_TYPE[
                                        item.id
                                    ].name.toLocaleLowerCase()}
                                    variant={item.id}
                                />
                            );
                        })}
                    </TypeWrapper>
                    <SwitchWrapper>
                        {isLoggedIn && (
                            <SwitchItem>
                                <Switch
                                    {...applyUserPreferencesField}
                                    checked={applyUserPreferencesField.value}
                                    onChange={(event) => {
                                        applyUserPreferencesField.onChange(
                                            event,
                                        );
                                        getData({ initialPage: true });
                                    }}
                                />
                                <span>
                                    Uwzględnij moje{' '}
                                    <Link
                                        to={`/account/food-preferences`}
                                        css={{
                                            color: colors.text,
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        preferencje żywieniowe
                                    </Link>
                                </span>
                            </SwitchItem>
                        )}
                        <SwitchItem>
                            <Switch
                                {...showBrochuresField}
                                checked={showBrochuresField.value}
                                onChange={(event) => {
                                    showBrochuresField.onChange(event);
                                    getData({ initialPage: true });
                                }}
                            />
                            Pokaż wszystkie dania i restauracje
                        </SwitchItem>
                        {localizedBanner && (
                            <SwitchItem>
                                <Switch
                                    checked={localizedBannerEnabled}
                                    onChange={() => {
                                        updateServerConfig({
                                            localizedBannerEnabled:
                                                !localizedBannerEnabled,
                                        });
                                    }}
                                />
                                <InfoShareLabel>
                                    Strefa Gastro
                                    <InfoShareLogo />
                                </InfoShareLabel>
                            </SwitchItem>
                        )}
                    </SwitchWrapper>
                    {!!filtersCount && (
                        <Wrapper>
                            <SearchFilterChips />
                        </Wrapper>
                    )}
                    {state === 'default' && (
                        <Wrapper padding={!isLoggedIn}>
                            <LastBrowsingCarousel />
                        </Wrapper>
                    )}
                    {state === 'focus' && (
                        <Wrapper>
                            <RecentlySearchedList
                                onClick={(value) => {
                                    queryField.onChange(value);
                                    methods.setFocus('query');
                                }}
                            />
                        </Wrapper>
                    )}
                    {state === 'autocomplete' && (
                        <Wrapper>
                            <SearchAutoComplete
                                applyUserPreferences={
                                    applyUserPreferencesField.value
                                }
                                showBrochures={showBrochuresField.value}
                                query={query}
                                filteredIngredients={filteredIngredients}
                                setFilteredIngredients={setFilteredIngredients}
                                onChipClick={() => setLastEvent('chipsClick')}
                            />
                        </Wrapper>
                    )}
                    {(state === 'filled' || state === 'loading') && (
                        <TabsWrapper top={top}>
                            <SearchTabs
                                onClick={setActiveTabs}
                                tabs={[
                                    {
                                        id: '1',
                                        name: 'Dania',
                                        count:
                                            productsMetadata?.totalCount ?? 0,
                                    },
                                    {
                                        id: '2',
                                        name: 'Restauracje',
                                        count:
                                            restaurantsMetadata?.totalCount ??
                                            0,
                                    },
                                ]}
                                value={activeTabs}
                                loading={state === 'loading'}
                            />
                        </TabsWrapper>
                    )}
                    {(state === 'filled' || state === 'loading') && (
                        <Wrapper>
                            <SearchResults
                                restaurants={{
                                    data: restaurants?.map((item) => ({
                                        address: item.address,
                                        avatarUrl: item.avatarUrl,
                                        description: item.description,
                                        name: item.name,
                                        photoUrl: item.photoUrl,
                                        distance: item.distance,
                                        isActive: item.isActive,
                                        isBrochure: item.isBrochure,
                                        slug: item.slug,
                                    })),
                                    hasNextPage:
                                        (restaurantsMetadata?.currentPage ??
                                            0) <
                                        (restaurantsMetadata?.totalPages ?? 0),
                                    loading: state === 'loading',
                                    onLoadMore: getRestaurantsData,
                                    disabled: !!restaurantsQuery.error,
                                }}
                                products={{
                                    data: products?.map((item) => ({
                                        accuracy: item?.accuracy,
                                        distance: item.distance,
                                        id: item.id,
                                        ingredients: item.ingredients,
                                        name: item.name,
                                        photoUrl: item.photoUrl,
                                        price: item.price,
                                        rating: item.rating,
                                        ratingsCount: item.ratingsCount,
                                        isBrochure: item.restaurant?.isBrochure,
                                    })),
                                    hasNextPage:
                                        (productsMetadata?.currentPage ?? 0) <
                                        (productsMetadata?.totalPages ?? 0),
                                    loading: state === 'loading',
                                    onLoadMore: getProductsData,
                                    disabled: !!productsQuery.error,
                                }}
                                activeTabs={activeTabs}
                                isDesktop={isDesktop}
                            />
                        </Wrapper>
                    )}
                    {state === 'empty' && (
                        <EmptyResultsWrapper>
                            <SearchEmptyResults
                                onClick={handleOpenModal}
                                isDesktop={isDesktop}
                            />
                        </EmptyResultsWrapper>
                    )}
                </PageWrapper>
                {isDesktop && (
                    <SearchFilterPanelWrapper>
                        <SearchFilterPanel
                            onLocalizationChange={() =>
                                getData({ initialPage: true })
                            }
                        />
                    </SearchFilterPanelWrapper>
                )}
            </PageContainer>
        </Container>
    );
};

const SearchHeadline = styled(Headline)`
    margin-bottom: 40px;
    padding: 0 0 24px;
    position: relative;
    z-index: 11;

    ${mediaQueries.sm} {
        margin-bottom: 24px;
        padding: 0 24px;
    }
`;

const Container = styled.div<{ paddingTop: number }>(
    ({ paddingTop }) => css`
        max-width: ${globals.maxWidthMedium}px;
        margin: 0 auto;
        padding: 0 120px;

        ${mediaQueries.md} {
            padding: 0 40px;
        }

        ${mediaQueries.sm} {
            padding: 0;
            padding-top: ${paddingTop - 15}px;
        }
    `,
);

const PageWrapper = styled(Grid)`
    padding: 0 0 48px;
    position: relative;
    margin-top: 40px;
    width: 100%;
`;

const SearchFilterPanelWrapper = styled.div`
    padding-top: 40px;
`;

const ChangingFocusBackground = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 0;
`;

const SearchWrapper = styled(Grid)<{ isDesktop: boolean; top: number }>(
    ({ isDesktop, top }) => css`
        align-items: center;
        background-color: ${colors.white};
        display: grid;
        gap: 16px;
        grid-template-columns: ${isDesktop ? '1fr' : '1fr 36px'};
        padding: 24px 0 20px;
        margin-top: -24px;
        position: sticky;
        top: 0;
        z-index: 10;

        ${mediaQueries.sm} {
            padding: ${top ? `${top}px 24px 20px` : '24px 24px 20px'};
            margin-top: -${top}px;
        }
    `,
);

const PageContainer = styled.div<{ isDesktop: boolean }>(
    ({ isDesktop }) => css`
        gap: 40px;
        display: grid;
        grid-template-columns: 1fr 368px;

        ${!isDesktop &&
        css`
            grid-template-columns: 1fr;
            display: block;
        `}
    `,
);

const ArrowWrapper = styled.div`
    cursor: pointer;
    font-size: 30px;
    padding: 24px 0 0;
    position: relative;
    z-index: 99;

    ${mediaQueries.sm} {
        padding: 24px 24px 0;
    }
`;

const TabsWrapper = styled.div<{ top: number }>(
    ({ top }) => css`
        background: ${colors.white};
        padding: 0 0 24px;
        position: sticky;
        top: 90px; /* Space for search input on the desktop */
        z-index: 10;

        ${mediaQueries.sm} {
            padding: 0 24px 24px;
            top: ${top
                ? `${60 + top}px`
                : '90px'}; /* Space for search input on mobile */
        }
    `,
);

const TypeWrapper = styled(Grid)`
    display: flex;
    gap: 12px;
    padding: 0;
    /* FIXME: find better solution */
    padding-top: 1px;

    ${mediaQueries.sm} {
        padding: 0 24px;
    }
`;

const SwitchWrapper = styled(Grid)`
    display: grid;
    gap: 16px;
    margin: 24px 0;
    z-index: 1;
    position: relative;

    ${mediaQueries.sm} {
        margin: 24px;
    }
`;

const SwitchItem = styled.p`
    display: flex;
    gap: 8px;
    font-size: 13px;
    line-height: 20px;
`;

const Wrapper = styled.div<{ padding?: boolean }>(
    ({ padding }) => css`
        display: grid;
        padding: ${padding ? '24px 0' : '0 0 24px'};

        ${mediaQueries.sm} {
            display: block;
            padding: 0 24px 24px;
        }
    `,
);

const EmptyResultsWrapper = styled.div`
    display: block;
    padding: 0 0 24px;

    ${mediaQueries.sm} {
        padding: 0 24px 24px;
    }
`;

const InfoShareLabel = styled.span`
    align-items: baseline;
    display: flex;
    gap: 5px;
`;
