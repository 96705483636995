import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import styled from '@emotion/styled';
import { ScannerIcon } from '../../../../svgs/ScannerIcon';
import { colors } from '@/style';
import TopNavFixed from '@components/TopNavFixed/TopNavFixed';
import { useTopNavContext } from '@context/topnav.context';

export const QrCodeScanner = () => {
    const { setData } = useTopNavContext();

    useEffect(() => {
        //@ts-expect-error migrate to ts
        setData({ title: 'QR kod skaner' });
    }, []);

    return (
        <ScannerWrapper>
            <TopNavFixed />
            <ScannerIcon />
            <Text>Skieruj kamerę na QR kod</Text>
        </ScannerWrapper>
    );
};

const ScannerWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
`;

const Text = styled.p`
    color: ${colors.white};
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 16px;
`;
