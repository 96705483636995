import React from 'react'; // eslint-disable-line no-unused-vars
import { MenuItem } from '../../Account.page';

const SettingsDeleteAccount = () => {
    return (
        <MenuItem
            id={2}
            to={`/account/delete`}
            name={'Usuń konto'}
            description={`Usunięcie konta`}
            AvatarIcon={deleteUserIcon}
            badgeContent={undefined}
            showBadge={false}
            action={undefined}
            smallIcon
        />
    );
};

export default SettingsDeleteAccount;

const deleteUserIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            width="38"
            height="38"
            viewBox="0 0 38 38"
        >
            <circle cx={19} cy={19} r={19} fill="#D3D7E0" />
            <g fill="#343340" clipPath="url(#clip0_162_3685)">
                <path d="M17 19a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM17.35 21.01C14.62 20.91 9 22.27 9 25v2h9.54c-2.47-2.76-1.23-5.89-1.19-5.99Z" />
                <path
                    stroke="#343340"
                    d="m24.597 23.394 1.775 1.767-.711.711-1.767-1.775-.354-.356-.354.356-1.767 1.775-.711-.711 1.775-1.767.356-.354-.356-.354-1.775-1.767.711-.711 1.767 1.775.354.356.354-.356 1.767-1.775.711.711-1.775 1.767-.356.354.356.354Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_162_3685">
                    <rect
                        width={24}
                        height={24}
                        x={7}
                        y={7}
                        fill="#fff"
                        rx={4}
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
