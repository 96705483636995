import React from 'react';
import { memo, useEffect } from 'react';
import { useUserInAppNotification } from './useUserInAppNotification';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    UserPollingServiceQuery,
    useMarkAsReadMutation,
} from '@graphql/generated';
import { useRatingPopupHandler } from '@/EZC/services/UserInAppNotificationService/useRatingPopupHandler';
import { popup } from '@/AppPopups';
import { MOBILE } from '@constants/common.constants';

interface UserInAppNotificationProviderProps {
    notifications: UserPollingServiceQuery['notifications']['data'];
}

export const UserInAppNotificationProvider = memo(
    ({ notifications }: UserInAppNotificationProviderProps) => {
        const { handleRatingPopup, productList, setProductList } =
            useRatingPopupHandler();
        const { NOTIFICATION_TYPE } = useUserInAppNotification();

        const [markAsRead] = useMarkAsReadMutation();

        useEffect(() => {
            notifications.map((notification) => {
                const { id, wasRead, type } = notification;

                if (!wasRead) {
                    if (!MOBILE) {
                        NOTIFICATION_TYPE[type]?.toast(notification);
                    }

                    handleRatingPopup(notification);

                    markAsRead({
                        variables: {
                            input: {
                                inAppNotificationId: id,
                            },
                        },
                        onError: graphqlErrorHandler(true),
                    });
                }
            });
        }, [notifications]);

        if (!!productList.length) {
            popup.show('ProductRatingPopup', {
                onCloseModal: () => setProductList([]),
                productList,
            });
        }

        return null;
    },
);
