import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style/index';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { CartConsumptionDetails } from '@graphql/generated';
import { Bullet } from '@components/Elements';
import { dateFormat } from '@helpers/dateFormat';
import PersonIcon from '@material-ui/icons/Person';
import InputFields from '@/EZC/pages/cart/components/InputFields.component';
import EventIcon from '@material-ui/icons/Event';

export interface SelectDateProps {
    consumptionDetails: CartConsumptionDetails;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    additionalDescription?: string;
}

export const SelectDate = ({
    consumptionDetails,
    onChange,
    additionalDescription,
}: SelectDateProps) => {
    const newConsumptionDate = dateFormat.standardDate(
        consumptionDetails?.date,
    );

    const newConsumptionTime = dateFormat.standardTime(
        consumptionDetails?.date,
    );
    return (
        <Accordion
            collapsedPadding="0px 0px"
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <EventIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>Termin</Title>
                        <Description>
                            <DescriptionInner>
                                {dateFormat.orderDate(newConsumptionDate)}
                                <Bullet />
                                {newConsumptionTime}
                                <Bullet />
                                {consumptionDetails.guests === 10
                                    ? '10+'
                                    : consumptionDetails?.guests}
                                <StyledPersonIcon fontSize="inherit" />
                            </DescriptionInner>
                        </Description>
                    </InfoWrapper>
                </ContentWrapper>
            }
        >
            <Wrapper>
                {additionalDescription && (
                    <AdditionalDescriptionWrapper>
                        {additionalDescription}
                    </AdditionalDescriptionWrapper>
                )}
                <InputFields
                    onChange={onChange}
                    consumptionDetails={{
                        consumptionDate: newConsumptionDate,
                        consumptionPersons: consumptionDetails.guests,
                        consumptionTime: newConsumptionTime,
                    }}
                />
            </Wrapper>
        </Accordion>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const DescriptionInner = styled.div`
    overflow-x: scroll;
    display: flex;
    align-items: center;
`;

const AdditionalDescriptionWrapper = styled(Description)`
    padding: 24px 24px 0;
`;

const StyledPersonIcon = styled(PersonIcon)`
    margin-left: 3px;
    margin-top: -2px;
`;
