/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { s, colors, alpha } from '../../../../style';

export default ({ condition, text, text2, theme }) =>
    condition && (
        <div
            css={[
                sStyle,
                theme === 'error' && {
                    color: colors.red600,
                    backgroundColor: colors.red.concat(alpha[8]),
                },
                theme === 'success' && {
                    color: colors.green,
                    backgroundColor: colors.green400.concat(alpha[8]),
                },
                theme === 'warning' && {
                    color: colors.orange200,
                    backgroundColor: colors.green200.concat(alpha[8]),
                },
                theme === 'info' && {
                    color: colors.blue,
                    backgroundColor: colors.blue.concat(alpha[8]),
                },
            ]}
        >
            {text} {text2}
        </div>
    );

const sStyle = {
    display: 'flex',
    alignItems: 'center',
    height: 87,
    fontWeight: 700,
    fontSize: '11px',
    backgroundColor: colors.gray300,
    // color: colors.text.concat(alpha[60]),
    // textAlign: 'center',
    [s.sm_down]: { padding: '1rem 1.5rem' },
    [s.md]: { padding: '1.5rem 3.5rem', fontSize: '13px' },
};
