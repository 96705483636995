/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Badge, Collapsable } from '../../../../components/Elements';
import { PaymentRequestIcon } from '@/svgs/PaymentRequestIcon';
import { colors } from '@style/index';

export const WalletPaymentRequests = ({ showBadge }) => {
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                title={'Prośby o rozliczenie'}
                description={`Sprawdź prośby o rozliczenie`}
                icon={<PaymentRequestIcon />}
                link={`/account/payment-requests`}
                left
                noPadding
                oneLiner
                badge={
                    showBadge && (
                        <Badge color={colors.orange} show showAnimation small />
                    )
                }
            ></Collapsable>
        </Grid>
    );
};
