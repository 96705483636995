import React from 'react';
import RestContainer from '../../../EZC/views/ProductPage/components/RestContainer';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { Grid } from '@material-ui/core';
import { Button, ButtonBorder } from '../../../components/Elements';
import {
    sContainerOuter,
    sGrid,
    sOuter,
    sPaddingTop,
} from '../../../style/style';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import NotificationsIcon from '@material-ui/icons/Notifications';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import HistoryIcon from '@material-ui/icons/History';
import PersonIcon from '@material-ui/icons/Person';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Headline from '../../../components/Headline/Headline';
import { colors, globals, s } from '../../../style';
import { MenuItem } from '../../../EZC/pages/account/Account.page';
import { useHomePageQuery } from '@graphql/generated';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { client } from '@/boot/client';
import { sentry } from '@/services/SentryService';
import { useAuthService } from '@/services/AuthService';
import { useQrCodeScanner } from '@hooks/useQrCodeScanner';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { ButtonBorder as ButtonBorderV2 } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { QrCodeIcon } from '@/svgs/QrCodeIcon';
import { MOBILE } from '@constants/common.constants';

const HomePage = () => {
    const { logout, restaurantId, setWaiterView } = useWaiterService();
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    const { data } = useHomePageQuery({
        skip: !restaurantId,
        variables: { input: { restaurantId } },
    });

    const itemsData = [
        {
            to: '/waiter/notifications',
            name: 'Aktywne',
            description: 'Zamówienia wymagające uwagi',
            AvatarIcon: NotificationsIcon,
        },
        {
            to: '/waiter/orders',
            name: 'Zamówienia',
            description: 'Zamówienia realizowane',
            AvatarIcon: LocalDiningIcon,
        },
        {
            to: '/waiter/new-order',
            name: 'Nowe zamówienie',
            description: 'Stwórz nowe zamówienie',
            AvatarIcon: AddCircleIcon,
        },
        {
            to: '/waiter/history',
            name: 'Historia',
            description: 'Zamówienia zakończone',
            AvatarIcon: HistoryIcon,
        },
        {
            to: '/rest',
            name: 'Panel Restauratora',
            AvatarIcon: RestaurantIcon,
            onClick: () => setWaiterView(isLoggedIn),
        },
        {
            to: '/account',
            name: 'Panel Klienta',
            AvatarIcon: PersonIcon,
            onClick: () => setWaiterView(isLoggedIn),
        },
    ];

    const history = useHistory();

    const { startScanning } = useQrCodeScanner((orderId) =>
        history.push(`/waiter/order/${orderId}`),
    );

    return (
        <React.Fragment>
            <TopNav title={'Kelner'} offset={60}>
                {MOBILE && (
                    <QrButtonWrapper>
                        <ButtonBorderV2 onClick={startScanning}>
                            <ButtonTextWrapper>
                                <QrCodeIcon /> QR kod skaner
                            </ButtonTextWrapper>
                        </ButtonBorderV2>
                    </QrButtonWrapper>
                )}
            </TopNav>
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder action={() => logout(client, sentry)}>
                            Wyloguj
                        </ButtonBorder>
                    }
                >
                    Kelner
                </Headline>
            </div>
            {data ? (
                <RestContainer data={data?.restaurant} />
            ) : (
                <div css={sRestSkeleton}>
                    <div css={sRestSkeletonInner} />
                </div>
            )}
            <div
                css={[
                    sOuter,
                    { marginTop: '2rem', [s.sm]: { marginTop: '2rem' } },
                ]}
            >
                <Grid container css={sGrid}>
                    {itemsData.map((item, id) => (
                        <MenuItem key={id} id={id} {...item} />
                    ))}
                    <MenuItem
                        id={itemsData.length}
                        name="Wyloguj"
                        AvatarIcon={MeetingRoomIcon}
                        action={() => logout(client, sentry)}
                    />
                </Grid>
            </div>
        </React.Fragment>
    );
};

const QrButtonWrapper = styled.div`
    min-width: 180px;
    display: flex;
    justify-content: flex-end;
`;

const ButtonTextWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 2px;
`;

export const sRestSkeletonInner = {
    backgroundColor: colors.gray200,
    borderRadius: 32,
    flexShrink: 0,
    width: '100%',
    height: '100%',
};

export const sRestSkeleton = {
    position: 'relative',
    top: 0,
    left: 0,
    maxWidth: globals.maxWidthMedium,
    height: 400,
    width: '100%',
    margin: '0 auto 1.5rem',
    [s.xs]: {
        width: 'calc(100vw - 48px)',
        height: 'calc(9 * calc(100vw - 48px) / 16)',
        borderRadius: 24,
    },
    [s.sm]: { padding: '1.5rem' },
    [s.md]: { padding: '1.5rem 2rem' },
};

export default HomePage;
