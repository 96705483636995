/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { sBottomLinks, sOuter, sGrid } from '../../../style/style';
import { ButtonBorder } from '../../../components/Elements';
import { s } from '../../../style';
import { Link } from 'react-router-dom';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { EuropeanFundsInfo } from '@/componentsV2/atoms/EuropeanFundsInfo/EuropeanFundsInfo';

const Terms = () => {
    return (
        <>
            <TopNav title={'Regulamin Platformy Eatzon'} />
            <div css={sOuter}>
                <Headline>Regulamin</Headline>
                <Grid container css={sGrid}>
                    <Doc />
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                    </Grid>
                    <EuropeanFundsInfo />
                </Grid>
            </div>
        </>
    );
};

export const sDoc = {
    fontSize: 14,
    // 'h2, h3, p, ol, li': {
    //   fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    //   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //   sans-serif`,
    // },
    'li::marker': {
        padding: '1rem',
    },
    'p, ol': {
        fontWeight: 400,
    },
    li: {
        marginLeft: '4rem',
        [s.sm_down]: { marginLeft: '2rem' },
        marginBottom: '0.5rem',
        listStyleType: 'decimal',
        ol: {
            marginTop: '0.5rem',
        },
        li: {
            listStyleType: 'lower-latin',
            li: {
                listStyleType: 'lower-roman',
            },
        },
    },
    h2: {
        marginTop: '4rem',
        marginBottom: '1rem',
        [s.sm_down]: { marginTop: '2rem' },
    },
    h3: {
        marginLeft: '3rem',
        [s.sm_down]: { marginLeft: '1rem' },
        marginTop: '2rem',
        marginBottom: '1rem',
    },
    p: {
        marginBottom: '1rem',
    },
    img: { margin: '0 auto', display: 'block' },
};

const Doc = () => {
    return (
        <div css={sDoc}>
            <h2>REGULAMIN PLATFORMY EATZON</h2>
            <p>
                Niniejszy Regulamin świadczenia usług drogą elektroniczną (dalej
                jako: „<strong>Regulamin</strong>”) określa warunki świadczenia
                usług za pośrednictwem serwisu internetowego Eatzon(dalej jako:
                „<strong>Platforma</strong>”).
            </p>
            <h2>I. DEFINICJE</h2>
            <p>
                Na potrzeby Regulaminu, przyjmuje się następujące znaczenie
                poniższych pojęć
            </p>
            <ol>
                <li>
                    <strong>Usługodawca</strong> - Eatzon Sp. z o.o. z siedzibą
                    w Elblągu 82-300 ul. Stanisława Sulimy 1/226, wpisana do
                    rejestru przedsiębiorców Krajowego Rejestru Sądowego
                    rejestru przedsiębiorców Krajowego Rejestru Sądowego przez
                    Sąd Rejonowy w Białymstoku, XII Wydział gospodarczy KRS:
                    0000857377, NIP:9462699743, REGON: 386876569, adres poczty
                    elektronicznej: office@eatzon.pl która stworzyła, rozwija i
                    zarządza Platformą Technologiczną, oraz dodatkowo w
                    stosownych przypadkach i na żądanie Użytkowników i klientów
                    korzystających z usług gastronomicznych i cateringowych
                    Restauratora pełni rolę pośrednika w dostawie dań i napojów.
                </li>
                <li>
                    <strong>Platforma Technologiczna</strong> - Aplikacja webowa
                    i mobilna Eatzon, strona www, i inne narzędzia i urządzenia
                    internetowe dostępne na stronie Usługodawcy eatzon.com
                    publikująca powszechnie dostępne informacje o lokalach
                    gastronomicznych, a także umożliwiająca zawieranie Umów
                    drogą elektroniczną pomiędzy Restauratorem a Użytkownikiem,
                    zarządzanie zawartymi Umowami, założenie i korzystanie z
                    funkcjonalności Konta Użytkownika.
                </li>
                <li>
                    <strong>Restaurator</strong> - Podmiot prowadzący
                    działalność gospodarczą w zakresie usług gastronomicznych i
                    cateringowych oraz handlowych związanych bezpośrednio z
                    żywnością, publikujący swoją Ofertę na Platformie w celu
                    umożliwienia Użytkownikom złożenia Zamówienia w celu
                    zawarcia umowy.
                </li>
                <li>
                    <strong>Użytkownik</strong> – konsument w rozumieniu art. 22
                    1 38 1 ustawy z dnia 26 kwietnia 1964 r. Kodeks cywilny (tj.
                    Dz.U. z 2020 r. poz. 1740 ze zm.) lub inny podmiot, który
                    korzysta z Platformy, a także przy użyciu Platformy złożył
                    Zamówienie w celu zawarcia umowy z Restauratorem
                </li>
                <li>
                    <strong>Konto Użytkownika</strong> - indywidualny dla
                    każdego Użytkownika panel, uruchomiony na jego rzecz i
                    żądanie z chwilą dokonania Rejestracji na Platformie; W
                    Koncie Użytkownika gromadzone są informacje na jego temat
                    oraz informacje o jego działaniach w ramach Platformy;
                </li>
                <li>
                    <strong>Portfel</strong> - funkcjonalność Konta Użytkownika
                    umożliwiająca dokonywanie płatności w Platformie.
                </li>
                <li>
                    <strong>Rejestracja</strong> – czynność faktyczna dokonana
                    przez Użytkownika w sposób określony w Regulaminie w celu
                    założenia Konta Użytkownika i złożenia Zamówienia.
                </li>
                <li>
                    <strong>Oferta</strong> - Wybór Produktów i usług, w tym
                    opcji Dostawy i Odbioru, prezentowanych przez poszczególnych
                    Restauratorów, które Użytkownik może zamówić korzystając z
                    Platformy, stanowiących ofertę w rozumieniu art. 66 Kodeksu
                    cywilnego
                </li>
                <li>
                    <strong>Produkt</strong> - posiłek w postaci produktu, dania
                    lub napoju znajdujący się w Ofercie Restauracji.
                </li>
                <li>
                    <strong>Odbiór</strong> - możliwy do wyboru przez
                    Użytkownika sposób dostarczenia Produktu określony w Ofercie
                    Restauratora.
                </li>
                <li>
                    <strong>Zamówienie</strong> – oświadczenie woli Użytkownika
                    składane Restauratorowi za pośrednictwem Platformy
                    stanowiące przyjęcie wybranej przez Użytkownika Oferty, a w
                    konsekwencji zawarcie Umowy. Umowa – oznacza stosunek prawny
                    zawarty pomiędzy Użytkownikiem a Restauratorem na podstawie
                    złożonego przez Użytkownika Zamówienia.
                </li>
                <li>
                    <strong>Koszyk</strong> – usługa polegająca na wyświetlaniu
                    listy wybranych przez Użytkownika cześci składowych Oferty
                    oraz szczegółów na temat warunków Umowy takich jak cena
                    jednostkowa i łączna zamówionych produktów i usług.
                </li>
                <li>
                    <strong>Konsument</strong> – Użytkownik będący osobą
                    fizyczną, zawierający z Restauratorem umowę niezwiązaną
                    bezpośrednio z jego działalnością gospodarczą lub zawodową;
                    postanowienia dotyczące Konsumenta mają zastosowanie
                    odpowiednio także do osoby fizycznej zawierającej z
                    Restauratorem umowę bezpośrednio związaną z jej
                    działalnością gospodarczą, gdy z treści tej umowy wynika, że
                    nie posiada ona dla tej osoby charakteru zawodowego,
                    wynikającego w szczególności z przedmiotu wykonywanej przez
                    nią działalności gospodarczej, udostępnionego na podstawie
                    przepisów o Centralnej Ewidencji i Informacji o Działalności
                    Gospodarczej.
                </li>
                <li>
                    <strong>
                        Systemy Płatności Online: bramka płatności PayNow
                    </strong>
                    - Płatności elektroniczne, w tym BLIK i płatności kartą
                    płatniczą. Podmiotem świadczącym obsługę płatności online w
                    zakresie płatności kartami jest Autopay S.A.{' '}
                    <img
                        src="https://static.paynow.pl/brand/mastercard_visa.gif"
                        alt="Mastercard and Visa"
                        width="123"
                        height="123"
                    />
                </li>
                <li>
                    <strong>Wizytówki</strong> - część Platformy
                    Technologicznej, w której Usługodawca publikuje powszechnie
                    dostępne informacje i dane dotyczące podmiotów prowadzących
                    działalność usługową związaną z wyżywieniem, w szczególności
                    restauracji i innych stałych i ruchomych placówek
                    gastronomicznych. Usługodawca przetwarza dane publikowane w
                    każdej z Wizytówek, w tym dane osobowe, w charakterze ich
                    administratora, wyłącznie w zakresie danych pochodzących ze
                    źródeł ogólnodostępnych, w tym rejestrów publicznych. Dane
                    te mają charakter wyłącznie informacyjny, mogą zawierać
                    omyłki, braki lub być nieaktualne, za co Usługodawca nie
                    ponosi odpowiedzialności. Publikowane w Wizytówkach
                    informacje nie stanowią oferty art. 66 Kodeksu cywilnego, a
                    także nie powinny być traktowane jako zachęta do
                    skorzystania lub nieskorzystania z usług danego
                    przedsiębiorcy.
                </li>
            </ol>

            <h2>II. POSTANOWIENIA OGÓLNE</h2>
            <ol>
                <li>
                    Niniejszy Regulamin określa zasady dostępu i korzystania z
                    Platformy. Warunkiem korzystania z Platformy jest uprzednie
                    zapoznanie się z postanowieniami Regulaminu i ich
                    akceptacja.
                </li>
                <li>
                    Niniejszy Regulamin ma zastosowanie wyłącznie do Usług
                    świadczonych przez Usługodawcę w zakresie pośrednictwa w
                    zawarciu Umowy pomiędzy Użytkownikiem a Restauratorem z
                    wykorzystaniem Platformy. Usługodawca informuje, iż
                    korzystanie z Platformy przez Użytkownika i Restauratora
                    jest usługą świadczoną drogą elektroniczną, w rozumieniu
                    ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
                    elektroniczną (Dz.U. z 2002 r., Nr 144, poz. 1204, z późn.
                    zm.) [dalej: u.ś.u.e], które to odbywa się podstawie
                    niniejszego Regulaminu. Regulamin określa w szczególności
                    zasady świadczenia Usług oferowanych na Platformie, ich
                    zakres oraz zasady korzystania z nich.
                </li>
                <li>
                    Usługodawca nigdy nie jest stroną Umowy, a jedynie
                    pośredniczy w jej zawarciu. Restaurator jest w pełni
                    odpowiedzialny za realizację Umowy. Usługodawca nie
                    odpowiada natomiast za niewykonywanie lub nienależyte
                    wykonanie Umowy, gdyż nie jest jej stroną.
                </li>
                <li>
                    Usługodawca udostępnia Platformę, za pośrednictwem której
                    Użytkownik ma możliwość zamówienia Produktów wchodzących w
                    skład Oferty Restauracji.
                </li>
                <li>
                    Minimalne warunki techniczne umożliwiające korzystanie z
                    Platformy to:
                    <ol>
                        <li>
                            Dostęp do urządzenia podłączonego do sieci Internet,
                        </li>
                        <li>Zainstalowany i aktualny system operacyjny,</li>
                        <li>
                            Przeglądarka internetowa umożliwiająca wyświetlanie
                            dokumentów HTML,
                        </li>
                        <li>
                            Włączona opcja akceptowania plików cookies w
                            przypadku wybranej usługi,
                        </li>
                        <li>Możliwość instalowania aplikacji zewnętrznych,</li>
                        <li>
                            Posiadanie aktywnego konta poczty elektronicznej
                            (adres e-mail).
                        </li>
                    </ol>
                </li>
                <li>
                    Usługodawca dołoży wszelkich starań, aby Platforma była
                    dostępna w sposób ciągły bez zakłóceń.
                </li>
                <li>
                    Usługodawca zastrzega sobie prawo do:
                    <ol>
                        <li>
                            okresowego wyłączania dostępności Platformy
                            Technologicznej w celu jej rozbudowy, konserwacji
                            lub przeprowadzenia działań naprawczych,
                        </li>
                        <li>
                            sporadycznych i krótkich przerw w dostępie do
                            Platformy Technologicznej bez podania przyczyny.
                        </li>
                    </ol>
                </li>
                <li>
                    Dla prawidłowego działania Platforma wykorzystuje dane i
                    funkcjonalności urządzenia mobilnego, na którym jest
                    zainstalowana. Przy instalacji Platformy lub przy jej
                    pierwszym użyciu Użytkownik zostanie poproszony o wyrażenie
                    zgody na niezbędny do prawidłowego działania Platformy
                    dostęp do:
                    <ol>
                        <li>dane o lokalizacji,</li>
                    </ol>
                </li>
                <li>
                    Fakultatywnie, w celu korzystania z dodatkowych
                    funkcjonalności Platformy, Użytkownik może wyrazić także
                    zgodę na dostęp Platformy do nieobligatoryjnych danych
                    takich jak:
                    <ol>
                        <li>dostęp do galerii,</li>
                        <li>dostęp do aparatu,</li>
                        <li>dostęp do mikrofonu,</li>
                        <li>dostęp do kontaktów,</li>
                        <li>dostęp do preferencji przeglądarki użytkownika.</li>
                    </ol>
                </li>
                <li>
                    Użytkownik jest obowiązany do korzystania z Platformy w
                    sposób zgodny z prawem i dobrymi obyczajami, mając na uwadze
                    poszanowanie dóbr osobistych oraz praw autorskich i praw
                    własności intelektualnej Usługodawcy i osób trzecich.
                </li>
                <li>
                    Usługodawca oświadcza, że korzystanie z usług świadczonych
                    drogą elektroniczną może się wiązać z zagrożeniem utraty
                    poufności, dostępności i integralności danych Użytkownika w
                    związku z nieuprawnionym działaniem osób trzecich. Z tego
                    względu Użytkownicy i Restauratorzy powinni stosować
                    właściwe środki techniczne i organizacyjne mające na celu
                    minimalizację zagrożeń, w szczególności programy
                    antywirusowe oraz zasadę nieujawniania osobom postronnym
                    hasła do Konta Użytkownika/Restauratora.
                </li>
            </ol>

            <h2>III. ZAKRES ŚWIADCZONYCH USŁUG</h2>
            <ol>
                <li>
                    Usługodawca udostępnia Platformę, za pośrednictwem której
                    prezentuje w imieniu i na rzecz Restauratora jego Ofertę w
                    celu umożliwienia Użytkownikom jej przeglądania, złożenie
                    Zamówienia i zawarcie Umowy.
                </li>
                <li>
                    Usługodawca zapewnia Użytkownikom możliwość przeglądania
                    powszechnie dostępnych informacji dotyczących podmiotów
                    prowadzących działalność usługową związaną z wyżywieniem, w
                    szczególności restauracji i innych stałych i ruchomych
                    placówek gastronomicznych, w formie Wizytówek.
                </li>
                <li>
                    Usługodawca pełni rolę pośrednika w zawarciu Umowy pomiędzy
                    Użytkownikiem a Restauratorem z wykorzystaniem Platformy.
                </li>
                <li>
                    Restaurator jest zobowiązany do umieszczenia w Ofercie
                    wszelkich wymaganych właściwymi przepisami prawa informacji
                    o składnikach i alergenach produktów wchodzących w skład
                    jego Oferty. Restaurator ponosi wyłączną odpowiedzialność za
                    informacje umieszczone w Ofercie, w szczególności za ich
                    zgodność ze stanem faktycznym.
                </li>
                <li>
                    Platforma umożliwia Użytkownikowi wybór opcji Odbioru
                    złożonego Zamówienia zgodnie z Ofertą Restauratora.
                </li>
                <li>
                    Usługodawca udostępnia Użytkownikom usługę prowadzenia Konta
                    Użytkownika na zasadach określonych w punkcie IV Regulaminu.
                </li>
            </ol>

            <h2>IV. KONTO UŻYTKOWNIKA</h2>
            <ol>
                <li>
                    Przeglądanie Oferty nie wymaga założenia Konta Użytkownika.
                </li>
                <li>
                    Konto Użytkownika jest niezbędne do korzystania z Usług
                    takich jak:
                    <ol>
                        <li>złożenie Zamówienia,</li>
                        <li>dostęp do historii Zamówień,</li>
                        <li>Portfel,</li>
                        <li>
                            wyrażanie opinii na temat Restauracji i Produktów,
                        </li>
                        <li>statystyki,</li>
                        <li>czat.</li>
                    </ol>
                </li>
                <li>
                    W celu Rejestracji należy wypełnić formularz rejestracyjny
                    udostępniony na Platformie oraz wskazać dane do
                    uwierzytelnienia. Rejestracja jest równoznaczna z
                    zaakceptowaniem treści niniejszego Regulaminu oraz Polityki
                    prywatności. Użytkownik zobowiązany jest do należytej
                    ochrony danych uwierzytelniających przed nieuprawnionym
                    dostępem, w szczególności zobowiązany jest do zachowania ich
                    w tajemnicy i nieujawniania osobom trzecim.
                </li>
                <li>
                    Po przesłaniu wypełnionego formularza rejestracyjnego
                    Użytkownik otrzyma niezwłocznie potwierdzenie dokonania
                    rejestracji na podany w formularzu adres e-mail. Z chwilą
                    wysłania wiadomości, o której mowa w zdaniu poprzedzającym,
                    między Użytkownikiem a Usługodawcą zostaje zawarta umowa o
                    świadczenie drogą elektroniczną usługi prowadzenia Konta
                    Użytkownika na zasadach określonych w niniejszym
                    Regulaminie.
                </li>
                <li>
                    Osoby do 16 roku życia nie mogą samodzielnie korzystać z
                    Platformy ani dokonać Rejestracji - w takim przypadku
                    Rejestrację musi zatwierdzić rodzic lub opiekun prawny.
                    Jeżeli zatwierdzenie nie zostanie dokonane w terminie 30
                    dni, Rejestracja nie dojdzie do skutku.
                </li>
                <li>
                    Użytkownik jest zobowiązany do tego, by dane dotyczące jego
                    tożsamości i prawnej zdolności do działania podane w
                    formularzu rejestracyjnym były prawdziwe, dokładne, aktualne
                    i kompletne. Użytkownik ponosi odpowiedzialność za podanie
                    danych fałszywych bądź niekompletnych. W przypadku
                    stwierdzenia podania przez Użytkownika fałszywych danych,
                    Usługodawcy przysługuje prawo odmowy Rejestracji lub - w
                    przypadku ustalenia, że dane są nieprawdziwe po dokonaniu
                    Rejestracji – prawo zablokowania Konta Użytkownika. O fakcie
                    oraz przyczynach odmowy Rejestracji lub blokady konta
                    Użytkownik zostanie poinformowany drogą elektroniczną na
                    adres podany w formularzu rejestracyjnym.
                </li>
                <li>
                    Z chwilą dokonania Rejestracji tworzony jest profil
                    publiczny Użytkownika. Użytkownik za pośrednictwem Konta
                    Użytkownika decyduje o zakresie danych dostępnych
                    publicznie.
                </li>
                <li>
                    Za pośrednictwem Konta Użytkownika Użytkownik może zarządzać
                    własnymi danymi wskazanymi podczas Rejestracji, w tym
                    dokonywać ich aktualizacji.
                </li>
                <li>
                    Usługa Konto Użytkownika świadczona jest nieodpłatnie przez
                    czas nieoznaczony. Użytkownik może w każdej chwili i bez
                    podania przyczyny żądać usunięcia Konta Użytkownika poprzez
                    wysłanie wiadomości do Usługodawcy na adres
                </li>
                <li>
                    Usługodawca zastrzega sobie prawo do usunięcia Konta
                    Użytkownika w przypadku jego nieaktywności w określonym
                    czasie. W takiej sytuacji Usługodawca powiadomi Użytkownika
                    na adres e-mail podany przy Rejestracji o planowanym
                    usunięciu Konta Użytkownika i umożliwi Użytkownikowi
                    wyrażenie sprzeciwu wobec usunięcia. Brak wniesienia
                    sprzeciwu przez Użytkownika w oznaczonym czasie oznacza
                    zgodę na usunięcie jego Konta Użytkownika.
                </li>
                <li>
                    Usługodawca ma prawo do natychmiastowego usunięcia Konta
                    danego Użytkownika, w sytuacji rażącego naruszania przez
                    niego postanowień niniejszego Regulaminu, w tym w
                    szczególności: umyślnego wystawiania niezgodnych z prawdą
                    ocen poszczególnych Restauracji, dostarczania treści o
                    charakterze bezprawnym, o których mowa w ust. 13 czy też
                    podania nieprawdziwych/fałszywych danych podczas procesu
                    rejestracyjnego - jeżeli nie zaprzestaje on bezprawnego
                    działania pomimo wyznaczonego przez Usługodawcę w tym
                    zakresie terminu - nie krótszego niż 7 dni.
                </li>
                <li>
                    Usługodawca nie ponosi odpowiedzialności za niemożność
                    świadczenia Usług na rzecz Użytkownika na skutek działania
                    siły wyższej. Przez Siłę wyższą należy rozumieć zdarzenie
                    zewnętrzne, którego Usługodawca nie mógł przewidzieć i 
                    któremu nie mógł zapobiec, uniemożliwiające wykonanie
                    postanowień Regulaminu w całości lub części, na stałe lub na
                    pewien czas, któremu Usługodawca nie mógł przeciwdziałać
                    przy zachowaniu należytej staranności.
                </li>
                <li>
                    W ramach korzystania z Usług niedozwolone jest dostarczanie
                    przez Użytkownika treści prawnie niedozwolonych, w tym w
                    szczególności:
                    <ol>
                        <li>
                            danych i informacji spreparowanych w sposób
                            stwarzający ryzyko naruszenia bezpieczeństwa systemu
                            informatycznego lub stabilności Platformy;
                        </li>
                        <li>
                            informacji naruszających jakiekolwiek dobra
                            Usługodawcy bądź osób trzecich;
                        </li>
                        <li>
                            innych danych i informacji naruszających
                            bezwzględnie obowiązujące przepisy prawa, w tym w
                            szczególności treści obraźliwych, pornograficznych,
                            nawołujących i odwołujących się do rasizmu,
                            nacjonalizmu, faszyzmu, przemocy, dyskryminacji
                            płciowej.
                        </li>
                    </ol>
                </li>
                <li>
                    Korzystając z Platformy Użytkownicy mają obowiązek
                    postępować zgodnie z powszechnie obowiązującymi przepisami
                    prawa oraz zasadami współżycia społecznego.
                </li>
                <li>
                    Zakazane jest wykorzystywanie przez Użytkowników Usług w
                    sposób sprzeczny z powszechnie obowiązującymi przepisami
                    prawa, dobrymi obyczajami lub w sposób naruszający
                    uzasadnione interesy Usługodawcy bądź podmiotów trzecich. W
                    szczególności Użytkownik nie będzie podejmował czynności,
                    które mogłyby narazić Usługodawcę na jakąkolwiek szkodę
                    majątkową.
                </li>
                <li>
                    Zabronione jest pobieranie przez Użytkowników zawartości baz
                    danych udostępnionych na Platformie i wtórne ich
                    wykorzystywanie w całości lub w istotnej części co do
                    jakości lub ilości.
                </li>
                <li>
                    Usługodawca zastrzega, iż wszystkie wiadomości i materiały
                    dostępne na Platformie są objęte ochroną prawa autorskiego.
                    Użytkownik ma prawo do korzystania z nich jedynie w ramach
                    dozwolonego użytku osobistego. Kopiowanie, zwielokrotnianie,
                    rozpowszechnianie w Internecie i inne formy korzystania z
                    materiałów i wiadomości umieszczonych na Platformie
                    wykraczające poza granice dozwolone prawem jest zabronione.
                </li>
            </ol>

            <h2>V. OPINIE UŻYTKOWNIKÓW</h2>
            <ol>
                <li>
                    Użytkownicy mają możliwość wyrażenia swojej opinii na temat
                    wykonania Umowy przez Restauratora w drodze oceny lub
                    wystawienia komentarza. Wyrażenie opinii jest dobrowolne.
                </li>
                <li>
                    Ocena może dotyczyć Restauratora lub konkretnego Produktu i
                    polega na przyznaniu punktów w skali od 1 do 5.
                </li>
                <li>
                    Użytkownik może wyrazić swoją opinię na temat Restauratora
                    lub Produktu jednokrotnie i dopiero po zrealizowaniu
                    złożonego Zamówienia. Użytkownik ma możliwość modyfikacji
                    swojej opinii.
                </li>
                <li>
                    W przypadku odstąpienia od Umowy przed jej zrealizowaniem,
                    wyrażona opinia nie zostanie opublikowana.
                </li>
                <li>
                    Opinie powinny być zgodne z rzeczywistą realizacją Umowy i
                    nie mogą:
                    <ol>
                        <li>
                            naruszać obowiązujących przepisów prawa, w tym praw
                            podmiotów trzecich oraz zasad współżycia
                            społecznego,
                        </li>
                        <li>
                            mieć charakteru zniesławiającego, naruszać dóbr
                            osobistych lub stanowić czynu nieuczciwej
                            konkurencji,
                        </li>
                        <li>
                            zawierać treści niedozwolonych i niezgodnych z
                            prawem w tym wulgaryzmów,
                        </li>
                        <li>treści o charakterze reklamowym.</li>
                    </ol>
                </li>
                <li>
                    Użytkownik ponosi wyłączną odpowiedzialność za treści
                    umieszczane w komentarzach.
                </li>
                <li>
                    Usługodawca nie ingeruje w treść opinii Użytkownika, jednak
                    zastrzega sobie prawo do ich usunięcia w uzasadnionych
                    przypadkach takich jak:
                    <ol>
                        <li>
                            naruszenie zasad określonych w punkcie 5 powyżej,
                        </li>
                        <li>
                            oczywisty błąd odnoszący się do przedmiotu opinii,
                        </li>
                        <li>nieczytelność.</li>
                    </ol>
                </li>
                <li>
                    Poprzez wyrażenie opinii Użytkownik wyraża zgodę na jej
                    wykorzystywanie przez Usługodawcę, w tym publiczne jej
                    prezentowanie innym Użytkownikom i osobom przeglądającym
                    treści na Platformie.
                </li>
            </ol>

            <h2>VI. CZAT</h2>
            <ol>
                <li>
                    Czat jest nieodpłatną usługą świadczoną w ramach Konta
                    Użytkownika, która, umożliwia nawiązanie kontaktu w czasie
                    rzeczywistym pomiędzy Użytkownikiem a Restauracją za
                    pośrednictwem komunikatora zamieszczonego na Platformie.
                </li>
                <li>
                    Czat służy do komunikacji w sprawie złożonego Zamówienia.
                </li>
                <li>
                    Aby rozpocząć korzystanie z Czatu należy wysłać wiadomość za
                    pośrednictwem okna Czatu. Zaprzestanie korzystania z usługi
                    jest możliwe w każdym czasie poprzez zamknięcie okna
                    konwersacji.
                </li>
            </ol>

            <h2>VII. ZAWARCIE I WYKONANIE UMOWY</h2>
            <ol>
                <li>
                    Zamówienia za pośrednictwem Platformy można składać przez 7
                    dni w tygodniu, 24 godziny na dobę, przy czym termin ich
                    realizacji wynika ze szczegółów Oferty Restauratora.
                </li>
                <li>
                    Złożenie Zamówienia możliwe jest wyłącznie za pośrednictwem
                    Konta Użytkownika.
                </li>
                <li>
                    Usługodawca prezentuje wszystkie dane Restauracji w sposób
                    wyraźnie wskazujący Użytkownikowi jego prawa i obowiązki w
                    przypadku złożenia zamówienia oraz akceptacji zamówienia
                    przez Restaurację.
                </li>
                <li>
                    W celu złożenia Zamówienia należy wybrać Produkty z Oferty
                    danego Restauratora, wskazać dostępną opcję Odbioru oraz
                    zaznaczyć pole Zamawiam.
                </li>
                <li>
                    Platforma niezwłocznie przekazuje Zamówienie do
                    Restauratora, który zobowiązany jest je potwierdzić w
                    całości lub w części bądź odmówić przyjęcia zamówienia. W
                    przypadku odmowy przyjęcia Zamówienia Restaurator zawiadomi
                    Użytkownika o tym fakcie. W przypadku częściowego
                    potwierdzenie Zamówienia Restaurator poinformuje Użytkownika
                    o powodzie częściowej akceptacji Zamówienia oraz o
                    dostępności zamówionych produktów, pozostawiając mu decyzję
                    o sposobie dalszej realizacji Zamówienia.
                </li>
                <li>
                    Do zawarcia Umowy dochodzi w momencie potwierdzenia przez
                    Restauratora złożonego Zamówienia. Z chwilą, w której
                    Użytkownik złożył Zamówienie uznaje się, że świadomie
                    zaakceptował warunki jego realizacji określone niniejszym
                    Regulaminem, w tym cenę i obowiązek jej zapłaty.
                </li>
                <li>
                    Z chwilą potwierdzenia przez Restaurację Zamówienia,
                    uzyskuje ono status “Aktywne”, a Użytkownik może je
                    uzupełnić o dodatkowe Produkty, co każdorazowo wymaga
                    potwierdzenia Restauratora zgodnie z punktem 4 i 5 powyżej.
                </li>
                <li>
                    W momencie potwierdzenia przez Restauratora Zamówienia
                    następuje przelew ze środków na rachunku Użytkownika.
                    Płatność zostanie pobrana przez Usługodawcę z chwilą
                    wykonania umowy przez Restauratora.
                </li>
                <li>
                    W momencie zawarcia Umowy, po stronie Użytkownika powstaje
                    obowiązek płatności za pośrednictwem Usługodawcy na rzecz
                    Restauracji ceny wynikającej z Zamówienia. Podana w
                    Zamówieniu cena stanowi całkowitą cenę jaką Użytkownik
                    zobowiązany będzie zapłacić wraz z należnym podatkiem oraz
                    kosztem dostawy. Obciążenie Użytkownika dodatkowymi kosztami
                    nastąpi wyłącznie po uzyskaniu jego wyraźnej zgody.
                    Użytkownik dokonuje płatności za pośrednictwem oferowanych
                    przez Platformę Metod Płatności Online lub za pośrednictwem
                    Portfela. Klient jest informowany o dostępnych sposobach
                    zapłaty na Platformie podczas składania Zamówienia w danej
                    Restauracji i musi dokonać wyboru jednego z nich przed
                    złożeniem Zamówienia, tj. przed kliknięciem przycisku
                    „Zamawiam i płacę”.
                </li>
                <li>
                    Użytkownik ma możliwość wpłacenia do swojego Portfela
                    środków do wykorzystania na Zamówienia w Platformie za
                    pośrednictwem udostępnionych przez Platformę Metod Płatności
                    Online. Wirtualną walutą Portfela jest Eatcoin, gdzie 1
                    Eatcoin = 1 PLN.
                </li>
                <li>
                    Restaurator upoważnił Usługodawcę do akceptacji w jego
                    imieniu płatności online dokonywanych przez Klientów.
                </li>
                <li>
                    Użytkownik za pośrednictwem Platformy może sprawdzać status
                    realizacji swojego Zamówienia oraz skontaktować się z
                    Restauratorem korzystając z funkcji czat.
                </li>
                <li>
                    Termin wykonania Umowy określa treść oferty Restauratora.
                    Miejscem spełniania świadczenia związanego z Zamówieniem
                    jest miejsce Odbioru wskazane przez Użytkownika w
                    Zamówieniu.
                </li>
                <li>
                    Umowa może być prawidłowo wykonana przez Restauratora
                    wyłącznie pod warunkiem wskazania przez Użytkownika w Koncie
                    Użytkownika i podczas Zamówienia prawidłowych danych.
                </li>
                <li>
                    W przypadku złożenia Zamówienia przez Użytkownika powinien
                    on być obecny pod wskazanym przy składaniu Zamówienia
                    adresem dostawy w celu jego odbioru bądź powinien pojawić
                    się w punkcie odbioru zamówień wskazanym w Ofercie, o
                    zadeklarowanej przez siebie godzinie celem odbioru
                    Zamówienia bądź celem zajęcia zarezerwowanego stolika.
                </li>
                <li>Użytkownik ponosi koszty dostawy Zamówienia.</li>
                <li>
                    Jeżeli Klient zdecyduje się na realizację Umowy poprzez
                    samodzielny odbiór Zamówienia, winien on zgłosić się w tym
                    celu i o wskazanej godzinie do punktu odbioru zamówień
                    wskazanego przez Restauratora w Ofercie.
                </li>
                <li>
                    Jeżeli Użytkownik zdecyduje się na rezerwację stolika oraz
                    złoży zamówienie celem zaserwowania go do stolika winien
                    pojawić się w Restauracji o zadeklarowanej przez siebie
                    godzinie za pośrednictwem Platformy.
                </li>
                <li>
                    W momencie dostarczenia bądź serwowania Zamówienia
                    Restaurator może poprosić o dokument tożsamości, jeśli
                    Zamówienie obejmuje produkty alkoholowe lub inne produkty z
                    ograniczeniem wiekowym. Jeśli Klient nie jest w stanie
                    wylegitymować się odpowiednio lub nie spełnia minimalnych
                    wymogów wiekowych, Restaurator odmówi wydania odpowiednich
                    produktów Użytkownikowi. W takim przypadku pobrane mogą
                    zostać koszty z tytułu unieważnienia Zamówienia.
                </li>
                <li>
                    Akceptując postanowienia Regulaminu i zawierając Umowę,
                    Użytkownik wyraża zgodę na wystawianie i udostępnianie
                    faktur przez Restauratora w formie elektronicznej i
                    udostępnienie jej w Koncie Użytkownika przez Usługodawcę
                    oraz na adres e-mail wskazany przez Użytkownika w Koncie
                    Użytkownika zgodnie z art. 106n ust. 1 ustawy z dnia 11
                    marca 2004 r. o podatku od towarów i usług (Dz. U. z 2004 r.
                    nr 54 poz. 535 ze zm.).
                </li>
                <li>
                    Klient ma prawo do odwołania zgody wyrażonej na
                    udostępnianie faktur w formie elektronicznej, w tym celu
                    Użytkownik powinien wnieść pisemny sprzeciw na adres
                    korespondencyjny lub elektroniczny Usługodawcy. W takim
                    wypadku Usługodawca przekaże Użytkownikowi fakturę w wersji
                    papierowej na adres korespondencyjny wskazany w Koncie
                    Użytkownika lub wniosku.
                </li>
                <li>
                    Użytkownik zobowiązany jest w szczególności do:
                    <ol>
                        <li>
                            Podawania na Platformie wyłącznie prawdziwych i
                            aktualnych danych,
                        </li>
                        <li>
                            Niezwłocznego aktualizowania danych wskazanych na
                            Platformie, w szczególności w takim zakresie, w
                            jakim jest to niezbędne dla prawidłowej realizacji
                            Zamówienia,
                        </li>
                        <li>Terminowej zapłaty ceny,</li>
                        <li>Terminowego odbioru Produktów.</li>
                    </ol>
                </li>
            </ol>

            <h2>VIII. PRAWO ODSTĄPIENIA OD UMOWY I ZWROTY</h2>
            <ol>
                <li>Użytkownik ma prawo odstąpienia od Umowy.</li>
                <li>
                    Odstąpienie od Umowy przez Użytkownika w zakresie anulowania
                    zamówienia jest możliwe najpóźniej na 180 min przed wybraną
                    godziną dostawy lub serwisu Zamówienia, gdyż przedmiotem
                    świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub
                    mająca krótki termin przydatności do użycia w rozumieniu
                    art. 38 pkt 4 ustawy z dnia 30 maja 2014 r o prawach
                    konsumenta (Dz.U. 2014 poz. 827 z późn. zm.). Odstąpienie w
                    powyżej wskazany sposób od zawartej Umowy przed rozpoczęciem
                    jej faktycznej realizacji jest możliwe bez podania
                    jakiejkolwiek przyczyny.
                </li>
                <li>
                    Odstąpienie od Umowy (anulowanie zamówienia) jest możliwe
                    wyłącznie poprzez kontakt z Restauratorem za pośrednictwem
                    Czatu lub telefonicznie. Restaurator musi potwierdzić
                    przyjęcie zgłoszenia rezygnacji z realizacji Zamówienia, o
                    ile spełnia warunki ust. 2, a następnie anulować złożone
                    przez Użytkownika Zamówienie.
                </li>
                <li>
                    Usługodawca wskazuje Użytkownikowi, że Restaurator jest
                    uprawniony do odstąpienia od Umowy, w przypadku:
                    <ol>
                        <li>
                            zaistnienia siły wyższej uniemożliwiającej
                            realizację Umowy,
                        </li>
                        <li>
                            dezaktualizacji zamieszczonej w Platformie Oferty w
                            sytuacji, gdy Użytkownik nie zgodził się na
                            alternatywę dotycząca Zamówienia złożoną przez
                            Restaurację,
                        </li>
                        <li>
                            przekazania przez Użytkownika nieprawidłowego lub
                            nieosiągalnego numeru telefonu bądź adresu dostawy.
                        </li>
                    </ol>
                </li>
                <li>
                    W przypadku odstąpienia od Umowy przez którąkolwiek ze stron
                    traktuje się ją jako niezawartą. Usługodawca dokona zwrotu
                    płatności uiszczonej przez Użytkownika.
                </li>
                <li>
                    W przypadku złożenia przez Użytkownik fałszywego Zamówienia
                    lub niewykonania przez niego Umowy (w szczególności przez
                    jej nieopłacenie lub nieobecność w miejscu dostawy lub w
                    punkcie odbioru lub serwisu w celu odebrania zamówionych
                    produktów), Usługodawca oraz Restauracja jest uprawniona do
                    odmowy przyjęcia jakichkolwiek przyszłych Zamówień od
                    takiego Użytkownika.
                </li>
                <li>
                    Usługodawca jest uprawniony do niezwłocznego odrzucania
                    Zamówień składanych przez Użytkownik, jeżeli zachodzi
                    uzasadniona wątpliwość co do ich poprawności lub
                    autentyczności. W przypadku uzasadnionego podejrzenia
                    popełnienia przestępstwa, Usługodawca ma obowiązek
                    powiadomić o tym fakcie odpowiednie organy ścigania.
                </li>
                <li>
                    Zwrot płatności, o którym mowa w ust. 5 i 7 jest zawsze
                    dokonywany w formie zasilenia Portfela Użytkownika zgodnie z
                    właściwymi przepisami prawa. Przetwarzanie zwrotu potrwa
                    maksymalnie 10 dni roboczych od momentu uzyskania przez
                    Usługodawcę informacji o odstąpieniu od zawartej Umowy lub
                    odrzuceniu przez Restaurację.
                </li>
                <li>
                    Odstąpienie od Umowy przez Użytkownika w zakresie zwrotu
                    zamówienia nie jest możliwe, gdyż przedmiotem świadczenia
                    usług jest rzecz ulegająca szybkiemu zepsuciu lub mająca
                    krótki termin przydatności do użycia w rozumieniu art. 38
                    pkt 4 ustawy z dnia 30 maja 2014 r o prawach konsumenta
                    (Dz.U. 2014 poz. 827 z późn. zm.). Określone produkty są
                    wyłączone spod prawa konsumenta do odstąpienia od umowy i
                    zwrotu produktu, co do dotyczy m.in. następujących
                    produktów:
                    <ol>
                        <li>
                            Produkty żywnościowe oraz produkty, które ze względu
                            na swoją specyfikę nie mogą być ponownie sprzedane
                            lub które ulegają szybkiemu zepsuciu lub mają krótki
                            termin przydatności do użycia;
                        </li>
                        <li>
                            Produkty, które ze względu na swoją specyfikę, po
                            dostarczeniu zostają nierozłącznie połączone z
                            innymi produktami;
                        </li>
                        <li>
                            Produkty dostarczane w zapieczętowanym opakowaniu,
                            których po otwarciu opakowania nie można zwrócić ze
                            względu na ochronę zdrowia lub ze względów
                            higienicznych, jeżeli opakowanie zostało otwarte po
                            dostarczeniu;
                        </li>
                        <li>
                            Produkty nieprefabrykowane, wyprodukowane według
                            specyfikacji i instrukcji Użytkownika.
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>IX. LICENCJA</h2>
            <ol>
                <li>
                    Usługodawca udziela Użytkownikowi niewyłącznej, niezbywalnej
                    i ograniczonej do terytorium Rzeczypospolitej Polskiej
                    licencji na korzystanie z Platformy.
                </li>
                <li>
                    Licencja uprawnia Użytkownika do nieodpłatnego pobrania
                    Platformy oraz do korzystania z niej zgodnie z
                    przeznaczeniem i zasadami Regulaminu.
                </li>
                <li>
                    Użytkownik przyjmuje do wiadomości, iż Usługodawcy
                    przysługują prawa własności intelektualnej, w tym prawa
                    autorskie i prawa pokrewne do Platformy i wszystkich jej
                    elementów mających charakter Utworu w rozumieniu Ustawy o
                    prawie autorskim.
                </li>
                <li>
                    Kopiowanie, rozpowszechnianie, dokonywanie opracowań
                    elementów Platformy, a także wszelkie inne formy korzystania
                    z Platformy wykraczające poza dozwolony użytek osobisty bez
                    wyraźnej pisemnej zgody Usługodawcy stanowią naruszenie
                    majątkowych praw autorskich.
                </li>
                <li>
                    Bazy danych udostępnione w Platformie są przedmiotem ochrony
                    zgodnie z Ustawą o ochronie baz danych. Zabronione jest
                    pobieranie przez Użytkowników zawartości baz danych
                    udostępnianych na Platformie i ich wtórne wykorzystywanie w
                    całości lub części.
                </li>
                <li>
                    Niniejszy Regulamin nie przyznaje Użytkownikowi żadnych praw
                    własności intelektualnej do Platformy i jej części. Wszelkie
                    prawa, które nie zostały wyraźnie przyznane są zastrzeżone
                    na rzecz Usługodawcy i jego licencjodawców.
                </li>
            </ol>

            <h2>X. REKLAMACJE</h2>
            <ol>
                <li>
                    Reklamacje Użytkowników dotyczące Oferty, Zamówienia lub
                    realizacji Umowy winny być składane bezpośrednio do
                    Restauracji z wykorzystaniem jej danych kontaktowych
                    wymienionych na Platformie lub za pośrednictwem Czatu. W
                    przypadku zgłoszenia reklamacji, o której mowa w zdaniu
                    poprzednim, Usługodawca może pełnić jedynie rolę mediatora.
                </li>
                <li>
                    W przypadku reklamacji Klienta dotyczącej Usług świadczonych
                    przez Usługodawcę, reklamacja winna być przekazana na adres
                    poczty elektronicznej Usługodawcy: support@eatzon.pl
                </li>
                <li>
                    Po otrzymaniu przez Usługodawcę reklamacji, o której mowa w
                    ust. 2, zostanie ona niezwłocznie rozpatrzona, jednak nie
                    później niż w terminie dwóch tygodni od dnia jej otrzymania.
                    Usługodawca potwierdzi otrzymanie reklamacji złożonej przez
                    Klienta na podany przez niego podczas zgłoszenia adres
                    e-mailowy.
                </li>
            </ol>

            <h2>XI. METODY PŁATNOŚCI</h2>
            <ol>
                <li>
                    Usługodawca udostępnia Klientowi płatności elektroniczne i
                    płatności kartą płatniczą (Visa, Visa Electron, Mastercard,
                    MasterCard Electronic, Maestro) za pośrednictwem serwisu
                    PayNow. Podmiotem świadczącym obsługę płatności online w
                    zakresie płatności kartami jest Autopay S.A.
                </li>
            </ol>

            <h2>XII. NEWSLETTER</h2>
            <ol>
                <li>
                    Usługa Newsletter polega na przesyłaniu przez Usługodawcę na
                    podany przez Użytkownika adres poczty elektronicznej
                    (e-mail) wiadomości zawierających informacje dotyczące
                    Oferty (w tym promocji) dostępnej na Platformie.
                </li>
                <li>
                    Użytkownik poprzez zawarcie umowy o świadczenie usługi
                    Newsletter z Usługodawcą, udostępnia swój adres poczty
                    elektronicznej (e-mail), podany w formularzu rejestracyjnym,
                    w celu otrzymywania wiadomości, o których mowa w ust.1.
                </li>
                <li>
                    Do korzystania z usługi Newsletter konieczny jest dostęp do
                    sieci Internet oraz posiadanie adresu poczty elektronicznej
                    (e-mail), natomiast nie jest konieczne spełnienie
                    szczególnych wymagań technicznych poza posiadaniem
                    standardowego systemu operacyjnego oraz standardowej
                    przeglądarki internetowej.
                </li>
                <li>
                    Usługa elektroniczna Newsletter świadczona jest nieodpłatnie
                    przez czas nieoznaczony. Użytkownik ma możliwość, w każdej
                    chwili i bez podania przyczyny, rezygnacji z Newslettera
                    poprzez wysłanie stosownego żądania do Usługodawcy, w
                    szczególności za pośrednictwem poczty elektronicznej
                    wybierając opcję „unsubscribe” załączonej do każdej
                    wiadomości przesłanej w ramach newslettera.
                </li>
            </ol>
            <h2>XIII. OCHRONA DANYCH OSOBOWYCHY</h2>
            <ol>
                <li>
                    Administratorem danych osobowych Użytkowników Platformy jest
                    Usługodawca.
                </li>
                <li>
                    Usługodawca udostępnia dane osobowe Użytkowników
                    Restauratorowi, w której złożyli Zamówienie. Restaurator
                    staje się wówczas odrębnym i samodzielnym administratorem
                    tych danych w zakresie niezbędnym do realizacji Umowy.
                </li>
                <li>
                    Cele oraz zasady przetwarzania danych osobowych szczegółowo
                    reguluje{' '}
                    <Link to="/privacy">
                        <strong>Polityka Prywatności</strong>
                    </Link>{' '}
                    stanowiąca integralną część niniejszego Regulaminu.
                </li>
            </ol>

            <h2>XIV. POSTANOWIENIA KOŃCOWE</h2>
            <ol>
                <li>
                    Regulamin i jego zmiany dostępne są w na stronie
                    internetowej eatzon.pl oraz w aplikacji eatzon.com w
                    formacie, który umożliwia pozyskanie, odtwarzanie i
                    utrwalanie treści Regulaminu.
                </li>
                <li>
                    Usługodawca zastrzega sobie prawo do wprowadzania oraz
                    odwoływania ofert oraz promocji bez uszczerbku dla praw
                    nabytych przez Klienta, w tym w szczególności warunków umów
                    zawartych przed dokonaniem zmiany.
                </li>
                <li>
                    Usługodawca zastrzega sobie możliwość wprowadzania zmian w
                    Regulaminie. Do Umów zawartych przed zmianą Regulaminu
                    stosuje się Regulamin obowiązujący w dacie zawarcia umowy. W
                    odniesieniu do umów o charakterze ciągłym (np. o prowadzenie
                    Konta Użytkownika), zmieniony Regulamin wiąże Użytkownika,
                    jeżeli zostały zachowane wymagania określone w art. 384 i
                    384[1] Kodeksu cywilnego, tj. Użytkownik został prawidłowo
                    powiadomiony o zmianach i nie wypowiedział umowy w terminie
                    14 dni kalendarzowych od dnia powiadomienia.
                </li>
                <li>
                    Niniejszy Regulamin obowiązuje od dnia publikacji na stronie
                    internetowej oraz w aplikacji.
                </li>
                <li>
                    W sprawach nieuregulowanych w niniejszym Regulaminie mają
                    zastosowanie przepisy Kodeksu cywilnego, przepisy Ustawy o
                    świadczeniu usług drogą elektroniczną oraz inne właściwe
                    przepisy prawa polskiego.
                </li>
            </ol>
        </div>
    );
};

export default Terms;
