import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useGeolocationContext } from '../../../context/Geolocation/Geolocation.provider';
import { usePlacesAutocomplete } from '../../../../components/GooglePlacesAutocomplete';
import { useNoAuthOrdersContext } from '../../../context/NoAuthOrders/NoAuthOrders.provider';
import { dateFormat } from '@helpers/dateFormat';

export default ({ orderId }) => {
    const { ordersNoAuth, onUpdateOrderNoAuth } = useNoAuthOrdersContext();
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [loading, setLoading] = useState(true);
    const [parentIsOpen, parentSetIsOpen] = useState(false);

    const { onSelectAddress } = usePlacesAutocomplete();
    const { geolocation, setGeolocation } = useGeolocationContext();

    const orderWeWorkOn = ordersNoAuth.find((order) => order.id === orderId);

    const handleDownloadPdf = (layoutPdfUrl) => {
        layoutPdfUrl
            ? window.open(layoutPdfUrl, '_blank')
            : toast.warning('Nie ma możliwości podglądu układu sali');
    };

    useEffect(() => {
        setLoading(true);
        if (orderWeWorkOn) {
            const [time, unit] =
                orderWeWorkOn?.type !== 'TAKEAWAY'
                    ? [30, 'minutes']
                    : [5, 'minutes'];
            const { date, newTime } = dateFormat.getDateAndTime(time, unit);
            onUpdateOrderNoAuth({
                invoiceDetails: undefined,
                ...orderWeWorkOn,
                deliveryAddress:
                    orderWeWorkOn.deliveryAddress || geolocation?.address,
                deliveryLocation: {
                    coordinates: orderWeWorkOn?.deliveryLocation
                        ?.coordinates ?? [geolocation.lng, geolocation?.lat],
                },
                date: orderWeWorkOn.consumptionDate || date,
                time: orderWeWorkOn.consumptionTime || newTime,
            });
        }
        setLoading(false);
    }, []);

    return {
        orderWeWorkOn,
        deliveryCost,
        setDeliveryCost,
        handleDownloadPdf,
        parentIsOpen,
        parentSetIsOpen,
        loading,
        onChange: (e) => {
            if (
                (e.target.name === 'time' || e.target.name === 'date') &&
                !e.target.value
            )
                return;

            onUpdateOrderNoAuth({
                ...orderWeWorkOn,
                [e.target.name]: e.target.value,
            });
        },

        onChangeServeOnTime(serveOnTime) {
            onUpdateOrderNoAuth({
                ...orderWeWorkOn,
                serveOnTime,
            });
        },

        onChangeTable(e) {
            onUpdateOrderNoAuth({
                ...orderWeWorkOn,
                table: e.target.value,
            });
        },

        handleSelectAddress(address) {
            onSelectAddress(address).then(({ lng, lat }) => {
                setGeolocation({ address, lat, lng });
                onUpdateOrderNoAuth({
                    ...orderWeWorkOn,
                    deliveryAddress: address,
                    deliveryLocation: {
                        coordinates: [lng, lat],
                    },
                });
            });
        },

        onChangeAddres(address) {
            onUpdateOrderNoAuth({ ...orderWeWorkOn, deliveryAddress: address });
        },

        onSaveNote(note) {
            onUpdateOrderNoAuth({ ...orderWeWorkOn, note: note });
        },

        onIsInvoiceChange() {
            onUpdateOrderNoAuth({
                ...orderWeWorkOn,
                isInvoice: !orderWeWorkOn.isInvoice,
                invoiceDetails: undefined,
            });
        },

        onInvoiceDetailsSave(data) {
            onUpdateOrderNoAuth({
                ...orderWeWorkOn,
                invoiceDetails: {
                    ...orderWeWorkOn.invoiceDetails,
                    ...data,
                },
            });
        },
    };
};
