import React, { useState } from 'react';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import { Bullet, Collapsable } from '../../../../components/Elements';
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable';
import { colors } from '../../../../style';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { useArchiveProductMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { useAuthService } from '@/services/AuthService';

const StatusActive = ({ productId, isArchived, restaurantStatus }) => {
    const [isOpen, setIsOpen] = useState(false);
    const restaurantId = useAuthService((state) => state.restaurantId);

    const [archiveProduct] = useArchiveProductMutation();

    const onArchive = (productId, isArchived) => {
        archiveProduct({
            variables: {
                input: {
                    productId,
                    restaurantId,
                    payload: { deleted: !isArchived },
                },
            },
            onCompleted: () => {
                toast.info(
                    isArchived
                        ? 'Produkt został dearchiwizowany'
                        : 'Produkt został zarchiwizowany',
                );
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                right
                noPadding
                oneLiner
                title={'Archiwum'}
                icon={
                    !isArchived ? (
                        <LockOpenIcon fontSize="inherit" />
                    ) : (
                        <LockIcon fontSize="inherit" color={'#ff0000'} />
                    )
                }
                description={
                    !isArchived ? (
                        <>
                            Dostępny
                            <Bullet />
                            <strong css={{ color: colors.green }}>
                                Widoczny
                            </strong>
                        </>
                    ) : (
                        <>
                            W Archiwum
                            <Bullet />
                            <strong css={{ color: colors.red }}>
                                Niewidoczny
                            </strong>
                        </>
                    )
                }
                parentIsOpen={isOpen}
                parentSetIsOpen={setIsOpen}
            >
                <CollapsableText>
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={!isArchived}
                                    disabled={!restaurantStatus}
                                    onChange={() =>
                                        onArchive(productId, isArchived)
                                    }
                                    name="isSendingClientPushes"
                                    color="primary"
                                />
                            }
                        ></FormControlLabel>
                        <span
                            css={{
                                fontWeight: 700,
                                color: colors.text,
                                fontSize: '13px',
                            }}
                        >
                            {!isArchived
                                ? 'Archiwizuj produkt'
                                : 'Dearchiwizuj produkt'}
                        </span>
                    </div>
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

export default StatusActive;
