import { useEffect, useState } from 'react';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { usePushNotificationWaiterUpdateMutation } from '@graphql/generated';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

export const useWebPushNotificationInitialize = () => {
    const { isLoggedIn, fcmToken } = useWaiterService((state) => state);

    const [waiterUpdate] = usePushNotificationWaiterUpdateMutation();

    const [hasPermissions, setHasPermissions] = useState(false);

    const registerNotifications = async () => {
        try {
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                setHasPermissions(true);
            }
        } catch (error) {
            console.error('requestNotificationPermission error:', error);
        }
    };

    useEffect(() => {
        registerNotifications();
    }, []);

    useEffect(() => {
        if (isLoggedIn && fcmToken) {
            waiterUpdate({
                context: { waiter: true },
                variables: {
                    input: {
                        payload: {
                            fcmToken,
                        },
                    },
                },
                onError: graphqlErrorHandler(true),
            });
        }
    }, [isLoggedIn, fcmToken]);

    return {
        hasPermissions,
    };
};
