import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { MOBILE } from '@constants/common.constants';

const getCsvForMobile = async (data: string[][], filename: string) => {
    try {
        let csvContent = '';
        data.forEach((rowArray) => {
            const row = rowArray.join(',');
            csvContent += row + '\r\n';
        });

        const permissions = await Filesystem.checkPermissions();
        if (permissions.publicStorage !== 'granted') {
            await Filesystem.requestPermissions();
        }

        const result = await Filesystem.writeFile({
            path: `${filename}.csv`,
            data: csvContent,
            directory: Directory.Documents,
            encoding: Encoding.UTF8,
        });
        await FileOpener.open({
            filePath: result.uri,
        });
    } catch (error) {
        console.error(error);
    }
};

const getCsvForWeb = (data: string[][], filename: string) => {
    const csvConfig = mkConfig({
        useKeysAsHeaders: true,
        filename,
    });
    const headers = data[0];
    const formattedData = data.slice(1).map((row) => {
        const formattedRow: Record<string, string> = {};
        row.forEach((cell, index) => {
            formattedRow[headers[index]] = cell;
        });
        return formattedRow;
    });
    const csv = generateCsv(csvConfig)(formattedData);
    download(csvConfig)(csv);
};

export const generateCsvFile = (data: string[][], filename: string) => {
    if (MOBILE) {
        getCsvForMobile(data, filename);
    } else {
        getCsvForWeb(data, filename);
    }
};
