import SadmContainer from './_container/sadm-container';
import SadmChangePermission from './change-permission/sadm-change-permission.page';

import { sadmRoutePrefix } from './settings';

const q = (route) => sadmRoutePrefix.concat(route);

const sadmRoutes = [
    {
        path: q('/change-permission'),
        component: SadmChangePermission,
    },
];

export { SadmContainer, sadmRoutes, sadmRoutePrefix };
