import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import styled from '@emotion/styled';
import {
    OPERATIONS,
    useAdminIngredientUpdateMutation,
} from '@graphql/generated';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IngredientUpsertSchema } from 'src/EZAdmin/domains/Ingredients/validation';

interface EzAdminIngredientsEditModalProps {
    closeModal: () => void;
    id: string;
    name: string;
}

export const EZAdminIngredientsEditModal = ({
    closeModal,
    id,
    name,
}: EzAdminIngredientsEditModalProps) => {
    const [ingredientUpdate, { loading }] = useAdminIngredientUpdateMutation();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm<{
        ingredientName: string;
    }>({
        defaultValues: { ingredientName: name },
        mode: 'all',
        resolver: yupResolver(IngredientUpsertSchema),
    });

    const ingredientNameError = errors?.ingredientName?.message;

    const {
        field: { onChange, value },
    } = useController({
        control,
        name: 'ingredientName',
    });

    const handleUpdateSubmit = () => {
        ingredientUpdate({
            variables: {
                input: {
                    id: id,
                    payload: { name: value },
                },
            },
            onCompleted: () => {
                closeModal();
                toast.dark('Składnik został zaktualizowany');
            },
            onError: (error) => {
                error?.graphQLErrors?.forEach((graphError) => {
                    if (
                        graphError.extensions.code ===
                        'INGREDIENT_EXISTS_ERROR_CODE'
                    ) {
                        setError('ingredientName', {
                            type: 'custom',
                            message: 'Podany składnik już istnieje.',
                        });
                    } else {
                        graphqlErrorHandler(true)(error);
                    }
                });
            },
            refetchQueries: [OPERATIONS.Query.AdminIngredientList],
        });
    };

    return (
        <ModalContentWrapper onSubmit={handleSubmit(handleUpdateSubmit)}>
            <EditModalTitle>Edycja nazwy składnika</EditModalTitle>
            <TextField
                error={!!ingredientNameError}
                helperText={ingredientNameError}
                onChange={onChange}
                label="Nazwa składnika"
                value={value}
            />
            <EditModalButtonGroup>
                <Button color="default" onClick={closeModal}>
                    Odrzuć
                </Button>
                <Button
                    color="primary"
                    disabled={loading}
                    type="submit"
                    variant="contained"
                >
                    Zapisz
                </Button>
            </EditModalButtonGroup>
        </ModalContentWrapper>
    );
};

const ModalContentWrapper = styled.form`
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    max-width: 600px;
    padding: 32px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
`;

const EditModalTitle = styled.h2`
    font-size: 20px;
    margin-bottom: 56px;
    text-align: left;
`;

const EditModalButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 40px;
    width: 100%;
`;
