/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import NoteIcon from '@material-ui/icons/PostAdd';
import OrderNote from './OrderNote.component';
import { Collapsable } from '../../../../components/Elements';
import { Grid } from '@material-ui/core';
import { useAuthService } from '@/services/AuthService';

const CheckoutNote = ({ left, note, onSaveNote, right, toTakeAway }) => {
    const [parentIsOpen, parentSetIsOpen] = useState(false);
    const { isLoggedIn } = useAuthService();
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                parentIsOpen={parentIsOpen}
                parentSetIsOpen={parentSetIsOpen}
                right={isLoggedIn ? right : toTakeAway}
                left={isLoggedIn ? left : !toTakeAway}
                noPadding
                oneLiner
                title={'Notatka'}
                icon={<NoteIcon />}
                description={note || 'Informacje dla restauracji'}
            >
                <OrderNote
                    note={note}
                    onSaveNote={onSaveNote}
                    parentSetIsOpen={parentSetIsOpen}
                />
            </Collapsable>
        </Grid>
    );
};

export default CheckoutNote;
