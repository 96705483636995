/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { Collapsable } from '../../../../components/Elements';
import { CollapsableText } from '../../../../components/Elements/Collapsable/Collapsable';
import { colors, alpha, s } from '../../../../style';

const OrderCanceled = ({ name, shortId, orderType, status }) => {
    return (
        <Grid
            item
            xs={12}
            css={{
                [s.xs]: { paddingBottom: '1rem' },
                [s.sm]: { paddingBottom: '1.5rem' },
            }}
        >
            <Collapsable
                openAlready
                noPadding
                oneLiner
                title={'Zamówienie anulowane'}
                icon={<WarningIcon />}
                description={`Stan zamówienia ${shortId}`}
                sIconCss={{
                    color: colors.red,
                    backgroundColor: colors.red.concat(alpha[16]),
                }}
            >
                <CollapsableText>
                    Zamówienie{' '}
                    <strong css={{ color: colors.text }}>{shortId}</strong>{' '}
                    zostało anulowane
                    {orderType === 'IN' &&
                        status !== 'CANCELED' &&
                        ' z powodu braku miejsc'}
                    . W przypadku opłaconego zamówienia,{' '}
                    <strong css={{ color: colors.text }}>
                        środki zostaną zwrócone na Twoje konto Eatzon
                    </strong>
                    . Zamówienie zostanie przeniesione do Historii Zamówień.
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

export default OrderCanceled;
