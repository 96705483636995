import {
    ChipSecondary,
    ChipSecondaryVariant,
} from '@/componentsV2/atoms/ChipSecondary/ChipSecondary';
import styled from '@emotion/styled';
import { useFormContext } from 'react-hook-form';
import {
    SearchFilterFormSchemaValues,
    SearchFilterQueryParamsValues,
    useSearchFilterFormSubmit,
} from '../../SearchFilterFormProvider/SearchFilterFormProvider';
import { useAuthService } from '@/services/AuthService';
import {
    useGetAllIngredientsQuery,
    useGetGroupNameQuery,
} from '@graphql/generated';
import { COOKING_TIME_VALUES } from '@constants/cookingTimeLevels';
import { CALORIES_LEVEL_VALUES } from '@constants/caloriesLevels';
import { COOK_METHOD_NAME_VALUES } from '@constants/fromGraphql/CookMethod.constants';
import { CUISINE_NAME_VALUES } from '@constants/fromGraphql/Cuisine.constants';
import { DIET_NAME_VALUES } from '@constants/fromGraphql/Diet.constants';
import { FLAVOUR_NAME_VALUES } from '@constants/fromGraphql/Flavor.constants';
import { DISH_NAME_VALUES } from '@constants/fromGraphql/Dish.constants';
import { MEAL_TIME_NAME_VALUES } from '@constants/fromGraphql/MealTime.constants';
import { PRICE_LEVEL_VALUES } from '@constants/priceLevels';
import { SPICINESS_LEVEL_VALUES } from '@constants/spicinessLevels';
import { SearchFilterSelectorValue } from '../SearchFilterSelector/SearchFilterSelector';
// import qs from 'qs';

interface SearchFilterChips {
    chipVariant?: ChipSecondaryVariant;
    formKey: keyof SearchFilterQueryParamsValues;
    id: string;
    name: string;
}

export const SearchFilterChips = () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    const { getValues, setValue } =
        useFormContext<SearchFilterFormSchemaValues>();
    const { handleFormSubmit } = useSearchFilterFormSubmit();
    const values = getValues();

    const ingredients = useGetAllIngredientsQuery();
    const group = useGetGroupNameQuery({
        skip: !isLoggedIn || !values.groups,
        variables: {
            input: {
                groupId: values.groups ?? '',
            },
        },
    });

    const filters: SearchFilterChips[] = [
        {
            formKey: 'distance',
            id: values.distance ?? '',
            name: values.distance ? `do ${values.distance} km` : '',
        },
        {
            formKey: 'calories',
            id: values.calories ?? '',
            name:
                CALORIES_LEVEL_VALUES?.find(
                    (calorie) => calorie.id === values.calories,
                )?.name ?? '',
        },
        {
            formKey: 'cookingTime',
            id: values.cookingTime ?? '',
            name:
                COOKING_TIME_VALUES?.find(
                    (time) => time.id === values.cookingTime,
                )?.name ?? '',
        },
        ...(values.cookMethod?.map((id) => ({
            formKey: 'cookMethod',
            id: id ?? '',
            name:
                COOK_METHOD_NAME_VALUES.find((method) => method.id === id)
                    ?.name ?? '',
        })) ?? []),
        ...(values.cuisine?.map((id) => ({
            formKey: 'cuisine',
            id: id ?? '',
            name:
                CUISINE_NAME_VALUES.find((cuisine) => cuisine.id === id)
                    ?.name ?? '',
        })) ?? []),
        ...(values.diet?.map((id) => ({
            formKey: 'diet',
            id: id ?? '',
            name: DIET_NAME_VALUES.find((diet) => diet.id === id)?.name ?? '',
        })) ?? []),
        ...(values.flavour?.map((id) => ({
            formKey: 'flavour',
            id: id ?? '',
            name:
                FLAVOUR_NAME_VALUES.find((flavour) => flavour.id === id)
                    ?.name ?? '',
        })) ?? []),
        ...(values.dish?.map((id) => ({
            formKey: 'dish',
            id: id ?? '',
            name: DISH_NAME_VALUES.find((dish) => dish.id === id)?.name ?? '',
        })) ?? []),
        {
            formKey: 'groups',
            id: values.groups ?? '',
            name: group.data?.group.name ?? '',
        },
        ...(values.mealTime?.map((id) => ({
            formKey: 'mealTime',
            id: id ?? '',
            name:
                MEAL_TIME_NAME_VALUES.find((mealTime) => mealTime.id === id)
                    ?.name ?? '',
        })) ?? []),
        {
            formKey: 'price',
            id: values.price ?? '',
            name:
                PRICE_LEVEL_VALUES.find((price) => price.id === values.price)
                    ?.name ?? '',
        },
        ...(values.spiciness?.map((id) => ({
            formKey: 'spiciness',
            id: id ?? '',
            name:
                SPICINESS_LEVEL_VALUES.find((spicines) => spicines.id === id)
                    ?.name ?? '',
        })) ?? []),
        ...(values.ingredients?.map((id) => ({
            formKey: 'ingredients',
            id: id ?? '',
            name:
                ingredients?.data?.list?.find(
                    (ingridient) => ingridient.id === id,
                )?.name ?? '',
        })) ?? []),
        ...(values.allergy?.map<SearchFilterChips>((id) => ({
            formKey: 'allergy',
            id: id ?? '',
            name:
                ingredients?.data?.list?.find(
                    (ingridient) => ingridient.id === id,
                )?.name ?? '',
            chipVariant: 'red',
        })) ?? []),
        ...(values.notPreffered?.map<SearchFilterChips>((id) => ({
            formKey: 'notPreffered',
            id: id ?? '',
            name:
                ingredients?.data?.list?.find(
                    (ingridient) => ingridient.id === id,
                )?.name ?? '',
            chipVariant: 'yellow',
        })) ?? []),
    ].filter((value) => !!value.id || !!value.name) as SearchFilterChips[];

    const handleDelete = (
        { id }: SearchFilterSelectorValue,
        formKey: keyof SearchFilterQueryParamsValues,
    ) => {
        const currentValue = getValues(formKey);
        const newValue = Array.isArray(currentValue)
            ? currentValue.filter((value) => value !== id)
            : null;

        setValue(formKey, newValue);

        handleFormSubmit();
    };

    return (
        <FilterWrapper>
            {filters?.map((filter) => (
                <ChipSecondary
                    key={filter.id}
                    onDelete={(value) => handleDelete(value, filter.formKey)}
                    size="small"
                    text={filter.name}
                    value={filter}
                    variant={filter.chipVariant ?? 'default'}
                />
            ))}
        </FilterWrapper>
    );
};

const FilterWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
`;
