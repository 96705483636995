import React, { useState } from 'react';
import {
    WaiterPollingServiceQuery,
    useMarkAsReadMutation,
} from '@graphql/generated';
import { memo, useEffect } from 'react';
import { useWaiterInAppNotification } from './useWaiterInAppNotification';
import PopupWaiter from '@/EZW/components/PopupWaiter';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    useWaiterService,
    WaiterService,
} from '../WaiterService/useWaiterService';
import { MOBILE } from '@constants/common.constants';

interface WaiterInAppNotificationProviderProps {
    notifications: WaiterPollingServiceQuery['notifications']['data'];
}

export const WaiterInAppNotificationProvider = memo(
    ({ notifications }: WaiterInAppNotificationProviderProps) => {
        const { restaurantId } = useWaiterService((state) => state);

        const [showPopup, setShowPopup] = useState(false);
        const { NOTIFICATION_TYPE } = useWaiterInAppNotification();

        const [markAsRead] = useMarkAsReadMutation();

        useEffect(() => {
            notifications.map((notification) => {
                const { id, wasRead, type, order } = notification;
                const showOrderToast = !(
                    order?.id === WaiterService().orderIdFromWaiter &&
                    type === 'ORDER_CREATED'
                );
                const showNewOrdersPopup =
                    type === 'ORDER_CREATED' &&
                    order?.id !== WaiterService().orderIdFromWaiter;

                if (!wasRead) {
                    if (!MOBILE) {
                        showOrderToast &&
                            NOTIFICATION_TYPE[type]?.toast(notification);

                        showNewOrdersPopup && setShowPopup(true);
                    }

                    markAsRead({
                        context: { waiter: true },
                        variables: {
                            input: {
                                inAppNotificationId: id,
                                restaurantId: restaurantId ?? '',
                            },
                        },
                        onError: graphqlErrorHandler(true),
                    });
                }
            });
        }, [notifications]);

        return (
            <>
                {showPopup && (
                    <PopupWaiter onHide={() => setShowPopup(false)} />
                )}
            </>
        );
    },
);
