import { useController, useForm } from 'react-hook-form';
import { useSendNotificationToUnregisteredUserMutation } from '@graphql/generated';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

interface NotificationContent {
    title: string;
    body: string;
}

export const useSendNotification = () => {
    const { control, handleSubmit } = useForm<NotificationContent>({
        resolver: yupResolver(NotificationSchema),
        defaultValues: {
            title: '',
            body: '',
        },
    });

    const titleControl = useController({
        control: control,
        name: 'title',
    });

    const bodyControl = useController({
        control: control,
        name: 'body',
    });

    const [sendPush, { loading }] =
        useSendNotificationToUnregisteredUserMutation();

    const handleOnSubmit = handleSubmit((value) => {
        const { title, body } = value;

        sendPush({
            variables: {
                input: {
                    title,
                    body,
                },
            },
            onCompleted: () => toast.dark('Powiadomienie zostało wysłane'),
            onError: graphqlErrorHandler(true),
        });
    });

    return {
        handleOnSubmit,
        loading,
        titleControl,
        bodyControl,
    };
};

const NotificationSchema = yup.object({
    title: yup.string().trim().required('To pole nie może pozostać puste'),
    body: yup.string().trim().required('To pole nie może pozostać puste'),
});
