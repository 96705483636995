import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { useEffect } from 'react';

export const useScrollToActiveInput = () => {
    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    useEffect(() => {
        if (keyboardHeight) {
            document?.activeElement?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }
    }, [keyboardHeight]);
};
