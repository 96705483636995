import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';

import Headline from '@components/Headline/Headline';
import {
    sGrid,
    sOuter,
    sDescription,
    sContainerOuter,
    sPaddingTop,
} from '@style/style';
import { Button } from '@components/Elements';
import { s } from '@style/index';
import { Input } from './components';
import { Grid } from '@material-ui/core';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useResetPasswordMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

const NewPassword = () => {
    const [inputValues, setInputValues] = useState({ first: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [authResetPassword, { loading }] = useResetPasswordMutation();
    const history = useHistory();
    const { token } = useParams();

    const onChange = (e) =>
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });

    const handleSubmit = () => {
        if (inputValues.first.length < 8) {
            toast.error('Hasło powinno zawierać minimum 8 znaków');

            return;
        }

        authResetPassword({
            variables: {
                input: { token, newPassword: inputValues.first },
            },
            onCompleted: () => {
                toast.success(
                    `Hasło zostało zmienione! Zaloguj się do swojego konta`,
                );
                history.push('/login');
            },
            onError: graphqlErrorHandler({
                customCodeMessages: {
                    INTERNAL_SERVER_ERROR:
                        'Link do zmiany hasła został już użyty, zresetuj ponownie hasło, aby otrzymać nowy link',
                },
            }),
        });
    };

    return (
        <React.Fragment>
            <TopNav title={'Nowe Hasło'} />
            <div css={sOuter}>
                <Headline>Nowe Hasło</Headline>
                <Grid container css={sGrid}>
                    <p css={sDescription}>
                        Ustaw nowe hasło do twojego konta Eatzon
                    </p>
                </Grid>
            </div>

            <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
                <div css={[sFormContainer]}>
                    <Input
                        onChange={onChange}
                        name="first"
                        value={inputValues.first}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Podaj nowe hasło"
                        sInputExtra={{ paddingRight: '50px' }}
                        autoComplete="off"
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                    />

                    <Button
                        w100
                        action={handleSubmit}
                        disabled={loading}
                        loading={loading}
                        loadingSpinner={loading}
                    >
                        Zapisz hasło
                    </Button>
                </div>
            </Grid>
        </React.Fragment>
    );
};

const sFormContainer = {
    [s.sm_down]: { paddingBottom: '2rem' },
    paddingBottom: '1.5rem',
};

export default NewPassword;
