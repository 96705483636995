import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { ClockIcon } from '@/svgs/ClockIcon';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@style/index';

interface SearchPhraseLabelProps {
    children: ReactNode;
    onClick: () => void;
    onRemove: () => void;
}

export const SearchPhraseLabel = ({
    children,
    onClick,
    onRemove,
}: SearchPhraseLabelProps) => {
    return (
        <PhraseWrapper>
            <Button onClick={onClick}>
                <ClockIcon />
                {children}
            </Button>
            <RemoveIcon onClick={onRemove} />
        </PhraseWrapper>
    );
};

const PhraseWrapper = styled.div`
    align-items: center;
    color: ${colors.text};
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Button = styled.button`
    align-items: center;
    background: 0;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    gap: 8px;
    transition: all 0.3s ease-in-out;
    width: 100%;

    &:hover {
        color: ${colors.gray800};
    }
`;

const RemoveIcon = styled(CloseIcon)`
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 20px;

    &:hover {
        color: ${colors.gray700};
    }
`;
