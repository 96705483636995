export type CookingTime = '10' | '35' | '60' | '60+';

export const COOKING_TIME_ENUM: Record<CookingTime, CookingTime> = {
    10: '10',
    35: '35',
    60: '60',
    '60+': '60+',
};

export const COOKING_TIME: Record<CookingTime, { name: string }> = {
    10: { name: 'do 10 min' },
    35: { name: 'do 35 min' },
    60: { name: 'do godziny' },
    '60+': { name: 'powyżej godziny' },
};

export const COOKING_TIME_KEYS = Object.keys(
    COOKING_TIME_ENUM,
) as CookingTime[];

export const COOKING_TIME_VALUES = Object.entries(COOKING_TIME).map(
    ([id, value]) => ({
        id,
        ...value,
    }),
) as {
    id: CookingTime;
    name: string;
}[];

/**
 * @deprecated
 */
export const COOKING_TIME_LEVELS = [
    { value: 10, label: 'Fast Food' },
    { value: 35, label: 'Standard' },
    { value: 60, label: 'Slow Food' },
    { value: 90, label: '' },
];
