import React, { memo } from 'react';
import { useUserPollingServiceQuery } from '@graphql/generated';
import { useEffect } from 'react';
import { graphqlErrorHandler } from '../../../services/GraphqlErrorService';
import { UserInAppNotificationProvider } from '../UserInAppNotificationService/UserInAppNotificationProvider';

export const UserPollingProvider = memo(() => {
    const { data, startPolling, stopPolling } = useUserPollingServiceQuery({
        fetchPolicy: 'network-only',
        onError: graphqlErrorHandler(true),
    });

    useEffect(() => {
        startPolling(2000);

        return () => {
            stopPolling();
        };
    }, []);

    return (
        <UserInAppNotificationProvider
            notifications={data?.notifications?.data ?? []}
        />
    );
});
