/// <reference types="@emotion/react/types/css-prop" />
import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { globals, s, colors, alpha } from '../../../style';
import { ButtonBase, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

/**
 * @deprecated since Accordion molecule in componentsV2
 */
const Collapsable = ({
    additionalComponent,
    alignStart,
    children,
    title,
    icon,
    iconBehindTitle,
    image,
    link,
    imageLink,
    description,
    extraCss,
    sOuterCss,
    sTextCss,
    oneLiner,
    // onLinerDesktop,
    noPadding,
    openAlready,
    wasOpenAlready,
    parentIsOpen,
    parentSetIsOpen,
    left,
    right,
    // gray200,
    sIconCss,
    badge,
    is12,
    noPaddingBottom,
}) => {
    const [isOpen, setIsOpen] = useState(openAlready ? true : false);
    const [wasOpen, setWasOpen] = useState(wasOpenAlready ? true : false);
    const history = useHistory();

    useEffect(() => {
        if (parentSetIsOpen) parentSetIsOpen(isOpen);
    }, [isOpen, parentSetIsOpen]);

    useEffect(() => {
        if (parentIsOpen === false || parentIsOpen === true)
            setIsOpen(parentIsOpen);
    }, [setIsOpen, parentIsOpen]);

    useEffect(() => {
        if (!wasOpen && isOpen) setWasOpen(true);
    }, [wasOpen, isOpen, setWasOpen]);

    return (
        <div
            className="collapsable"
            css={[
                sOuterCss,
                left && {
                    paddingBottom: '1.5rem',
                    [s.sm_down]: { paddingBottom: '1rem' },
                    [s.md]: { paddingRight: '0.75rem' },
                },
                right && {
                    paddingBottom: '1.5rem',
                    [s.sm_down]: { paddingBottom: '1rem' },
                    [s.md]: { paddingLeft: '0.75rem' },
                },
                // { paddingBottom: '1.5rem', [s.sm_down]: { paddingBottom: '1rem' } },
                noPaddingBottom && {
                    paddingBottom: 0,
                    [s.sm_down]: { paddingBottom: 0 },
                    [s.md]: { paddingBottom: 0 },
                },
            ]}
        >
            <div
                css={[
                    sContainerOuter,
                    // gray200 && { backgroundColor: colors.gray200 },
                    extraCss,
                    isOpen && { opacity: 1 },
                ]}
            >
                <ButtonBase css={sButtonBase}>
                    <div
                        css={[
                            sContainer,
                            isOpen && {
                                '&:hover': {
                                    [s.hover]: {
                                        borderBottomLeftRadius: 0,
                                        borderBottomRightRadius: 0,
                                    },
                                },
                            },
                            alignStart && { alignItems: 'flex-start' },
                        ]}
                        onClick={() =>
                            !link ? setIsOpen(!isOpen) : history.push(link)
                        }
                    >
                        {icon && (
                            <div css={[sIcon, sIconCss]}>
                                {icon}
                                {badge}
                            </div>
                        )}
                        {image && (
                            <Link to={imageLink} css={sImage}>
                                <img src={image} loading="lazy" alt={' '} />
                            </Link>
                        )}
                        <div css={[sText, sTextCss]}>
                            {iconBehindTitle ? (
                                <div
                                    css={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '4px',
                                        marginBottom: '4px',
                                    }}
                                >
                                    <span
                                        css={[
                                            sText.title,
                                            {
                                                margin: 0,
                                            },
                                        ]}
                                    >
                                        {title}
                                    </span>
                                    {iconBehindTitle && (
                                        <IconWrapper>
                                            {iconBehindTitle}
                                        </IconWrapper>
                                    )}
                                </div>
                            ) : (
                                <span css={sText.title}>{title}</span>
                            )}
                            <span
                                css={[
                                    sText.subtitle,
                                    oneLiner && sText.oneLiner,
                                    is12 && sText.oneLineri12,
                                ]}
                            >
                                {description}
                            </span>
                            {additionalComponent && additionalComponent}
                        </div>
                        <ExpandMoreIcon
                            css={[
                                sExpandMoreIcon,
                                isOpen
                                    ? { transform: 'rotate(180deg)' }
                                    : { transform: 'rotate(0deg)' },
                                link && { transform: 'rotate(-90deg)' },
                            ]}
                        />
                    </div>
                </ButtonBase>
                <Collapse in={isOpen} css={sCollapseOuter}>
                    <div
                        css={[
                            children && sCollapseInner,
                            !noPadding && {
                                padding: '1rem',
                                [s.md]: {
                                    padding: '2rem',
                                },
                            },
                            isOpen
                                ? {
                                      borderColor: colors.gray400,
                                      transition: 'border-color 0.6s ease-out',
                                  }
                                : {
                                      borderColor: colors.gray400.concat(
                                          alpha[0],
                                      ),
                                      transition: 'border-color 0.15s ease-out',
                                  },
                        ]}
                    >
                        {wasOpen && children}
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export const CollapsableText = ({ children }) => (
    <div
        css={{
            lineHeight: '1.618em',
            color: colors.gray600,
            fontSize: '13px',
            fontWeight: 400,
            [s.sm_down]: { padding: '1.5rem' },
            [s.md]: { padding: '1.5rem 2rem' },
        }}
    >
        {children}
    </div>
);

const IconWrapper = styled.div`
    display: flex;
`;

const sContainerOuter = {
    transition: 'opacity 0.3 ease-out',
    overflow: 'hidden',
    maxWidth: globals.maxWidthMedium,
    borderRadius: '1rem',
    // backgroundColor: colors.gray100,
    // [s.md]: { backgroundColor: colors.gray200 },
    background: `linear-gradient(to right, ${colors.gray100}, ${colors.gray200})`,
};

const sButtonBase = {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '1rem',
    cursor: 'pointer',
    alignItems: 'start',
    width: '100%',
    textAlign: 'left',
};

const sContainer = {
    transition:
        'background-color 0.3s ease-out, border-radius 0.1s ease-out, opacity 0.3s ease-out',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '0.5rem',
    cursor: 'pointer',
    width: '100%',
    padding: '1rem',
    borderRadius: '1rem',
    [s.md]: {
        padding: '2rem',
    },
    '&:hover': {
        [s.hover]: {
            backgroundColor: colors.gray300.concat(alpha[100]),
        },
    },
};

const sIcon = {
    position: 'relative',
    fontSize: 24,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: colors.gray400,
    color: colors.text,
    flexShrink: 0,
};

export const sText = {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
    marginLeft: '0.75rem',
    [s.sm]: {
        marginLeft: '1rem',
    },
    width: '100%',
    title: {
        fontSize: '15px',
        fontWeight: 700,
        marginBottom: '0.25rem',
        [s.sm_down]: {
            letterSpacing: '-0.025em',
        },
        [s.ss]: {
            fontSize: '13px',
        },
    },
    subtitle: {
        fontWeight: 400,
        fontSize: '13px',
        color: colors.gray600,
        display: 'flex',
        alignItems: 'center',
        [s.ss]: {
            fontSize: '12px',
        },
    },
    oneLiner: {
        overflowX: 'scroll',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 'calc(100vw - 175px)',
        scrollbarWidth: 'none',
        [s.md_only]: { maxWidth: 'calc(50vw - 196px)' },
        [s.lg]: {
            maxWidth: 530,
        },
        '&::-webkit-scrollbar': {
            display: 'none',
        },

        '& -ms-overflow-style': {
            display: 'none',
        },
    },
    oneLineri12: {
        [s.md]: { maxWidth: 'calc(100vw - 196px)' },
    },
    // onLinerDesktop: {
    //   overflow: 'hidden',
    //   textOverflow: 'ellipsis',
    //   whiteSpace: 'nowrap',
    //   maxWidth: 'calc(100vw - 175px)',
    //   [s.md]: { maxWidth: 'calc(50vw - 196px)' }
    // }
};

const sExpandMoreIcon = {
    marginLeft: '1rem',
    transition: 'transform 0.15s ease-out',
};

const sCollapseOuter = {
    width: '100%',
};

const sCollapseInner = {
    borderTop: `1px solid ${colors.gray400}`,
};

export const sImage = {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    flexShrink: 0,
    [s.md]: {
        width: 48,
        height: 48,
    },
    '&:hover': {
        img: {
            transform: 'scale(0.9)',
            filter: 'brightness(1.1)',
        },
    },

    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        borderRadius: '50%',
        transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
    },
};

export default Collapsable;
