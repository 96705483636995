export default function endWordHelper(word, int) {
    const a = parseInt(int);

    if (word === 'wynik') {
        if (a === 1) return `${int} ${word}`;
        if (a > 1 && a < 5) return `${int} ${word}i`;
        if (a === 0 || a >= 5) return `${int} ${word}ów`;
    } else if (word === 'osoba') {
        if (a === 1) return `${int} ${word}`;
        if (a > 1 && a < 5) return `${int} ${word.slice(0, word.length - 1)}y`;
        if (a === 0 || a >= 5)
            return `${int} ${word.slice(0, word.length - 3)}ób`;
    } else {
        if (a === 1) return `${int} ${word}`;
        if (a > 1 && a < 5) return `${int} ${word}y`;
        if (a === 0 || a >= 5) return `${int} ${word}ów`;
    }
}
