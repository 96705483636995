import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { makeStyles } from '@material-ui/core/styles';
import { ToastContainer, Slide } from 'react-toastify';
import smoothscroll from 'smoothscroll-polyfill';
import 'react-toastify/dist/ReactToastify.css';
import '@utils/yupMethods';

import { useRoutes } from './routes';
import { useTopNav } from './hooks/topnav.hook';
import { TopNavContext } from './context/topnav.context';

import { useGeolocationContext } from './EZC/context/Geolocation/Geolocation.provider';
import { resolveEnv } from '@helpers/resolveEnv';
import { useAuthService } from './services/AuthService';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useRedirectToNative } from '@hooks/useRedirectToNative';
import { PushNotificationProvider } from './services/PushNotificationService/PushNotificationProvider';
import { MOBILE, __DEV__ } from '@constants/common.constants';
import { useSentryInitialize } from './services/SentryService';
import { useStatusBar } from '@hooks/useStatusBar';
import { useForceUpdate } from '@hooks/useForceUpdate/useForceUpdate';
import DisableNumInputScroll from '@/componentsV2/DisableNumInputScroll/DisableNumInputScroll';
import { usePossibilityToPlaySounds } from './services/SoundService/hooks/usePossibilityToPlaySounds';
import { WebPushNotificationProvider } from './services/WebPushNotificationService/WebPushNotificationProvider';
import { Metadata } from './componentsV2/atoms/Metadata/Metadata';
import { __DEV__ModifyToken } from './componentsV2/__DEV__ModifyToken/__DEV__ModifyToken';
import { useTrackingPwaForGa } from '@hooks/useTrackingPwaForGa';
import { Smartlook } from '@awesome-cordova-plugins/smartlook';
import { useKeyboardListener } from './services/KeyboardService/useKeyboardListener';
import { useDeeplinkListener } from '@hooks/useDeeplinkListener';
import { useServerConfigInitialize } from './services/ServerConfigService/useServerConfigInitialize';
import { useHideKeyboard } from '@hooks/useHideKeyboard';
import { ReactTagManager } from 'react-gtm-ts';

smoothscroll.polyfill();

Smartlook.setProjectKey({ key: resolveEnv('REACT_APP_SMART_LOOK_KEY') });
Smartlook.start();

ReactTagManager.init({
    code: resolveEnv('REACT_APP_GOOGLE_TAG_MANAGER'),
    debug: false,
    performance: false,
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function App() {
    const { top } = useSafeAreaInsets();
    useRedirectToNative();

    useTrackingPwaForGa();

    useStatusBar();

    useForceUpdate();

    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    const { handleLocation, setGeolocation } = useGeolocationContext();

    usePossibilityToPlaySounds();

    const { scrolled, data, animBack, setScrolled, setData, setAnimBack } =
        useTopNav();

    // SUPER HYPER IMPORTANT - withour this Material UI and Emotion doesn't work and whole UI breaks!!!!
    const classes = useStyles();

    const routes = useRoutes(isLoggedIn);

    const passwordLessEnvs = () =>
        resolveEnv('REACT_APP_NODE_ENV') === 'LOCAL_DEV' ||
        resolveEnv('REACT_APP_NODE_ENV') === 'TEST2' ||
        resolveEnv('REACT_APP_NODE_ENV') === 'TEST';
    // TODO: add feature flag
    // const [isAccess, setIsAccess] = useState(passwordLessEnvs);

    useSentryInitialize();
    useServerConfigInitialize();

    useKeyboardListener();

    useDeeplinkListener();

    useHideKeyboard();

    useEffect(() => {
        handleLocation(true, setGeolocation);
    }, []);
    // TODO: add feature flag
    // if (isAccess || isAuth)
    return (
        <TopNavContext.Provider
            value={{
                scrolled,
                setScrolled,
                data,
                setData,
                animBack,
                setAnimBack,
            }}
        >
            {MOBILE ? (
                <PushNotificationProvider />
            ) : (
                <WebPushNotificationProvider />
            )}

            {__DEV__ && (
                <__DEV__ModifyToken
                    // !important Don't push it to git!
                    secret=""
                />
            )}
            <DisableNumInputScroll />
            <Metadata
                title="Eatzon - Twoja wyszukiwarka jedzenia"
                description="Ezn"
            />
            {routes}

            <ToastContainer
                style={{ transform: `translateY(${top}px)` }}
                transition={Slide}
                position="top-center"
                autoClose={6000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TopNavContext.Provider>
    );
    // TODO: add feature flag
    // return <PasswordProtected isAccess={isAccess} setIsAccess={setIsAccess} />;
}

export default App;
