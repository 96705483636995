/** @jsx jsx */ /** @jsxRuntime classic */
import { jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import React, { useContext, useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { NavLink } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import PersonIcon from '@material-ui/icons/Person';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Badge } from '@components/Elements';
import { colors, s, alpha } from '@style';
import { useNoAuthOrdersContext } from '@/EZC/context/NoAuthOrders/NoAuthOrders.provider';
import { ActiveOrdersListenerContext } from '@/EZC/context/ActiveOrdersListener/ActiveOrdersListener.context';
import { useAuthService } from '@/services/AuthService';
import { useCartCountQuery } from '@graphql/generated';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useClickNavLink } from '@/EZC/hooks/useClickNavLink';
import { useUnsettledPayoffs } from '@/EZC/hooks/useUnsettledPayoffs';
import { CircularProgress } from '@material-ui/core';
import { useOrderInCartByRestaurant } from '@/EZC/context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

export default function LabelBottomNavigation() {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const waiterView = useWaiterService((state) => state.waiterView);

    const clickHomeLink = useClickNavLink('/');

    const { data: cartData } = useCartCountQuery({
        variables: {
            pagination: {
                page: 1,
                pageSize: 1,
            },
        },
        skip: !isLoggedIn,
    });
    const { bottomNavigation } = useSafeAreaInsets();
    const { isUnsettledPayoff } = useUnsettledPayoffs();

    const { ordersNoAuth } = useNoAuthOrdersContext();
    const { attentionOrderIds } = useContext(ActiveOrdersListenerContext);
    const { loadingOrders } = useOrderInCartByRestaurant();

    const totalCount = cartData?.cartList?.metadata?.totalCount;
    const cartListData = cartData?.cartList?.data;

    const productsInOneItemCart =
        cartData &&
        totalCount === 1 &&
        cartListData
            ?.map((order) => order?.lines?.map((product) => product.quantity))
            .reduce((acc, val) => acc.concat(val || []), [])
            .reduce((sum, curr) => sum + curr, 0);

    const productsInCartNoAuth =
        ordersNoAuth.length === 1 &&
        ordersNoAuth
            .map((order) => order?.lines?.map((product) => product.quantity))
            .reduce((acc, val) => acc.concat(val || []), [])
            .reduce((sum, curr) => sum + curr, 0);

    const totalItemsAuth = totalCount > 1 ? totalCount : productsInOneItemCart;

    const totalItemsNoAuth =
        ordersNoAuth.length > 1 ? ordersNoAuth.length : productsInCartNoAuth;

    const totalItems = isLoggedIn ? totalItemsAuth : totalItemsNoAuth;

    const accountPath = !isLoggedIn
        ? waiterView
            ? '/waiter'
            : '/account'
        : '/account';

    return (
        <React.Fragment>
            <ButtonBase css={sNav(bottomNavigation)}>
                <div css={sContainer}>
                    <NavLink
                        exact
                        onClick={clickHomeLink}
                        to="/"
                        activeStyle={sActiveNavLink}
                    >
                        <HomeIcon fontSize="inherit" color="inherit" />
                    </NavLink>

                    <NavLink to="/search" activeStyle={sActiveNavLink}>
                        <SearchIcon fontSize="inherit" color="inherit" />
                    </NavLink>
                    <NavLink to="/cart" activeStyle={sActiveNavLink}>
                        <ShoppingCartIcon fontSize="inherit" color="inherit" />
                        <Badge
                            color={colors.green}
                            show={totalItems > 0}
                            showAnimation
                        >
                            {loadingOrders ? (
                                <CircularProgress
                                    size={'10px'}
                                    thickness={5}
                                    color="white"
                                />
                            ) : (
                                totalItems > 0 && totalItems
                            )}
                        </Badge>
                    </NavLink>
                    <NavLink to="/orders" activeStyle={sActiveNavLink}>
                        <LocalDiningIcon fontSize="inherit" color="inherit" />
                        {isLoggedIn && (
                            <Badge
                                color={colors.orange}
                                show={attentionOrderIds?.length > 0}
                                showAnimation
                            >
                                {attentionOrderIds?.length > 0 &&
                                    attentionOrderIds.length}
                            </Badge>
                        )}
                    </NavLink>
                    <NavLink to={accountPath} activeStyle={sActiveNavLink}>
                        <PersonIcon fontSize="inherit" color="inherit" />
                        {isLoggedIn && (
                            <Badge
                                color={colors.orange}
                                show={isUnsettledPayoff}
                                showAnimation
                                small
                            />
                        )}
                    </NavLink>
                </div>
            </ButtonBase>
        </React.Fragment>
    );
}

const sNav = (bottomInsets = 0) => {
    return {
        paddingBottom: bottomInsets - 72,
        boxSizing: 'border-box',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 1100,
        width: '100vw',
        height: bottomInsets,
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '2rem 2rem 0 0',
        boxShadow: `0px -4px 16px ${colors.text.concat(alpha[8])}`,
        color: colors.green,
        cursor: 'default',

        [s.sm]: {
            boxShadow: `0px -4px 64px ${colors.text.concat(alpha[8])}`,
        },

        '.active:hover': {
            [s.hover]: {
                filter: 'saturate(150%) contrast(120%)',
            },
        },

        '.MuiTouchRipple-rippleVisible': {
            animationDuration: '1.2s',
        },
    };
};

const sLink = {
    transition:
        'color 0.2s ease-out, background-color 0.2s ease-out, filter 0.2s ease-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    color: colors.text,
    fontSize: 32,
    borderRadius: '50%',
    position: 'relative',

    '&:hover': {
        backgroundColor: colors.gray200,
        color: colors.text.concat(alpha[60]),
    },
};

const sContainer = {
    width: '100%',
    height: '100%',
    maxWidth: 960,
    display: 'flex',

    alignItems: 'center',
    // backgroundColor: '#fff',
    padding: '0 1.5rem',

    [s.xs]: { justifyContent: 'space-between' },
    [s.sm]: { justifyContent: 'space-evenly' },

    a: { ...sLink },
};

const sActiveNavLink = {
    backgroundColor: colors.green200,
    color: colors.green,

    '&:hover': {
        backgroundColor: 'red',
        filter: 'brightness(120%) contrast(1.5)',
    },
};
