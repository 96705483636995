import { useMemo } from 'react';
import {
    parseCategoryToPreferenceChip,
    parseIngredientsToPreferenceChip,
} from './FoodPreferences.helpers';
import { DIET_NAME } from '@constants/fromGraphql/Diet.constants';
import { CUISINE_NAME } from '@constants/fromGraphql/Cuisine.constants';
import { FLAVOUR_NAME } from '@constants/fromGraphql/Flavor.constants';
import {
    FoodPreferencesPageQuery,
    IngredientPreferencesListQuery,
} from '@graphql/generated';

interface UseFoodPreferencesDataArgs {
    preferences: FoodPreferencesPageQuery['user']['preferences'] | undefined;
    ingredientsData: IngredientPreferencesListQuery['list']['data'] | undefined;
    allergiesData: IngredientPreferencesListQuery['list']['data'] | undefined;
}

export const useFoodPreferencesData = ({
    preferences,
    allergiesData,
    ingredientsData,
}: UseFoodPreferencesDataArgs) => {
    const dietChips = useMemo(
        () =>
            parseCategoryToPreferenceChip({
                value: DIET_NAME,
                preffered: preferences?.diet?.preffered,
            }),
        [DIET_NAME, JSON.stringify(preferences?.diet)],
    );

    const cuisineChips = useMemo(
        () =>
            parseCategoryToPreferenceChip({
                value: CUISINE_NAME,
                preffered: preferences?.cuisine?.preffered,
                notPreffered: preferences?.cuisine?.notPreffered,
            }),
        [CUISINE_NAME, JSON.stringify(preferences?.cuisine)],
    );

    const flavorChips = useMemo(
        () =>
            parseCategoryToPreferenceChip({
                value: FLAVOUR_NAME,
                preffered: preferences?.flavor?.preffered,
                notPreffered: preferences?.flavor?.notPreffered,
            }),
        [FLAVOUR_NAME, JSON.stringify(preferences?.flavor)],
    );

    const ingredientsChips = useMemo(
        () =>
            parseIngredientsToPreferenceChip({
                value:
                    ingredientsData
                        ?.concat(preferences?.ingredients?.preffered || [])
                        .concat(preferences?.ingredients?.notPreffered || []) ||
                    [],
                preffered: preferences?.ingredients?.preffered,
                notPreffered: preferences?.ingredients?.notPreffered,
            }),
        [
            ingredientsData,
            JSON.stringify(preferences?.ingredients?.notPreffered),
            JSON.stringify(preferences?.ingredients?.preffered),
        ],
    );

    const allergiesChips = useMemo(
        () =>
            parseIngredientsToPreferenceChip({
                value:
                    allergiesData?.concat(preferences?.allergies || []) || [],
                notPreffered: preferences?.allergies || [],
            }),
        [allergiesData, JSON.stringify(preferences?.allergies)],
    );

    const spicinessRange: [number, number] = useMemo(
        () => [
            preferences?.spiciness?.min ?? 0,
            preferences?.spiciness?.max ?? 5,
        ],
        [JSON.stringify(preferences?.spiciness)],
    );

    return {
        dietChips,
        cuisineChips,
        flavorChips,
        ingredientsChips,
        allergiesChips,
        spicinessRange,
    };
};
