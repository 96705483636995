import { useRestauratorProductListLazyQuery } from '@graphql/generated';
import { useDebounce } from '@hooks/useDebounce';
import { useEffect, useState } from 'react';
import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export const useProductsList = () => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const searchDebounce = useDebounce(search, 200);
    const restaurantId = useAuthService((state) => state.restaurantId);

    const [getProductsList, { data, loading, error }] =
        useRestauratorProductListLazyQuery({
            fetchPolicy: 'cache-and-network',
        });

    const getProducts = (search: string, page: number, rowsPerPage: number) => {
        getProductsList({
            variables: {
                input: {
                    payload: {
                        name: search,
                        restaurantId,
                        applyUserPreferences: false,
                        includeDeleted: true,
                    },
                },
                pagination: { page, pageSize: rowsPerPage },
                pagePagination: true,
            },
            onError: graphqlErrorHandler(true),
        });
    };

    const handleChangeRowsPerPage = (
        e: React.MouseEvent<HTMLButtonElement> | null,
    ) =>
        //@ts-expect-error to do
        setRowsPerPage(parseInt(e?.target?.value));
    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        page: number,
    ) => {
        setCurrentPage(page + 1);
    };

    useEffect(() => {
        searchDebounce && setCurrentPage(1);
        getProducts(searchDebounce, currentPage, rowsPerPage);
    }, [searchDebounce, currentPage]);

    useEffect(() => {
        setCurrentPage(1);
        getProducts(searchDebounce, 1, rowsPerPage);
    }, [rowsPerPage]);

    const handleSearch = (e: any) => setSearch(e?.target?.value ?? '');

    return {
        products: data?.productList.data ?? [],
        currentPage: currentPage - 1,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,
        productsCount: data?.productList.metadata.totalCount ?? 0,
        loading,
        error,
        searchString: search,
        onSearchString: handleSearch,
    };
};
