import { onError } from '@apollo/client/link/error';
import {
    ApolloLink,
    DefaultContext,
    FetchResult,
    Observable,
} from '@apollo/client';
import { OPERATIONS } from '@graphql/generated';

export interface CreateRefreshLink {
    fetchNewSessionToken: (
        refreshToken: string,
        sessionToken: string,
    ) => Promise<void>;
    getRefreshToken: () => string;
    getSessionToken: () => string;
    onRefreshError: () => void;
    onTokenExpiredNoRefresh: () => void;
    setContextCodition: (context: DefaultContext) => boolean;
}

let isRefreshing = false;

const omittedOperations: string[] = [
    OPERATIONS.Mutation.AuthSignOut,
    OPERATIONS.Mutation.UserRefreshToken,
    OPERATIONS.Mutation.WaiterRefreshToken,
];

export const createRefreshLink = ({
    fetchNewSessionToken,
    getRefreshToken,
    getSessionToken,
    onRefreshError,
    onTokenExpiredNoRefresh,
    setContextCodition,
}: CreateRefreshLink): ApolloLink =>
    onError(({ graphQLErrors, operation, forward }) => {
        const contextCodition = setContextCodition(operation.getContext());
        const isOmittedOperation = omittedOperations.includes(
            operation.operationName,
        );

        if (
            contextCodition &&
            graphQLErrors &&
            !isRefreshing &&
            !isOmittedOperation
        ) {
            for (const err of graphQLErrors) {
                if (
                    err?.extensions?.code &&
                    err?.extensions?.code === 'UNAUTHENTICATED'
                ) {
                    const observable = new Observable<
                        FetchResult<Record<string, any>>
                    >((observer) => {
                        (async () => {
                            try {
                                isRefreshing = true;

                                const sessionToken = getSessionToken();
                                const refreshToken = getRefreshToken();

                                if (!sessionToken || !refreshToken) {
                                    onTokenExpiredNoRefresh();
                                    return observer.error(err);
                                }

                                await fetchNewSessionToken(
                                    refreshToken,
                                    sessionToken,
                                );

                                const subscriber = {
                                    next: observer.next.bind(observer),
                                    error: observer.error.bind(observer),
                                    complete: observer.complete.bind(observer),
                                };
                                forward(operation).subscribe(subscriber);
                            } catch (err) {
                                onRefreshError();
                                observer.error(err);
                            } finally {
                                isRefreshing = false;
                            }
                        })();
                    });

                    return observable;
                }
            }
        }
    });
