import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

export const useClickNavLink = (link: string) => {
    const [canChangeView, setCanChangeView] = useState(true);

    const handleDebounce = debounce(() => {
        setCanChangeView(true);
    }, 1000);

    useEffect(() => {
        if (!canChangeView) {
            handleDebounce();
        }
    }, [canChangeView]);

    const location = useLocation();

    useEffect(() => {
        setCanChangeView(location.pathname !== link);
    }, [location.pathname]);

    const handleNavLink = (e: MouseEvent) => {
        if (canChangeView) {
            setCanChangeView(false);
        } else {
            e.preventDefault();
        }
    };

    return handleNavLink;
};
