import React from 'react'; // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router';
import {
    sTitle,
    sSubtitle,
    sContainer2,
    sContainerShown,
} from '../../EZC/context/ActiveOrdersListener/components/Popup';
import {
    sBackground,
    sContainer,
} from '../../components/Widget/Widget.component';
import ActiveOrderItem from '../../EZC/pages/orders/components/ActiveOrderItem';
import { Collapse, Grid } from '@material-ui/core';
import { Button } from '../../components/Elements';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { usePopupWaiterQuery } from '@graphql/generated';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import PersonIcon from '@material-ui/icons/Person';

const PopupWaiter = ({ onHide }) => {
    const history = useHistory();
    const {
        restaurantId = '',
        orders: { attentionCount },
    } = useWaiterService();

    const { data, loading } = usePopupWaiterQuery({
        context: { waiter: true },
        variables: {
            input: {
                restaurantId,
                filters: {
                    statuses: ['VERIFICATION', 'SENT_TO_RESTAURANT', 'PENDING'],
                },
            },
            pagination: { page: 1, pageSize: 5 },
        },
        fetchPolicy: 'network-only',
        onError: graphqlErrorHandler(true),
    });

    return (
        <React.Fragment>
            <div css={[sBackground]} onClick={onHide}></div>
            <div css={[sContainer, sContainer2, sContainerShown]}>
                <h1 css={sTitle}>Nowe Zamówienia</h1>
                <p css={sSubtitle}>
                    {attentionCount} zamówie
                    {attentionCount > 1
                        ? attentionCount > 5
                            ? 'ń'
                            : 'nia'
                        : 'nie'}{' '}
                    został
                    {attentionCount > 1
                        ? attentionCount > 5
                            ? 'o'
                            : 'y'
                        : 'o'}{' '}
                    zaktualizowa
                    {attentionCount > 1
                        ? attentionCount > 5
                            ? 'nych'
                            : 'ne'
                        : 'ne'}{' '}
                    i wymaga
                    {attentionCount > 1
                        ? attentionCount > 5
                            ? 'ją'
                            : 'ją'
                        : ''}{' '}
                    Twojej uwagi
                </p>
                <Collapse in={Boolean(attentionCount)}>
                    <div css={{ padding: '1rem 0 0' }}>
                        {loading && <SkeletonOrder />}
                        {data?.orders.data.map((order) => (
                            <Grid
                                container
                                key={order.id}
                                onClick={() => {
                                    history.push(`/waiter/order/${order.id}`);
                                    onHide();
                                }}
                            >
                                <ActiveOrderItem
                                    orderId={order.id}
                                    date={order.details.date}
                                    consumptionType={order.details.type}
                                    restaurantAvatar={
                                        order.owner?.personal.avatarUrl ? (
                                            <img
                                                src={
                                                    order.owner?.personal
                                                        .avatarUrl
                                                }
                                                alt={'user avatar'}
                                                loading="lazy"
                                            />
                                        ) : (
                                            <PersonIcon />
                                        )
                                    }
                                    restaurantName={`${
                                        order?.owner?.personal.name ?? ''
                                    } ${
                                        order?.owner?.personal.surname ?? ''
                                    }`.trim()}
                                    priceSum={order.priceSum}
                                    status={order.status}
                                    w100
                                />
                            </Grid>
                        ))}
                    </div>
                </Collapse>
                <div css={{ padding: '1rem 0 2rem' }}>
                    <Button
                        w100
                        w100Desktop
                        action={() => {
                            history.push(`/waiter/notifications`);
                            onHide();
                        }}
                    >
                        ZOBACZ WSZYSTKIE&nbsp;
                        <span css={{ opacity: 0.5 }}>(</span>
                        {attentionCount}
                        <span css={{ opacity: 0.5 }}>)</span>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PopupWaiter;
