import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import styled from '@emotion/styled';
import {
    Ingredient,
    useAdminIngredientListLazyQuery,
} from '@graphql/generated';
import {
    Button,
    CircularProgress,
    IconButton,
    Input,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@material-ui/core';
import { Create as CreateIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { mediaQueries } from '@style/breakpoints';
import { colors } from '@style/index';
import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react';
import { EZAdminIngredientsEditModal } from 'src/EZAdmin/domains/Ingredients/components/EZAdminIngredientUpdate/EZAdminIngredientUpdateModal';
import { EZAdminIngredientsDeleteModal } from 'src/EZAdmin/domains/Ingredients/components/EZAdminIngredientsDeleteModal/EZAdminIngredientsDeleteModal';

const ROWS_PER_PAGE = [10, 25, 50];

export const EZAdminIngredientsTable = () => {
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedIngredient, setSelectedIngredient] =
        useState<Ingredient | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');

    const [getIngredientList, { data, loading }] =
        useAdminIngredientListLazyQuery({
            fetchPolicy: 'cache-and-network',
        });

    const ingredientList = data?.ingredientList?.data;

    const emptyState = !search.length && !loading && !ingredientList?.length;
    const noSearchState =
        !!search.length && !loading && !ingredientList?.length;
    const showTable = !loading && !emptyState && !noSearchState;

    const getIngredients = (
        page: number,
        rowsPerPage: number,
        search?: string,
    ) => {
        getIngredientList({
            variables: {
                input: { payload: { name: search } },
                pagination: { page, pageSize: rowsPerPage },
                pagePagination: !search,
            },
            onError: (error) => graphqlErrorHandler(true)(error),
        });
    };

    const handleEditClick = (ingredient: Ingredient) => {
        setSelectedIngredient(ingredient);
        setEditModalOpen(true);
    };

    const handleDeleteClick = (ingredient: Ingredient) => {
        setSelectedIngredient(ingredient);
        setDeleteModalOpen(true);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event?.target?.value));
        setCurrentPage(1);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        page: number,
    ) => {
        setCurrentPage(page + 1);
    };

    const handleSearch = () => {
        getIngredients(currentPage, rowsPerPage, search);
    };

    const onEnterSearch = (event: KeyboardEvent<HTMLInputElement>) =>
        event.key === 'Enter' && handleSearch();

    useEffect(() => {
        getIngredients(currentPage, rowsPerPage, search);
    }, [currentPage, rowsPerPage]);

    useEffect(() => {
        setCurrentPage(1);
    }, [rowsPerPage, search]);

    return (
        <div>
            <SearchWrapper>
                <SearchInput
                    onChange={(event) => setSearch(event.target.value)}
                    onKeyUp={(event: KeyboardEvent<HTMLInputElement>) =>
                        onEnterSearch(event)
                    }
                    placeholder="Podaj nazwę składnika"
                    value={search}
                />
                <Button
                    variant="outlined"
                    color="default"
                    onClick={handleSearch}
                >
                    Szukaj
                </Button>
            </SearchWrapper>
            <TableContainer>
                <CustomTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nazwa składnika</TableCell>
                            <TableCell>Edycja</TableCell>
                            <TableCell>Usuwanie</TableCell>
                        </TableRow>
                    </TableHead>
                    {showTable && (
                        <TableBody>
                            {data?.ingredientList?.data?.map(
                                (ingredient: Ingredient) => (
                                    <TableRow key={ingredient.id}>
                                        <TableCell>{ingredient.name}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    handleEditClick(ingredient)
                                                }
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                onClick={() =>
                                                    handleDeleteClick(
                                                        ingredient,
                                                    )
                                                }
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ),
                            )}
                        </TableBody>
                    )}
                </CustomTable>
                {showTable && (
                    <PaginationWrapper>
                        <Pagination
                            count={
                                data?.ingredientList?.metadata?.totalCount ?? 0
                            }
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            page={currentPage - 1}
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={ROWS_PER_PAGE}
                        />
                    </PaginationWrapper>
                )}
                {loading && (
                    <LoaderWrapper>
                        <CircularProgress />
                        <Title>Trwa wyszukiwanie</Title>
                    </LoaderWrapper>
                )}
                {noSearchState && (
                    <NoResultsWrapper>
                        <Title>
                            Przepraszam, nie znaleziono żadnych wyników
                        </Title>
                        <Subtitle>
                            Upewnij się, że poprawnie wpisałeś nazwę składnika
                            lub zmień zapytanie.
                        </Subtitle>
                    </NoResultsWrapper>
                )}
                {emptyState && (
                    <NoResultsWrapper>
                        <Title>Nie dodano jeszcze żadnych składników</Title>
                    </NoResultsWrapper>
                )}
            </TableContainer>

            <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                <EZAdminIngredientsEditModal
                    closeModal={() => setEditModalOpen(false)}
                    id={selectedIngredient?.id || ''}
                    name={selectedIngredient?.name || ''}
                />
            </Modal>

            <Modal
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
            >
                <EZAdminIngredientsDeleteModal
                    closeModal={() => setDeleteModalOpen(false)}
                    id={selectedIngredient?.id || ''}
                />
            </Modal>
        </div>
    );
};

const SearchWrapper = styled.div`
    display: flex;
    gap: 8px;
    margin-bottom: 32px;
    width: 100%;

    ${mediaQueries.xs} {
        margin: 0;
        padding: 0 24px 32px 24px;
    }
`;

const CustomTable = styled(Table)`
    border-collapse: initial;
    border-radius: 4px;
    border: 1px solid ${colors.alto};

    ${mediaQueries.xs} {
        border: none;
    }

    th:first-child,
    td:first-child {
        width: 80%;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 10%;
        text-align: center;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 10%;
        text-align: center;
    }
`;

const SearchInput = styled(Input)`
    min-width: 200px;
`;

const NoResultsWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 56px;

    ${mediaQueries.xs} {
        padding: 0 15px;
    }
`;

const Title = styled.h2`
    font-size: 24px;
    font-weight: 400;
    text-align: center;

    ${mediaQueries.xs} {
        font-size: 14px;
    }
`;

const Subtitle = styled.h3`
    font-size: 16px;
    font-weight: 400;
    margin-top: 8px;
    text-align: center;

    ${mediaQueries.xs} {
        font-size: 12px;
    }
`;

const LoaderWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    margin-top: 160px;
    width: 100%;

    ${mediaQueries.xs} {
        margin-top: 80px;
    }
`;

const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    ${mediaQueries.xs} {
        justify-content: center;
    }
`;

const Pagination = styled(TablePagination)`
    .MuiToolbar-root {
        .MuiInputBase-root {
            ${mediaQueries.xs} {
                margin: 0 10px 0 5px;
            }
        }

        .MuiTablePagination-actions {
            ${mediaQueries.xs} {
                margin: 0;
            }
        }
    }
`;
