import React, { useContext } from 'react'; // eslint-disable-line no-unused-vars
import { NavLink } from 'react-router-dom';

import NotificationsIcon from '@material-ui/icons/Notifications';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import HomeIcon from '@material-ui/icons/Home';
import HistoryIcon from '@material-ui/icons/History';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Badge } from '../../components/Elements';
import { colors, s, alpha } from '../../style';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

export default function LabelBottomNavigation() {
    const { isLoggedIn, orders } = useWaiterService();
    const { bottomNavigation } = useSafeAreaInsets();
    const attentionCount = orders.attentionCount;
    const activeCount = orders.activeCount;

    return (
        <React.Fragment>
            <ButtonBase
                css={[
                    sNav(bottomNavigation),
                    !isLoggedIn && { transform: 'translateY(100%)' },
                ]}
            >
                {isLoggedIn && (
                    <div css={sContainer}>
                        <NavLink
                            to="/waiter"
                            exact
                            activeStyle={sActiveNavLink}
                        >
                            <HomeIcon fontSize="inherit" color="inherit" />
                        </NavLink>
                        <NavLink
                            to="/waiter/notifications"
                            activeStyle={sActiveNavLink}
                        >
                            <NotificationsIcon
                                fontSize="inherit"
                                color="inherit"
                            />
                            <Badge
                                color={colors.orange}
                                show={!!attentionCount}
                                showAnimation
                            >
                                {attentionCount}
                            </Badge>
                        </NavLink>
                        <NavLink
                            to="/waiter/orders"
                            activeStyle={sActiveNavLink}
                        >
                            <LocalDiningIcon
                                fontSize="inherit"
                                color="inherit"
                            />
                            <Badge
                                color={colors.gray400}
                                show={!!activeCount}
                                showAnimation
                            >
                                {activeCount}
                            </Badge>
                        </NavLink>

                        <NavLink
                            to="/waiter/new-order"
                            activeStyle={sActiveNavLink}
                        >
                            <AddCircleIcon fontSize="inherit" color="inherit" />
                        </NavLink>
                        <NavLink
                            to="/waiter/history"
                            activeStyle={sActiveNavLink}
                        >
                            <HistoryIcon fontSize="inherit" color="inherit" />
                        </NavLink>
                    </div>
                )}
            </ButtonBase>
        </React.Fragment>
    );
}

const sNav = (bottomInsets = 0) => {
    return {
        paddingBottom: bottomInsets - 72,
        transition: 'transform 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
        boxSizing: 'border-box',
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 1100,
        width: '100vw',
        height: bottomInsets,
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '2rem 2rem 0 0',
        boxShadow: `0px -4px 16px ${colors.text.concat(alpha[8])}`,
        color: colors.orange,
        cursor: 'default',

        [s.sm]: {
            boxShadow: `0px -4px 64px ${colors.text.concat(alpha[8])}`,
        },

        '.active:hover': {
            [s.hover]: {
                filter: 'saturate(150%) contrast(120%)',
            },
        },

        '.MuiTouchRipple-rippleVisible': {
            animationDuration: '1.2s',
        },
    };
};

const sLink = {
    transition:
        'color 0.2s ease-out, background-color 0.2s ease-out, filter 0.2s ease-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    color: colors.text,
    fontSize: 30,
    borderRadius: '50%',
    position: 'relative',

    '&:hover': {
        backgroundColor: colors.gray200,
        color: colors.text.concat(alpha[60]),
    },
};

const sContainer = {
    width: '100%',
    height: '100%',
    maxWidth: 960,
    display: 'flex',

    alignItems: 'center',
    // backgroundColor: '#fff',
    padding: '0 1.5rem',

    [s.xs]: { justifyContent: 'space-between' },
    [s.sm]: { justifyContent: 'space-evenly' },

    a: { ...sLink },
};

const sActiveNavLink = {
    backgroundColor: colors.orange150,
    color: colors.orange,

    '&:hover': {
        filter: 'brightness(120%) contrast(1.5)',
    },
};
