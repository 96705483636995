import { Diet } from '@/graphql/generated';

export const DIET_ENUM: Record<Diet, Diet> = {
    GLUTEN_FREE: 'GLUTEN_FREE',
    LACTOSE_FREE: 'LACTOSE_FREE',
    MEAT: 'MEAT',
    SUGAR_FREE: 'SUGAR_FREE',
    VEGAN: 'VEGAN',
    VEGETARIAN: 'VEGETARIAN',
    DASH: 'DASH',
    FAT_FREE: 'FAT_FREE',
    HIGH_CALORIE: 'HIGH_CALORIE',
    HIGH_PROTEIN: 'HIGH_PROTEIN',
    KETOGENIC: 'KETOGENIC',
    LIGHT: 'LIGHT',
    LOW_CALORIE: 'LOW_CALORIE',
    LOW_CARB: 'LOW_CARB',
    LOW_FAT: 'LOW_FAT',
    LOW_PROTEIN: 'LOW_PROTEIN',
    PESCATARIAN: 'PESCATARIAN',
    RAW: 'RAW',
};

export const DIET: Record<Diet, { name: string }> = {
    GLUTEN_FREE: { name: 'bezglutenowa' },
    LACTOSE_FREE: { name: 'bez laktozy' },
    MEAT: { name: 'mięsna' },
    SUGAR_FREE: { name: 'bez cukru' },
    VEGAN: { name: 'wegańska' },
    VEGETARIAN: { name: 'wegetariańska' },
    DASH: {
        name: 'DASH',
    },
    FAT_FREE: {
        name: 'beztłuszczowa',
    },
    HIGH_CALORIE: {
        name: 'wysokokaloryczna',
    },
    HIGH_PROTEIN: {
        name: 'wysokobiałkowa',
    },
    KETOGENIC: {
        name: 'ketogeniczna',
    },
    LIGHT: {
        name: 'lekkostrawna',
    },
    LOW_CALORIE: {
        name: 'niskokaloryczna',
    },
    LOW_CARB: {
        name: 'niskowęglowodanowa',
    },
    LOW_FAT: {
        name: 'niskotłuszczowa',
    },
    LOW_PROTEIN: {
        name: 'niskobiałkowa',
    },
    PESCATARIAN: {
        name: 'peskatariańska',
    },
    RAW: {
        name: 'surowa',
    },
};

export const DIET_NAME: Partial<Record<Diet, string>> = Object.entries(
    DIET,
).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
    {},
);

export const DIET_NAME_VALUES = Object.entries(DIET_NAME).map(([id, name]) => ({
    id,
    name,
})) as { id: Diet; name: string }[];

export const DIET_KEYS = Object.keys(DIET) as Diet[];
