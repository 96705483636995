import React from 'react';
import { Link } from 'react-router-dom';
import { colors, alpha, s } from '../../../style';
import { Bullets } from '../../../components/Elements';
import { AverageRating } from '@/componentsV2/atoms/AverageRating/AverageRating';
import styled from '@emotion/styled';
import { productHelpers } from '@helpers/product.helpers';
import { BadgeWithScore } from '@/componentsV2/atoms/BadgeWithScore/BadgeWithScore';
import { ProductAvatar } from '@/svgs/ProductAvatar';
import { useCarouselProductTileWidth } from '@hooks/useCarouselProductTileWidth';
import { EatzonIcon } from '@/componentsV2/atoms/EatzonIcon/EatzonIcon';

// TODO: refactor
export const ProdItemMiniV2 = ({ data, id, last, showBadge, removeMargin }) => {
    const photoUrl = data?.photoUrl;
    const restAvatarUrl = data?.restaurant?.avatarUrl;
    const price = data?.price;
    const name = data?.name;
    const isBrochure = data?.restaurant?.isBrochure;

    const categoriesShow = productHelpers.getCategories(data);

    const productItemWidth = useCarouselProductTileWidth();

    return (
        <React.Fragment>
            <div css={[{ scrollSnapAlign: 'start' }]}>
                <Link
                    to={`/product/${data?.id}`}
                    css={[
                        sContainer,
                        id === 0 && {
                            [s.sm_down]: { marginLeft: '1.5rem' },
                        },
                        {
                            animation: `fadeIn 0.3s ease-in-out ${
                                id * 0.15 * 0 - id * 0.05 * 0
                            }s alternate`,
                        },
                        id === 0 &&
                            removeMargin && {
                                marginLeft: '0',
                                [s.sm_down]: { marginLeft: '0' },
                            },
                        { width: productItemWidth },
                    ]}
                >
                    <div
                        css={[
                            sImageContainer,

                            !photoUrl && { backgroundColor: colors.gray300 },
                            !photoUrl &&
                                restAvatarUrl && {
                                    height: 128,
                                    img: { objectPosition: 'center center' },
                                },
                        ]}
                    >
                        {showBadge && (
                            <Wrapper>
                                <BadgeWithScore />
                            </Wrapper>
                        )}
                        {photoUrl && (
                            <img src={photoUrl} alt={name} loading="lazy" />
                        )}
                        {!photoUrl && restAvatarUrl && (
                            <img
                                src={restAvatarUrl}
                                alt={name}
                                loading="lazy"
                            />
                        )}
                        {!photoUrl && !restAvatarUrl && <ProductAvatar />}
                        <EatzonIcon
                            isInactive={isBrochure}
                            size={10}
                            position="absoluteLeft"
                            space={4}
                        />
                    </div>
                    <div css={sTextContainer}>
                        <div css={{ padding: '0.5rem' }}>
                            <h3 css={sTextContainer.title}>{name}</h3>
                            <p css={sTextContainer.categories}>
                                <Bullets array={categoriesShow} />
                            </p>
                        </div>
                        <ProductInfoWrapper>
                            <Price>
                                {price?.toFixed(2)}
                                <span> zł</span>
                            </Price>
                            <AverageRating
                                average={data?.rating ?? 0}
                                ratings={data?.ratingsCount ?? 0}
                            />
                        </ProductInfoWrapper>
                    </div>
                </Link>
            </div>
            {last === 1 && <div css={sLast} />}
        </React.Fragment>
    );
};

const Wrapper = styled.div`
    position: absolute;
    top: 8px;
    left: 8px;
`;

const sContainer = {
    height: 196,
    backgroundColor: colors.gray200,
    borderRadius: 12,
    flexShrink: 0,
    marginLeft: 12,
    transition: 'background-color 0.3s ease-out, box-shadow 0.3s ease-out',
    WebkitTransition:
        'background-color 0.3s ease-out, box-shadow 0.3s ease-out',

    '&:hover': {
        backgroundColor: colors.gray300,
        img: {
            transform: 'scale(1.1)',
            WebkitTransition: 'scale(1.1)',
        },
    },

    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
};

const sImageContainer = {
    width: '100%',
    height: 144, // height: 128?
    position: 'relative',
    borderRadius: 'inherit',
    overflow: 'hidden',
    willChange: 'transform',

    img: {
        transition: 'transform 0.3s ease-out',
        transition: '-webkit-transform 0.3s ease-out',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center bottom',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
    },
};

const sTextContainer = {
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    position: 'absolute',

    left: 0,
    bottom: 0,
    zIndex: 2,

    background: `linear-gradient(to right, ${colors.gray100.concat(
        alpha[90],
    )}, ${colors.white.concat(alpha[0])})`,

    backdropFilter: 'blur(12px)',
    width: '100%',
    color: colors.text,

    title: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 170 - 16,
        fontWeight: 700,
        fontSize: '13px',
        letterSpacing: '-0.025em',
    },

    categories: {
        marginTop: 2,
        opacity: 0.6,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 170 - 16,
        fontSize: '12px',
        textTransform: 'capitalize',
    },
};

const sLast = {
    display: 'flex',
    flexShrink: 0,
    width: 194 - 12,
    height: 196,
    [s.xs]: {
        width: 209, // was 209
    },
};

const ProductInfoWrapper = styled.div`
    align-items: center;
    border-top: 1px solid #dfe1e4;
    display: flex;
    justify-content: space-between;
    padding: 2px 8px 6px;
`;

const Price = styled.span`
    font-size: 12px;
    font-weight: 700;

    span {
        opacity: 0.3;
    }
`;
