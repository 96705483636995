import React, { memo, ReactNode } from 'react';
import {
    Checkbox,
    CheckboxProps,
} from '@/componentsV2/atoms/Checkbox/Checkbox';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors } from '@/style';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';

export interface ListItemCheckboxProps
    extends Omit<CheckboxProps, 'title' | 'children'> {
    title: string;
    disabledItemInfo?: ReactNode;
    separator?: boolean;
}

export const ListItemCheckbox = memo(
    ({
        title,
        disabledItemInfo,
        disabled,
        separator,
        ...props
    }: ListItemCheckboxProps) => {
        return (
            <>
                <Checkbox {...props} disabled={disabled} width="100%">
                    <ContentWrapper>
                        <Title disabled={!!disabled}>{title}</Title>
                        {disabled && disabledItemInfo && (
                            <DisabledItemInfo>
                                {disabledItemInfo}
                            </DisabledItemInfo>
                        )}
                    </ContentWrapper>
                </Checkbox>
                {separator && <Separator color={colors.porcelainGray} />}
            </>
        );
    },
);

const ContentWrapper = styled.div`
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
`;

const Title = styled.p<{ disabled: boolean }>(
    ({ disabled }) => css`
        font-size: 13px;
        font-weight: 600;
        line-height: 24px;
        opacity: ${disabled ? 0.3 : 1};
    `,
);

const DisabledItemInfo = styled.p`
    font-size: 10px;
    text-align: right;
`;
