import { PayoffStatus } from '@/graphql/generated';

export const PAYMENT_STATUS_ENUM: Record<PayoffStatus, PayoffStatus> = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
};

export const PAYMENT_STATUS: Record<PayoffStatus, { name: string }> = {
    PENDING: {
        name: 'Przydzielone',
    },
    ACCEPTED: {
        name: 'Rozliczone',
    },
    REJECTED: {
        name: 'Odrzucone',
    },
};

export const PAYMENT_STATUS_NAME: Partial<Record<PayoffStatus, string>> =
    Object.entries(PAYMENT_STATUS).reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
        {},
    );

export const ORDER_STATUS_NAME_VALUES = Object.entries(PAYMENT_STATUS_NAME).map(
    ([id, name]) => ({ id, name }),
) as {
    id: PayoffStatus;
    name: string;
}[];
