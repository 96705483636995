/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Spinner from '@material-ui/core/CircularProgress';
import { globals, s } from '../../style';

const PageLoader = () => {
    return (
        <div
            css={{
                width: '100vw',
                height: `calc(100vh - ${globals.topnavHeight.desktop}px)`,
                [s.sm_down]: {
                    height: `calc(100vh - ${globals.topnavHeight.mobile}px)`,
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                svg: {
                    animation: 'fadeIn 1.4s ease-in-out both alternate',
                    // '@keyframes fadeIn': {
                    //   from: { opacity: 0 },
                    //   to: { opacity: 1 },
                    // },
                },
            }}
        >
            <Spinner size={96} thickness={6} />
        </div>
    );
};

export default PageLoader;
