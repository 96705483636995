import styled from '@emotion/styled';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SPICINESS_LEVELS } from '@constants/spicinessLevels';
import {
    RangeSlider,
    RangeSliderProps,
} from '@/componentsV2/molecules/RangeSlider/RangeSlider';

export interface RangeBlockProps {
    loading?: boolean;
    spicinessRangeProps: Pick<
        RangeSliderProps,
        'value' | 'onChange' | 'isDirty'
    >;
}

export const RangeBlock = ({
    loading,
    spicinessRangeProps,
}: RangeBlockProps) => {
    if (loading) return <Loading />;

    return (
        <RangeSlider
            {...spicinessRangeProps}
            marks={SPICINESS_LEVELS}
            max={5}
        />
    );
};

const Loading = styled(CircularProgress)`
    margin: 0 auto;
`;
