/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useRestaurantContext } from '../context/restaurant.provider';
import ImagePicker from '../../../components/ImagePicker';

const ImageBackground = () => {
    const { backgroundPreview, backgroundUrl, handleAddBackground } =
        useRestaurantContext();

    return (
        <ImagePicker
            right
            onChange={handleAddBackground}
            image={backgroundPreview}
            imageUrl={backgroundUrl}
            title="Tło"
            desc="Zdjęcie w tle"
            name="photofileBackground"
        />
    );
};

export default ImageBackground;
