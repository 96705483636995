/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { s, globals } from '@style';
import { Bullet, Bullets } from '@components/Elements';
import { AverageRating } from '@/componentsV2/atoms/AverageRating/AverageRating';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { DISH_ENUM } from '@constants/fromGraphql/Dish.constants';
import { SPICINESS_NAMES } from '@constants/spicinessLevels';
import { EatzonIcon } from '@/svgs/EatzonIcon';
import { colors } from '@style/index';

const Intro = ({
    address,
    product,
    photo,
    photoAvatar,
    title,
    categories = [],
    description,
    children,
    isBrochure,
}) => {
    const isLiquid = product?.dishes?.some((dish) =>
        [
            DISH_ENUM.ALCOHOL,
            DISH_ENUM.COLD_DRINK,
            DISH_ENUM.HOT_DRINK,
            DISH_ENUM.DRINK,
        ].includes(dish),
    );

    return (
        <div
            css={{
                position: 'relative',
                zIndex: 1,
                width: '100vw',
                maxWidth: globals.maxWidthMedium,
                margin: '0 auto',
                marginTop: globals.topnavHeight.mobile,
                [s.md]: { marginTop: globals.topnavHeight.desktop + 64 },
            }}
        >
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'row',
                    [s.sm_down]: { flexDirection: 'column' },
                }}
            >
                <div css={{ [s.md]: { width: '60%' } }}>
                    <div
                        css={{
                            // backgroundColor: 'red',
                            padding: '1.5rem 1.5rem 24px',
                            display: 'flex',
                            [s.md]: {
                                alignItems: 'center',
                            },
                            [s.md_only]: {
                                padding: '1.5rem 1.5rem 1rem',
                            },
                        }}
                    >
                        <div
                            css={{
                                borderRadius: '50%',
                                width: 64,
                                height: 64,
                                flexShrink: 0,
                                boxShadow: '0px 4px 8px rgba(0,0,0,0.08)',
                                [s.md]: {
                                    width: 96,
                                    height: 96,
                                },
                                img: {
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center center',
                                    // borderRadius: 32,
                                    borderRadius: '50%',
                                },
                            }}
                        >
                            {photoAvatar}
                        </div>
                        <div
                            css={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '1.5rem',
                                [s.md]: {
                                    marginLeft: '2rem',
                                },
                            }}
                        >
                            <h1
                                css={[
                                    {
                                        fontSize: '30px',
                                        fontWeight: 700,
                                        letterSpacing: '-0.05em',
                                        lineHeight: '1em',
                                        paddingBottom: '0.5rem',
                                        [s.ss]: {
                                            fontSize: '22px',
                                        },
                                        [s.lg]: {
                                            paddingBottom: '1rem',
                                            fontSize: '38px',
                                        },
                                    },
                                    title.length >= 20 && {
                                        [s.xs]: {
                                            fontSize: '22px',
                                        },
                                        [s.ss]: {
                                            fontSize: '18px',
                                        },
                                    },
                                    title.length >= 40 && {
                                        [s.xs]: {
                                            fontSize: '18px',
                                        },
                                        [s.ss]: {
                                            fontSize: '18px',
                                        },
                                        [s.sm]: {
                                            fontSize: '22px',
                                        },
                                        [s.lg]: {
                                            fontSize: '30px',
                                        },
                                    },
                                ]}
                            >
                                {title}
                            </h1>
                            {isBrochure && <Address>{address}</Address>}
                            {product && (
                                <AverageRatingWrapper>
                                    {!!product?.rating && (
                                        <EatzonIcon
                                            color={colors.orange}
                                            size={'16px'}
                                        />
                                    )}
                                    <AverageRating
                                        average={product?.rating ?? 0}
                                        ratings={product?.ratingsCount ?? 0}
                                    />
                                    {product?.wasOrdered &&
                                        !product?.myRate && (
                                            <>
                                                {!!product?.rating && (
                                                    <span>•</span>
                                                )}
                                                {product.myRate && (
                                                    <span>
                                                        {product.myRate}
                                                    </span>
                                                )}
                                                <ProductPageLink
                                                    to={`/product/${product?.id}/rating`}
                                                >
                                                    Oceń danie
                                                </ProductPageLink>
                                            </>
                                        )}
                                </AverageRatingWrapper>
                            )}
                            <div
                                css={[
                                    {
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        textTransform: 'capitalize',
                                        lineHeight: '1.618em',
                                        fontSize: '15px',
                                        [s.lg]: {
                                            fontSize: '18px',
                                        },
                                    },
                                    categories?.length > 2 && {
                                        fontSize: '13px',
                                    },
                                ]}
                            >
                                <Bullets array={categories} />
                            </div>
                        </div>
                    </div>
                    <Description>{description}</Description>
                    {product && (
                        <>
                            <DetailsDescription>
                                Stopień ostrości: {product.spiciness}/5{' '}
                                {SPICINESS_NAMES[product.spiciness]}
                            </DetailsDescription>
                            <DetailsDescription>
                                {product?.weight
                                    ? `${product?.weight} ${isLiquid ? 'ml' : 'g'}`
                                    : 'Gramatura: nie podano'}
                                {product?.calories && (
                                    <>
                                        <Bullet />
                                        {product?.calories} kcal
                                    </>
                                )}
                            </DetailsDescription>
                        </>
                    )}
                    {!isBrochure && (
                        <div
                            css={{
                                padding: '1.5rem 1.5rem 0',
                                [s.sm_down]: { textAlign: 'center' },
                                fontSize: '13px',
                                lineHeight: '1.618em',
                                [s.md]: {
                                    marginLeft: '8rem',
                                    paddingTop: '2.5rem',
                                },
                                [s.lg]: {
                                    fontSize: '15px',
                                },
                                [s.xl]: {
                                    maxWidth: '75%',
                                },
                            }}
                        >
                            {children}
                        </div>
                    )}
                </div>
                {photo && (
                    <div
                        css={{
                            padding: '1.5rem',
                            [s.sm_down]: {
                                paddingTop: '2rem',
                                paddingBottom: 0,
                            },
                            [s.md]: { width: '40%' },
                        }}
                    >
                        <div
                            css={{
                                width: '100%',
                                height: '100%',
                                paddingTop: '56.25%',
                                position: 'relative',
                                borderRadius: 24,
                                boxShadow: '-4px 8px 32px rgba(0,0,0,0.16)',
                                overflow: 'hidden',
                                '&:hover': {
                                    img: {
                                        transform: 'scale(1.1)',
                                        filter: 'brightness(1.1)',
                                    },
                                },
                            }}
                        >
                            <img
                                loading="lazy"
                                src={photo}
                                alt={title}
                                css={{
                                    transition:
                                        'transform 0.3s ease-in-out, filter 0.3s ease-in-out',
                                    borderRadius: 24,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center center',
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const AverageRatingWrapper = styled.div`
    align-items: end;
    display: flex;
    gap: 6px;
    margin: 4px 0 12px;
`;

const Description = styled.p`
    padding: 0 24px;
    opacity: 0.6;

    ${s.sm_down} {
        text-align: center;
    }
    font-size: 13px;

    ${s.md} {
        margin-left: 128px;
        padding-right: 48px;
    }
    ${s.lg} {
        font-size: 15px;
        padding-right: 96px;
    }
    ${s.xl} {
        max-width: 75%;
    }
`;

const DetailsDescription = styled(Description)`
    font-size: 13px;
    font-weight: 400;
    padding: 8px 24px 0;

    ${s.lg} {
        font-size: 15px;
    }
`;

const Address = styled.p`
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
`;

const ProductPageLink = styled(Link)`
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
`;
export default Intro;
