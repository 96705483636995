import { MealTime } from '@/graphql/generated';

export const MEAL_TIME_ENUM: Record<MealTime, MealTime> = {
    BREAKFAST: 'BREAKFAST',
    DINNER: 'DINNER',
    SUPPER: 'SUPPER',
    LUNCH: 'LUNCH',
    SECOND_BREAKFAST: 'SECOND_BREAKFAST',
};

export const MEAL_TIME: Record<MealTime, { name: string }> = {
    BREAKFAST: { name: 'śniadanie' },
    DINNER: { name: 'obiad' },
    SUPPER: { name: 'kolacja' },
    LUNCH: {
        name: 'lunch',
    },
    SECOND_BREAKFAST: {
        name: 'drugie śniadanie',
    },
};

export const MEAL_TIME_NAME: Partial<Record<MealTime, string>> = Object.entries(
    MEAL_TIME,
).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
    {},
);

export const MEAL_TIME_NAME_VALUES = Object.entries(MEAL_TIME_NAME).map(
    ([id, name]) => ({ id, name }),
) as {
    id: MealTime;
    name: string;
}[];

export const MEAL_TIME_KEYS = Object.keys(MEAL_TIME) as MealTime[];
