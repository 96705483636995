import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { useAuthService } from '../AuthService';
import { client } from '@/boot/client';
import { sentry } from '@/services/SentryService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

interface TestMode {
    isTestMode: boolean;
    isTesterActive: boolean;
    setIsTestMode: (isTestMode: boolean) => void;
    setIsTesterActive: (isTesterActive: boolean) => void;
}

export const useTestModeServiceStorage = create<TestMode>()(
    devtools(
        persist(
            (set, get) => ({
                isTestMode: false,
                isTesterActive: false,
                setIsTestMode: async (isTestMode) => {
                    if (get().isTestMode === isTestMode) return;

                    if (useAuthService.getState().isLoggedIn) {
                        await useAuthService.getState().logout(client, sentry);
                    }
                    if (useWaiterService.getState().isLoggedIn) {
                        await useWaiterService
                            .getState()
                            .logout(client, sentry);
                    }

                    set({
                        isTestMode,
                    });
                    location.reload();
                },
                setIsTesterActive: (isTesterActive) => {
                    if (get().isTestMode) {
                        get().setIsTestMode(false);
                    }

                    set({
                        isTesterActive,
                    });
                },
            }),
            {
                name: 'test_mode',
            },
        ),
    ),
);

export const AuthService = useAuthService.getState;
