import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { history } from '@/index';

interface GroupService {
    fromCart?: string;
    redirectToCart: () => void;
    setFromCart: (value: string) => void;
}

export const useGroupService = create<GroupService>()(
    devtools(
        persist(
            (set, get) => ({
                fromCart: undefined,
                redirectToCart: () => {
                    history.push(`/cart-checkout/${get().fromCart}`);
                    set({ fromCart: undefined });
                },
                setFromCart: (value) => {
                    set({
                        fromCart: value,
                    });
                },
            }),
            {
                name: 'group',
            },
        ),
    ),
);

export const GroupService = useGroupService.getState;
