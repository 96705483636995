export type ImageFormats = keyof typeof IMAGE_FORMATS;

export const IMAGE_FORMATS = {
    '128x128': '128x128',
    '240x240': '240x240',
    '600x480': '600x480',
    '1200x900': '1200x900',
    '800x600': '800x600',
    '300x180': '300x180',
};

export type ImageFileExtension =
    | 'avif'
    | 'bmp'
    | 'bpg'
    | 'cr2'
    | 'cur'
    | 'dcm'
    | 'flif'
    | 'gif'
    | 'heic'
    | 'ico'
    | 'jp2'
    | 'jpeg'
    | 'jpg'
    | 'jpm'
    | 'jpx'
    | 'jxr'
    | 'png'
    | 'psd'
    | 'tif'
    | 'webp';

/** 5 MB */
export const MAX_IMAGE_SIZE = 5242880;
export const MAX_FILE_SIZE = 5242880;
