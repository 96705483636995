/** @jsx jsx */ /** @jsxRuntime classic */
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useConfirmAccountMutation } from '@graphql/generated';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '@components/Spinner';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';

export default function () {
    const [confirmAccount] = useConfirmAccountMutation();

    const { token } = useParams();
    const history = useHistory();

    useEffect(() => {
        confirmAccount({
            variables: { input: { token } },
            onCompleted: () => {
                AnalyticsHelpers.createCustomEvent({
                    name: 'confirm_account',
                    params: { type: 'confirm' },
                });
                toast.success('Adres email został potwierdzony!');
                history.push('/login');
            },
            onError: () => {
                graphqlErrorHandler(true);
                history.push('/');
            },
        });
    }, [token]);

    return <Spinner size={2} />;
}
