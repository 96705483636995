import { useAuthService } from '@/services/AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { setContext } from '@apollo/client/link/context';
import { resolveEnv } from '@helpers/resolveEnv';
import { createUploadLink } from 'apollo-upload-client';
import { useTestModeServiceStorage } from '@/services/TestModeService/TestModeServiceStorage';
import { onError } from '@apollo/client/link/error';
import { sentry } from '@/services/SentryService';

export const directionalLink = createUploadLink({
    uri: (operation) => {
        const link = useTestModeServiceStorage.getState().isTestMode
            ? resolveEnv('REACT_APP_TEST_MODE_SERVER_URI')
            : resolveEnv('REACT_APP_SERVER_URI');

        return `${link}?${operation.operationName}`;
    },
});

export const authLink = setContext((_, { headers, waiter }) => {
    const token = waiter
        ? useWaiterService.getState().sessionToken
        : useAuthService.getState().sessionToken;

    return {
        headers: {
            ...headers,
            ...(!!token && {
                authorization: `Bearer ${token}`,
            }),
        },
    };
});

export const errorSentryLink = onError(({ graphQLErrors, operation }) => {
    graphQLErrors && sentry.captureGraphQlErrors(graphQLErrors, operation);
});
