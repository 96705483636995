import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from '@style/index';

type ButtonVariant = 'black' | 'gray';

export interface ButtonTextProps {
    className?: string;
    fontSize?: string;
    loading?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    text: string;
    underline?: boolean;
    variant: ButtonVariant;
}

export const ButtonText = ({
    className,
    loading,
    fontSize = '14px',
    onClick,
    text,
    underline,
    variant,
}: ButtonTextProps) => {
    return (
        <Button
            className={className}
            fontSize={fontSize}
            onClick={onClick}
            underline={underline}
            variant={variant}
            disabled={loading}
        >
            {loading && <Spinner spinnerVariant="default" size={16} />}
            {text}
        </Button>
    );
};

const Button = styled.button<{
    fontSize: string;
    underline?: boolean;
    variant: ButtonVariant;
}>(
    ({ fontSize, variant, underline }) => css`
        background: none;
        border: none;
        color: ${colors.text};
        cursor: pointer;
        flex-basis: auto;
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: ${fontSize};
        line-height: 20px;
        outline: none;
        text-decoration: ${underline ? 'underline' : 'none'};
        transition: color 0.3s ease-in-out;

        ${variant === 'gray' &&
        css`
            color: ${colors.baliHai};
        `}

        &:hover {
            color: ${colors.gray};
        }
    `,
);
