import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';

interface SeparatorProps {
    color?: string;
    margin?: string;
}
export const Separator = ({ color, margin }: SeparatorProps) => (
    <SeparatorElement color={color} margin={margin} />
);

const SeparatorElement = styled.div<{ color?: string; margin?: string }>(
    ({ color, margin }) => css`
        background-color: ${color ? color : colors.text};
        height: 1px;
        /* FIXME: we shouldn't use opacity here! */
        opacity: ${!!color ? 1 : 0.2};
        margin: ${margin ? margin : 0};
        width: 100%;
    `,
);
