/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { s } from '@/style';

const HeaderInfo = ({
    avatarUrl,
    finishChatLoading,
    name,
    onAdminFinishChat,
    onCloseChat,
    userId,
}) => (
    <div
        css={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #f0f2f5',
            padding: '4px 0',
            flexDirection: 'column',
            [s.sm]: { flexDirection: 'row' },
        }}
    >
        <Avatar alt={name} src={avatarUrl} />

        <div css={{ flexGrow: 1, paddingLeft: 12 }}>
            <p
                css={{
                    fontWeight: 700,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
            >
                {name}&nbsp;
                <span
                    css={{
                        fontSize: 12,
                        opacity: 0.3,
                        fontWeight: 400,
                        fontStyle: 'italic',
                    }}
                >
                    {userId}
                </span>
            </p>
        </div>

        {onAdminFinishChat && (
            <Button onClick={onAdminFinishChat} disabled={finishChatLoading}>
                Zakończ czat
            </Button>
        )}
        {onCloseChat && (
            <IconButton onClick={onCloseChat}>
                <CloseIcon />
            </IconButton>
        )}
    </div>
);

export default HeaderInfo;
