import { ChipVariant } from '@/componentsV2/atoms/Chip/Chip';
import { AccordionChipType } from '@/EZC/domains/Account/organisms/ChipPreferencesAccordion/ChipPreferencesAccordion';
import { uniqBy } from 'lodash';

type ExtendedPreferencesType = {
    id: string;
    name: string;
};

export type ParseCategoryToPreferenceChipArgs = {
    defaultVariant?: ChipVariant;
    notPreffered?: string[];
    preffered?: string[];
    value: Record<string, string>;
};

export const parseCategoryToPreferenceChip = ({
    defaultVariant = 'default',
    notPreffered,
    preffered,
    value,
}: ParseCategoryToPreferenceChipArgs): AccordionChipType[] => {
    const result: AccordionChipType[] = [];

    for (const key in value) {
        let variant: ChipVariant = defaultVariant;

        if (preffered?.some((item) => item === key)) variant = 'add';
        else if (notPreffered?.some((item) => item === key)) variant = 'remove';

        result.push({
            label: value[key],
            value: key,
            variant,
        });
    }

    return result;
};

export type ParseIngredientsToPreferenceChipArgs = {
    defaultVariant?: ChipVariant;
    notPreffered?: ExtendedPreferencesType[];
    preffered?: ExtendedPreferencesType[];
    value: ExtendedPreferencesType[];
};

export const parseIngredientsToPreferenceChip = ({
    defaultVariant = 'default',
    notPreffered,
    preffered,
    value,
}: ParseIngredientsToPreferenceChipArgs): AccordionChipType[] =>
    uniqBy(value, 'id').map(({ id, name }) => {
        let variant: ChipVariant = defaultVariant;

        if (preffered?.some((item) => item.id === id)) variant = 'add';
        else if (notPreffered?.some((item) => item.id === id))
            variant = 'remove';

        return {
            label: name,
            value: id,
            variant,
        };
    });
