import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

import { useNoAuthOrdersContext } from '../context/NoAuthOrders/NoAuthOrders.provider';
import { useAuthService } from '@/services/AuthService';
import {
    AllCartOrdersDocument,
    CartByRestaurantDocument,
    CartCountDocument,
    useCartAddMutation,
    useCartDecreaseQuantityItemMutation,
    useCartDeleteMutation,
} from '@graphql/generated';
import { storageName } from '@/EZC/context/NoAuthOrders/useNoAuthOrders';

export default function useOrdersMutations() {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    const {
        onCreateOrderAddProdNoAuth,
        onAddProdNoAuth,
        onDeleteProdNoAuth,
        onDeleteOrderNoAuth,
    } = useNoAuthOrdersContext();

    const [cartDelete] = useCartDeleteMutation();
    const [cartAdd] = useCartAddMutation();
    const [cartDecreaseQuantity] = useCartDecreaseQuantityItemMutation();

    // Order handlers
    const handleDeleteOrder = (data, cb) => {
        const { orderId } = data;

        if (!isLoggedIn) {
            onDeleteOrderNoAuth(data, cb);
            localStorage.removeItem(storageName);
            toast.warning('Zamówienie zostało usunięte', { autoClose: 6000 });
            return;
        }
        cartDelete({
            variables: { input: { cartId: orderId } },
            refetchQueries: [
                AllCartOrdersDocument,
                CartCountDocument,
                CartByRestaurantDocument,
            ],
            onCompleted: () =>
                toast.warning('Zamówienie zostało usunięte', {
                    autoClose: 6000,
                }),
            onError: graphqlErrorHandler(true),
        });
    };

    const handleCreateOrderAddProd = (
        data,
        cb,
        noauthProdData,
        noauthRestData,
    ) => {
        const { prodId } = data;

        if (!isLoggedIn) {
            onCreateOrderAddProdNoAuth(
                data,
                cb,
                noauthProdData,
                noauthRestData,
            );
            toast.success('🛍️ Dodano produkt do koszyka', { autoClose: 5000 });
            return;
        }
        cartAdd({
            variables: {
                input: {
                    productId: prodId,
                    quantity: 1,
                },
            },
            refetchQueries: [CartByRestaurantDocument, CartCountDocument],
            onError: graphqlErrorHandler(true),
        });
    };

    // Product handlers
    const handleAddProduct = (data, cb, noAuthProdData) => {
        const { prodId } = data;

        if (!isLoggedIn) {
            onAddProdNoAuth(data, cb, noAuthProdData);
            return;
        }

        cartAdd({
            variables: {
                input: {
                    productId: prodId,
                    quantity: 1,
                },
            },
            refetchQueries: [CartByRestaurantDocument, CartCountDocument],
            onError: graphqlErrorHandler(true),
        });
    };

    const handleDeleteProduct = (data, cb) => {
        const { prodId } = data;

        if (!isLoggedIn) {
            onDeleteProdNoAuth(data, cb);
            return;
        }

        cartDecreaseQuantity({
            variables: { input: { productId: prodId, quantity: 1 } },
            refetchQueries: [CartByRestaurantDocument, CartCountDocument],
            onError: graphqlErrorHandler(true),
        });
    };

    return {
        handleDeleteOrder,
        handleCreateOrderAddProd,
        handleAddProduct,
        handleDeleteProduct,
    };
}
