export const PaymentRequestIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <g clipPath="url(#paymentRequest)">
            <path
                fill="#343340"
                d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6Zm1 9h-4v1h3c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1h-1v1h-2v-1H9v-2h4v-1h-3c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h1V8h2v1h2v2Z"
            />
        </g>
        <defs>
            <clipPath id="paymentRequest">
                <rect width={24} height={24} fill="#fff" rx={4} />
            </clipPath>
        </defs>
    </svg>
);
