import React from 'react';

export const InfoShareLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={89} height={16} fill="none">
        <g fill="#FF4040" clipPath="url(#a)">
            <path d="M.355 15.604V6.438h2.84v9.166H.356ZM10.965 15.604V9.145H8.052v6.459H5.203V6.438h8.602v9.166h-2.84ZM16.719 9.097h-1.575V6.43h1.575V2.76h5.624v2.667h-2.776V6.43h2.776v2.667h-2.776v6.499H16.72V9.097ZM32.973 11.022c0 2.765-2.126 4.802-4.82 4.802-2.695 0-4.821-2.053-4.821-4.802 0-2.764 2.159-4.801 4.82-4.801 2.662 0 4.82 2.029 4.82 4.801Zm-6.76 0c0 1.285.852 2.102 1.94 2.102 1.103 0 1.939-.817 1.939-2.102 0-1.269-.836-2.101-1.94-2.093-1.095-.008-1.939.824-1.939 2.093ZM59.12 6.437v9.167h-2.848V9.145h-2.979v6.459h-2.84V2.768h2.84v3.67h5.827ZM67.553 7.474V6.439h2.849v9.166h-2.849v-.986c-.592.72-1.444 1.205-2.613 1.205-2.346 0-4.147-1.965-4.147-4.802 0-2.853 1.842-4.801 4.13-4.801 1.177 0 2.038.509 2.63 1.253Zm-3.88 3.548c0 1.285.853 2.102 1.94 2.102 1.104 0 1.94-.817 1.94-2.102 0-1.269-.836-2.101-1.94-2.093-1.103-.008-1.94.824-1.94 2.093ZM75.246 9.137v6.459h-2.84V6.437h5.973v2.708h-3.133v-.008ZM84.13 6.22c2.362 0 4.415 1.553 4.724 4.058.065.558.024.962-.016 1.286h-6.566c.098 1.164.869 1.859 1.956 1.859.804 0 1.526-.38 1.696-1.18h2.93c-.357 2.28-2.394 3.58-4.642 3.58-2.824 0-4.837-2.085-4.837-4.793 0-2.724 2.029-4.81 4.756-4.81Zm-1.655 3.477h3.376c-.235-.85-.998-1.156-1.672-1.156-.795-.008-1.395.42-1.704 1.155ZM37.493 8.685v1.043h10.956v5.876H34.645V13.35h11.548v-1.213H34.645V6.43h13.804v2.247H37.493v.008ZM3.548 3.714a1.73 1.73 0 1 1-3.458 0 1.73 1.73 0 0 1 3.458 0Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h88.889v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
