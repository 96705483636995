/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({ container, size, addCss }) => {
    const containerStyle = () => {
        switch (container) {
            case 'center':
                return styleContainer.center;
            default:
                return styleContainer.default;
        }
    };

    return (
        <div css={[containerStyle()]}>
            <CircularProgress
                size={size ? size + 'rem' : '5rem'}
                css={addCss}
                thickness={5}
            />
        </div>
    );
};

const styleContainer = {
    default: {},
    center: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        // '@keyframes fadeIn': {
        //   from: { opacity: 0 },
        //   to: { opacity: 1 },
        // },
        animation: 'fadeIn 1s ease-in',
    },
};

export default Loader;
