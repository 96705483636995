import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';
import {
    OPERATIONS,
    useAdminIngredientCreateMutation,
} from '@graphql/generated';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, TextField } from '@material-ui/core';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { IngredientUpsertSchema } from 'src/EZAdmin/domains/Ingredients/validation';

interface EzAdminIngredientsCreateModalProps {
    closeModal: () => void;
}

export const EZAdminIngredientsCreateModal = ({
    closeModal,
}: EzAdminIngredientsCreateModalProps) => {
    const [ingredientCreate, { loading }] = useAdminIngredientCreateMutation();

    const {
        control,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm<{
        ingredientName: string;
    }>({ mode: 'all', resolver: yupResolver(IngredientUpsertSchema) });

    const ingredientNameError = errors?.ingredientName?.message;

    const {
        field: { onChange, value },
    } = useController({
        control,
        name: 'ingredientName',
    });

    const handleCreateSubmit = () => {
        ingredientCreate({
            variables: {
                input: {
                    name: value,
                },
            },
            onCompleted: () => {
                closeModal();
                toast.dark('Składnik został dodany');
            },
            onError: (error) => {
                error?.graphQLErrors?.forEach((graphError) => {
                    if (
                        graphError.extensions.code ===
                        'INGREDIENT_EXISTS_ERROR_CODE'
                    ) {
                        setError('ingredientName', {
                            type: 'custom',
                            message: 'Podany składnik już istnieje.',
                        });
                    } else {
                        graphqlErrorHandler(true)(error);
                    }
                });
            },
            refetchQueries: [OPERATIONS.Query.AdminIngredientList],
        });
    };

    return (
        <ModalContentWrapper onSubmit={handleSubmit(handleCreateSubmit)}>
            <EditModalTitle>Nowy składnik</EditModalTitle>
            <TextField
                error={!!ingredientNameError}
                helperText={ingredientNameError}
                onChange={onChange}
                label="Podaj nazwę składnika"
                value={value}
            />
            <EditModalButtonGroup>
                <Button color="default" onClick={closeModal}>
                    Odrzuć
                </Button>
                <Button
                    color="primary"
                    disabled={loading}
                    type="submit"
                    variant="contained"
                >
                    Zapisz
                </Button>
            </EditModalButtonGroup>
        </ModalContentWrapper>
    );
};

const ModalContentWrapper = styled.form`
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 50%;
    max-width: 600px;
    padding: 32px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    ${mediaQueries.xs} {
        max-width: 80%;
    }
`;

const EditModalTitle = styled.h2`
    font-size: 20px;
    margin-bottom: 56px;
    text-align: left;
`;

const EditModalButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 40px;
    width: 100%;
`;
