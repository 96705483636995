import { ConsumptionType } from '@/graphql/generated';
import { CarIcon } from '@/svgs/CarIcon';
import { ChairIcon } from '@/svgs/ChairIcon';
import { ForkKnifeIcon } from '@/svgs/ForkKnifeIcon';
import { ShoppingBag } from '@/svgs/ShoppingBag';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { ReactNode } from 'react';

export const CONSUMPTION_TYPE_ENUM: Record<ConsumptionType, ConsumptionType> = {
    IN: 'IN',
    TAKEAWAY: 'TAKEAWAY',
    DELIVERY: 'DELIVERY',
};

export const CONSUMPTION_TYPE: Record<
    ConsumptionType,
    { icon: ReactNode; iconSecondary: ReactNode; name: string }
> = {
    DELIVERY: {
        icon: <DriveEtaIcon fontSize="inherit" />,
        iconSecondary: <CarIcon />,
        name: 'Dostawa',
    },
    IN: {
        icon: <ChairIcon />,
        iconSecondary: <ForkKnifeIcon />,
        name: 'Na miejscu',
    },
    TAKEAWAY: {
        icon: <LocalMallIcon fontSize="inherit" />,
        iconSecondary: <ShoppingBag />,
        name: 'Odbiór',
    },
};

export const CONSUMPTION_TYPE_NAME: Partial<Record<ConsumptionType, string>> =
    Object.entries(CONSUMPTION_TYPE).reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
        {},
    );

export const CONSUMPTION_TYPE_NAME_VALUES = Object.entries(
    CONSUMPTION_TYPE_NAME,
).map(([id, name]) => ({ id, name })) as {
    id: ConsumptionType;
    name: string;
}[];
