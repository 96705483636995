import React, { createContext, useContext } from 'react';
import { useProducts } from '../hooks/useProducts';
import { useProductsList } from '../hooks/useProductsList';

function noop() {}
export const ProductsContext = createContext({
    currentPage: undefined,
    handleChangePage: noop,
    rowsPerPage: undefined,
    handleChangeRowsPerPage: noop,
    products: [],
    productsCount: undefined,
    loading: undefined,
    error: undefined,
    searchString: '',
    onSearchString: noop,
});

export const useProductsContext = () => useContext(ProductsContext);

export default ({ children }) => {
    return (
        <ProductsContext.Provider
            value={{
                ...useProductsList(),
            }}
        >
            {children}
        </ProductsContext.Provider>
    );
};
