import dayjs, { ManipulateType } from 'dayjs';
import 'dayjs/locale/pl';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import duration from 'dayjs/plugin/duration';

dayjs.locale('pl');
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(duration);

export const dateFormat = {
    isToday: (date: Date | string) => dayjs(date).isToday(),
    standardDate: (date: Date | string): string =>
        dayjs(date).format('YYYY-MM-DD'),
    dayMonthYearDate: (date: Date | string): string =>
        dayjs(date).format('DD.MM.YYYY'),
    standardTime: (date: Date | string): string => dayjs(date).format('HH:mm'),
    fullDate: (date: Date | string): string =>
        dayjs(date).format('DD MMMM YYYY'),
    shortDateWithTime: (date: Date | string): string =>
        dayjs(date).format('DD MMM HH:mm'),
    orderDate: (date: Date | string): string => {
        const isCurrentYear = dayjs().year() === dayjs(date).year();
        return dayjs(date).format(isCurrentYear ? 'D MMM' : 'D MMM YYYY');
    },
    orderDateLong: (date: Date | string): string => {
        const isCurrentYear = dayjs().year() === dayjs(date).year();
        return dayjs(date).format(isCurrentYear ? 'D MMMM' : 'D MMM YYYY');
    },
    orderDateWithTime: (date: Date | string): string => {
        const isCurrentYear = dayjs().year() === dayjs(date).year();
        return dayjs(date).format(
            isCurrentYear ? 'DD MMM HH:mm' : 'DD MMM YYYY',
        );
    },
    dayAndMonth: (date: Date | string): string => dayjs(date).format('DD.MM'),
    getTimeDiff: (createdAt: Date | string) => {
        const daysDiff = Math.abs(dayjs(createdAt).diff(new Date(), 'days'));
        const hoursDiff = Math.abs(dayjs(createdAt).diff(new Date(), 'hour'));
        const minutesDiff = Math.abs(
            dayjs(createdAt).diff(new Date(), 'minutes'),
        );
        if (daysDiff > 0) {
            return `${daysDiff} d`;
        }
        if (hoursDiff > 0) {
            return `${hoursDiff} g`;
        }
        return `${minutesDiff} min`;
    },
    getMinutes: (date: Date | string) => {
        const minutesDiff = Math.abs(dayjs(date).diff(new Date(), 'minutes'));
        return minutesDiff;
    },
    calculateHowManyMinutes: (
        endDate?: Date | string,
        startDate?: Date | string,
    ) => {
        const startDateTime = dayjs(startDate);
        const endDateTime = dayjs(endDate);

        return endDateTime.diff(startDateTime, 'minutes');
    },
    dateTimeToIso: (date: string, time: string): string => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD');
        const parsedTime = dayjs(time, 'HH:mm');

        if (!parsedTime.isValid()) return '';

        const formattedTime = parsedTime.format('HH:mm');

        return dayjs(`${formattedDate} ${formattedTime}`).toISOString();
    },
    getDateBeforeDays: (days: number) => {
        const currentDate = dayjs();
        return currentDate.subtract(days, 'day').format('YYYY-MM-DD');
    },
    addHoursToDate: (hours: number, date?: Date | string) =>
        dayjs(date).add(hours, 'hour'),

    getFutureDate: (
        currentDate: Date,
        timeToAdd?: { time?: number; timeUnit?: ManipulateType | string },
    ) => {
        const defaultTimeToAdd = { time: 1, timeUnit: 'hour' };

        const futureDateTime = dayjs().add(
            timeToAdd?.time ?? defaultTimeToAdd.time,
            (timeToAdd?.timeUnit ??
                defaultTimeToAdd.timeUnit) as ManipulateType,
        );

        const checkingDateTime = dayjs(currentDate);

        return checkingDateTime.toDate() > futureDateTime.toDate()
            ? currentDate
            : futureDateTime;
    },
    addMinutesToDate: (minutes: number, startDate?: Date | string) => {
        const startDateTime = dayjs(startDate);
        let futureTime = startDateTime.add(minutes, 'minute');

        if (futureTime.isBefore(dayjs())) {
            futureTime = dayjs();
        }

        return futureTime.toISOString();
    },
    calculateTimeLeft: (date: Date | string) => {
        const difference = dayjs(date).diff(dayjs());
        const duration = dayjs.duration(difference);

        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        if (duration.milliseconds() < 0) return '00:00';

        return dayjs
            .duration({
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            })
            .format(hours > 0 ? 'H:mm:ss' : 'mm:ss');
    },
    calculateDuration: (endDate: Date | string, startDate: Date | string) => {
        const startDateTime = dayjs(startDate);
        const endDateTime = dayjs(endDate);

        return endDateTime.diff(startDateTime, 'millisecond');
    },
    calculateRemainingHours: (
        endDate: Date | string,
        startDate: Date | string,
    ) => {
        return dayjs
            .duration(dateFormat.calculateDuration(endDate, startDate))
            .asHours();
    },
    isAfterCurrentDate: (date: string | Date) => {
        return dayjs().isAfter(date);
    },
    calculateTimeElapsed: (date: string | Date) => {
        return dayjs().diff(date, 'millisecond');
    },
    getDateAndTime: (time: number, unit: ManipulateType | string) => {
        const adjustedDate = dayjs().add(time, unit as ManipulateType);

        const date = adjustedDate.format('YYYY-MM-DD');
        const newTime = adjustedDate.format('HH:mm');

        return { date, newTime };
    },
};
