import { useEffect, useRef } from 'react';
import {
    useClearNotificationSoundsMutation,
    useSoundPollingQuery,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

export const useSoundService = () => {
    const audio = useRef<HTMLAudioElement | null>(null);
    const [clearNotificationSounds] = useClearNotificationSoundsMutation();
    const isSoundPlaying = useRef<boolean>(false);
    const { restaurantId } = useWaiterService((state) => state);

    const { data, startPolling, stopPolling } = useSoundPollingQuery({
        context: { waiter: true },
        fetchPolicy: 'network-only',
        onError: graphqlErrorHandler(true),
        skip: !restaurantId,
        variables: {
            restaurantId: restaurantId ?? '',
        },
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (isSoundPlaying.current) {
            startPolling(2000);
        } else {
            stopPolling();
        }

        return () => {
            stopPolling();
        };
    }, [isSoundPlaying.current]);

    useEffect(() => {
        if (
            data?.allNotifications.data[0]?.createdAt >
                data?.notifications.data[0]?.createdAt &&
            data?.allNotifications.data[0].type ===
                'CLEAR_NOTIFICATION_SOUNDS' &&
            isSoundPlaying.current
        ) {
            stopPlayingSound('all');
        }
    }, [data]);

    const playSound = (sounds: string, id: string) => {
        audio.current?.pause();

        const newAudioObject = new Audio(sounds);
        newAudioObject.id = id;

        newAudioObject
            .play()
            .then(() => {})
            .catch((error) => {
                console.error(
                    'Wystąpił błąd podczas odtwarzania pliku audio:',
                    error,
                );
            });

        audio.current = newAudioObject;
        isSoundPlaying.current = true;
    };

    const stopPlayingSound = (id: string) => {
        if (id === audio.current?.id || id === 'all') {
            audio.current?.pause();
            if (isSoundPlaying.current) {
                clearNotificationSounds({
                    context: {
                        waiter: /waiter/.test(window.location.href),
                    },
                    onError: graphqlErrorHandler(true),
                });
            }
            isSoundPlaying.current = false;
        }
    };

    const clearAudioObject = () => {
        audio.current = null;
    };

    useEffect(() => {
        window.addEventListener('click', () => stopPlayingSound('all'));
        audio.current?.addEventListener('ended', clearAudioObject);

        return () => {
            window.removeEventListener('click', () => stopPlayingSound('all'));
            audio.current?.removeEventListener('ended', clearAudioObject);
        };
    }, []);

    return { playSound, stopPlayingSound };
};
