import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import ChatIcon from '@material-ui/icons/Chat';

import {
    ChatContainer,
    InputArea,
    Messages,
    MessagesContainer,
    HeaderInfo,
} from './components';
import {
    useAdminActiveChats,
    useAdminChatFinish,
} from '@/services/FirebaseChatService/chats/admin';
import PageLoader from '@components/PageLoader/PageLoader';
import { useChat } from '@hooks/useChat';
import { FIREBASE_ENV } from '@/services/FirebaseChatService/FirebaseChatService.config';
import {
    useChatMarkAsReadMessage,
    useChatSendMessage,
} from '@/services/FirebaseChatService/chats/common';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { s } from '@/style';

const AdminChatAdmin = () => {
    const { chats, loading, activeChat, setActiveChatId } =
        useAdminActiveChats();
    const { finishChat, loading: finishChatLoading } = useAdminChatFinish();
    const { measuredRef, scrollToBottom } = useChat(activeChat);
    const { message, sendMessage, setMessage } = useChatSendMessage(
        `env/${FIREBASE_ENV}/adminChats/${activeChat?.owner}`,
    );

    useChatMarkAsReadMessage(
        `env/${FIREBASE_ENV}/adminChats/${activeChat?.owner}`,
        activeChat?.messages ?? [],
    );

    useEffect(() => {
        if (chats.length) {
            let latestMessage = null;

            chats.forEach((item) => {
                item.messages.forEach((message) => {
                    if (
                        !latestMessage ||
                        new Date(message.createdAt) >
                            new Date(latestMessage.createdAt)
                    ) {
                        latestMessage = message;
                    }
                });
            });

            setActiveChatId(latestMessage.sender);
        }
    }, [!!chats.length]);

    const { bottom } = useSafeAreaInsets();

    const setUserName = (chat) => {
        if (!chat) return '';

        if (chat?.name) {
            return chat?.surname ? `${chat.name} ${chat.surname}` : chat.name;
        }

        return chat.id;
    };

    const handleSend = () => {
        sendMessage();
        scrollToBottom();
    };

    if (loading) return <PageLoader />;
    if (!chats.length) return <NoChatsView />;

    return (
        <ChatContainer>
            <AdminChatFlexContainer>
                <ChatPreviewsContainer>
                    <ChatLogo />
                    {chats.map((chat) => (
                        <ChatPreviewIcon
                            key={chat.owner}
                            name={setUserName(chat)}
                            setChat={() => setActiveChatId(chat.owner)}
                            isActive={chat.owner === activeChat?.owner}
                            unreadMssgAmount={chat.unreadMessages}
                        />
                    ))}
                </ChatPreviewsContainer>

                <SingleChatWindow>
                    <HeaderInfo
                        avatarUrl={activeChat?.avatarUrl}
                        finishChatLoading={finishChatLoading}
                        name={setUserName(activeChat)}
                        onAdminFinishChat={() => finishChat(activeChat?.owner)}
                        userId={activeChat?.owner}
                    />

                    <MessagesContainer
                        ref={measuredRef}
                        paddingBottom={55 + bottom}
                    >
                        <Messages
                            messages={activeChat?.messages}
                            reverseUser
                            userId={activeChat?.owner}
                        />
                    </MessagesContainer>

                    <InputArea
                        newMssg={message}
                        setNewMssg={setMessage}
                        onSend={handleSend}
                        isAdmin
                    />
                </SingleChatWindow>
            </AdminChatFlexContainer>
        </ChatContainer>
    );
};

const NoChatsView = () => (
    <ChatContainer>
        <HeaderInfo name="Brak czatów" />
    </ChatContainer>
);

const AdminChatFlexContainer = ({ children }) => (
    <div css={{ display: 'flex', height: '100%' }}>{children}</div>
);

const ChatPreviewsContainer = ({ children }) => (
    <div css={{ marginRight: 8, marginLeft: -23, [s.sm]: { marginLeft: 0 } }}>
        {children}
    </div>
);

const SingleChatWindow = ({ children }) => (
    <div
        css={{
            flexGrow: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}
    >
        {children}
    </div>
);

const ChatLogo = () => (
    <div
        css={{
            height: 48,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '4px 0',

            svg: {
                height: 28,
                width: 28,
            },
        }}
    >
        <ChatIcon css={{ color: '#3389f9' }} />
    </div>
);

const ChatPreviewIcon = ({
    isActive,
    name,
    setChat,
    unreadMssgAmount,
    avatarUrl,
}) => (
    <div
        css={{
            minHeight: 56,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Badge
            color="secondary"
            badgeContent={unreadMssgAmount}
            css={{ span: { transform: 'none' } }}
        >
            <Avatar
                alt={name}
                src={avatarUrl}
                onClick={setChat}
                css={{
                    margin: 4,
                    cursor: 'pointer',
                    backgroundColor: isActive ? '#3389f9' : undefined,
                }}
            />
        </Badge>
    </div>
);

export default AdminChatAdmin;
