/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import Headline from '../../../components/Headline/Headline';

import { Grid } from '@material-ui/core';
import {
    sBottomLinks,
    sOuter,
    sGrid,
    sDescription,
} from '../../../style/style';
import { ButtonBorder } from '../../../components/Elements';

const Page404 = () => {
    return (
        <div>
            <TopNav title={'404'} subtitle={'Strona nie istnieje'} />
            <div css={sOuter}>
                <Headline>404</Headline>
                <Grid container css={sGrid}>
                    <div css={sDescription}>
                        Wygląda na to, że strona nie istnieje. Kliknij poniższy
                        przycisk, aby cofnąć się do widoku głównego.
                    </div>

                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/'}>Wróć na START</ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Page404;
