import React from 'react';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { Grid } from '@material-ui/core';
import { sOuter } from '@style/style';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useHistory } from 'react-router-dom';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import styled from '@emotion/styled';
import { colors, s } from '@style/index';
import { useAddressBookPage } from './useAddressBookPage';
import { AddressBox } from '@/EZC/domains/Account/organisms/AddressBox/AddressBox';

export const AddressBookPage = () => {
    const history = useHistory();

    const { data, loading, handleSubmit, updatedLoading } =
        useAddressBookPage();

    return (
        <>
            <div>
                {/* @ts-expect-error migrate to TS */}
                <TopNav title={'Książka adresowa'} />
                <div
                    /* @ts-expect-error migrate to TS */
                    css={sOuter}
                >
                    {/* @ts-expect-error migrate to TS */}
                    <Headline
                        itemChildren={
                            <Button
                                width="107px"
                                onClick={() =>
                                    history.push(`/account/address-book/new`)
                                }
                            >
                                Dodaj adres
                            </Button>
                        }
                        extraCss={{ flexWrap: 'unset', alignItems: 'start' }}
                    >
                        Książka adresowa
                    </Headline>

                    <Wrapper container spacing={2}>
                        <Grid item md={12}>
                            <Description>
                                Wprowadź adres dostawy, który będzie dostępny do
                                wyboru podczas składania zamówienia.
                            </Description>
                        </Grid>
                        {loading ? (
                            <>
                                {Array.from({ length: 4 }, (_, index) => (
                                    <SkeletonOrder key={index} />
                                ))}
                            </>
                        ) : (
                            <>
                                {!!data?.user?.addresses?.length ? (
                                    data?.user?.addresses?.map((address) => (
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            key={address?.id}
                                        >
                                            <AddressBox
                                                key={address?.id}
                                                addressName={address?.name}
                                                fullAddress={{
                                                    address: address?.address,
                                                    lng:
                                                        address
                                                            ?.coordinates?.[0] ??
                                                        0,
                                                    lat:
                                                        address
                                                            ?.coordinates?.[1] ??
                                                        0,
                                                }}
                                                additionalAddress={
                                                    address?.additionalInfo
                                                }
                                                isDefault={address?.isDefault}
                                                id={address?.id}
                                                onSubmit={handleSubmit}
                                                loading={updatedLoading}
                                            />
                                        </Grid>
                                    ))
                                ) : (
                                    <Empty>Brak adresów</Empty>
                                )}
                            </>
                        )}
                    </Wrapper>
                </div>
            </div>
        </>
    );
};

const Wrapper = styled(Grid)`
    padding: 0 24px 48px;
    ${s.md} {
        padding: 0 32px 48px;
    }
`;

export const Description = styled.p`
    font-size: 15px;
    line-height: 22px;
    color: ${colors.blueZodiac};
    padding-bottom: 16px;
`;

const Empty = styled.p`
    font-size: 23px;
    line-height: 22px;
    color: ${colors.gray500};
    padding: 65px 0;
    text-align: center;
    width: 100%;
`;

const Button = styled(ButtonBorder)`
    font-size: 13px;
`;
