import { popup, usePopup } from '@/AppPopups';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { OPERATIONS, useOrderChangeStatusMutation } from '@graphql/generated';
import { alpha, colors, globals, s } from '@style';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';

const FixedButtons = ({ zIndex, isBlocked, order, loading }) => {
    const [changeStatus, { loading: changeStatusLoading }] =
        useOrderChangeStatusMutation();
    const { bottomNavigation } = useSafeAreaInsets();
    const history = useHistory();

    useEffect(() => {
        order?.status === 'ACCEPTED' &&
            order?.details?.type !== 'IN' &&
            !order?.delivery?.eta &&
            popup.show('SetDeliveryTimePopup', {
                order,
                loading,
                isTakeaway: order?.details?.type === 'TAKEAWAY',
                update: true,
            });
    }, [order]);

    const handleChangeStatus = ({ status, text }, callback) => {
        changeStatus({
            context: {
                waiter: true,
            },
            variables: { input: { orderId: order?.id, status, payload: {} } },
            onCompleted: () => {
                text && toast.info(text);
                callback && callback();
            },
            onError: graphqlErrorHandler(true),
            refetchQueries: [
                OPERATIONS.Query.ActivePage,
                OPERATIONS.Query.OrderPage,
            ],
        });
    };

    return (
        <ButtonWrapper zIndex={zIndex} bottom={bottomNavigation}>
            {(order?.status === 'PREPARING' ||
                order?.status === 'ACCEPTED' ||
                (order?.status === 'PREPARED' &&
                    order?.details?.type === 'DELIVERY') ||
                order?.status === 'DELIVERY') &&
                order?.details?.type !== 'IN' && (
                    <ButtonLessPadding
                        width={'100%'}
                        onClick={() =>
                            popup.show('SetDeliveryTimePopup', {
                                order,
                                loading,
                                isTakeaway: order?.details?.type === 'TAKEAWAY',
                                update: true,
                            })
                        }
                        variant="black"
                        loading={changeStatusLoading}
                    >
                        ZAKTUALIZUJ CZAS{' '}
                        {order?.details?.type === 'TAKEAWAY'
                            ? 'ODBIORU'
                            : 'DOSTAWY'}
                    </ButtonLessPadding>
                )}
            {order?.status === 'ACCEPTED' && (
                <ButtonLessPadding
                    width={'100%'}
                    onClick={() => {
                        if (isBlocked && order?.details?.type === 'TAKEAWAY') {
                            return toast.error(
                                'Oznaczenie zamówienia jako realizowane jest możliwe nie wcześniej niż 3 godziny przed odbiorem',
                            );
                        }
                        if (isBlocked && order?.details?.type === 'IN') {
                            return toast.error(
                                'Oznaczenie zamówienia jako realizowane jest możliwe nie wcześniej niż 3 godziny przed rezerwacją',
                            );
                        }
                        handleChangeStatus({
                            status: 'PREPARING',
                            text:
                                order?.details?.type === 'DELIVERY'
                                    ? 'Zamówienie zostało oznaczone jako gotowe'
                                    : '',
                        });
                    }}
                    variant={isBlocked ? 'gray' : 'orange'}
                    loading={changeStatusLoading}
                >
                    Oznacz jako realizowane
                </ButtonLessPadding>
            )}
            {order?.status === 'PREPARING' && (
                <ButtonLessPadding
                    width={'100%'}
                    onClick={() => {
                        handleChangeStatus({
                            status:
                                order?.details?.type === 'DELIVERY' ||
                                order?.details?.type === 'TAKEAWAY'
                                    ? 'PREPARED'
                                    : 'COMPLETED',
                        });
                    }}
                    variant={
                        order?.details?.type === 'DELIVERY' ||
                        order?.details?.type === 'TAKEAWAY'
                            ? 'turquiose'
                            : 'green'
                    }
                    loading={changeStatusLoading}
                >
                    {order?.details?.type === 'DELIVERY' ||
                    order?.details?.type === 'TAKEAWAY'
                        ? 'OZNACZ JAKO PRZYGOTOWANE'
                        : 'OZNACZ JAKO ZREALIZOWANE'}
                </ButtonLessPadding>
            )}
            {order?.status === 'PREPARED' && (
                <ButtonLessPadding
                    width={'100%'}
                    onClick={() => {
                        handleChangeStatus(
                            {
                                status:
                                    order?.details?.type === 'DELIVERY'
                                        ? 'DELIVERY'
                                        : 'COMPLETED',
                            },
                            order?.details?.type === 'TAKEAWAY'
                                ? () => {
                                      handleChangeStatus({
                                          status: 'CLOSED',
                                          text: 'Zamówienie zostało zamknięte',
                                      });
                                  }
                                : null,
                        );
                    }}
                    variant={
                        order?.details?.type === 'DELIVERY'
                            ? 'navy'
                            : order?.details?.type === 'TAKEAWAY'
                              ? 'black'
                              : 'green'
                    }
                    loading={changeStatusLoading}
                >
                    {order?.details?.type === 'DELIVERY'
                        ? 'OZNACZ JAKO W DOSTAWIE'
                        : order?.details?.type === 'TAKEAWAY'
                          ? 'ZAKOŃCZ ZAMÓWIENIE'
                          : 'OZNACZ JAKO ZREALIZOWANE'}
                </ButtonLessPadding>
            )}
            {order?.status === 'DELIVERY' && (
                <ButtonLessPadding
                    width={'100%'}
                    onClick={() => {
                        handleChangeStatus({
                            status: 'COMPLETED',
                            text: 'Zamówienie zostało dostarczone',
                        });
                    }}
                    variant={'green'}
                    loading={loading}
                >
                    OZNACZ JAKO DOSTARCZONE
                </ButtonLessPadding>
            )}
            {order?.details?.type !== 'TAKEAWAY' &&
                order?.status === 'COMPLETED' && (
                    <ButtonLessPadding
                        width={'100%'}
                        onClick={() => {
                            handleChangeStatus({
                                status: 'CLOSED',
                                text: 'Zamówienie zostało zakończone',
                            });
                        }}
                        variant={'black'}
                        loading={loading}
                    >
                        ZAKOŃCZ ZAMÓWIENIE
                    </ButtonLessPadding>
                )}

            {(order?.status === 'PENDING' ||
                order?.status === 'SENT_TO_RESTAURANT' ||
                order?.status === 'VERIFICATION') && (
                <>
                    <Button
                        variant={'redDark'}
                        width={'100%'}
                        onClick={() =>
                            popup.show('CancelOrderOrProductPopup', {
                                orderId: order?.id,
                            })
                        }
                        loading={changeStatusLoading}
                    >
                        Odrzuć zamówienie lub produkt
                    </Button>
                    <Button
                        width="100%"
                        onClick={() => {
                            order?.details?.type !== 'IN'
                                ? popup.show('SetDeliveryTimePopup', {
                                      order,
                                      loading,
                                      isTakeaway:
                                          order?.details?.type === 'TAKEAWAY',
                                      update: false,
                                  })
                                : handleChangeStatus({
                                      status: 'ACCEPTED',
                                      text: 'Zamówienie zostało zaakceptowane',
                                  });
                        }}
                        variant="yellow"
                        loading={changeStatusLoading}
                    >
                        Akceptuj Zamówienie
                    </Button>
                </>
            )}
            {order?.details?.type === 'IN' &&
                order?.status !== 'VERIFICATION' &&
                order?.status !== 'CLOSED' &&
                order?.status !== 'CANCELED' &&
                order?.status !== 'REJECTED' &&
                order?.status !== 'PARTIAL_ACCEPTANCE' && (
                    <Button
                        width={'100%'}
                        onClick={() => history.push(`/waiter/add/${order?.id}`)}
                        variant="yellowGradient"
                    >
                        Domów
                    </Button>
                )}
        </ButtonWrapper>
    );
};

const ButtonLessPadding = styled(Button)`
    padding: 0 8px;
`;

export const ButtonWrapper = styled.div(
    ({ bottom, zIndex }) => css`
        display: grid;
        grid-gap: 8px;
        width: 100%;
        padding: 0 32px;
        max-width: ${globals.maxWidthMedium}px;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 24px;
        transition:
            bottom 0.6s ease-in-out,
            filter 0.3s ease-out,
            transform 0.3s ease-out,
            opacity 0.3s ease-out;
        z-index: ${zIndex ? zIndex : 200};
        bottom: ${bottom ? `${bottom}px` : '72px'};
    `,
);

export const sButtonFixed = {
    background: `linear-gradient(to right, ${colors.orange200}, ${colors.orange})`,
    width: '100%',
    maxWidth: 720,
    // padding: '0 2rem',
    [s.sm_down]: {
        maxWidth: 'calc(100vw - 3rem)',
        padding: '0 1.5rem',
    },
    position: 'fixed',
    height: 48,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '1.5rem',
    transition:
        'bottom 0.6s ease-in-out, filter 0.3s ease-out, transform 0.3s ease-out, opacity 0.3s ease-out',
    boxShadow: `0px 8px 24px ${colors.orange.concat(alpha[60])}`,
    zIndex: 200,
    bottom: 72,
    '&:hover': {
        [s.hover]: {
            filter: 'brightness(1.1)',
        },
    },
};

export default FixedButtons;
