/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useContext, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { s } from '../../../style';

const Bullet = () => (
    <span css={{ margin: '0 0.25rem', [s.sm]: { margin: '0 0.5rem' } }}>
        &bull;
    </span>
);

export default Bullet;
