import React from 'react';
import { Link } from 'react-router-dom';
import { colors, alpha } from '@/style/index';
import { AverageRating } from '@/componentsV2/atoms/AverageRating/AverageRating';
import styled from '@emotion/styled';
import { ProductAvatar } from '@/svgs/ProductAvatar';
import { Product } from '@graphql/generated';
import { EatzonIcon } from '@/componentsV2/atoms/EatzonIcon/EatzonIcon';

export type ProductType = Pick<
    Product,
    | 'id'
    | 'name'
    | 'photoUrl'
    | 'price'
    | 'rating'
    | 'ratingsCount'
    | 'distance'
    | 'accuracy'
> & { isBrochure?: boolean };

export const ProductListItem = ({
    accuracy,
    distance,
    id,
    name,
    photoUrl,
    price,
    rating,
    ratingsCount,
    isBrochure = false,
}: ProductType) => {
    return (
        <Container to={`/product/${id}`} id={id}>
            <ImageContainer>
                {photoUrl ? (
                    <BackgroundImage src={photoUrl} alt={name} loading="lazy" />
                ) : (
                    <ProductAvatar />
                )}
                <EatzonIcon
                    isInactive={isBrochure}
                    size={10}
                    space={4}
                    position="absoluteLeft"
                />
            </ImageContainer>
            <TextContainer>
                <Title>{name}</Title>
                <BulletsWrapper>
                    {distance} km
                    {!!accuracy && (
                        <>
                            <Bullet>&bull;</Bullet>
                            Trafność {accuracy}%
                        </>
                    )}
                </BulletsWrapper>
                <ProductInfoWrapper>
                    <Price>
                        {price?.toFixed(2)}
                        <Currency> zł</Currency>
                    </Price>
                    <AverageRating average={rating} ratings={ratingsCount} />
                </ProductInfoWrapper>
            </TextContainer>
        </Container>
    );
};

const Container = styled(Link)`
    background-color: ${colors.gray200};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 188px;
    position: relative;
    transition:
        background-color 0.3s ease-out,
        box-shadow 0.3s ease-out;

    &:hover {
        background-color: ${colors.gray300};
    }
`;

const Bullet = styled.span`
    margin: 0 4px;
`;

const ImageContainer = styled.div`
    width: 100%;
    position: relative;
    border-radius: inherit;
    overflow: hidden;
    height: 128px;
`;

const BackgroundImage = styled.img`
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease-out;
    width: 100%;

    &:hover {
        transform: scale(1.1);
    }
`;

const TextContainer = styled.div`
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
    background: linear-gradient(
        to right,
        ${colors.gray100.concat(alpha[90])},
        ${colors.white.concat(alpha[0])}
    );
    backdrop-filter: blur(12px);
    bottom: 0;
    color: ${colors.text};
    left: 0;
    padding: 8px;
    position: absolute;
    width: 100%;
`;

const Title = styled.h3`
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const BulletsWrapper = styled.div`
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 8px;
    opacity: 0.6;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const ProductInfoWrapper = styled.div`
    align-items: center;
    border-top: 1px solid ${colors.titanWhite};
    display: flex;
    justify-content: space-between;
    padding-top: 8px;
`;

const Price = styled.span`
    font-size: 12px;
    font-weight: 700;
`;

const Currency = styled.span`
    color: ${colors.ghost};
`;
