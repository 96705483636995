import { useCommonStore } from '@/CommonStore';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { useEffect } from 'react';

export const useTrackingPwaForGa = () => {
    const { isPwaChecked, setIsPwaChecked } = useCommonStore();

    const checkPWA = () => {
        if (!isPwaChecked) {
            AnalyticsHelpers.createCustomEvent({
                name: 'install_PWA',
                params: { type: 'PWA' },
            });
            setIsPwaChecked(true);
        }
    };

    useEffect(() => {
        window
            .matchMedia('(display-mode: standalone)')
            .addEventListener('change', checkPWA);

        return () =>
            window
                .matchMedia('(display-mode: standalone)')
                .removeEventListener('change', checkPWA);
    }, []);
};
