import { Dish } from '@/graphql/generated';

export const DISH_ENUM: Record<Dish, Dish> = {
    ALCOHOL: 'ALCOHOL',
    APPETIZER: 'APPETIZER',
    BURGER: 'BURGER',
    COLD_DRINK: 'COLD_DRINK',
    DESERT: 'DESERT',
    DRINK: 'DRINK',
    HOT_DRINK: 'HOT_DRINK',
    MAIN: 'MAIN',
    PASTA: 'PASTA',
    PIZZA: 'PIZZA',
    SALAD: 'SALAD',
    SANDWICH: 'SANDWICH',
    SOUP: 'SOUP',
    STEAK: 'STEAK',
    ADDITION: 'ADDITION',
    APPETIZER_COLD: 'APPETIZER_COLD',
    APPETIZER_HOT: 'APPETIZER_HOT',
    BREAD: 'BREAD',
    CREAM: 'CREAM',
    FISH: 'FISH',
    FLOUR_BASED: 'FLOUR_BASED',
    ICE_CREAM: 'ICE_CREAM',
    PRESERVE: 'PRESERVE',
    SEAFOOD: 'SEAFOOD',
    SNACK: 'SNACK',
    SUSHI: 'SUSHI',
};

export const DISH: Record<Dish, { name: string; pluralName?: string }> = {
    ALCOHOL: { name: 'alkohol' },
    APPETIZER: { name: 'przystawka' },
    BURGER: { name: 'burger' },
    COLD_DRINK: { name: 'zimny napój', pluralName: 'zimne napoje' },
    DESERT: { name: 'deser' },
    DRINK: { name: 'napój', pluralName: 'napoje' },
    HOT_DRINK: { name: 'ciepły napój', pluralName: 'ciepłe napoje' },
    MAIN: { name: 'danie główne' },
    PASTA: { name: 'makaron' },
    PIZZA: { name: 'pizza' },
    SALAD: { name: 'sałatka' },
    SANDWICH: { name: 'kanapka' },
    SOUP: { name: 'zupa' },
    STEAK: { name: 'stek' },
    ADDITION: {
        name: 'dodatek',
    },
    APPETIZER_COLD: {
        name: 'przystawka zimna',
    },
    APPETIZER_HOT: {
        name: 'przystawka ciepła',
    },
    BREAD: {
        name: 'pieczywo',
    },
    CREAM: {
        name: 'krem',
    },
    FISH: {
        name: 'ryby',
    },
    FLOUR_BASED: {
        name: 'danie mączne',
    },
    ICE_CREAM: {
        name: 'lody',
    },
    PRESERVE: {
        name: 'przetwory',
    },
    SEAFOOD: {
        name: 'owoce morza',
    },
    SNACK: {
        name: 'przekąska',
    },
    SUSHI: {
        name: 'sushi',
    },
};

export const DISH_NAME: Partial<Record<Dish, string>> = Object.entries(
    DISH,
).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
    {},
);

export const DISH_NAME_VALUES = Object.entries(DISH_NAME).map(([id, name]) => ({
    id,
    name,
})) as {
    id: Dish;
    name: string;
}[];

export const DISH_KEYS = Object.keys(DISH) as Dish[];
