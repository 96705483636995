/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { alpha, colors, s } from '../../../../style';
import { useQuery } from '@apollo/client';
import {
    sBackground,
    sContainer,
} from '../../../../components/Widget/Widget.component';
import { Button } from '../../../../components/Elements';
import { Collapse } from '@material-ui/core';
import ActiveOrderItem from '../../../pages/orders/components/ActiveOrderItem';
import { RestaurantAvatar } from '@/svgs/RestaurantAvatar';

// const Popup = ({ orderAmount, onClick, isShown }) => isShown && (
//   <Container onClick={onClick}>
//     <OrdersAmount orderAmount={orderAmount} />
//     <Header>
//       Zamówieni{orderAmount === 1 ? 'e' : 'a'} wymaga{orderAmount > 1 && 'ją'} uwagi
//     </Header>
//     <Subheader>
//       Dotknij w dowolnym miejscu, aby zobaczyć
//     </Subheader>
//   </Container>
// )

const Popup = ({ orders, onClick, isShown, onHide }) => {
    //TODO EAT-333 add activeOrdersLisener
    // const { data, loading, error } = useQuery(GET_ACTIVE_ORDER, {
    //     variables: { orderId: orders[0] },
    //     skip: true,
    // });

    const order = data && data.ezcOrderActive;

    return (
        <React.Fragment>
            <div
                css={[sBackground, !isShown && sBackgroundHidden]}
                onClick={onHide}
            ></div>
            <div css={[sContainer, sContainer2, isShown && sContainerShown]}>
                <h1 css={sTitle}>Zamówienie</h1>
                <p css={sSubtitle}>
                    Jedno z zamówień zostało zaktualizowane i wymaga Twojej
                    uwagi
                </p>
                <Collapse in={Boolean(order)}>
                    <div css={{ padding: '1rem 0 0' }} onClick={onClick}>
                        {order && (
                            <ActiveOrderItem
                                key={order._id}
                                orderId={order._id}
                                date={order.consumptionDate}
                                time={order.consumptionTime}
                                consumptionType={order.consumptionType}
                                restaurantAvatar={
                                    order.restId.avatarUrl ? (
                                        <img
                                            src={order.restId.avatarUrl}
                                            alt={order.restId.name}
                                            loading="lazy"
                                        />
                                    ) : (
                                        <RestaurantAvatar />
                                    )
                                }
                                restaurantName={order.restId.name}
                                priceSum={order.priceSum}
                                isPaid={order.isPaid}
                                status={[...order.status].pop().name}
                                needAttention={orders.includes(order._id)}
                                w100
                            />
                        )}
                    </div>
                </Collapse>
                <div css={{ padding: '1rem 0 2rem' }}>
                    <Button w100 w100Desktop action={onClick}>
                        Zobacz
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};

export const sTitle = {
    fontWeight: 700,
    letterSpacing: '-0.05em',
    fontSize: '30px',
    marginBottom: '1rem',
    margin: '0 0.5rem 1rem',
};

export const sSubtitle = {
    color: colors.text.concat(alpha[30]),
    fontSize: '15px',
    lineHeight: '1.618em',
    margin: '0 0.5rem 1rem',
    [s.md]: {
        fontSize: '17px',
    },
};

export const sContainer2 = {
    zIndex: 1001,
    transform: 'translate(-50%, 100%)',
    padding: '2rem 2rem 4.5rem',
    [s.sm_down]: {
        padding: '2rem 1.5rem 4.5rem',
    },
};

export const sBackgroundHidden = { opacity: 0, pointerEvents: 'none' };

export const sContainerShown = { transform: 'translate(-50%, 0%)' };

export default Popup;
