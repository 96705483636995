/**
 * constants like default values for forms
 */
import { GeolocationType } from '@/types/Geolocalization';
import { ConsumptionType } from '@graphql/generated';

export const CONSUMPTION_TYPE_DEFAULT: ConsumptionType[] = [
    'DELIVERY',
    'IN',
    'TAKEAWAY',
];

type AvailableHours = {
    open: number;
    close: number;
};

export const AVAILABLE_HOURS_DEFAULT: Record<string, AvailableHours> = {
    monday: {
        open: 0,
        close: 1440,
    },
    tuesday: {
        open: 0,
        close: 1440,
    },
    wednesday: {
        open: 0,
        close: 1440,
    },
    thursday: {
        open: 0,
        close: 1440,
    },
    friday: {
        open: 0,
        close: 1440,
    },
    saturday: {
        open: 0,
        close: 1440,
    },
    sunday: {
        open: 0,
        close: 1440,
    },
};

export const DEFAULT_GEOLOCATION: GeolocationType = {
    lat: 54.4419208,
    lng: 18.5125771,
    address: 'Sopot, Polska',
};

export const DEFAULTS_CONSTANTS: {
    consumptionTypes: ConsumptionType[];
    geolocation: GeolocationType;
    infoshareAddress: string;
    defaultAddressName: string;
    distance: string;
    distanceInMeters: number;
} = {
    geolocation: {
        lat: 54.4419208,
        lng: 18.5125771,
        address: 'Sopot, Polska',
    },
    consumptionTypes: ['IN', 'DELIVERY', 'TAKEAWAY'],
    infoshareAddress: 'InfoShare Conference, Żaglowa 11, 80-560 Gdańsk',
    defaultAddressName: 'Adres nr 1',
    distance: '50',
    distanceInMeters: 50000,
};

export const SPECIAL_CHARS_REGEX =
    /(?:^|[^a-zA-Z0-9\s])[^a-zA-Z0-9\s]+|[^a-zA-Z0-9\s]+(?:[^a-zA-Z0-9\s]|$)/g;
