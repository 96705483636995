import React from 'react'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Collapsable, Bullet, Row } from '../../../../components/Elements';
import DocumentIcon from '@material-ui/icons/InsertDriveFile';

const OrderInvoice = ({ invoiceDetails }) => {
    const { name, nip, address, city, postalCode } = invoiceDetails;
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                noPadding
                oneLiner
                title={'Faktura'}
                icon={<DocumentIcon />}
                description={
                    <div
                        css={{
                            overflowX: 'scroll',
                        }}
                    >
                        {name}
                        <Bullet />
                        {nip}
                        <Bullet />
                        {address}
                        <Bullet />
                        {postalCode}
                        <Bullet />
                        {city}
                    </div>
                }
            >
                <Row desc={'Nazwa'}>{name}</Row>
                <Row desc={'NIP'}>{nip}</Row>
                <Row desc={'Adres'}>{address}</Row>
                <Row desc={'Kod Pocztowy'}>{postalCode}</Row>
                <Row desc={'Miasto'}>{city}</Row>
            </Collapsable>
        </Grid>
    );
};

export default OrderInvoice;
