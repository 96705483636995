import { ProductAvailableHoursInput } from '@graphql/generated';
import dayjs from 'dayjs';

const daysOfWeek: (keyof ProductAvailableHoursInput)[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];

interface HoursInMinutes {
    open: number;
    close: number;
}

interface ConvertedHours {
    open: string;
    close: string;
    closeWholeDay: boolean;
    openWholeDay: boolean;
}

export const convertTimestamps = {
    toISO: (
        hoursInMinutes?: Record<string, HoursInMinutes>,
    ): ProductAvailableHoursInput => {
        const result: ProductAvailableHoursInput = {
            friday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
            monday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
            saturday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
            sunday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
            thursday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
            tuesday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
            wednesday: {
                open: 0,
                close: 0,
                openWholeDay: false,
                closeWholeDay: false,
            },
        };

        for (const day of daysOfWeek) {
            const { open, close } = hoursInMinutes?.[day] || {
                open: 0,
                close: 0,
            };
            const closeWholeDay = open === 0 && close === 0;
            const openWholeDay = open === 0 && close === 1440;
            const openDate = dayjs()
                .startOf('day')
                .add(open, 'minutes')
                .toISOString();
            const closeDate = dayjs()
                .startOf('day')
                .add(close, 'minutes')
                .toISOString();

            result[day] = {
                open: openDate,
                close: closeDate,
                closeWholeDay,
                openWholeDay,
            };
        }

        return result;
    },
    fromISO: (
        isoHours: Record<string, ConvertedHours>,
    ): Record<string, HoursInMinutes> => {
        const result: Record<string, HoursInMinutes> = {};
        if (isoHours) {
            for (const day of daysOfWeek) {
                const { open, close, closeWholeDay, openWholeDay } = isoHours[
                    day
                ] || {
                    open: 0,
                    close: 0,
                    closeWholeDay: false,
                    openWholeDay: false,
                };

                if (closeWholeDay) {
                    result[day] = {
                        open: 0,
                        close: 0,
                    };
                } else if (openWholeDay) {
                    result[day] = {
                        open: 0,
                        close: 1440,
                    };
                } else {
                    const openDate = dayjs(open);
                    const closeDate = dayjs(close);

                    const openInMinutes = openDate.diff(
                        openDate.startOf('day'),
                        'minutes',
                    );
                    let closeInMinutes = closeDate.diff(
                        closeDate.startOf('day'),
                        'minutes',
                    );

                    if (closeInMinutes === 0) {
                        closeInMinutes = 1440;
                    }

                    result[day] = {
                        open: openInMinutes,
                        close: closeInMinutes,
                    };
                }
            }
        }

        return result;
    },
};
