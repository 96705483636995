import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { Grid, Avatar } from '@material-ui/core';
import { Collapsable, Button } from '../../../../../components/Elements';
import FaceIcon from '@material-ui/icons/Face';
import { CollapsableText } from '../../../../../components/Elements/Collapsable/Collapsable';
import Input from '../../../../views/Auth/components/Input.component';
import CloseIcon from '@material-ui/icons/Close';
import { s, colors } from '../../../../../style';
import { imagePreview as imgPreviewUtil } from '../../../../../helpers/image-preview';
import { toast } from 'react-toastify';
import {
    useAvatarImageUploadLazyQuery,
    useUserUpdateMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useUploadImage } from '@hooks/useUploadImage';

const SettingsAvatar = ({ avatar }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [imgFile, setImgFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const [userUpdate, { loading: updateLoading }] = useUserUpdateMutation();
    const [avatarImageUpload] = useAvatarImageUploadLazyQuery();

    const { uploadImage, loading: imageLoading } =
        useUploadImage(avatarImageUpload);

    const loading = updateLoading || imageLoading;

    const onAddImage = (event) => {
        setImgFile(event.target.files[0]);
        imgPreviewUtil(event.target.files[0])
            .then((file) => setImagePreview(file))
            .catch(() => setImagePreview(null));
        event.target.value = '';
    };

    const onReset = () => {
        if (avatar && !imgFile) {
            userUpdate({
                variables: {
                    input: {
                        payload: {
                            personal: {
                                avatarUrl: null,
                            },
                        },
                    },
                },
                onCompleted: () => {
                    toast.dark('Avatar został zaktualizowany');
                },
                onError: graphqlErrorHandler(true),
            });
        } else {
            setImgFile(null);
            setImagePreview(null);
        }
        setIsOpen(false);
    };
    const handleFormSubmit = async () => {
        if (!imgFile || loading) return;

        try {
            const avatarUrl = await uploadImage(imgFile, '128x128');

            userUpdate({
                variables: {
                    input: {
                        payload: {
                            personal: {
                                avatarUrl,
                            },
                        },
                    },
                },
                onCompleted: () => {
                    toast.dark('Avatar został zaktualizowany');
                    onReset();
                },
                onError: graphqlErrorHandler(true),
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                left
                noPadding
                oneLiner
                title={'Avatar'}
                icon={<FaceIcon />}
                description={`Zdjęcie profilowe`}
                parentIsOpen={isOpen}
                parentSetIsOpen={setIsOpen}
            >
                <CollapsableText>
                    <div
                        css={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                            marginBottom: '1.5rem',
                            [s.md]: { gap: '2rem', marginBottom: '2rem' },
                        }}
                    >
                        <Avatar
                            src={imagePreview || avatar}
                            alt="avatar"
                            css={{ width: 48, height: 48 }}
                        />
                        <div
                            css={{
                                position: 'relative',
                                cursor: 'pointer',
                                width: '100%',
                            }}
                        >
                            <Button
                                loadingSpinner={loading}
                                loading={loading}
                                w100
                                w100Desktop
                                extraCss={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    fontSize: '12px',
                                    backgroundColor: colors.gray500,
                                }}
                            >
                                {imgFile?.name || 'WYBIERZ PLIK'}
                            </Button>
                            <Input
                                accept="image/*"
                                type="file"
                                name="photofile"
                                onChange={onAddImage}
                                placeholder="avatar"
                                darker
                                sInputExtra={{ opacity: 0, cursor: 'pointer' }}
                                sBaseExtra={{
                                    marginBottom: 0,
                                    cursor: 'pointer',
                                }}
                            />
                        </div>
                    </div>
                    <div
                        css={{
                            display: 'flex',
                            gap: '1rem',
                            [s.md]: { gap: '2rem' },
                        }}
                    >
                        <Button
                            size48
                            dimmed
                            action={onReset}
                            loading={loading}
                        >
                            <CloseIcon />
                        </Button>
                        <Button
                            w100
                            w100Desktop
                            action={handleFormSubmit}
                            loadingSpinner={loading}
                            loading={loading}
                            isLoading={loading}
                        >
                            Zapisz
                        </Button>
                    </div>
                </CollapsableText>
            </Collapsable>
        </Grid>
    );
};

export default SettingsAvatar;
