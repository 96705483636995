/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Button, ButtonBorder } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { sOuter, sGrid, sDescription, sPaddingBottom } from '@style/style';
import useMenuEdit from './hooks/useMenuEdit';
import { sButtonFixed } from '@/EZW/pages/Order/buttons/FixedButtons';
import Sections from './components/Sections';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import { Grid } from '@material-ui/core';

import WidgetContainer from '@components/Widget/Widget.component';
import { Collapse } from '@material-ui/core';
import AddProductsToSection from './components/AddProductsToSection';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

const MenuPage = () => {
    const {
        restaurantName,
        loading,
        isLoading,
        menuSections,
        wasChanges,
        setWasChanges,
        activeSectionId,
        isAddProductsOpen,
        singleSectionProducts,
        isNewSection,
        setMenuSections,
        setSingleSectionProducts,

        handleAddProductsOpen,
        handleAddProductsClose,
        onAddSection,
        onDeleteSection,
        onDeleteProduct,
        onNameChange,
        onSaveAddedProducts,
        onSave,
    } = useMenuEdit();

    const { bottomNavigation } = useSafeAreaInsets();

    const setIsAddProductsOpen = (isOpen) =>
        isOpen ? handleAddProductsClose : handleAddProductsOpen;

    const activeSection = (activeSectionId &&
        menuSections.find((section) => section.id === activeSectionId)) || {
        products: [],
    };

    if (loading)
        return (
            <React.Fragment>
                <div css={sOuter}>
                    <Headline>Menu</Headline>
                    <Grid container css={[sGrid, sPaddingBottom]}>
                        <p css={sDescription}>
                            Modyfikuj oraz twórz nowe rozdziały na karcie Menu
                            Restauracji.
                        </p>
                    </Grid>
                    <Grid container css={sGrid}>
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Grid>
                </div>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <TopNav title={'Menu'} offset={60} />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder action={onAddSection}>
                            Nowa Sekcja
                        </ButtonBorder>
                    }
                >
                    Menu
                </Headline>
                <Grid container css={[sGrid, sPaddingBottom]}>
                    <p css={sDescription}>
                        Modyfikuj oraz twórz nowe rozdziały na karcie Menu{' '}
                        <strong>{restaurantName}</strong>.
                    </p>
                </Grid>
                <Sections
                    menuSections={menuSections}
                    setMenuSections={setMenuSections}
                    handleAddProductsOpen={handleAddProductsOpen}
                    onDeleteSection={onDeleteSection}
                    onDeleteProduct={onDeleteProduct}
                    onNameChange={onNameChange}
                    wasChanges={wasChanges}
                    setWasChanges={setWasChanges}
                />
            </div>
            <WidgetContainer
                isWidgetOpen={isAddProductsOpen}
                setIsWidgetOpen={handleAddProductsClose}
            >
                <Collapse in={isAddProductsOpen}>
                    <div>
                        {isAddProductsOpen && (
                            <AddProductsToSection
                                isNewSection={isNewSection}
                                menuSections={menuSections}
                                sectionId={activeSectionId}
                                right={singleSectionProducts}
                                setRight={setSingleSectionProducts}
                            />
                        )}
                    </div>
                </Collapse>
            </WidgetContainer>
            <div
                css={[
                    sButtonFixed,
                    { zIndex: 1002, bottom: bottomNavigation },
                    !isAddProductsOpen &&
                        !wasChanges && {
                            transform: 'translate(-50%, 150%)',
                            opacity: 0,
                        },
                ]}
            >
                <Button
                    w100
                    w100Desktop
                    buttonTransparent={true}
                    action={isAddProductsOpen ? onSaveAddedProducts : onSave}
                    disabled={!isAddProductsOpen && (isLoading || !wasChanges)}
                    loadingSpinner={isLoading}
                    loading={isLoading}
                >
                    {isAddProductsOpen ? 'Zatwierdź' : 'Zapisz zmiany'}
                </Button>
            </div>
        </React.Fragment>
    );
};

export default MenuPage;
