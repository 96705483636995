import { sButtonFixed } from '../../../../EZW/pages/Order/buttons/FixedButtons';
import { Button } from '../../../../components/Elements';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { IOS } from '@constants/common.constants';

const SaveButton = ({ loading, onSubmit }) => {
    const { bottomNavigation } = useSafeAreaInsets();
    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    return (
        <div
            css={[
                sButtonFixed,
                { bottom: bottomNavigation },
                {
                    ...(IOS &&
                        keyboardHeight && { position: 'relative', bottom: 0 }),
                },
            ]}
        >
            <Button
                w100
                w100Desktop
                buttonTransparent={true}
                action={onSubmit}
                disabled={loading}
                loadingSpinner={loading}
                loading={loading}
            >
                Zapisz zmiany
            </Button>
        </div>
    );
};

export default SaveButton;
