import React, { useState } from 'react';
import { Grid, Collapse as MaterialCollapse } from '@material-ui/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import PersonIcon from '@material-ui/icons/Person';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { CustomerProps } from '@/EZW/domains/pages/NewOrderPage/NewOrderPage';
import { InputTextNew } from '@/componentsV2/atoms/InputTextNew/InputTextNew';

export interface AddNoAuthUserInfoProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    description?: string;
    onClick: (value: CustomerProps) => void;
}

export const AddNoAuthUserInfo = ({
    open,
    setOpen,
    description,
    onClick,
}: AddNoAuthUserInfoProps) => {
    const { control, handleSubmit, getValues } =
        useForm<DescriptionSchemaValues>({
            resolver: yupResolver(DescriptionSchema),
            defaultValues: {
                clientDescription: '',
            },
        });

    const {
        field: { onChange, value },
    } = useController({
        control: control,
        name: 'clientDescription',
    });

    const handleFormSubmit = handleSubmit(
        (value) => {
            onClick({ name: value.clientDescription });
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );

    const [userDescription, setUserDescription] = useState('');

    return (
        <>
            <Grid item xs={12}>
                <TextInputDescription
                    label="OPIS KLIENTA *"
                    onChange={(event) => {
                        onChange(event.currentTarget.value);
                        setOpen(true);
                        setUserDescription(event.currentTarget.value);
                    }}
                    value={value}
                    name="clientDescription"
                    inputProps={{ maxLength: 50 }}
                />

                {open && getValues('clientDescription') && (
                    <Collapse in={open}>
                        <>
                            <NoAuthUserInfo>
                                <IconWrapper>
                                    <PersonIcon />
                                </IconWrapper>
                                {userDescription}
                            </NoAuthUserInfo>
                            <Button
                                variant="black"
                                onClick={handleFormSubmit}
                                width="100%"
                            >
                                ZAPISZ OPIS
                            </Button>
                        </>
                    </Collapse>
                )}
            </Grid>
            <Description>{description}</Description>
        </>
    );
};

const DescriptionSchema = yup.object({
    clientDescription: yup
        .string()
        .trim()
        .required('Podaj opis klienta aby ułatwić identyfikację i obsługę'),
});

export interface DescriptionSchemaValues
    extends yup.InferType<typeof DescriptionSchema> {}

const TextInputDescription = styled(InputTextNew)`
    z-index: 9;
`;

const Collapse = styled(MaterialCollapse)`
    background: ${colors.gray100};
    border-radius: 0 0 24px 24px;
    padding: 24px;
    position: relative;

    &:before {
        background: ${colors.gray100};
        border-radius: 30px 30px 0 0;
        content: '';
        height: 52px;
        left: 0;
        position: absolute;
        top: -52px;
        width: 100%;
    }

    .MuiCollapse-wrapperInner {
        display: grid;
        grid-gap: 24px;
    }
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray300};
    border-radius: 50%;
    color: ${colors.white};
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

export const NoAuthUserInfo = styled.div`
    align-items: center;
    display: flex;
    font-size: 13px;
    font-weight: 700;
    gap: 12px;
`;

const Description = styled.p`
    color: ${colors.dust};
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
`;
