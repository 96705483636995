import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { ChipSecondary } from '@/componentsV2/atoms/ChipSecondary/ChipSecondary';
import { InputTextNew } from '@/componentsV2/atoms/InputTextNew/InputTextNew';
import { useDebounce } from '@hooks/useDebounce';

export interface DistanceBoxProps {
    onChange: (value: DistanceBoxProps['value']) => void;
    value: string | null;
}

// TODO: change
export const chipsData = [
    { name: 'do 1 km', id: '1' },
    { name: 'do 2 km', id: '2' },
    { name: 'do 3 km', id: '3' },
];

export const DistanceBox = ({ onChange, value }: DistanceBoxProps) => {
    const [inputValue, setInputValue] = useState(
        chipsData.some((item) => item.id === value) ? '' : value,
    );

    const debounce = useDebounce(inputValue, 400);

    useEffect(() => {
        debounce && onChange(debounce);
    }, [debounce]);

    return (
        <>
            <DistanceBoxWrapper>
                {chipsData.map((item) => {
                    return (
                        <ChipSecondary
                            key={item.id}
                            onClick={() => {
                                onChange(value === item.id ? '' : item.id);
                                setInputValue('');
                            }}
                            text={item.name}
                            value={item}
                            variant={value === item.id ? 'default' : 'gray'}
                            size="default"
                        />
                    );
                })}
            </DistanceBoxWrapper>
            <InputTextNew
                placeholder="Wpisz"
                onChange={(event) => {
                    const value = event.target.value;

                    setInputValue(value);

                    !value && onChange(value);
                }}
                type="number"
                value={inputValue}
                defaultValue="50"
            />
        </>
    );
};

const DistanceBoxWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    padding-bottom: 16px;
    width: 100%;
`;
