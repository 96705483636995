import React from 'react';

import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import ActiveOrderItem from '../components/ActiveOrderItem';

import { Grid, Hidden } from '@material-ui/core';
import { s } from '@style';
import { sDescription, sBottomLinks } from '@style/style';
import { useAuthService } from '@/services/AuthService';
import { useAllActiveOrdersLazyQuery } from '@graphql/generated';

import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import styled from '@emotion/styled';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useHistory } from 'react-router-dom';
import { RestaurantAvatar } from '@/svgs/RestaurantAvatar';

const AllActiveOrdersAuth = () => {
    const [getAllActiveOrders, { data, loading }] =
        useAllActiveOrdersLazyQuery();
    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getAllActiveOrders({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        filters: {
                            statuses: [
                                'ACCEPTED',
                                'COMPLETED',
                                'DELIVERY',
                                'PARTIAL_ACCEPTANCE',
                                'PENDING',
                                'PREPARED',
                                'PREPARING',
                                'SENT_TO_RESTAURANT',
                                'VERIFICATION',
                            ],
                        },
                    },
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.orders.metadata,
    });

    if (!loading && !data?.orders.metadata.totalCount)
        return (
            <div css={sDescription}>
                Na ten moment nie posiadasz żadnych aktywnych Zamówień
            </div>
        );

    return (
        <>
            {data?.orders.data.map((order, id) => (
                <ActiveOrderItem
                    id={id}
                    key={order.id}
                    orderId={order.id}
                    date={order.details.date}
                    consumptionType={order.details.type}
                    restaurantAvatar={
                        order.restaurant.avatarUrl ? (
                            <img
                                src={order.restaurant.avatarUrl}
                                alt={order.restaurant.name}
                                loading="lazy"
                            />
                        ) : (
                            <RestaurantAvatar />
                        )
                    }
                    restaurantName={order.restaurant.name}
                    priceSum={order.priceSum}
                    isPaid={order.isPaid}
                    status={order.status}
                />
            ))}
            {loading && (
                <>
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <Hidden mdUp>
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Hidden>
                </>
            )}
            {hasMore && (
                <Grid item xs={12}>
                    <ShowMoreButton onClick={getMoreData} disabled={loading}>
                        Pokaż więcej
                    </ShowMoreButton>
                </Grid>
            )}
        </>
    );
};

const AllActiveOrdersNoAuth = () => {
    const history = useHistory();

    return (
        <>
            <p css={sDescription}>
                Panel Zamówień jest widoczny tylko dla zalogowanych użytkowników
            </p>
            <Grid item xs={12} css={sBottomLinks}>
                <ButtonBorder onClick={() => history.push('/login')}>
                    Zaloguj
                </ButtonBorder>
                <ButtonBorder onClick={() => history.push('/signup')}>
                    Zarejestruj
                </ButtonBorder>
            </Grid>
        </>
    );
};

const AllActiveOrders = () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const history = useHistory();

    if (isLoggedIn)
        return (
            <>
                <AllActiveOrdersAuth />
                <Grid
                    item
                    xs={12}
                    css={{
                        display: 'flex',
                        [s.sm_down]: {
                            paddingTop: '0.5rem',
                        },
                        [s.md]: {
                            paddingTop: '1.5rem',
                        },
                    }}
                >
                    <ButtonBorder
                        onClick={() => history.push('/orders-history')}
                    >
                        Historia Zamówień
                    </ButtonBorder>
                    <span css={{ marginLeft: '1rem' }} />
                    <ButtonBorder
                        onClick={() => history.push('/account/notifications')}
                    >
                        Powiadomienia
                    </ButtonBorder>
                </Grid>
            </>
        );
    return <AllActiveOrdersNoAuth />;
};

const ShowMoreButton = styled(ButtonBorder)`
    margin-bottom: 32px;
`;

export default AllActiveOrders;
