import { useEffect } from 'react';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';
import { useDeliveryInfoQuery } from '@graphql/generated';
import { convertTimestamps } from '@helpers/convertTimestamps';

const useDeliveryInfo = ({ restId, customCoordinates }) => {
    if (!restId) console.error('useDeliveryInfo wymaga restId');

    const { geolocation } = useGeolocationContext();
    const { lat, lng } = geolocation;

    const coordinates =
        customCoordinates && customCoordinates.length === 2
            ? customCoordinates
            : [lng, lat];

    const { data, loading, error, refetch } = useDeliveryInfoQuery({
        variables: {
            input: { restaurantId: restId },
            distance: { coordinates },
        },
        skip: !coordinates?.[0],
    });

    useEffect(() => {
        !customCoordinates?.length && refetch();
    }, [geolocation]);

    const {
        availableHours,
        deliveryCost,
        deliveryRadius,
        freeDeliveryAmount,
        minAmount,
    } = data?.restaurant.deliverySettings || {};

    const distance = parseInt(data?.restaurant?.distance);

    const _deliveryCostAmount =
        distance <= deliveryRadius && data?.restaurant
            ? [...deliveryCost]
                  ?.sort((a, b) => a.deliveryRadius - b.deliveryRadius)
                  .find((item) => item.deliveryRadius >= distance)?.amount ??
              [...deliveryCost]?.sort(
                  (a, b) => a.deliveryRadius - b.deliveryRadius,
              )[deliveryCost.length - 1]?.amount
            : null;

    return {
        data,
        loading,
        error,
        availableHours: convertTimestamps.fromISO(availableHours),
        deliveryCost,
        deliveryRadius,
        distanceToUser: distance,
        freeDeliveryAmount,
        minAmount,
        _deliveryCostAmount,
    };
};

export default useDeliveryInfo;
