import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import PageLoader from '../../../components/PageLoader/PageLoader';
import {
    sOuter,
    sNoMarginTop,
    sGrid,
    sBottomLinks,
} from '../../../style/style';
import { ButtonBorder } from '../../../components/Elements';
import { colors, s } from '@style';
import Headline from '../../../components/Headline/Headline';
import { Grid, ButtonBase, useTheme, useMediaQuery } from '@material-ui/core';
import { StatusOrder, SectionHeadline } from '../../../components/Elements';
import OrderAddress from '../../../EZC/pages/orders/components/OrderAddress';
import OrderDate from '../../../EZC/pages/orders/components/OrderDate';
import OrderInvoice from '../../../EZC/pages/orders/components/OrderInvoice';
import OrderNote from '../../../EZC/pages/orders/components/OrderNote';
import OrderPaymentLarge from '../../../EZC/pages/orders/components/OrderPaymentLarge';
import OrderID from '../../../EZC/pages/orders/components/OrderID';
import OrderStatuses from '../../../EZC/pages/orders/components/OrderStatuses';

import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import StatusGuide from '../../../components/StatusGuide/StatusGuide';
import OrderUser from './OrderUser';
import { ORDER_STATUS } from '@constants/fromGraphql/OrderStatus.constants';
import OrderChat from '@/EZC/pages/orders/components/OrderChat';
import { OrderChatHistory } from '@/EZC/pages/orders/components/OrderChatHistory';
import { OrderType } from '@/componentsV2/molecules/OrderType/OrderType';
import { dateFormat } from '@helpers/dateFormat';
import { ProductTileChangeProductStatus } from '@/EZW/components/organisms/ProductTileChangeProductStatus/ProductTileChangeProductStatus';
import { ProductTile } from 'src/componentsV2/molecules/ProductTile/ProductTile';
import styled from '@emotion/styled';
import { mediaQueries, mediaQueriesMaterial } from '@style/breakpoints';
import FixedButtons from '@/EZW/pages/Order/buttons/FixedButtons';
import { ButtonBorder as ButtonV2 } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useOrderPage } from './useOrderPage';
import { GroupModeIcon } from '@/svgs/GroupModeIcon';
import { SingleModeIcon } from '@/svgs/SingleModeIcon';
import { ProductStatusSelect } from '@/componentsV2/molecules/ProductStatusSelect/ProductStatusSelect';
import { ItemWithCheckbox } from '@/componentsV2/atoms/ItemWithCheckbox/ItemWithCheckbox';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { css } from '@emotion/react';
import { globals } from '@style/index';
import { useOrderPageChangeLabel } from './useOrderPageChangeLabel';
import _ from 'lodash';
import { OrderServingTime } from '@/componentsV2/molecules/OrderServingTime/OrderServingTime';
import { OrderPhone } from '@/componentsV2/molecules/OrderPhone/OrderPhone';
import { Text } from '@/EZC/pages/orders/components/ActiveOrderItem';

const OrderActive = () => {
    const [isCancelWidgetOpen, setIsCancelWidgetOpen] = useState(false);
    const [isStatusGuideOpen, setIsStatusGuideOpen] = useState(false);
    const [showFixedButtons, setShowFixedButtons] = useState(true);

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const { bottomNavigation } = useSafeAreaInsets();

    const {
        canChangeStatus,
        deliveryAddress,
        deliveryCost,
        deliveryDetails,
        details: orderDetails,
        id,
        order,
        orderId,
        orderPageError,
        orderPageLoading,
        owner,
        priceSum,
        productsGroupedById,
        productsIds,
        shortId,
        status,
        statusLog,
        timeLeft,
        userId,
        waiterNote,
    } = useOrderPage();

    const {
        changeLabel,
        checkedItems,
        darkFont,
        groupMode,
        handleCheckboxChange,
        handleProductsLabelChange,
        isItemsChangeStatusLoading,
        selectedLabel,
        setGroupMode,
        submitForm,
    } = useOrderPageChangeLabel();

    useEffect(() => {
        if (!groupMode) return;

        isItemsChangeStatusLoading && setShowFixedButtons(false);

        const timerId = setTimeout(() => {
            setShowFixedButtons(true);
        }, 1500);

        return () => clearTimeout(timerId);
    }, [isItemsChangeStatusLoading]);

    if (orderPageLoading) return <PageLoader />;

    const packageCost =
        status === 'REJECTED'
            ? 0
            : orderDetails?.type === 'IN'
              ? 0
              : order?.items?.reduce(
                    (sum, curr) =>
                        curr.status === 'CANCELED'
                            ? 0 + sum
                            : curr.product.packagingPrice + sum,
                    0,
                );

    const deliveryCostFinal =
        status === 'REJECTED' || status === 'CANCELED' ? 0 : deliveryCost;

    if (orderPageError) {
        return (
            <React.Fragment>
                <TopNav title={'Zamówienie'} subtitle={` - nieodnalezione`} />
                <div css={sOuter}>
                    <Headline>Nieodnaleziono</Headline>
                    <Grid container css={sGrid}>
                        <Grid item xs={12} css={sBottomLinks}>
                            <ButtonBorder link={'/orders'}>
                                Zamówienia
                            </ButtonBorder>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    const checkedAll =
        checkedItems?.length ===
        order?.items?.filter((item) => item?.label !== 'CANCELED')?.length;

    return (
        <>
            <TopNav
                title={shortId}
                subtitle={` - ${owner?.personal.name ?? ''} ${
                    owner?.personal.surname ?? ''
                }`}
                photo={owner?.personal.avatarUrl}
                textCss={{
                    [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
                }}
            />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <div
                            css={{ cursor: 'pointer' }}
                            onClick={() =>
                                setIsStatusGuideOpen(!isStatusGuideOpen)
                            }
                        >
                            {!(
                                status === 'COMPLETED' &&
                                order?.details?.type === 'TAKEAWAY'
                            ) ? (
                                <StatusOrder
                                    color={
                                        status === 'COMPLETED' &&
                                        order?.details?.type === 'DELIVERY'
                                            ? ORDER_STATUS[status]?.delivery
                                                  ?.color
                                            : ORDER_STATUS[status]?.color
                                    }
                                >
                                    {status === 'COMPLETED' &&
                                    order?.details?.type === 'DELIVERY'
                                        ? ORDER_STATUS[status]?.delivery?.name
                                        : ORDER_STATUS[status]?.name}
                                </StatusOrder>
                            ) : (
                                <StatusOrder
                                    color={ORDER_STATUS['PREPARED']?.color}
                                >
                                    {ORDER_STATUS['PREPARED']?.name}
                                </StatusOrder>
                            )}
                        </div>
                    }
                    extraCss={sHeadline}
                >
                    Zamówienie <ShortId>{shortId}</ShortId>
                    <OrderDateHeadline>
                        {dateFormat.standardDate(statusLog[0]?.date)}
                    </OrderDateHeadline>
                </Headline>
                <Grid container css={sGrid}>
                    {(order?.status === 'ACCEPTED' ||
                        order?.status === 'PREPARING' ||
                        order?.status === 'DELIVERY') &&
                        !isSm &&
                        order?.details?.type !== 'IN' &&
                        timeLeft > 0 && (
                            <Info>
                                Szacowany czas przygotowania i dostawy:{' '}
                                <strong>{timeLeft} minut. </strong>
                            </Info>
                        )}
                    <OrderUser
                        personal={{
                            name: !!owner
                                ? `${owner?.personal.name ?? ''} ${
                                      owner?.personal.surname ?? ''
                                  }`.trim()
                                : `${waiterNote}`,
                            avatarUrl: owner?.personal.avatarUrl,
                        }}
                        consumptionDate={orderDetails?.date}
                        consumptionPersons={orderDetails?.guests}
                        consumptionType={orderDetails?.type}
                        priceSum={priceSum}
                        paidSum={priceSum}
                        additionalComponent={
                            isSm &&
                            order?.details?.type !== 'IN' &&
                            (order?.status === 'ACCEPTED' ||
                                order?.status === 'PREPARING' ||
                                order?.status === 'DELIVERY') && (
                                <TimeInfoWrapper>
                                    <OrderTimeWrapper isSm={isSm}>
                                        <Text>Godzina odbioru:</Text>
                                        <span>
                                            {dateFormat.standardTime(
                                                order?.delivery?.eta,
                                            )}
                                        </span>
                                    </OrderTimeWrapper>
                                    <TimeLeftWrapper>
                                        <OrderTimeLeft>
                                            {dateFormat.calculateTimeLeft(
                                                order?.delivery?.eta,
                                            )}
                                        </OrderTimeLeft>
                                    </TimeLeftWrapper>
                                </TimeInfoWrapper>
                            )
                        }
                    />
                </Grid>
            </div>
            <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
                <Grid
                    container
                    css={[sGrid, sGridSpacing]}
                    justifyContent="space-between"
                    spacing={3}
                >
                    <SectionHeadlineWrapper>
                        <SectionHeadline>Produkty</SectionHeadline>

                        {canChangeStatus && (
                            <SwitchButton
                                onClick={() => setGroupMode(!groupMode)}
                            >
                                {!groupMode ? (
                                    <ModeButtonWrapper>
                                        <GroupModeIcon />
                                        Grupowa edycja
                                    </ModeButtonWrapper>
                                ) : (
                                    <ModeButtonWrapper>
                                        <SingleModeIcon />
                                        Pojedyncza edycja
                                    </ModeButtonWrapper>
                                )}
                            </SwitchButton>
                        )}
                    </SectionHeadlineWrapper>
                    {canChangeStatus && (
                        <ModeInfo>
                            Zarządzaj statusami produktów, zmieniając pojedyncze
                            lub jednocześnie dostosowując statusy dla kilku
                            produktów.
                        </ModeInfo>
                    )}
                    {groupMode && (
                        <MarkAllWrapper
                            container
                            justifyContent="space-between"
                            spacing={3}
                        >
                            <ProductInfoWrapper item xs={6}>
                                {canChangeStatus && (
                                    <MarkAsReadyButtonWrapper>
                                        <MarkAllAsReady
                                            onClick={() => {
                                                handleCheckboxChange(
                                                    order?.items
                                                        ?.filter(
                                                            (item) =>
                                                                item?.label !==
                                                                'CANCELED',
                                                        )
                                                        .map(
                                                            (item) => item?.id,
                                                        ),
                                                    !checkedAll,
                                                );
                                            }}
                                        >
                                            {checkedAll
                                                ? 'Odznacz wszystko'
                                                : 'Zaznacz wszystko'}
                                        </MarkAllAsReady>
                                    </MarkAsReadyButtonWrapper>
                                )}
                            </ProductInfoWrapper>
                            <ButtonWrapper item xs={6}>
                                {canChangeStatus && (
                                    <ProductStatusSelect
                                        showNoSelectableValue
                                        loading={isItemsChangeStatusLoading}
                                        value={selectedLabel}
                                        darkFont={darkFont}
                                        onChange={(label) =>
                                            handleProductsLabelChange(label)
                                        }
                                        disabled={!checkedItems.length}
                                    />
                                )}
                            </ButtonWrapper>
                        </MarkAllWrapper>
                    )}
                </Grid>
                {canChangeStatus &&
                    groupMode &&
                    productsIds.map((item) => {
                        const allCanceledProductsInGroup = productsGroupedById[
                            item
                        ]?.every((product) => product?.label === 'CANCELED');
                        return (
                            <ItemWrapper key={item}>
                                <ItemWithCheckbox
                                    checked={productsGroupedById[item]?.some(
                                        (product) =>
                                            product &&
                                            checkedItems.includes(product.id),
                                    )}
                                    indeterminate={
                                        (productsGroupedById[item]?.some(
                                            (product) =>
                                                product &&
                                                checkedItems.includes(
                                                    product.id,
                                                ),
                                        ) &&
                                            productsGroupedById[item]?.some(
                                                (product) =>
                                                    product &&
                                                    !checkedItems.includes(
                                                        product.id,
                                                    ),
                                            )) ||
                                        allCanceledProductsInGroup
                                    }
                                    disabled={allCanceledProductsInGroup}
                                    children={
                                        <ProductTileChangeProductStatus
                                            items={productsGroupedById[item]}
                                            changeableStatus
                                            loading={false}
                                            onChangeLabel={changeLabel}
                                            checkbox={groupMode}
                                            checkedValues={checkedItems}
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    onChange={(_, checked) => {
                                        handleCheckboxChange(
                                            productsGroupedById[item]
                                                .filter(
                                                    (item) =>
                                                        item.label !==
                                                        'CANCELED',
                                                )
                                                .map((item) => item.id),
                                            checked,
                                        );
                                    }}
                                />
                            </ItemWrapper>
                        );
                    })}
                <Grid
                    container
                    css={[sGrid, sGridSpacing]}
                    justifyContent="space-between"
                    spacing={3}
                >
                    {canChangeStatus &&
                        !groupMode &&
                        productsIds.map((productId) => (
                            <Grid item xs={12} md={6}>
                                <ProductTileChangeProductStatus
                                    items={productsGroupedById[productId]}
                                    changeableStatus={true}
                                    orderId={orderId}
                                    onChangeLabel={changeLabel}
                                    loading={isItemsChangeStatusLoading}
                                />
                            </Grid>
                        ))}
                    {!canChangeStatus && !groupMode && (
                        <>
                            {productsIds.map((productId) => {
                                const productGroupedById =
                                    order?.details?.type === 'IN'
                                        ? productsGroupedById[productId][0]
                                        : productsGroupedById[productId].filter(
                                              (item) =>
                                                  item.status !== 'CANCELED',
                                          )[0];
                                {
                                    return productGroupedById ? (
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            key={productId}
                                        >
                                            <ProductTile
                                                quantity={
                                                    productsGroupedById[
                                                        productId
                                                    ].filter(
                                                        (item) =>
                                                            item.status !==
                                                            'CANCELED',
                                                    ).length
                                                }
                                                price={
                                                    productGroupedById.product
                                                        .price *
                                                    productsGroupedById[
                                                        productId
                                                    ].filter(
                                                        (item) =>
                                                            item.status !==
                                                            'CANCELED',
                                                    ).length
                                                }
                                                productPrice={
                                                    productGroupedById.product
                                                        .price
                                                }
                                                name={
                                                    productGroupedById.product
                                                        .name
                                                }
                                                imageUrl={
                                                    productGroupedById.product
                                                        .photoUrl
                                                }
                                            />
                                        </Grid>
                                    ) : null;
                                }
                            })}
                        </>
                    )}
                </Grid>
            </div>
            <div
                css={[
                    sOuter,
                    sNoMarginTop,
                    sGridSpacingParent,
                    { marginBottom: '72px' },
                ]}
            >
                <Grid
                    container
                    css={[sGrid, sGridSpacing]}
                    spacing={3}
                    justifyContent="space-between"
                >
                    <SectionHeadline>Szczegóły</SectionHeadline>

                    {status === 'REJECTED' ||
                    status === 'CANCELED' ||
                    status === 'CLOSED' ? (
                        <OrderChatHistory
                            orderId={orderId}
                            userId={userId}
                            waiter
                            noPaddingBottom
                        />
                    ) : (
                        <OrderChat
                            orderId={orderId}
                            name={owner?.personal.name}
                            avatarUrl={owner?.personal.avatarUrl}
                            link={`/waiter/chat/${orderId}`}
                            waiter
                        />
                    )}

                    <OrderType
                        consumptionType={orderDetails?.type}
                        tableNumber={orderDetails?.table}
                        tableChange
                    />
                    {orderDetails?.type === 'DELIVERY' && (
                        <OrderAddress
                            deliveryAddress={deliveryAddress}
                            deliveryDetails={deliveryDetails}
                        />
                    )}
                    {orderDetails?.phone && (
                        <OrderPhone phone={orderDetails?.phone} />
                    )}
                    <OrderDate
                        consumptionDate={orderDetails.date}
                        consumptionPersons={orderDetails.guests}
                    />
                    {orderDetails?.type === 'IN' && (
                        <OrderServingTime
                            serveOnTime={orderDetails.serveOnTime}
                        />
                    )}
                    {orderDetails?.note && (
                        <OrderNote note={orderDetails?.note} />
                    )}
                    {orderDetails?.invoice && (
                        <OrderInvoice invoiceDetails={orderDetails?.invoice} />
                    )}
                    <OrderStatuses
                        open={isStatusGuideOpen}
                        setOpen={setIsStatusGuideOpen}
                        statuses={statusLog}
                        waiter
                    />
                    <OrderID shortId={shortId} orderId={id} />
                    <OrderPaymentLarge
                        productsCost={priceSum?.toFixed(
                            priceSum - deliveryCostFinal === 0 ? 0 : 2,
                        )}
                        packageCost={
                            orderDetails?.type === 'IN'
                                ? null
                                : packageCost?.toFixed(2)
                        }
                        priceSum={priceSum + deliveryCostFinal + packageCost}
                        paidSum={priceSum + deliveryCostFinal + packageCost}
                        deliveryCost={deliveryCostFinal}
                        isDelivery={orderDetails?.type === 'DELIVERY'}
                    />
                </Grid>
            </div>
            {selectedLabel === 'NOTSET' && showFixedButtons && (
                <FixedButtons
                    zIndex={isCancelWidgetOpen ? 999 : 1000}
                    isBlocked={
                        dateFormat.calculateRemainingHours(
                            orderDetails?.date,
                            new Date(),
                        ) > 3
                    }
                    status={status}
                    order={order}
                    loading={orderPageLoading}
                    orderType={orderDetails?.type}
                />
            )}
            {selectedLabel !== 'NOTSET' && (
                <ButtonSaveWrapper bottom={bottomNavigation}>
                    <Button variant="black" onClick={submitForm}>
                        Zapisz
                    </Button>
                </ButtonSaveWrapper>
            )}
            <StatusGuide
                open={isStatusGuideOpen}
                setOpen={setIsStatusGuideOpen}
            />
        </>
    );
};
const ItemWrapper = styled.div`
    padding: 5px 24px 8px 12px;
    width: 100%;
`;
const Info = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 24px;
    text-align: center;
    width: 100%;
`;

const ModeInfo = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 32px;
    padding: 0 32px;
    width: 100%;

    ${mediaQueriesMaterial.sm} {
        margin: 0 0 18px;
        padding: 0 22px;
    }

    ${mediaQueriesMaterial.xs} {
        padding: 0;
    }
`;

const OrderTimeWrapper = styled.p`
    display: grid;
    gap: 2px;
    text-align: start;
    font-size: 15px;
    color: ${colors.text};

    ${mediaQueriesMaterial.md} {
        gap: 0;
    }

    ${mediaQueriesMaterial.sm} {
        font-size: 13px;
        justify-items: flex-end;
    }
`;

const TimeLeftWrapper = styled.p`
    text-align: end;
`;

const TimeInfoWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding-right: 120px;
    font-size: 12px;
    line-height: 18px;
    gap: 64px;
    color: ${colors.blueZodiac};
    flex: 1;
    align-items: center;

    ${mediaQueriesMaterial.md} {
        gap: 56px;
        padding-right: 80px;
    }

    ${mediaQueries.md} {
        gap: 40px;
        padding-right: 0;
    }

    ${mediaQueriesMaterial.sm} {
        display: grid;
        gap: 12px;
    }
`;

const SectionHeadlineWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    ${mediaQueriesMaterial.sm} {
        padding: 0 22px;
    }

    ${mediaQueriesMaterial.xs} {
        padding: 0;
    }
`;

const ModeButtonWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
`;

const ButtonWrapper = styled(Grid)`
    display: flex;
    justify-content: end;
`;

const SwitchButton = styled(ButtonV2)`
    margin-right: 32px;
    margin-top: 38px;
    white-space: nowrap;

    ${mediaQueriesMaterial.sm} {
        margin-right: 0;
    }
`;

const OrderTimeLeft = styled.span`
    font-weight: 700;
    color: ${colors.text};
    font-size: 18px;
    line-height: 24px;

    ${s.md} {
        font-size: 30px;
    }
`;

const ProductInfoWrapper = styled(Grid)`
    margin-bottom: 25px;
`;

const MarkAllWrapper = styled(Grid)`
    margin-bottom: 24px;
    padding: 0 32px;

    ${mediaQueriesMaterial.sm} {
        padding: 0 22px;
    }

    ${mediaQueriesMaterial.xs} {
        padding: 0;
    }
`;

const ShortId = styled.span`
    display: inline;

    ${s.xs} {
        display: flex;
        justify-content: center;
    }
`;

const OrderDateHeadline = styled.p`
    color: ${colors.gray600};
    font-size: 15px;
    font-weight: 700;
    text-align: left;

    ${mediaQueriesMaterial.xs} {
        font-size: 13px;
        text-align: center;
    }
`;

const sGridSpacing = {
    '.MuiGrid-item': {
        [s.xs]: { paddingBottom: '4px !important' },
    },
};

const sHeadline = {
    alignItems: 'center',
    [s.xs]: {
        padding: '2rem 1.5rem 1.5rem',
        justifyContent: 'center',
        flexDirection: 'column',
    },
};

const sGridSpacingParent = {
    padding: '0 0 40px',
    [s.xs]: {
        '.MuiGrid-spacing-xs-3': {
            width: '100%',
            margin: 0,

            '& > .MuiGrid-item': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
};

const MarkAsReadyButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-left: 0;
    padding-right: 32px;
    padding-bottom: 0;

    ${mediaQueriesMaterial.sm} {
        padding-right: 0;
    }
`;

const MarkAllAsReady = styled.button`
    border: none;
    background-color: inherit;
    cursor: pointer;
    font-size: 12px;
    line-height: 20px;
    text-decoration: underline;
    display: inline;
    color: ${colors.text};
`;

export const ButtonSaveWrapper = styled.div(
    ({ bottom }) => css`
        display: grid;
        grid-gap: 8px;
        width: 100%;
        padding: 0 32px;
        max-width: ${globals.maxWidthMedium}px;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 24px;
        transition:
            bottom 0.6s ease-in-out,
            filter 0.3s ease-out,
            transform 0.3s ease-out,
            opacity 0.3s ease-out;
        z-index: 1000;
        bottom: ${bottom ? `${bottom}px` : '72px'};
    `,
);

export default OrderActive;
