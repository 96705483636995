import { toast } from 'react-toastify';

import { Environment, useMoveRestaurantMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useState } from 'react';
import { SOURCE_ENVIRONMENT } from '@constants/fromGraphql/Environment.constants';

type RestaurantState = { loading?: boolean; value?: Environment };

export const useExportRestaurant = () => {
    const [restaurantsState, setRestaurantsState] =
        useState<Record<string, RestaurantState>>();

    const [moveRestaurant] = useMoveRestaurantMutation({
        onError: graphqlErrorHandler(true),
    });

    const handleRestaurantState = (
        restaurantId: string,
        newState: Partial<RestaurantState>,
    ) => {
        setRestaurantsState((prevState) => {
            const currentState = prevState?.[restaurantId] ?? {};

            return {
                ...prevState,
                [restaurantId]: { ...currentState, ...newState },
            };
        });
    };

    const handleMoveRestaurant = async (
        restaurantId: string,
        destinationEnv: Environment,
    ) => {
        handleRestaurantState(restaurantId, { loading: true });

        await moveRestaurant({
            variables: {
                input: {
                    restaurantId,
                    destinationEnv,
                    sourceEnv: SOURCE_ENVIRONMENT(),
                },
            },
            onCompleted: () => {
                toast.success(`Restauracja została wyeksportowana`);
            },
            onError: (error) => {
                toast.error(error.message);
            },
        });

        handleRestaurantState(restaurantId, { loading: false });
    };

    return {
        handleMoveRestaurant,
        restaurantsState,
        handleRestaurantState,
    };
};
