import NiceModal from '@ebay/nice-modal-react';
import { popup } from '@/AppPopups';
import { BasePopupNew } from '@/componentsV2/organisms/BasePopupNew/BasePopupNew';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { PopupFooterSticky } from '@/componentsV2/organisms/BasePopupNew/PopupFooterSticky';
import { PopupHeaderSecondary } from '@/componentsV2/organisms/BasePopupNew/PopupHeaderSecondary';
import CloseIcon from '@material-ui/icons/Close';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { css } from '@emotion/react';
import {
    SearchFilterFormSchemaValues,
    useSearchFilterFormSubmit,
} from '@/EZC/domains/Search/SearchFilterFormProvider/SearchFilterFormProvider';
import { SearchFilterPopupFields } from './SearchFilterPopupFields';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';

export interface SearchFilterPopupProps {
    methods: UseFormReturn<SearchFilterFormSchemaValues, any, undefined>;
}
export interface SearchFilterPopupInnerProps {
    onCancel: () => void;
}

export const SearchFilterPopup = NiceModal.create(
    ({ methods }: SearchFilterPopupProps) => {
        return (
            <FormProvider {...methods}>
                <SearchFilterPopupInner
                    onCancel={() => {
                        popup.hide('SearchFilterPopup');
                    }}
                />
            </FormProvider>
        );
    },
);

export const SearchFilterPopupInner = ({
    onCancel,
}: SearchFilterPopupInnerProps) => {
    const { handleFormSubmit, handleReset, handleClear } =
        useSearchFilterFormSubmit();

    const { loading } = useGeolocationContext();

    return (
        <BasePopupNew
            header={
                <PopupHeaderSecondary
                    text="Filtry"
                    iconButton={{
                        icon: <CloseIcon />,
                        onClick: () => {
                            onCancel();
                            handleReset();
                        },
                    }}
                    textButton={{
                        text: 'Wyczyść',
                        onClick: () => {
                            handleClear();
                        },
                        variant: 'black',
                    }}
                />
            }
            footer={
                <PopupFooterSticky>
                    <Button
                        loading={loading}
                        variant="black"
                        width="100%"
                        onClick={handleFormSubmit}
                    >
                        Wyszukaj
                    </Button>
                </PopupFooterSticky>
            }
            alwaysFullHeight
            stickyFooter
            stickyHeader
        >
            <div>
                <SearchFilterPopupFields />
            </div>
        </BasePopupNew>
    );
};

export const Title = styled.p<{ padding?: boolean }>(
    () => css`
        align-items: baseline;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        gap: 2px;
    `,
);

export const TitleInfo = styled.span`
    font-size: 11px;
    font-weight: 400;
    color: ${colors.gray600};
`;
