import styled from '@emotion/styled';
import { colors } from '@style/index';

export const BadgeWithScore = () => {
    return <BadgeWithScoreWrapper>Najlepsza trafność</BadgeWithScoreWrapper>;
};

const BadgeWithScoreWrapper = styled.div`
    background-color: ${colors.greenBlue};
    border-radius: 16px;
    color: ${colors.white};
    font-size: 11px;
    font-weight: 700;
    line-height: 16.5px;
    padding: 2px 12px;
    text-transform: uppercase;
    width: max-content;
`;
