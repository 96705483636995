import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import {
    CuisinePreferenceInput,
    DietPreferenceInput,
    DishPreferenceInput,
    FlavorPreferenceInput,
    useIngredientPopularListQuery,
    useIngredientsSuggestedListQuery,
    usePreferencesPopupQuery,
    useUpdateUserPreferencesMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { FLAVOUR_NAME } from '@constants/fromGraphql/Flavor.constants';
import { DISH_NAME } from '@constants/fromGraphql/Dish.constants';
import { CUISINE_NAME } from '@constants/fromGraphql/Cuisine.constants';
import { DIET_NAME } from '@constants/fromGraphql/Diet.constants';
import { usePreferencesIngredients } from '@hooks/usePreferencesIngreedients/usePreferencesIngreedients';
import { usePopup } from '@/AppPopups';

type PrefferenceType = { id: string; name: string };

type PreferenceCategory = {
    preffered: PrefferenceType[];
    notPreffered: PrefferenceType[];
};

const defaultPreferences = {
    preffered: [],
    notPreffered: [],
};

export type ChipPreferencesValues = {
    diet: PreferenceCategory;
    cuisine: PreferenceCategory;
    dish: PreferenceCategory;
    flavor: PreferenceCategory;
    ingredients: PreferenceCategory;
    allergies: PreferenceCategory;
    spiciness: [number, number];
};

const parseCategory = <T extends string>(
    value: T[],
    object: Partial<Record<T, string>>,
): PrefferenceType[] =>
    value?.map((item) => ({
        id: item,
        name: object[item] as T,
    })) || [];

export const usePreferencesPopup = () => {
    const popup = usePopup();

    const { data, loading } = usePreferencesPopupQuery({
        onError: graphqlErrorHandler(true),
    });
    const { allergies, ingredients } = usePreferencesIngredients();

    const igredientPopularList = useIngredientPopularListQuery({
        onError: graphqlErrorHandler(true),
        variables: {
            input: {
                limit: 8,
            },
        },
    });
    const igredientSuggestedList = useIngredientsSuggestedListQuery({
        onError: graphqlErrorHandler(true),
        variables: {
            input: {
                limit: 8,
            },
        },
    });

    const [updateUserPreferences, { loading: updateLoading }] =
        useUpdateUserPreferencesMutation();

    const {
        control,
        reset,
        handleSubmit,
        formState: { isDirty },
    } = useForm<ChipPreferencesValues>({
        defaultValues: {
            diet: defaultPreferences,
            cuisine: defaultPreferences,
            dish: defaultPreferences,
            flavor: defaultPreferences,
            ingredients: defaultPreferences,
            allergies: defaultPreferences,
            spiciness: [0, 5],
        },
    });

    const { preferences } = data?.user || {};

    const handleFormSubmit = handleSubmit((formData) => {
        if (!preferences) return;
        if (!isDirty) {
            return toast.error(
                'Preferencje nie zostały zapisane, ponieważ żadne wybory nie zostały dokonane.',
            );
        }

        const getPreferences = (chipsValue: PreferenceCategory) => ({
            notPreffered: chipsValue.notPreffered.map(({ id }) => id),
            preffered: chipsValue.preffered.map(({ id }) => id),
        });

        updateUserPreferences({
            variables: {
                input: {
                    payload: {
                        preferences: {
                            cuisine: getPreferences(
                                formData.cuisine,
                            ) as CuisinePreferenceInput,
                            diet: getPreferences(
                                formData.diet,
                            ) as DietPreferenceInput,
                            dish: getPreferences(
                                formData.dish,
                            ) as DishPreferenceInput,
                            flavor: getPreferences(
                                formData.flavor,
                            ) as FlavorPreferenceInput,
                            ingredients: {
                                preffered: getPreferences(formData.ingredients)
                                    .preffered,
                                notPreffered: getPreferences(
                                    formData.ingredients,
                                ).notPreffered,
                            },
                            allergies: getPreferences(formData.allergies)
                                .notPreffered,
                            spiciness: {
                                min: formData.spiciness[0],
                                max: formData.spiciness[1],
                            },
                        },
                    },
                },
            },
            onCompleted: () => {
                toast.dark('Preferencje zostały zaktualizowane 👏');
                popup.remove();
            },
            onError: graphqlErrorHandler(true),
        });
    });

    useEffect(() => {
        if (data) {
            reset({
                diet: {
                    preffered: parseCategory(
                        preferences?.diet?.preffered || [],
                        DIET_NAME,
                    ),
                    notPreffered: [],
                },
                cuisine: {
                    preffered: parseCategory(
                        preferences?.cuisine?.preffered || [],
                        CUISINE_NAME,
                    ),
                    notPreffered: parseCategory(
                        preferences?.cuisine?.notPreffered || [],
                        CUISINE_NAME,
                    ),
                },
                dish: {
                    preffered: parseCategory(
                        preferences?.dish?.preffered || [],
                        DISH_NAME,
                    ),
                    notPreffered: parseCategory(
                        preferences?.dish?.notPreffered || [],
                        DISH_NAME,
                    ),
                },
                flavor: {
                    preffered: parseCategory(
                        preferences?.flavor?.preffered || [],
                        FLAVOUR_NAME,
                    ),
                    notPreffered: parseCategory(
                        preferences?.flavor?.notPreffered || [],
                        FLAVOUR_NAME,
                    ),
                },
                ingredients: {
                    preffered: preferences?.ingredients?.preffered || [],
                    notPreffered: preferences?.ingredients?.notPreffered || [],
                },
                allergies: {
                    preffered: [],
                    notPreffered: preferences?.ingredients?.notPreffered || [],
                },
                spiciness: [
                    preferences?.spiciness?.min ?? 0,
                    preferences?.spiciness?.max ?? 5,
                ],
            });
        }
    }, [data]);

    return {
        allergies,
        control,
        handleFormSubmit,
        igredientPopularList,
        igredientSuggestedList,
        ingredients,
        isDirty,
        loading,
        reset,
        updateLoading,
        popup,
    };
};
