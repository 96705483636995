export const ServingTime = () => {
    return (
        <svg
            width="22"
            height="17"
            viewBox="0 0 22 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Icon vector">
                <path
                    id="Subtract"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.28988 12.5H0V14.5H10.2547C9.83338 13.896 9.50489 13.2224 9.28988 12.5ZM9.07089 11.5H1C1.27 7.43 4.25 4.1 8.16 3.29C8.06 3.05 8 2.78 8 2.5C8 1.4 8.9 0.5 10 0.5C11.1 0.5 12 1.4 12 2.5C12 2.78 11.94 3.05 11.84 3.29C12.4855 3.42373 13.1057 3.62614 13.6925 3.88923C10.9602 4.84285 9 7.4425 9 10.5C9 10.8395 9.02417 11.1734 9.07089 11.5Z"
                    fill="#323232"
                />
                <path
                    id="Ellipse 11"
                    d="M21.25 10.5C21.25 13.3995 18.8995 15.75 16 15.75C13.1005 15.75 10.75 13.3995 10.75 10.5C10.75 7.60051 13.1005 5.25 16 5.25C18.8995 5.25 21.25 7.60051 21.25 10.5Z"
                    stroke="#343340"
                    strokeWidth="1.5"
                />
                <path
                    id="Vector"
                    d="M15.5872 7.7998L15.5872 11.5498M15.3145 11.0676L17.886 12.6128"
                    stroke="#343340"
                    strokeWidth="1.125"
                />
            </g>
        </svg>
    );
};
