import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { transparentize } from 'polished';

export type TabValueType = '1' | '2' | '3';
interface TabType {
    id: TabValueType;
    name: string;
    count: number;
}

export interface SearchTabsProps {
    value: TabValueType;
    onClick: (value: TabValueType) => void;
    tabs: TabType[];
    loading?: boolean;
}

export const SearchTabs = ({
    value,
    onClick,
    tabs,
    loading,
}: SearchTabsProps) => {
    return (
        <TabsWrapper>
            <TabsContainer>
                {tabs.map((tab) => {
                    return (
                        <Tab
                            key={tab.id}
                            onClick={() => onClick(tab.id)}
                            active={tab.id === value}
                        >
                            <TextWrapper>
                                {tab.name}
                                <SearchCount>
                                    {loading && !tab.count ? (
                                        <Spinner
                                            spinnerVariant="absolute"
                                            size={12}
                                        />
                                    ) : tab.count > 100 ? (
                                        '100+'
                                    ) : (
                                        tab.count
                                    )}
                                </SearchCount>
                            </TextWrapper>
                        </Tab>
                    );
                })}
                <AnimatedBackground
                    activeTabIndex={tabs.findIndex((item) => item.id === value)}
                    totalTabs={tabs.length}
                />
            </TabsContainer>
        </TabsWrapper>
    );
};

const TabsWrapper = styled.div`
    background: linear-gradient(${colors.gray100}, ${colors.gray200});
    border-radius: 50px;
    padding: 5px;
`;

const TabsContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

const TextWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
`;

const SearchCount = styled.span`
    align-items: center;
    border-radius: 8px;
    border: 1px solid ${colors.text};
    display: flex;
    font-size: 10px;
    justify-content: center;
    line-height: 15px;
    min-height: 23px;
    min-width: 23px;
    padding: 2px 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
`;

const Tab = styled.button<{ active: boolean }>(
    ({ active }) => css`
        background-color: transparent;
        border-radius: 50px;
        border: none;
        color: ${colors.text};
        cursor: pointer;
        flex: 1;
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        padding: 8px;
        position: relative;
        z-index: 1;

        &:hover {
            background: ${transparentize(0.4, colors.white)};
        }

        ${active &&
        css`
            box-shadow: 0 4px 20px 0 ${transparentize(0.92, colors.black)};
            color: ${colors.text};
            cursor: default;
            pointer-events: none;

            ${TextWrapper} {
                opacity: 1;
            }

            ${SearchCount} {
                border: 1px solid ${colors.text};
            }
        `}
    `,
);

const AnimatedBackground = styled.span<{
    activeTabIndex: number;
    totalTabs: number;
}>(
    ({ activeTabIndex, totalTabs }) => css`
        position: absolute;
        display: flex;
        top: 50%;
        height: 40px;
        margin-left: 8px;
        width: calc((100% / ${totalTabs}));
        background-color: ${colors.white};
        z-index: 0;
        border-radius: 50px;
        transition: 0.25s ease-out;
        transform: translateY(-50%);
        left: calc((100% / ${totalTabs}) * ${activeTabIndex} - 8px);
    `,
);
