import { useState, ChangeEvent } from 'react';
import { useForm, useController } from 'react-hook-form';
import { Ingredient } from '@graphql/generated';
import { useCallback, useMemo } from 'react';
import { ListAlphabeticallyProps } from '@/componentsV2/molecules/ListAlphabetically/ListAlphabetically';
import { uniq } from 'lodash';

export const useListAlphabetically = ({
    ingredients,
    onChange,
    initialLength,
    variant,
    value,
    loading,
}: Omit<ListAlphabeticallyProps, 'type' | 'emptyResultText'>) => {
    const [visibleLetters, setVisibleLetters] = useState<string[]>([]);
    const [isExpanded, setIsExpanded] = useState(false);

    const { control } = useForm({
        defaultValues: {
            query: '',
        },
    });

    const { field: queryField } = useController({
        control,
        name: 'query',
    });

    const sortedIngredients = ingredients
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));

    const alphabet = uniq(
        sortedIngredients.map((item) => item.name.charAt(0).toUpperCase()),
    );

    const filteredIngredients = useMemo(() => {
        if (queryField.value) {
            return sortedIngredients.filter((item) =>
                item.name
                    .toLowerCase()
                    .includes(queryField.value.toLowerCase()),
            );
        } else if (isExpanded || variant !== 'wrapped')
            return sortedIngredients;
        else return sortedIngredients.slice(0, initialLength);
    }, [queryField.value, isExpanded, loading]);

    const groupedIngredients = useMemo(
        () =>
            filteredIngredients.slice().reduce(
                (acc, ingredient) => {
                    const firstLetter = ingredient.name.charAt(0).toUpperCase();
                    acc[firstLetter] = acc[firstLetter] || [];
                    acc[firstLetter].push(ingredient);

                    return acc;
                },
                {} as {
                    [letter: string]: ListAlphabeticallyProps['ingredients'];
                },
            ),
        [isExpanded, filteredIngredients],
    );

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const id = event.currentTarget.value;

            const xd = value.includes(id)
                ? value.filter((item) => item !== id)
                : [...value, id];

            onChange(xd);
        },
        [value],
    );

    const scrollToLetter = (letter: string) => {
        document.getElementById(letter)?.scrollIntoView();
    };

    const activeLetter = visibleLetters[0];

    return {
        queryField,
        groupedIngredients,
        scrollToLetter,
        handleChange,
        setVisibleLetters,
        activeLetter,
        expandAllItems: () => {
            setIsExpanded(true);
        },
        showButtonAll:
            variant === 'wrapped' && !isExpanded && !queryField.value,
        count: ingredients.length - initialLength,
        alphabet,
    };
};
