/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Link } from 'react-router-dom';

import { Grid, ButtonBase } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { s, colors } from '@style';
import { Collapsable, Bullet, Button } from '@components/Elements';
import FastfoodIcon from '@material-ui/icons/Fastfood';

import X from '@material-ui/icons/Clear';
import { ProductAvatar } from '@/svgs/ProductAvatar';
import { ShoppingBag } from '@/svgs/ShoppingBag';

export default ({
    id,
    cartsView,
    orderId,
    restaurantName,
    restaurantSlug,
    restaurantLogoSrc,
    orderedProducts,
    orderPriceSum,
    onDelete,
    onlyOne,
    orderType,
}) => {
    let productsAmount = 0;
    for (const product of orderedProducts) {
        productsAmount += product.quantity;
    }
    const packagingPrice =
        orderType === 'IN'
            ? 0
            : orderedProducts.reduce(
                  (acc, item) =>
                      acc + (item.product.packagingPrice ?? 0) * item.quantity,
                  0,
              );

    const price = orderedProducts.reduce(
        (acc, item) => acc + item.product.price * item.quantity,
        0,
    );

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                gray200
                openAlready={onlyOne}
                noPadding
                oneLiner
                title={restaurantName}
                description={
                    <div css={{ fontWeight: 400 }}>
                        <span css={{ color: colors.text }}>
                            {orderPriceSum.toFixed(2)}
                        </span>{' '}
                        zł
                        <Bullet />
                        <>{productsAmount}</>
                        <FastfoodIcon
                            fontSize="inherit"
                            css={{
                                marginLeft: '0.25rem',
                                [s.md]: { marginLeft: '0.5rem' },
                            }}
                        />
                    </div>
                }
                image={restaurantLogoSrc}
                imageLink={`/restaurant/${restaurantSlug}`}
                sOuterCss={[
                    id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
                    id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
                    {
                        marginBottom: '1.5rem',
                        [s.sm_down]: { marginBottom: '1rem' },
                    },
                ]}
            >
                <div css={sItems}>
                    {orderedProducts.map(
                        ({ quantity, product }, orderProductId) => (
                            <ProductItem
                                key={orderProductId}
                                id={product.id}
                                name={product.name}
                                photoUrl={product.photoUrl}
                                price={product.price}
                                amount={quantity}
                                cartsView={cartsView}
                            />
                        ),
                    )}
                    {orderType !== 'IN' && (
                        <ProductItem
                            id="opakowanie"
                            name="Opakowanie"
                            price={price}
                            amount={productsAmount}
                            packagingPrice={packagingPrice}
                            packageItem
                        />
                    )}
                </div>
                <div
                    css={{
                        display: 'flex',
                        padding: '1rem',
                        [s.md]: {
                            padding: '2rem',
                        },
                    }}
                >
                    <Button size48 dimmed action={onDelete}>
                        <DeleteIcon />
                    </Button>
                    <div
                        css={{
                            width: '100%',
                            paddingLeft: '1rem',
                            [s.md]: { paddingLeft: '2rem' },
                        }}
                    >
                        <Button w100 link={`/cart-checkout/${orderId}`}>
                            Dalej
                        </Button>
                    </div>
                </div>
            </Collapsable>
        </Grid>
    );
};

export const ProductItem = ({
    id,
    photoUrl,
    name,
    price,
    amount,
    cartsView,
    packageItem,
    packagingPrice,
}) => {
    return (
        <Link to={`/product/${id}`}>
            <ButtonBase css={sItem}>
                <div css={{ display: 'flex', alignItems: 'center' }}>
                    <div css={[sImage]}>
                        {packageItem ? (
                            <ShoppingBag />
                        ) : photoUrl ? (
                            <img src={photoUrl} alt={name} loading="lazy" />
                        ) : (
                            <ProductAvatar />
                        )}
                    </div>
                    {packageItem ? (
                        <div css={{ display: 'flex', flexDirection: 'column' }}>
                            <p className="cart-item-title">{name}</p>
                        </div>
                    ) : (
                        <div css={{ display: 'flex', flexDirection: 'column' }}>
                            <p className="cart-item-title">{name}</p>
                            <p className="cart-item-desc">
                                {amount > 1 && amount}
                                {amount > 1 && (
                                    <X
                                        fontSize="inherit"
                                        css={{
                                            margin: '0 0.25rem',
                                            opacity: 1,
                                        }}
                                    />
                                )}
                                {price?.toFixed(2)}
                                <span css={{ opacity: 1 }}>&nbsp;zł</span>

                                {cartsView ? null : packagingPrice ===
                                  null ? null : (
                                    <>
                                        <Bullet />
                                        <p>
                                            Opakowanie{' '}
                                            {packagingPrice?.toFixed(2)} zł
                                        </p>
                                    </>
                                )}
                            </p>
                        </div>
                    )}
                </div>
                {packageItem ? (
                    <div className="cart-item-price">
                        <>{packagingPrice.toFixed(2)}</>
                        &nbsp;
                        <span css={{ opacity: 0.3 }}>zł</span>
                    </div>
                ) : (
                    <div className="cart-item-price">
                        <>
                            {(amount * price + (packagingPrice ?? 0)).toFixed(
                                2,
                            )}
                        </>
                        &nbsp;
                        <span css={{ opacity: 0.3 }}>zł</span>
                    </div>
                )}
            </ButtonBase>
        </Link>
    );
};

const sItems = {
    [s.sm_down]: { padding: 0 },
};

const sItem = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    transition: 'background-color 0.3s ease-out',
    [s.md]: { padding: '1.25rem 2rem' },

    '&:hover': {
        [s.hover]: {
            backgroundColor: colors.gray300,
        },
    },

    '.cart-item-title': {
        fontWeight: 700,
        fontSize: '11px',
        letterSpacing: '-0.025em',
        marginBottom: '0.25rem',
        marginRight: '1rem',
        [s.md]: {
            fontSize: '13px',
        },
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textAlign: 'left',
    },
    '.cart-item-desc': {
        opacity: 0.4,
        color: colors.text,
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 400,
        [s.md]: {
            opacity: 0.6,
        },
    },
    '.cart-item-price': {
        fontWeight: 700,
        fontSize: '11px',
        whiteSpace: 'nowrap',
    },
};

const sImage = {
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    flexShrink: 0,
    marginRight: '0.75rem',
    [s.md]: {
        width: 48,
        height: 48,
        marginRight: '1rem',
    },
    '&:hover': {
        img: {
            transform: 'scale(0.9)',
            filter: 'brightness(1.1)',
        },
    },

    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
        borderRadius: '50%',
        transition: 'transform 0.3s ease-out, filter 0.3s ease-out',
    },
};
