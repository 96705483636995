/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { colors, s } from '../../../style';

const Row = ({ desc, children, focus, icon, extraCss, extaCssDesc }) => (
    <div
        css={[
            sRow,
            focus && {
                borderTop: `1px solid ${colors.gray400}`,
                borderBottom: `1px solid ${colors.gray400}`,
                backgroundColor: colors.gray300,
                '&:hover': { [s.hover]: { backgroundColor: colors.gray400 } },
            },
            extraCss,
        ]}
    >
        <div css={{ display: 'flex', alignItems: 'center' }}>
            {icon && (
                <span
                    css={{
                        marginRight: '1.5rem',
                        fontSize: '1.25rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        [s.md]: { paddingLeft: '0.75rem' },
                        [s.sss]: { marginRight: '0.5rem' },
                    }}
                >
                    {icon}
                </span>
            )}
            <span
                className="row-desc"
                css={[
                    icon && {
                        color: colors.gray600,
                        fontWeight: 500,
                        fontSize: '0.875rem',
                    },
                    focus && { color: colors.text, fontWeight: 700 },
                ]}
            >
                {desc}
            </span>
        </div>
        <div className="row-children" css={{ textAlign: 'right' }}>
            {children}
        </div>
    </div>
);

const sRow = {
    borderTop: `1px solid ${colors.gray300}`,
    '&:first-of-type': { borderTop: 'none' },
    transition: 'opacity 0.3s ease-out, background-color 0.3s ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1rem 1.5rem',
    fontWeight: 800,
    [s.sm]: {
        padding: '1.5rem 2rem',
    },
    color: colors.text,
    fontSize: '0.875rem',
    opacity: 1,
    '&:hover': {
        [s.hover]: {
            backgroundColor: colors.gray300,
        },
    },
};

export default Row;
