import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

export const useQrCodeScanner = (callback?: (content: string) => void) => {
    const { setIsScannerActive } = useWaiterService();

    const startScanning = async () => {
        await BarcodeScanner.checkPermission({ force: true });
        setIsScannerActive(true);
        BarcodeScanner.hideBackground();
        document.body.style.backgroundColor = 'transparent';

        const result = await BarcodeScanner.startScan();
        if (result.hasContent) {
            callback?.(result.content);
            stopScanning();
        }
    };

    const stopScanning = () => {
        setIsScannerActive(false);
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    return {
        startScanning,
        stopScanning,
    };
};
