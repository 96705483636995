import React, { useEffect, useState } from 'react';
import Headline from '@components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { sGrid, sOuter } from '@style/style';
import { StarsRating } from '@/componentsV2/atoms/StarsRating/StarsRating';
import styled from '@emotion/styled';
import { colors } from '@/style';

import { mediaQueriesMaterial } from '@style/breakpoints';
import {
    OPERATIONS,
    useProductRateMutation,
    useProductRatingPageQuery,
} from '@graphql/generated';
import PageLoader from '@components/PageLoader/PageLoader';
import { useHistory, useParams } from 'react-router-dom';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { productHelpers } from '@helpers/product.helpers';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { BOTTOM_NAVIGATION } from '@constants/layout.constants';

const ProductRatingPage = () => {
    const productId = useParams<{ id: string }>().id;
    const history = useHistory();
    const [stars, setStars] = useState(0);

    const { data, loading, error } = useProductRatingPageQuery({
        variables: {
            input: {
                productId,
            },
        },
        onError: graphqlErrorHandler({
            omitErrorCodes: ['VALIDATION_FAILED'],
        }),
    });

    const [productRate, { loading: productRateLoading }] =
        useProductRateMutation();

    const handleProductRate = () => {
        productRate({
            variables: {
                input: {
                    productId: productId,
                    rating: stars,
                },
            },
            refetchQueries: [OPERATIONS.Query.ProductRatingPage],
            onCompleted: () => {
                history.push(`/product/${productId}`);
                toast.success('Twoja ocena została zapisana');
            },
            onError: graphqlErrorHandler(true),
        });
    };

    useEffect(() => {
        if (!loading && !data?.product.wasOrdered) {
            history.push(`/product/${productId}`);
        }

        if (data?.product.myRate) {
            setStars(data?.product.myRate);
        }

        if (error) {
            history.push(`/404`);
        }
    }, [data, error, loading]);

    if (loading) return <PageLoader />;

    return (
        <>
            {/* @ts-expect-error migrate to TS */}
            <TopNav title={'Ocena produktu'} />
            <ProductRatingContent
                /* @ts-expect-error migrate to TS */
                css={sOuter}
            >
                {/* @ts-expect-error migrate to TS */}
                <Headline>Ocena</Headline>
                <Grid container css={[sGrid]} spacing={2}>
                    <Grid item xs={12}>
                        <Description>
                            Podziel się swoją opinią na temat zamówionego dania
                        </Description>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ProductTileWrapper>
                            <ProductTile
                                average={data?.product.rating ?? 0}
                                categories={productHelpers.getCategories(
                                    data?.product,
                                )}
                                imageUrl={data?.product.photoUrl ?? ''}
                                name={data?.product.name ?? ''}
                                productPrice={data?.product.price ?? 0}
                                ratings={data?.product.ratingsCount ?? 0}
                            />
                        </ProductTileWrapper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StarsRatingTitle>Oceń</StarsRatingTitle>
                        <StarsRatingWrapper>
                            <StarsRating
                                value={stars ?? 0}
                                onChange={(_, value) => setStars(value ?? 0)}
                                readOnly={!!data?.product.myRate}
                            />
                        </StarsRatingWrapper>
                    </Grid>
                    <ButtonWrapper>
                        <Button
                            disabled={!stars}
                            loading={productRateLoading}
                            onClick={handleProductRate}
                            variant={'black'}
                            width={'100%'}
                        >
                            Zapisz ocenę
                        </Button>
                    </ButtonWrapper>
                </Grid>
            </ProductRatingContent>
        </>
    );
};

export default ProductRatingPage;

const ProductRatingContent = styled.div`
    padding-bottom: ${BOTTOM_NAVIGATION}px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
`;

const ProductTileWrapper = styled.div`
    margin-bottom: 40px;
`;

const StarsRatingTitle = styled.p`
    color: ${colors.text};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
`;

const StarsRatingWrapper = styled.div`
    display: flex;

    ${mediaQueriesMaterial.sm} {
        justify-content: center;
    }
`;

const ButtonWrapper = styled.div`
    background: ${colors.white};
    bottom: ${BOTTOM_NAVIGATION}px;
    left: 0;
    padding: 14px 24px;
    position: fixed;
    width: 100%;

    @media (min-width: 1480px) {
        left: 50%;
        transform: translateX(-50%);
        width: 1480px;
    }
`;
