/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { sBottomLinks, sOuter, sGrid } from '../../../style/style';
import { ButtonBorder } from '../../../components/Elements';
import { s } from '../../../style';
import { Link } from 'react-router-dom';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { EuropeanFundsInfo } from '@/componentsV2/atoms/EuropeanFundsInfo/EuropeanFundsInfo';

const TermsRestaurateur = () => {
    return (
        <>
            <TopNav title={'Regulamin Platformy Eatzon'} />
            <div css={sOuter}>
                <Headline>Regulamin</Headline>
                <Grid container css={sGrid}>
                    <Doc />
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/privacy'}>
                            Polityka Prywatności
                        </ButtonBorder>
                    </Grid>
                    <EuropeanFundsInfo />
                </Grid>
            </div>
        </>
    );
};

export const sDoc = {
    fontSize: 14,
    // 'h2, h3, p, ol, li': {
    //   fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    //   'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    //   sans-serif`,
    // },
    'li::marker': {
        padding: '1rem',
    },
    'p, ol': {
        fontWeight: 400,
    },
    li: {
        marginLeft: '4rem',
        [s.sm_down]: { marginLeft: '2rem' },
        marginBottom: '0.5rem',
        listStyleType: 'decimal',
        ol: {
            marginTop: '0.5rem',
        },
        li: {
            listStyleType: 'lower-latin',
            li: {
                listStyleType: 'lower-roman',
            },
        },
    },
    h2: {
        marginTop: '4rem',
        marginBottom: '1rem',
        [s.sm_down]: { marginTop: '2rem' },
    },
    h3: {
        marginLeft: '3rem',
        [s.sm_down]: { marginLeft: '1rem' },
        marginTop: '2rem',
        marginBottom: '1rem',
    },
    p: {
        marginBottom: '1rem',
    },
};

const Doc = () => {
    return (
        <div css={sDoc}>
            <h2>REGULAMIN UŻYTKOWANIA PLATFORMY EATZON - RESTAURATOR</h2>
            <p>
                Niniejszy Regulamin świadczenia usług drogą elektroniczną (dalej
                jako: „<strong>Regulamin</strong>”) określa warunki świadczenia
                usług za pośrednictwem serwisu internetowego Eatzon(dalej jako:
                „<strong>Platforma</strong>”).
            </p>
            <h2>I. DEFINICJE</h2>
            <p>
                Na potrzeby Regulaminu, przyjmuje się następujące znaczenie
                poniższych pojęć
            </p>
            <ol>
                <li>
                    Usługodawca - Eatzon Sp. z o.o. z siedzibą w Elblągu 82-300
                    ul. Stanisława Sulimy 1/226, wpisana do rejestru
                    przedsiębiorców Krajowego Rejestru Sądowego prowadzonego
                    przez Sąd Rejonowy Gdańsk-Północ w Gdańsku, VII Wydział
                    Gospodarczy Krajowego Rejestru Sądowego pod numerem:
                    0000857377, NIP:9462699743, REGON: 386876569, adres poczty
                    elektronicznej: office@eatzon.pl która stworzyła, rozwija i
                    zarządza Platformą Technologiczną, oraz dodatkowo w
                    stosownych przypadkach i na żądanie Użytkowników i klientów
                    korzystających z usług gastronomicznych i cateringowych
                    Restauratora pełni rolę pośrednika w dostawie dań i napojów.
                </li>
                <li>
                    Platforma Technologiczna - Aplikacja webowa i mobilna
                    Eatzon, strona www, i inne narzędzia i urządzenia
                    internetowe dostępne na stronie Usługodawcy Eatzon.pl
                    umożliwiająca zawieranie Umów drogą elektroniczną pomiędzy
                    Restauratorem a Użytkownikiem, zarządzanie zawartymi
                    Umowami, założenie i korzystanie z funkcjonalności Konta
                    Użytkownika.
                </li>
                <li>
                    Restaurator - Podmiot prowadzący działalność gospodarczą w
                    zakresie usług gastronomicznych i cateringowych oraz
                    handlowych związanych bezpośrednio z żywnością, publikujący
                    swoją Ofertę na Platformie w celu umożliwienia Użytkownikom
                    złożenia Zamówienia w celu zawarcia umowy
                </li>
                <li>
                    Użytkownik – konsument w rozumieniu art. 22 38 ustawy z dnia
                    26 kwietnia 1964 r. Kodeks cywilny (tj. Dz.U. z 2020 r. poz.
                    1740 ze zm.) lub inny podmiot, który korzysta z Platformy, a
                    także przy użyciu Platformy złożył Zamówienie w celu
                    zawarcia umowy z Restauratorem
                </li>
                <li>
                    Konto Użytkownika - indywidualny dla każdego Użytkownika
                    panel, uruchomiony na jego rzecz i żądanie z chwilą
                    dokonania Rejestracji na Platformie; W Koncie Użytkownika
                    gromadzone są informacje na jego temat oraz informacje o
                    jego działaniach w ramach Platformy;
                </li>
                <li>
                    Konto Restauratora - indywidualny dla każdego Restauratora
                    panel, uruchomiony na jego rzecz i żądanie z chwilą
                    dokonania Rejestracji na Platformie; W Koncie Restauratora
                    gromadzone są informacje na temat jego Restauracji,
                    zamówieniach złożonych przez Użytkowników oraz informacje o
                    jego działaniach w ramach Platformy;
                </li>
                <li>
                    Portfel - funkcjonalność Konta Użytkownika umożliwiająca
                    dokonywanie płatności na Platformie.{' '}
                </li>
                <li>
                    Rejestracja – czynność faktyczna dokonana przez Użytkownika
                    lub Restauratora w sposób określony w Regulaminie w celu
                    założenia Konta Użytkownika lub Restauratora.
                </li>
                <li>
                    Obsługa płatności – pobieranie w imieniu Restauracji środków
                    od Użytkowników w celu płatności na jej rzecz zgodnie z
                    Umową, przekazywanie pobranych i niepotrąconych środków na
                    rachunek Restauracji, oraz zabezpieczenie takich środków po
                    ich pobraniu w imieniu Restauracji do momentu ich
                    przekazania na rachunek bankowy Restauracji.
                </li>
                <li>
                    Oferta - Wybór Produktów i usług, w tym opcji Odbioru,
                    prezentowanych przez poszczególnych Restauratorów, które
                    Użytkownik może zamówić korzystając z Platformy
                </li>
                <li>
                    Produkt - posiłek w postaci produktu, dania lub napoju
                    znajdujący się w Ofercie Restauracji.{' '}
                </li>
                <li>
                    Odbiór - możliwy do wyboru przez Użytkownika sposób
                    dostarczenia przedmiotu Umowy określony w Ofercie
                    Restauratora.{' '}
                </li>
                <li>
                    Zamówienie – oświadczenie woli Użytkownika złożone za
                    pośrednictwem Platformy i wyrażające bezpośrednią wolę
                    zawarcia Umowy pomiędzy Użytkownikiem a Restauratorem na
                    wybraną przez niego Ofertę Restauratora
                </li>
                <li>
                    Umowa – oznacza stosunek prawny zawarty pomiędzy
                    Użytkownikiem a Restauratorem na podstawie złożonego przez
                    Użytkownika Zamówienia.
                </li>
                <li>
                    Koszyk – usługa polegająca na wyświetlaniu listy wybranych
                    przez Użytkownika składowych Oferty oraz szczegółów na temat
                    warunków zakupu takich jak cena jednostkowa i łączna
                </li>
                <li>
                    Konsument – Użytkownik będący osobą fizyczną, zawierający z
                    Restauratorem umowę niezwiązaną bezpośrednio z jego
                    działalnością gospodarczą lub zawodową; postanowienia
                    dotyczące Konsumenta mają zastosowanie odpowiednio także do
                    osoby fizycznej zawierającej z Restauratorem umowę
                    bezpośrednio związaną z jej działalnością gospodarczą, gdy z
                    treści tej umowy wynika, że nie posiada ona dla tej osoby
                    charakteru zawodowego, wynikającego w szczególności z
                    przedmiotu wykonywanej przez nią działalności gospodarczej,
                    udostępnionego na podstawie przepisów o Centralnej Ewidencji
                    i Informacji o Działalności Gospodarczej.
                </li>
                <li>
                    Systemy Płatności Online: bramka płatności PayNow Płatności
                    elektroniczne, w tym BLIK i płatności kartą płatniczą (Visa,
                    Visa Electron, Mastercard, MasterCard Electronic, Maestro)
                    za pośrednictwem serwisu PayNow.
                </li>
            </ol>
            <h2>II. POSTANOWIENIA OGÓLNE</h2>
            <ol>
                <li>
                    Niniejszy Regulamin określa zasady dostępu i korzystania z
                    Platformy. Warunkiem korzystania z Platformy jest uprzednie
                    zapoznanie się z postanowieniami Regulaminu i ich
                    akceptacja.
                </li>
                <li>
                    Niniejszy Regulamin ma zastosowanie wyłącznie do Usług
                    świadczonych przez Usługodawcę w zakresie pośrednictwa w
                    zawarciu Umowy pomiędzy Użytkownikiem a Restauratorem z
                    wykorzystaniem Platformy. Usługodawca informuje, iż
                    korzystanie z Platformy przez Użytkownika i Restauratora
                    jest usługą świadczoną drogą elektroniczną, w rozumieniu
                    ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
                    elektroniczną (Dz.U. z 2002 r., Nr 144, poz. 1204, z późn.
                    zm.) [dalej: u.ś.u.e], które to odbywa się podstawie
                    niniejszego Regulaminu. Regulamin określa w szczególności
                    zasady świadczenia Usług oferowanych na Platformie, ich
                    zakres oraz zasady korzystania z nich.
                </li>
                <li>
                    Usługodawca nigdy nie jest stroną Umowy, a jedynie
                    pośredniczy w jej zawarciu. Restauracja jest w pełni
                    odpowiedzialna za realizację Umowy. Usługodawca nie
                    odpowiada za niewykonywanie lub nienależyte wykonanie Umowy,
                    gdyż nie jest jej stroną.
                </li>
                <li>
                    Usługodawca udostępnia Platformę za pośrednictwem której
                    Użytkownik ma możliwość zamówienia produktów wchodzących w
                    skład Oferty Restauracji.
                </li>
                <li>
                    Minimalne warunki umożliwiające korzystanie z Platformy to:
                    <ol>
                        <li>
                            Dostęp do urządzenia podłączonego do sieci Internet,{' '}
                        </li>
                        <li>Zainstalowany i aktualny system operacyjny, </li>
                        <li>
                            Przeglądarka internetowa umożliwiająca wyświetlanie
                            dokumentów HTML,
                        </li>
                        <li>
                            Włączona opcja akceptowania plików cookies w
                            przypadku wybranej usługi,
                        </li>
                        <li>Możliwość instalowania aplikacji zewnętrznych,</li>
                        <li>
                            Posiadanie aktywnego konta poczty elektronicznej
                            (adres e-mail).
                        </li>
                    </ol>
                </li>
                <li>
                    Usługodawca dołoży wszelkich starań, aby Platforma była
                    dostępna w sposób ciągły bez zakłóceń.{' '}
                </li>
                <li>
                    Usługodawca zastrzega sobie prawo do:
                    <ol>
                        <li>
                            okresowego wyłączania dostępności Platformy
                            Technologicznej w celu jej rozbudowy, konserwacji
                            lub przeprowadzenia działań naprawczych,{' '}
                        </li>
                        <li>
                            sporadycznych i krótkich przerw w dostępie do
                            Platformy Technologicznej bez podania przyczyny.{' '}
                        </li>
                    </ol>
                </li>
                <li>
                    Dla prawidłowego działania Platforma wykorzystuje dane i
                    funkcjonalności urządzenia mobilnego, na którym jest
                    zainstalowana. Przy instalacji Platformy lub przy jej
                    pierwszym użyciu Użytkownik zostanie poproszony o wyrażenie
                    zgody na niezbędny do prawidłowego działania Platformy
                    dostęp do:
                    <ol>
                        <li>dane o lokalizacji</li>
                    </ol>
                </li>
                <li>
                    Fakultatywnie, w celu korzystania z dodatkowych
                    funkcjonalności Platformy, Użytkownik może wyrazić także
                    zgodę na dostęp Platformy do nieobligatoryjnych danych
                    takich jak:{' '}
                    <ol>
                        <li>dostęp do galerii,</li>
                        <li>dostęp do aparatu,</li>
                        <li>dostęp do mikrofonu,</li>
                        <li>dostęp do kontaktów,</li>
                        <li>dostęp do preferencji przeglądarki użytkownika</li>
                    </ol>
                </li>
                <li>
                    Restaurator jest obowiązany do korzystania z Platformy w
                    sposób zgodny z prawem i dobrymi obyczajami, mając na uwadze
                    poszanowanie dóbr osobistych oraz praw autorskich i praw
                    własności intelektualnej Usługodawcy i osób trzecich.
                </li>
                <li>
                    Usługodawca oświadcza, że korzystanie z usług świadczonych
                    drogą elektroniczną może się wiązać z zagrożeniem utraty
                    poufności, dostępności i integralności danych Użytkownika i
                    Restauratora w związku z nieuprawnionym działaniem osób
                    trzecich. Z tego względu Użytkownicy i Restauratorzy powinni
                    stosować właściwe środki techniczne i organizacyjne mające
                    na celu minimalizację zagrożeń, w szczególności programy
                    antywirusowe oraz zasadę nieujawniania osobom postronnym
                    hasła do Konta Użytkownika/Restauratora.
                </li>
            </ol>
            <h2>III. ZAKRES ŚWIADCZONYCH USŁUG</h2>
            <ol>
                <li>
                    Usługodawca pełni rolę pośrednika w zawarciu Umowy pomiędzy
                    Użytkownikiem a Restauratorem z wykorzystaniem Platformy.{' '}
                </li>
                <li>
                    Usługodawca udostępnia Platformę, za pośrednictwem której
                    prezentuje w imieniu i na rzecz Restauratora jej Ofertę w
                    celu umożliwienia Użytkownikom jej przeglądania, złożenia
                    Zamówienia i zawarcia Umowy.{' '}
                </li>
                <li>
                    Usługodawca udostępnia Restauratorom usługę prowadzenia
                    Konta Restauratora na zasadach określonych w punkcie IV
                    Regulaminu.
                </li>
                <li>
                    Restaurator akceptując niniejszy regulamin zgadza się na
                    pobieranie przez Usługodawcę ustalonego wynagrodzenia
                    (prowizji), o którym mowa w pkt. VII ust. 7.
                </li>
                <li>
                    Usługodawca zobowiązuje się do:{' '}
                    <ol>
                        <li>
                            prezentowania na Platformie Oferty Restauratora,
                        </li>
                        <li>przekazywania Restauratorowi Zamówień </li>
                        <li>
                            przekazywanie Użytkownikowi potwierdzenia przyjęcia
                            Zamówienie przez Restauratora,
                        </li>
                        <li>
                            odbierania od Użytkownika środków uiszczonych z
                            tytułu zapłaty ceny na rzecz Restauratora.
                        </li>
                    </ol>
                </li>
                <li>
                    Usługodawca zobowiązuje się do opublikowania jedynie tych
                    danych Restauracji, które będą istotne w procesie składania
                    Zamówienia w Platformie ,oraz oświadcza, iż nie będzie
                    weryfikować ich prawdziwości. Wszelką odpowiedzialność za
                    szkody wyrządzone Użytkownikom lub Usługodawcy, a
                    spowodowane podaniem nieprawdziwych lub niekompletnych
                    danych ponosi Restauracja.
                </li>
            </ol>
            <h2>IV. KONTO RESTAURATORA</h2>
            <ol>
                <li>
                    Konto Restauratora jest niezbędne do prezentowania swojej
                    oferty Użytkownikom Platformy.
                </li>
                <li>
                    Na Koncie Restauratora znajdują się informacje o aktualnych
                    i zrealizowanych Zamówieniach, należnych płatnościach,
                    fakturach, Ofercie Restauracji oraz Planie Sali w celu
                    umożliwienia Użytkownikom rezerwacji stolika.
                </li>
                <li>
                    Rejestracja Restauracji jest możliwa w przypadku uprzedniego
                    stworzenia konta Użytkownika.{' '}
                </li>
                <li>
                    W celu Rejestracji Restauracji należy zgłosić chęć
                    utworzenia Konta Restauratora poprzez kontakt mailowy z
                    Usługodawcą. Restaurator ma możliwość posiadania kilku
                    restauracji w swoim profilu.
                </li>
                <li>
                    Po weryfikacji zgłoszenia przez Usługodawcę, Usługodawca
                    udostępni za pośrednictwem panelu Użytkownika dostęp do
                    oddzielnego panelu Restauratora.
                </li>
                <li>
                    Restaurator zobowiązany jest do należytej ochrony danych
                    uwierzytelniających przed nieuprawnionym dostępem, w
                    szczególności zobowiązany jest do zachowania ich w tajemnicy
                    i nieujawniania osobom trzecim.
                </li>
                <li>
                    Restaurator jest zobowiązany do tego, by dane dotyczące jego
                    tożsamości i prawnej zdolności do działania podane w
                    formularzu rejestracyjnym były prawdziwe, dokładne, aktualne
                    i kompletne. Restaurator ponosi odpowiedzialność za podanie
                    danych fałszywych bądź niekompletnych. W przypadku
                    stwierdzenia podania przez Restauratora fałszywych danych,
                    Usługodawcy przysługuje prawo odmowy Rejestracji lub - w
                    przypadku ustalenia, że dane są nieprawdziwe po dokonaniu
                    Rejestracji – prawo zablokowania Konta Restauratora. O
                    fakcie oraz przyczynach odmowy Rejestracji lub blokady konta
                    Restauratora zostanie poinformowany drogą elektroniczną na
                    adres podany w formularzu rejestracyjnym.
                </li>
                <li>
                    Z chwilą dokonania Rejestracji tworzony jest profil
                    publiczny Restauratora.
                </li>
                <li>
                    Za pośrednictwem Konta Restaurator może zarządzać własnymi
                    danymi wskazanymi podczas Rejestracji, w tym dokonywać ich
                    aktualizacji.
                </li>
                <li>
                    W ramach korzystania z Usług niedozwolone jest dostarczanie
                    przez Restauratora treści prawnie niedozwolonych, w tym w
                    szczególności:{' '}
                    <ol>
                        <li>
                            danych i informacji spreparowanych w sposób
                            stwarzający ryzyko naruszenia bezpieczeństwa systemu
                            informatycznego lub stabilności Platformy;
                        </li>
                        <li>
                            informacji naruszających jakiekolwiek dobra
                            Usługodawcy bądź osób trzecich;
                        </li>
                        <li>
                            innych danych i informacji naruszających
                            bezwzględnie obowiązujące przepisy prawa, w tym w
                            szczególności treści obraźliwych, pornograficznych,
                            nawołujących i odwołujących się do rasizmu,
                            nacjonalizmu, faszyzmu, przemocy, dyskryminacji
                            płciowej.
                        </li>
                    </ol>
                </li>
                <li>
                    Korzystając z Platformy Restauratorzy mają obowiązek
                    postępować zgodnie z powszechnie obowiązującymi przepisami
                    prawa oraz zasadami współżycia społecznego.
                </li>
                <li>
                    Zakazane jest wykorzystywanie przez Restauratora Usług w
                    sposób sprzeczny z powszechnie obowiązującymi przepisami
                    prawa, dobrymi obyczajami lub w sposób naruszający
                    uzasadnione interesy Usługodawcy bądź podmiotów trzecich. W
                    szczególności Restaurator nie będzie podejmował czynności,
                    które mogłyby narazić Usługodawcę na jakąkolwiek szkodę
                    majątkową.
                </li>
                <li>
                    Zabronione jest pobieranie przez Restauratora zawartości baz
                    danych udostępnionych na Platformie i wtórne ich
                    wykorzystywanie w całości lub w istotnej części co do
                    jakości lub ilości.
                </li>
                <li>
                    Usługodawca zastrzega, iż wszystkie wiadomości i materiały
                    dostępne na Platformie są objęte ochroną prawa autorskiego.
                    Restaurator ma prawo do korzystania z nich jedynie w ramach
                    dozwolonego użytku osobistego. Kopiowanie, zwielokrotnianie,
                    rozpowszechnianie w Internecie i inne formy korzystania z
                    materiałów i wiadomości umieszczonych na Platformie
                    wykraczające poza granice dozwolone prawem jest zabronione.
                </li>
            </ol>
            <h2>V. OPINIE UŻYTKOWNIKÓW</h2>
            <ol>
                <li>
                    Użytkownicy mają możliwość wyrażenia swojej opinii na temat
                    wykonania Umowy przez Restauratora w drodze oceny lub
                    wystawienia komentarza. Wyrażenie opinii jest dobrowolne.{' '}
                </li>
                <li>
                    Usługodawca może zamieszczać recenzje dotyczące Restauracji
                    na Platformie i usuwać je z Platformy. Usługodawca nie
                    koordynuje treści, zamieszczania lub usuwania recenzji
                    dotyczących Restauratora.
                </li>
                <li>
                    Usługodawca nie ponosi odpowiedzialności wobec Restauratora
                    za treść recenzji i jakiekolwiek ich negatywne skutki.
                </li>
                <li>
                    Szczegółowe wytyczne określające zasady dodawania opinii
                    przez Użytkowników znajdują się w{' '}
                    <Link to="/terms">
                        <strong>
                            REGULAMINIE UŻYTKOWANIA PLATFORMY EATZON -
                            UŻYTKOWNIK.
                        </strong>
                    </Link>{' '}
                </li>
            </ol>

            <h2>VI. CZAT</h2>
            <ol>
                <li>
                    Czat jest nieodpłatną usługą świadczoną w ramach Konta
                    Restauratora, która, umożliwia nawiązanie kontaktu w czasie
                    rzeczywistym z Użytkownikiem, który złożył Zamówienie za
                    pośrednictwem komunikatora zamieszczonego na Platformie.
                </li>
                <li>
                    Czat służy do komunikacji w sprawie złożonego Zamówienia.
                </li>
                <li>
                    Aby rozpocząć korzystanie z Czatu należy wysłać wiadomość za
                    pośrednictwem okna Czatu. Zaprzestanie korzystania z usługi
                    jest możliwe w każdym czasie poprzez zamknięcie okna
                    konwersacji.{' '}
                </li>
                <li>
                    Usługodawca nie odpowiada za dokładność i kompletność
                    Zamówień składanych przez Użytkowników. Restauracja może
                    dokonać weryfikacji Zamówienia złożonego przez Użytkownika,
                    kontaktując się z nim za pomocą czatu.
                </li>
            </ol>
            <h2>VII. ZAWARCIE UMOWY</h2>
            <ol>
                <li>
                    W chwili potwierdzenia przez Restauratora Zamówienia
                    dochodzi do zawarcia Umowy pomiędzy Restauratorem a
                    Użytkownikiem.
                </li>
                <li>
                    Z chwilą zawarcia Umowy następuje pobranie środków
                    finansowych Użytkownika na poczet zapłaty ceny za wykonanie
                    Umowy przez Restauratora.
                </li>
                <li>
                    Usługodawca prezentuje wszystkie dane Użytkownik najpóźniej
                    na 180 min przed wybraną godziną realizacji Umowy może
                    odstąpić od Umowy poprzez bezpłatne odwołanie Zamówienia.
                </li>
                <li>
                    W przypadku odstąpienia przez Użytkownika od Umowy w trybie
                    opisanym w ust. 3 Umowę uznaje się za niezawartą.
                </li>
                <li>
                    Zapłata ceny przez Użytkownika na rzecz Restauratora za
                    pośrednictwem Platformy następuje jedynie drogą
                    elektroniczną w dowolnej formie dostępnej w Platformie.
                </li>
                <li>
                    Zapłata ceny uiszczonej przez Użytkownika na rzecz
                    Restauratora pobranej przez Usługodawcę będzie rozliczana z
                    Restauratorem w każdy poniedziałek po zakończeniu 7 dniowego
                    okresu rozliczeniowego, w którym cena ta została uiszczona
                    na rachunek bankowy Restauratora z potrąceniem wynagrodzenia
                    prowizyjnego o którym mowa w ustępie 7. W przypadku gdy
                    poniedziałek będzie dniem wolnym od pracy datą zapłaty
                    będzie kolejny dzień pracujący następujący po nim.{' '}
                    <ol>
                        <li>
                            W przypadku bilansu dodatniego Restauracji,
                            Usługodawca przekazuje jej pozostałe, niepotrącone
                            środki uzyskane na jej rzecz od Użytkowników w
                            terminie 7 roboczych od otrzymania środków przez
                            Usługodawcę. Warunkiem przekazania środków jest
                            podanie przez Restaurację poprawnych danych rachunku
                            bankowego Restauracji.
                        </li>
                        <li>
                            W przypadku bilansu ujemnego Restauracji,
                            Usługodawca nie przekazuje Restauracji uzyskanych od
                            Użytkowników środków, a dokonuje stopniowego
                            potrącenia, aż do czasu wyrównania bilansu.
                        </li>
                    </ol>
                </li>
                <li>
                    Za usługę pośrednictwa Usługodawcy należne jest
                    wynagrodzenie prowizyjne (prowizja) w wysokości 10% netto od
                    kwoty brutto zamówienia złożonego przez Użytkownika, którą
                    Usługodawca potrąca z każdej należności z tytułu ceny za
                    wykonanie Umowy uiszczonej przez Użytkownika. Usługobiorca
                    na koniec każdego miesiąca przesyła Restauracji fakturę,
                    która stanowi zbiorcze potwierdzenie wysokości naliczonej
                    prowizji. Prowizja pobierana jest od wartości ceny za
                    wykonanie Umowy brutto, którą stanowi wartość zamówionych
                    posiłków wraz z kosztem ich dostawy do Klienta.
                </li>
                <li>
                    Umowa zostaje zawarta na okres 12 miesięcy i przedłuża się
                    za każdym razem o kolejne 12 miesięcy jeżeli nie zostanie
                    wypowiedziana przez żadną ze stron na trzy miesiące przed
                    upływem okresu na jaki została zawarta.
                </li>
            </ol>
            <h2>VIII. REALIZACJA UMOWY - OBSŁUGA ZAMÓWIEŃ</h2>
            <ol>
                <li>
                    Restaurator jest zobowiązany do umieszczenia w Ofercie
                    wszelkich wymaganych właściwymi przepisami prawa informacji
                    o składnikach i alergenach (substancjach lub produktach
                    powodujących alergię lub reakcję nietolerancji, o których
                    mowa w Rozporządzeniu Parlamentu Europejskiego i Rady (UE)
                    NR 1169/2011 z dnia 25 października 2011 r. w sprawie
                    przekazywania konsumentom informacji na temat żywności,
                    zmiany rozporządzeń Parlamentu Europejskiego i Rady (WE) nr
                    1924/2006 i (WE) nr 1925/2006 oraz uchylenia dyrektywy
                    Komisji 87/250/EWG, dyrektywy Rady 90/496/EWG, dyrektywy
                    Komisji 1999/10/WE, dyrektywy 2000/13/WE Parlamentu
                    Europejskiego i Rady, dyrektyw Komisji 2002/67/WE i
                    2008/5/WE oraz rozporządzenia Komisji (WE) nr 608/2004)
                    produktów wchodzących w skład jego Oferty{' '}
                </li>
                <li>
                    Restaurator ponosi wyłączną odpowiedzialność za informacje
                    umieszczone w Ofercie, w szczególności za ich zgodność ze
                    stanem faktycznym.
                </li>
                <li>
                    Oferta nie może być mniej korzystna niż oferta składana
                    przez Restauratora, w tym również w tradycyjny sposób w
                    Restauracji, bez pośrednictwa Usługodawcy.
                </li>
                <li>
                    Do zawarcia Umowy dochodzi w momencie potwierdzenia przez
                    Restauratora złożonego przez Użytkownika Zamówienia.
                    Restauracja odpowiada za przestrzeganie i wykonywanie Umów.
                </li>
                <li>
                    Platforma tak szybko, jak to możliwe przekazuje Zamówienie
                    do Restauratora, który zobowiązany jest je potwierdzić w
                    całości lub w części bądź odmówić przyjęcia zamówienia. W
                    przypadku odmowy przyjęcia Zamówienia Restaurator zawiadomi
                    Użytkownika o tym fakcie. W przypadku częściowego
                    potwierdzenie Zamówienia Restaurator poinformuje Użytkownika
                    o powodzie częściowej akceptacji Zamówienia oraz o
                    dostępności zamówionych produktów, pozostawiając mu decyzję
                    o sposobie dalszej realizacji Zamówienia.
                </li>
                <li>
                    Restauracja winna być dostępna w podanych uprzednio
                    godzinach jej otwarcia.
                </li>
                <li>
                    W momencie zamówienia przesłanego poza godzinami pracy
                    restauracji odpowiedź do Użytkownika z potwierdzeniem może
                    zostać przesłana kolejnego dnia w godzinach pracy
                    restauracji.
                </li>
                <li>
                    Status zamówienia pozostanie oczekujący do czasu
                    potwierdzenia przez restaurację dostępności.
                </li>
                <li>
                    Z chwilą potwierdzenia przez Restaurację Zamówienia,
                    uzyskuje ono status “Aktywne”, a Użytkownik może je
                    uzupełnić o dodatkowe Produkty, co każdorazowo wymaga
                    potwierdzenia Restauratora zgodnie z punktem 4 i 5 powyżej.
                </li>
                <li>
                    W momencie zawarcia Umowy, po stronie Użytkownika powstaje
                    obowiązek płatności za pośrednictwem Usługodawcy na rzecz
                    Restauracji ceny wynikającej z Zamówienia. Restauracja
                    upoważnia Usługodawcę do akceptacji w jej imieniu płatności
                    online dokonywanych przez Klientów.
                </li>
                <li>
                    Termin wykonania Umowy określa treść oferty Restauratora.
                    Miejscem spełniania świadczenia związanego z Zamówieniem
                    jest miejsce Odbioru wskazane przez Użytkownika w
                    Zamówieniu.
                </li>
                <li>
                    Restaurator ma obowiązek przestrzegania terminu realizacji
                    zamówienia wskazanego w Ofercie.
                </li>
                <li>
                    W przypadku rezerwacji stolika przez Użytkownika Restaurator
                    w ramach akceptacji Zamówienia zobowiązuję się do rezerwacji
                    wybranego stolika.{' '}
                </li>
                <li>
                    Jeżeli Restaurator dostarcza Użytkownikowi Zamówienie za
                    pośrednictwem osoby trzeciej, ponosi odpowiedzialność za
                    dostawę i jej koszt. Restauracja zwalnia Usługodawcę z
                    odpowiedzialności za roszczenia osób trzecich w związku z
                    dostawą i związanymi z nią kosztami.
                </li>
                <li>
                    Restaurator może odstąpić od Umowy zawartej z Użytkownikiem,
                    jeżeli:
                    <ol>
                        <li>
                            Użytkownik wprowadził niepoprawne dane adresowe w
                            procesie składania Zamówienia
                            (nieprawidłowy/nieosiągalny numer telefonu, błędny
                            adres dostawy);
                        </li>
                        <li>
                            Użytkownik nie zgodził się na alternatywę dla
                            Zamówienia proponowaną przez Restaurację w przypadku
                            dezaktualizacji Oferty Restauracji
                        </li>
                        <li>
                            w przypadku niemożności wykonania przez nią Umowy z
                            uwagi na działanie siły wyższej.
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>IX. ZOBOWIĄZANIA RESTAURACJI</h2>
            <ol>
                Restauracja:
                <li>
                    upoważnia Usługodawcę do stałego pośredniczenia przy
                    zawieraniu Umów oraz do ich zawierania w jej imieniu i na
                    jej rzecz;
                </li>
                <li>
                    udostępni wszelkie wymagane przez Usługodawcę w celu
                    poprawnego wykonywania Usług dane Restauracji;
                </li>
                <li>odpowiada za dokładność podanych danych Restauracji;</li>
                <li>
                    niezwłocznie przekaże Usługodawcy dodatkowe, konieczne dla
                    poprawnego wykonywania przez nią Usług informacje związane z
                    Restauracją lub produktami Restauracji na każde żądanie
                    Usługodawcy. Usługodawca ma prawo do zawieszenia świadczenia
                    Usług na rzecz Restauracji, aż do czasu otrzymania
                    wnioskowanych informacji;
                </li>
                <li>
                    zawiadomi Usługodawcę o zmianach danych Restauracji w
                    terminie czternastu (14) dni przed ich wejściem w życie w
                    celu:{' '}
                    <ol>
                        <li>
                            umożliwienia Usługodawcy zmiany danych Restauracji
                            wyświetlanych w Platformie; lub
                        </li>
                        <li>
                            umożliwienia samodzielnej zmiany danych Restauracji
                            przez Restauratora;
                        </li>
                    </ol>
                </li>
                <li>
                    udziela Usługodawcy pozwolenia na wykorzystywanie i
                    przetwarzanie danych Restauracji, na przykład do baz danych,
                    platform partnerskich lub wyszukiwarek, a także udziela
                    Usługodawcy licencji i prawa udzielania sublicencji osobom
                    trzecim w zakresie danych Restauracji;
                </li>
                <li>
                    niezwłocznie zawiadomi Usługodawcę, jeżeli Państwowa
                    Inspekcja Sanitarna lub jakikolwiek inny organ stwierdzi
                    naruszenie przez Restaurację przepisów powszechnie
                    obowiązującego prawa dotyczących oferowanych przez
                    Restaurację produktów żywieniowych, w szczególności ustawy z
                    dnia 25 sierpnia 2006 r. o bezpieczeństwie żywności i
                    żywienia (Dz.U. 2006 Nr 171 poz. 1225 z późn. zm.) lub
                    jakiejkolwiek innej ustawy lub rozporządzenia, w tym także
                    przepisów prawa wspólnotowego; i. gwarantuje, że Oferta jest
                    nie mniej korzystna niż oferta składana przez Restauratora,
                    w tym również w tradycyjny sposób w Restauracji, bez
                    pośrednictwa Usługodawcy. Jeżeli Restaurator złamie
                    postanowienie, różnicą pomiędzy ceną podaną w Platformie a
                    ceną pobraną poza Platformą:
                    <ol>
                        <li>Usługodawca obciąży Restaurację oraz</li>
                        <li>
                            zostanie ona zwrócona Użytkownikówi przez
                            Usługodawcę w formie wpłaty Eatcoinów do Portfela
                            Użytkownika. Restauracja dokona korekty cen w
                            Platformie stosownie do cen podanych na jej stronie
                            internetowej i w menu, z uwzględnieniem rabatów.
                            Usługodawca również może dokonać wskazanej korekty
                            cen stosownie do cen podanych na stronie
                            internetowej Restauracji i w menu, z uwzględnieniem
                            rabatów; oraz
                        </li>
                    </ol>
                </li>
                <li>
                    nie zamieści w Platformie jakichkolwiek odnośników do stron
                    internetowych nie związanych i nie będących własnością
                    Usługodawcy ani jakichkolwiek reklam.
                </li>
            </ol>
            <h2>X. GWARANCJE RESTAURATORA</h2>
            <ol>
                Restaurator:
                <li>
                    gwarantuje, że dania, napoje i inne produkty dostarczane
                    Użytkownikowi są zdatne do spożycia i spełniają wymogi
                    przepisów powszechnie obowiązującego prawa, w szczególności
                    ustawy z dnia 25 sierpnia 2006 r. o bezpieczeństwie żywności
                    i żywienia (Dz.U. 2006 Nr 171 poz. 1225 z późn. zm.) oraz
                    powiązanych z nią ustaw i rozporządzeń, a także prawa
                    wspólnotowego;
                </li>
                <li>
                    gwarantuje, że posiada wszystkie wymagane dla Restauracji
                    licencje, koncesje i zezwolenia;
                </li>
                <li>
                    oświadcza, że nie znajduje się w stanie
                    upadłości/likwidacji, oraz, że nie prowadzi postępowania
                    restrukturyzacyjnego. Oświadcza również, iż nie jest wobec
                    niej lub osób nią zarządzających prowadzone jakiekolwiek
                    postępowanie karne lub karno-skarbowe.
                </li>
                <li>
                    gwarantuje, że dane Restauracji mogą być swobodnie
                    wykorzystywane i przetwarzane przez Usługodawcę bez
                    naruszenia praw własności intelektualnej lub innych praw
                    osób trzecich oraz;
                </li>
                <li>
                    w razie wystąpienia przez jakąkolwiek osobę trzecią
                    przeciwko Usługodawcy z roszczeniem lub dochodzeniem
                    odpowiedzialności związanym z naruszeniem jej praw własności
                    intelektualnej lub innych praw Restauracja zobowiązuje się
                    zwolnić Usługodawcę od jakiegokolwiek obowiązku świadczenia
                    względem takiej osoby trzeciej oraz w pełni przejąć ciężar i
                    koszt obrony przed takimi roszczeniami osoby trzeciej;
                </li>
                <li>
                    gwarantuje, że Umowy są realizowane zgodnie z danymi
                    Restauracji wyświetlanymi na Platformie;
                </li>
                <li>
                    {' '}
                    przyjmuje na siebie odpowiedzialności za roszczenia
                    zgłaszane przez Użytkowników związane z postępowaniem
                    Restauracji lub sposobem realizacji przez nią Umów a w razie
                    wystąpienia przez Użytkownika przeciwko Usługodawcy z
                    roszczeniem lub dochodzeniem odpowiedzialności zobowiązuje
                    się zwolnić Usługodawcę od jakiegokolwiek obowiązku
                    świadczenia względem Użytkownika oraz w pełni przejąć ciężar
                    i koszt obrony przed takimi roszczeniami.
                </li>
            </ol>
            <h2>XI. OBSŁUGA PŁATNOŚCI</h2>
            <ol>
                <li>
                    Restauracja upoważnia do odbierania w jej imieniu wszelkich
                    płatności bezgotówkowych i płatności online od Użytkowników
                    z tytułu Zamówień realizowanych przez Restaurację na
                    podstawie zawieranych Umów. Usługodawca nie ponosi
                    odpowiedzialności za Użytkowników niewywiązujących się ze
                    swoich zobowiązań finansowych wobec Restauracji, w przypadku
                    płatności bezpośrednio do Restauracji oraz w przypadku
                    jakiejkolwiek formy płatności, która odbywa się bez
                    pośrednictwa Spółki.
                </li>
                <li>
                    Usługodawca oferuje Użytkownikom szereg metod płatności
                    należności z tytułu Umów na rzecz Restauracji. W okresie
                    obowiązywania niniejszego Regulaminu, uznaje się, że
                    Restauracja zgodziła się wszystkie metody płatności online
                    oferowane przez Usługodawcę.
                </li>
                <li>
                    Usługodawca ma możliwość dokonywania wzajemnych potrąceń
                    należności przysługujących jej oraz Restauracji, w tym w
                    szczególności z tytułu prowizji i płatności na rzecz
                    Restauracji otrzymywanych od Użytkowników, nawet w sytuacji,
                    gdy są one jeszcze niewymagalne na co Restauracja wyraża
                    zgodę. Realizacja uprawnień, o których mowa powyżej oraz
                    przekazanie środków uzyskanych przez Usługodawcę od
                    Użytkowników na rzecz Restauracji przebiega w zgodnie z pkt.
                    VII ust. 6.
                </li>
            </ol>
            <h2>XII. PRAWO ODSTĄPIENIA OD UMOWY</h2>
            <ol>
                <li>
                    Usługodawca jest uprawniony do odstąpienia od świadczenia
                    usług na rzecz Restauratora w przypadku, gdy:
                    <ol>
                        <li>
                            zostanie ogłoszona likwidacja lub złożony wniosek o
                            upadłość Restauratora lub Usługodawcy,
                        </li>
                        <li>
                            Restaurator zakończy lub zawiesi prowadzenie
                            działalności gospodarczej,
                        </li>
                        <li>
                            zaistnieje brak kontaktu z Restauratorem powyżej 14
                            dni,{' '}
                        </li>
                        <li>
                            Restaurator bez podania przyczyny zaprzestanie
                            wykonywania Umowy, w szczególności zaprzestanie
                            zawierać lub realizować Umowy przez 90 dni,
                        </li>
                        <li>
                            Restaurator będzie realizował przedmiot Umowy w
                            sposób nienależyty lub niezgodny z niniejszą umową,
                            w szczególności będzie opóźniał się z dowozem
                            posiłków.
                        </li>
                    </ol>
                </li>
                <li>
                    Usługodawca może skorzystać z prawa odstąpienia od Umowy w
                    terminie 60 dni od powzięcia informacji o zaistnieniu jednej
                    z okoliczności wymienionych w ustępie poprzedzającym. 
                </li>
                <li>
                    Restaurator może zrezygnować z prezentowania swojej oferty w
                    Platformie poprzez ukrycie jej widoczności.
                </li>
            </ol>
            <h2>XIII. LICENCJA</h2>
            <ol>
                <li>
                    Usługodawca udziela Restauratorowi niewyłącznej,
                    niezbywalnej i ograniczonej do terytorium Rzeczypospolitej
                    Polskiej licencji na korzystania z Platformy.
                </li>
                <li>
                    Licencja uprawnia Restauratora do nieodpłatnego pobrania
                    Platformy oraz do korzystania z niej zgodnie z
                    przeznaczeniem i zasadami Regulaminu.
                </li>
                <li>
                    Posiadanie Konta Restauratora w Platformie jest bezpłatne, z
                    wyłączeniem opłaty prowizyjnej za Zamówienia realizowane na
                    rzecz Użytkowników, o której mowa w pkt. VII ust. 7.
                </li>
                <li>
                    Usługodawca zastrzega sobie prawo do wprowadzania
                    dodatkowych, płatnych funkcjonalności w Platformie, przy
                    czym funkcjonalności te nie będą obligatoryjne oraz nie będą
                    ograniczały funkcjonalności podstawowej wersji aplikacji.
                </li>
                <li>
                    Restaurator przyjmuje do wiadomości, iż Usługodawcy
                    przysługują prawa własności intelektualnej, w tym prawa
                    autorskie i prawa pokrewne do Platformy i wszystkich jej
                    elementów mających charakter Utworu w rozumieniu Ustawy o
                    prawie autorskim.
                </li>
                <li>
                    Kopiowanie, rozpowszechnianie, dokonywanie opracowań
                    elementów Platformy, a także wszelkie inne formy korzystania
                    z Platformy wykraczające poza dozwolony użytek osobisty bez
                    wyraźnej pisemnej zgody Usługodawcy stanowią naruszenie
                    majątkowych praw autorskich.
                </li>
                <li>
                    Bazy danych udostępnione w Platformie są przedmiotem ochrony
                    zgodnie z Ustawą o ochronie baz danych. Zabronione jest
                    pobieranie przez Użytkowników zawartości baz danych
                    udostępnianych na Platformie i ich wtórne wykorzystywanie w
                    całości lub części.
                </li>
                <li>
                    Niniejszy Regulamin nie przyznaje Restauratorowi żadnych
                    praw własności intelektualnej do Platformy i jej części.
                    Wszelkie prawa, które nie zostały wyraźnie przyznane są
                    zastrzeżone na rzecz Usługodawcy i jego licencjodawców.
                </li>
            </ol>
            <h2>XIV. REKLAMACJE</h2>
            <ol>
                <li>
                    Reklamacje Użytkowników dotyczące Oferty, Zamówienia lub
                    realizacji Umowy winny być składane bezpośrednio do
                    Restauracji z wykorzystaniem danych kontaktowych
                    wymienionych na Platformie. W przypadku zgłoszenia
                    reklamacji, o której mowa w zdaniu poprzednim, Usługodawca
                    może pełnić jedynie rolę mediatora.
                </li>
                <li>
                    Restauracja odpowiada za rozpatrywanie reklamacji
                    Użytkowników w zakresie realizacji Umów.
                </li>
                <li>
                    W przypadku reklamacji Restauratora dotyczącej Usług
                    świadczonych przez Usługodawcę, reklamacja winna być
                    przekazana na adres poczty elektronicznej Usługodawcy:
                    support@eatzon.pl
                </li>
                <li>
                    Po otrzymaniu przez Usługodawcę reklamacji, o której mowa w
                    ust. 2, zostanie ona niezwłocznie rozpatrzona, jednak nie
                    później niż w terminie dwóch tygodni od dnia jej otrzymania.
                    Usługodawca potwierdzi otrzymanie reklamacji złożonej przez
                    Restauratora na podany przez niego podczas zgłoszenia adres
                    e-mailowy.
                </li>
            </ol>
            <h2>XV. PROMOCJA</h2>
            <ol>
                <li>
                    Usługodawca jest uprawniony do zamieszczania na wybranych
                    przez siebie serwisach internetowych danych Restauratora
                    (danych firmowych, cennika, rodzaju świadczonych usług,
                    menu, warunki dostaw, godziny otwarcia, logo) łącznie z
                    możliwością przekierowania na Platformę.
                </li>
                <li>
                    Usługodawca korzysta z usług marketingowych oferowanych
                    przez wyszukiwarki internetowe, a w związku z tym jest
                    uprawniony do używania w tym celu danych przedsiębiorstwa
                    Usługobiorcy celem zastosowania tych usług dla wypromowania
                    Usługobiorcy.
                </li>
                <li>
                    Usługodawca ma prawo do publikowania Oferty na dowolnych
                    stronach internetowych.{' '}
                </li>
                <li>
                    Usługodawca ma prawo do umieszczania w swoich materiałach
                    informacyjnych i ofertach informacji o świadczeniu usług na
                    rzecz Restauratora. 
                </li>
            </ol>
            <h2>XVI. NEWSLETTER</h2>
            <ol>
                <li>
                    Usługa Newsletter polega na przesyłaniu przez Usługodawcę na
                    podany przez Restauratora adres poczty elektronicznej
                    (e-mail) wiadomości zawierających informacje dotyczące
                    oferty Platformy.
                </li>
                <li>
                    Restaurator poprzez zawarcie umowy o świadczenie usługi
                    Newsletter z Usługodawcą, udostępnia swój adres poczty
                    elektronicznej (e-mail), podany w formularzu rejestracyjnym,
                    w celu otrzymywania wiadomości, o których mowa w ust.1.
                </li>
                <li>
                    Do korzystania z usługi Newsletter konieczny jest dostęp do
                    sieci Internet oraz posiadanie adresu poczty elektronicznej
                    (e-mail), natomiast nie jest konieczne spełnienie
                    szczególnych wymagań technicznych poza posiadaniem
                    standardowego systemu operacyjnego oraz standardowej
                    przeglądarki internetowej.
                </li>
                <li>
                    Usługa elektroniczna Newsletter świadczona jest nieodpłatnie
                    przez czas nieoznaczony. Restaurator ma możliwość, w każdej
                    chwili i bez podania przyczyny, rezygnacji z Newslettera
                    poprzez wysłanie stosownego żądania do Usługodawcy, w
                    szczególności za pośrednictwem poczty elektronicznej
                    wybierając opcję „unsubscribe” załączonej do każdej
                    wiadomości przesłanej w ramach newslettera.
                </li>
            </ol>
            <h2>XVII. SIŁA WYŻSZA</h2>
            <ol>
                <li>
                    Strony nie są odpowiedzialne za naruszenie obowiązków
                    wynikających z Umowy w przypadku, gdy wyłączną przyczyną
                    naruszenia jest działanie Siły wyższej. 
                </li>
                <li>
                    Przez Siłę wyższą należy rozumieć zdarzenie zewnętrzne,
                    którego Strony nie mogły przewidzieć i  któremu nie mogły
                    zapobiec, uniemożliwiające wykonanie Umowy w całości lub
                    części, na stałe lub na pewien czas, któremu Strona nie
                    mogła przeciwdziałać przy zachowaniu należytej staranności
                    i które nie wynikło wskutek błędów lub zaniedbań Strony
                    dotkniętej jej działaniem. 
                </li>
                <li>
                    Na czas działania Siły wyższej obowiązki Strony, która nie
                    jest w stanie wykonać danego obowiązku ze względu na
                    działanie Siły wyższej, ulegają zawieszeniu. 
                </li>
                <li>
                    W przypadku zaistnienia Siły wyższej Strona, której dotyczy
                    działanie Siły wyższej, zobowiązana jest niezwłocznie
                    poinformować drugą Stronę na piśmie o wystąpieniu Siły
                    wyższej, ze wskazaniem przewidywanego czasu trwania
                    przeszkody w realizacji wynikających z Umowy obowiązków z
                    powodu działania Siły wyższej. Przez czas zaistniałej Siły
                    Wyższej oferta Restauratora zostanie ukryta i nie będzie
                    wyświetlana w Platformie
                </li>
                <li>
                    Strony ustalają, że w przypadku braku faktycznej i realnej
                    możliwości wykonywania postanowień umownych przez co
                    najmniej 14 dni wyłącznie na skutek zaistnienia Siły
                    wyższej, każdej ze Stron przysługuje możliwość odstąpienia
                    od Umowy, w terminie 30 dni od dnia poinformowania drugiej
                    strony o zaistnieniu Siły wyższej.{' '}
                </li>
            </ol>
            <h2>XVIII. OCHRONA DANYCH OSOBOWYCH</h2>
            <ol>
                <li>
                    Administratorem danych osobowych Platformy jest Usługodawca.
                </li>
                <li>
                    Usługodawca udostępnia dane osobowe Użytkowników
                    Restauratorowi, w której złożyli Zamówienie. Restaurator
                    staje się wówczas odrębnym i samodzielnym administratorem
                    tych danych w zakresie niezbędnym do realizacji Umowy.
                </li>
                <li>
                    Restaurator oświadcza, iż zapoznała się z polityką
                    prywatności Platformy i będzie postępować zgodnie z tą
                    polityką prywatności. Restaurator nie będzie udostępniać
                    danych osobowych Użytkowników osobom trzecim oraz nie będzie
                    kontaktować się z Użytkownikami w innych celach niż związane
                    z wykonywaniem Umów.
                </li>
                <li>
                    Cele oraz zasady przetwarzania danych osobowych szczegółowo
                    reguluje{' '}
                    <Link to="/privacy">
                        <strong>Polityka Prywatności</strong>
                    </Link>{' '}
                    stanowiąca integralną część niniejszego Regulaminu.
                </li>
            </ol>
            <h2>XIX. POSTANOWIENIA KOŃCOWE</h2>
            <ol>
                <li>
                    Regulamin i jego zmiany dostępne są w na stronie
                    internetowej eatzon.pl oraz w aplikacji eatzon.com w
                    formacie, który umożliwia pozyskanie, odtwarzanie i
                    utrwalanie treści Regulaminu.
                </li>
                <li>
                    Usługodawca zastrzega sobie prawo do wprowadzania oraz
                    odwoływania ofert oraz promocji bez uszczerbku dla praw
                    nabytych przez Użytkownika i Restauratora, w tym w
                    szczególności warunków umów zawartych przed dokonaniem
                    zmiany.
                </li>
                <li>
                    Usługodawca zastrzega sobie możliwość wprowadzania zmian w
                    Regulaminie. Do Umów zawartych przed zmianą Regulaminu
                    stosuje się Regulamin obowiązujący w dacie zawarcia umowy. W
                    odniesieniu do umów o charakterze ciągłym, zmieniony
                    Regulamin wiąże Restauratora, jeżeli zostały zachowane
                    wymagania określone w art. 384 i 384[1] Kodeksu cywilnego,
                    tj. Użytkownik został prawidłowo powiadomiony o zmianach i
                    nie wypowiedział umowy w terminie 14 dni kalendarzowych od
                    dnia powiadomienia.
                </li>
                <li>
                    Niniejszy Regulamin obowiązuje od dnia publikacji na stronie
                    internetowej oraz w aplikacji.
                </li>
                <li>
                    W sprawach nieuregulowanych w niniejszym Regulaminie mają
                    zastosowanie przepisy Kodeksu cywilnego, przepisy Ustawy o
                    świadczeniu usług drogą elektroniczną oraz inne właściwe
                    przepisy prawa polskiego.
                </li>
            </ol>
        </div>
    );
};

export default TermsRestaurateur;
