import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    useUserDeleteGroupMutation,
    useUserGroupListLazyQuery,
    useUserLeaveGroupMutation,
    useUserSendInviteMutation,
} from '@graphql/generated';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { useState } from 'react';
import { toast } from 'react-toastify';

export const useMyGroupsPage = () => {
    const [openPopup, setOpenPopup] = useState(false);
    const [open, setOpen] = useState(false);
    const { user } = useAuthService();

    const [getGroups, { data, loading }] = useUserGroupListLazyQuery();
    const [groupLeave] = useUserLeaveGroupMutation();

    const [invite] = useUserSendInviteMutation({
        onError: graphqlErrorHandler(true),
    });

    const handleSendInvitation = (email: string) => {
        invite({
            variables: { input: { emailTo: email } },
            onCompleted: () => {
                toast.dark(
                    'Zaproszenie zostało wysłane. Po rejestracji znajomego dodaj go do grupy ponownie',
                );
            },
        });
        setOpen(false);
    };

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getGroups({
                fetchPolicy: 'cache-and-network',
                variables: {
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.list?.metadata,
    });

    const [userDeleteGroup, { loading: deleteLoading }] =
        useUserDeleteGroupMutation();

    const handleDeleteGroup = (id: string, isOwner: boolean) => {
        if (isOwner) {
            userDeleteGroup({
                variables: {
                    input: { groupId: id },
                },
                onCompleted: () => {
                    toast.dark('Grupa została usunięta');
                    setOpenPopup(false);
                },
                update: (cache) => {
                    const normalizedId = cache.identify({
                        id,
                        __typename: 'Group',
                    });
                    cache.evict({ id: normalizedId });
                    cache.gc();
                },
                onError: graphqlErrorHandler(true),
            });
        } else {
            groupLeave({
                variables: {
                    input: {
                        groupId: id,
                    },
                },
                onCompleted: () => {
                    toast.dark(`Opuściłeś grupę`);
                    setOpenPopup(false);
                },
                update: (cache) => {
                    const normalizedId = cache.identify({
                        id,
                        __typename: 'Group',
                    });
                    cache.evict({ id: normalizedId });
                    cache.gc();
                },
                onError: graphqlErrorHandler(true),
            });
        }
    };

    return {
        data,
        getMoreData,
        handleDeleteGroup,
        hasMore,
        loading,
        deleteLoading,
        openPopup,
        open,
        setOpenPopup,
        handleSendInvitation,
        userId: user?.userId,
    };
};
