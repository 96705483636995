import { Controller, useFormContext } from 'react-hook-form';
import { COOK_METHOD_NAME } from '@constants/fromGraphql/CookMethod.constants';
import { CUISINE_NAME } from '@constants/fromGraphql/Cuisine.constants';
import { DIET_NAME } from '@constants/fromGraphql/Diet.constants';
import { DISH_NAME } from '@constants/fromGraphql/Dish.constants';
import { FLAVOUR_NAME } from '@constants/fromGraphql/Flavor.constants';
import { Grid, TextField } from '@material-ui/core';
import { MEAL_TIME_NAME } from '@constants/fromGraphql/MealTime.constants';
import { sDescription, sGrid } from '@style/style';
import { sInput } from '@/EZR/style/input.style';
import { uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useIngredientListQuery } from '@graphql/generated';
import AutocompleteInput from '@/EZR/components/autocompletes/AutocompleteInput';
import ImageProduct from './ImageProduct';

const Details = ({ children, photoUrl, ingredients }) => {
    const { control } = useFormContext();
    const [ingredientsOptions, setIngredientsOptions] = useState();
    const [ingredientName, setIngredientName] = useState('');

    const { data } = useIngredientListQuery({
        variables: {
            input: {
                payload: {
                    name: ingredientName,
                },
            },
        },
    });

    useEffect(() => {
        setIngredientsOptions((prevIngredients) => ({
            ...prevIngredients,
            ...uniqBy(
                [...(data?.list?.data ?? []), ...(ingredients ?? [])],
                'id',
            ).reduce(
                (accumulator, currentValue) => ({
                    ...accumulator,
                    [currentValue.id]: currentValue.name,
                }),
                {},
            ),
        }));
    }, [data, ingredients]);

    return (
        <Grid container css={sGrid}>
            <p css={sDescription}>
                Modyfikuj szczegóły produktu, zarządzaj godzinami dostępności
                oraz parametrami wyszukiwania.
            </p>
            <Grid item xs={12} md={12} css={{ marginTop: '2rem' }}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            required
                            label="Nazwa"
                            name="name"
                            fullWidth
                            css={sInput}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Opis"
                            name="description"
                            fullWidth
                            css={sInput}
                            multiline
                            maxRows={10}
                            minRows={2}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="cuisines"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Kuchnia"
                            options={CUISINE_NAME}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="dishes"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Danie"
                            options={DISH_NAME}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="diets"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Dieta"
                            options={DIET_NAME}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="flavors"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Smak"
                            options={FLAVOUR_NAME}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="cookMethods"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Przyrządzenie"
                            options={COOK_METHOD_NAME}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="ingredientsIds"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Składniki"
                            required
                            options={ingredientsOptions ?? []}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                            onInputChange={setIngredientName}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="mealTimes"
                    control={control}
                    render={({ field }) => (
                        <AutocompleteInput
                            {...field}
                            freeSolo={false}
                            label="Pora dnia"
                            options={MEAL_TIME_NAME}
                            setStateArr={field.onChange}
                            stateArr={field.value}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="weight"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            css={sInput}
                            fullWidth
                            required
                            label="Gramatura, g lub ml"
                            name="weight"
                            type="number"
                            onChange={(event) => {
                                field.onChange(
                                    event.target.value === ''
                                        ? undefined
                                        : +event.target.value,
                                );
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="calories"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            css={sInput}
                            fullWidth
                            label="Kalorie, kcal"
                            name="calories"
                            type="number"
                            onChange={(event) => {
                                field.onChange(
                                    event.target.value === ''
                                        ? null
                                        : +event.target.value,
                                );
                            }}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <Controller
                    name="cookTime"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            css={sInput}
                            fullWidth
                            required
                            label="Czas przygotowania, min"
                            name="cookTime"
                            type="number"
                            InputProps={{ inputProps: { min: 1 } }}
                            onChange={(event) => {
                                field.onChange(
                                    event.target.value === ''
                                        ? undefined
                                        : +event.target.value,
                                );
                            }}
                        />
                    )}
                />
            </Grid>
            <ImageProduct imageUrl={photoUrl} />
            {children}
        </Grid>
    );
};

export default Details;
