import { ListItemCheckbox } from '@/componentsV2/molecules/ListItemCheckbox/ListItemCheckbox';
import { SearchInput } from '@/componentsV2/molecules/SearchInput/SearchInput';
import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';
import styled from '@emotion/styled';
import { CookMethod, Cuisine, Diet, Dish, Flavor } from '@graphql/generated';
import { SearchFilterEmpty } from '@/EZC/domains/Search/molecules/SearchFilterEmpty/SearchFilterEmpty';
import { ButtonText } from '@/componentsV2/atoms/ButtonText/ButtonText';
import { useSearchFilterList } from './useSearchFilterList';

export interface SearchFilterListItem {
    id: Cuisine | Dish | Diet | CookMethod | Flavor;
    name: string;
    isExcluded?: boolean;
}

export interface SearchFilterListProps {
    emptyResultText: string;
    initialLength: number;
    list: SearchFilterListItem[];
    loading?: boolean;
    onChange: (value: string[]) => void;
    value: string[];
    variant: 'all' | 'wrapped';
}

export const SearchFilterList = ({
    emptyResultText,
    initialLength = 11,
    list,
    loading,
    onChange,
    value,
    variant,
}: SearchFilterListProps) => {
    const {
        count,
        expandAllItems,
        filteredList,
        handleChange,
        showButtonAll,
        queryField,
    } = useSearchFilterList({ value, onChange, list, variant, initialLength });

    return (
        <ListWrapper>
            <SearchInput
                onChange={queryField.onChange}
                variant="default"
                placeholder="Na co masz ochotę?"
                name="query"
            />
            {loading ? (
                <Spinner spinnerVariant="absolute" />
            ) : (
                <ListItemCheckboxWrapper>
                    {filteredList?.map((item) => {
                        return (
                            <ListItemCheckbox
                                checked={value.some((id) => id === item.id)}
                                key={item.id}
                                title={item.name}
                                onChange={() => {
                                    handleChange(item.id);
                                }}
                                disabledItemInfo={
                                    item.isExcluded
                                        ? 'Wykluczona w Twoich preferencjach żywieniowych'
                                        : ''
                                }
                                disabled={item.isExcluded}
                                reverse={variant === 'wrapped'}
                                separator={variant === 'all'}
                            />
                        );
                    })}
                </ListItemCheckboxWrapper>
            )}
            {showButtonAll && (
                <div>
                    <ButtonText
                        onClick={expandAllItems}
                        fontSize="14px"
                        underline
                        text={`Rozwiń wszystkie (${count})`}
                        variant="black"
                    />
                </div>
            )}
            {!filteredList?.length && !loading && (
                <EmptyResults text={emptyResultText} />
            )}
        </ListWrapper>
    );
};

const ListWrapper = styled.div`
    display: grid;
    gap: 8px;
`;

const EmptyResults = styled(SearchFilterEmpty)`
    padding: 28px;
`;

const ListItemCheckboxWrapper = styled.div`
    display: grid;
`;
