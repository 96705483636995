import React from 'react'; // eslint-disable-line no-unused-vars

import { useNoAuthOrdersContext } from '../../../context/NoAuthOrders/NoAuthOrders.provider';
import useOrdersMutations from '../../../hooks/useOrders.mutations';
import OrderItem from '../components/OrderItem.component';

import { Hidden } from '@material-ui/core';
import { sDescription } from '../../../../style/style';
import SkeletonOrder from '../../../../components/SkeletonOrder/SkeletonOrder';

export default () => {
    const { handleDeleteOrder } = useOrdersMutations();
    const { ordersNoAuth, loading } = useNoAuthOrdersContext();

    const onDeleteOrder = (orderId) => handleDeleteOrder({ orderId });

    if (!ordersNoAuth || ordersNoAuth.length === 0)
        return <div css={sDescription}>Twój koszyk jest pusty</div>;

    if (loading)
        return (
            <>
                <SkeletonOrder />
                <Hidden mdUp>
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <SkeletonOrder />
                </Hidden>
            </>
        );

    return ordersNoAuth.map((order, id) => (
        <OrderItem
            cartsView
            id={id}
            key={id}
            onDelete={() => onDeleteOrder(order.id)}
            {...makeOrderProps(order)}
        />
    ));
};

function makeOrderProps(order) {
    const { id, restId, lines, type } = order;

    return {
        orderId: id,
        restaurantId: restId.id,
        restaurantName: restId.name,
        restaurantSlug: restId.slug,
        restaurantAddress: restId.address,
        restaurantLogoSrc: restId.avatarUrl,
        orderedProducts: lines.map((item) => item),
        orderPriceSum: lines
            ?.map(({ quantity, product: { price } }) => quantity * price)
            ?.reduce((product, accumulator) => product + accumulator, 0),
        orderType: type,
    };
}
