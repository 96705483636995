import { Chip as MaterialChipSecondary } from '@material-ui/core';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@style/index';
import { SearchFilterSelectorValue } from '@/EZC/domains/Search/molecules/SearchFilterSelector/SearchFilterSelector';
import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';

export type ChipSecondaryVariant = 'default' | 'yellow' | 'red' | 'gray';

export interface ChipSecondaryProps {
    onClick?: (value: SearchFilterSelectorValue) => void;
    onDelete?: (value: SearchFilterSelectorValue) => void;
    size: 'small' | 'default';
    text: string;
    value: SearchFilterSelectorValue;
    variant: ChipSecondaryVariant;
}

export const ChipSecondary = ({
    onClick,
    onDelete,
    size,
    text,
    value,
    variant,
}: ChipSecondaryProps) => {
    const { background, color } = variants[variant];

    return (
        <ChipSecondaryElement
            $background={background}
            $color={color}
            onClick={() => {
                onClick?.(value);
            }}
            label={
                text || (
                    <Spinner spinnerColor={colors.porcelainGray} size={12} />
                )
            }
            $size={size}
            onDelete={onDelete ? () => onDelete(value) : undefined}
            deleteIcon={<Icon $color={color} />}
        />
    );
};

const variants: Record<
    ChipSecondaryVariant,
    {
        background: string;
        color: string;
    }
> = {
    default: {
        background: '#343340',
        color: '#FFFFFF',
    },
    yellow: {
        background: '#FFFCED',
        color: '#343340',
    },
    red: {
        background: '#FFD5D5',
        color: '#343340',
    },
    gray: {
        background: '#E3E4E7',
        color: '#84838A',
    },
};

const ChipSecondaryElement = styled(MaterialChipSecondary)<{
    $background: string;
    $color: string;
    $size: 'default' | 'small';
}>(
    ({ $background, $color, $size }) => css`
        background-color: ${$background};
        color: ${$color};
        font-size: 12px;
        font-weight: 400;
        gap: 10px;
        line-height: 18px;
        max-width: 100%;
        padding: 6px 12px;

        ${$size === 'small' &&
        css`
            gap: 4px;
            height: 24px;
        `}

        &:hover,
        &:focus {
            background-color: ${$background};
            color: ${$color};
        }

        &:active {
            box-shadow: none;
        }

        .MuiChip-label {
            padding: 0;
        }

        .MuiChip-icon {
            margin: 0 2px 0 0;
        }

        .MuiChip-span {
            padding: 0;
        }
    `,
);

export const Icon = styled(CloseIcon)<{
    $color: string;
}>(
    ({ $color }) => css`
        color: ${$color};
        height: 20px;
        margin: 0;
        transition: color 0.3s ease-in-out;
        width: 20px;

        &:hover {
            color: ${colors.gray};
        }
    `,
);
