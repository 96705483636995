import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import NiceModal from '@ebay/nice-modal-react';
import { popup } from '@/AppPopups';

type CancelProductPopupInnerProps = {
    onCancel: () => void;
};

type CancelProductPopupProps = {
    cancelProduct: () => void;
};

export const CancelProductPopup = NiceModal.create(
    ({ cancelProduct }: CancelProductPopupProps) => (
        <CancelProductPopupInner
            onCancel={() => {
                cancelProduct();
            }}
        />
    ),
);

export const CancelProductPopupInner = ({
    onCancel,
}: CancelProductPopupInnerProps) => {
    return (
        <BasePopup
            header={
                <PopupHeader>
                    <Title>Odrzucić produkt</Title>
                </PopupHeader>
            }
            footer={
                <ButtonsWrapper>
                    <Button
                        variant="gray"
                        width={'100%'}
                        onClick={() => popup.remove('CancelProductPopup')}
                    >
                        WRÓĆ DO ZAMÓWIENIA
                    </Button>
                    <Button variant="red" width={'100%'} onClick={onCancel}>
                        ODRZUCAM PRODUKT
                    </Button>
                </ButtonsWrapper>
            }
            children={
                <Description>
                    Czy na pewno chcesz odrzucić ten produkt?
                </Description>
            }
        ></BasePopup>
    );
};

const PopupHeader = styled.header`
    display: grid;
    grid-gap: 24px;
`;
const Title = styled.h2`
    font-size: 28px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
