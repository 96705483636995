import { Link } from 'react-router-dom';
import { useProductsContext } from '../context/products.provider';
import { sGrid, sNoMarginTop, sOuter } from '../../../../style/style';
import { Grid } from '@material-ui/core';

import { colors, s } from '../../../../style';
import {
    sContainer,
    sTextContainer,
    sImageWrapper,
    sPrice,
} from '../../../../EZC/components/ProdItem/ProdItemMenuList.component';
import { StatusLarge } from '../../../../components/Elements';
import TablePagination from '@material-ui/core/TablePagination';
import { productHelpers } from '@helpers/product.helpers';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';
import { ProductAvatar } from '@/svgs/ProductAvatar';

const ProductsList = () => {
    const {
        products,
        currentPage,
        handleChangePage,
        rowsPerPage,
        handleChangeRowsPerPage,
        productsCount,
    } = useProductsContext();

    if (!products.length) return null;

    return (
        <div css={[sOuter, sNoMarginTop]}>
            <Grid container css={sGrid}>
                {products.map((item, index) => (
                    <Grid item xs={12} md={6} key={item.id}>
                        <ProductItem data={item} index={index} />
                    </Grid>
                ))}
            </Grid>
            <StyledTablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={productsCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export const ProductItem = ({ data, index }) => {
    const { name, price, photoUrl, id, deleted } = data;

    return (
        <div
            css={[
                sContainer,
                index % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
                index % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
                deleted && {
                    background: colors.gray500,
                    color: 'white',
                    '&:hover': {
                        background: colors.gray600,
                    },
                },
            ]}
        >
            <Link to={`/rest/products/${id}`} css={[sTextContainer]}>
                <div>
                    {deleted && (
                        <div css={{ marginBottom: '0.5rem' }}>
                            <StatusLarge color={colors.red800}>
                                Archiwum
                            </StatusLarge>
                        </div>
                    )}
                    <h5 className="prod-item-title">{name.toLowerCase()}</h5>
                    <p
                        className="prod-item-ingredients"
                        css={{
                            marginBottom: '1.5rem',
                            textTransform: 'capitalize',
                        }}
                    >
                        {productHelpers.getCategories(data).join(', ')}
                    </p>
                    <p css={sPrice}>
                        {price.toFixed(2)} <span>&nbsp;zł</span>
                    </p>
                </div>
                <div
                    css={[
                        sImageWrapper,
                        !photoUrl && {
                            backgroundColor: colors.gray200,
                            [s.sm_down]: { boxShadow: 'none' },
                            [s.md]: { boxShadow: 'none' },
                        },
                        deleted && { opacity: 0.3 },
                    ]}
                >
                    {photoUrl ? (
                        <img
                            className="prod-item-image"
                            src={photoUrl}
                            alt={''}
                            loading="lazy"
                        />
                    ) : (
                        <ProductAvatar />
                    )}
                </div>
            </Link>
        </div>
    );
};

export const StyledTablePagination = styled(TablePagination)`
    .MuiTablePagination-actions {
        ${mediaQueries.xs} {
            margin-left: 0;
        }
    }
`;

export default ProductsList;
