/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

// Styles
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { Chip, TextField } from '@material-ui/core';
import { sInput } from '../../style/input.style';
import { sChip } from './Creatable';

const filter = createFilterOptions();

const AutocompleteInput = ({
    options,
    freeSolo = false,
    label,
    onInputChange,
    placeholder,
    required,
    stateArr,
    setStateArr,
}) => {
    const [inputValue, setInputValue] = useState('');

    return (
        <div css={styleContainer}>
            <Autocomplete
                value={stateArr}
                onChange={(event, newValue) => {
                    setStateArr(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                    onInputChange && onInputChange(newInputValue);
                }}
                multiple
                css={[{ marginBottom: 24, textTrasform: 'capitalize' }]}
                options={
                    typeof options === 'object' ? Object.keys(options) : options
                }
                freeSolo={freeSolo}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') return option;
                    if (option.inputValue) return option.inputValue;
                    return option;
                }}
                renderOption={(option) => options[option]}
                filterOptions={(optionsUI, params) => {
                    const optionsNames = Object.values(options).map(
                        (option) => option,
                    );
                    const filtered = filter(optionsNames, params);

                    const newFiltered = filtered.map(
                        (item) =>
                            Object.keys(options).filter(
                                (option) => options[option] === item,
                            )[0],
                    );
                    return newFiltered;
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            css={sChip}
                            label={options[option]}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        required={required}
                        css={sInput}
                    />
                )}
            />
        </div>
    );
};

const styleContainer = {
    width: '100%',
};

export default AutocompleteInput;
