/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    Bullet,
    Button,
    ButtonBorder,
    Collapsable,
} from '@components/Elements';
import Spinner from 'src/components/Spinner/Spinner.component';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { sOuter, sGrid, sDescription, sBottomLinks } from '@style/style';
import { Grid, TextField } from '@material-ui/core';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import { CollapsableText } from '@components/Elements/Collapsable/Collapsable';
import { toast } from 'react-toastify';
import { sInput, sInputDarker } from '@/EZR/style/input.style';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { s } from '@style';
import {
    useAccessPageQuery,
    useRestaurantWaiterPasswordChangeMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAuthService } from '@/services/AuthService';
import { useForm, useController } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const AccessPage = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const restaurantId = useAuthService((state) => state.restaurantId);

    const {
        data: dataRestaurant,
        loading,
        error,
    } = useAccessPageQuery({
        variables: {
            input: { restaurantId },
        },
        skip: !restaurantId,
        onError: graphqlErrorHandler(true),
    });

    const [
        restaurantWaiterChangePassword,
        { loading: isPasswordChangeLoading },
    ] = useRestaurantWaiterPasswordChangeMutation();

    const login = dataRestaurant?.restaurant?.waiter?.login;

    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(ChangePasswordSchema),
        defaultValues: {
            password: '',
        },
    });

    const { field: passwordField } = useController({
        control,
        name: 'password',
    });

    const onSubmitPassword = handleSubmit(
        (values) => {
            restaurantWaiterChangePassword({
                variables: {
                    input: {
                        restaurantId,
                        password: values.password,
                        login,
                    },
                },
                onCompleted: () => {
                    toast.dark('Hasło zostało zmienione');
                    reset();
                    setIsOpen(false);
                },
                onError: graphqlErrorHandler(true),
            });
        },
        (errors) => {
            toast.error(errors.password.message);
        },
    );

    return (
        <React.Fragment>
            <TopNav title={'Dostępy'} offset={60} />
            <div css={sOuter}>
                <Headline>Dostępy</Headline>
                <Grid container css={[sGrid, { marginBottom: '1rem' }]}>
                    <p css={sDescription}>
                        Zarządzaj dostępem do konta powiązanych z Restauracją{' '}
                        <strong>{dataRestaurant?.restaurant?.name}</strong>
                    </p>
                </Grid>
                <Grid container css={sGrid}>
                    <Grid item xs={12} md={6}>
                        <Collapsable
                            left
                            noPadding
                            oneLiner
                            title={'Kelner'}
                            icon={<RoomServiceIcon />}
                            description={
                                <>
                                    {dataRestaurant && (
                                        <>
                                            {dataRestaurant?.restaurant?.name}
                                            <Bullet />
                                        </>
                                    )}{' '}
                                    Dostęp do panelu kelnera
                                </>
                            }
                            parentIsOpen={isOpen}
                            parentSetIsOpen={setIsOpen}
                        >
                            {!loading && !error && (
                                <CollapsableText>
                                    <TextField
                                        disabled
                                        label="Login"
                                        value={login}
                                        fullWidth
                                        css={[sInput, sInputDarker]}
                                    />
                                    <TextField
                                        autoComplete="off"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        label="Nowe Hasło"
                                        onChange={passwordField.onChange}
                                        value={passwordField.value}
                                        fullWidth
                                        css={[sInput, sInputDarker]}
                                    />
                                    <div
                                        css={{
                                            display: 'flex',
                                            gap: '1rem',
                                            [s.md]: { gap: '2rem' },
                                        }}
                                    >
                                        <Button
                                            size48
                                            dimmed
                                            action={() =>
                                                setShowPassword(
                                                    (prevState) => !prevState,
                                                )
                                            }
                                        >
                                            {showPassword ? (
                                                <VisibilityIcon />
                                            ) : (
                                                <VisibilityOffIcon />
                                            )}
                                        </Button>
                                        <Button
                                            w100
                                            w100Desktop
                                            action={onSubmitPassword}
                                        >
                                            {isPasswordChangeLoading ? (
                                                <Spinner
                                                    size={1.5}
                                                    addCss={{ color: '#fff' }}
                                                />
                                            ) : (
                                                'Zmień Hasło'
                                            )}
                                        </Button>
                                    </div>
                                </CollapsableText>
                            )}
                        </Collapsable>
                    </Grid>
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/waiter'}>
                            Panel Kelnera
                        </ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

const ChangePasswordSchema = yup.object({
    password: yup.string().min(8, 'Hasło powinno zawierać min. 8 symboli'),
});

export default AccessPage;
