import { useAuthService } from '@/services/AuthService';
import {
    OPERATIONS,
    useGroupAddMembersMutation,
    useUserDeleteMemberMutation,
} from '@graphql/generated';
import { useState } from 'react';
import { UserFriendInfo as UserType } from '@/EZC/domains/Account/pages/NewGroupPage/useNewGroupPage';
import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

interface GroupAccordionProps {
    groupId: string;
}
export const useGroupAccordion = ({ groupId }: GroupAccordionProps) => {
    const [open, setOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState('');
    const [expanded, setExpanded] = useState(false);
    const { user } = useAuthService();
    const [groupAddMembers, { loading }] = useGroupAddMembersMutation();
    const [deleteMember, { loading: deleteLoading }] =
        useUserDeleteMemberMutation();

    const handleAddToGroup = (data: UserType) => {
        groupAddMembers({
            variables: {
                input: {
                    groupId: groupId,
                    members: [data.id],
                },
            },
            onCompleted: () => toast.dark(`Dodano do grupy`),
            onError: graphqlErrorHandler(true),
            refetchQueries: [OPERATIONS.Query.UserGroupList],
        });

        if (user?.userId === data.id) {
            toast.error('Nie możesz dołączyć do własnej grupy');
            return;
        }
        setOpen(false);
    };

    const handleRemoveFromGroup = (userId: string) => {
        deleteMember({
            variables: {
                input: {
                    groupId,
                    userId,
                },
            },
            onCompleted: () => {
                toast.dark(`Usunieto z grupy`);
                setUserToDelete('');
            },
            onError: graphqlErrorHandler(true),
            refetchQueries: [OPERATIONS.Query.UserGroupList],
        });
    };

    return {
        deleteLoading,
        expanded,
        handleAddToGroup,
        handleRemoveFromGroup,
        loading,
        open,
        setExpanded,
        setOpen,
        setUserToDelete,
        userToDelete,
    };
};
