export type ClientErrorCode = keyof typeof CLIENT_ERROR_MESSAGES;

export const CLIENT_ERROR_MESSAGES = {
    INCORRECT_IMAGE_TYPE:
        'Nieprawidłowy format pliku. Obsługiwane formaty to .jpg, .jpeg oraz .png',
    FILE_UPLOAD: 'Błąd podczas wysyłania pliku',
    IMAGE_UPLOAD: 'Błąd podczas wysyłania zdjęcia',
    MAX_IMAGE_SIZE: 'Maksymalny rozmiar zdjęcia to 5 MB',
    SOMETHING_WENT_WRONG: 'Coś poszło nie tak...',
    LONG_MAX_IMAGE_SIZE:
        'Rozmiar pliku, który próbujesz przesłać, przekracza dopuszczalny limit 5 MB',
};
