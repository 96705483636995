import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAccountCoinsQuery } from '@graphql/generated';
import MoneyEz from '@helpers/MoneyEz';

export const useAccountCoins = () => {
    const { data } = useAccountCoinsQuery({
        onError: graphqlErrorHandler(true),
    });

    const actualBalance = MoneyEz(data?.user.coinsAvailable || 0)
        .getAmountWithFullPrecision()
        .split('.');

    const blockedBalance = MoneyEz(data?.user.coinsBlocked || 0)
        .getAmountWithFullPrecision()
        .split('.');

    return {
        actualBalance,
        blockedBalance,
    };
};
