import { useState } from 'react';

export default () => {
    const [openningHours, setOpenningHours] = useState(initOpenningHours);

    const handleOpenningHours = (e, type) => {
        setOpenningHours({
            ...openningHours,
            [e.target.name]: {
                ...openningHours[e.target.name],
                [type]: parseInt(e.target.value, 10),
            },
        });
    };

    return {
        openningHours,
        setOpenningHours,
        handleOpenningHours,
    };
};

export const initOpenningHours = {
    monday: {
        open: 600,
        close: 1440,
    },
    tuesday: {
        open: 600,
        close: 1440,
    },
    wednesday: {
        open: 600,
        close: 1440,
    },
    thursday: {
        open: 600,
        close: 1440,
    },
    friday: {
        open: 600,
        close: 1440,
    },
    saturday: {
        open: 600,
        close: 1440,
    },
    sunday: {
        open: 600,
        close: 1440,
    },
};
