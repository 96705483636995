import { colors } from '@style/index';
import { InputTextNew } from '@/componentsV2/atoms/InputTextNew/InputTextNew';
import { toast } from 'react-toastify';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import React, { ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@/componentsV2/atoms/Checkbox/Checkbox';
import { css } from '@emotion/react';

const AddressFormSchema = yup.object({
    addressName: yup
        .string()
        .max(40, 'Nazwa adresu jest zbyt długa (maks. 40 znaków)')
        .required('To pole nie może być puste'),
    address: yup
        .object({
            address: yup.string().defined(),
            lng: yup.number().defined(),
            lat: yup.number().defined(),
        })
        .test(
            'valid-geolocation',
            'To pole nie może być puste.',
            (value) => !!value.address,
        )
        .test(
            'valid-geolocation',
            'Nie udało się określić lokalizacji, wpisz swój adres.',
            (value) => !!value.lng && !!value.lat,
        ),
    additionalAddress: yup.string().nullable(),
    defaultAddress: yup.boolean(),
});
export interface AddressFormSchemaValues
    extends yup.InferType<typeof AddressFormSchema> {}

type AddressFormType = 'cart' | 'dark' | 'default';

interface AddressFormProps {
    children: (value: boolean) => ReactNode;
    defaultValues?: AddressFormSchemaValues;
    addressId?: string;
    onSubmit: (values: AddressFormSchemaValues, addressId?: string) => void;
    setIsEdited?: (values: boolean) => void;
    setAddressError?: (values: boolean) => void;
    formVariant?: AddressFormType;
}

export const AddressForm = ({
    children,
    onSubmit,
    defaultValues,
    setIsEdited,
    setAddressError,
    addressId,
    formVariant = 'default',
}: AddressFormProps) => {
    const methods = useForm<AddressFormSchemaValues>({
        resolver: yupResolver(AddressFormSchema),
        values: {
            //@ts-expect-error FIXME
            address: defaultValues?.address ?? {},
            additionalAddress: defaultValues?.additionalAddress ?? '',
            addressName: defaultValues?.addressName ?? '',
            defaultAddress: !!defaultValues?.defaultAddress,
        },
    });

    useEffect(() => {
        setIsEdited?.(methods.formState.isDirty || !defaultValues);
    }, [methods.formState.isDirty]);

    const { field: addressField } = useController({
        control: methods.control,
        name: 'address',
    });

    const { field: addressNameField } = useController({
        control: methods.control,
        name: 'addressName',
    });

    const { field: additionalAddressField } = useController({
        control: methods.control,
        name: 'additionalAddress',
    });

    const { field: defaultAddressField } = useController({
        control: methods.control,
        name: 'defaultAddress',
    });

    const handleFormSubmit = methods.handleSubmit(
        async (values) => {
            onSubmit(values, addressId);
        },
        () => {
            toast.error(
                'Wypełnij wszystkie obowiązkowe pola: nazwa, ulica i numer budynku.',
            );
            setAddressError?.(true);
        },
    );

    const handleSelect = async (address: string) => {
        try {
            const results = await geocodeByAddress(address);
            const { lng, lat } = await getLatLng(results[0]);
            addressField.onChange({ address, lng, lat });
        } catch (error) {
            console.error(error);
            addressField.onChange({});
        }
    };

    const handleChange = async (address: string) => {
        try {
            const results = await geocodeByAddress(address);
            const { lng, lat } = await getLatLng(results[0]);

            addressField.onChange({ address, lng, lat });
        } catch (error) {
            console.error(error);
            addressField.onChange({ address, lng: 0, lat: 0 });
        }
    };

    return (
        <Wrapper onSubmit={handleFormSubmit} noValidate>
            <InputTextNew
                {...addressNameField}
                required
                error={!!methods?.formState?.errors?.addressName?.message}
                label="NAZWA ADRESU"
                fullWidth
                errorMessage={methods?.formState?.errors?.addressName?.message}
                inputVariant={formVariant === 'default' ? 'default' : 'dark'}
            />
            <PlacesAutocomplete
                value={addressField?.value?.address ?? ''}
                onChange={handleChange}
                onSelect={handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <InputContainer>
                        <InputTextNew
                            {...getInputProps()}
                            label={'ULICA I NUMER BUDYNKU'}
                            required
                            errorMessage={
                                methods?.formState?.errors?.address?.message
                            }
                            error={
                                !!methods?.formState?.errors?.address?.message
                            }
                            inputVariant={
                                formVariant === 'default' ? 'default' : 'dark'
                            }
                        />
                        <DropdownContainer className="autocomplete-dropdown-container">
                            {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? {
                                          backgroundColor: colors.gray300,
                                      }
                                    : { cursor: 'pointer' };

                                return (
                                    <SugestionContainer
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                        key={suggestion.index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </SugestionContainer>
                                );
                            })}
                        </DropdownContainer>
                    </InputContainer>
                )}
            </PlacesAutocomplete>
            <InputTextNew
                {...additionalAddressField}
                label="NUMER MIESZKANIA, PIĘTRO..."
                fullWidth
                inputVariant={formVariant === 'default' ? 'default' : 'dark'}
            />
            {formVariant !== 'cart' && !defaultValues?.defaultAddress && (
                <CheckboxWrapper formVariant={formVariant}>
                    <Checkbox
                        onChange={defaultAddressField.onChange}
                        name="defaultAddress"
                        checked={defaultAddressField.value}
                        borderColor={'gray'}
                        fillColor={'green'}
                    />
                    Ustaw jako domyślny adres dostawy
                </CheckboxWrapper>
            )}
            {children(
                methods.formState.isSubmitted && !methods.formState.isValid,
            )}
        </Wrapper>
    );
};

const Wrapper = styled.form`
    display: grid;
    width: 100%;
    gap: 16px;
`;

const DropdownContainer = styled.div`
    margin-top: 2px;
    padding: 0 0;
    width: 100%;
    position: absolute;
    z-index: 9;
    top: 100%;
    background: ${colors.white};
`;

const InputContainer = styled.div`
    position: relative;
`;

const SugestionContainer = styled.div`
    border-radius: 32px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    padding: 16px;
    width: 100%;

    &:last-child {
        margin-bottom: 20px;
    }
`;

const CheckboxWrapper = styled.div<{ formVariant?: AddressFormType }>(
    ({ formVariant }) => css`
        font-size: 12px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-top: ${formVariant === 'dark' ? '8px' : '16px'};
        color: ${colors.text};

        .MuiCheckbox-root {
            color: ${colors.gray400};
            padding: 0 8px 0 0;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
            color: ${colors.green};
            border-radius: 4px;
        }
    `,
);
