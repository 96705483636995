/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PageLoader from '@components/PageLoader/PageLoader';
import { useLoginWithGoogle } from './useLoginWithGoogle';
import { MOBILE } from '@constants/common.constants';

const LoginWithGoogle = () => {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const code = params.get('code') || '';

    const { handleLoginWithGoogle } = useLoginWithGoogle();

    useEffect(() => {
        if (!code && !MOBILE) {
            history.goBack();
        }
    }, [code, MOBILE]);

    useEffect(() => {
        code && handleLoginWithGoogle();
    }, []);

    return <PageLoader />;
};

export default LoginWithGoogle;
