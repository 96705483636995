import { resolveEnv } from '@helpers/resolveEnv';
import { FirebaseOptions } from 'firebase/app';
import { useTestModeServiceStorage } from '@/services/TestModeService/TestModeServiceStorage';

export const FIREBASE_ENV = useTestModeServiceStorage.getState().isTestMode
    ? resolveEnv('REACT_APP_TEST_MODE_FIREBASE_ENV')
    : resolveEnv('REACT_APP_FIREBASE_ENV');

export const FIREBASE_CONFIG: FirebaseOptions = JSON.parse(
    resolveEnv('REACT_APP_FIREBASE_CONFIG'),
);
