import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { useAdminChangeRestaurantOwnerMutation } from '@graphql/generated';
import { useState } from 'react';

const useChangeRestaurantOwner = () => {
    const [changeOwner] = useAdminChangeRestaurantOwnerMutation();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [changeOwnerData, setChangeOwnerData] = useState({
        restId: '',
        toUserId: '',
    });

    const onRestaurantChoose = (restId) => {
        setIsPopupOpen(true);
        setChangeOwnerData({ ...changeOwnerData, restId });
    };

    const onPopupClose = () => {
        setIsPopupOpen(false);
        setChangeOwnerData({ restId: '', toUserId: '' });
    };

    const onChoseOwner = (toUserId) => {
        setChangeOwnerData({ ...changeOwnerData, toUserId });
    };

    const onResetOwner = () => {
        setChangeOwnerData({ ...changeOwnerData, toUserId: '' });
    };

    const onChangeOwner = async () => {
        const { restId, toUserId } = changeOwnerData;

        if (!restId || !toUserId) {
            toast.warning('Wybierz właściciela restauracji');
            return;
        }

        await changeOwner({
            variables: {
                input: { restaurantId: restId, newOwnerId: toUserId },
            },

            onCompleted: () => {
                toast.success('Właściciel restauracji został zmieniony');
                setIsPopupOpen(false);
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return {
        onRestaurantChoose,
        isPopupOpen,
        onPopupClose,
        onChangeOwner,
        onChoseOwner,
        onResetOwner,
    };
};

export default useChangeRestaurantOwner;
