import { useState } from 'react';
import { Dialog } from '@capacitor/dialog';
import { resolveEnv } from '@helpers/resolveEnv';
import { useTestModeServiceStorage } from './TestModeServiceStorage';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import { MOBILE } from '@constants/common.constants';

export const useTestModeService = () => {
    const { isTesterActive, setIsTesterActive, setIsTestMode } =
        useTestModeServiceStorage();

    const [clickCounter, setClickCounter] = useState(0);

    const handleClick = () => {
        if (!MOBILE) return;

        if (!isTesterActive) {
            if (clickCounter < 15) {
                setClickCounter(clickCounter + 1);
            } else {
                handleOpenPrompt();
            }
        } else {
            handleSelectEnv();
        }
    };

    const handleSelectEnv = async () => {
        const result = await ActionSheet.showActions({
            title: 'Wybierz środowisko',
            options: [
                {
                    title: 'Środowisko domyślne',
                },
                {
                    title: 'Środowisko testowe',
                },
                {
                    title: 'Wyłącz tryb testowania',
                    style: ActionSheetButtonStyle.Destructive,
                },
                {
                    title: 'Anuluj',
                    style: ActionSheetButtonStyle.Cancel,
                },
            ],
        });
        if (result.index === 3) return;
        if (result.index === 2) {
            setIsTesterActive(false);
            setClickCounter(0);
        } else {
            setIsTestMode(result.index === 1);
        }
    };

    const handleOpenPrompt = async () => {
        if (!isTesterActive) {
            const { value: login, cancelled } = await Dialog.prompt({
                inputPlaceholder: 'Login',
                message: 'Login do testera',
                cancelButtonTitle: 'Anuluj',
            });

            if (cancelled) return;

            const { value: password } = await Dialog.prompt({
                inputPlaceholder: 'Hasło',
                message: 'Hasło do testera',
                cancelButtonTitle: 'Anuluj',
            });

            if (
                (login === resolveEnv('REACT_APP_TEST_MODE_LOGIN') &&
                    password === resolveEnv('REACT_APP_TEST_MODE_PASSWORD')) ||
                (login === resolveEnv('REACT_APP_TEST_MODE_CLIENT_LOGIN') &&
                    password ===
                        resolveEnv('REACT_APP_TEST_MODE_CLIENT_PASSWORD'))
            ) {
                setIsTesterActive(true);
                handleSelectEnv();
            } else {
                alert('Nieprawidłowe dane logowania');
                setClickCounter(0);
            }
        } else {
            handleSelectEnv();
        }
    };

    return {
        handleClick,
    };
};
