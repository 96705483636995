/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
} from 'react-sortable-hoc';

import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddIcon from '@material-ui/icons/Add';

import Accordion from '@material-ui/core/Accordion';
import {
    Grid,
    AccordionSummary,
    AccordionDetails,
    Hidden,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    sGrid,
    sOuter,
    sPaddingBottom,
    sPaddingBottom2,
    sPaddingTop,
} from '../../../../style/style';
import { colors, s } from '../../../../style';
import { Button, ButtonBorder } from '../../../../components/Elements';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
    sContainer,
    sTextContainer,
    sImageWrapper,
    sPrice,
} from '../../../../EZC/components/ProdItem/ProdItemMenuList.component';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import { ProductAvatar } from '@/svgs/ProductAvatar';
// import { ProductItem } from '../../Products/components/ProductsList'

// import ProductsTable from '../components/ProductsTable'

export default ({
    menuSections,
    setMenuSections,
    handleAddProductsOpen,
    onDeleteSection,
    onDeleteProduct,
    onNameChange,
    wasChanges,
    setWasChanges,
}) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const copiedMenuSections = [...menuSections];
        const movedSection = copiedMenuSections[oldIndex];
        copiedMenuSections.splice(oldIndex, 1);
        copiedMenuSections.splice(newIndex, 0, movedSection);
        copiedMenuSections.forEach((section, index) => {
            copiedMenuSections[index] = {
                ...section,
                order: index,
            };
        });

        if (!wasChanges) setWasChanges(true);
        setMenuSections(copiedMenuSections);
    };

    return (
        <div css={sGrid}>
            <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                {menuSections.map((section, index) => (
                    <SortableItem
                        key={section.id}
                        index={index}
                        section={section}
                        handleAddProductsOpen={handleAddProductsOpen}
                        onDeleteSection={onDeleteSection}
                        onDeleteProduct={onDeleteProduct}
                        onNameChange={onNameChange}
                    />
                ))}
            </SortableContainer>
        </div>
    );
};

const DragHandle = sortableHandle(() => (
    <div css={{ paddingRight: 8, cursor: 'row-resize' }}>
        <DragHandleIcon />
    </div>
));

const SortableContainer = sortableContainer(({ children }) => (
    <div>{children}</div>
));

const SortableItem = sortableElement(
    ({
        section,
        handleAddProductsOpen,
        onDeleteSection,
        onDeleteProduct,
        onNameChange,
    }) => {
        return (
            <div css={sAccordionOutside}>
                <Accordion css={sAccordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        css={sAccordionSummary}
                    >
                        <div
                            css={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <DragHandle />

                            <div
                                css={{
                                    flexGrow: 1,
                                    paddingLeft: 8,
                                }}
                            >
                                <input
                                    defaultValue={section.name}
                                    onChange={onNameChange(section.id)}
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    placeholder="Nowa Sekcja"
                                    css={sInput}
                                />
                            </div>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails css={sDetails}>
                        <div css={sButtons}>
                            <Button
                                w100Mobile
                                action={() => handleAddProductsOpen(section.id)}
                                startIcon={<AddIcon>Dodaj</AddIcon>}
                            >
                                <AddCircleIcon fontSize="inherit" />
                                &nbsp;Produkt
                            </Button>
                            <div
                                css={{
                                    paddingLeft: '1rem',
                                    [s.md]: { paddingLeft: '1.5rem' },
                                }}
                            />
                            <Button
                                size48
                                dimmed
                                action={() => onDeleteSection(section.id)}
                            >
                                <DeleteIcon />
                            </Button>
                        </div>
                        {section.products && section.products.length > 0 && (
                            <ProductsContainer
                                data={[...section.products]}
                                onDeleteProduct={onDeleteProduct(section.id)}
                            />
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    },
);

const ProductsContainer = ({ data, onDeleteProduct }) => {
    return (
        <Grid container css={[sPaddingTop]}>
            {data.map((product, id) => (
                <Grid item xs={12} md={6} key={id}>
                    <ProductItem
                        data={product}
                        id={id}
                        onDeleteProduct={onDeleteProduct}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export const ProductItem = ({ data, id, onDeleteProduct }) => {
    const { name, price, photoUrl, categories, id: _id } = data;

    return (
        <div
            css={[
                sContainer,
                {
                    background: colors.gray300,
                    '&:hover': {
                        background: colors.gray200,
                    },
                },
                id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
                id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
            ]}
        >
            <div css={[sTextContainer]}>
                <div
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <h5 className="prod-item-title">
                            {name.toLowerCase()}
                        </h5>
                        <p
                            className="prod-item-ingredients"
                            css={{ marginBottom: '1.5rem' }}
                        >
                            {categories?.map((category, id) =>
                                id > 0 ? ', ' + category.name : category.name,
                            )}
                        </p>
                    </div>
                </div>
                <div
                    css={[
                        sImageWrapper,
                        !photoUrl && {
                            backgroundColor: colors.gray200,
                            [s.sm_down]: { boxShadow: 'none' },
                            [s.md]: { boxShadow: 'none' },
                        },
                    ]}
                >
                    {photoUrl ? (
                        <img
                            className="prod-item-image"
                            src={photoUrl}
                            alt={''}
                            loading="lazy"
                        />
                    ) : (
                        <ProductAvatar />
                    )}
                </div>
            </div>
            <div
                css={{
                    display: 'flex',
                    marginTop: '1rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <p css={sPrice}>
                    {price.toFixed(2)} <span>&nbsp;zł</span>
                </p>
                <div css={{ display: 'flex' }}>
                    <ButtonBorder
                        link={`/product/${_id}`}
                        extraCss={sButtonBorderHover}
                    >
                        <div css={{ fontSize: '15px' }}>
                            <VisibilityIcon fontSize="inherit" />
                        </div>
                        <Hidden xsDown>&nbsp;Wyświetl</Hidden>
                    </ButtonBorder>
                    <div css={{ padding: '0.25rem' }} />
                    <ButtonBorder
                        link={`/rest/products/${_id}`}
                        extraCss={sButtonBorderHover}
                    >
                        <div css={{ fontSize: '15px' }}>
                            <EditIcon fontSize="inherit" />
                        </div>
                        <Hidden xsDown>&nbsp;Edytuj</Hidden>
                    </ButtonBorder>
                    <div css={{ padding: '0.25rem' }} />
                    <ButtonBorder
                        action={() => onDeleteProduct(_id)}
                        extraCss={sButtonBorderHover}
                    >
                        <div css={{ fontSize: '15px' }}>
                            <DeleteIcon fontSize="inherit" />
                        </div>
                        <Hidden xsDown>&nbsp;Usuń</Hidden>
                    </ButtonBorder>
                </div>
            </div>
        </div>
    );
};

const sButtonBorderHover = {
    '&:hover': {
        backgroundColor: colors.text,
        color: colors.white,
        boxShadow: `inset 0px 0px 0px 2px ${colors.text}`,
    },
};

const sDetails = {
    padding: '0 !important',
    display: 'flex',
    flexDirection: 'column',
};

const sButtons = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '1rem',
    [s.md]: {
        paddingTop: '1.5rem',
        justifyContent: 'flex-start',
    },
};

const sAccordion = {
    boxShadow: 'none',
    borderRadius: '1rem !important',
    backgroundColor: colors.gray100,
    marginBottom: '1rem !important',
    padding: '0.75rem 1.5rem 0.75rem 1.5rem',
    border: 'none',
    [s.md]: {
        padding: '1.5rem 2rem 1.5rem 2rem',
        marginBottom: '1.5rem !important',
    },

    '.MuiAccordion-rounded': {
        '&:first-child': {
            borderTopLeftRadius: '1rem !important',
            borderTopRightRadius: '1rem !important',
        },
        borderTopLeftRadius: '1rem !important',
        borderTopRightRadius: '1rem !important',
    },
    '.MuiAccordionSummary-root': {
        padding: '0 !important',
    },
};

const sAccordionOutside = {
    '.MuiAccordion-root:before': {
        display: 'none',
    },
    '.MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-content': {
        margin: '0 !important',
    },
    '.MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 0,
    },
};

const sInput = {
    transition: 'background-color 0.3s ease-out',
    padding: '1rem',
    [s.xs]: {
        padding: '0.75rem 1rem',
    },
    fontSize: '1rem',
    fontWeight: 800,
    backgroundColor: colors.gray100,
    border: 'none',
    fontFamily: 'Poppins',
    letterSpacing: '-0.01em',
    borderRadius: '1rem',
    width: '100%',
    '&:hover, &:focus': {
        backgroundColor: colors.gray400,
    },
    '::placeholder': {
        opacity: 1,
        color: colors.gray600,
    },
};

const sAccordionSummary = {
    margin: '0 !important',
};
