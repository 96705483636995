/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline';
import { Grid } from '@material-ui/core';
import { sBottomLinks, sOuter, sGrid } from '../../../style/style';
import { ButtonBorder } from '../../../components/Elements';
import { sDoc } from './Terms.page';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { EuropeanFundsInfo } from '@/componentsV2/atoms/EuropeanFundsInfo/EuropeanFundsInfo';

const Privacy = () => {
    return (
        <>
            <TopNav title={'Polityka Prywatności'} />
            <div css={sOuter}>
                <Headline>Polityka Prywatności</Headline>
                <Grid container css={sGrid}>
                    <Doc />
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/terms'}>Regulamin</ButtonBorder>
                    </Grid>
                    <EuropeanFundsInfo />
                </Grid>
            </div>
        </>
    );
};

const Doc = () => {
    return (
        <div
            css={[
                sDoc,
                {
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    width: '100%',
                },
            ]}
        >
            <p>
                Niniejsza Polityka prywatności (dalej jako: „
                <strong>Polityka</strong>”) określa zasady i warunki
                przetwarzania danych osobowych w związku z korzystaniem z
                serwisu internetowego prowadzonego przez Eatzon pod adresem
                eatzon.pl (dalej jako: „<strong>Platforma</strong>”).
            </p>
            <h2>I. DEFINICJE</h2>
            <p>
                Na potrzeby niniejszej Polityki, przyjmuje się następujące
                znaczenie poniższych pojęć:
            </p>
            <ol>
                <li>
                    Administrator / Eatzon – Eatzon Spółka z ograniczoną
                    odpowiedzialnością z siedzibą w Elblągu (82-300) przy
                    Stanisława Sulimy 1/226, działająca na podstawie wpisu do
                    Krajowego Rejestru Sądowego pod numerem 0000857377, NIP:
                    9462699743, REGON: 38687656900000
                </li>
                <li>
                    Dane osobowe – wszelkie informacje o zidentyfikowanej lub
                    możliwej do zidentyfikowania osobie fizycznej ("osobie,
                    której dane dotyczą"); możliwa do zidentyfikowania osoba
                    fizyczna to osoba, którą można bezpośrednio lub pośrednio
                    zidentyfikować, w szczególności na podstawie identyfikatora
                    takiego jak imię i nazwisko, numer identyfikacyjny, dane o
                    lokalizacji, identyfikator internetowy lub jeden bądź kilka
                    szczególnych czynników określających fizyczną,
                    fizjologiczną, genetyczną, psychiczną, ekonomiczną,
                    kulturową lub społeczną tożsamość osoby fizycznej;
                </li>
                <li>
                    RODO - Rozporządzenie Parlamentu Europejskiego i Rady (UE)
                    2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                    fizycznych w związku z przetwarzaniem danych osobowych i w
                    sprawie swobodnego przepływu takich danych oraz uchylenia
                    dyrektywy 95/46/WE.
                </li>
                <li>
                    Użytkownik / Podmiot danych - każda osoba fizyczna
                    odwiedzająca Platformę Eatzon lub korzystająca z jednej albo
                    kilku usług czy funkcjonalności opisanych w Polityce.
                </li>
            </ol>
            <h2>II. INFORMACJE O ADMINISTRATORZE</h2>
            <ol>
                <li>
                    Administratorem danych osobowych jest Eatzon Spółka z
                    ograniczoną odpowiedzialnością z siedzibą w Elblągu (82-300)
                    przy ul. Stanisława Sulimy 1/226, działająca na podstawie
                    wpisu do Krajowego Rejestru Sądowego pod numerem 0000857377,
                    NIP: 9462699743, REGON: 38687656900000.
                </li>
                <li>
                    Kontakt z Administratorem we wszelkich sprawach dotyczących
                    danych osobowych jest możliwy pisemnie na adres
                    korespondencyjny wskazany powyżej lub za pośrednictwem
                    adresu e-mail:{' '}
                    <a href="mailto:kontakt@eatzon.pl">kontakt@eatzon.pl</a>
                </li>
            </ol>
            <h2>III. CELE I PODSTAWY PRAWNE PRZETWARZANIA DANYCH</h2>
            <p>
                W związku z korzystaniem przez Użytkownika z Platformy, Eatzon
                zbiera dane w zakresie niezbędnym do świadczenia poszczególnych
                usług, a także informacje o aktywności Użytkownika na
                Platformie. Szczegółowe zasady i cele przetwarzania Danych
                osobowych zostały opisane poniżej.
            </p>
            <h3>a. KORZYSTANIE Z PLATFORM</h3>
            <ol>
                <li>
                    Administrator danych przetwarza dane osób korzystających z
                    Platformy:
                    <ol>
                        <li>
                            w celu świadczenia usług drogą elektroniczną w
                            zakresie udostępniania Użytkownikom treści
                            gromadzonych na Platformie - podstawą prawną jest
                            niezbędność przetwarzania do wykonania umowy o
                            świadczenie usług drogą elektroniczną (art. 6 ust. 1
                            lit. b RODO);
                        </li>
                        <li>
                            w celach analitycznych i statystycznych - podstawą
                            prawną jest uzasadniony interes Administratora (art.
                            6 ust. 1 lit. f RODO) polegający na mierzeniu
                            efektywności Platformy i doskonaleniu jej
                            funkcjonalności;
                        </li>
                        <li>
                            w celu diagnozowania problemów związanych z pracą
                            Platformy, zapewnieniu jej prawidłowej konfiguracji
                            i bezpieczeństwa informatycznego - podstawą prawną
                            jest uzasadniony interes Administrator (art. 6 ust.
                            1 lit. f RODO) polegający na zapewnieniu
                            prawidłowego funkcjonowania Platformy.
                        </li>
                    </ol>
                </li>
                <li>
                    W tych celach Administrator danych przetwarza dane
                    zarejestrowane w logach systemowych dotyczące aktywności w
                    Platformie tj. oglądaną zawartość Platformy, dane dotyczące
                    sesji, urządzenia, systemu operacyjnego, przeglądarki,
                    lokalizacji i adresu IP Użytkownika.
                </li>
            </ol>
            <h3>b. REJESTRACJA W PLATFORMIE (KONTO UŻYTKOWNIKA)</h3>
            <ol>
                <li>
                    W celu założenia konta na Platformie Eatzon wymaga podania
                    wyłącznie Danych osobowych niezbędnych do utworzenia i
                    prowadzenia konta (tj. imię, nazwisko, adres e-mail).
                    Niepodanie tych danych skutkuje brakiem możliwości założenia
                    konta w Platformie. Podanie pozostałych danych jest
                    dobrowolne.
                </li>
                <li>
                    Użytkownik ma możliwość założenia Konta Użytkownika również
                    za pośrednictwem portali społecznościowych. Wówczas
                    Platforma za zgodą Użytkownika pobiera z konta w portalu
                    społecznościowym dane niezbędne do rejestracji Konta
                    Użytkownika. Informacje o zakresie i celach przetwarzania
                    danych przez portale społecznościowe wskazane są w ich
                    politykach prywatności.
                </li>
                <li>
                    Dane osobowe są przetwarzane:
                    <ol>
                        <li>
                            w celu świadczenia usług drogą elektroniczną
                            związanych z prowadzeniem i obsługą Konta
                            Użytkownika na Platformie i udostępnianiem
                            funkcjonalności Konta - podstawą prawną jest
                            niezbędność przetwarzania do wykonania umowy (art. 6
                            ust. 1 lit. b RODO), a w zakresie danych oznaczonych
                            jako fakultatywne - podstawą prawną przetwarzania
                            jest zgoda (art. 6 ust. 1 lit. a RODO);
                        </li>
                        <li>
                            w celach analitycznych i statystycznych - podstawą
                            prawną jest uzasadniony interes Administratora (art.
                            6 ust. 1 lit. f RODO) polegający na mierzeniu
                            efektywności Platformy i doskonaleniu jej
                            funkcjonalności;
                        </li>
                        <li>
                            diagnozowania problemów związanych z pracą
                            Platformy, zapewnieniu jej prawidłowej konfiguracji
                            i bezpieczeństwa informatycznego - podstawą prawną
                            jest uzasadniony interes Administrator (art. 6 ust.
                            1 lit. f RODO) polegający na zapewnieniu
                            prawidłowego funkcjonowania Platformy;
                        </li>
                        <li>
                            w celu ewentualnego ustalenia i dochodzenia roszczeń
                            lub obrony przed roszczeniami – podstawą prawną
                            przetwarzania jest prawnie uzasadniony interes
                            Administratora (art. 6 ust. 1 lit. f RODO)
                            polegający na ochronie jego praw;
                        </li>
                    </ol>
                </li>
            </ol>
            <h3>c. ŚWIADCZENIE USŁUG I FUNKCJONALNOŚCI KONTA UŻYTKOWNIKA</h3>
            <ol>
                <li>
                    W zależności od usługi lub funkcjonalności Konta Użytkownika
                    Eatzon może przetwarzać następujące dane:
                    <ol>
                        <li>
                            dotyczące historii Zamówień Użytkownika na
                            Platformie – podstawą prawną przetwarzania danych
                            jest niezbędność przetwarzania do wykonania umowy na
                            prowadzenie Konta Użytkownika (art. 6 ust. 1 lit. b
                            RODO);
                        </li>
                        <li>
                            dotyczące wyrażonych przez Użytkownika opinii na
                            temat Restauracji i Produktów – podstawą prawną
                            przetwarzania jest niezbędność przetwarzania do
                            świadczenia usługi wyrażania opinii na temat
                            Restauracji i Produktów (art. 6 ust. 1 lit. b RODO);
                        </li>
                        <li>
                            wizerunek Użytkownika – podstawą prawną
                            przetwarzania jest zgoda Użytkownika wyrażona w
                            drodze wyraźnego działania potwierdzającego
                            polegającego na wgraniu zdjęcia lub grafiki przez
                            Użytkownika do Konta Użytkownika (art. 6 ust. 1 lit.
                            a RODO);
                        </li>
                        <li>
                            informacje o geolokalizacji, jeżeli Użytkownik
                            włączył usługę lokalizacji swojego urządzenia dla
                            aplikacji mobilnej bądź strony internetowej, wówczas
                            Eatzon przetwarza zdefiniowane przez Użytkownika i
                            udostępnione za jego zgodą dane dotyczące
                            lokalizacji, by oferować usługi dopasowane do jego
                            położenia geograficznego – podstawą prawną
                            przetwarzania danych jest zgoda Użytkownika wyrażona
                            w drodze wyraźnej czynności potwierdzającej
                            polegającej na zatwierdzeniu udostępnienia
                            Platformie danych dotyczących lokalizacji urządzenia
                            (art. 6 ust. 1 lit. a RODO);
                        </li>
                        <li>
                            w celu ewentualnego ustalenia i dochodzenia roszczeń
                            lub obrony przed roszczeniami – podstawą prawną
                            przetwarzania jest prawnie uzasadniony interes
                            Administratora (art. 6 ust. 1 lit. f RODO)
                            polegający na ochronie jego praw;
                        </li>
                        <li>
                            dotyczące działań Użytkownika w Platformie, w tym
                            informacje na temat przeglądanych przez niego
                            Restauracji i Produktów – podstawą prawną
                            przetwarzania tych danych jest uzasadniony interes
                            Administratora polegający na dostosowywaniu treści
                            do zainteresowań Użytkownika.
                        </li>
                    </ol>
                </li>
                <li>
                    Podanie danych przetwarzanych na podstawie zgody Użytkownika
                    jest dobrowolne, ale konieczne dla skorzystania z wybranej
                    funkcjonalności Konta Użytkownika. W pozostałym zakresie
                    podanie danych jest obligatoryjne do świadczenia usługi
                    Konta Użytkownika zgodnie z umową.
                </li>
            </ol>
            <h3>d. REALIZACJA ZAMÓWIEŃ</h3>
            <ol>
                <li>
                    Złożenie zamówienia na Platformie wiąże się z koniecznością
                    założenia Konta Użytkownika oraz podania danych niezbędnych
                    do realizacji Zamówienia tj. danych dotyczących sposobu
                    Odbioru (w szczególności adres dostawy) oraz danych
                    dotyczących płatności.
                </li>
                <li>
                    Dane osobowe są przetwarzane:
                    <ol>
                        <li>
                            w celu realizacji i obsługi złożonego Zamówienia –
                            podstawą prawną przetwarzania jest niezbędność
                            przetwarzania do wykonania umowy (art. 6 ust. 1 lit.
                            b RODO); w zakresie danych podanych fakultatywnie
                            podstawą prawną przetwarzania jest zgoda (art. 6
                            ust. 1 lit. a RODO);
                        </li>
                        <li>
                            w celu realizacji obowiązków ustawowych ciążących na
                            Administratorze, wynikających w szczególności z
                            przepisów podatkowych i przepisów o rachunkowości
                            oraz innych przepisów prawa mających zastosowanie –
                            podstawą prawną przetwarzania jest obowiązek prawny
                            (art. 6 ust. 1 lit. c RODO);
                        </li>
                        <li>
                            w celu ewentualnego ustalenia i dochodzenia roszczeń
                            lub obrony przed roszczeniami, a także w celu
                            zapobiegania i przeciwdziałania nadużyciom –
                            podstawą prawną przetwarzania jest prawnie
                            uzasadniony interes Administratora (art. 6 ust. 1
                            lit. f RODO) polegający na ochronie jego praw;
                        </li>
                    </ol>
                </li>
                <li>
                    Podanie danych jest konieczne do realizacji Zamówienia, a
                    ich brak uniemożliwi jego realizację.
                </li>
            </ol>
            <h3>e. CZAT</h3>
            <ol>
                <li>
                    Administrator zapewnia możliwość kontaktu za pośrednictwem
                    czatu na Platformie. W takiej sytuacji Administrator
                    przetwarza wyłącznie takie dane, które Podmiot danych
                    dobrowolnie poda w swojej wiadomości.
                </li>
                <li>
                    Podanie danych jest dobrowolne, ale może być konieczne dla
                    prawidłowej obsługi zapytania. Dane są przetwarzane na
                    podstawie prawnie uzasadnionego interesu Administratora
                    (art. 6 ust. 1 lit. f RODO), jakim jest umożliwienie osobom
                    zainteresowanym kontaktu z Administratorem
                </li>
            </ol>
            <h3>f. FORMULARZ</h3>
            <ol>
                <li>
                    Administrator zapewnia możliwość kontaktu za pośrednictwem
                    formularza kontaktowego na Platformie. Skorzystanie z
                    formularza kontaktowego wymaga podania danych niezbędnych do
                    nawiązania kontaktu i udzielenia odpowiedzi na przesłane
                    zapytanie. Niepodanie tych danych skutkuje brakiem
                    możliwości skorzystania z formularza kontaktowego.
                </li>
                <li>
                    Dane osobowe są przetwarzane w celu identyfikacji nadawcy
                    oraz obsługi jego zapytania na podstawie prawnie
                    uzasadnionego interesu Administratora (art. 6 ust. 1 lit. f
                    RODO), jakim jest zapewnienie możliwości kontaktu osobom
                    zainteresowanym. Dane zawarte w korespondencji Administrator
                    może przetwarzać także w celu zabezpieczenia swoich praw w
                    ramach dochodzenia lub obrony przed roszczeniami.
                </li>
            </ol>
            <h3>g. MARKETING</h3>
            <ol>
                <li>
                    Dane osobowe Użytkownika mogą być przetwarzane przez
                    Administratora w celu kierowania do niego treści
                    marketingowych kanałami komunikacji, na które Użytkownik
                    wyraził zgodę. Podanie danych jest dobrowolne, a udzielona
                    zgoda może być w każdej chwili cofnięta bez wpływu na
                    zgodność z prawem przetwarzania, którego dokonano przed jej
                    wycofaniem.
                </li>
                <li>
                    Administrator przetwarza dane osobowe Użytkowników także w
                    związku z kierowaniem reklamy kontekstowej (tj. reklamy
                    niedopasowanej do indywidualnych preferencji Użytkownika).
                    Podstawą przetwarzania danych jest uzasadniony interes
                    Administratora polegający na realizacji działań
                    marketingowych wobec klientów.
                </li>
                <li>
                    Administrator może kierować do Użytkowników reklamę
                    behawioralną dopasowaną do indywidualnych preferencji
                    Użytkownika na podstawie ich profilowania. Podstawą
                    przetwarzania danych jest uzasadniony interes Administratora
                    polegający na prezentacji treści dopasowanych do
                    zainteresowań Użytkowników.
                </li>
            </ol>
            <h2>IV. ŹRÓDŁO DANYCH</h2>
            <p>
                Dane osobowe pozyskujemy bezpośrednio od Użytkownika w związku z
                korzystaniem przez niego z Platformy i jej funkcjonalności.
            </p>
            <p>
                Źródłem danych na wyraźne polecenie Użytkownika może portal
                społecznościowy, gdy Użytkownik korzysta z funkcji
                uwierzytelnienia na Platformie za pomocą swojego konta w
                zewnętrznym portalu.
            </p>
            <h2>V. OKRES PRZETWARZANIA DANYCH OSOBOWYCH</h2>
            <ol>
                <li>
                    Okres przetwarzania danych jest zależny od rodzaju
                    świadczonej usługi, celu przetwarzania oraz podstawy
                    prawnej:
                    <ol>
                        <li>
                            w przypadku, gdy Administrator przetwarza dane
                            osobowe <strong>na podstawie zgody</strong>, okres
                            przetwarzania trwa do momentu wycofania tej zgody
                            lub ustania celu przetwarzania, na który zgoda
                            została wyrażona – w zależności od tego, które
                            zdarzenie nastąpi wcześniej;
                        </li>
                        <li>
                            w przypadku, gdy dane osobowe są przetwarzane na{' '}
                            <strong>
                                podstawie uzasadnionego interesu Administratora
                                danych
                            </strong>
                            , okres przetwarzania trwa do momentu ustania ww.
                            interesu (np. okres przedawnienia roszczeń
                            cywilnoprawnych) lub do momentu skutecznego
                            sprzeciwienia się osoby, której dane dotyczą,
                            dalszemu takiemu przetwarzaniu – w sytuacjach, gdy
                            sprzeciw taki zgodnie z przepisami prawa
                            przysługuje;
                        </li>
                        <li>
                            w przypadku, gdy podstawą przetwarzania Danych
                            osobowych są{' '}
                            <strong>
                                ciążące na Administratorze obowiązki prawne
                            </strong>
                            , wówczas Dane osobowe są przetwarzane do czasu
                            wygaśnięcia obowiązków prawnych, w szczególności
                            dotyczących przechowywania dokumentów księgowych.
                        </li>
                        <li>
                            w przypadku, gdy Administrator przetwarza dane
                            osobowe <strong>na podstawie umowy</strong>, wówczas
                            dane osobowe są przetwarzane przez okres niezbędny
                            do jej realizacji; okres przetwarzania danych może
                            być przedłużony w przypadku, gdy przetwarzanie jest
                            niezbędne do ustalenia i dochodzenia ewentualnych
                            roszczeń lub obrony przed roszczeniami, a po tym
                            czasie jedynie w przypadku i w zakresie, w jakim
                            będą wymagać tego przepisy prawa.
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>VI. ODBIORCY DANYCH</h2>
            <ol>
                <li>
                    W związku z prowadzeniem Platformy oraz realizacją
                    świadczonych usług Administrator danych korzysta z usług
                    podmiotów zewnętrznych wspierających go w prowadzeniu jego
                    działalności, w tym przekazuje i udostępnia im Dane osobowe
                    Użytkowników.
                </li>
                <li>
                    Podmioty zewnętrzne, którym Administrator przekazuje i
                    udostępnia Dane osobowe Użytkowników to:
                    <ol>
                        <li>Restauratorzy;</li>
                        <li>
                            podmioty zapewniające wsparcie techniczne, w tym
                            hosting danych;
                        </li>
                        <li>
                            podmioty zapewniające realizację i obsługę
                            płatności, w tym banki i dostawcy usług płatniczych;
                        </li>
                        <li>
                            podmioty wspierające Administratora w prowadzonej
                            działalności, w tym biuro rachunkowe, kancelaria
                            prawna, pomoc techniczna IT, dostawcy oprogramowania
                            do zarządzania firmą;
                        </li>
                        <li>
                            podmioty oferujące narzędzia dotyczące analityki
                            Platoformy;
                        </li>
                        <li>
                            podmioty oferujące narzędzia do masowego mailingu;
                        </li>
                        <li>
                            podmioty świadczące usługi windykacji należności.
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>VII. PRZEKAZYWANIE DANYCH DO PAŃSTW TRZECICH</h2>
            <ol>
                <li>
                    Administrator przy prowadzeniu działalności współpracuje z
                    podmiotami przetwarzającymi dane poza Europejskim Obszarem
                    Gospodarczym.
                </li>
                <li>
                    Transfer Danych osobowych odbywa się wyłącznie, gdy jest to
                    konieczne i z zapewnieniem odpowiedniego stopnia ochrony
                    poprzez:
                    <ol>
                        <li>
                            współpracę z podmiotami przetwarzającymi dane
                            osobowe w państwach, w odniesieniu do których
                            została wydana stosowna decyzja Komisji
                            Europejskiej.
                        </li>
                        <li>
                            stosowanie standardowych klauzul umownych wydanych
                            przez Komisję Europejską;
                        </li>
                        <li>
                            stosowanie wiążących reguł korporacyjnych
                            zatwierdzonych przez właściwy organ nadzorczy;
                        </li>
                    </ol>
                </li>
                <li>
                    Podmiot danych może uzyskać kopię stosowanych przez
                    Administratora zabezpieczeń dotyczących transferu Danych
                    osobowych do państwa trzeciego kontaktując się z
                    Administratorem.
                </li>
            </ol>
            <h2>VIII. PRAWA PODMIOTÓW DANYCH</h2>
            <ol>
                <li>
                    W zakresie wskazanym przez obowiązujące przepisy prawa,
                    Użytkownikowi w związku z przetwarzaniem przez
                    Administratora jego danych osobowych przysługują następujące
                    prawa:
                    <ol>
                        <li>
                            <strong>prawo dostępu do danych</strong> — prawo do
                            uzyskania informacji o przetwarzaniu danych
                            osobowych, w tym m.in. o celach i podstawach
                            prawnych przetwarzania, zakresie posiadanych danych,
                            podmiotach, którym dane osobowe są ujawniane i
                            planowanym terminie ich usunięcia, a także prawo do
                            otrzymania kopii przetwarzanych danych na podstawie
                            art. 15 RODO
                        </li>
                        <li>
                            <strong>prawo do sprostowania danych</strong> —
                            prawo do żądania poprawienia lub zaktualizowania
                            danych osobowych, jeśli są one nieaktualne,
                            niedokładne lub niepełne na podstawie art. 16 RODO.
                        </li>
                        <li>
                            <strong>prawo do usunięcia danych</strong> — prawo
                            do żądania usunięcia danych w przypadkach i na
                            zasadach określonych w art. 17 RODO.
                        </li>
                        <li>
                            <strong>
                                prawo do ograniczenia przetwarzania danych
                            </strong>{' '}
                            — prawo do żądania zaprzestania przez Administratora
                            danych tymczasowo lub na stałe przetwarzania
                            wszystkich lub części danych osobowych w sytuacjach
                            i na zasadach określonych w art. 18 RODO
                        </li>
                        <li>
                            <strong>prawo do przenoszenia danych</strong> —
                            prawo do otrzymania w ustrukturyzowanym, powszechnie
                            używanym formacie nadającym się do odczytu
                            maszynowego danych osobowych, które zostały
                            Administratorowi dostarczone oraz prawo żądania
                            przesłania tych danych osobowych innemu
                            administratorowi na podstawie art. 20 RODO.
                        </li>
                        <li>
                            <strong> prawo do sprzeciwu</strong> - prawo do
                            wniesienia sprzeciwu wobec przetwarzania danych
                            osobowych w sytuacjach i na zasadach określonych w
                            art. 21 RODO.
                        </li>
                        <li>
                            <strong>
                                prawo do wycofania zgody na przetwarzanie danych
                            </strong>{' '}
                            w dowolnym momencie bez wpływu na zgodność z prawem
                            przetwarzania dokonanego przed jej wycofaniem.
                        </li>
                        <li>
                            <strong>
                                prawo do wniesienia skargi do organu nadzorczego
                            </strong>{' '}
                            - w przypadku uznania, że przetwarzanie danych
                            osobowych narusza przepisy RODO lub inne przepisy
                            dotyczące ochrony danych osobowych, Podmiot danych
                            może złożyć skargę do Prezesa Urzędu Ochrony Danych
                            Osobowych.
                        </li>
                    </ol>
                </li>
                <li>
                    W celu złożenia wniosku dotyczącego realizacji praw należy
                    skontaktować się z Administratorem na adres poczty
                    tradycyjnej lub elektronicznej.
                </li>
            </ol>
            <h2>IX. PROFILOWANIE</h2>
            <ol>
                <li>
                    W celu realizowania działań marketingowych i lepszego
                    dopasowania oferty Administrator wykorzystuje profilowanie
                    tj. w oparciu o zautomatyzowane przetwarzanie danych
                    Administrator dokonuje oceny wybranych czynników dotyczących
                    Użytkowników w celu prognozy ich preferencji.
                </li>
                <li>
                    Administrator nie wykorzystuje danych osobowych w celu
                    zautomatyzowanego podejmowania decyzji (w tym profilowania)
                    w sposób, który wywoływałby skutki prawne lub w podobny
                    sposób istotnie wpływał na Użytkownika.
                </li>
            </ol>
            <h2>X. PLIKI COOKIES I PODOBNE TECHNOLOGIE</h2>
            <ol>
                <li>
                    Na Platformie Administrator wykorzystuje technologię
                    przechowującą lub uzyskującą dostęp do informacji na
                    komputerze bądź innym urządzeniu Użytkownika podłączonym do
                    sieci (pliki cookies lub inne podobne technologie).
                </li>
                <li>
                    Korzystanie z plików cookies i podobnych technologii wymaga
                    uzyskania od Użytkownika zgody. Zgoda może być w każdym
                    momencie wycofana.
                </li>
                <li>
                    Zezwolenie nie jest wymagane wyłącznie w przypadku plików
                    cookies i podobnych technologii, których stosowanie jest
                    niezbędne do świadczenia usługi telekomunikacyjnej lub
                    usługi wyraźnie zażądanej przez Użytkownika.
                </li>
                <li>
                    Podczas pierwszej wizyty na Platformie wyświetlana jest
                    informacja na temat stosowania plików cookies. Brak zmiany
                    ustawień przeglądarki jest równoznaczny z wyrażeniem zgody
                    na ich użycie.
                </li>
                <li>
                    Użytkownik ma prawo do zmiany ustawień plików cookies z
                    poziomu swojej przeglądarki albo ich usunięcia.
                </li>
                <li>
                    Administrator wykorzystuje poszczególne rodzaje plików
                    cookies w następujących celach:
                    <ol>
                        <li>
                            Cookies „serwisowe” – niewymagające zgody, niezbędne
                            do transmisji danych lub wykonania usługi wyraźnie
                            żądanej przez Użytkownika, w tym:
                            <ol>
                                <li>
                                    Cookies służące do personalizacji
                                    interfejsu,
                                </li>
                                <li>
                                    Cookies z danymi wprowadzonymi przez
                                    Użytkownika (user input cookies),
                                </li>
                                <li>
                                    Cookies służące do uwierzytelnienia
                                    Użytkownika na Platformie,
                                </li>
                                <li>
                                    Cookies służące do zapewnienia
                                    bezpieczeństwa usługi żądanej przez
                                    Użytkownika,
                                </li>
                                <li>
                                    Cookies odtwarzaczy multimedialnych, gdy są
                                    konieczne do odtworzenia treści,
                                </li>
                                <li>
                                    Cookies wtyczek portali społecznościowych
                                    służących do wymiany treści w odniesieniu do
                                    zalogowanych Użytkowników
                                </li>
                            </ol>
                        </li>
                        <li>
                            Cookies „marketingowe” – wymagają zgody Użytkownika,
                            służą wyświetlaniu Użytkownikowi na Platformie
                            reklam kontekstowych Administratora; Platforma
                            wykorzystuje pliki cookies Google Adwords, które
                            pozwalają na wyświetlanie reklam osobom, które
                            odwiedziły Platformę. Informacje na temat
                            przetwarzania danych przez Google w zakresie
                            powyższej usługi są dostępne pod linkiem:{' '}
                            <a
                                href="https://policies.google.com/technologies/ads?hl=pl"
                                tagert="_blank"
                                rel="noreferrer noopenner"
                            >
                                https://policies.google.com/technologies/ads?hl=pl
                            </a>
                        </li>
                        <li>
                            Cookies „analityczne” – pliki cookies Google
                            Analytics dostarczone przez spółkę Google do analizy
                            sposobu korzystania z Platformy przez Użytkownika,
                            tworzenia statystyk i raportów dotyczących
                            funkcjonowania Platformy
                        </li>
                    </ol>
                </li>
            </ol>
            <h2>XI. POSTANOWIENIA KOŃCOW</h2>
            <ol>
                <li>Niniejsza Polityka obowiązuje od dnia 19.03.202</li>
                <li>
                    Zastrzegamy sobie prawo do bieżącej aktualizacji tekstu
                    niniejszej Polityki. Aktualna treść jest zawsze dostępna na
                    Platformie.
                </li>
            </ol>
        </div>
    );
};

export default Privacy;
