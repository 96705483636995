import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { useParams } from 'react-router-dom';
import SingleOrderMenuButton from './views/SingleOrderMenuButton';
import PageLoader from '@components/PageLoader/PageLoader';
import {
    sOuter,
    sNoMarginTop,
    sGrid,
    sPaddingTop,
    sBottomLinks,
} from '@style/style';
import { Bullet, ButtonBorder, Collapsable } from '@components/Elements';
import { colors, s, alpha } from '@style';
import Headline from '@components/Headline/Headline';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import RestContainer from '@/EZC/views/ProductPage/components/RestContainer';
import { StatusOrder, SectionHeadline } from '@components/Elements';
import OrderAddress from './components/OrderAddress';
import OrderDate from './components/OrderDate';
import OrderInvoice from './components/OrderInvoice';
import OrderNote from './components/OrderNote';
import OrderPaymentLarge from './components/OrderPaymentLarge';
import OrderID from './components/OrderID';
import OrderCanceled from './components/OrderCanceled';
import OrderStatuses from './components/OrderStatuses';
import ButtonCancel from './components/ButtonCancel';
import StatusGuide from '@components/StatusGuide/StatusGuide';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import {
    useOrderActivePageQuery,
    useNotifyArrivalMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { ORDER_STATUS } from '@constants/fromGraphql/OrderStatus.constants';
import { dateFormat } from '@helpers/dateFormat';
import styled from '@emotion/styled';
import { mediaQueries, mediaQueriesMaterial } from '@style/breakpoints';
import { ProductTileSplitOrder } from '@/EZC/domains/Orders/organisms/ProductTileSplitOrder/ProductTileSplitOrder';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import OrderChat from './components/OrderChat';
import { OrderChatHistory } from './components/OrderChatHistory';
import { useFirebaseChatService } from '@/services/FirebaseChatService/FirebaseChatService';
import { UserFriendInfo } from '@/EZC/domains/Account/molecules/UserFriendInfo/UserFriendInfo';
import { GroupIcon } from '@style/assets/GroupIcon';
import PersonIcon from '@material-ui/icons/Person';
import { CollapsableText } from '@components/Elements/Collapsable/Collapsable';
import { OrderType } from '@/componentsV2/molecules/OrderType/OrderType';
import { DeliveryOrderTime } from '@/EZC/domains/Orders/molecules/DeliveryOrderTime/DeliveryOrderTime';
import { usePayoffRequests } from '@/EZC/hooks/usePayoffRequests';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import { popup } from '@/AppPopups';
import { OrderServingTime } from '@/componentsV2/molecules/OrderServingTime/OrderServingTime';
import { OrderPhone } from '@/componentsV2/molecules/OrderPhone/OrderPhone';
import { ButtonBorder as ButtonBorderV2 } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { QrIcon } from '@/svgs/QrIcon';
import QRCode from 'react-qr-code';

export const OrderPage = () => {
    const userId = useFirebaseChatService((state) => state.userId);
    const orderId = useParams().id;

    const [isCancelWidgetOpen, setIsCancelWidgetOpen] = useState(false);
    const [isStatusGuideOpen, setIsStatusGuideOpen] = useState(false);

    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));

    const { data, error, loading } = useOrderActivePageQuery({
        fetchPolicy: 'cache-and-network',
        onError: graphqlErrorHandler(true),
        variables: { input: { orderId } },
    });
    const [notifyArrival, { loading: notifyArrivalLoading }] =
        useNotifyArrivalMutation();

    const { bottomNavigation } = useSafeAreaInsets();

    const restaurant = data?.order.restaurant;
    const {
        delivery,
        group,
        id,
        items,
        shortId,
        status,
        statusLog,
        details: orderDetails,
        owner,
    } = data?.order || {};

    useEffect(() => {
        if (data?.order.status === 'PARTIAL_ACCEPTANCE') {
            popup.show('AcceptOrderChangesPopup', {
                orderId: data?.order.id,
            });
        }
    }, [data?.order.status]);

    const { deliveryAddress, deliveryCost, deliveryDetails } = delivery || {};
    const priceSum =
        status === 'REJECTED'
            ? 0
            : items?.reduce(
                  (acc, item) =>
                      item.status !== 'CANCELED'
                          ? acc + item.product.price
                          : acc + 0,
                  0,
              );
    const groupUsers = group?.members
        ? [owner, ...group.members].map((user) => ({
              id: user.id,
              email: user.email,
              avatar: user.personal.avatarUrl,
              name: `${user.personal.name} ${user.personal.surname}`.trim(),
          }))
        : [];
    const groupUsersWithoutOwner = groupUsers.filter(
        (user) => user.id !== owner.id,
    );

    const {
        assignedItemsPrice,
        groupedItemsByProduct,
        handleCancel,
        handleSave,
        payoffRequestsLoading,
        payoffRequestsSend,
    } = usePayoffRequests({
        items,
        users: groupUsersWithoutOwner,
    });

    const addMoreAction =
        (orderDetails?.type !== 'IN' && status === 'VERIFICATION') ||
        (orderDetails?.type === 'IN' &&
            (status === 'ACCEPTED' ||
                status === 'PREPARING' ||
                status === 'COMPLETED'));

    const packageCost =
        orderDetails?.type === 'IN'
            ? 0
            : status === 'REJECTED'
              ? 0
              : items?.reduce(
                    (sum, curr) =>
                        curr.status === 'CANCELED'
                            ? 0 + sum
                            : curr.product.packagingPrice + sum,
                    0,
                );
    const isOwner = userId === owner?.id;

    const deliveryCostFinal =
        status === 'REJECTED' || status === 'CANCELED' ? 0 : deliveryCost;

    const verificationStatusDate = statusLog?.find(
        (item) => item.status === 'VERIFICATION',
    ).date;

    if (loading) return <PageLoader />;

    if (error) {
        return (
            <React.Fragment>
                <TopNav title={'Zamówienie'} subtitle={` - nieodnalezione`} />
                <div css={sOuter}>
                    <Headline>Nieodnaleziono</Headline>
                    <Grid container css={sGrid}>
                        <Grid item xs={12} css={sBottomLinks}>
                            <ButtonBorder link={'/orders'}>
                                Zamówienia
                            </ButtonBorder>
                            <ButtonBorder link={'/orders-history'}>
                                Historia Zamówień
                            </ButtonBorder>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TopNav
                title={restaurant?.name}
                subtitle={` - ${shortId}`}
                photo={restaurant?.avatarUrl}
                textCss={{
                    [s.xs]: { maxWidth: `calc(100vw - 9rem)` },
                }}
            >
                {data?.order?.details?.type === 'TAKEAWAY' && (
                    <QrButton
                        width="100%"
                        onClick={() =>
                            popup.show('QrCodePopup', { qrCodeData: id })
                        }
                    >
                        <Wrapper>
                            <QrIcon /> QR kod
                        </Wrapper>
                    </QrButton>
                )}
            </TopNav>
            <div css={sOuter}>
                <OrderHeadline
                    itemChildren={
                        <div
                            css={{ cursor: 'pointer' }}
                            onClick={() =>
                                setIsStatusGuideOpen(!isStatusGuideOpen)
                            }
                        >
                            <StatusOrder
                                color={
                                    status === 'COMPLETED' &&
                                    orderDetails?.type === 'DELIVERY'
                                        ? ORDER_STATUS[status]?.delivery?.color
                                        : ORDER_STATUS[status]?.color
                                }
                            >
                                {status === 'COMPLETED' &&
                                orderDetails?.type === 'DELIVERY'
                                    ? ORDER_STATUS[status]?.delivery?.name
                                    : ORDER_STATUS[status]?.name}
                            </StatusOrder>
                        </div>
                    }
                >
                    Zamówienie
                    <OrderId> {shortId}</OrderId>
                    {data?.order?.details?.type === 'TAKEAWAY' &&
                        !isSm &&
                        status !== 'CLOSED' &&
                        status !== 'VERIFICATION' && (
                            <>
                                <Description>
                                    Pokaż QR kod obsłudze przy odbiorze
                                    zamówienia.
                                </Description>
                                <QrContainer>
                                    <QRCode value={id} />
                                </QrContainer>
                            </>
                        )}
                    <OrderDateHeadline>
                        {dateFormat.standardDate(statusLog[0]?.date)}
                    </OrderDateHeadline>
                    {!isOwner && userId && (
                        <OrderOwnerInfoWrapper>
                            <GroupIcon size="16px" color={colors.gray600} />
                            <DescriptionWrapper>
                                Grupowe
                                <StyledBullet />
                                {`${owner?.personal?.name} ${owner?.personal?.surname}`}
                            </DescriptionWrapper>
                        </OrderOwnerInfoWrapper>
                    )}
                </OrderHeadline>
                {isOwner &&
                    (status === 'ACCEPTED' || status === 'PREPARING') &&
                    orderDetails?.type === 'IN' && (
                        <ReportIncomingButtonWrapper>
                            <Button
                                variant={'black'}
                                width={'100%'}
                                disabled={
                                    dateFormat.calculateRemainingHours(
                                        orderDetails.date,
                                        new Date(),
                                    ) > 1
                                }
                                onClick={() => {
                                    notifyArrival({
                                        variables: { input: { orderId } },
                                        onError: graphqlErrorHandler(true),
                                    });
                                }}
                                loading={notifyArrivalLoading}
                            >
                                Zgłoś przybycie
                            </Button>
                        </ReportIncomingButtonWrapper>
                    )}
                {(status === 'PREPARING' ||
                    status === 'PREPARED' ||
                    status === 'DELIVERY') &&
                    (orderDetails?.type === 'DELIVERY' ||
                        orderDetails?.type === 'TAKEAWAY') && (
                        <DeliveryInfoWrapper container css={sGrid}>
                            <DeliveryOrderTime
                                deliveryTime={delivery?.eta}
                                orderDoneTime={
                                    statusLog?.find(
                                        (item) => item?.status === status,
                                    )?.date
                                }
                                loading={loading}
                                status={status}
                                isTakeaway={orderDetails?.type === 'TAKEAWAY'}
                            />
                        </DeliveryInfoWrapper>
                    )}
                {status === 'ACCEPTED' &&
                    (orderDetails?.type === 'DELIVERY' ||
                        orderDetails?.type === 'TAKEAWAY') && (
                        <Grid container css={sGrid}>
                            <OrderInfo>
                                Twoje zamówienie zostanie{' '}
                                {orderDetails?.type === 'DELIVERY'
                                    ? 'dostarczone'
                                    : 'przygotowane'}{' '}
                                <strong>
                                    {dateFormat.orderDateLong(
                                        orderDetails.date,
                                    )}
                                </strong>{' '}
                                o godzinie{' '}
                                <strong>
                                    {dateFormat.standardTime(orderDetails.date)}
                                </strong>
                            </OrderInfo>
                        </Grid>
                    )}
            </div>

            {(status === 'CANCELED' || status === 'REJECTED') && (
                <div css={[sOuter, sNoMarginTop]}>
                    <Grid container css={sGrid}>
                        <OrderCanceled
                            name={restaurant?.name}
                            shortId={shortId}
                            orderType={orderDetails?.type}
                            status={status}
                        />
                    </Grid>
                </div>
            )}

            {userId ? (
                <div css={[sOuter, sNoMarginTop]}>
                    <Grid container css={sGrid}>
                        {isOwner ? (
                            status === 'REJECTED' ||
                            status === 'CANCELED' ||
                            status === 'CLOSED' ? (
                                <OrderChatHistory
                                    orderId={id}
                                    userId={userId}
                                    left
                                />
                            ) : (
                                <OrderChat
                                    orderId={id}
                                    name={restaurant?.name}
                                    avatarUrl={restaurant?.avatarUrl}
                                />
                            )
                        ) : (
                            <></>
                        )}

                        <OrderStatuses
                            left
                            statuses={statusLog}
                            open={isStatusGuideOpen}
                            setOpen={setIsStatusGuideOpen}
                        />
                        {group && (
                            <Grid item xs={12} md={6}>
                                {/* @ts-expect-error migrate to TS */}
                                <Collapsable
                                    left={isOwner}
                                    right={!isOwner}
                                    noPadding
                                    oneLiner
                                    title={'Zamówienie grupowe'}
                                    icon={<GroupIcon />}
                                    description={
                                        <DescriptionWrapper>
                                            Grupa
                                            <GroupName>{group?.name}</GroupName>
                                            <StyledBullet />
                                            {group?.members?.length + 1}
                                            <PersonIcon
                                                fontSize="inherit"
                                                css={{ marginLeft: '0.4px' }}
                                            />
                                        </DescriptionWrapper>
                                    }
                                >
                                    <CollapsableText>
                                        <FriendsWrapper>
                                            {groupUsers?.map((user) => {
                                                return (
                                                    <UserFriendInfo
                                                        key={user?.id}
                                                        {...user}
                                                    />
                                                );
                                            })}
                                        </FriendsWrapper>
                                    </CollapsableText>
                                </Collapsable>
                            </Grid>
                        )}
                    </Grid>
                </div>
            ) : (
                <Grid container css={sGrid}>
                    <SkeletonOrder />
                    <SkeletonOrder />
                </Grid>
            )}
            <div css={[sOuter, sNoMarginTop, sGridSpacingParent]}>
                <Grid
                    container
                    css={[sGrid, sGridSpacing]}
                    spacing={3}
                    justifyContent="space-between"
                >
                    <SectionHeadline>Produkty</SectionHeadline>
                    {Object.values(groupedItemsByProduct)?.map(
                        ({ groupedItems }, index) => {
                            return orderDetails?.type !== 'IN' ? (
                                groupedItems?.filter(
                                    (item) => item?.status !== 'CANCELED',
                                ).length > 0 && (
                                    <Grid item xs={12} md={6} key={id + index}>
                                        <ProductTileSplitOrder
                                            key={id}
                                            onSave={handleSave}
                                            onCancel={handleCancel}
                                            showItemsOneByOne={false}
                                            showSplitPayment={
                                                status !== 'PENDING' &&
                                                status !== 'REJECTED' &&
                                                isOwner &&
                                                status !== 'VERIFICATION' &&
                                                status !== 'SENT_TO_RESTAURANT'
                                            }
                                            users={groupUsersWithoutOwner}
                                            groupedItems={groupedItems?.filter(
                                                (item) =>
                                                    item?.status !== 'CANCELED',
                                            )}
                                            loading={payoffRequestsLoading}
                                        />
                                    </Grid>
                                )
                            ) : (
                                <Grid item xs={12} md={6} key={id + index}>
                                    <ProductTileSplitOrder
                                        key={id}
                                        onSave={handleSave}
                                        onCancel={handleCancel}
                                        showItemsOneByOne={
                                            orderDetails &&
                                            orderDetails.type === 'IN'
                                        }
                                        showSplitPayment={
                                            status !== 'PENDING' &&
                                            status !== 'REJECTED' &&
                                            isOwner &&
                                            status !== 'VERIFICATION' &&
                                            status !== 'SENT_TO_RESTAURANT'
                                        }
                                        users={groupUsersWithoutOwner}
                                        groupedItems={groupedItems}
                                        loading={payoffRequestsLoading}
                                    />
                                </Grid>
                            );
                        },
                    )}
                </Grid>
            </div>

            <div
                css={[
                    sOuter,
                    sNoMarginTop,
                    sGridSpacingParent,
                    { paddingBottom: 50, gap: '16px', display: 'grid' },
                ]}
            >
                <Grid
                    container
                    css={[sGrid, sGridSpacing]}
                    spacing={3}
                    justifyContent="space-between"
                >
                    <SectionHeadline>Szczegóły</SectionHeadline>
                    <OrderType
                        consumptionType={orderDetails?.type}
                        tableNumber={orderDetails?.table}
                    />
                    {orderDetails?.type === 'DELIVERY' && (
                        <OrderAddress
                            deliveryAddress={deliveryAddress}
                            deliveryDetails={deliveryDetails}
                        />
                    )}
                    {orderDetails?.phone && (
                        <OrderPhone phone={orderDetails?.phone} />
                    )}
                    <OrderDate
                        consumptionDate={orderDetails.date}
                        consumptionPersons={orderDetails.guests}
                    />
                    {orderDetails?.type === 'IN' && (
                        <OrderServingTime
                            serveOnTime={orderDetails.serveOnTime}
                        />
                    )}
                    {orderDetails?.note && (
                        <OrderNote note={orderDetails?.note} />
                    )}
                    {orderDetails?.invoice && (
                        <OrderInvoice invoiceDetails={orderDetails?.invoice} />
                    )}
                    <OrderID shortId={shortId} orderId={id} />
                    <OrderPaymentLarge
                        productsCost={priceSum?.toFixed(
                            priceSum - deliveryCostFinal === 0 ? 0 : 2,
                        )}
                        packageCost={
                            orderDetails?.type === 'IN'
                                ? null
                                : packageCost?.toFixed(2)
                        }
                        priceSum={priceSum + deliveryCostFinal + packageCost}
                        paidSum={priceSum + deliveryCostFinal + packageCost}
                        deliveryCost={deliveryCostFinal}
                        isDelivery={orderDetails?.type === 'DELIVERY'}
                    />
                </Grid>

                <RestContainer data={restaurant} />
            </div>

            {status === 'VERIFICATION' && isOwner && (
                <div
                    css={[
                        sOuter,
                        sNoMarginTop,
                        sPaddingTop,
                        {
                            position: 'relative',
                            zIndex: 1000,
                            marginTop: -150,
                        },
                    ]}
                >
                    <Grid
                        container
                        css={[
                            sGrid,
                            {
                                gap: '2rem',
                                marginTop: '2rem',
                                marginBottom: '3.5rem',
                                [s.sm_down]: {
                                    flexDirection: 'column',
                                    gap: '1rem',
                                    marginBottom: '0.5rem',
                                },
                            },
                            orderDetails?.type !== 'IN' && {
                                paddingBottom: '35px',
                                marginTop: -30,
                            },
                        ]}
                    >
                        <ButtonCancel
                            orderId={orderId}
                            isCancelWidgetOpen={isCancelWidgetOpen}
                            setIsCancelWidgetOpen={setIsCancelWidgetOpen}
                            disabled={
                                orderDetails?.type === 'IN' &&
                                dateFormat.calculateHowManyMinutes(
                                    new Date(),
                                    verificationStatusDate,
                                ) <= 10
                            }
                        />
                    </Grid>
                </div>
            )}

            {addMoreAction && !assignedItemsPrice && isOwner && (
                <div
                    css={[
                        sButtonFixed,
                        { zIndex: isCancelWidgetOpen ? 999 : 1000 },
                        { bottom: bottomNavigation },
                    ]}
                >
                    <SingleOrderMenuButton
                        restId={restaurant.id}
                        orderId={id}
                    />
                </div>
            )}
            {!!assignedItemsPrice && (
                <SplitOrderConfirmWrapper
                    css={{
                        bottom: bottomNavigation,
                    }}
                >
                    <Button
                        onClick={payoffRequestsSend}
                        variant="greenGradient"
                        loading={payoffRequestsLoading}
                        css={{ left: '50%', transform: 'translateX(-50%)' }}
                    >
                        <SplitOrderConfirmText>
                            ROZLICZ ZAMÓWIENIE
                        </SplitOrderConfirmText>{' '}
                        {assignedItemsPrice.toFixed(2)} ZŁ
                    </Button>
                </SplitOrderConfirmWrapper>
            )}
            <StatusGuide
                open={isStatusGuideOpen}
                setOpen={setIsStatusGuideOpen}
            />
        </React.Fragment>
    );
};

const OrderDateHeadline = styled.p`
    color: ${colors.gray600};
    font-size: 15px;
    font-weight: 700;
    text-align: left;

    ${mediaQueriesMaterial.sm} {
        font-size: 13px;
        text-align: center;
    }
`;

const OrderHeadline = styled(Headline)`
    justify-content: space-between;
    align-items: center;

    ${mediaQueries.sm} {
        justify-content: center;
        padding: 32px 24px 24px;
        text-align: center;
        flex-direction: column;
    }
`;

const OrderInfo = styled.p`
    color: ${colors.gray600};
    font-size: 15px;
    padding-bottom: 24px;

    ${mediaQueries.sm} {
        text-align: center;
    }
`;

const OrderId = styled.p`
    display: inline;
    ${mediaQueriesMaterial.xs} {
        display: block;
        text-align: center;
    }
`;

const QrContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    padding-bottom: 32px;
    letter-spacing: 0.02em;
`;

const OrderOwnerInfoWrapper = styled.div`
    align-items: center;
    color: ${colors.gray600};
    display: flex;
    font-size: 12px;
    font-weight: normal;
    gap: 4px;
    justify-content: start;
    letter-spacing: normal;
    margin-top: 4px;

    ${s.sx} {
        justify-content: center;
    }
`;

const DeliveryInfoWrapper = styled(Grid)`
    margin-bottom: 24px;
    display: grid;
`;

const QrButton = styled(ButtonBorderV2)`
    white-space: nowrap;
`;

const FriendsWrapper = styled.div`
    display: grid;
    grid-gap: 24px;
`;

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 2px;
`;

const GroupName = styled.span`
    color: ${colors.text};
    margin-left: 2px;
`;

const DescriptionWrapper = styled.div`
    align-items: center;
    display: flex;
`;

const StyledBullet = styled(Bullet)`
    margin: 0 4px;
`;

const SplitOrderConfirmWrapper = styled.div`
    bottom: 72px;
    left: 50%;
    position: fixed;
    transform: translateX(-50%);
    z-index: 200;
    width: 100%;
`;

const SplitOrderConfirmText = styled.span`
    font-size: 11px;
    line-height: normal;
    letter-spacing: 0.33px;
`;

export const sGridSpacing = {
    '.MuiGrid-item': {
        [s.xs]: { paddingBottom: '4px !important' },
    },
};

export const sGridSpacingParent = {
    width: '100vw',
    overflow: 'hidden',

    [s.xs]: {
        '.MuiGrid-spacing-xs-3': {
            width: '100%',
            margin: 0,

            '& > .MuiGrid-item': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
};

export const sButtonFixed = {
    background: `linear-gradient(to right, ${colors.green400}, ${colors.green800})`,
    width: '100%',
    maxWidth: 720,
    // padding: '0 2rem',
    [s.sm_down]: {
        maxWidth: 'calc(100vw - 3rem)',
        padding: '0 1.5rem',
    },
    position: 'fixed',
    height: 48,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '1.5rem',
    transition: 'bottom 0.6s ease-in-out, filter 0.3s ease-out',
    boxShadow: `0px 8px 24px ${colors.green.concat(alpha[60])}`,
    zIndex: 200,
    bottom: 72,
    '&:hover': {
        [s.hover]: {
            filter: 'brightness(1.1)',
        },
    },
};

const ReportIncomingButtonWrapper = styled.div`
    padding: 0 32px;
    ${mediaQueriesMaterial.sm} {
        padding: 0 24px;
    }

    ${mediaQueriesMaterial.xs} {
        padding-bottom: 24px;
    }
`;
