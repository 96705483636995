import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { Checkbox, CheckboxProps } from '@material-ui/core';

interface ItemWithCheckboxProps
    extends Pick<
        CheckboxProps,
        'onChange' | 'name' | 'checked' | 'disabled' | 'indeterminate'
    > {
    children: React.ReactElement;
}

export const ItemWithCheckbox = ({
    children,
    onChange,
    name,
    checked,
    disabled,
    indeterminate = false,
}: ItemWithCheckboxProps) => {
    return (
        <Wrapper>
            <CheckboxWrapper>
                <Checkbox
                    indeterminate={indeterminate}
                    onChange={onChange}
                    name={name}
                    checked={checked}
                    disabled={disabled}
                />
            </CheckboxWrapper>
            <ChildrenWrapper>{children}</ChildrenWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.label`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const ChildrenWrapper = styled.div`
    display: grid;
    width: 100%;
`;

const CheckboxWrapper = styled.div`
    .MuiCheckbox-root {
        color: ${colors.gray400};
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: ${colors.orange};
    }
`;
