/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField';

export default function InputEmail(props) {
    return (
        <TextField
            type="email"
            {...props}
            css={{
                width: '100%',
                maxWidth: 360,
                minWidth: 320,
            }}
        />
    );
}
