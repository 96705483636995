import React from 'react';

export const QrCodeIcon = () => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_7860_52078)">
            <path
                d="M2 7.33333H7.33333V2H2V7.33333ZM3.33333 3.33333H6V6H3.33333V3.33333Z"
                fill="#A3A5B0"
            />
            <path
                d="M2 13.9998H7.33333V8.6665H2V13.9998ZM3.33333 9.99984H6V12.6665H3.33333V9.99984Z"
                fill="#A3A5B0"
            />
            <path
                d="M8.66663 2V7.33333H14V2H8.66663ZM12.6666 6H9.99996V3.33333H12.6666V6Z"
                fill="#A3A5B0"
            />
            <path d="M14 12.6665H12.6666V13.9998H14V12.6665Z" fill="#A3A5B0" />
            <path
                d="M9.99996 8.6665H8.66663V9.99984H9.99996V8.6665Z"
                fill="#A3A5B0"
            />
            <path d="M11.3333 10H10V11.3333H11.3333V10Z" fill="#A3A5B0" />
            <path
                d="M9.99996 11.3335H8.66663V12.6668H9.99996V11.3335Z"
                fill="#A3A5B0"
            />
            <path
                d="M11.3333 12.6665H10V13.9998H11.3333V12.6665Z"
                fill="#A3A5B0"
            />
            <path
                d="M12.6667 11.3335H11.3334V12.6668H12.6667V11.3335Z"
                fill="#A3A5B0"
            />
            <path
                d="M12.6667 8.6665H11.3334V9.99984H12.6667V8.6665Z"
                fill="#A3A5B0"
            />
            <path d="M14 10H12.6666V11.3333H14V10Z" fill="#A3A5B0" />
        </g>
        <defs>
            <clipPath id="clip0_7860_52078">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
