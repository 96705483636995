import { SafeArea } from 'capacitor-plugin-safe-area';
import { useEffect, useState } from 'react';

const BOTTOM_NAVIGATION_HEIGHT = 72;

export const useSafeAreaInsets = () => {
    const [insets, setInsets] = useState({
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        bottomNavigation: 0,
    });

    useEffect(() => {
        SafeArea.getSafeAreaInsets().then(({ insets }) => {
            setInsets({
                ...insets,
                bottomNavigation: BOTTOM_NAVIGATION_HEIGHT + insets.bottom / 2,
            });
        });
    }, []);

    return insets;
};
