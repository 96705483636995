import React from 'react';
import { PreferencesPopup } from '@/EZC/domains/Account/organisms/PreferencesPopup/PreferencesPopup';
import NiceModal, { NiceModalHocProps, useModal } from '@ebay/nice-modal-react';
import { CancelOrderOrProductPopup } from './EZW/components/organisms/CancelOrderOrProductPopup/CancelOrderOrProductPopup';
import { YouHaveBeenAddedToGroupPopup } from './EZC/domains/Account/organisms/YouHaveBeenAddedToGroupPopup/YouHaveBeenAddedToGroupPopup';
import { CancelOrderPopup } from './EZW/components/organisms/CancelOrderPopup/CancelOrderPopup';
import { WalletChargePopup } from './EZC/domains/Account/organisms/WalletChargePopup/WalletChargePopup';
import { SetDeliveryTimePopup } from './EZW/domains/Orders/organisms/SetDeliveryTimePopup/SetDeliveryTimePopup';
import { CancelProductPopup } from './EZW/components/organisms/CancelProductPopup/CancelProductPopup';
import { AcceptOrderChangesPopup } from './EZC/components/organisms/AcceptOrderChangesPopup/AcceptOrderChangesPopup';
import { MarkAllAsServedPopup } from './EZW/components/organisms/MarkAllAsServedPopup/MarkAllAsServedPopup';
import { AcceptCartPopup } from './EZC/components/organisms/AcceptCartPopup/AcceptCartPopup';
import { ProductRatingPopup } from '@/EZC/domains/Rating/organisms/ProductRatingPopup/ProductRatingPopup';
import { JoinToEatzonPopup } from './componentsV2/organisms/JoinToEatzonPopup/JoinToEatzonPopup';
import { QrCodePopup } from './EZC/domains/Orders/organisms/QrCodePopup/QeCodePopup';
import { SearchFilterIngredientsPopup } from './EZC/domains/Search/organisms/SearchFilterIngredientsPopup/SearchFilterIngredientsPopup';
import { FilterSettingsPopup } from './EZC/domains/Home/organisms/FilterSettingsPopup/FilterSettingsPopup';
import { SearchFilterPopup } from './EZC/domains/Search/organisms/SearchFilterPopup/SearchFilterPopup';
import { SearchFilterSecondPopup } from './EZC/domains/Search/organisms/SearchFilterSecondPopup/SearchFilterSecondPopup';
import { AddressDeletePopup } from './EZC/domains/Account/organisms/AddressDeletePopup/AddressDeletePopup';

const popups = [
    {
        component: PreferencesPopup,
        id: 'PreferencesPopup',
    },
    {
        //@ts-expect-error FIXME
        component: CancelOrderOrProductPopup,
        id: 'CancelOrderOrProductPopup',
    },
    {
        //@ts-expect-error FIXME
        component: YouHaveBeenAddedToGroupPopup,
        id: 'YouHaveBeenAddedToGroupPopup',
    },
    {
        component: CancelOrderPopup,
        id: 'CancelOrderPopup',
    },
    {
        //@ts-expect-error FIXME
        component: WalletChargePopup,
        id: 'WalletChargePopup',
    },
    {
        //@ts-expect-error FIXME
        component: SetDeliveryTimePopup,
        id: 'SetDeliveryTimePopup',
    },
    {
        //@ts-expect-error FIXME
        component: CancelProductPopup,
        id: 'CancelProductPopup',
    },
    {
        //@ts-expect-error FIXME
        component: AcceptOrderChangesPopup,
        id: 'AcceptOrderChangesPopup',
    },
    {
        //@ts-expect-error FIXME
        component: MarkAllAsServedPopup,
        id: 'MarkAllAsServedPopup',
    },
    {
        //@ts-expect-error FIXME
        component: AcceptCartPopup,
        id: 'AcceptCartPopup',
    },
    {
        //@ts-expect-error FIXME
        component: ProductRatingPopup,
        id: 'ProductRatingPopup',
    },
    {
        component: JoinToEatzonPopup,
        id: 'JoinToEatzonPopup',
    },
    { component: FilterSettingsPopup, id: 'FilterSettingsPopup' },
    {
        //@ts-expect-error FIXME
        component: QrCodePopup,
        id: 'QrCodePopup',
    },
    {
        //@ts-expect-error FIXME
        component: SearchFilterPopup,
        id: 'SearchFilterPopup',
    },
    {
        //@ts-expect-error FIXME
        component: SearchFilterSecondPopup,
        id: 'SearchFilterSecondPopup',
    },
    {
        //@ts-expect-error FIXME
        component: SearchFilterIngredientsPopup,
        id: 'SearchFilterIngredientsPopup',
    },
    {
        component: AddressDeletePopup,
        id: 'AddressDeletePopup',
    },
] as const satisfies {
    id: string;
    component: React.FC<NiceModalHocProps>;
}[];

/**
 * @example popup.show('ExamplePopup');
 * @example popup.show('ExamplePopup', { exampleProp: 'text' } as ExamplePopupProps);
 * @example popup.remove('ExamplePopup')
 *
 * @description Do not use NiceModal directly!
 *
 * TODO: add strict typed `show` method and `modal` arg
 */
export const popup = NiceModal satisfies typeof NiceModal;

/**
 * @description Do not use useModal directly!
 *
 * TODO: add strict typed `show` method and `modal` arg
 */
export const usePopup = useModal satisfies typeof useModal;

export type PopupsIds = (typeof popups)[number]['id'];

interface AppPopupsProps {
    children: React.ReactNode;
}

popups.forEach((popup) => {
    NiceModal.register(popup.id, popup.component);
});

export const AppPopups = ({ children }: AppPopupsProps) => {
    return <NiceModal.Provider>{children}</NiceModal.Provider>;
};
