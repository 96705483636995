import React from 'react';

export const ForkKnifeIcon = () => (
    <svg
        width="25"
        height="24"
        fill="inherit"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.25 8.25v-4.5a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-1.5 0Zm13.5-4.5V21a.75.75 0 0 1-1.5 0v-4.5h-4.5a.75.75 0 0 1-.75-.75c.035-1.796.262-3.584.677-5.332.917-3.796 2.655-6.34 5.028-7.357a.75.75 0 0 1 1.045.69Zm-1.5 1.304c-3.016 2.303-3.607 7.914-3.722 9.947h3.722V5.054Zm-7.51-1.426a.749.749 0 1 0-1.48.246L11 8.31a3 3 0 0 1-6 0l.739-4.436a.75.75 0 1 0-1.48-.246l-.75 4.5a.766.766 0 0 0-.009.122 4.507 4.507 0 0 0 3.75 4.437V21a.75.75 0 0 0 1.5 0v-8.313A4.506 4.506 0 0 0 12.5 8.25a.766.766 0 0 0-.01-.122l-.75-4.5Z"
            fill="inherit"
        />
    </svg>
);
