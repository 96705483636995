import { useMemo, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { SearchFilterListProps } from './SearchFilterList';

export const useSearchFilterList = ({
    list,
    variant,
    value,
    onChange,
    initialLength,
}: Pick<
    SearchFilterListProps,
    'onChange' | 'variant' | 'value' | 'list' | 'initialLength'
>) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const methods = useForm<{
        query: string;
        isExpanded: boolean;
    }>({
        defaultValues: {
            query: '',
        },
    });

    const { field: queryField } = useController({
        control: methods.control,
        name: 'query',
    });

    const filteredList = useMemo(() => {
        if (queryField.value) {
            return list.filter((item) =>
                item.name
                    .toLowerCase()
                    .startsWith(queryField.value.toLowerCase()),
            );
        } else if (isExpanded || variant !== 'wrapped') return list;
        else return list.slice(0, initialLength);
    }, [list, queryField.value, isExpanded]);

    const handleChange = (id: string) => {
        onChange(
            value.includes(id)
                ? value.filter((item) => item !== id)
                : [...value, id],
        );
    };

    return {
        count: list?.length - initialLength,
        expandAllItems: () => {
            setIsExpanded(true);
        },
        queryField,
        filteredList,
        handleChange,
        showButtonAll:
            variant === 'wrapped' && !isExpanded && !queryField.value,
    };
};
