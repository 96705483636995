import { Error } from '@graphql/generated';

export type GraphqlErrorCode =
    | Error
    | 'BAD_USER_INPUT'
    | 'UNAUTHENTICATED'
    | 'INTERNAL_SERVER_ERROR'
    | 'VALIDATION_FAILED'
    | 'FORBIDDEN';

export const GRAPHQL_ERROR_MESSAGES: Record<GraphqlErrorCode, string> = {
    ALREADY_CONFIRMED_ERROR_CODE: '',
    AUTH_TOKEN_USED_ERROR_CODE: '',
    AUTH_WRONG_CREDENTIALS_ERROR_CODE: 'Email lub hasło nie są poprawne',
    AUTH_WRONG_TOKEN_ERROR_CODE: '',
    CANNOT_ACCESS_ORDER_ERROR: 'Nie można uzyskać dostępu do tego zamówienia',
    CANNOT_ACCESS_RESTAURANT_ERROR_CODE: '',
    CANNOT_CREATE_CHAT_ERROR_CODE: '',
    CANNOT_RATE_NOT_ORDERED_PRODUCT_ERROR_CODE:
        'Nie można ocenić niezamówionego produktu',
    CART_NOT_EXISTS_ERROR_CODE: 'Koszyk nie istnieje',
    CHAT_CREATE_ERROR_CODE: '',
    CHAT_EXISTS_ERROR_CODE: '',
    FORBIDDEN: 'Nie masz uprawień do wykonania akcji',
    IN_APP_NOTIFICATION_NOT_EXISTS_ERROR_CODE: '',
    INGREDIENT_EXISTS_ERROR_CODE: 'Podany składnik już istnieje',
    INGREDIENT_NOT_FOUND_ERROR_CODE: 'Składnik nie został odnaleziony',
    INGREDIENT_USED_IN_PRODUCT_ERROR_CODE: 'Składnik jest użyty w produkcie',
    INTERNAL_SERVER_ERROR: 'Coś poszło nie tak...',
    MENU_CATEGORY_EXISTS_ERROR_CODE: '',
    ORDER_NOT_ENOUGH_COINS_ERROR:
        'Niewystarczająca ilość Eatcoinów na koncie. Doładuj konto, by rozliczyć płatność',
    ORDER_NOT_EXISTS_ERROR: 'Zamówienie nie istnieje',
    ORDER_CAN_NOT_MOVE_TO_COMPLETED_ERROR:
        'Nie można zmienić statusu. Nie wszystkie produkty zostały wydane',
    ORDER_PRODUCT_NOT_AVAILABLE_TIME_ERROR:
        'Produkt niedostępny w tym momencie',
    ORDER_PRODUCT_NOT_SUPPORT_CONSUMPTION_TYPE_ERROR:
        'Produkt nie obsługuje tego sposobu zamówienia',
    ORDER_RESTAURANT_CLOSED_ERROR:
        'Restauracja w tej chwili jest zamknięta. Akceptacja zamówienia możliwa tylko w godzinach otwarcia',
    ORDER_RESTAURANT_NOT_SUPPORT_CONSUMPTION_TYPE_ERROR:
        'Restauracja nie obsługuje wybranego typu zamówienia',
    ORDER_RESTAURANT_TOO_FAR_ERROR: 'Restauracja jest zbyt daleko',
    PRODUCT_EXISTS_ERROR_CODE: 'Podany produkt już istnieje',
    PRODUCT_NOT_EXISTS_ERROR_CODE: 'Podany produkt nie istnieje',
    RATING_EXISTS_ERROR_CODE: 'Produkt został już oceniony',
    REGISTRATION_EMAIL_EXISTS_ERROR_CODE: 'Podany email już istnieje',
    RESTAURANT_NOT_ENOUGH_COINS_CODE:
        'Wybrana restauracja nie posiada wystarczającej kwoty',
    RESTAURANT_NOT_EXISTS_ERROR_CODE: 'Restauracja nie została odnaleziona',
    TRANSACTION_NOT_EXISTS_ERROR_CODE: '',
    UNAUTHENTICATED_ERROR_CODE: '',
    USER_CAN_NOT_HAVE_IBAN_ERROR_CODE: '',
    USER_CAN_NOT_SET_RESTAURATEUR_PRIVACY_POLICY_ERROR_CODE:
        'Użytkownik nie może ustawić polityki prywatności restauratora',
    USER_EXISTS_ERROR_CODE: '',
    USER_GROUP_EXISTS_ERROR_CODE: 'Grupa o tej nazwie już istnieje',
    USER_GROUP_NOT_EXISTS_ERROR_CODE: '',
    USER_NOT_EXISTS_ERROR_CODE: 'Użytkownik nie został odnaleziony',
    VALIDATION_FAILED: 'Coś poszło nie tak...',
    WAITER_NOT_EXISTS_ERROR_CODE: 'Użytkownik nie został odnaleziony',
    AUTH_TOKEN_NOT_FOUND_CODE:
        'Twój token nie został odnaleziony lub jesteś zalogowany w innej sesji',
    GROUP_DOESNT_EXISTS: '',
    GROUP_EXISTS: '',
    GROUP_MEMBER_ALREADY_IN_GROUP: 'Ta osoba jest już dodana do wybranej grupy',
    GROUP_MEMBER_DOESNT_EXIST: '',
    GROUP_NOT_MEMBER: 'Ten użytkownik nie jest członkiem tej grupy',
    GROUP_NOT_OWNER: '',
    GROUP_OWNER: 'Jesteś właścicielem tej grupy i nie możesz jej opuścić',
    NOT_ENOUGH_COINS_ERROR_CODE:
        'Niewystarczająca ilość Eatcoinów na koncie. Doładuj konto, by rozliczyć płatność',
    PAYOFF_ALREADY_CREATED: '',
    PAYOFF_ALREADY_PAID: '',
    PAYOFF_MULTIPLE_ITEMS_REQUESTED: '',
    PAYOFF_NOT_FOUND: '',
    PAYOFF_USER_NOT_OWNER_OF_ITEM_ERROR: '',
    PAYOFF_USER_NOT_RECIPIENT: '',
    PAYOFF_CAN_NOT_BE_CREATED_FOR_ORDER_STATUS: '',
    RESTAURANT_NOT_OWNER: '',
    PAYOFF_BAD_INPUT_ERROR: '',
    PAYOFF_REJECTED_ERROR: '',
    CANNOT_CREATE_ORDER_ADDITION_ERROR: '',
    ORDER_ADDITION_ITEM_ALREADY_ACCEPTED_ERROR: '',
    ORDER_BAD_WAITER_INPUT_ERROR: '',
    ORDER_CAN_NOT_MOVE_TO_ACCEPTED_ERROR: '',
    ORDER_CAN_NOT_NOTIFY_ARRIVAL_ERROR:
        'Nie możesz zgłosić przybycia do restauracji w tym momencie',
    ORDER_CAN_NOT_ORDER_ADDITION_ERROR: '',
    ORDER_CAN_NOT_TRANSITION_STATUS_ERROR: '',
    ORDER_CAN_SET_ETA_ERROR: '',
    ORDER_ITEM_NOT_FOUND_ERROR: '',
    ORDER_NOT_OWNER_ERROR: '',
    CANNOT_CANCEL_ORDER_ERROR:
        'Anulowanie zamówienia jest możliwe 10 minut po jego utworzeniu',
    ORDER_CAN_NOT_MOVE_TO_CLOSED_ERROR:
        'Nie można zakończyć zamówienia. Nie wszystkie produkty zostały wydane',
    ORDER_CAN_NOT_USE_THIS_STATUS_ERROR: '',
    GOOGLE_NO_USER_ERROR: '',
    ORDER_NOT_IN_PARTIAL_ACCEPTANCE_ERROR: '',
    CANNOT_IS_ACTIVE_CODE: '',
    CART_NEEDS_TO_HAVE_PHONE_NUMBER: 'Podaj poprawny numer telefonu',
    ORDER_NEEDS_TO_HAVE_PHONE_NUMBER: 'Podaj poprawny numer telefonu',
    MENU_CATEGORY_PRODUCT_ALREADY_ADDED_TO_ANOTHER_ERROR_CODE:
        'Brak możliwości dodania produktu do więcej niż jednej kategorii w menu',
    USER_INVOICE_DOES_NOT_EXIST: '',
    CART_PRODUCT_FROM_BROCHURE_RESTAURANT:
        'Nie można dodać tych produktów do koszyka',
    USER_ADDRESS_BAD_INPUT_ERROR: '',
    USER_ADDRESS_DOES_NOT_EXIST: '',
    USER_NIP_DOES_NOT_EXIST_ERROR_CODE: '',
    UNAUTHENTICATED: '',
    BAD_USER_INPUT: 'Coś poszło nie tak...',
    ORDER_RESTAURANT_IS_BROCHURE_ERROR:
        'Nie można złożyć zamówienia. Restauracja jest nieczynna',
};
