/** @jsx jsx */ /** @jsxRuntime classic */
import React, { forwardRef } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

const MessagesContainer = forwardRef(
    ({ children, measuredRef, paddingBottom }, ref) => (
        <div
            css={{
                // height: 'calc(100vh - 60px - 58px - 50px)',
                // paddingBottom: '1.5rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                overflowY: 'scroll',
                flexGrow: 1,
                '&::-webkit-scrollbar': {
                    width: 0,
                    height: 0,
                },
                scrollbarWidth: 'none',
                paddingBottom,
                // [s.md]: { paddingBottom: '2rem' },
            }}
        >
            <div
                css={{
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0,
                    },
                    scrollbarWidth: 'none',
                }}
            >
                {children}
                <div ref={ref || measuredRef} />
            </div>
        </div>
    ),
);

export default MessagesContainer;
