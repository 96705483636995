import { Button } from '@/componentsV2/molecules/Button/Button';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateConsentsMutation } from '@graphql/generated';
import dayjs from 'dayjs';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAuthService } from '@/services/AuthService';
import { PrivacyPolicyContent } from '@/EZR/components/molecules/PrivacyPolicyContent/PrivacyPolicyContent';

export const RestaurateurPrivacyPolicyPopup = () => {
    const { setRestaurateurPrivacyPolicyPopup } = useAuthService();

    const methods = useForm({
        resolver: yupResolver(Schema),
        defaultValues: {
            privacyPolicy: false,
            terms: false,
        },
    });

    const [userUpdate] = useUpdateConsentsMutation();

    const handleFormSubmit = methods.handleSubmit(async () => {
        userUpdate({
            variables: {
                input: {
                    payload: {
                        consents: {
                            restaurateurPrivacyPolicy: dayjs().toISOString(),
                        },
                    },
                },
            },
            onCompleted: () => {
                setRestaurateurPrivacyPolicyPopup(true);
            },
            onError: graphqlErrorHandler(true),
        });
    });

    return (
        <FormProvider {...methods}>
            <BasePopup
                overlayBackground
                children={<PrivacyPolicyContent />}
                footer={
                    <PopupFooter>
                        <Button
                            variant="black"
                            onClick={handleFormSubmit}
                            disabled={
                                methods?.formState?.isSubmitted &&
                                !methods?.formState?.isValid
                            }
                        >
                            Akceptuję
                        </Button>
                    </PopupFooter>
                }
                header={
                    <PopupHeader>
                        Zgoda na Politykę Prywatności i Regulamin
                    </PopupHeader>
                }
            />
        </FormProvider>
    );
};

const PopupFooter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 36px;
`;

const PopupHeader = styled.h2`
    color: ${colors.text};
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
`;

const Schema = yup.object().shape({
    privacyPolicy: yup
        .boolean()
        .oneOf([true], 'To pole musi być zaznaczone, aby przejść dalej'),
    terms: yup
        .boolean()
        .oneOf([true], 'To pole musi być zaznaczone, aby przejść dalej'),
});
