function assert(condition, errorMessage) {
    if (!condition) throw new Error('MoneyEz: '.concat(errorMessage));
}

export function isInteger(number) {
    /*
     * To uncomment after backend refactor with MoneyEz in v1.2
     */
    // assert(
    //   Number.isInteger(number),
    //   'You must provide number in złoty, e.g 16 or 14.59'
    // )
}

export function isNumber(number) {
    assert(
        typeof number === 'number',
        'Provided amount must be typeof Number.',
    );
}
