import { Vat } from '@/graphql/generated';

export const VAT: Record<Vat, Vat> = {
    VAT_0: 'VAT_0',
    VAT_5: 'VAT_5',
    VAT_8: 'VAT_8',
    VAT_23: 'VAT_23',
};

export const VAT_NAME: Record<Vat, string> = {
    VAT_0: '0 (Zwolniony)',
    VAT_5: '5%',
    VAT_8: '8%',
    VAT_23: '23%',
};

export const VAT_VALUE: Record<Vat, number> = {
    VAT_0: 0,
    VAT_5: 0.05,
    VAT_8: 0.08,
    VAT_23: 0.23,
};
