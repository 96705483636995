import React, { useState } from 'react';
import { toast } from 'react-toastify';
import validateEmail from '@helpers/validateEmail';
import { useApolloClient } from '@apollo/client';
import { Input } from './components';
import Headline from '@components/Headline/Headline';
import { Grid } from '@material-ui/core';
import {
    sGrid,
    sOuter,
    sDescription,
    sContainerOuter,
    sPaddingTop,
} from '@style/style';
import { ButtonBorder } from '@components/Elements';
import { s } from '@style/index';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useHistory } from 'react-router-dom';
import {
    useGetAuthGoogleOauthRedirectUrlQuery,
    useSignUpMutation,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import styled from '@emotion/styled';
import { GoogleIcon } from '@/svgs/GoogleIcon';
import { TwoActionButtons } from '@/componentsV2/molecules/TwoActionButtons/TwoActionButtons';
import { useLoginWithGoogle } from '@/EZC/pages/LoginWithGoogle/useLoginWithGoogle';
import { MOBILE } from '@constants/common.constants';
import { useAuthService } from '@/services/AuthService';

const initValues = {
    email: '',
    password: '',
    repeatPassword: '',
    name: '',
    surname: '',
};

const Signup = ({ isWidget }) => {
    const [inputValues, setInputValues] = useState(initValues);
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    const [authSignUp, { loading }] = useSignUpMutation();
    const { data, loading: googleRedirectLoading } =
        useGetAuthGoogleOauthRedirectUrlQuery();

    const client = useApolloClient();
    const history = useHistory();
    const { handleLoginWithGoogle } = useLoginWithGoogle();

    const { setHasAccountAlready } = useAuthService();

    const onChange = (e) =>
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });

    const handleSubmit = async (e) => {
        try {
            setIsLoading(true);
            inputCheck(inputValues);
            const { email, password, name, surname } = inputValues;
            await authSignUp({
                variables: { input: { email, password, name, surname } },
                onCompleted: () => {
                    toast.success(
                        `Na podany adres email wysłaliśmy link do potwierdzenia konta 😎`,
                        {
                            autoClose: false,
                        },
                    );
                    AnalyticsHelpers.createCustomEvent({
                        name: 'sign_up',
                        params: {
                            method: 'email',
                            user_type: 'new',
                        },
                    });
                    setHasAccountAlready(true);
                    client.clearStore();
                    history.push('/login');
                },
                onError: graphqlErrorHandler(true),
            });
            setIsLoading(false);
        } catch (error) {
            error.errors.forEach((el) =>
                toast.warn(el, { onClose: () => setIsLoading(false) }),
            );
        }
    };

    return (
        <React.Fragment>
            <TopNav title={'Rejestracja'} />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder link={`/login`}>Zaloguj</ButtonBorder>
                    }
                >
                    Rejestracja
                </Headline>
                <Grid container css={sGrid}>
                    <p css={sDescription}>
                        Eatzon to aplikacja do zamawiana dań oraz rezerwacji w
                        restauracjach. Zarejestruj się i doświadczaj nowych
                        doznań kulinarnych!
                    </p>
                </Grid>
            </div>

            <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
                <div css={[sFormContainer]}>
                    <Input
                        onChange={onChange}
                        name="name"
                        value={inputValues.name}
                        placeholder="Imię"
                    />

                    <Input
                        onChange={onChange}
                        name="surname"
                        value={inputValues.surname}
                        placeholder="Nazwisko"
                    />

                    <Input
                        onChange={onChange}
                        name="email"
                        type="email"
                        value={inputValues.email}
                        placeholder="Email"
                    />
                    <Input
                        onChange={onChange}
                        name="password"
                        value={inputValues.password}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Hasło"
                        sInputExtra={{ paddingRight: '50px' }}
                        autocomplete="new-password"
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                    />
                    <Input
                        onChange={onChange}
                        name="repeatPassword"
                        value={inputValues.repeatPassword}
                        type={showRepeatPassword ? 'text' : 'password'}
                        placeholder="Powtórz Hasło"
                        sInputExtra={{ paddingRight: '50px' }}
                        autocomplete="new-password"
                        showPassword={showRepeatPassword}
                        setShowPassword={setShowRepeatPassword}
                    />
                    <TwoActionButtons
                        buttonFirst={{
                            onClick: handleSubmit,
                            disabled: loading || isLoading,
                            loading: loading || isLoading,
                            children: 'Zarejestruj',
                            variant: 'black',
                        }}
                        buttonSecond={{
                            onClick: () => {
                                if (MOBILE) {
                                    handleLoginWithGoogle();
                                } else {
                                    window.location.href = data?.url;
                                }
                            },
                            children: (
                                <GoogleButtonContent>
                                    <GoogleIcon /> Zarejestruj przez Google
                                </GoogleButtonContent>
                            ),
                            disabled: !data,
                            loading: googleRedirectLoading,
                            variant: 'black',
                        }}
                        separatorText="lub"
                    />
                    <p css={[sAgreement]}>
                        Rejestrując się, akceptujesz
                        <a
                            href="https://eatzon.pl/warunki-uslugi"
                            rel="noreferrer noopener"
                            target="_blank"
                        >
                            <strong> Warunki Usługi</strong>
                        </a>{' '}
                        oraz{' '}
                        <strong>
                            <a
                                href="https://eatzon.pl/polityka-prywatnosci"
                                rel="noreferrer noopener"
                                target="_blank"
                            >
                                Politykę Prywatności
                            </a>
                        </strong>
                    </p>
                </div>
            </Grid>
        </React.Fragment>
    );
};

function inputCheck(inputValues) {
    const errors = [];
    if (inputValues.password.length < 8) {
        errors.push('Hasło powinno zawierać min. 8 symboli');
    }

    if (inputValues.password !== inputValues.repeatPassword) {
        errors.push('Hasła nie są zgodne');
    }

    if (!inputValues.name) {
        errors.push('Imię jest wymagane');
    }

    if (!inputValues.surname) {
        errors.push('Nazwisko jest wymagane');
    }

    if (!validateEmail(inputValues.email)) {
        errors.push('Email jest nieprawidłowy');
    }

    if (errors.length) {
        const err = new Error();
        err.errors = errors;
        throw err;
    }
}

export default Signup;

const sFormContainer = {
    paddingBottom: '1.5rem',
};

const sAgreement = {
    textAlign: 'center',
    lineHeight: '1.618em',
    maxWidth: '75%',
    margin: 'auto',
    fontSize: '11px',
    paddingTop: '1.5rem',
    [s.md]: { paddingTop: '2rem' },
};

const GoogleButtonContent = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
