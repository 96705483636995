import React from 'react'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import {
    StatusLarge,
    Collapsable,
    Row,
    Bullet,
} from '../../../../components/Elements';
import { s } from '../../../../style';

import UpdateIcon from '@material-ui/icons/Update';
import { ORDER_STATUS } from '@constants/fromGraphql/OrderStatus.constants';
import { dateFormat } from '@helpers/dateFormat';
import styled from '@emotion/styled';

const OrderStatuses = ({ statuses, waiter, open, setOpen, left }) => {
    /**
     * Statuses are filtered because there are 3 at once and all of them mean pending status
     */

    const filteredStatuses = statuses.filter(
        (item) =>
            item?.status !== 'PENDING' && item?.status !== 'SENT_TO_RESTAURANT',
    );

    const statusCurrent = [...filteredStatuses].pop();

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                right={!waiter}
                left={left}
                noPadding
                oneLiner
                title={'Statusy'}
                icon={<UpdateIcon />}
                description={
                    <>
                        {ORDER_STATUS[statusCurrent?.status]?.name}
                        <Bullet />
                        <OrderDate>
                            {dateFormat.orderDateWithTime(statusCurrent?.date)}
                        </OrderDate>
                    </>
                }
            >
                {filteredStatuses.map((item, index) => (
                    <Row
                        key={index}
                        desc={
                            <div
                                css={{ cursor: 'pointer' }}
                                onClick={() => setOpen && setOpen(!open)}
                            >
                                <StatusLarge
                                    color={ORDER_STATUS[item.status]?.color}
                                    extraCss={{ whiteSpace: 'nowrap' }}
                                >
                                    {ORDER_STATUS[item.status]?.name}
                                </StatusLarge>
                            </div>
                        }
                    >
                        <span
                            css={{
                                textTransform: 'capitalize',
                                [s.xs]: { fontSize: '12px' },
                            }}
                        >
                            {dateFormat.orderDateWithTime(item.date)}
                        </span>
                    </Row>
                ))}
            </Collapsable>
        </Grid>
    );
};

const OrderDate = styled.span`
    text-transform: capitalize;
`;

export default OrderStatuses;
