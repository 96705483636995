import { ReactNode } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ConsumptionType } from '@graphql/generated';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';

interface FilterSettingsFormProviderProps {
    children: ReactNode;
}

export const FilterSettingsFormProvider = ({
    children,
}: FilterSettingsFormProviderProps) => {
    const { distance, consumptionTypes, geolocation } =
        useUserSettingsStoreShallow([
            'distance',
            'consumptionTypes',
            'geolocation',
        ]);

    const methods = useForm<FilterSettingsSchemaValues>({
        resolver: yupResolver(FilterSettingsSchema),
        defaultValues: {
            geolocation,
            distance,
            consumptionTypes,
        },
    });

    return <FormProvider {...methods}>{children}</FormProvider>;
};

const FilterSettingsSchema = yup.object({
    distance: yup.string().nullable().default(null),
    geolocation: yup
        .object({
            address: yup.string().defined(),
            lng: yup.number().defined(),
            lat: yup.number().defined(),
        })
        .test(
            'valid-geolocation',
            'Nie udało się określić lokalizacji, wpisz swój adres.',
            (value) => !!value.address && !!value.lng && !!value.lat,
        ),
    consumptionTypes: yup
        .array()
        .of(yup.string<ConsumptionType>().defined())
        .min(1, 'Wybierz conajmniej jedną preferencje.')
        .defined(),
});

export interface FilterSettingsSchemaValues
    extends yup.InferType<typeof FilterSettingsSchema> {}
