import React from 'react'; // eslint-disable-line no-unused-vars
import { FileIcon } from '@/svgs/FileIcon';
import { TableIcon } from '@/svgs/TableIcon';
import { Collapsable } from '@components/Elements';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '@style/index';
import { useRestaurantTableUpload } from './useRestaurantTableUpload';
import styled from '@emotion/styled';
import { Button as BaseButton } from '@/componentsV2/molecules/Button/Button';
import { css } from '@emotion/core';

interface RestaurantTableUploadProps {
    isNewRestaurant: boolean;
}

export const RestaurantTableUpload = ({
    isNewRestaurant,
}: RestaurantTableUploadProps) => {
    const {
        file,
        handleFileChange,
        handleFileSave,
        layoutPdfUrl,
        fileInputRef,
        handleFileReset,
        loading,
        showButtons,
    } = useRestaurantTableUpload(!!isNewRestaurant);

    return (
        <Grid item xs={12} md={6}>
            {/* @ts-expect-error migrate to TS */}
            <Collapsable
                left
                noPadding
                oneLiner
                title={'Układ sali'}
                icon={<TableIcon />}
                description="PDF plik"
            >
                <Wrapper>
                    <Description>
                        Kliknij, aby wybrać plik PDF z widokiem układu sali do
                        załadowania
                    </Description>
                    {!!layoutPdfUrl && (
                        <FileWrapper>
                            <FileIcon />
                            <a
                                href={layoutPdfUrl ?? ''}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {file?.name || 'Plik PDF'}
                            </a>
                            <DeleteButton onClick={handleFileReset}>
                                <CloseIcon style={{ color: colors.white }} />
                            </DeleteButton>
                        </FileWrapper>
                    )}
                    {showButtons.select && !layoutPdfUrl && (
                        <Button width="100%" variant="gray">
                            <FileInput
                                accept="application/pdf"
                                id="fileInput"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                type="file"
                            />
                            wybierz plik
                        </Button>
                    )}
                    {showButtons.save && !isNewRestaurant && (
                        <Button
                            loading={loading}
                            onClick={handleFileSave}
                            variant="black"
                            width="100%"
                        >
                            zapisz
                        </Button>
                    )}
                </Wrapper>
            </Collapsable>
        </Grid>
    );
};

const Wrapper = styled.div`
    padding: 24px;
`;

const FileWrapper = styled.div`
    align-items: center;
    color: ${colors.text};
    display: flex;
    font-size: 13px;
    font-weight: 700;
    gap: 4px;
    margin: 16px 0 0;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
`;

const Button = styled(BaseButton)`
    margin: 24px 0 0;
`;

const DeleteButton = styled.button`
    align-items: center;
    background-color: ${colors.frenchGray};
    border: none;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-left: 12px;
    padding: 12px;
`;

const FileInput = styled.input`
    appearance: none;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
`;
