import React from 'react';

import AllCartOrders from './views/AllCartOrders';
import AllCartOrdersNoAuth from './views/AllCartOrdersNoAuth';
import Headline from '../../../components/Headline/Headline';

import { Grid } from '@material-ui/core';
import { sBottomLinks, sOuter, sGrid } from '../../../style/style';
import { ButtonBorder } from '../../../components/Elements';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { useAuthService } from '@/services/AuthService';
import styled from '@emotion/styled';
import { colors, s } from '@style/index';

const Cart = () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    return (
        <div>
            <TopNav title={'Koszyk'} />
            <div css={sOuter}>
                <Headline>Koszyk</Headline>
                <Grid container css={sGrid}>
                    <Info>
                        Dla zamówień z dostawą lub na wynos, do ceny zamówienia
                        zostanie doliczona opłata za opakowanie.{' '}
                    </Info>
                    {isLoggedIn ? <AllCartOrders /> : <AllCartOrdersNoAuth />}
                    <Grid item xs={12} css={sBottomLinks}>
                        <ButtonBorder link={'/orders'}>Zamówienia</ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Cart;

const Info = styled.p`
    color: ${colors.blueZodiac};
    line-height: 26px;
    font-size: 15px;
    margin-bottom: 32px;

    ${s.xs} {
        max-width: 300px;
    }
`;
