import React from 'react';

export const TableIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.71433 5.31427C1.4303 5.03024 1.4303 4.56973 1.71433 4.2857L4.02861 1.97142C4.31264 1.68739 4.77315 1.68739 5.05718 1.97142L7.37147 4.2857C7.6555 4.56973 7.6555 5.03024 7.37147 5.31427L5.05718 7.62856C4.77315 7.91259 4.31264 7.91259 4.02861 7.62856L1.71433 5.31427ZM16.8857 7.11429C19.584 9.81259 19.584 14.1874 16.8857 16.8857C14.1874 19.584 9.81256 19.584 7.11426 16.8857C4.41596 14.1874 4.41595 9.81259 7.11426 7.11429C9.81256 4.41598 14.1874 4.41598 16.8857 7.11429ZM4.15716 22.1571C4.44119 22.4412 4.9017 22.4412 5.18573 22.1571L7.50001 19.8428C7.78404 19.5588 7.78404 19.0983 7.50001 18.8143L5.18573 16.5C4.9017 16.216 4.44119 16.216 4.15716 16.5L1.84287 18.8143C1.55884 19.0983 1.55884 19.5588 1.84287 19.8428L4.15716 22.1571ZM16.3713 19.9714C16.0873 19.6874 16.0873 19.2269 16.3713 18.9429L18.6856 16.6286C18.9697 16.3445 19.4302 16.3445 19.7142 16.6286L22.0285 18.9429C22.3125 19.2269 22.3125 19.6874 22.0285 19.9714L19.7142 22.2857C19.4302 22.5697 18.9697 22.5697 18.6856 22.2857L16.3713 19.9714ZM18.8142 7.5C19.0982 7.78403 19.5587 7.78403 19.8427 7.5L22.157 5.18571C22.4411 4.90168 22.4411 4.44117 22.157 4.15714L19.8427 1.84286C19.5587 1.55883 19.0982 1.55883 18.8142 1.84286L16.4999 4.15714C16.2159 4.44118 16.2159 4.90168 16.4999 5.18571L18.8142 7.5Z"
            fill="#343340"
        />
    </svg>
);
