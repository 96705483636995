/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

const ChatWindow = ({ children }) => (
    <div
        css={{
            flexGrow: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}
    >
        {children}
    </div>
);

export default ChatWindow;
