import { useCommonStore } from '@/CommonStore';
import { SearchPhraseLabel } from '@/componentsV2/atoms/SearchPhraseLabel/SearchPhraseLabel';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import React from 'react';

export interface RecentlySearchedListProps {
    onClick: (value: string) => void;
}

export const RecentlySearchedList = ({
    onClick,
}: RecentlySearchedListProps) => {
    const {
        clearSearchPhraseBrowsingHistory,
        browsingHistory: { searchPhrases },
    } = useCommonStore();

    return (
        <>
            <SearchPhraseTitle>
                OSTATNIO WYSZUKIWANE
                <ClearButton onClick={() => clearSearchPhraseBrowsingHistory()}>
                    Wyczyść
                </ClearButton>
            </SearchPhraseTitle>
            <SearchPhraseWrapper>
                {searchPhrases?.map((phrase) => (
                    <SearchPhraseLabel
                        key={phrase}
                        onClick={() => {
                            onClick(phrase);
                        }}
                        onRemove={() =>
                            clearSearchPhraseBrowsingHistory(phrase)
                        }
                    >
                        {phrase}
                    </SearchPhraseLabel>
                ))}
            </SearchPhraseWrapper>
        </>
    );
};

const SearchPhraseWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
    width: 100%;
`;

const SearchPhraseTitle = styled.p`
    align-items: center;
    color: ${colors.gray500};
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.36px;
    line-height: 18px;
    padding-bottom: 18px;
    text-transform: uppercase;
`;

const ClearButton = styled.button`
    background: none;
    border: none;
    color: ${colors.baliHai};
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    outline: none;
    text-decoration: underline;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: ${colors.gray};
    }
`;
