/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import MaskedInput from 'react-text-mask';

import { Input, TextField, ButtonBase, Collapse } from '@material-ui/core';

import {
    sBase,
    sInput,
    sInputDarker,
} from '@/EZC/views/Auth/components/Input.component';
import { s } from '@style';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';

export const validationSchema = yup.object().shape({
    // TODO: uncomment when search nip functionality will be ready
    // name: yup.string().required('Nazwa firmy jest wymagana'),
    name: yup.string(),
    nip: yup
        .string()
        .required('NIP jest wymagany')
        .matches(/^\d{3}-\d{3}-\d{2}-\d{2}$/, 'NIP jest niepoprawny'),
    // TODO: uncomment when search nip functionality will be ready
    // address: yup.string().required('Adres jest wymagany'),
    // city: yup.string().required('Miasto jest wymagane'),
    // postalCode: yup
    //     .string()
    //     .required('Kod pocztowy jest wymagany')
    //     .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy jest niepoprawny'),
});

export default ({
    onInvoiceDetailsSave,
    isInvoice,
    onIsInvoiceChange,
    invoiceDetails,
    onInvoiceDetailsChange,
    parentSetIsOpen,
}) => {
    const { control, handleSubmit } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const handleFormSubmit = handleSubmit(
        (data) => {
            onInvoiceDetailsSave(data);
            parentSetIsOpen(false);
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );

    return (
        <div
            css={{
                [s.sm_down]: { padding: '1.5rem 1rem' },
                [s.md]: { padding: '2rem' },
            }}
        >
            <Button
                variant="gray"
                width="100%"
                onClick={() => onIsInvoiceChange(!isInvoice)}
                extraCss={{ fontSize: '13px' }}
            >
                {isInvoice ? 'Zrezygnuj z Faktury' : 'Dodaj dane do Faktury'}
            </Button>

            <Collapse in={!!isInvoice}>
                {!!isInvoice && (
                    <InvoiceDetails
                        control={control}
                        invoiceDetails={invoiceDetails}
                        onChange={onInvoiceDetailsChange}
                        handleFormSubmit={handleFormSubmit}
                    />
                )}
            </Collapse>
        </div>
    );
};

const InvoiceDetails = ({
    control,
    invoiceDetails,
    onChange,
    handleFormSubmit,
}) => {
    return (
        <div css={{ marginTop: '1.5rem' }}>
            <ButtonBase css={sBase}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue={invoiceDetails?.name || ''}
                    render={({ field }) => (
                        <TextField
                            placeholder="Nazwa Firmy"
                            {...field}
                            fullWidth
                            required
                            css={[sInput, sInputDarker]}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ButtonBase>
            <ButtonBase css={sBase}>
                <Controller
                    name="nip"
                    control={control}
                    defaultValue={invoiceDetails?.nip || ''}
                    render={({ field }) => (
                        <Input
                            placeholder="NIP"
                            {...field}
                            fullWidth
                            required
                            inputComponent={TextMaskNip}
                            css={[sInput, sInputDarker]}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ButtonBase>
            {/* TODO: uncomment when search nip functionality will be ready */}
            {/* <ButtonBase css={sBase}>
                <Controller
                    name="address"
                    control={control}
                    defaultValue={invoiceDetails?.address || ''}
                    render={({ field }) => (
                        <TextField
                            placeholder="Adres"
                            {...field}
                            fullWidth
                            required
                            css={[sInput, sInputDarker]}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ButtonBase>
            <ButtonBase css={sBase}>
                <Controller
                    name="city"
                    control={control}
                    defaultValue={invoiceDetails?.city || ''}
                    render={({ field }) => (
                        <TextField
                            placeholder="Miasto"
                            {...field}
                            fullWidth
                            required
                            css={[sInput, sInputDarker]}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ButtonBase>
            <ButtonBase css={[sBase, { marginBottom: 0 }]}>
                <Controller
                    name="postalCode"
                    control={control}
                    defaultValue={invoiceDetails?.postalCode || ''}
                    render={({ field }) => (
                        <Input
                            placeholder="Kod Pocztowy"
                            {...field}
                            fullWidth
                            required
                            inputComponent={TextMaskZipCode}
                            css={[sInput, sInputDarker]}
                            onChange={(e) => {
                                field.onChange(e);
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ButtonBase> */}
            <InvoiceButton
                variant="black"
                onClick={handleFormSubmit}
                width="100%"
            >
                Zapisz fakturę
            </InvoiceButton>
        </div>
    );
};

const InvoiceButton = styled(Button)`
    margin-top: 24px;
`;

function TextMaskNip(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[1-9]/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
            ]}
        />
    );
}

function TextMaskZipCode(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, '-', /\d/, /\d/, /\d/]}
        />
    );
}
