import ReactDOM from 'react-dom';
import App from './App';
import { Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { GeolocationProvider } from './EZC/context/Geolocation/Geolocation.provider';
import './style/index.css';
import { FirebaseAnalyticsService } from './services/FirebaseAnalyticsService';
import { createBrowserHistory } from 'history';

// Apollo Client
import { ApolloProvider } from '@apollo/client';

// Material UI
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { plPL } from '@material-ui/core/locale';
import { colors } from './style';
import { GaService } from './services/GaService';
import { client } from '@/boot/client';
import { FirebaseChatService } from './services/FirebaseChatService/FirebaseChatService';
import { AppPopups } from './AppPopups';

GaService.initializeWeb();
FirebaseAnalyticsService.initialize();
FirebaseChatService.initialize();

const muiTheme = createTheme(
    {
        palette: {
            primary: {
                main: colors.text,
            },
            secondary: {
                main: colors.green,
            },
        },
        typography: {
            fontFamily: ['Poppins'].join(','),
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 844,
                lg: 1280,
                xl: 1920,
            },
        },
    },
    plPL,
);

export const history = createBrowserHistory();

const IndexApp = () => (
    <ApolloProvider client={client}>
        <ThemeProvider theme={muiTheme}>
            <Router history={history}>
                <GeolocationProvider>
                    <AppPopups>
                        <App />
                    </AppPopups>
                </GeolocationProvider>
            </Router>
        </ThemeProvider>
    </ApolloProvider>
);

ReactDOM.render(<IndexApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

// if (useAuthService.getState().sessionToken) subscribeUser();
// if (useWaiterService.getState().sessionToken) subscribeWaiter();
