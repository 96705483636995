/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import MoneyEz from '@helpers/MoneyEz';

import useDeliveryInfo from '@/EZC/hooks/useDeliveryInfo';
import ConditionParagraph from './components/ConditionParagraph';

const GetDeliveryInfo = ({
    restId,
    customCoordinates,
    orderAmount = 0,
    setDeliveryCost,
    setDeliveryError = () => {},
}) => {
    const {
        data,
        loading,
        error,
        deliveryRadius,
        distanceToUser,
        freeDeliveryAmount,
        minAmount,
        _deliveryCostAmount,
    } = useDeliveryInfo({ restId, customCoordinates });

    const orderAmountNumber = parseFloat(orderAmount);
    //TODO change delivery info EAT-331
    useEffect(() => {
        if (data?.restaurant?.deliverySettings) {
            setDeliveryCost(
                distanceToUser > deliveryRadius
                    ? null
                    : typeof data?.restaurant?.deliverySettings
                            ?.freeDeliveryAmount === 'number' &&
                        data?.restaurant?.deliverySettings
                            ?.freeDeliveryAmount <= orderAmountNumber
                      ? 0
                      : _deliveryCostAmount,
            );
        }
    }, [orderAmount, data]);

    useEffect(() => {
        setDeliveryError({
            minAmount: orderAmountNumber < minAmount,
            distance: distanceToUser > deliveryRadius,
        });
    }, [orderAmount, minAmount, distanceToUser, deliveryRadius]);

    if (loading) return <div />;
    if (error) {
        return <p>Spróbuj załadować ponownie</p>;
    }
    if (!data?.restaurant?.deliverySettings) {
        return <p>Spróbuj załadować ponownie</p>;
    }

    if (distanceToUser > deliveryRadius)
        return (
            <ConditionParagraph
                condition={distanceToUser > deliveryRadius}
                text="Wybrany adres jest poza obszarem dostaw restauracji"
                theme="error"
            />
        );

    return (
        <>
            <ConditionParagraph
                condition={freeDeliveryAmount === null}
                text={'Koszt dostawy: '}
                text2={MoneyEz(
                    _deliveryCostAmount,
                ).getAmountWithFullPrecisionAndZl()}
            />

            <ConditionParagraph
                condition={
                    freeDeliveryAmount !== null &&
                    freeDeliveryAmount > orderAmountNumber
                }
                text={'Koszt dostawy: '}
                text2={MoneyEz(
                    _deliveryCostAmount,
                ).getAmountWithFullPrecisionAndZl()}
            />
            <ConditionParagraph
                condition={minAmount > orderAmountNumber}
                text={
                    'Minimalna kwota dostawy nie została osiągnięta. Pozostało: '
                }
                text2={MoneyEz(minAmount)
                    .subsctract(orderAmount)
                    .getAmountWithFullPrecisionAndZl()}
                theme="error"
            />
            <ConditionParagraph
                condition={
                    freeDeliveryAmount !== null &&
                    freeDeliveryAmount > orderAmountNumber
                }
                text={'Darmowa dostawa od '}
                text2={MoneyEz(
                    freeDeliveryAmount || 0,
                ).getAmountWithFullPrecisionAndZl()}
                theme="info"
            />

            <ConditionParagraph
                condition={
                    freeDeliveryAmount !== null &&
                    freeDeliveryAmount <= orderAmountNumber
                }
                text="Darmowa dostawa aktywowana"
                theme="success"
            />
        </>
    );
};

export default GetDeliveryInfo;
