/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid, Hidden } from '@material-ui/core';
import useOrdersMutation from '@/EZC/hooks/useOrders.mutations';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import OrderItem from '../components/OrderItem.component';
import useCheckoutLogic from '@/EZC/pages/cart/hooks/useCheckoutLogic';

import { sDescription } from '@style/style';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import { useAllCartOrdersLazyQuery } from '@graphql/generated';
import Pagination from '@material-ui/lab/Pagination';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useOrderInCartByRestaurant } from '@/EZC/context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider';

const PAGE_SIZE = 10;

export default () => {
    const [getCartList, { data, loading, error }] = useAllCartOrdersLazyQuery({
        fetchPolicy: 'cache-and-network',
    });

    const { loadingOrders } = useOrderInCartByRestaurant();

    const page = data?.cartList?.metadata?.currentPage ?? 1;

    const fetchMore = (page) => {
        getCartList({
            variables: {
                pagination: { page: page, pageSize: PAGE_SIZE },
            },
        });
    };

    useEffect(() => {
        getCartList({
            variables: {
                pagination: { page, pageSize: PAGE_SIZE },
            },
            onError: graphqlErrorHandler(true),
        });
    }, []);

    const { handleDeleteOrder } = useOrdersMutation();
    const { onDeleteCartOrderFromLocalStorage } = useCheckoutLogic({});
    const history = useHistory();
    const location = useLocation();
    const query = queryString.parse(location.search);

    useEffect(() => {
        if (query.checkout && data?.cart?.length > 0) {
            const checkouts = data.cart;
            const checkoutId = checkouts[checkouts.length - 1].id;
            // console.log(`redirect to checkout ${checkoutId}`)
            history.push(`/cart-checkout/${checkoutId}`);
        }
    }, [data]);

    const onDeleteOrder = (orderId) => {
        handleDeleteOrder(
            { orderId },
            onDeleteCartOrderFromLocalStorage(orderId),
        );
    };

    if (loadingOrders || loading)
        return (
            <>
                <SkeletonOrder />
                <Hidden mdUp>
                    <SkeletonOrder />
                    <SkeletonOrder />
                    <SkeletonOrder />
                </Hidden>
            </>
        );

    if (error) {
        return null;
    }

    if (!data || !data?.cartList?.data || data?.cartList?.data?.length === 0)
        return <div css={sDescription}>Twój koszyk jest pusty</div>;

    return (
        <>
            <Grid container>
                {data?.cartList?.data?.map((order, id) => (
                    <OrderItem
                        onlyOne={data?.cartList?.metadata?.totalCount === 1}
                        id={id}
                        key={id}
                        cartsView
                        onDelete={() => onDeleteOrder(order.id)}
                        {...makeOrderProps(order)}
                    />
                ))}
            </Grid>
            {data?.cartList?.metadata?.totalCount > PAGE_SIZE && (
                <Grid justifyContent="center" container>
                    <Pagination
                        count={data?.cartList?.metadata?.totalPages}
                        page={data?.cartList?.metadata?.currentPage}
                        onChange={(_, page) => fetchMore(page)}
                    />
                </Grid>
            )}
        </>
    );
};

function makeOrderProps(order) {
    const { id, restaurant, lines, priceSum, consumptionDetails } = order;

    return {
        orderId: id,
        restaurantId: restaurant.id,
        restaurantName: restaurant.name,
        restaurantSlug: restaurant.slug,
        restaurantLogoSrc: restaurant.avatarUrl,
        orderedProducts: lines ?? [],
        orderPriceSum: priceSum,
        orderType: consumptionDetails?.type,
    };
}
