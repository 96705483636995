import NiceModal from '@ebay/nice-modal-react';
import { popup } from '@/AppPopups';
import { BasePopupNew } from '@/componentsV2/organisms/BasePopupNew/BasePopupNew';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { PopupFooterSticky } from '@/componentsV2/organisms/BasePopupNew/PopupFooterSticky';
import { PopupHeaderSecondary } from '@/componentsV2/organisms/BasePopupNew/PopupHeaderSecondary';
import {
    SearchFilterList,
    SearchFilterListProps,
} from '@/EZC/domains/Search/molecules/SearchFilterList/SearchFilterList';
import { useState } from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

export interface SearchFilterSecondPopupProps {
    searchFilteredList: Pick<
        SearchFilterListProps,
        'list' | 'loading' | 'emptyResultText'
    >;
    checked: string[];
    onSave: (value: string[]) => void;
    title: string;
}

export interface SearchFilterSecondPopupInnerProps
    extends SearchFilterSecondPopupProps {
    onCancel: () => void;
}

export const SearchFilterSecondPopup = NiceModal.create(
    ({
        checked = [],
        onSave,
        searchFilteredList,
        title,
    }: Pick<
        SearchFilterSecondPopupProps,
        'searchFilteredList' | 'title' | 'onSave' | 'checked'
    >) => {
        return (
            <SearchFilterSecondPopupInner
                onCancel={() => {
                    popup.hide('SearchFilterSecondPopup');
                }}
                checked={checked}
                onSave={(value) => {
                    onSave(value);
                }}
                searchFilteredList={searchFilteredList}
                title={title}
            />
        );
    },
);

export const SearchFilterSecondPopupInner = ({
    searchFilteredList,
    onCancel,
    onSave,
    title,
    checked,
}: SearchFilterSecondPopupInnerProps) => {
    const [checkedItems, setCheckedItems] = useState<string[]>(checked);

    return (
        <BasePopupNew
            animation="fromLeft"
            header={
                <PopupHeaderSecondary
                    text={title}
                    iconButton={{
                        icon: <ChevronLeft fontSize="medium" />,
                        onClick: onCancel,
                    }}
                    textButton={{
                        text: 'Wyczyść',
                        onClick: () => {
                            setCheckedItems([]);
                        },
                        variant: 'black',
                    }}
                />
            }
            footer={
                <PopupFooterSticky>
                    <Button
                        variant="black"
                        width="100%"
                        onClick={() => onSave(checkedItems)}
                    >
                        Zapisz
                    </Button>
                </PopupFooterSticky>
            }
            alwaysFullHeight
            stickyFooter
            stickyHeader
        >
            <SearchFilterList
                initialLength={11}
                value={checkedItems}
                onChange={setCheckedItems}
                variant="all"
                {...searchFilteredList}
            />
        </BasePopupNew>
    );
};
