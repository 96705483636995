import {
    useGetRestaurantListQuery,
    useRestaurantQuery,
} from '@graphql/generated';
import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export const useHome = () => {
    const { restaurantId } = useAuthService((state) => state);

    const { data: dataRestaurant } = useRestaurantQuery({
        variables: {
            input: { restaurantId: restaurantId || '' },
        },
        skip: !restaurantId,
        onError: graphqlErrorHandler(true),
    });

    const { data: restauratorData } = useGetRestaurantListQuery({
        onError: graphqlErrorHandler(true),
    });

    const unsortedRestaurants = restauratorData?.restaurator?.restaurants ?? [];

    const restaurantsSorted = [...unsortedRestaurants].sort((a, b) =>
        a.isActive ? -1 : b.isActive ? 1 : 0,
    );

    return {
        restaurantsSorted,
        dataRestaurant,
    };
};
