import { MOBILE } from '@constants/common.constants';
import { useEffect } from 'react';
import { Keyboard } from '@capacitor/keyboard';

export const useHideKeyboard = () => {
    const hideKeyboard = (event: KeyboardEvent) => {
        if (MOBILE && event.key === 'Enter') {
            Keyboard.hide();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', (event) => {
            hideKeyboard(event);
        });

        return () => {
            removeEventListener('keydown', (event) => {
                hideKeyboard(event);
            });
        };
    }, []);
};
