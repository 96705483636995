import React from 'react';

export const UncheckIcon = ({ color = '#000' }: { color?: string }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="1.25" />
        <rect
            x="0.75"
            y="0.75"
            width="16.5"
            height="16.5"
            rx="1.25"
            stroke={color}
            strokeWidth="1.5"
        />
    </svg>
);
