import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useKeyboardStoreShallow } from './KeyboardStore';
import { useScrollToActiveInput } from '@hooks/useScrollToActiveInput';

export const KeyboardSpacer = () => {
    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    useScrollToActiveInput();

    return <KeyboardSpacerElement height={keyboardHeight} />;
};

const KeyboardSpacerElement = styled.div<{ height: number }>(
    ({ height }) => css`
        width: 100%;
        height: ${height}px;
    `,
);
