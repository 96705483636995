/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import TextField from '@material-ui/core/TextField';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';

export default function InputEmail(props) {
    return (
        <TextInput
            type="text"
            {...props}
            css={{ maxWidth: 320, width: '100%' }}
        />
    );
}

const TextInput = styled(TextField)`
    width: 100%;
    max-width: 360px;
    min-width: 320px;

    .MuiFormLabel-root {
        width: 100%;
    }
    ${mediaQueries.xs} {
        min-width: 200px;
    }
`;
