import React, { useRef } from 'react';
import { Ingredient } from '@graphql/generated';
import { ListItemCheckbox } from '@/componentsV2/molecules/ListItemCheckbox/ListItemCheckbox';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { css } from '@emotion/core';
import { VisibleComponent } from '@/componentsV2/atoms/VisibleComponent/VisibleComponent';
import { SearchInput } from '@/componentsV2/molecules/SearchInput/SearchInput';
import { useListAlphabetically } from './useListAlphabetically';
import { SearchFilterEmpty } from '@/EZC/domains/Search/molecules/SearchFilterEmpty/SearchFilterEmpty';
import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';
import { ButtonText } from '@/componentsV2/atoms/ButtonText/ButtonText';
import { ViewportList, ViewportListRef } from 'react-viewport-list';

type ListType = 'mobile' | 'desktop';
export interface ListAlphabeticallyProps {
    emptyResultText: string;
    ingredients: (Ingredient & { disabled?: boolean })[];
    initialLength: number;
    loading?: boolean;
    onChange: (id: string[]) => void;
    type: ListType;
    value: string[];
    variant: 'all' | 'wrapped';
}

export const ListAlphabetically = ({
    emptyResultText,
    ingredients,
    initialLength = 11,
    loading,
    onChange,
    type = 'desktop',
    value,
    variant,
}: ListAlphabeticallyProps) => {
    const {
        queryField,
        groupedIngredients,
        handleChange,
        activeLetter,
        setVisibleLetters,
        showButtonAll,
        expandAllItems,
        count,
        alphabet,
    } = useListAlphabetically({
        ingredients,
        onChange,
        initialLength,
        variant,
        value,
        loading,
    });

    const listRef = useRef<ViewportListRef>(null);

    const scrollToLetter = (index: number) => {
        setVisibleLetters([]);

        listRef.current?.scrollToIndex({
            index,
            prerender: 1,
        });
    };

    return (
        <>
            <Wrapper>
                <div>
                    <SearchInputElement
                        {...queryField}
                        type="default"
                        placeholder="Na co masz ochotę?"
                    />
                    {type === 'desktop' && !queryField.value && (
                        <Alphabet listType={'desktop'}>
                            {alphabet.map((letter, index) => (
                                <AlphabetButton
                                    active={letter === activeLetter}
                                    onClick={() => {
                                        expandAllItems();

                                        // NOTE: we have to wait for expanded items
                                        setTimeout(() => {
                                            scrollToLetter(index);
                                        }, 0);
                                    }}
                                    listType="desktop"
                                >
                                    {letter}
                                </AlphabetButton>
                            ))}
                        </Alphabet>
                    )}
                </div>
                {loading ? (
                    <Spinner spinnerVariant="absolute" />
                ) : Object.keys(groupedIngredients).length === 0 ? (
                    <EmptyStateWrapper>
                        <SearchFilterEmpty text={emptyResultText} />
                    </EmptyStateWrapper>
                ) : (
                    <ListWrapper type={type}>
                        <ViewportList
                            items={Object.entries(groupedIngredients)}
                            ref={listRef}
                        >
                            {([letter, ingredientsForLetter]) => (
                                <VisibleComponent
                                    key={letter}
                                    onEnter={() => {
                                        setVisibleLetters((prevState) =>
                                            [...prevState, letter].sort(),
                                        );
                                    }}
                                    onLeave={() => {
                                        setVisibleLetters((prevState) =>
                                            prevState.filter(
                                                (item) => item !== letter,
                                            ),
                                        );
                                    }}
                                >
                                    <div id={letter}>
                                        <Letter listType={type}>
                                            {letter}
                                        </Letter>
                                        <ViewportList
                                            items={ingredientsForLetter}
                                        >
                                            {(ingredient) => (
                                                <ListItemCheckbox
                                                    checked={value.includes(
                                                        ingredient.id,
                                                    )}
                                                    key={ingredient.id}
                                                    title={ingredient.name}
                                                    onChange={handleChange}
                                                    value={ingredient.id}
                                                    separator={
                                                        type === 'mobile'
                                                    }
                                                    disabledItemInfo={
                                                        <>
                                                            Wykluczona w Twoich
                                                            <br />
                                                            preferencjach
                                                            żywieniowych
                                                        </>
                                                    }
                                                    disabled={
                                                        ingredient.disabled
                                                    }
                                                    reverse={type === 'desktop'}
                                                />
                                            )}
                                        </ViewportList>
                                    </div>
                                </VisibleComponent>
                            )}
                        </ViewportList>
                        {showButtonAll && (
                            <div>
                                <ButtonText
                                    onClick={expandAllItems}
                                    fontSize="14px"
                                    underline
                                    text={`Rozwiń wszystkie (${count})`}
                                    variant="black"
                                />
                            </div>
                        )}
                    </ListWrapper>
                )}
            </Wrapper>
            {type === 'mobile' && !queryField.value && (
                <Alphabet listType={'mobile'}>
                    {Object.keys(groupedIngredients).map((letter, index) => (
                        <AlphabetButton
                            active={letter === activeLetter}
                            listType="mobile"
                            onClick={() => {
                                scrollToLetter(index);
                            }}
                        >
                            {letter}
                        </AlphabetButton>
                    ))}
                </Alphabet>
            )}
        </>
    );
};

const Wrapper = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
`;

const SearchInputElement = styled(SearchInput)`
    margin-bottom: 15px;
`;

const ListWrapper = styled.div<{ type: ListType }>(
    ({ type }) => css`
        ${type === 'mobile' &&
        css`
            padding-right: 30px;
        `}
    `,
);

const Alphabet = styled.div<{ listType: ListType }>(
    ({ listType }) => css`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding-bottom: 15px;

        ${listType === 'mobile' &&
        css`
            flex-direction: column;
            position: fixed;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 25px;
            padding-bottom: 0;
        `}
    `,
);

const AlphabetButton = styled.button<{
    active: boolean;
    listType: ListType;
}>(
    ({ active, listType }) => css`
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        border: none;
        background: ${active ? colors.green : 'transparent'};
        color: ${active ? colors.white : colors.text};
        border-radius: 4px;
        width: 30px;
        height: 30px;

        ${listType === 'mobile' &&
        css`
            width: 16px;
            height: 14px;
            font-size: 10px;
            font-weight: 500;
            color: ${active ? colors.white : colors.gray700};
        `}
    `,
);

const Letter = styled.p<{
    listType: ListType;
}>(
    ({ listType }) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        margin-top: 10px;
        top: 0;
        background-color: ${colors.white};
        z-index: 10;

        ${listType === 'mobile' &&
        css`
            position: sticky;
        `}
    `,
);

const EmptyStateWrapper = styled.div`
    padding-top: 28px;
`;
