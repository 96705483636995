/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import CategoryList from '../../../components/CategoryList';

const Location = ({ address, location, distCalculated, google }) => (
    <div css={{ margin: '1.5rem 0' }}>
        <p css={{ color: '#9FA2A8', fontSize: 23, fontWeight: 300 }}>
            Lokalizacja
        </p>

        <CategoryList
            categories={address.split(', ').map((el) => ({ name: el }))}
        />

        <div
            css={{
                position: 'relative',
                margin: '16px 24px 280px 0',
                filter: 'grayscale(100%)',
                transition: 'filter .3s ease-out',
                '&:hover': {
                    filter: 'grayscale(0)',
                },
            }}
        >
            <Map
                google={google}
                zoom={14}
                initialCenter={{
                    lat: location.coordinates[1],
                    lng: location.coordinates[0],
                }}
                style={{
                    width: '100%',
                    height: 240,
                    borderRadius: 24,
                    position: 'relative',
                }}
                disableDefaultUI={true}
            >
                <Marker
                    // title={'The marker`s title will appear as a tooltip.'}
                    // name={'SOMA'}
                    position={{
                        lat: location.coordinates[1],
                        lng: location.coordinates[0],
                    }}
                />
            </Map>
        </div>

        <div
            css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginRight: 24,
            }}
        >
            {distCalculated ? (
                <div
                    css={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',

                        p: {
                            color: '#9FA2A8',
                            fontSize: 22,
                            fontWeight: 700,
                        },

                        span: {
                            color: '#D3D7E0',
                            fontSize: 15,
                            fontWeight: 700,
                        },
                    }}
                >
                    <DirectionsRunIcon
                        css={{ color: '#D3D7E0', height: 50, width: 32 }}
                    />
                    <div css={{ margin: '0 16px' }}>
                        <p>{(parseInt(distCalculated / 1000, 10) / 4) * 60}</p>
                        <span>min</span>
                    </div>
                    <div>
                        <p>{parseInt(distCalculated / 100, 10) / 10}</p>
                        <span>km</span>
                    </div>
                </div>
            ) : (
                <div />
            )}

            <a
                href={`https://www.google.com/maps/dir//${address}`}
                target="_blank"
                rel="noopener noreferrer"
                css={{
                    backgroundColor: '#D3D7E0',
                    color: '#FFFFFF',
                    padding: '14px 24px',
                    minWidth: 64,
                    borderRadius: 22,
                    fontWeight: 700,
                }}
            >
                TRASA
            </a>
        </div>
    </div>
);

export default GoogleApiWrapper({
    apiKey: 'AIzaSyDyWZm088wLqg6PPXHrN-CXhxX8iIguv94',
})(Location);
