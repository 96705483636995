import styled from '@emotion/styled';
import { colors } from '@/style';
import { ReactNode } from 'react';

export interface PopupFooterStickyProps {
    children: ReactNode;
}

export const PopupFooterSticky = ({ children }: PopupFooterStickyProps) => {
    return <Footer>{children}</Footer>;
};

const Footer = styled.div`
    align-items: center;
    background: ${colors.white};
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    padding: 12px 24px;
    width: 100%;
`;
