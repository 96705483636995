import { useEffect, useState } from 'react';

export const useScrollBottom = () => {
    const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY || window.pageYOffset;
            const scrollHeight = Math.max(
                document.documentElement.scrollHeight,
                document.body.scrollHeight,
            );
            const clientHeight =
                window.innerHeight || document.documentElement.clientHeight;

            setIsScrollAtBottom(scrollTop + clientHeight >= scrollHeight);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return isScrollAtBottom;
};
