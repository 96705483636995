import { ButtonHTMLAttributes, CSSProperties, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/core';
import { colors } from '@/style';
import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';

export interface ButtonBorderNewProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: ReactNode;
    icon?: ReactNode;
    loading?: boolean;
    readonly?: boolean;
    variant?: ButtonBorderNewVariants;
    width?: CSSProperties['width'];
}

export type ButtonBorderNewVariants = 'green' | 'gray';

export const ButtonBorderNew = ({
    children,
    disabled,
    icon,
    loading,
    readonly = false,
    type = 'button',
    variant = 'green',
    width,
    ...rest
}: ButtonBorderNewProps) => {
    return (
        <ButtonBorderNewElement
            {...rest}
            disabled={disabled}
            readonly={loading || readonly}
            type={type}
            variant={variant}
            width={width}
        >
            {loading && <Spinner spinnerVariant="absolute" size={10} />}
            <ChildrenWrapper $loading={loading}>
                {icon && icon}
                {children}
            </ChildrenWrapper>
        </ButtonBorderNewElement>
    );
};

export const ButtonBorderNewVariants: Record<
    ButtonBorderNewVariants,
    SerializedStyles
> = {
    green: css`
        border: 1px solid ${colors.green};
        color: ${colors.green};
    `,
    gray: css`
        border: 1px solid ${colors.gray600};
        color: ${colors.gray600};
    `,
};

const ButtonBorderNewElement = styled.button<{
    readonly?: boolean;
    variant: ButtonBorderNewVariants;
    width?: CSSProperties['width'];
}>`
    ${({ readonly, variant, width }) => css`
        align-items: center;
        background: transparent;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        font-size: 10px;
        font-weight: 400;
        justify-content: center;
        line-height: 15px;
        outline: none;
        padding: 4px 12px;
        position: relative;
        transition: all ease-in-out 0.3s;
        width: ${width ? width : 'auto'};

        ${variant && ButtonBorderNewVariants[variant]};

        ${readonly &&
        css`
            cursor: default;
            pointer-events: none;
        `}

        &:hover {
            opacity: 0.8;
        }

        &:disabled {
            cursor: default;
            opacity: 0.5;
            pointer-events: none;
        }
    `}
`;

const ChildrenWrapper = styled.div<{ $loading?: boolean }>(
    ({ $loading }) => css`
        align-items: center;
        display: flex;
        gap: 4px;
        visibility: ${$loading ? 'hidden' : 'visible'};
    `,
);
