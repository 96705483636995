import { BasePopupNew } from '@/componentsV2/organisms/BasePopupNew/BasePopupNew';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import { ConsumptionType } from '@graphql/generated';
import { css } from '@emotion/react';
import { DistanceBox } from '@/componentsV2/molecules/DistanceBox/DistanceBox';
import { popup } from '@/AppPopups';
import { PopupHeaderPrimary } from '@/componentsV2/organisms/BasePopupNew/PopupHeaderPrimary';
import { PreferencesBox } from '@/componentsV2/molecules/PreferencesBox/PreferencesBox';
import { useFilterSettingsPopup } from './useFilterSettingsPopup';
import { YourLocalizationBox } from '@/componentsV2/molecules/YourLocalizationBox/YourLocalizationBox';
import CloseIcon from '@material-ui/icons/Close';
import NiceModal from '@ebay/nice-modal-react';
import styled from '@emotion/styled';
import { FilterSettingsFormProvider } from './FilterSettingsFormProvider';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { PopupFooterPrimary } from '@/componentsV2/organisms/BasePopupNew/PopupFooterPrimary';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';

export const FilterSettingsPopup = NiceModal.create(() => {
    return (
        <FilterSettingsFormProvider>
            <FilterSettingsPopupInner
                onCancel={() => {
                    popup.hide('FilterSettingsPopup');
                }}
            />
        </FilterSettingsFormProvider>
    );
});

interface FilterSettingsPopupInnerProps {
    onCancel: () => void;
}

export const FilterSettingsPopupInner = ({
    onCancel,
}: FilterSettingsPopupInnerProps) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    const {
        handleFormSubmit,
        fields: { localizationField, distanceField, preferencesField },
    } = useFilterSettingsPopup();

    return (
        <BasePopupNew
            header={
                <PopupHeaderPrimary
                    text="Ustawienia"
                    iconButton={{
                        icon: <CloseIcon />,
                        onClick: onCancel,
                    }}
                />
            }
            footer={
                <PopupFooterPrimary>
                    <Button
                        variant="black"
                        width="100%"
                        onClick={handleFormSubmit}
                    >
                        Zapisz
                    </Button>
                </PopupFooterPrimary>
            }
            alwaysFullHeight={!isMd}
            stickyHeader
            containerMaxWidth={'960px'}
            overlay
        >
            <div>
                <SectionTitle padding={'0 0 8px'}>
                    Twoja lokalizacja
                </SectionTitle>
                <SectionDescription>
                    Wpisz dowolny adres lub zezwól na udostępnienie lokalizacji,
                    a my wybierzemy dania oraz restauracje w Twojej okolicy.
                </SectionDescription>
                <YourLocalizationBox
                    onChange={localizationField.onChange}
                    value={localizationField.value}
                />
            </div>
            <Separator color={colors.porcelainGray} margin="20px 0" />
            <div>
                <SectionTitle padding={'0 0 8px'}>Odległość</SectionTitle>
                <SectionDescription>
                    Wybierz lub wpisz odległość, w jakiej mamy wyszukać dania i
                    restauracje
                </SectionDescription>
                <DistanceBox
                    onChange={(value) => {
                        distanceField.onChange(value);
                    }}
                    value={distanceField.value}
                />
            </div>
            <Separator color={colors.porcelainGray} margin="20px 0" />
            <div>
                <SectionTitle padding={'0 0 8px'}>Typ zamówienia</SectionTitle>
                <PreferencesBox
                    onChange={preferencesField.onChange}
                    value={preferencesField.value as ConsumptionType[]}
                />
            </div>
        </BasePopupNew>
    );
};

const SectionTitle = styled.h2<{ padding: string }>(
    ({ padding }) => css`
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        padding: ${padding};
    `,
);

const SectionDescription = styled.p`
    color: ${colors.baliHai};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding-bottom: 20px;
`;
