import React from 'react';

export const GroupModeIcon = () => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_6577_35087)">
            <path
                d="M9.33464 3.16602H6.66797V5.83268H9.33464V3.16602Z"
                fill="#A3A5B0"
            />
            <path
                d="M5.33464 11.166H2.66797V13.8327H5.33464V11.166Z"
                fill="#A3A5B0"
            />
            <path
                d="M5.33464 7.16602H2.66797V9.83268H5.33464V7.16602Z"
                fill="#A3A5B0"
            />
            <path
                d="M5.33464 3.16602H2.66797V5.83268H5.33464V3.16602Z"
                fill="#A3A5B0"
            />
            <path
                d="M9.33464 8.77935V7.16602H6.66797V9.83268H8.2813L9.33464 8.77935Z"
                fill="#A3A5B0"
            />
            <path
                d="M13.9213 8.02602L13.1413 7.24602C13.0346 7.13935 12.8613 7.13935 12.7546 7.24602L12.168 7.83268L13.3346 8.99935L13.9213 8.41268C14.028 8.30602 14.028 8.13268 13.9213 8.02602Z"
                fill="#A3A5B0"
            />
            <path
                d="M7.33398 12.6664V13.8331H8.50065L12.9473 9.38639L11.7807 8.21973L7.33398 12.6664Z"
                fill="#A3A5B0"
            />
            <path
                d="M13.3346 3.16602H10.668V5.83268H13.3346V3.16602Z"
                fill="#A3A5B0"
            />
        </g>
        <defs>
            <clipPath id="clip0_6577_35087">
                <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                />
            </clipPath>
        </defs>
    </svg>
);
