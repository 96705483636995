import React from 'react'; // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core';
import { Collapsable, Badge, Bullet } from '../../../../components/Elements';
import ForumIcon from '@material-ui/icons/Forum';

import { colors } from '../../../../style';
import { useChatUnreadMessages } from '@/services/FirebaseChatService/chats/common';
import { FIREBASE_ENV } from '@/services/FirebaseChatService/FirebaseChatService.config';

const OrderChat = ({ orderId, isFinished, waiter, link }) => {
    const { unreadMessages, allMessages, interlocutorUnreadMessages } =
        useChatUnreadMessages(`env/${FIREBASE_ENV}/chats/${orderId}`);

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                left={!waiter}
                noPadding
                oneLiner
                title={isFinished ? 'Czat Archiwum' : 'Czat'}
                badge={
                    <Badge
                        collapse
                        left={!waiter}
                        color={colors.orange}
                        show={unreadMessages > 0}
                        showAnimation
                    >
                        {unreadMessages > 0 && unreadMessages}
                    </Badge>
                }
                icon={<ForumIcon />}
                link={link ? link : !isFinished && `/orders-chat/${orderId}`} // bug prevent
                description={
                    isFinished ? (
                        <>
                            {allMessages || ''}
                            {!allMessages && 'Brak wiadomość'}
                            &nbsp;{allMessages === 1 && 'wiadomość'}
                            {allMessages > 1 && 'wiadomości'}
                        </>
                    ) : allMessages > 0 ? (
                        unreadMessages > 0 ? (
                            <>
                                <span
                                    css={{
                                        color: colors.text,
                                        fontWeight: 400,
                                    }}
                                >
                                    {unreadMessages}
                                </span>
                                &nbsp;{unreadMessages === 1 && 'Nowa wiadomość'}
                                {unreadMessages > 1 &&
                                    unreadMessages < 5 &&
                                    'Nowe wiadomości'}
                                {unreadMessages >= 5 && 'Nowych wiadomości'}{' '}
                                {waiter ? 'od klienta' : 'od Restauracji'}
                            </>
                        ) : (
                            <>
                                {interlocutorUnreadMessages !== 0 ? (
                                    <>
                                        Nieodczytane
                                        <Bullet />
                                        {interlocutorUnreadMessages}{' '}
                                        {interlocutorUnreadMessages === 1
                                            ? 'wiadomość'
                                            : 'wiadomości'}
                                    </>
                                ) : (
                                    <>Wszystkie wiadomości odczytane</>
                                )}
                            </>
                        )
                    ) : (
                        'Rozpocznij konwersację'
                    )
                }
            ></Collapsable>
        </Grid>
    );
};

export default OrderChat;
