/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useRef, useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    useHistory,
} from 'react-router-dom';
import { EzcContainer, ezcRoutes } from './EZC/pages';
import EZWIndex from './EZW/pages/Index/IndexPage';
import EZRIndex from './EZR/pages/Index/IndexPage';
import { EZWRoutes } from './EZW/pages';
import { EZRRoutes } from './EZR/pages';
import { useTopNavContext } from './context/topnav.context';
import { IOS, MOBILE } from '@constants/common.constants';
// import Version from './components/Version/Version'

import {
    ezAdminRoutePrefix,
    ezAdminRoutes,
    EzAdminContainer,
} from './EZAdmin/pages';

import {
    SadmContainer,
    sadmRoutes,
    sadmRoutePrefix,
} from './EZSuperAdmin/pages';

import Page404 from './EZC/pages/404/Page404';

import { Element as ScrollElement, Link as ScrollLink } from 'react-scroll';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import BottomSpacing from './components/BottomSpacing/BottomSpacing';
import { useAuthService } from './services/AuthService';
import { SentryRoute } from './services/SentryService';
import { ezSupportRoutes } from './EZS';
import { EzSupportContainer } from './EZS/domains/EzSupportContainer';
import { useWaiterService } from './EZW/services/WaiterService/useWaiterService';
import styled from '@emotion/styled';
import { KeyboardSpacer } from './services/KeyboardService/KeyboardSpacer';

export const useRoutes = (isAuth) => {
    const userType = useAuthService((state) => state.user?.userType);

    const isSuperAdmin = userType === 'Admin';

    return (
        <Switch>
            {isSuperAdmin && (
                <SentryRoute path={sadmRoutePrefix} component={SadmContainer} />
            )}
            <SentryRoute
                path={ezAdminRoutePrefix}
                component={EzAdminContainer}
            />
            <SentryRoute path="/support" component={EzSupportContainer} />
            <SentryRoute path="/waiter" component={EZWIndex} />
            <SentryRoute path="/rest" component={EZRIndex} />
            <SentryRoute path="*" component={EzcContainer} />
        </Switch>
    );
};

export const Scroller = ({ children, location }) => {
    const scroller = useRef();
    const { scrolled, setScrolled, data } = useTopNavContext();

    const handleScroll = () => {
        if (scroller && scroller.current && !data.isScrolledAlready) {
            if (scroller.current.scrollTop > 100) {
                if (!scrolled) setScrolled(true);
            } else {
                if (scrolled) setScrolled(false);
            }
        }
    };

    useEffect(() => {
        setScrolled(false);
        // return () => animBack && setAnimBack(false)
    }, [location, setScrolled]);

    return (
        <div
            ref={scroller}
            onScroll={handleScroll}
            id="scroller"
            css={[
                sScroller,
                {
                    ...(MOBILE && {
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }),
                },
            ]}
        >
            <ScrollElement>{children}</ScrollElement>
            {IOS && <KeyboardSpacer />}
        </div>
    );
};

export const useRoutesEzc = (isAuth) => {
    let location = useLocation();
    const history = useHistory();
    const [locationKeys, setLocationKeys] = useState([]);
    const { animBack, setAnimBack } = useTopNavContext();

    const userType = useAuthService((state) => state.user?.userType);

    const { waiterView, isLoggedIn } = useWaiterService((state) => state);

    const isSupport = userType === 'Support';

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'PUSH') {
                setAnimBack(false);
                setLocationKeys([location.key]);
            }
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                    // Handle forward event
                    setAnimBack(false);
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    // Handle back event
                    setAnimBack(true);
                }
            }
        });
    }, [locationKeys, setLocationKeys]);

    return (
        <div css={[sAnimations, animBack && sAnimationsBack]}>
            <TransitionGroup>
                <CSSTransition
                    key={location.pathname}
                    classNames="fade"
                    timeout={600}
                >
                    <Scroller location={location.key}>
                        <Switch location={location}>
                            {isAuth && isSupport && (
                                <Redirect from="/login" to="/support" />
                            )}
                            {isAuth && !isSupport && (
                                <Redirect from="/login" to="/" />
                            )}
                            {isAuth && <Redirect from="/signup" to="/" />}
                            {waiterView && isLoggedIn && !isAuth && (
                                <Redirect from="/" to="/waiter" />
                            )}

                            {ezcRoutes.map(({ authOnly, ...routeProps }) =>
                                authOnly ? (
                                    isAuth && (
                                        <SentryRoute
                                            key={routeProps.path}
                                            {...routeProps}
                                        />
                                    )
                                ) : (
                                    <SentryRoute
                                        key={routeProps.path}
                                        {...routeProps}
                                    />
                                ),
                            )}

                            <SentryRoute
                                path="/404"
                                component={Page404}
                                exact
                            />

                            <Redirect from="/account" to="/login" />
                            <Redirect from="/waiter" to="/login" />
                            <Redirect from="/rest" to="/login" />

                            <Redirect to="/404" />
                        </Switch>
                        <BottomSpacing />
                    </Scroller>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

export const useRoutesEZW = (isAuth) => {
    let location = useLocation();
    const history = useHistory();
    const [locationKeys, setLocationKeys] = useState([]);
    const { animBack, setAnimBack } = useTopNavContext();

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'PUSH') {
                setAnimBack(false);
                setLocationKeys([location.key]);
            }
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                    // Handle forward event
                    setAnimBack(false);
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    // Handle back event
                    setAnimBack(true);
                }
            }
        });
    }, [locationKeys, setLocationKeys]);

    return (
        <div css={[sAnimations, animBack && sAnimationsBack]}>
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={600}
                >
                    <Scroller location={location.key}>
                        <Switch location={location}>
                            {isAuth && (
                                <Redirect from="/waiter/login" to="/waiter" />
                            )}
                            {!isAuth && (
                                <Redirect from="/waiter/*" to="/waiter/login" />
                            )}
                            {EZWRoutes.map((el) => (
                                <SentryRoute key={el.path} {...el} />
                            ))}
                            <Redirect to={'/waiter'} />
                        </Switch>
                        <BottomSpacing />
                    </Scroller>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

export const useRoutesEZR = (isAuth) => {
    let location = useLocation();
    const history = useHistory();
    const [locationKeys, setLocationKeys] = useState([]);
    const { animBack, setAnimBack } = useTopNavContext();

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === 'PUSH') {
                setAnimBack(false);
                setLocationKeys([location.key]);
            }
            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys);
                    // Handle forward event
                    setAnimBack(false);
                } else {
                    setLocationKeys((keys) => [location.key, ...keys]);
                    // Handle back event
                    setAnimBack(true);
                }
            }
        });
    }, [locationKeys, setLocationKeys]);

    return (
        <div css={[sAnimations, animBack && sAnimationsBack]}>
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    classNames="fade"
                    timeout={600}
                >
                    <Scroller location={location.key}>
                        <Switch location={location}>
                            {!isAuth && <Redirect from="/rest/*" to="/login" />}
                            {!isAuth && <Redirect from="/rest" to="/login" />}
                            {EZRRoutes.map((el) => (
                                <SentryRoute key={el.path} {...el} />
                            ))}
                            <Redirect exact to={'/rest'} />
                        </Switch>
                        <BottomSpacing />
                    </Scroller>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};

export const useRoutesEZS = () => (
    <Switch>
        {ezSupportRoutes.map((el) => (
            <SentryRoute key={el.path} {...el} />
        ))}
        <Redirect to={'/support/transactions-by-restaurants'} />
    </Switch>
);

export const useRoutesEzAdmin = () => (
    <Switch>
        {ezAdminRoutes.map((el) => (
            <SentryRoute key={el.path} {...el} />
        ))}
        <Redirect to={ezAdminRoutePrefix + '/transactions-by-restaurants'} />
    </Switch>
);

export const useRoutesSadm = () => (
    <Switch>
        {sadmRoutes.map((el) => (
            <SentryRoute key={el.path} {...el} />
        ))}
        <Redirect to={sadmRoutePrefix + '/change-permission'} />
    </Switch>
);

const sScroller = {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    overflowY: 'scroll',
};

const sAnimations = {
    '.fade-enter-active, .fade-exit-active': {
        pointerEvents: 'none',
        // backgroundColor: 'red',
    },
    '.fade-enter, .fade-enter.fade-enter-active': {
        // transition: 'opacity 0.6s ease-in-out, transform 0.3s ease-out',
        transition:
            'opacity 0.6s ease-in, transform 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
    },
    '.fade-exit,.fade-exit.fade-exit-active': {
        // transition: 'opacity 0.6s ease-in-out, transform 0.3s ease-out',
        transition:
            'opacity 0.6s ease-out, transform 0.3s cubic-bezier(0.25, 1, 0.5, 1)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
    },
    '.fade-exit.fade-enter-exit, .fade-enter.fade-enter-active': {
        opacity: 1,
        transform: 'translateX(0px)',
    },
    '.fade-enter': {
        opacity: 0,
        transform: 'translateX(100vw)',
    },
    '.fade-exit': {
        opacity: 0,
        transform: 'translateX(-100vw)',
    },
};

const sAnimationsBack = {
    '.fade-enter': {
        opacity: 0,
        transform: 'translateX(-100vw)',
    },
    '.fade-exit': {
        opacity: 0,
        transform: 'translateX(100vw)',
    },
};
