import styled from '@emotion/styled';
import { colors } from '@/style';
import CloseIcon from '@material-ui/icons/Close';
import {
    ButtonIcon,
    ButtonIconProps,
} from '@/componentsV2/atoms/ButtonIcon/ButtonIcon';

export interface PopupHeaderPrimaryProps {
    iconButton?: ButtonIconProps;
    text: string;
}

export const PopupHeaderPrimary = ({
    iconButton,
    text,
}: PopupHeaderPrimaryProps) => {
    return (
        <Header>
            <Title>{text}</Title>
            {iconButton && <ButtonIcon {...iconButton} icon={<CloseIcon />} />}
        </Header>
    );
};

const Header = styled.div`
    align-items: center;
    background: ${colors.white};
    border-bottom: 1px solid ${colors.porcelainGray};
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
    width: 100%;
`;

const Title = styled.h1`
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: ${colors.text};
`;
