export type PriceLevel = '20' | '40' | '80' | '120' | '120+';

export const PRICE_LEVEL_ENUM: Record<PriceLevel, PriceLevel> = {
    20: '20',
    40: '40',
    80: '80',
    120: '120',
    '120+': '120+',
};

export const PRICE_LEVEL: Record<PriceLevel, { name: string }> = {
    20: { name: 'do 20 zł' },
    40: { name: 'do 40 zł' },
    80: { name: 'do 80 zł' },
    120: { name: 'do 120 zł' },
    '120+': { name: 'powyżej 120 zł' },
};

export const PRICE_LEVEL_KEYS = Object.keys(PRICE_LEVEL_ENUM) as PriceLevel[];

export const PRICE_LEVEL_VALUES = Object.entries(PRICE_LEVEL).map(
    ([id, value]) => ({
        id,
        ...value,
    }),
) as {
    id: PriceLevel;
    name: string;
}[];

/**
 * @deprecated
 */
export const PRICE_LEVELS = [
    { value: 20, label: '$' },
    { value: 40, label: '$$' },
    { value: 80, label: '$$$' },
    { value: 120, label: '$$$$' },
    { value: 150, label: '' },
];
