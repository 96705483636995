/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Navbar from './Navbar';
import TopNavFixed from '../../components/TopNavFixed/TopNavFixed';
import { sMain } from '../../EZC/components/Layout/Layout.component';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { QrCodeScanner } from './organisms/QrCodeScanner/QrCodeScanner';

const Layout = ({ children }) => {
    const { isScannerActive, setIsScannerActive } = useWaiterService();

    useEffect(() => {
        setIsScannerActive(false);
    }, []);

    return (
        <React.Fragment>
            {isScannerActive ? (
                <QrCodeScanner />
            ) : (
                <>
                    <TopNavFixed />
                    <main css={sMain}>{children}</main>
                    <Navbar />
                </>
            )}
        </React.Fragment>
    );
};

export default Layout;
