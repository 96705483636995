import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import jwt_decode from 'jwt-decode';
import { filterKeysFromObject } from '@helpers/filterKeysFromObject';
import { SessionToken, TokenUser } from '@/types/Tokens';
import { AuthSignOutDocument } from '@graphql/generated';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

interface AuthService {
    isLoggedIn: boolean;
    /** If it's `true` popup will show */
    joinToEatzon: boolean;
    refreshToken?: string;
    restaurateurPrivacyPolicyPopup: boolean;
    sessionToken?: string;
    fcmToken?: string;
    user?: TokenUser;
    isPhoneBookSent: boolean;
    restaurantId?: string;
    isLoggedWithGoogle?: boolean;
    hasAccountAlready: boolean;
    initialize: (token: string) => void;
    logout: (client: any, sentry: any) => void;
    setTokens: (
        tokens: Pick<AuthService, 'sessionToken' | 'refreshToken'>,
    ) => void;
    setRestaurantId: (restaurantId: string) => void;
    setJoinToEatzon: (value: boolean) => void;
    setFcmToken: (fcmToken: string) => void;
    setIsPhoneBookSent: (value: boolean) => void;
    setRestaurateurPrivacyPolicyPopup: (value: boolean) => void;
    setIsLoggedWithGoogle: (value: boolean) => void;
    setHasAccountAlready: (value: boolean) => void;
}

export const useAuthService = create<AuthService>()(
    devtools(
        persist(
            (set, get) => ({
                isLoggedIn: false,
                joinToEatzon: true,
                refreshToken: undefined,
                sessionToken: undefined,
                user: undefined,
                restaurateurPrivacyPolicyPopup: false,
                isPhoneBookSent: false,
                restaurantId: undefined,
                isLoggedWithGoogle: false,
                hasAccountAlready: false,
                initialize: (token) => {
                    const tokenData = jwt_decode<SessionToken>(token);
                    const user = filterKeysFromObject<SessionToken, TokenUser>(
                        tokenData,
                        ['exp', 'iat', 'tokenType'],
                    );

                    set({
                        user,
                        isLoggedIn: true,
                        joinToEatzon: false,
                        hasAccountAlready: true,
                    });
                },
                logout: async (client: any, sentry) => {
                    try {
                        if (get().fcmToken)
                            await client.mutate({
                                mutation: AuthSignOutDocument,
                                variables: {
                                    input: { fcmToken: get().fcmToken },
                                },
                            });

                        if (get().isLoggedWithGoogle) {
                            await GoogleAuth.signOut();
                        }
                        get().setIsLoggedWithGoogle(false);

                        sentry.clearScope();
                    } catch (error) {
                        console.error('logout ::', error);
                    } finally {
                        set({
                            isLoggedIn: false,
                            joinToEatzon: get().joinToEatzon,
                            isPhoneBookSent: get().isPhoneBookSent,
                            refreshToken: undefined,
                            sessionToken: undefined,
                            restaurantId: undefined,
                            user: undefined,
                            restaurateurPrivacyPolicyPopup: false,
                        });
                    }
                },
                setJoinToEatzon: (value) => {
                    set({
                        joinToEatzon: value,
                    });
                },

                setRestaurateurPrivacyPolicyPopup: (value) => {
                    set({
                        restaurateurPrivacyPolicyPopup: value,
                    });
                },

                setTokens: ({ refreshToken, sessionToken }) => {
                    set({
                        refreshToken: refreshToken || get().refreshToken,
                        sessionToken: sessionToken || get().sessionToken,
                    });
                    sessionToken && get().initialize(sessionToken);
                },

                setFcmToken: (fcmToken) => {
                    set({
                        fcmToken,
                    });
                },

                setIsPhoneBookSent: (isPhoneBookSent) => {
                    set({
                        isPhoneBookSent,
                    });
                },

                setRestaurantId: (restaurantId) => {
                    set({
                        restaurantId,
                    });
                },

                setIsLoggedWithGoogle: (value) => {
                    set({
                        isLoggedWithGoogle: value,
                    });
                },

                setHasAccountAlready: (hasAccountAlready) => {
                    set({
                        hasAccountAlready,
                    });
                },
            }),
            {
                name: 'auth',
                onRehydrateStorage: () => (state) =>
                    state?.sessionToken &&
                    state?.initialize(state.sessionToken),
                partialize: (state) =>
                    filterKeysFromObject(state, ['user', 'isLoggedIn']),
            },
        ),
    ),
);

export const AuthService = useAuthService.getState;
