/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Headline from '@components/Headline/Headline';
import {
    sGrid,
    sOuter,
    sDescription,
    sContainerOuter,
    sPaddingTop,
} from '@style/style';
import { ButtonBorder, Button } from '@components/Elements';
import { s } from '@style/index';
import { Input } from './components';
import { Grid } from '@material-ui/core';
import validateEmail from '@helpers/validateEmail';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useSendResetPasswordLinkMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

const ResetPassword = () => {
    const [inputValues, setInputValues] = useState({ email: '' });
    const [authSendResetPasswordLink, { loading }] =
        useSendResetPasswordLinkMutation();
    const history = useHistory();

    const onChange = (e) =>
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });

    const handleSubmit = () => {
        if (!validateEmail(inputValues.email)) {
            toast.error('Email nieprawidłowy');

            return;
        }

        authSendResetPasswordLink({
            variables: {
                input: { email: inputValues.email },
            },
            onCompleted: () => {
                toast.info(
                    `Link do zresetowania hasła został wysłany na podany adres email`,
                );
                history.push('/');
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return (
        <React.Fragment>
            <TopNav title={'Resetowanie Hasła'} />
            <div css={sOuter}>
                <Headline
                    itemChildren={
                        <ButtonBorder link={`/login`}>Zaloguj</ButtonBorder>
                    }
                >
                    Resetowanie
                </Headline>
                <Grid container css={sGrid}>
                    <p css={sDescription}>
                        Zresetuj hasło do twojego konta Eatzon, podając
                        powiązany z nim adres email. Na skrzynkę zostanie
                        przesłana dalsza instrukcja
                    </p>
                </Grid>
            </div>

            <Grid container css={[sContainerOuter, sGrid, sPaddingTop]}>
                <div css={[sFormContainer]}>
                    <Input
                        onChange={onChange}
                        name="email"
                        type="email"
                        value={inputValues.email}
                        placeholder="Email"
                    />
                    <Button
                        w100
                        action={handleSubmit}
                        disabled={loading}
                        loading={loading}
                        loadingSpinner={loading}
                    >
                        Resetuj hasło
                    </Button>
                </div>
            </Grid>
        </React.Fragment>
    );
};

const sFormContainer = {
    [s.sm_down]: { paddingBottom: '2rem' },
    paddingBottom: '1.5rem',
};

export default ResetPassword;
