import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style';
import {
    CheckCircle,
    Cancel,
    WatchLater as WatchLaterIcon,
} from '@material-ui/icons';
import { PAYMENT_STATUS_NAME } from '@constants/fromGraphql/PaymentStatus.constants';
import { PayoffStatus } from '@graphql/generated';

interface SplitPaymentStatusProps {
    status: PayoffStatus;
}

export const SplitPaymentStatus = ({ status }: SplitPaymentStatusProps) => {
    if (status === 'PENDING') {
        return (
            <AssignedStatus>
                <WatchLaterIcon fontSize="inherit" />
                {PAYMENT_STATUS_NAME['PENDING']}
            </AssignedStatus>
        );
    }

    if (status === 'ACCEPTED') {
        return (
            <SettledStatus>
                <CheckCircleIcon />
                {PAYMENT_STATUS_NAME['ACCEPTED']}
            </SettledStatus>
        );
    }

    if (status === 'REJECTED') {
        return (
            <RejectedStatus>
                <CancelIcon />
                {PAYMENT_STATUS_NAME['REJECTED']}
            </RejectedStatus>
        );
    }

    return null;
};

const AssignedStatus = styled.div`
    align-items: center;
    color: ${colors.text};
    display: flex;
    font-size: 12px;
    gap: 4px;
    line-height: 18px;
`;

const SettledStatus = styled.div`
    align-items: center;
    color: ${colors.green};
    display: flex;
    font-size: 12px;
    gap: 4px;
    line-height: 18px;
`;

const RejectedStatus = styled.div`
    align-items: center;
    color: ${colors.red};
    display: flex;
    font-size: 12px;
    gap: 4px;
    line-height: 18px;
`;

const CheckCircleIcon = styled(CheckCircle)`
    font-size: 12px;
`;

const CancelIcon = styled(Cancel)`
    font-size: 12px;
`;
