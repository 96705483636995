import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthService } from '@/services/AuthService';

export const useRedirectToNative = () => {
    const location = useLocation();
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    useEffect(() => {
        if (location.pathname.includes('/account') && !isLoggedIn) {
            window.location.href = 'eatzon://';
        }
    }, []);
};
