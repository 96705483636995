import { useEffect } from 'react';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useWebPushNotificationInitialize } from './useWebPushNotificationInitialize';
import {
    getMessaging,
    getToken as getMessagingToken,
    onMessage,
} from 'firebase/messaging';

export const WebPushNotificationProvider = () => {
    const messaging = getMessaging();
    const { hasPermissions } = useWebPushNotificationInitialize();
    const setWaiterFcmToken = useWaiterService((state) => state.setFcmToken);

    useEffect(() => {
        const fetchAndSetToken = async () => {
            if (hasPermissions) {
                try {
                    getMessagingToken(messaging, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                    }).then((currentToken) => {
                        if (currentToken) {
                            setWaiterFcmToken(currentToken);
                        } else {
                            console.warn(
                                'No registration token available. Request permission to generate one.',
                            );
                        }
                        onMessage(getMessaging(), (message) => {
                            new Notification(message.data?.title || '', {
                                body: message.data?.body,
                                icon: '/icons/logo512.png',
                            });
                        });
                    });
                } catch (error) {
                    console.error('Error getting device token:', error);
                }
            }
        };
        fetchAndSetToken();
    }, [hasPermissions]);

    return null;
};
