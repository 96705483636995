import { client } from '@/boot/client';
import {
    UserRefreshTokenTyped,
    WaiterRefreshTokenTyped,
} from '@graphql/generated';
import { CreateRefreshLink } from '@/links/createRefreshLink';
import { useAuthService } from '@/services/AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

export const fetchNewUserToken: CreateRefreshLink['fetchNewSessionToken'] = (
    refreshToken: string,
    sessionToken: string,
) =>
    client
        .mutate({
            mutation: UserRefreshTokenTyped,
            variables: {
                input: {
                    refreshToken,
                    sessionToken,
                },
            },
        })
        .then(({ data }) => {
            useAuthService.getState().setTokens({
                refreshToken: data?.tokens.refreshToken,
                sessionToken: data?.tokens.sessionToken,
            });
        });

export const fetchNewWaiterToken: CreateRefreshLink['fetchNewSessionToken'] = (
    refreshToken: string,
    sessionToken: string,
) =>
    client
        .mutate({
            mutation: WaiterRefreshTokenTyped,
            variables: {
                input: {
                    refreshToken,
                    sessionToken,
                },
            },
        })
        .then(({ data }) => {
            useWaiterService.getState().setTokens({
                refreshToken: data?.tokens.refreshToken,
                sessionToken: data?.tokens.sessionToken,
            });
        });
