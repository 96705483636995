export const ScannerIcon = () => {
    return (
        <svg
            width="279"
            height="279"
            viewBox="0 0 279 279"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7860_52540)">
                <path
                    d="M18.4653 29.7758L18.4771 18.9689L29.284 18.9571L29.284 15.6337L15.1419 15.6337L15.1419 29.7758L18.4653 29.7758Z"
                    fill="white"
                />
            </g>
            <g clip-path="url(#clip1_7860_52540)">
                <path
                    d="M260.535 29.7758L260.523 18.9689L249.716 18.9571L249.716 15.6337L263.858 15.6337L263.858 29.7758L260.535 29.7758Z"
                    fill="white"
                />
            </g>
            <g clip-path="url(#clip2_7860_52540)">
                <path
                    d="M18.4653 249.224L18.4771 260.031L29.284 260.043L29.284 263.366L15.1419 263.366L15.1419 249.224L18.4653 249.224Z"
                    fill="white"
                />
            </g>
            <g clip-path="url(#clip3_7860_52540)">
                <path
                    d="M260.535 249.224L260.523 260.031L249.716 260.043L249.716 263.366L263.858 263.366L263.858 249.224L260.535 249.224Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_7860_52540">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="matrix(-1 0 0 1 40 0)"
                    />
                </clipPath>
                <clipPath id="clip1_7860_52540">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(239)"
                    />
                </clipPath>
                <clipPath id="clip2_7860_52540">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="matrix(-1 0 0 -1 40 279)"
                    />
                </clipPath>
                <clipPath id="clip3_7860_52540">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="matrix(1 0 0 -1 239 279)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
