import { Flavor } from '@/graphql/generated';

export const FLAVOUR_ENUM: Record<Flavor, Flavor> = {
    BITTER: 'BITTER',
    MEATY: 'MEATY',
    OILY: 'OILY',
    SALTY: 'SALTY',
    SOUR: 'SOUR',
    SPICY: 'SPICY',
    SWEET: 'SWEET',
    DRY: 'DRY',
    UMAMI: 'UMAMI',
};

export const FLAVOUR: Record<Flavor, { name: string }> = {
    BITTER: { name: 'gorzki' },
    MEATY: { name: 'mięsny' },
    OILY: { name: 'oleisty' },
    SALTY: { name: 'słony' },
    SOUR: { name: 'kwaśny' },
    SPICY: { name: 'pikantny' },
    SWEET: { name: 'słodki' },
    DRY: {
        name: 'wytrawny',
    },
    UMAMI: {
        name: 'umami',
    },
};

export const FLAVOUR_NAME: Partial<Record<Flavor, string>> = Object.entries(
    FLAVOUR,
).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
    {},
);

export const FLAVOUR_NAME_VALUES = Object.entries(FLAVOUR_NAME).map(
    ([id, name]) => ({ id, name }),
) as {
    id: Flavor;
    name: string;
}[];

export const FLAVOUR_KEYS = Object.keys(FLAVOUR) as Flavor[];
