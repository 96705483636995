import React from 'react';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { Grid } from '@material-ui/core';
import { sOuter } from '@style/style';
import styled from '@emotion/styled';
import { s } from '@style/index';
import { Description } from '@/EZC/domains/Account/pages/AddressBookPage/AddressBookPage';
import {
    AddressForm,
    AddressFormSchemaValues,
} from '@/EZC/domains/Account/molecules/AddressForm/AddressForm';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { useUserCreateAddressMutation } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { mediaQueries } from '@style/breakpoints';

export const AddNewAddressPage = () => {
    const history = useHistory();
    const [createAddress, { loading }] = useUserCreateAddressMutation({
        onError: graphqlErrorHandler(true),
    });
    const handleSubmit = (values: AddressFormSchemaValues) => {
        createAddress({
            variables: {
                input: {
                    name: values.addressName,
                    address: values.address.address,
                    additionalInfo: values.additionalAddress,
                    coordinates: [values.address.lng, values.address.lat],
                    isDefault: values.defaultAddress,
                },
            },
            onCompleted: () => {
                toast.dark('Adres został dodany');
                history.push('/account/address-book');
            },
        });
    };
    return (
        <>
            <div>
                {/* @ts-expect-error migrate to TS */}
                <TopNav title={'Nowy adres'} />
                <div
                    /* @ts-expect-error migrate to TS */
                    css={sOuter}
                >
                    {/* @ts-expect-error migrate to TS */}
                    <Headline
                        extraCss={{ flexWrap: 'unset', alignItems: 'center' }}
                    >
                        Nowy adres
                    </Headline>

                    <Wrapper container>
                        <Description>
                            Uzupełnij wszystkie wymagane pola, takie jak nazwa
                            adresu, ulica i numer budynku. W polu „Numer
                            mieszkania, piętro...” możesz również podać
                            dodatkowe informacje.
                        </Description>
                        <AddressForm
                            children={(error) => (
                                <SubmitButton
                                    variant="black"
                                    type="submit"
                                    disabled={error}
                                    loading={loading}
                                >
                                    DODAJ ADRES
                                </SubmitButton>
                            )}
                            onSubmit={handleSubmit}
                        />
                    </Wrapper>
                </div>
            </div>
        </>
    );
};

const Wrapper = styled(Grid)`
    padding: 0 24px 48px;
    ${s.md} {
        padding: 0 32px 48px;
    }
`;

const SubmitButton = styled(Button)`
    position: fixed;
    bottom: 72px;
    left: 32px;
    right: 32px;

    ${mediaQueries.sm} {
        left: 24px;
        right: 24px;
    }
`;
