import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    useProductCreateMutation,
    useProductImageUploadLazyQuery,
    useProductUpdateMutation,
} from '@graphql/generated';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUploadImage } from '@hooks/useUploadImage';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    ProductNewPageSchema,
    parseValuesToUpsertInput,
} from './useProductForm.helpers';
import { getDirtyValues } from '@helpers/getDirtyValues';
import {
    AVAILABLE_HOURS_DEFAULT,
    CONSUMPTION_TYPE_DEFAULT,
} from '@constants/defaults.constants';
import { useAuthService } from '@/services/AuthService';

interface UseProductNewFormOptions {
    productId?: string;
    update?: boolean;
}

export const useProductForm = (options?: UseProductNewFormOptions) => {
    const history = useHistory();

    const methods = useForm({
        resolver: yupResolver(ProductNewPageSchema),
        defaultValues: {
            availableHours: AVAILABLE_HOURS_DEFAULT,
            calories: undefined,
            consumptionTypes: CONSUMPTION_TYPE_DEFAULT,
            cookMethods: [],
            cookTime: undefined,
            cuisines: [],
            description: '',
            diets: [],
            dishes: [],
            flavors: [],
            weight: undefined,
            ingredientsIds: [],
            mealTimes: [],
            name: '',
            photo: undefined,
            price: undefined,
            packagingPrice: undefined,
            spiciness: 0,
        },
    });

    const [productCreate, { loading: productCreateLoading }] =
        useProductCreateMutation();
    const [productUpdate, { loading: productUpdateLoading }] =
        useProductUpdateMutation();
    const [productImageUpload] = useProductImageUploadLazyQuery();

    const { uploadImage, loading: imageLoading } =
        useUploadImage(productImageUpload);

    const restaurantId = useAuthService((state) => state.restaurantId);

    const handleFormSubmit = methods.handleSubmit(
        async (values) => {
            const file = values.photo?.[0];
            const photoUrl = file
                ? await uploadImage(file, '800x600')
                : undefined;

            if (options?.update) {
                const dirtyValuses = getDirtyValues<
                    typeof methods.formState.dirtyFields,
                    typeof values
                >(methods.formState.dirtyFields, values);
                const payload = parseValuesToUpsertInput(dirtyValuses);

                productUpdate({
                    variables: {
                        input: {
                            productId: options.productId ?? '',
                            payload: {
                                ...payload,
                                photoUrl,
                            },
                            restaurantId: restaurantId || '',
                        },
                    },
                    onCompleted: () => {
                        toast.success('Produkt został zaktualizowany');
                        history.push('/rest/products');
                    },
                    onError: graphqlErrorHandler(true),
                });
            } else {
                productCreate({
                    variables: {
                        // @ts-expect-error TODO
                        input: {
                            ...parseValuesToUpsertInput(values),
                            photoUrl,
                            restaurantId: restaurantId || '',
                        },
                    },
                    onCompleted: () => {
                        toast.success('Produkt został dodany');
                        history.push('/rest/products');
                    },
                    onError: graphqlErrorHandler(true),
                });
            }
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );

    return {
        handleFormSubmit,
        loading: imageLoading ?? productCreateLoading ?? productUpdateLoading,
        methods,
    };
};
