import { useEffect, useState } from 'react';
import { dateFormat } from '@helpers/dateFormat';

export const useDeliveryOrderTime = (
    deliveryTime: string,
    orderSentTime: string,
) => {
    const [timeLeft, setTimeLeft] = useState<string>(
        dateFormat.calculateTimeLeft(deliveryTime),
    );
    const [timeUp, setTimeUp] = useState<boolean>(
        dateFormat.isAfterCurrentDate(deliveryTime),
    );

    const calculatedProgress = (timeElapsed: number, totalDuration: number) =>
        Math.min(100, (timeElapsed / totalDuration) * 100);

    const [progress, setProgress] = useState<number>(
        calculatedProgress(
            dateFormat.calculateTimeElapsed(orderSentTime),
            dateFormat.calculateDuration(deliveryTime, orderSentTime),
        ),
    );

    useEffect(() => {
        const totalDuration = dateFormat.calculateDuration(
            deliveryTime,
            orderSentTime,
        );

        const timer = setInterval(() => {
            const timeElapsed = dateFormat.calculateTimeElapsed(orderSentTime);

            setTimeLeft(dateFormat.calculateTimeLeft(deliveryTime));

            setProgress(calculatedProgress(timeElapsed, totalDuration));

            if (dateFormat.isAfterCurrentDate(deliveryTime)) {
                clearInterval(timer);
                setTimeUp(true);
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [deliveryTime, orderSentTime]);

    return {
        timeUp,
        timeLeft,
        progress,
    };
};
