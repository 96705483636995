import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useUnsettledPayoffsQuery } from '@graphql/generated';

export const useUnsettledPayoffs = () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    const { data } = useUnsettledPayoffsQuery({
        variables: {
            input: {
                statuses: ['PENDING'],
                isRecipient: true,
                isRequester: false,
            },
        },
        onError: graphqlErrorHandler(true),
        skip: !isLoggedIn,
    });

    const isUnsettledPayoff = !!data?.lists?.metadata?.totalCount;

    return {
        isUnsettledPayoff,
    };
};
