import { breakpointsMaterial } from '@style/breakpoints';
import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

export const useCarouselProductTileWidth = () => {
    const { width } = useWindowSize();

    const defaultItemWidth = 170;
    const minItemWidth = 140;

    const numberOfTiles = useMemo(
        () => Math.ceil(width / defaultItemWidth),
        [width],
    );

    const tileWidth = useMemo(() => Math.floor(width / numberOfTiles), [width]);

    /**
     * Carousel arrows appear in the widths defined above `breakpointsMaterial.xs` (599px), so there is no need to calculate the width of item
     */

    if (width > breakpointsMaterial.xs) return defaultItemWidth;

    return tileWidth < minItemWidth ? minItemWidth : tileWidth;
};
