/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { RestaurantItem } from '@/EZAdmin/components/RestaurantItem/RestaurantItem';

const SearchResultsRestaurants = ({ restaurants, ItemHelperComponent }) => {
    return restaurants?.length === 0 ? (
        <NoRestaurantsFound />
    ) : restaurants ? (
        restaurants.map((restaurant) => (
            <RestaurantItem
                key={restaurant.id}
                helperComponent={
                    ItemHelperComponent && (
                        <ItemHelperComponent restaurantId={restaurant.id} />
                    )
                }
                {...restaurant}
            />
        ))
    ) : null;
};

const NoRestaurantsFound = () => (
    <div css={{ margin: '16px 0' }}>
        <h4>Brak wyników.</h4>
        <p>Spróbuj doprecyzować nazwę Restauracji.</p>
    </div>
);

export default SearchResultsRestaurants;
