import { Control, useController } from 'react-hook-form';
import { ChipPreferencesValues } from './usePreferencesPopup';

export const usePreferencesPopupData = (
    control: Control<ChipPreferencesValues>,
) => {
    const { field: dietField } = useController({
        control,
        name: 'diet',
    });

    const { field: cuisineField } = useController({
        control,
        name: 'cuisine',
    });

    const { field: dishField } = useController({
        control,
        name: 'dish',
    });

    const { field: flavorField } = useController({
        control,
        name: 'flavor',
    });

    const { field: ingredientsField } = useController({
        control,
        name: 'ingredients',
    });

    const { field: allergiesField } = useController({
        control,
        name: 'allergies',
    });

    const spicinessController = useController({
        control,
        name: 'spiciness',
    });

    return {
        allergiesField,
        cuisineField,
        dietField,
        dishField,
        flavorField,
        ingredientsField,
        spicinessController,
    };
};
