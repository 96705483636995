import { Chip } from '@/componentsV2/atoms/Chip/Chip';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import styled from '@emotion/styled';
import PersonIcon from '@material-ui/icons/Person';
import { colors } from '@style/index';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useHistory } from 'react-router-dom';
import { useGroupService } from '@/services/GroupService';

interface GroupSelectorProps {
    groupName: string;
    groupData: { id: string; name: string }[];
    groupId: string;
    onGroupClick: (id: string) => void;
    cartId: string;
    totalGroups: number;
    showMore: () => void;
    initialLength: number;
}

export const GroupSelector = ({
    groupName,
    groupData,
    groupId,
    onGroupClick,
    cartId,
    totalGroups,
    showMore,
    initialLength,
}: GroupSelectorProps) => {
    const history = useHistory();
    const { setFromCart } = useGroupService();

    return (
        <Accordion
            collapsedPadding={'0'}
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <PersonIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>Grupa</Title>
                        <Description>{groupName}</Description>
                    </InfoWrapper>
                    <ButtonBorder
                        width={'max-content'}
                        onClick={() => {
                            history.push('/account/my-groups/new');
                            setFromCart(cartId);
                        }}
                    >
                        Dodaj grupę
                    </ButtonBorder>
                </ContentWrapper>
            }
        >
            <GroupWrapper>
                {groupData?.map((group) => (
                    <Chip
                        key={group.id}
                        id={group.id}
                        label={group.name}
                        variant={groupId === group.id ? 'addDark' : 'default'}
                        onClick={onGroupClick}
                    />
                ))}
                {totalGroups > initialLength && (
                    <ShowMoreOrLessButton onClick={showMore}>
                        {totalGroups > groupData.length
                            ? `Pokaż więcej (${totalGroups - groupData.length})`
                            : `Pokaż mniej`}
                    </ShowMoreOrLessButton>
                )}
            </GroupWrapper>
        </Accordion>
    );
};

const GroupWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 24px;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr 112px;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const ShowMoreOrLessButton = styled.button`
    margin-top: 15px;
    border: none;
    background-color: inherit;
    cursor: pointer;
    width: 100%;
    font-size: 12px;
    line-height: 15px;
    text-decoration: underline;
    text-align: left;
`;
