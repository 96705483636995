import {
    BasePopupProps,
    BasePopup,
} from '@/componentsV2/organisms/BasePopup/BasePopup';
import styled from '@emotion/styled';
import { StarsRating } from '@/componentsV2/atoms/StarsRating/StarsRating';
import React, { useState } from 'react';
import { colors } from '@/style';
import { mediaQueriesMaterial } from '@style/breakpoints';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import { ProductFragment, useProductRateMutation } from '@graphql/generated';
import { productHelpers } from '@helpers/product.helpers';
import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import NiceModal from '@ebay/nice-modal-react';
import { popup } from '@/AppPopups';

export interface ProductRatingPopupProps {
    onCloseModal: () => void;
    productList: Partial<ProductFragment>[];
}

export const ProductRatingPopup = NiceModal.create(
    ({ onCloseModal, productList }: ProductRatingPopupProps) => {
        const [stars, setStars] = useState(0);
        const [currentIndex, setCurrentIndex] = useState(0);

        const currentProduct = productList[currentIndex];

        const [productRate, { loading }] = useProductRateMutation();

        const handleProductRate = () => {
            productRate({
                variables: {
                    input: {
                        productId: currentProduct.id ?? '',
                        rating: stars,
                    },
                },
                onCompleted: () => {
                    setCurrentIndex((prevState) => prevState + 1);
                    setStars(0);
                    toast.success('Twoja ocena została zapisana');

                    if (currentIndex === productList.length - 1) {
                        onCloseModal();
                        popup.remove('ProductRatingPopup');
                    }
                },
                onError: graphqlErrorHandler(true),
            });
        };

        return (
            <BasePopup
                contentMargin={'20px 0 74px'}
                header={
                    <HeaderWrapper>
                        <HeaderInfo>
                            <PopupTitle>Ocena</PopupTitle>
                            {productList.length > 1 && (
                                <RatingCounter>
                                    {currentIndex + 1}/{productList.length}
                                </RatingCounter>
                            )}
                        </HeaderInfo>
                        <ButtonBorder
                            onClick={() => {
                                onCloseModal();
                                popup.remove('ProductRatingPopup');
                            }}
                        >
                            Pomiń
                        </ButtonBorder>
                    </HeaderWrapper>
                }
                footer={
                    <ButtonWrapper>
                        <Button
                            disabled={!stars}
                            loading={loading}
                            onClick={handleProductRate}
                            variant={'black'}
                            width={'100%'}
                        >
                            Zapisz ocenę
                        </Button>
                    </ButtonWrapper>
                }
            >
                <Description>
                    Podziel się swoją opinią na temat zamówionego dania
                </Description>
                <ProductTileWrapper>
                    {currentProduct && (
                        <ProductTile
                            average={currentProduct.rating ?? 0}
                            categories={productHelpers.getCategories(
                                currentProduct,
                            )}
                            imageUrl={currentProduct.photoUrl ?? ''}
                            name={currentProduct.name ?? ''}
                            productPrice={currentProduct.price ?? 0}
                            ratings={currentProduct.ratingsCount ?? 0}
                        />
                    )}
                </ProductTileWrapper>
                <StarsRatingTitle>Oceń</StarsRatingTitle>
                <StarsRatingWrapper>
                    <StarsRating
                        value={stars ?? 0}
                        onChange={(_, value) => setStars(value ?? 0)}
                    />
                </StarsRatingWrapper>
            </BasePopup>
        );
    },
);

const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const HeaderInfo = styled.div`
    align-items: center;
    display: flex;
    gap: 12px;
`;

const PopupTitle = styled.h2`
    color: ${colors.text};
    font-size: 30px;
    font-weight: 700;
`;

const RatingCounter = styled.h2`
    color: ${colors.gray600};
    font-size: 20px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
`;

export const ProductTileWrapper = styled.div`
    margin-bottom: 40px;
`;

export const StarsRatingTitle = styled.p`
    color: ${colors.text};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
`;

const StarsRatingWrapper = styled.div`
    display: flex;

    ${mediaQueriesMaterial.xs} {
        justify-content: center;
    }
`;

const ButtonWrapper = styled.div`
    position: relative;
    width: 100%;

    @media (min-width: 1480px) {
        left: 50%;
        transform: translateX(-50%);
        width: 1480px;
    }
`;
