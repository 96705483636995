/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import {
    List,
    Grid,
    LinearProgress,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
} from '@material-ui/core';
import { sGrid } from '@style/style';
import { SectionHeadline } from '@components/Elements';
import ArrowDown from '@material-ui/icons/ExpandMore';
import ArrowUp from '@material-ui/icons/ExpandLess';
import ArrowUpAll from '@material-ui/icons/KeyboardCapslock';
import { IconButton } from '@material-ui/core';
import { colors, s } from '@style';
import BottomSpacing from '@components/BottomSpacing/BottomSpacing';
import { useAddProductstoSectionLazyQuery } from '@graphql/generated';
import { useAuthService } from '@/services/AuthService';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import styled from '@emotion/styled';

function not(a, b) {
    return a.filter((aa) => b.findIndex((bb) => bb.id === aa.id) === -1);
}

function intersection(a, b) {
    return a.filter((aa) => b.findIndex((bb) => bb?.id === aa?.id) !== -1);
}

const AddProductsToSection = ({
    isNewSection,
    sectionId,
    menuSections,
    right,
    setRight,
}) => {
    const restaurantId = useAuthService((state) => state.restaurantId);

    const [getProducts, { data, error, loading }] =
        useAddProductstoSectionLazyQuery();

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getProducts({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        payload: { restaurantId, applyUserPreferences: false },
                    },
                    menu: !isNewSection ? { menuId: sectionId } : undefined,
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.productList?.metadata,
    });
    const activeSection = menuSections.find(
        (section) => section.id === sectionId,
    ) || { products: [] };

    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);

    useEffect(() => {
        setRight([...activeSection.products]);
    }, [activeSection]);

    useEffect(() => {
        if (data?.productList?.data) {
            const sectionProductsIds = right.map((el) => el.id);
            const productsToAddFromData = data.productList.data.filter(
                (product) => sectionProductsIds.indexOf(product.id) === -1,
            );
            const updatedProductsToAdd = left.filter(
                (item) =>
                    !data?.productList?.data?.some(
                        (prod) => item.id === prod?.id,
                    ),
            );
            setLeft([...productsToAddFromData, ...updatedProductsToAdd]);
        }
    }, [data, right]);

    if (loading) return <LinearProgress />;
    if (error) return null;

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.findIndex(({ id }) => id === value.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left.filter((item) => !item.isInMenu)));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items, omit) => (
        <List
            dense
            component="div"
            role="list"
            css={{
                '.MuiListItem-gutters': {
                    padding: '0 0.25rem',
                    [s.md]: { padding: '0 1.25rem' },
                },
            }}
        >
            <Grid container>
                {items.map((product) => {
                    const isChecked =
                        checked.findIndex(({ id }) => product.id === id) !== -1;

                    /* When we have omit: true (e.g. "Wszystkie produkty") and isInMenu: true checkbox have to be disabled and indeterminate, other menu even with isInMenu: true should be clickable  */
                    const isInOtherMenu = omit ? false : product?.isInMenu;

                    return (
                        <Grid item xs={12} md={6} key={product.id}>
                            <ListItem
                                role="listitem"
                                button
                                onClick={
                                    !isInOtherMenu
                                        ? handleToggle(product)
                                        : null
                                }
                                css={!isChecked && { color: colors.gray500 }}
                            >
                                <ListItemIcon
                                    css={[
                                        {
                                            '.MuiCheckbox-colorSecondary.Mui-checked':
                                                {
                                                    color: colors.orange,
                                                },
                                        },
                                        !isChecked && {
                                            '.MuiCheckbox-root': {
                                                color: colors.gray500,
                                            },
                                        },
                                    ]}
                                >
                                    <Checkbox
                                        checked={isChecked}
                                        tabIndex={-1}
                                        disableRipple
                                        disabled={isInOtherMenu}
                                        indeterminate={isInOtherMenu}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={product.name}
                                    css={{
                                        '.MuiTypography-body2': {
                                            letterSpacing: '-0.02em',
                                        },
                                    }}
                                />
                            </ListItem>
                        </Grid>
                    );
                })}
            </Grid>
            <ListItem />
        </List>
    );

    return (
        <div css={[sGrid, { flexDirection: 'column' }]}>
            <SectionHeadline>{activeSection.name}</SectionHeadline>
            {customList(right, true)}

            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '1.5rem 0rem 2rem',
                    [s.md]: {
                        padding: '3.5rem 1rem 4rem',
                        justifyContent: 'flex-start',
                    },
                }}
            >
                <IconButton
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected down"
                >
                    <ArrowDown />
                </IconButton>
                <IconButton
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all down"
                >
                    <ArrowUpAll css={{ transform: 'rotate(180deg)' }} />
                </IconButton>
                <IconButton
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all up"
                >
                    <ArrowUpAll />
                </IconButton>
                <IconButton
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected up"
                >
                    <ArrowUp />
                </IconButton>
            </div>

            <SectionHeadline noPaddingTop>Wszystkie Produkty</SectionHeadline>
            {customList(left, false)}
            {hasMore && (
                <Grid item xs={12}>
                    <ShowMoreButton
                        disabled={loading}
                        loading={loading}
                        onClick={getMoreData}
                    >
                        Pokaż więcej
                    </ShowMoreButton>
                </Grid>
            )}
            <BottomSpacing />
        </div>
    );
};

const ShowMoreButton = styled(ButtonBorder)`
    margin: 8px 0 0 16px;
`;

export default AddProductsToSection;
