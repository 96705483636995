import React from 'react'; // eslint-disable-line no-unused-vars
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sDescription, sGrid, sOuter } from '../../../style/style';
import Headline from '../../../components/Headline/Headline';
import { ButtonBorder } from '../../../components/Elements';
import { Grid } from '@material-ui/core';
import { s } from '../../../style';
import ActiveOrderItem from '../../../EZC/pages/orders/components/ActiveOrderItem';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useActivePageLazyQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import PersonIcon from '@material-ui/icons/Person';
import { uniqBy } from 'lodash';

const ActivePage = () => {
    const { restaurantId = '' } = useWaiterService();

    const [getOrders, { data: { orders } = {}, loading }] =
        useActivePageLazyQuery();
    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination) =>
            getOrders({
                context: { waiter: true },
                fetchPolicy: 'cache-and-network',
                variables: {
                    input: {
                        restaurantId,
                        filters: {
                            statuses: [
                                'PENDING',
                                'VERIFICATION',
                                'SENT_TO_RESTAURANT',
                            ],
                        },
                    },
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: orders?.metadata,
    });

    // FIXME: it should come from useActivePageLazyQuery, but apollo somehow doesn't refresh data of query
    const activeOrders = uniqBy(orders?.data, 'id').filter(
        (order) =>
            order.status === 'PENDING' ||
            order.status === 'VERIFICATION' ||
            order.status === 'SENT_TO_RESTAURANT',
    );

    return (
        <React.Fragment>
            <TopNav title={'Nowe Zamówienia'} offset={60} />
            <div css={sOuter}>
                <Headline>Nowe Zamówienia</Headline>
                <Grid container css={sGrid}>
                    {!activeOrders.length && (
                        <>
                            <p
                                css={[
                                    sDescription,
                                    {
                                        animation:
                                            'fadeIn 1s ease-out 0s both alternate',
                                    },
                                ]}
                            >
                                Brak zamówień wymagających uwagi
                            </p>
                        </>
                    )}

                    {activeOrders.map((order, index) => (
                        <ActiveOrderItem
                            id={index}
                            key={order.id}
                            orderId={order.id}
                            date={order.details.date}
                            consumptionType={order.details.type}
                            restaurantAvatar={
                                order?.owner?.personal.avatarUrl ? (
                                    <img
                                        src={order?.owner?.personal.avatarUrl}
                                        alt={'user avatar'}
                                        loading="lazy"
                                    />
                                ) : (
                                    <PersonIcon />
                                )
                            }
                            restaurantName={
                                !!order?.owner
                                    ? `${order?.owner?.personal.name ?? ''} ${
                                          order?.owner?.personal.surname ?? ''
                                      }`.trim()
                                    : `${order?.waiterNote}`
                            }
                            shortId={order.shortId}
                            isInvoice={order.hasInvoice}
                            priceSum={order.priceSum}
                            status={order.status}
                            waiter
                            needAttention
                        />
                    ))}
                    {hasMore && (
                        <Grid item xs={12}>
                            <ButtonBorder
                                action={getMoreData}
                                disable={loading}
                            >
                                Pokaż więcej
                            </ButtonBorder>
                        </Grid>
                    )}
                    <Grid item xs={12} css={sButtons}>
                        <ButtonBorder link={'/waiter/orders'}>
                            Zamówienia
                        </ButtonBorder>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};
const sButtons = {
    display: 'flex',
    [s.sm_down]: {
        paddingTop: '0.5rem',
    },
    [s.md]: {
        paddingTop: '1.5rem',
    },
};

export default ActivePage;
