/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Skeleton from '@material-ui/lab/Skeleton';
import { s, colors } from '../../style';
import { Grid } from '@material-ui/core';

const SkeletonOrder = () => {
    return (
        <Grid item xs={12} md={6} css={sContainer}>
            <div css={sContainerInner}>
                <Skeleton variant="rect" css={sBackground} />
                <Skeleton variant="circle" css={sImage} animation="wave" />
                <div
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Skeleton
                        variant="text"
                        width={160}
                        height={20}
                        animation="wave"
                    />
                    <Skeleton
                        variant="text"
                        width={80}
                        height={20}
                        animation="wave"
                    />
                </div>
            </div>
        </Grid>
    );
};

const sContainer = {
    position: 'relative',
    // width: '100%',
    height: 73,

    // backgroundColor: colors.gray200,
    marginBottom: '1rem',
    borderRadius: '1rem',
    [s.md]: {
        height: 112,
        // marginRight: '0.75rem',
        marginBottom: '1.5rem',
        '&:nth-of-type(odd)': {
            '& > div': { marginRight: '0.75rem' },
        },
        '&:nth-of-type(even)': {
            '& > div': { marginLeft: '0.75rem' },
        },
    },
};

const sContainerInner = {
    display: 'flex',
    position: 'relative',
    borderRadius: 'inherit',
    padding: '1rem',
    [s.md]: {
        padding: '2rem',
    },
};

const sBackground = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    backgroundColor: colors.gray200,
};

const sImage = {
    flexShrink: 0,
    width: 40,
    height: 40,
    marginRight: '0.75rem',
    [s.md]: {
        width: 48,
        height: 48,
        marginRight: '1rem',
    },
};

export default SkeletonOrder;
