import React from 'react';

import { Grid } from '@material-ui/core';
import { SectionHeadline } from '../../../../components/Elements';
import { sDescription, sGrid } from '../../../../style/style';
import OpenHoursTable from '../../../components/OpenHoursTable';
import { Controller, useFormContext } from 'react-hook-form';

const OpenHours = () => {
    const { control } = useFormContext();

    return (
        <Grid container css={sGrid}>
            <SectionHeadline noPaddingLeft>
                Godziny dostępności produktu
            </SectionHeadline>
            <p css={[sDescription, { marginBottom: '1rem' }]}>
                Określ godziny dostępności Produktu. Zakres 0:00 - 24:00
                oznacza, że produkt jest dostępny w pełnym zakresie działania
                Restauracji. Zakres 0:00 - 0:00 oznacza, że tego dnia produkt
                jest niedostępny.
            </p>
            <Controller
                name="availableHours"
                control={control}
                render={({ field }) => (
                    <OpenHoursTable
                        setter={(e, type) => {
                            field.onChange({
                                ...field.value,
                                [e.target.name]: {
                                    ...field.value[e.target.name],
                                    [type]: parseInt(e.target.value, 10),
                                },
                            });
                        }}
                        getter={field.value}
                    />
                )}
            />
        </Grid>
    );
};

export default OpenHours;
