import { useParams } from 'react-router-dom';
import {
    useOrderItemLabelChangeMutation,
    ProductLabel,
} from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { popup } from '@/AppPopups';
import { useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useOrderPageChangeLabel = () => {
    const orderId = useParams<{ id: string }>().id;

    const [groupMode, setGroupMode] = useState(false);

    const methods = useForm<{
        items: string[];
        label: ProductLabel | 'NOTSET';
    }>({
        defaultValues: {
            items: [],
            label: 'NOTSET',
        },
    });

    const {
        field: { onChange: onLabelChange, value: selectedLabel },
    } = useController({
        control: methods.control,
        name: 'label',
    });

    const {
        field: { onChange: onCheckedItemsChange, value: checkedItems },
    } = useController({
        control: methods.control,
        name: 'items',
    });

    const [changeItemLabel, { loading: isItemsChangeStatusLoading }] =
        useOrderItemLabelChangeMutation();

    const changeLabel = (id: string, label: ProductLabel) => {
        const handleChangeLabel = () => {
            changeItemLabel({
                context: { waiter: true },
                variables: {
                    input: {
                        orderId,
                        items: [{ id, label }],
                    },
                },
                onError: graphqlErrorHandler(true),
            });
        };
        if (label === 'CANCELED') {
            popup.show('CancelProductPopup', {
                cancelProduct: () => {
                    handleChangeLabel();
                    popup.remove('CancelProductPopup');
                },
            });
        } else {
            handleChangeLabel();
        }
    };

    const handleChangeAllProductsStatus = (
        items: string[],
        label: ProductLabel,
    ) => {
        const selectedItemsIds = items.map((itemId) => itemId);

        changeItemLabel({
            context: { waiter: true },
            variables: {
                input: {
                    orderId,
                    items: selectedItemsIds.map((itemId) => ({
                        id: itemId,
                        label,
                    })),
                },
            },
            onCompleted: () => {
                toast.info('Status został zaktualizowany');
                methods.reset();
            },
            onError: () => graphqlErrorHandler(true),
        });
    };

    const submitForm = methods.handleSubmit((value) => {
        handleChangeAllProductsStatus(value.items, value.label as ProductLabel);
    });

    const handleCheckboxChange = (items: string[], checked: boolean) => {
        const currentItems = checkedItems as string[];

        if (!currentItems.length) {
            onLabelChange('NOTSET');
        }

        let updatedItems: string[];

        if (checked) {
            updatedItems = [
                ...currentItems,
                ...items.filter((itemId) => !currentItems.includes(itemId)),
            ];
        } else {
            updatedItems = currentItems.filter(
                (currentItem) => !items.includes(currentItem),
            );
        }

        onCheckedItemsChange(updatedItems);
    };

    useEffect(() => {
        if (!checkedItems.length) {
            onLabelChange('NOTSET');
        }
    }, [checkedItems]);

    useEffect(() => {
        !groupMode && methods.reset();
    }, [groupMode]);

    const handleProductsLabelChange = (label: ProductLabel) => {
        onLabelChange(label);
    };

    return {
        changeLabel,
        checkedItems,
        darkFont: !!checkedItems.length && selectedLabel === 'NOTSET',
        groupMode,
        handleCheckboxChange,
        handleProductsLabelChange,
        isItemsChangeStatusLoading,
        selectedLabel,
        setGroupMode,
        submitForm,
    };
};
