import { Environment } from '@/graphql/generated';
import { resolveEnv } from '@helpers/resolveEnv';

export const ENVIRONMENT_ENUM: Record<Environment, Environment> = {
    Dev: 'Dev',
    Local: 'Local',
    Production: 'Production',
    Test: 'Test',
};

export const ENVIRONMENT: Record<Environment, { name: string }> = {
    Dev: {
        name: 'Dev',
    },
    Local: {
        name: 'Local',
    },
    Production: {
        name: 'Production',
    },
    Test: {
        name: 'Test',
    },
};

export const ENVIRONMENT_NAME: Partial<Record<Environment, string>> =
    Object.entries(ENVIRONMENT).reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
        {},
    );

export const ENVIRONMENT_NAME_VALUES = Object.entries(ENVIRONMENT_NAME).map(
    ([id, name]) => ({ id, name }),
) as {
    id: Environment;
    name: string;
}[];

export const SOURCE_ENVIRONMENT = () => {
    // TODO: change to custom ENV
    const REACT_APP_SENTRY_ENVIRONMENT = resolveEnv(
        'REACT_APP_SENTRY_ENVIRONMENT',
    );

    if (REACT_APP_SENTRY_ENVIRONMENT === 'staging') return ENVIRONMENT_ENUM.Dev;
    if (REACT_APP_SENTRY_ENVIRONMENT === 'production')
        return ENVIRONMENT_ENUM.Production;
    if (REACT_APP_SENTRY_ENVIRONMENT === 'client-staging')
        return ENVIRONMENT_ENUM.Test;

    return ENVIRONMENT_ENUM.Local;
};
