/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Collapsable, Bullet, Row } from '../../../../components/Elements';
import PeopleIcon from '@material-ui/icons/People';
import TimeIcon from '@material-ui/icons/QueryBuilder';
import EventIcon from '@material-ui/icons/Event';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { dateFormat } from '@helpers/dateFormat';

const OrderDate = ({ consumptionDate, consumptionPersons }) => {
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                noPadding
                oneLiner
                title={'Termin'}
                icon={<EventIcon />}
                description={
                    <div
                        css={{
                            overflowX: 'scroll',
                            display: 'flex',
                            alignItems: 'center',
                            textTransform: 'capitalize',
                        }}
                    >
                        {dateFormat.orderDate(consumptionDate)}
                        <Bullet />
                        {dateFormat.standardTime(consumptionDate)}
                        <Bullet />
                        {consumptionPersons === 10 ? '10+' : consumptionPersons}
                        <PersonIcon
                            fontSize="inherit"
                            css={{ marginLeft: '0.25rem' }}
                        />
                    </div>
                }
            >
                <Row
                    desc={'Data'}
                    icon={<CalendarTodayIcon fontSize="inherit" />}
                >
                    {dateFormat.fullDate(consumptionDate)}
                </Row>
                <Row desc={'Czas'} icon={<TimeIcon fontSize="inherit" />}>
                    {dateFormat.standardTime(consumptionDate)}
                </Row>
                <Row desc={'Osoby'} icon={<PeopleIcon fontSize="inherit" />}>
                    {consumptionPersons === 10 ? '10+' : consumptionPersons}
                </Row>
            </Collapsable>
        </Grid>
    );
};

export default OrderDate;
