/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import WarningIcon from '@material-ui/icons/Warning';
import { s, colors } from '../../../../style';

const InActivity = ({ children }) => {
    return (
        <div
            css={{
                borderRadius: 24,
                height: 32,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                fontSize: '13px',
                fontWeight: 700,
                padding: '0 1.5rem',
                color: '#ffffff',
                [s.md]: { maxWidth: '80%' },
                [s.lg]: { maxWidth: '60%' },
                background: `linear-gradient(to right, ${colors.red600}, ${colors.red800})`,
            }}
        >
            <WarningIcon css={{ marginRight: '0.75rem' }} fontSize={'14px'} />{' '}
            {children}
        </div>
    );
};

export default InActivity;
