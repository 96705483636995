import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { colors } from '@style/index';
import styled from '@emotion/styled';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import NiceModal from '@ebay/nice-modal-react';
import { usePopup } from '@/AppPopups';
import QRCode from 'react-qr-code';
import { useTheme, useMediaQuery } from '@material-ui/core';

interface QrCodePopupProps {
    qrCodeData: string;
}

export const QrCodePopup = NiceModal.create(
    ({ qrCodeData }: QrCodePopupProps) => {
        const popup = usePopup();

        const theme = useTheme();
        const isSm = useMediaQuery(theme.breakpoints.up('sm'));

        return (
            <BasePopup
                header={
                    <ModalHeader>
                        <Header>QR kod</Header>
                        <ButtonBorder onClick={() => popup.remove()}>
                            Zamknij
                        </ButtonBorder>
                    </ModalHeader>
                }
            >
                <PopupDescription>
                    Twój unikalny QR kod zamówienia. Przedstaw go kelnerowi, aby
                    szybko i łatwo zidentyfikować Twoje zamówienie.
                </PopupDescription>
                <QrContainer>
                    <QRCode
                        value={qrCodeData}
                        size={256}
                        style={{
                            height: isSm ? 'unset' : 'auto',
                            maxWidth: isSm ? 'unset' : '100%',
                            width: isSm ? 'unset' : '100%',
                        }}
                    />
                </QrContainer>
            </BasePopup>
        );
    },
);

const Header = styled.h2`
    color: ${colors.text};
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
`;

const PopupDescription = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    padding-bottom: 32px;
`;

const ModalHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const QrContainer = styled.div`
    align-items: center;
    display: flex;
    height: 450px;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
`;
