/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    FindRestaurant,
    Button,
    InputAmount,
    PopupContainer,
    SearchResultsRestaurants,
} from '@/EZAdmin/components';
import { useChargeRestaurant } from './useChargeRestaurant';
import { useFindRestaurant } from '@/EZAdmin/hooks/useFindRestaurant';
import { useController } from 'react-hook-form';
import styled from '@emotion/styled';

const EzAdminChargeRestaurant = () => {
    const {
        handleRestaurantChoose,
        isPopupOpen,
        handlePopupClose,
        onChargeRestaurant,
        control,
    } = useChargeRestaurant();

    const { field: amountField } = useController({
        control,
        name: 'amount',
    });

    const {
        getRestaurants,
        data,
        loading,
        hasMore,
        findRestaurant,
        setSearch,
    } = useFindRestaurant(false);

    const ItemHelperComponent = ({ restaurantId }) => (
        <Button onClick={() => handleRestaurantChoose(restaurantId)}>
            Wybierz
        </Button>
    );

    return (
        <React.Fragment>
            <ListWrapper>
                <FindRestaurant
                    getRestaurant={getRestaurants}
                    loading={loading}
                    findRestaurant={findRestaurant}
                    setSearch={setSearch}
                    isActive={false}
                />
                <SearchResultsRestaurants
                    restaurants={data?.restaurantList.data}
                    ItemHelperComponent={ItemHelperComponent}
                />
                {hasMore && (
                    <ButtonShowMore onClick={() => getRestaurants()}>
                        Pokaż więcej
                    </ButtonShowMore>
                )}
            </ListWrapper>

            <PopupContainer
                isOpen={isPopupOpen}
                onClose={handlePopupClose}
                headerText="Podaj kwotę "
                btnText="Obciążyć konto restauracji"
                btnOnClick={onChargeRestaurant}
            >
                <InputWrapper>
                    <InputAmount
                        value={amountField.value}
                        onChange={amountField.onChange}
                        label="Podaj kwotę"
                        name={'amount'}
                    />
                </InputWrapper>
            </PopupContainer>
        </React.Fragment>
    );
};

const ListWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 70%;
`;

const InputWrapper = styled.div`
    min-width: 320px;
    max-width: 560px;
    width: 100%;
`;

const ButtonShowMore = styled(Button)`
    margin: 16px 0 32px;
`;

export default EzAdminChargeRestaurant;
