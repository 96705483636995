import { FilterIcon } from '@/svgs/FilterIcon';
import styled from '@emotion/styled';
import { colors } from '@style/index';

interface FilterButtonProps {
    onClick: () => void;
    count: number;
}

export const FilterButton = ({ onClick, count }: FilterButtonProps) => {
    return (
        <ButtonWrapper onClick={onClick}>
            {count > 0 && <Count>{count}</Count>}
            <FilterIcon />
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.button`
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 36px;
    position: relative;
    transition: background-color 0.3s ease-in-out;
    width: 36px;

    &:hover {
        background-color: ${colors.white};
    }
`;

const Count = styled.div`
    align-items: center;
    background: ${colors.green};
    border-radius: 50%;
    border: 2px solid ${colors.white};
    color: ${colors.white};
    display: flex;
    font-size: 10px;
    font-weight: 700;
    height: 20px;
    justify-content: center;
    position: absolute;
    right: -2px;
    top: 0;
    width: 20px;
`;
