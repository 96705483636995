import { MOBILE } from '@constants/common.constants';
import { useEffect } from 'react';
import { useSearchParam } from 'react-use';

export const useWebTransactionFinished = ({
    handleOrderCreate,
    loading,
}: {
    handleOrderCreate: () => void;
    loading: boolean;
}) => {
    const paymentStatus = useSearchParam('paymentStatus');

    useEffect(() => {
        if (!loading && paymentStatus === 'CONFIRMED' && !MOBILE) {
            handleOrderCreate();
        }
    }, [paymentStatus, loading]);
};
