import React, { useContext, createContext } from 'react'; // eslint-disable-line no-unused-vars
import _useActiveOrdersListener from './hooks/_useActiveOrdersListener';
import { Popup } from './components';

export const ActiveOrdersListenerContext = createContext({
    isShown: false,
    attentionOrderIds: [],
});

export const useActiveOrdersListener = () =>
    useContext(ActiveOrdersListenerContext);

export const ActiveOrdersListenerProvider = ({ children, isAuth }) => {
    //TODO (future) add proper _useActiveOrdersListener
    const { isShown, onPopupClick, attentionOrderIds, onHide } =
        _useActiveOrdersListener({ isAuth });

    return (
        <ActiveOrdersListenerContext.Provider
            value={{
                isShown,
                attentionOrderIds,
            }}
        >
            {/* //TODO (future) add activeOrdersLisener */}
            {/* <Popup
                isShown={isShown}
                orders={attentionOrderIds}
                onClick={onPopupClick}
                onHide={onHide}
            /> */}

            {children}
        </ActiveOrdersListenerContext.Provider>
    );
};
