import React from 'react';
import { useAuthService } from '@/services/AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import jwtDecode from 'jwt-decode';
// @ts-expect-error no types
import jwtEncode from 'jwt-encode';

interface ModifyTokenProps {
    secret: string;
}

export const __DEV__ModifyToken = ({ secret }: ModifyTokenProps) => {
    const auth = useAuthService();
    const waiter = useWaiterService();

    const modifyUserToken = () => {
        const decoded = auth.sessionToken && jwtDecode(auth.sessionToken);
        const modified =
            decoded &&
            jwtEncode(
                { ...decoded, exp: Math.floor(Date.now() / 1000) - 60 * 180 },
                secret,
            );

        auth.setTokens({ sessionToken: modified });
    };
    const modifyWaiterToken = () => {
        const decoded = waiter.sessionToken && jwtDecode(waiter.sessionToken);
        const modified =
            decoded &&
            jwtEncode(
                { ...decoded, exp: Math.floor(Date.now() / 1000) - 60 * 180 },
                secret,
            );

        waiter.setTokens({ sessionToken: modified });
    };

    if (!secret) return null;

    return (
        <>
            <div style={{ overflowX: 'auto' }}>
                <pre>User: {JSON.stringify(auth, null, 4)}</pre>
                <pre>Waiter: {JSON.stringify(waiter, null, 4)}</pre>
            </div>

            <button onClick={() => modifyUserToken()}>modify user token</button>
            <button onClick={() => modifyWaiterToken()}>
                modify waiter token
            </button>
        </>
    );
};
