import { useCallback, useState } from 'react';

export const useChat = (deps: any) => {
    const [chatNode, setChatNode] = useState<HTMLDivElement | null>(null);

    const measuredRef = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                setChatNode(node);
                node.scrollIntoView({ behavior: 'instant' });
            }
        },
        [deps],
    );

    const scrollToBottom = () => {
        if (chatNode) chatNode.scrollIntoView({ behavior: 'smooth' });
    };

    return {
        measuredRef,
        scrollToBottom,
    };
};
