import styled from '@emotion/styled';
import { colors } from '@style/index';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import RoomIcon from '@material-ui/icons/Room';
import {
    AddressForm,
    AddressFormSchemaValues,
} from '@/EZC/domains/Account/molecules/AddressForm/AddressForm';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { GeolocationType } from '@/types/Geolocalization';
import StarsIcon from '@material-ui/icons/Stars';
import { popup } from '@/AppPopups';
import { useState } from 'react';

interface AddressBoxProps {
    addressName: string;
    fullAddress: GeolocationType;
    additionalAddress?: string | null;
    isDefault: boolean;
    id: string;
    onSubmit: (values: AddressFormSchemaValues, addressId?: string) => void;
    loading: boolean;
}

export const AddressBox = ({
    addressName,
    fullAddress,
    additionalAddress,
    isDefault,
    id,
    onSubmit,
    loading,
}: AddressBoxProps) => {
    const [open, setOpen] = useState(false);
    return (
        <Accordion
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <RoomIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <TitleWrapper>
                            {isDefault && (
                                <StarsIcon color="inherit" fontSize="inherit" />
                            )}
                            <Title>{addressName}</Title>
                        </TitleWrapper>
                        <DescriptionWrapper>
                            <Description>{fullAddress.address}</Description>
                        </DescriptionWrapper>
                    </InfoWrapper>
                </ContentWrapper>
            }
            isOpen={open}
            setIsOpen={setOpen}
        >
            <Wrapper>
                <AddressForm
                    children={() => (
                        <ButtonsWrapper>
                            <Button
                                variant="red"
                                onClick={() =>
                                    popup.show('AddressDeletePopup', { id })
                                }
                            >
                                USUŃ ADRES
                            </Button>
                            <Button
                                type="submit"
                                variant="black"
                                loading={loading}
                                disabled={loading}
                                onClick={() => setOpen(false)}
                            >
                                ZAPISZ ZMIANY
                            </Button>
                        </ButtonsWrapper>
                    )}
                    defaultValues={{
                        addressName,
                        address: fullAddress,
                        additionalAddress,
                        defaultAddress: isDefault,
                    }}
                    addressId={id}
                    onSubmit={onSubmit}
                    formVariant="dark"
                />
            </Wrapper>
        </Accordion>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const ButtonsWrapper = styled.div`
    display: grid;
    gap: 16px;
    padding-top: 8px;
`;

const TitleWrapper = styled.div`
    display: flex;
    gap: 4px;
    color: ${colors.green};
    font-size: 16px;
    align-items: center;
`;

const DescriptionWrapper = styled.div`
    white-space: nowrap;
    overflow-x: auto;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
    overflow: hidden;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    color: ${colors.text};
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;
