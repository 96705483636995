import { Separator } from '@/componentsV2/atoms/Separator/Separator';
import styled from '@emotion/styled';
import { colors } from '@style/index';

interface DoubleSeparatorProps {
    text?: string;
    margin?: string;
}
export const DoubleSeparator = ({ margin, text }: DoubleSeparatorProps) => (
    <SeparatorWrapper>
        <Separator margin={margin} />
        {text}
        <Separator margin={margin} />
    </SeparatorWrapper>
);

const SeparatorWrapper = styled.div`
    align-items: center;
    color: ${colors.gray600};
    display: flex;
    font-size: 15px;
    gap: 10px;
`;
