/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Bullet, StatusLarge, Badge } from '../../../components/Elements';
import { colors, s } from '../../../style';
import { Grid, Hidden } from '@material-ui/core';
import {
    sImage,
    sText,
} from '../../../components/Elements/Collapsable/Collapsable';
import {
    sLeft,
    sContainerInner,
    sTopContainer,
    sLimitText,
} from '../../../EZC/pages/orders/components/ActiveOrderItem';
import eatzonIcon from '../../../style/assets/eatzonIcon.svg';
import { dateFormat } from '@helpers/dateFormat';

const Transaction = ({ id, data }) => {
    const { type, amount, createdAt, user } = data;

    const isPayout = type === 'RESTAURANT_PAYOUT';

    const { standardTime, fullDate } = dateFormat;
    return (
        <Grid
            item
            xs={12}
            md={6}
            css={{
                animation: `fadeIn 0.3s ease-in-out`,
            }}
        >
            <div
                css={[
                    id % 2 === 1 && { [s.md]: { marginLeft: '0.75rem' } },
                    id % 2 === 0 && { [s.md]: { marginRight: '0.75rem' } },
                    {
                        display: 'flex',
                        marginBottom: '1.5rem',
                        [s.sm_down]: { marginBottom: '1rem' },
                    },
                ]}
            >
                <div css={[sContainerInner]}>
                    <div css={sTopContainer}>
                        <div css={sLeft}>
                            <div css={[sImage, { position: 'relative' }]}>
                                {isPayout ||
                                (!isPayout && user?.personal?.avatarUrl) ? (
                                    <img
                                        src={
                                            isPayout
                                                ? eatzonIcon
                                                : user?.personal?.avatarUrl
                                        }
                                        alt={
                                            isPayout
                                                ? 'Eatzon'
                                                : user.personal.avatarUrl
                                        }
                                        loading="lazy"
                                    />
                                ) : (
                                    <div
                                        css={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: '50%',
                                            backgroundColor: colors.gray400,
                                        }}
                                    />
                                )}

                                <Badge
                                    small
                                    left
                                    color={colors.orange}
                                    extraCss={{
                                        boxShadow: `0px 0px 0px 3px ${colors.gray200}`,
                                    }}
                                    // show={paymentState === 'PENDING'}
                                    showAnimation
                                />
                            </div>
                            <div css={sText}>
                                <p
                                    css={[
                                        sText.title,
                                        sText.oneLiner,
                                        sLimitText,
                                    ]}
                                >
                                    {isPayout
                                        ? 'Eatzon'
                                        : `${user.personal.name ?? ''} ${
                                              user.personal.surname ?? ''
                                          }`}
                                </p>
                                <p
                                    css={[
                                        sText.subtitle,
                                        sText.oneLiner,
                                        {
                                            display: 'flex',
                                            [s.xs]: {
                                                maxWidth:
                                                    'calc(100vw - 48px - 32px - 40px - 100px)',
                                            },
                                        },
                                    ]}
                                >
                                    {`${fullDate(createdAt)} ${standardTime(
                                        createdAt,
                                    )}`}

                                    <Bullet />
                                    {isPayout ? 'WYPŁATA' : 'SPRZEDAŻ'}
                                </p>
                            </div>
                        </div>
                        <StatusLarge
                            color={isPayout ? colors.red : colors.green}
                        >
                            {isPayout ? '-' : '+'} {amount.toFixed(2)} zł
                        </StatusLarge>
                    </div>
                </div>
            </div>
        </Grid>
    );
};

export default Transaction;
