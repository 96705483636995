export type SpicinessLevel = '1' | '2' | '3' | '4';

export const SPICINESS_LEVEL_ENUM: Record<SpicinessLevel, SpicinessLevel> = {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
};

export const SPICINESS_LEVEL: Record<SpicinessLevel, { name: string }> = {
    1: { name: 'mild' },
    2: { name: 'medium' },
    3: { name: 'spicy' },
    4: { name: 'hot' },
};

export const SPICINESS_LEVEL_KEYS = Object.keys(
    SPICINESS_LEVEL_ENUM,
) as SpicinessLevel[];

export const SPICINESS_LEVEL_VALUES = Object.entries(SPICINESS_LEVEL).map(
    ([id, value]) => ({
        id,
        ...value,
    }),
) as {
    id: SpicinessLevel;
    name: string;
}[];

/**
 * @deprecated
 */
export const SPICINESS_LEVELS = [
    { value: 1, label: 'Mild' },
    { value: 2, label: 'Medium' },
    { value: 3, label: 'Spicy' },
    { value: 4, label: 'Hot' },
    { value: 5, label: '' },
];

/**
 * @deprecated
 */
export const SPICINESS_NAMES = [
    'Mild',
    'Mild',
    'Medium',
    'Spicy',
    'Hot',
    'Hot',
];
