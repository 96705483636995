export const ProductAvatar = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="31" cy="31" r="31" fill="#BDBDBD" />
        <g clipPath="url(#clip0_4008_44866)">
            <path
                d="M37.6619 42.7562H39.3772C40.2452 42.7562 40.9582 42.0949 41.0615 41.2475L42.7665 24.2182H37.5999V20.0332H35.5642V24.2182H30.4285L30.7385 26.6362C32.5055 27.1219 34.1589 28.0002 35.1509 28.9715C36.6389 30.4389 37.6619 31.9579 37.6619 34.4379V42.7562ZM20.0332 41.7229V40.6999H35.5642V41.7229C35.5642 42.2912 35.0992 42.7562 34.5205 42.7562H21.0769C20.4982 42.7562 20.0332 42.2912 20.0332 41.7229ZM35.5642 34.4895C35.5642 26.2229 20.0332 26.2229 20.0332 34.4895H35.5642ZM20.0539 36.5665H35.5539V38.6332H20.0539V36.5665Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_4008_44866">
                <rect
                    width="24.8"
                    height="24.8"
                    fill="white"
                    transform="translate(19 19)"
                />
            </clipPath>
        </defs>
    </svg>
);
