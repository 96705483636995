import { CATEGORIES, Category } from '@constants/categories.constants';
import { ProductCategoriesFragment } from '@graphql/generated';

interface GetCategoriesOptions {
    order?: (keyof Omit<ProductCategoriesFragment, '__typename' | 'id'>)[];
}

const getCategories = (
    product?: Partial<ProductCategoriesFragment>,
    options?: GetCategoriesOptions,
) => {
    const order: GetCategoriesOptions['order'] = options?.order ?? [
        'cuisines',
        'diets',
        'dishes',
        'mealTimes',
    ];

    return order
        .reduce<string[]>((accumulator, currentValue) => {
            const currentCategory = product?.[currentValue];

            if (Array.isArray(currentCategory)) {
                return [...accumulator, ...currentCategory];
            }

            return accumulator;
        }, [])
        .map((category) => CATEGORIES[category as Category]?.name);
};

export const productHelpers = {
    getCategories,
};
