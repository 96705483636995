/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import usePayment from '@/EZC/views/Payment/hooks/usePayment';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { WalletCharge } from '@/EZC/domains/Account/organisms/WalletCharge/WalletCharge';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import NiceModal from '@ebay/nice-modal-react';
import { usePopup } from '@/AppPopups';

export interface WalletChargePopupProps {
    defaultValue?: string;
    chargeTitle: string;
    chargeDescription: string;
    popupTitle: string;
    popupDescription: string;
    mobileTransactionFinishedCallback?: () => void;
}

export const WalletChargePopup = NiceModal.create(
    ({
        defaultValue,
        chargeTitle,
        chargeDescription,
        popupTitle,
        popupDescription,
        mobileTransactionFinishedCallback,
    }: WalletChargePopupProps) => {
        const popup = usePopup();
        const { amount, isLoading, onValueChange, onSubmit } = usePayment({
            defaultValue,
            mobileTransactionFinishedCallback: () => {
                mobileTransactionFinishedCallback?.();
                popup.remove();
            },
        });

        return (
            <BasePopup
                header={
                    <ModalHeader>
                        <Header>{popupTitle}</Header>
                        <ButtonBorder onClick={() => popup.remove()}>
                            Pomiń
                        </ButtonBorder>
                    </ModalHeader>
                }
                footer={
                    <Button
                        width={'100%'}
                        variant={amount ? 'black' : 'gray'}
                        onClick={onSubmit}
                        loading={isLoading}
                    >
                        Zapłać
                    </Button>
                }
            >
                <PopupDescription>{popupDescription}</PopupDescription>
                <WalletCharge
                    expanded
                    preventCollapse
                    amount={amount}
                    isLoading={isLoading}
                    onValueChange={onValueChange}
                    title={chargeTitle}
                    description={chargeDescription}
                />
            </BasePopup>
        );
    },
);

const Header = styled.h2`
    color: ${colors.text};
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
`;

const PopupDescription = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    line-height: 22px;
    margin: 0;
    padding-bottom: 40px;
`;

const ModalHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;
