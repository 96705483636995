import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style/index';
import { Grid, ButtonBase } from '@material-ui/core';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { TableChairIcon } from '@/svgs/TableChairIcon';
import { Button } from '../Button/Button';
import { MOBILE } from '@constants/common.constants';
import { Keyboard } from '@capacitor/keyboard';

export interface SelectTableProps {
    onChangeTable: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDisplayRoomLayout: () => void;
    displayRoomLayoutDescription: string;
    changeTableNumberDescription: string;
    tableNumber: string;
}

export const SelectTable = ({
    onChangeTable,
    onDisplayRoomLayout,
    displayRoomLayoutDescription,
    changeTableNumberDescription,
    tableNumber,
}: SelectTableProps) => {
    return (
        <Accordion
            collapsedPadding="24px 32px"
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <TableChairIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>Stolik</Title>
                        <Description>Wybierz preferowane miejsce</Description>
                    </InfoWrapper>
                </ContentWrapper>
            }
        >
            <Wrapper>
                <div>
                    <Text>{displayRoomLayoutDescription}</Text>
                    <ViewButton
                        variant={'black'}
                        width={'100%'}
                        onClick={onDisplayRoomLayout}
                    >
                        Zobacz układ sali
                    </ViewButton>
                    <Text>{changeTableNumberDescription}</Text>
                    <TableChangeButton>
                        <TableChangeInput
                            onChange={(e) => onChangeTable(e)}
                            placeholder="Numer stołu"
                            value={tableNumber}
                            onKeyDown={(event) => {
                                if (MOBILE && event.key === 'Enter') {
                                    Keyboard.hide();
                                }
                            }}
                        />
                    </TableChangeButton>
                </div>
            </Wrapper>
        </Accordion>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const Text = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
`;

const ViewButton = styled(Button)`
    margin: 24px 0 32px;
`;

const TableChangeButton = styled(ButtonBase)`
    width: 100%;
    background-color: ${colors.gray250};
    border-radius: 25px;
    margin-top: 24px;
`;
const TableChangeInput = styled.input`
    width: 100%;
    padding: 16px 24px;
    border: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: bold;
    font-family: Poppins;
`;
