import React from 'react';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { client } from '@/boot/client';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { Grid } from '@material-ui/core';
import { sDescription } from '@style/style';
import { sOuter, sGrid } from '@style/style';
import { toast } from 'react-toastify';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { useAuthService } from '@/services/AuthService';
import { useDeleteAccountMutation } from '@graphql/generated';
import { useHistory } from 'react-router-dom';
import Headline from '@components/Headline/Headline';
import styled from '@emotion/styled';
import { sentry } from '@/services/SentryService';

const DeleteAccount = () => {
    const history = useHistory();
    const logout = useAuthService((state) => state.logout);

    const [deleteUserAccount, { loading }] = useDeleteAccountMutation();

    const onReset = () => {
        history.goBack();
    };
    const onConfirm = () =>
        deleteUserAccount({
            onCompleted: () => {
                toast.dark('Konto zostało usunięte');
                logout(client, sentry);
            },
            onError: graphqlErrorHandler(true),
        });

    return (
        <React.Fragment>
            <TopNav title={'Usuń konto'} />
            <div css={{ ...sOuter, height: '75vh' }}>
                <Headline>Usuń konto</Headline>
                <Grid
                    container
                    css={{
                        ...sGrid,
                        height: '100%',
                    }}
                >
                    <div>
                        <p
                            css={{
                                fontSize: '15px',
                                fontWeight: 400,
                                lineHeight: '19px',
                                paddingBottom: '12px',
                                position: 'relative',
                            }}
                        >
                            Czy napewno chcesz usunąć konto?
                        </p>
                        <div
                            css={{
                                ...sDescription,
                                fontSize: '15px',
                                fontWeight: 400,
                            }}
                        >
                            Usunięcie konta jest nieodwracalne, nastąpi
                            usunięcie Twoich preferencji kulinarnych, historii
                            zamówień i rekomendacji.
                        </div>
                    </div>
                    <div
                        css={{
                            position: 'absolute',
                            cursor: 'pointer',
                            padding: '0 1.5rem',
                            bottom: '0',
                            left: '0',
                            width: '100%',
                        }}
                    >
                        <ButtonsWrapper>
                            <Button
                                onClick={onReset}
                                width="100%"
                                variant="gray"
                            >
                                ODRZUĆ
                            </Button>
                            <Button
                                onClick={onConfirm}
                                width="100%"
                                variant="black"
                                loading={loading}
                            >
                                USUŃ KONTO
                            </Button>
                        </ButtonsWrapper>
                    </div>
                </Grid>
            </div>
        </React.Fragment>
    );
};

const ButtonsWrapper = styled.div`
    display: grid;
    grid-gap: 16px;
`;

export default DeleteAccount;
