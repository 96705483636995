// import { React, createContext, useContext, useState } from 'react'

// const defaultState = {
//   ref: null,
//   data: {},
//   setRef: () => {},
//   setData: () => {},
// }

// const TopNavContext = createContext(defaultState)

// export const TopNavProvider = ({ children }) => {
//   const [ref, setRefState] = useState(null)
//   const [data, setDataState] = useState({})

//   const setRef = (value) => setRefState(value)
//   const setData = (value) => setDataState(value)

//   return (
//     <TopNavContext.Provider
//       value={{
//         ref,
//         data,
//         setRef,
//         setData,
//       }}>
//       {children}
//     </TopNavContext.Provider>
//   )
// }

// export default TopNavContext

// export const useTopNavContext = () => useContext(TopNavContext)

import { createContext, useContext } from 'react';

function noop() {}

export const TopNavContext = createContext({
    ref: null,
    data: {},
    animBack: false,
    setRef: noop,
    setData: noop,
    setAnimBack: noop,
});

export const useTopNavContext = () => useContext(TopNavContext);
