import {
    zlotyToGroshi,
    groshiToZloty,
    showZlotyWithPrecision,
} from './utils/converters';

/**
 * MoneyEz works with złoty, not groshi.
 * Amount must be złoty, e.g 19zł || 29,89zł.
 * It converts to Int, groshi: 19zł => 1900
 * All operations are making on Int (groshi)
 *
 * @param {Number} amount If amount === null || amount === undefined -> amount = 0.
 * @returns Object
 */
const MoneyEz = (amount) => {
    // amount is Zloty.
    const groshi = zlotyToGroshi(amount);

    return {
        getAmount() {
            return amount;
        },

        getAmountWithFullPrecision() {
            return showZlotyWithPrecision(amount);
        },

        getAmountWithFullPrecisionAndZl() {
            return showZlotyWithPrecision(amount).concat('zł');
        },

        getGroshi() {
            return groshi;
        },

        add(money) {
            const groshiToAdd = zlotyToGroshi(money);
            const zloty = groshiToZloty(groshi + groshiToAdd);
            return MoneyEz(zloty);
        },

        sumProductArray(productsArray) {
            let groshi = 0;
            for (const product of productsArray) {
                groshi += zlotyToGroshi(product.price) * product.amount;
            }
            const zloty = groshiToZloty(groshi);
            return MoneyEz(zloty);
        },

        subsctract(money) {
            const groshiToSubstract = zlotyToGroshi(money);
            const zloty = groshiToZloty(groshi - groshiToSubstract);
            return MoneyEz(zloty);
        },

        multiply(number) {
            const result = Math.round(groshi * number);
            const zloty = groshiToZloty(result);
            return MoneyEz(zloty);
        },
    };
};

export default MoneyEz;
