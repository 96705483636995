import { GaService } from '@/services/GaService';
import { MOBILE } from '@constants/common.constants';
import { FirebaseAnalyticsService } from '@/services/FirebaseAnalyticsService';

const createCustomEvent = ({
    name,
    params,
}: {
    name: string;
    params: { [key: string]: unknown };
}) => {
    if (MOBILE) {
        FirebaseAnalyticsService.logEvent(name, params);
    } else {
        GaService.event(name, params);
    }
};

export const AnalyticsHelpers = {
    createCustomEvent,
};
