import { CLIENT_ERROR_MESSAGES } from '@constants/errors/clientErrorMessages';
import { ImageFileExtension, MAX_IMAGE_SIZE } from '@constants/files';
import { ProductCreateInput } from '@graphql/generated';
import { ProductUpdatePayloadInput } from '@graphql/generated';
import {
    ConsumptionType,
    CookMethod,
    Cuisine,
    Diet,
    Dish,
    Flavor,
    MealTime,
    Vat,
} from '@graphql/generated';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { getFileType } from '@helpers/getFileType';
import { checkImageExtension } from '@helpers/image.helpers';
import * as yup from 'yup';

export const parseValuesToUpsertInput = (
    values: Partial<ProductNewPageValues>,
): ProductCreateInput | ProductUpdatePayloadInput => ({
    availableHours:
        values.availableHours && convertTimestamps.toISO(values.availableHours),
    calories: values.calories,
    consumptionTypes: values.consumptionTypes,
    cookMethods: values.cookMethods,
    cookTime: values.cookTime,
    cuisines: values.cuisines,
    description: values.description,
    diets: values.diets,
    dishes: values.dishes,
    flavors: values.flavors,
    weight: values.weight,
    ingredientsIds: values.ingredientsIds,
    mealTimes: values.mealTimes,
    name: values.name,
    price: values.price,
    packagingPrice: values.packagingPrice ?? 0,
    spiciness: values?.spiciness,
    vat: values.vat,
});

const availableHoursDay = yup.object({
    open: yup.number().required(),
    close: yup.number().required(),
});

export const ProductNewPageSchema = yup.object({
    availableHours: yup.object({
        friday: availableHoursDay,
        monday: availableHoursDay,
        saturday: availableHoursDay,
        sunday: availableHoursDay,
        thursday: availableHoursDay,
        tuesday: availableHoursDay,
        wednesday: availableHoursDay,
    }),
    calories: yup
        .number()
        .nullable()
        .min(1, 'Minimalna kaloryczność to 1 kcal')
        .max(2500, 'Maksymalna kaloryczność to 2500 kcal'),
    consumptionTypes: yup
        .array()
        .of(yup.string<ConsumptionType>().required())
        .min(1, 'Podaj typy zamówień'),
    cookMethods: yup.array().of(yup.string<CookMethod>().required()),
    cookTime: yup
        .number()
        .min(1, 'Podaj czas przygotowania')
        .required('Podaj czas przygotowania'),
    cuisines: yup.array().of(yup.string<Cuisine>().required()),
    description: yup.string(),
    diets: yup.array().of(yup.string<Diet>().required()),
    dishes: yup.array().of(yup.string<Dish>().required()),
    flavors: yup.array().of(yup.string<Flavor>().required()),
    weight: yup
        .number()
        .min(1, 'Minimalna gramatura to 1g lub 1ml')
        .max(2500, 'Maksymalna gramatura to 2500g lub 2500ml')
        .required('Podaj gramaturę'),
    ingredientsIds: yup
        .array()
        .of(yup.string().required())
        .min(1, 'Podaj składniki'),
    mealTimes: yup.array().of(yup.string<MealTime>().required()),
    name: yup.string().required('Podaj nazwę'),
    photo: yup
        .mixed<Record<number, File>>()
        .test('fileSize', CLIENT_ERROR_MESSAGES.MAX_IMAGE_SIZE, (value) => {
            if (!value?.[0]) return true;

            return value[0].size <= MAX_IMAGE_SIZE;
        })
        .test(
            'imageType',
            CLIENT_ERROR_MESSAGES.INCORRECT_IMAGE_TYPE,
            (value) => {
                if (!value?.[0]) return true;

                const type = getFileType(value?.[0]);

                return checkImageExtension(type as ImageFileExtension);
            },
        ),
    price: yup.number().required('Podaj cenę'),
    packagingPrice: yup.number().nullable(),
    spiciness: yup.number(),
    vat: yup.string<Vat>().required('Podaj VAT'),
});

export interface ProductNewPageValues
    extends yup.InferType<typeof ProductNewPageSchema> {}
