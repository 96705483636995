import { ANDROID } from '@constants/common.constants';
import semver from 'semver';

const isCurrentVersionLoverThanAvailable = (
    currentVersion: string,
    availableVersion: string,
) => {
    if (ANDROID) {
        return parseInt(currentVersion) < parseInt(availableVersion);
    } else {
        return semver.lt(currentVersion, availableVersion);
    }
};

export const ForceUpdateHelpers = {
    isCurrentVersionLoverThanAvailable,
};
