export const LocationPinIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.392 3.424a4.753 4.753 0 0 0-6.783 0 4.916 4.916 0 0 0 0 6.889L8 13.747l3.392-3.434a4.916 4.916 0 0 0 0-6.89ZM8 14.6l-.427.421-3.818-3.866c-2.34-2.369-2.34-6.206 0-8.575a5.953 5.953 0 0 1 8.49 0c2.34 2.369 2.34 6.206 0 8.575l-3.818 3.866L8 14.6Zm0 0 .427.42a.602.602 0 0 1-.854.001L8 14.601Zm0-9.4a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4ZM5.6 6.4a2.4 2.4 0 1 1 4.8 0 2.4 2.4 0 0 1-4.8 0Z"
                fill="#555F6D"
            />
        </svg>
    );
};
