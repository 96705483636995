import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { Button } from '@/componentsV2/molecules/Button/Button';
import {
    useAcceptOrderChangesPopupQuery,
    usePartialAcceptanceDecisionMutation,
    CanceledOrderProductFragment,
} from '@graphql/generated';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import { popup } from '@/AppPopups';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import NiceModal from '@ebay/nice-modal-react';

interface CancelOrderOrProductPopupInnerProps {
    items: CanceledOrderProductFragment[];
    restaurantName: string;
    onAcceptOrder: () => void;
    onRejectOrder: () => void;
    loading: boolean;
}

interface CancelOrderOrProductPopupProps {
    orderId: string;
}

export const AcceptOrderChangesPopup = NiceModal.create(
    ({ orderId }: CancelOrderOrProductPopupProps) => {
        const { data } = useAcceptOrderChangesPopupQuery({
            fetchPolicy: 'cache-and-network',
            onError: graphqlErrorHandler(true),
            variables: { input: { orderId } },
        });

        const [partialAcceptanceDecision, { loading }] =
            usePartialAcceptanceDecisionMutation();

        return (
            <AcceptOrderChangesPopupInner
                items={data?.order.items || []}
                restaurantName={data?.order.restaurant.name || ''}
                onAcceptOrder={() => {
                    partialAcceptanceDecision({
                        variables: { input: { orderId, decision: true } },
                        onCompleted: () =>
                            popup.remove('AcceptOrderChangesPopup'),
                        onError: graphqlErrorHandler(true),
                    });
                }}
                onRejectOrder={() => {
                    partialAcceptanceDecision({
                        variables: { input: { orderId, decision: false } },
                        onCompleted: () =>
                            popup.remove('AcceptOrderChangesPopup'),
                        onError: graphqlErrorHandler(true),
                    });
                }}
                loading={loading}
            />
        );
    },
);

export const AcceptOrderChangesPopupInner = ({
    items,
    restaurantName,
    onAcceptOrder,
    onRejectOrder,
    loading,
}: CancelOrderOrProductPopupInnerProps) => {
    return (
        <BasePopup
            children={
                <div>
                    <Description>
                        Wymienione produkty zostały odrzucone przez restaurację{' '}
                        {restaurantName} z Twojego zamówienia. Zaakceptuj
                        odrzucone produkty, które nie znajdą się w Twoim
                        zamówieniu.
                    </Description>

                    {items
                        .filter((item) => item.status === 'CANCELED')
                        .map((item) => (
                            <ItemWrapper>
                                <ProductTile
                                    name={item.product.name}
                                    imageUrl={item.product.photoUrl || ''}
                                    productPrice={item.product.price}
                                    ratings={item.product.ratingsCount}
                                    average={item.product.rating}
                                    categories={[]}
                                />
                            </ItemWrapper>
                        ))}
                </div>
            }
            footer={
                <Footer>
                    <Button
                        loading={loading}
                        variant={'red'}
                        onClick={onRejectOrder}
                    >
                        Rezygnuję z zamówienia
                    </Button>
                    <Button
                        loading={loading}
                        variant={'greenGradient'}
                        onClick={onAcceptOrder}
                    >
                        Akceptuję
                    </Button>
                </Footer>
            }
            header={<Header>Zaakceptuj zmiany zamówienia</Header>}
            overlayBackground
        />
    );
};

const Header = styled.h2`
    color: ${colors.text};
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ItemWrapper = styled.div`
    padding: 5px;
`;
