/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Bar } from 'react-chartjs-2';

import { Grid } from '@material-ui/core';
import { dateFormat } from '@helpers/dateFormat';

export function dataParse(data) {
    const { dayAndMonth } = dateFormat;

    const labels = data.map((el) => dayAndMonth(new Date(el.label)));
    const values = data.map((el) => el.value);

    return {
        labels,
        values,
    };
}

export default ({ orders, loading, error }) => {
    if (loading) return null;
    if (error) return null;

    const { labels, values } = dataParse(orders ?? []);

    return (
        <Grid item xs={12} lg={6}>
            <Bar
                data={dataBar(labels, values)}
                height={300}
                options={{
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    callback: (value) => {
                                        if (value % 1 === 0) {
                                            return value;
                                        }
                                    },
                                    min: 0,
                                },
                            },
                        ],
                    },
                }}
            />
        </Grid>
    );
};

const dataBar = (labels, data) => ({
    labels,
    datasets: [
        {
            label: 'Zamówienia',
            data,

            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
        },
    ],
});
