/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    Grid,
    TextField,
    FormControlLabel,
    Switch,
    Collapse,
} from '@material-ui/core';
import { Collapsable, SectionHeadline, Bullet } from '@components/Elements';
import { sDescription, sGrid } from '@style/style';
import { useRestaurantContext } from '@/EZR/pages/Restaurant/context/restaurant.provider';
import { sInput, sInputDarker } from '@/EZR/style/input.style';
import { colors, s } from '@style';

import FreeIcon from '@material-ui/icons/CheckCircle';
import NotFreeIcon from '@material-ui/icons/Cancel';
import { CollapsableText } from '@components/Elements/Collapsable/Collapsable';

const DeliverySettings = () => {
    const [isOpen, setIsOpen] = useState(false);

    const {
        deliverySettings: { deliveryRadius, minAmount, freeDeliveryAmount },
        handleDeliverySettings,
    } = useRestaurantContext();

    const isFree = freeDeliveryAmount !== null;

    return (
        <Grid container css={sGrid}>
            <Grid xs={12}>
                <SectionHeadline noPaddingLeft>
                    Ustawienia Dostaw
                </SectionHeadline>
                <p css={[sDescription, { marginBottom: '0rem' }]}>
                    Ustal maksymalny promień dostawy, minmalną wartość
                    zamówienia oraz warunki darmowej dostawy.
                </p>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                css={{ [s.md]: { paddingRight: '0.75rem' } }}
            >
                <TextField
                    label="Promień dostawy (metry)"
                    name="name"
                    value={deliveryRadius}
                    onChange={handleDeliverySettings('deliveryRadius')}
                    fullWidth
                    css={[sInput]}
                />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                css={{ [s.md]: { paddingLeft: '0.75rem' } }}
            >
                <TextField
                    label="Minimalna kwota zamówienia (ZŁ)"
                    name="name"
                    value={minAmount}
                    onChange={handleDeliverySettings('minAmount')}
                    fullWidth
                    css={[sInput]}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Collapsable
                    left
                    noPadding
                    oneLiner
                    title={'Darmowa Dostawa'}
                    icon={
                        isFree ? (
                            <FreeIcon fontSize="inherit" />
                        ) : (
                            <NotFreeIcon fontSize="inherit" color={'#ff0000'} />
                        )
                    }
                    description={
                        isFree ? (
                            <>
                                <strong css={{ color: colors.green }}>
                                    Aktywna
                                </strong>

                                <Bullet />
                                <strong>od {freeDeliveryAmount} ZŁ</strong>
                            </>
                        ) : (
                            <>
                                <strong css={{ color: colors.red }}>
                                    Nieaktywna
                                </strong>
                            </>
                        )
                    }
                    parentIsOpen={isOpen}
                    parentSetIsOpen={setIsOpen}
                >
                    <CollapsableText>
                        <div css={{ display: 'flex', flexDirection: 'column' }}>
                            <div
                                css={{ display: 'flex', alignItems: 'center' }}
                            >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isFree}
                                            onChange={handleDeliverySettings(
                                                'freeDeliveryAmountCheckbox',
                                            )}
                                            name="isSendingClientPushes"
                                            color="primary"
                                        />
                                    }
                                ></FormControlLabel>
                                <span
                                    css={{
                                        fontWeight: 700,
                                        color: colors.text,
                                        fontSize: '13px',
                                    }}
                                >
                                    {isFree
                                        ? 'Wyłącz darmową dostawę'
                                        : 'Włącz darmową dostawę'}
                                </span>
                            </div>
                            <Collapse in={isFree}>
                                <TextField
                                    label="Od kwoty (ZŁ)"
                                    name="name"
                                    value={freeDeliveryAmount || 0}
                                    onChange={handleDeliverySettings(
                                        'freeDeliveryAmount',
                                    )}
                                    fullWidth
                                    css={[
                                        sInput,
                                        sInputDarker,
                                        {
                                            marginBottom: '1rem',
                                            marginTop: '1.5rem',
                                        },
                                    ]}
                                />
                            </Collapse>
                        </div>
                    </CollapsableText>
                </Collapsable>
            </Grid>
        </Grid>
    );
};

export default DeliverySettings;
