import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom';

import { ProdItemMenuList } from '../../components/ProdItem';

import { sButtonFixed } from './OrderPage';
import { Button, ButtonBorder } from '../../../components/Elements';
import { sOuter } from '../../views/RestaurantPage/RestaurantMenuSection.view';
import { Element as ElementScroll } from 'react-scroll';
import { s } from '../../../style';
import { Grid } from '@material-ui/core';
import NavRestMenu from '../../views/RestaurantPage/components/NavRestMenu';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { useTopNavContext } from '../../../context/topnav.context';
import {
    useActiveOrderMenuQuery,
    useOrderUserItemAddMutation,
    useUserAvailableCoinsQuery,
} from '@graphql/generated';
import { useSearchParam } from 'react-use';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import Headline from '@components/Headline/Headline';
import { sDescription, sGrid, sOuter as sOuterPlaceholder } from '@style/style';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import { toast } from 'react-toastify';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { popup } from '@/AppPopups';
import { showZlotyWithPrecision } from '@helpers/MoneyEz/utils/converters';
import { priceFormat } from '@helpers/priceFormat';

export default () => {
    const history = useHistory();
    const restaurantId = useSearchParam('restId');
    const orderId = useSearchParam('orderId');

    const { setAnimBack } = useTopNavContext();
    const [cart, setCart] = useState([]);

    const { data, loading, error } = useActiveOrderMenuQuery({
        fetchPolicy: 'cache-and-network',
        onError: graphqlErrorHandler(true),
        variables: {
            orderInput: { orderId },
            restaurantInput: { restaurantId },
        },
        skip: !restaurantId,
    });

    const { data: userData } = useUserAvailableCoinsQuery();

    const [updateOrder, { loading: updateLoading }] =
        useOrderUserItemAddMutation();

    const { bottomNavigation } = useSafeAreaInsets();

    const menu = data?.restaurant.menu;
    const status = data?.order.status;
    const consumptionType = data?.order.details.type;

    const addMoreAvailable =
        (consumptionType !== 'IN' && status === 'VERIFICATION') ||
        (consumptionType === 'IN' &&
            (status === 'ACCEPTED' ||
                status === 'PREPARING' ||
                status === 'COMPLETED'));

    const onSendProducts = () => {
        if (!cart?.length) {
            toast.warning('Dodaj produkty do zamówienia');
            return;
        }

        const userAvailableCoins = userData?.user?.coinsAvailable;

        const totalProductCost = showZlotyWithPrecision(
            cart.reduce((sum, curr) => curr.price * curr.quantity + sum, 0),
        );

        const totalCost = parseFloat(
            priceFormat.standardPrice(totalProductCost),
        );

        updateOrder({
            variables: {
                input: {
                    orderId: orderId,
                    productIds: cart.flatMap((product) =>
                        Array.from(
                            { length: product.quantity },
                            () => product.id,
                        ),
                    ),
                },
            },
            onError: (error) => {
                graphqlErrorHandler({
                    omitErrorCodes: ['CANNOT_CREATE_ORDER_ADDITION_ERROR'],
                    callbackOnErrorCode: {
                        CANNOT_CREATE_ORDER_ADDITION_ERROR: () =>
                            popup.show('WalletChargePopup', {
                                defaultValue: Math.ceil(
                                    totalCost - userAvailableCoins,
                                ).toString(),
                                chargeTitle: 'Opłać zamówienie',
                                chargeDescription:
                                    'Przelej środki, aby zrealizować zamówienie.',
                                popupTitle: 'Opłać zamówienie',
                                popupDescription:
                                    'Obecnie nie masz wystarczającej ilości środków, aby złożyć zamówienie.',
                            }),
                    },
                })(error);
            },
            onCompleted: () => {
                toast.info('Do Twojego zamówienia dodano produkt');
                history.push(`/order/${orderId}`);
            },
        });
    };

    const addProduct = (product) => {
        const { id, name, price, photoUrl } = product;
        let isProductExist = cart.find((product) => product.id === id);

        if (!isProductExist) {
            setCart([...cart, { id, name, price, photoUrl, quantity: 1 }]);
            return;
        }

        setCart(
            cart.map((product) =>
                product.id !== id
                    ? product
                    : { ...product, quantity: product.quantity + 1 },
            ),
        );
    };

    const deleteProduct = ({ id }) => {
        let product = cart.find((product) => product.id === id);

        if (product.quantity === 1) {
            setCart(cart.filter((prod) => prod.id !== id));
        } else {
            setCart(
                cart.map((product) =>
                    product.id !== id
                        ? product
                        : { ...product, quantity: product.quantity - 1 },
                ),
            );
        }
    };

    useEffect(() => {
        if (!loading && !addMoreAvailable) {
            toast.info('Nie możesz dodawać produktów do tego zamówienia');
            history.push(`/order/${orderId}`);
        }
    }, [addMoreAvailable, loading]);

    if (loading) {
        return (
            <React.Fragment>
                <div css={sOuterPlaceholder}>
                    <Headline>Menu</Headline>
                    <Grid container css={sGrid}>
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    if (!orderId || !restaurantId || error || !menu.length) {
        return (
            <React.Fragment>
                <div css={sOuterPlaceholder}>
                    <Headline>Menu</Headline>
                    <p css={sDescription}>Nie odnaleziono menu restauracji</p>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TopNav
                isScrolledAlready
                title={data?.restaurant?.name}
                photo={data?.restaurant?.avatarUrl}
                textCss={{ maxWidth: `calc(100vw - ${48 + 32 + 30 + 36}px)` }}
                extraCssOuter={{ boxShadow: 'none' }}
            >
                <ButtonBorder
                    extraCss={{ backgroundColor: 'white' }}
                    action={() => {
                        setAnimBack(true);
                        history.push(`/order/${orderId}`);
                    }}
                >
                    Wróć
                </ButtonBorder>
            </TopNav>
            <NavRestMenu items={menu.map((section) => section.name)} />

            <div css={[sOuter, { marginTop: '7rem' }]}>
                {menu.map((section, sectionId) => (
                    <ElementScroll
                        name={encodeURIComponent(section.name)}
                        key={sectionId}
                    >
                        <div
                            key={sectionId}
                            css={{
                                paddingTop: '3rem',
                                [s.md]: { paddingTop: '4rem' },
                            }}
                        >
                            <p
                                css={{
                                    fontSize: '22px',
                                    fontWeight: 700,
                                    whiteSpace: 'nowrap',
                                    letterSpacing: '-0.05em',
                                    paddingLeft: '1rem',
                                    marginBottom: '1.5rem',
                                    [s.xs]: { fontSize: '22px' },
                                    [s.md]: { paddingLeft: '2rem' },
                                }}
                            >
                                {section.name}
                            </p>
                            <Grid container>
                                {section.products.map((product, index) => {
                                    const onAddProduct = () => {
                                        addProduct(product);
                                    };
                                    const onDeleteProduct = () => {
                                        deleteProduct({
                                            id: product.id,
                                        });
                                    };
                                    return (
                                        <ProdItemMenuList
                                            id={index}
                                            key={product.id}
                                            data={product}
                                            handleAddProduct={onAddProduct}
                                            handleDeleteProduct={
                                                onDeleteProduct
                                            }
                                            productInOrder={cart?.find(
                                                (prod) =>
                                                    prod.id === product.id,
                                            )}
                                            orderI={orderId}
                                            noLink
                                        />
                                    );
                                })}
                            </Grid>
                        </div>
                    </ElementScroll>
                ))}

                <div css={[sButtonFixed, { bottom: bottomNavigation }]}>
                    <Button
                        w100
                        w100Desktop
                        action={onSendProducts}
                        buttonTransparent
                        loading={updateLoading}
                        loadingSpinner={updateLoading}
                    >
                        Domów{' '}
                        {cart.length > 0 && (
                            <span>
                                &nbsp; +{' '}
                                {cart
                                    .reduce(
                                        (sum, curr) =>
                                            curr.price * curr.quantity + sum,
                                        0,
                                    )
                                    .toFixed(2)}{' '}
                                PLN
                            </span>
                        )}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};
