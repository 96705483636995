/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
// import TextField from '@material-ui/core/TextField'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import Input, { sBase } from '@/EZC/views/Auth/components/Input.component';
import { colors, s } from 'src/style';
import { ButtonBase, TextField } from '@material-ui/core';
import { sInput } from '@/EZR/style/input.style';

export const usePlacesAutocomplete = () => {
    const onSelectAddress = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            const { lat, lng } = await getLatLng(results[0]);
            return { lng, lat };
        } catch (error) {
            console.error(error);
        }
    };

    return {
        onSelectAddress,
    };
};

export default function GooglePlacesAutocomplete({
    value,
    onChange,
    onSelect,
    textFieldCss,
    required,
    placeholder,
    label,
    restaurantView,
}) {
    return (
        <PlacesAutocomplete
            value={value}
            onChange={onChange}
            onSelect={onSelect}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => (
                <React.Fragment>
                    <div css={{ position: 'relative' }}>
                        {restaurantView ? (
                            <ButtonBase
                                css={[sBase, { marginBottom: 0 }]}
                                tabIndex="-1"
                            >
                                <TextField
                                    {...getInputProps()}
                                    label={label ? label : ''}
                                    required
                                    name="address"
                                    fullWidth
                                    css={[sInput]}
                                    value={value}
                                />
                            </ButtonBase>
                        ) : (
                            <Input
                                darker
                                {...getInputProps()}
                                id="address"
                                label={label ? label : ''}
                                placeholder={placeholder || 'Podaj Adres'}
                                css={[
                                    {
                                        width: '100%',
                                        marginBottom: 0,
                                    },
                                    textFieldCss,
                                ]}
                                value={value}
                                required={required}
                                sBaseExtra={{ marginBottom: 0 }}
                                restaurantView={restaurantView}
                            />
                        )}
                    </div>
                    <div
                        className="autocomplete-dropdown-container"
                        css={{
                            padding: '0 0',
                            width: '100%',
                            marginTop: '1rem',
                            [s.sm]: { marginTop: '1.5rem' },
                            [s.xs]: { marginBottom: '1rem' },
                        }}
                    >
                        {suggestions.map((suggestion) => {
                            const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                                ? { backgroundColor: colors.gray300 }
                                : { cursor: 'pointer' };

                            return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                    })}
                                    key={suggestion.index}
                                    css={{
                                        cursor: 'pointer',
                                        padding: '1rem',
                                        width: '100%',
                                        fontSize: '12px',
                                        fontWeight: 400,
                                        borderRadius: 32,
                                    }}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>
            )}
        </PlacesAutocomplete>
    );
}
