/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import { Grid } from '@material-ui/core';
import { SectionHeadline } from '@components/Elements';
import { sDescription, sGrid } from '@style/style';
import { useRestaurantContext } from '@/EZR/pages/Restaurant/context/restaurant.provider';

import {
    consumptionTypeTypes as types,
    consumptionTypeNames,
    consumptionTypeIcons,
} from '@constants/types';
import { colors, s, alpha } from '@style';

const ConsumptionTypes = () => {
    const { restaurantInfo, setRestaurantInfo } = useRestaurantContext();
    const { consumptionTypes } = restaurantInfo;

    const onChange = (type) => {
        const value = consumptionTypes.includes(type)
            ? consumptionTypes.filter((el) => el !== type)
            : [...consumptionTypes, type];

        setRestaurantInfo({ ...restaurantInfo, consumptionTypes: value });
    };

    return (
        <Grid container css={[sGrid]}>
            <SectionHeadline noPaddingLeft>Typy Zamówień</SectionHeadline>
            <p css={sDescription}>
                Zaznacz, które sposoby zamówień są aktualnie dostępne w Twojej
                restauracji.
            </p>
            <Grid item xs={12} css={sContainer}>
                <div
                    css={[
                        sTypeButton,
                        consumptionTypes?.includes(types.IN) &&
                            sTypeButtonActive,
                    ]}
                    onClick={() => onChange(types.IN)}
                >
                    {consumptionTypeIcons[types.IN]}
                    <p>{consumptionTypeNames[types.IN]}</p>
                </div>
                <div
                    css={[
                        sTypeButton,
                        consumptionTypes?.includes(types.TAKEAWAY) &&
                            sTypeButtonActive,
                        { [s.md]: { margin: '0 1.5rem' } },
                    ]}
                    onClick={() => onChange(types.TAKEAWAY)}
                >
                    {consumptionTypeIcons[types.TAKEAWAY]}
                    <p>{consumptionTypeNames[types.TAKEAWAY]}</p>
                </div>

                <div
                    css={[
                        sTypeButton,
                        consumptionTypes?.includes(types.DELIVERY) &&
                            sTypeButtonActive,
                    ]}
                    onClick={() => onChange(types.DELIVERY)}
                >
                    {consumptionTypeIcons[types.DELIVERY]}
                    <p>{consumptionTypeNames[types.DELIVERY]}</p>
                </div>
            </Grid>
        </Grid>
    );
};

const sContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    [s.md]: {
        justifyContent: 'flex-start',
    },
};

const sTypeButton = {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-out, color 0.3s ease-out',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '30px',
    backgroundColor: colors.gray200,
    color: colors.gray500,
    padding: '1rem',
    borderRadius: '1rem',
    width: 160,
    [s.xs]: {
        width: '30%',
        maxWidth: '30%',
        flexBasis: '30%',
    },
    p: {
        textAlign: 'center',
        marginTop: '0.5rem',
        fontSize: '0.675rem',
        textTransform: 'uppercase',
        fontWeight: 700,
        [s.md]: {
            fontSize: '0.75rem',
        },
    },
};

const sTypeButtonActive = {
    backgroundColor: colors.orange.concat(alpha[16]),
    color: colors.orange,
};

export default ConsumptionTypes;
