import { useState } from 'react';
import { useFindRestaurantLazyQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export const PAGE_SIZE = 20;

export const useFindRestaurant = (isActive: boolean) => {
    const [search, setSearch] = useState('');
    const [findRestaurant, { data, loading }] = useFindRestaurantLazyQuery({
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            graphqlErrorHandler(true)(error);
        },
    });

    const hasMore =
        (data?.restaurantList?.metadata?.currentPage ?? 0) <
        (data?.restaurantList?.metadata?.totalPages ?? 0);
    const page = data?.restaurantList?.metadata?.currentPage ?? 0;

    const getRestaurants = () => {
        findRestaurant({
            variables: {
                input: { payload: { name: search, isActive } },
                pagination: { page: page + 1, pageSize: PAGE_SIZE },
            },
        });
    };

    return {
        data,
        loading,
        getRestaurants,
        hasMore,
        findRestaurant,
        setSearch,
    };
};
