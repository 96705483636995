import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { resolveEnv } from '@helpers/resolveEnv';
import { Capacitor } from '@capacitor/core';

interface FirebaseAnalyticsServiceProps {
    logEvent: (
        name: string,
        params: { [key: string]: unknown },
    ) => Promise<void>;
    initialize: () => void;
}

export const FirebaseAnalyticsService: FirebaseAnalyticsServiceProps = {
    logEvent: (
        name: string,
        params: { [key: string]: unknown },
    ): Promise<void> => FirebaseAnalytics.logEvent({ name, params }),
    initialize: () => {
        if (Capacitor.getPlatform() !== 'web') {
            FirebaseAnalytics.setCollectionEnabled({
                enabled: resolveEnv('REACT_APP_NODE_ENV') === 'PROD',
            });
        }
    },
};
