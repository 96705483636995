import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { DistanceBox } from '@/componentsV2/molecules/DistanceBox/DistanceBox';
import { YourLocalizationBox } from '@/componentsV2/molecules/YourLocalizationBox/YourLocalizationBox';
import { ChipSecondary } from '@/componentsV2/atoms/ChipSecondary/ChipSecondary';
import {
    SPICINESS_LEVEL,
    SPICINESS_LEVEL_VALUES,
} from '@constants/spicinessLevels';
import {
    COOKING_TIME,
    COOKING_TIME_VALUES,
} from '@constants/cookingTimeLevels';
import {
    CALORIES_LEVEL,
    CALORIES_LEVEL_VALUES,
} from '@constants/caloriesLevels';
import {
    MEAL_TIME_NAME,
    MEAL_TIME_NAME_VALUES,
} from '@constants/fromGraphql/MealTime.constants';
import { PRICE_LEVEL, PRICE_LEVEL_VALUES } from '@constants/priceLevels';
import { Group, Ingredient } from '@graphql/generated';
import { SearchFilterList } from '../../molecules/SearchFilterList/SearchFilterList';
import {
    CUISINE_NAME,
    CUISINE_NAME_VALUES,
} from '@constants/fromGraphql/Cuisine.constants';
import {
    DISH_NAME,
    DISH_NAME_VALUES,
} from '@constants/fromGraphql/Dish.constants';
import {
    DIET_NAME,
    DIET_NAME_VALUES,
} from '@constants/fromGraphql/Diet.constants';
import {
    FLAVOUR_NAME,
    FLAVOUR_NAME_VALUES,
} from '@constants/fromGraphql/Flavor.constants';
import {
    COOK_METHOD_NAME,
    COOK_METHOD_NAME_VALUES,
} from '@constants/fromGraphql/CookMethod.constants';
import { ListAlphabetically } from '@/componentsV2/molecules/ListAlphabetically/ListAlphabetically';
import { Controller, useFormContext } from 'react-hook-form';
import { SearchFilterFormSchemaValues } from '@/EZC/domains/Search/SearchFilterFormProvider/SearchFilterFormProvider';
import { SearchFilterSelector } from '@/EZC/domains/Search/molecules/SearchFilterSelector/SearchFilterSelector';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';

interface SearchFilterPanelFieldsProps {
    groups: {
        list: Omit<Group, 'members' | 'owner'>[];
        loading: boolean;
    };
    ingredients: {
        list: Ingredient[];
        loading: boolean;
    };
    onLocalizationChange: () => void;
}

export const SearchFilterPanelFields = ({
    groups,
    ingredients,
    onLocalizationChange,
}: SearchFilterPanelFieldsProps) => {
    const [openedAccordion, setOpenedAccordion] = useState('');

    const { control, resetField } =
        useFormContext<SearchFilterFormSchemaValues>();

    return (
        <div>
            <Separator color={colors.porcelainGray} />
            <Controller
                name={'geolocation'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        accordion
                        title={
                            <LocationWrapper>
                                <ItemTitle>Lokalizacja</ItemTitle>
                                <LocationName>
                                    {field.value.address}
                                </LocationName>
                            </LocationWrapper>
                        }
                        separator
                        onEnter={() => setOpenedAccordion('geolocation')}
                        isOpen={openedAccordion === 'geolocation'}
                    >
                        <YourLocalizationBox
                            onChange={(event) => {
                                field.onChange(event);
                                onLocalizationChange();
                            }}
                            value={field.value}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'distance'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        accordion
                        title={
                            <ItemTitle>
                                Odległość{' '}
                                <ItemTitleInfo>
                                    w km od lokalizacji
                                </ItemTitleInfo>
                            </ItemTitle>
                        }
                        separator
                        onEnter={() => setOpenedAccordion('distance')}
                        isOpen={openedAccordion === 'distance'}
                        contentPadding={
                            field.value || openedAccordion === 'distance'
                                ? '0 0 16px'
                                : '0'
                        }
                        reset={() =>
                            resetField('distance', {
                                defaultValue: null,
                            })
                        }
                        selected={
                            field.value
                                ? [
                                      {
                                          id: field.value ?? '',
                                          name: `${field.value} km` ?? '',
                                      },
                                  ]
                                : []
                        }
                        onChange={() => {
                            field.onChange(null);
                            setOpenedAccordion('');
                        }}
                    >
                        <DistanceBox
                            onChange={field.onChange}
                            value={field.value}
                        />
                    </SearchFilterSelector>
                )}
            />

            {!!groups?.list?.length && (
                <Controller
                    name={'groups'}
                    control={control}
                    render={({ field }) => {
                        return (
                            <SearchFilterSelector
                                accordion
                                title={<ItemTitle>Grupy</ItemTitle>}
                                reset={() =>
                                    resetField('groups', {
                                        defaultValue: null,
                                    })
                                }
                                contentPadding={
                                    field.value || openedAccordion === 'groups'
                                        ? '0 0 16px 0'
                                        : '0'
                                }
                                separator
                                onEnter={() => setOpenedAccordion('groups')}
                                isOpen={openedAccordion === 'groups'}
                                selected={
                                    field.value
                                        ? [
                                              {
                                                  id: field.value ?? '',
                                                  name:
                                                      groups.list.find(
                                                          (item) =>
                                                              item.id ===
                                                              field.value,
                                                      )?.name ?? '',
                                              },
                                          ]
                                        : []
                                }
                                onChange={() => {
                                    field.onChange(null);
                                    setOpenedAccordion('');
                                }}
                            >
                                <ChipsWrapper>
                                    {groups.list.map((item) => {
                                        return (
                                            <ChipSecondary
                                                key={item.id}
                                                onClick={() =>
                                                    field.onChange(item.id)
                                                }
                                                size="default"
                                                text={item.name}
                                                value={item}
                                                variant={
                                                    field.value === item.id
                                                        ? 'default'
                                                        : 'gray'
                                                }
                                            />
                                        );
                                    })}
                                </ChipsWrapper>
                            </SearchFilterSelector>
                        );
                    }}
                />
            )}

            <Controller
                name={'cuisine'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: CUISINE_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Kuchnia</ItemTitle>}
                        reset={() => resetField('cuisine')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('cuisine')}
                        isOpen={openedAccordion === 'cuisine'}
                    >
                        <SearchFilterList
                            list={CUISINE_NAME_VALUES}
                            emptyResultText="Nie znaleziono pasującej kuchni"
                            initialLength={11}
                            onChange={field.onChange}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'dish'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: DISH_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Danie</ItemTitle>}
                        reset={() => resetField('dish')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('dish')}
                        isOpen={openedAccordion === 'dish'}
                    >
                        <SearchFilterList
                            list={DISH_NAME_VALUES}
                            emptyResultText="Nie znaleziono pasującego dania"
                            initialLength={11}
                            onChange={field.onChange}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'diet'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: DIET_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Dieta</ItemTitle>}
                        reset={() => resetField('diet')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('diet')}
                        isOpen={openedAccordion === 'diet'}
                    >
                        <SearchFilterList
                            list={DIET_NAME_VALUES}
                            emptyResultText="Nie znaleziono pasującej diety"
                            initialLength={11}
                            onChange={field.onChange}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'flavour'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: FLAVOUR_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Smak</ItemTitle>}
                        reset={() => resetField('flavour')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('flavour')}
                        isOpen={openedAccordion === 'flavour'}
                    >
                        <SearchFilterList
                            list={FLAVOUR_NAME_VALUES}
                            emptyResultText="Nie znaleziono pasującego smaku"
                            initialLength={11}
                            onChange={field.onChange}
                            value={field.value}
                            variant={'all'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'cookMethod'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: COOK_METHOD_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Metoda gotowania</ItemTitle>}
                        reset={() => resetField('cookMethod')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('cookMethod')}
                        isOpen={openedAccordion === 'cookMethod'}
                    >
                        <SearchFilterList
                            list={COOK_METHOD_NAME_VALUES}
                            emptyResultText="Nie znaleziono pasującej metody gotowania"
                            initialLength={11}
                            onChange={field.onChange}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'ingredients'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name:
                                ingredients.list.find((item) => item.id === id)
                                    ?.name || '',
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Składniki</ItemTitle>}
                        reset={() => resetField('ingredients')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('ingredients')}
                        isOpen={openedAccordion === 'ingredients'}
                    >
                        <ListAlphabetically
                            emptyResultText="Nie znaleziono pasującego składnika"
                            ingredients={ingredients.list}
                            initialLength={11}
                            onChange={field.onChange}
                            type={'desktop'}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'allergy'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name:
                                ingredients.list.find((item) => item.id === id)
                                    ?.name || '',
                            variant: 'red',
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Alergie</ItemTitle>}
                        reset={() => resetField('allergy')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('allergy')}
                        isOpen={openedAccordion === 'allergy'}
                    >
                        <ListAlphabetically
                            emptyResultText="Nie znaleziono pasującego składnika"
                            ingredients={ingredients.list}
                            initialLength={11}
                            onChange={field.onChange}
                            type={'desktop'}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'notPreffered'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name:
                                ingredients.list.find((item) => item.id === id)
                                    ?.name || '',
                            variant: 'yellow',
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        accordion
                        title={<ItemTitle>Niechciane składniki</ItemTitle>}
                        reset={() => resetField('notPreffered')}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                        onEnter={() => setOpenedAccordion('notPreffered')}
                        isOpen={openedAccordion === 'notPreffered'}
                    >
                        <ListAlphabetically
                            emptyResultText="Nie znaleziono pasującego składnika"
                            ingredients={ingredients.list}
                            initialLength={11}
                            onChange={field.onChange}
                            type={'desktop'}
                            value={field.value}
                            variant={'wrapped'}
                        />
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'price'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={
                            field?.value
                                ? [
                                      {
                                          id: field?.value ?? '',
                                          name:
                                              PRICE_LEVEL[field.value]?.name ??
                                              '',
                                      },
                                  ]
                                : []
                        }
                        onChange={() => {
                            {
                                field.onChange(null);
                                setOpenedAccordion('');
                            }
                        }}
                        accordion
                        title={
                            <ItemTitle>
                                Cena <ItemTitleInfo>w zł</ItemTitleInfo>
                            </ItemTitle>
                        }
                        reset={() =>
                            resetField('price', {
                                defaultValue: null,
                            })
                        }
                        separator
                        onEnter={() => setOpenedAccordion('price')}
                        isOpen={openedAccordion === 'price'}
                        contentPadding={
                            field.value || openedAccordion === 'price'
                                ? '0 0 16px 0'
                                : '0'
                        }
                    >
                        <ChipsWrapper>
                            {PRICE_LEVEL_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value === item.id
                                                ? null
                                                : item.id,
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value === item.id
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'mealTime'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        accordion
                        title={<ItemTitle>Pora dnia</ItemTitle>}
                        reset={() => resetField('mealTime')}
                        separator
                        onEnter={() => setOpenedAccordion('mealTime')}
                        isOpen={openedAccordion === 'mealTime'}
                        selected={field.value.map((id) => ({
                            id,
                            name: MEAL_TIME_NAME[id]!,
                        }))}
                        onChange={(selected) => {
                            field.onChange(selected.map((select) => select.id));
                            setOpenedAccordion('');
                        }}
                        contentPadding={
                            field.value.length || openedAccordion === 'mealTime'
                                ? '0 0 16px 0'
                                : '0'
                        }
                    >
                        <ChipsWrapper>
                            {MEAL_TIME_NAME_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value.includes(item.id)
                                                ? field.value.filter(
                                                      (current) =>
                                                          current !== item.id,
                                                  )
                                                : [...field.value, item.id],
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value.includes(item.id)
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'calories'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        accordion
                        selected={
                            field?.value
                                ? [
                                      {
                                          id: field?.value,
                                          name: CALORIES_LEVEL[field.value]
                                              .name,
                                      },
                                  ]
                                : []
                        }
                        title={
                            <ItemTitle>
                                Kaloryczność{' '}
                                <ItemTitleInfo>w kcal</ItemTitleInfo>
                            </ItemTitle>
                        }
                        reset={() => resetField('calories')}
                        separator
                        onEnter={() => setOpenedAccordion('calories')}
                        isOpen={openedAccordion === 'calories'}
                        onChange={() => {
                            field.onChange(null);
                            setOpenedAccordion('');
                        }}
                        contentPadding={
                            field.value || openedAccordion === 'calories'
                                ? '0 0 16px 0'
                                : '0'
                        }
                    >
                        <ChipsWrapper>
                            {CALORIES_LEVEL_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value === item.id
                                                ? null
                                                : item.id,
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value === item.id
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'cookingTime'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        accordion
                        title={<ItemTitle>Czas przygotowania</ItemTitle>}
                        selected={
                            field?.value
                                ? [
                                      {
                                          id: field?.value,
                                          name: COOKING_TIME[field.value].name,
                                      },
                                  ]
                                : []
                        }
                        onChange={() => {
                            field.onChange(null);
                            setOpenedAccordion('');
                        }}
                        reset={() => resetField('cookingTime')}
                        separator
                        onEnter={() => setOpenedAccordion('cookingTime')}
                        isOpen={openedAccordion === 'cookingTime'}
                        contentPadding={
                            field.value || openedAccordion === 'cookingTime'
                                ? '0 0 16px 0'
                                : '0'
                        }
                    >
                        <ChipsWrapper>
                            {COOKING_TIME_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() => field.onChange(item.id)}
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value === item.id
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />

            <Controller
                name={'spiciness'}
                control={control}
                render={({ field }) => (
                    <SearchFilterSelector
                        accordion
                        title={<ItemTitle>Skala ostrości</ItemTitle>}
                        reset={() => resetField('spiciness')}
                        separator
                        onEnter={() => setOpenedAccordion('spiciness')}
                        isOpen={openedAccordion === 'spiciness'}
                        selected={field.value.map((id) => ({
                            id,
                            name: SPICINESS_LEVEL[id]!.name,
                        }))}
                        onChange={(selected) => {
                            field.onChange(selected.map((select) => select.id));
                            setOpenedAccordion('');
                        }}
                        contentPadding={
                            field.value.length ||
                            openedAccordion === 'spiciness'
                                ? '0 0 16px 0'
                                : '0'
                        }
                    >
                        <ChipsWrapper>
                            {SPICINESS_LEVEL_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value.includes(item.id)
                                                ? field.value.filter(
                                                      (current) =>
                                                          current !== item.id,
                                                  )
                                                : [...field.value, item.id],
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value.includes(item.id)
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />
        </div>
    );
};

const ItemTitle = styled.p`
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
`;

const ItemTitleInfo = styled.span`
    font-size: 11px;
    font-weight: 600;
    line-height: 24px;
    color: ${colors.gray600};
`;

const LocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const LocationName = styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
`;

const ChipsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
