/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { daysOfWeek } from '@constants/daysOfWeek';
import { useRestaurantContext } from '@/EZR/pages/Restaurant/context/restaurant.provider';

import deliverySettingsCheck from '@/EZR/pages/Restaurant/helpers/deliverySettingsCheck';
import deliveryParseFloatAmount from '@/EZR/pages/Restaurant/helpers/deliveryParseFloatAmounts';
import { sButtonFixed } from '@/EZW/pages/Order/buttons/FixedButtons';
import { Button } from '@components/Elements';
import { Collapse } from '@material-ui/core';
import Headline from '@components/Headline/Headline';
import { sGridOverride, sHeadline } from '@/EZW/pages/Popups/styles';
import WidgetContainer from '@components/Widget/Widget.component';
import { sDescription, sGrid } from '@style/style';
import { colors } from '@style';
import {
    GetRestaurantListDocument,
    useRestaurantCreateMutation,
    useRestaurantWaiterCreateMutation,
} from '@graphql/generated';
import { useUploadImg } from '@/EZR/pages/Restaurant/hooks/useUploadImage';
import { getFileType } from '@helpers/getFileType';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { geocodeByAddress } from 'react-places-autocomplete';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { useAuthService } from '@/services/AuthService';
import { restaurantNameSchema } from '../helpers/restaurantNameSchema';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { IOS } from '@constants/common.constants';

export default () => {
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const { setRestaurantId } = useAuthService((state) => state);
    const [restaurantCreate, { loading: restaurantLoading }] =
        useRestaurantCreateMutation();
    const [restaurantWaiterCreate, { data, loading: waiterLoading }] =
        useRestaurantWaiterCreateMutation();
    const {
        openningHours,
        restaurantInfo,
        logoFile,
        backgroundFile,
        deliverySettings,
    } = useRestaurantContext();

    const { uploadImgAndGetURL } = useUploadImg();

    const { bottomNavigation } = useSafeAreaInsets();

    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    const handleSubmit = async () => {
        try {
            for (const day in openningHours) {
                if (openningHours[day].open > openningHours[day].close) {
                    toast.error(
                        `${daysOfWeek[day]} - godzina otwarcia nie może być późniejsza od godziny zamknięcia`,
                    );
                    return;
                }
            }

            if (restaurantInfo?.consumptionTypes.includes('DELIVERY')) {
                const deliverySettingsErrors =
                    deliverySettingsCheck(deliverySettings);
                if (deliverySettingsErrors.length > 0) {
                    const errors = new Error();
                    errors.array = deliverySettingsErrors;
                    throw errors;
                }
                deliveryParseFloatAmount(deliverySettings);
            }

            const errors = [];

            if (!restaurantInfo.name) errors.push('Podaj nazwę');
            if (!restaurantInfo.address) errors.push('Podaj adres');
            if (restaurantInfo.name.length > 51)
                errors.push(
                    'Nazwa restauracji jest zbyt długa (maks. 50 znaków)',
                );
            if (!restaurantInfo.description) errors.push('Podaj opis');
            if (!restaurantNameSchema.isValidSync(restaurantInfo.name))
                errors.push('Nazwa restauracji zawiera niedozwolone znaki');

            if (errors.length) {
                errors.forEach((el) => toast.error(el));
                return;
            }

            try {
                const results = await geocodeByAddress(restaurantInfo.address);
                if (results.length === 0) {
                    toast.error(
                        'Nieprawidłowa lokalizacja. Proszę wprowadzić poprawną lokalizację.',
                    );
                    return;
                }
            } catch (error) {
                if (error === 'ZERO_RESULTS') {
                    toast.error(
                        'Nieprawidłowa lokalizacja. Proszę wprowadzić poprawną lokalizację.',
                    );
                } else {
                    toast.error(
                        'Wystąpił błąd podczas pobierania danych z Google Maps API.',
                    );
                }
                return;
            }

            const s3LogoUrl = await uploadImgAndGetURL(
                logoFile,
                getFileType(logoFile),
                '/240x240',
            );

            const s3BackgroundUrl = await uploadImgAndGetURL(
                backgroundFile,
                getFileType(backgroundFile),
                '/1200x900',
            );

            const res = await restaurantCreate({
                variables: {
                    input: {
                        name: restaurantInfo.name,
                        address: restaurantInfo.address,
                        description: restaurantInfo.description,
                        consumptionTypes: restaurantInfo?.consumptionTypes,
                        location: restaurantInfo?.location,
                        openingHours: convertTimestamps.toISO(openningHours),
                        deliverySettings: {
                            availableHours: convertTimestamps.toISO(
                                deliverySettings.availableHours,
                            ),
                            deliveryRadius: deliverySettings.deliveryRadius,
                            minAmount: deliverySettings.minAmount,
                            freeDelivery: !!deliverySettings.freeDelivery,
                            freeDeliveryAmount:
                                deliverySettings.freeDeliveryAmount,
                            deliveryCost: deliverySettings.deliveryCost.map(
                                (item) => ({
                                    deliveryRadius: item[0],
                                    amount: item[1],
                                }),
                            ),
                        },
                        photoUrl: s3BackgroundUrl,
                        avatarUrl: s3LogoUrl,
                        layoutPdfUrl: restaurantInfo?.layoutPdfUrl,
                    },
                },

                onCompleted: (data) => {
                    setRestaurantId(data?.restaurant?.id);
                },

                onError: (error) => {
                    graphqlErrorHandler(true)(error);
                    setOpen(false);
                },
            });

            if (res?.data?.restaurant) {
                await restaurantWaiterCreate({
                    variables: {
                        input: { restaurantId: res?.data?.restaurant?.id },
                    },

                    onCompleted: () => setOpen(true),
                    onError: (error) => {
                        graphqlErrorHandler(true)(error);
                    },
                    refetchQueries: [
                        {
                            query: GetRestaurantListDocument,
                        },
                    ],
                });
            }
        } catch (err) {
            if (err.array && err.array.length > 0) {
                err.array.forEach((e) => toast.warn(e));
            } else {
                toast.warn(err.message);
            }
        }
    };

    const onClose = () => {
        setOpen(false);
        history.replace('/waiter');
    };

    const onClickOutsidePopup = (isClosed) => {
        setOpen(isClosed);
        history.push('/rest');
    };

    return (
        <>
            <div
                css={[
                    sButtonFixed,
                    { bottom: bottomNavigation },
                    {
                        ...(IOS &&
                            keyboardHeight && {
                                position: 'relative',
                                bottom: 0,
                            }),
                    },
                ]}
            >
                <Button
                    w100
                    w100Desktop
                    buttonTransparent={true}
                    action={handleSubmit}
                    disabled={restaurantLoading || waiterLoading}
                    loadingSpinner={restaurantLoading || waiterLoading}
                    loading={restaurantLoading || waiterLoading}
                >
                    Dodaj restaurację
                </Button>
            </div>

            <WidgetContainer
                isWidgetOpen={open}
                setIsWidgetOpen={onClickOutsidePopup}
            >
                <Collapse in={open}>
                    <div>
                        <Headline extraCss={sHeadline}>
                            Dodano Restaurację
                        </Headline>
                        <div css={[sGrid, sGridOverride]}>
                            <p css={[sDescription, { fontSize: '13px' }]}>
                                Twoja nowa Restauracja została dodana! Poniżej
                                wyświetlono dane dostępowe do{' '}
                                <strong>Panelu Kelnera</strong>.
                            </p>
                            <p css={[sDescription, { fontSize: '13px' }]}>
                                Login:{' '}
                                <strong css={{ color: colors.text }}>
                                    {data?.waiter?.login}
                                </strong>
                            </p>
                            <p css={[sDescription, { fontSize: '13px' }]}>
                                Hasło:{' '}
                                <strong css={{ color: colors.text }}>
                                    {data?.waiter?.password}
                                </strong>
                            </p>
                            <p css={[sDescription, { fontSize: '13px' }]}>
                                Poniższe dane są wyświetlone tylko raz. Zapisz
                                je lub zmień w widoku <em>Dostępy</em>
                            </p>
                            <Button w100 w100Desktop action={onClose}>
                                Panel Kelnera
                            </Button>
                        </div>
                    </div>
                </Collapse>
            </WidgetContainer>
        </>
    );
};
