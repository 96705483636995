import LoginPage from './Login/LoginPage';
import OrdersPage from './Orders/OrdersPage';
import ActivePage from './Active/ActivePage';
import HistoryPage from './History/HistoryPage';
import HomePage from './Home/HomePage';
import OrderPage from './Order/OrderPage';
import ChatPage from './Chat/ChatPage';
import OrderAdd from './OrderAdd/OrderAdd';
import { NewOrderPage } from '@/EZW/domains/pages/NewOrderPage/NewOrderPage';
import { OrderCheckoutPage } from './OrderCheckout/OrderCheckoutPage';

export const EZWRoutes = [
    {
        path: '/waiter/login',
        component: LoginPage,
    },
    {
        path: '/waiter',
        component: HomePage,
        exact: true,
    },
    {
        path: '/waiter/orders',
        component: OrdersPage,
    },
    {
        path: '/waiter/new-order',
        component: NewOrderPage,
    },
    {
        path: '/waiter/new-order-checkout',
        component: OrderCheckoutPage,
    },
    {
        path: '/waiter/order/:id',
        component: OrderPage,
    },
    {
        path: '/waiter/notifications',
        component: ActivePage,
    },
    {
        path: '/waiter/history',
        component: HistoryPage,
        exact: true,
    },
    {
        path: '/waiter/chat/:id',
        component: ChatPage,
    },
    {
        path: '/waiter/add/:id',
        component: OrderAdd,
    },
];
