/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

const Subheader = ({ children }) => (
    <span
        css={{
            color: '#bbffad',
            fontSize: 18,
            fontWeight: 700,
        }}
    >
        {children}
    </span>
);

export default Subheader;
