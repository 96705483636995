/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Button, UserItem, FindUserByEmail } from '@/EZAdmin/components';
import { useAdminFindUsersLazyQuery } from '@graphql/generated';
import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

const FindUser = ({ onChoseOwner }) => {
    const [isSelect, setIsSelect] = useState(false);

    const [adminFindUsers, { data, loading }] = useAdminFindUsersLazyQuery();

    const handleGetUser = (email) => {
        adminFindUsers({
            variables: { input: { query: { email } } },
            onCompleted: (data) => {
                if (!data?.adminFindUsers?.data?.length) {
                    toast.error('Użytkownik nie został odnaleziony');
                }
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return (
        <div>
            <FindUserByEmail loading={loading} getUser={handleGetUser} />
            {data?.adminFindUsers?.data?.length > 0 && (
                <UserItem
                    {...data.adminFindUsers.data[0]}
                    helperComponent={
                        <Button
                            color={isSelect ? 'secondary' : undefined}
                            onClick={() => {
                                setIsSelect(true);
                                onChoseOwner(data.adminFindUsers.data[0].id);
                            }}
                        >
                            Wybierz właściciela
                        </Button>
                    }
                />
            )}
        </div>
    );
};

export default FindUser;
