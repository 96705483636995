/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

// import Layout from '../../../components/Layout'
import AdminChatClient from '../../../views/Chat/AdminChatClient.view';

const EzChat = () => {
    return (
        // <Layout>
        <AdminChatClient />
        // </Layout>
    );
};

export default EzChat;
