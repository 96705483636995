import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

// import Layout from '../../components/Layout'
import OrderChatView from '../../views/Chat/OrderChat.view';
import { useOrdersChatUserQuery } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import PageLoader from '@components/PageLoader/PageLoader';

const OrdersChat = () => {
    const history = useHistory();
    const orderId = useParams().id;

    const { data, loading } = useOrdersChatUserQuery({
        variables: { input: { orderId } },
        onError: (error) => {
            graphqlErrorHandler(false)(error);
            toast.error(
                'Nieprawidłowe ID chatu. Spróbuj wejść ponownie. Jeśli nadal widzisz ten błąd, skontaktuj się z nami, używając Chatu z Eatzon, który znajdziesz w widoku Konto lub na podstronie /account',
            );

            history.push('/orders');
        },
    });

    const owner = data?.order?.owner?.personal;
    const status = data?.order.status;

    useEffect(() => {
        (status === 'REJECTED' ||
            status === 'CANCELED' ||
            status === 'CLOSED') &&
            history.push(`/order/${orderId}`);
    }, [status]);

    if (loading) return <PageLoader />;

    return (
        // <Layout>
        <OrderChatView
            orderId={orderId}
            name={data?.order.restaurant.name}
            secondUser={`${owner?.name ?? ''} ${owner?.surname ?? ''}`.trim()}
            avatarUrl={data?.order.restaurant.avatarUrl}
            onCloseChat={() => history.push('/order/' + orderId)}
        />
        // </Layout>
    );
};

export default OrdersChat;
