import { Helmet } from 'react-helmet';

interface MetadataProps {
    title: string;
    description?: string;
    ogImage?: string;
}

export const Metadata = ({ title, description, ogImage }: MetadataProps) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:image" content={ogImage} />
        </Helmet>
    );
};
