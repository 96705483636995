/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Carousel from '@/EZC/components/Carousel/Carousel.component';
import { RestItemLarge } from '@/EZC/components/RestItem';

import { s, globals } from '@style';
import { useRestaurantCarouselListLazyQuery } from '@graphql/generated';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';

const RestaurantCarousel = () => {
    const { consumptionTypes, distance, geolocation, showBrochures } =
        useUserSettingsStoreShallow([
            'consumptionTypes',
            'distance',
            'geolocation',
            'showBrochures',
        ]);

    const { localizedBannerEnabled, restaurantsForLocalizedHomepage } =
        useServerConfigStoreShallow([
            'restaurantsForLocalizedHomepage',
            'localizedBannerEnabled',
        ]);

    const [getRestaurants, { data }] = useRestaurantCarouselListLazyQuery();

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination, input) =>
            getRestaurants({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input,
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.restaurantList?.metadata,
        pageSize: 20,
        input: {
            payload: {
                location: {
                    coordinates: [geolocation.lng, geolocation.lat],
                },
                consumptionTypes,
                maxDistance:
                    (parseInt(distance ?? '') || 0) * 1000 || undefined,
                isBrochure: showBrochures,
                isActive: true,
            },
            restaurantIds: localizedBannerEnabled
                ? restaurantsForLocalizedHomepage
                : undefined,
        },
    });

    if (!data) return null;
    return (
        <React.Fragment>
            <div css={sContainer}>
                <h2 css={sTitle}>Restauracje</h2>
            </div>
            <div
                css={{
                    maxWidth: globals.maxWidthMediumCarousel,
                    margin: '0 auto',
                }}
            >
                <Carousel
                    scrollButtons={364}
                    data={data?.restaurantList?.data}
                    CarouselItem={RestItemLarge}
                    link="/restaurant/"
                    getMoreData={getMoreData}
                    hasMore={hasMore}
                />
            </div>
        </React.Fragment>
    );
};

const sContainer = {
    maxWidth: globals.maxWidthMedium,
    width: '100%',
    margin: '0 auto',
};

const sTitle = {
    margin: '0 0 8px',
    fontSize: '22px',
    fontWeight: 700,
    letterSpacing: '-0.03em',
    paddingLeft: '24px',
    [s.md]: {
        fontSize: '30px',
    },
};

export default RestaurantCarousel;
