/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import { useProductsContext } from '@/EZR/pages/Products/context/products.provider';
import { useTopNavContext } from '@context/topnav.context';
import CloseIcon from '@material-ui/icons/Close';

import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

import { sGrid, sOuter } from '@style/style';
import { Input } from '@/EZC/views/Auth/components';
import { ButtonBase } from '@material-ui/core';
import { s } from '@style/index';
import { sButtonSmall } from '@/EZC/components/Carousel/Carousel.component';

export default () => {
    const { searchString, onSearchString } = useProductsContext();
    const { top } = useSafeAreaInsets();

    const { scrolled } = useTopNavContext();
    const [focus, setFocus] = useState(false);

    return (
        <div css={[sOuter, sOuter2, { top: top }]}>
            <div
                css={[
                    sGrid,
                    sGrid2,
                    { marginBottom: '2rem' },
                    scrolled && { width: 'calc(100% - 3.5rem)' },
                    focus && { width: '100%' },
                    {
                        [s.md]: {
                            marginBottom: '3rem',
                        },
                    },
                ]}
            >
                <div
                    css={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        width: '100%',
                    }}
                >
                    <Input
                        onFocus={() => setFocus(true)}
                        onMouseLeave={() => setFocus(false)}
                        onChange={onSearchString}
                        type="text"
                        value={searchString}
                        placeholder="Szukaj"
                        sBaseExtra={{ margin: 0, zIndex: 1002 }}
                        sInputExtra={[sInput]}
                    />
                    <ButtonBase
                        css={[
                            sButtonSearch,
                            sButtonReset,
                            !searchString && {
                                opacity: 0,
                                pointerEvents: 'none',
                            },
                            { right: 0 },
                        ]}
                        onClick={() =>
                            onSearchString({ target: { value: '' } })
                        }
                    >
                        <CloseIcon fontSize="inherit" />
                    </ButtonBase>
                </div>
            </div>
        </div>
    );
};

const sButtonSearch = {
    ...sButtonSmall,
    [s.md]: {
        minWidth: 52,
        minHeight: 52,
        padding: '0 1.5rem',
    },
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1002,
    p: {
        [s.sm_down]: { display: 'none' },
        textTransform: 'uppercase',
        letterSpacing: '0.25em',
        fontSize: '0.75rem',
        fontWeight: 700,
        marginRight: '1rem',
    },
};

const sOuter2 = {
    marginTop: 0,
    position: 'sticky',
    top: 9,
    left: 0,
    zIndex: 1000,
    [s.md]: { marginTop: 0, top: 14 },
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
};

const sGrid2 = {
    position: 'relative',
    top: 0,
    left: 0,
    transition: 'width 0.3s cubic-bezier(0.22, 1, 0.36, 1)',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 1.5rem 0 1.5rem',
    [s.md]: {
        padding: '0 1.5rem',
    },
};

const sInput = {
    fontSize: '13px',
    [s.md]: { fontSize: '15px' },
};

const sButtonReset = {
    opacity: 0.3,
    '&:hover': {
        opacity: 1,
    },
    right: 32,
    [s.sm]: { right: 52 },
    [s.md]: { right: 160, width: 52, maxWidth: 52 },
};
