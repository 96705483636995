export const GoogleIcon = ({
    color,
    size,
}: {
    color?: string;
    size?: string;
}) => (
    <svg
        width={size ?? '21'}
        height={size ?? '20'}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_6797_76180)">
            <path
                d="M20.2618 10.1868C20.2618 9.36743 20.1954 8.76949 20.0515 8.14941H10.6948V11.8477H16.187C16.0763 12.7668 15.4783 14.1509 14.1496 15.081L14.1309 15.2048L17.0893 17.4967L17.2943 17.5171C19.1767 15.7787 20.2618 13.2208 20.2618 10.1868Z"
                fill="#4285F4"
            />
            <path
                d="M10.6947 19.9312C13.3854 19.9312 15.6442 19.0453 17.2941 17.5173L14.1494 15.0812C13.3079 15.6681 12.1784 16.0777 10.6947 16.0777C8.05932 16.0777 5.8226 14.3393 5.02527 11.9365L4.9084 11.9464L1.83222 14.3271L1.79199 14.439C3.43077 17.6944 6.79695 19.9312 10.6947 19.9312Z"
                fill="#34A853"
            />
            <path
                d="M5.02526 11.9363C4.81488 11.3163 4.69313 10.6518 4.69313 9.96534C4.69313 9.27878 4.81488 8.61443 5.01419 7.99435L5.00862 7.86229L1.89389 5.44336L1.79198 5.49183C1.11656 6.84275 0.729004 8.35977 0.729004 9.96534C0.729004 11.5709 1.11656 13.0879 1.79198 14.4388L5.02526 11.9363Z"
                fill="#FBBC05"
            />
            <path
                d="M10.6947 3.85336C12.566 3.85336 13.8283 4.66168 14.548 5.33718L17.3605 2.59107C15.6332 0.985496 13.3854 0 10.6947 0C6.79695 0 3.43077 2.23672 1.79199 5.49214L5.01421 7.99466C5.8226 5.59183 8.05932 3.85336 10.6947 3.85336Z"
                fill="#EB4335"
            />
        </g>
        <defs>
            <clipPath id="clip0_6797_76180">
                <rect
                    width="19.542"
                    height="20"
                    fill="white"
                    transform="translate(0.729004)"
                />
            </clipPath>
        </defs>
    </svg>
);
