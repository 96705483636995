export const ShoppingBag = () => {
    return (
        <svg
            width="25"
            height="24"
            fill="inherit"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.75 3.75H4.25a1.5 1.5 0 0 0-1.5 1.5v13.5a1.5 1.5 0 0 0 1.5 1.5h16.5a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5Zm0 15H4.25V5.25h16.5v13.5ZM17 8.25a4.5 4.5 0 1 1-9 0 .75.75 0 0 1 1.5 0 3 3 0 1 0 6 0 .75.75 0 1 1 1.5 0Z"
                fill="inherit"
            />
        </svg>
    );
};
