/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

const Badge = ({
    children,
    extraCss,
    small,
    color = 'black',
    left,
    collapse,
    show,
    showAnimation,
}) => {
    return (
        <div
            css={[
                sBadge,
                { backgroundColor: color },
                small && sSmall,
                left ? { left: 0 } : { right: 0 },
                collapse && {
                    top: -6,
                    left: -6,
                    boxShadow: 'none',
                },
                extraCss,
                showAnimation &&
                    (show
                        ? { transform: 'scale(1)' }
                        : { transform: 'scale(0)' }),
            ]}
        >
            {children}
        </div>
    );
};

const sBadge = {
    position: 'absolute',
    top: 0,
    width: 'max-content',
    minWidth: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 11,
    color: '#ffffff',
    fontWeight: 700,
    borderRadius: 10,
    lineHeight: '1em',
    letterSpacing: '-0.05em',
    textAlign: 'center',
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    boxShadow: '0px 0px 0px 3px white',
    transition: 'transform 0.3s ease-in-out',
};

const sSmall = {
    minWidth: 12,
    height: 12,
    width: 12,
};

export default Badge;
