import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { ButtonBase } from '@material-ui/core';
import { colors, s } from '@style/index';

const defaultAmounts = [20, 50, 100, 200, 500];

export interface DefaultWalletAmountsProps {
    amount: string | number;
    onValueChange: (value: number) => void;
}
export const DefaultWalletAmounts = ({
    amount,
    onValueChange,
}: DefaultWalletAmountsProps) => {
    return (
        <AmountWrapper>
            {defaultAmounts.map((item, index) => (
                <DefaultAmount
                    changeColor={+amount === item}
                    key={index}
                    onClick={() => onValueChange(item)}
                >
                    {item}
                </DefaultAmount>
            ))}
        </AmountWrapper>
    );
};

const AmountWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
`;

const DefaultAmount = styled(ButtonBase)<{ changeColor: boolean }>(
    ({ changeColor }) => css`
        align-items: center;
        background-color: ${changeColor ? colors.text : colors.gray400};
        border-radius: 16px;
        color: ${changeColor ? colors.white : colors.text};
        display: flex;
        font-size: 13px;
        font-weight: 700;
        height: 32px;
        justify-content: center;
        padding: 0 0.5rem;
        transition:
            color 0.3s ease-out,
            background-color 0.3s ease-out;
    `,
);
