import {
    SpicinessRangeInput,
    UserUpdatePreferencesInput,
    useFoodPreferencesPageQuery,
    useUpdateUserPreferencesMutation,
} from '@graphql/generated';
import { AccordionChipType } from '@/EZC/domains/Account/organisms/ChipPreferencesAccordion/ChipPreferencesAccordion';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { usePreferencesIngredients } from '@hooks/usePreferencesIngreedients/usePreferencesIngreedients';

type UpdateUserPreferencesArgs<T extends keyof UserUpdatePreferencesInput> = {
    callback?: () => void;
    isAllergies?: boolean;
    name: T;
    value: SpicinessRangeInput | AccordionChipType[];
};

const useFoodPreferences = () => {
    const { data, loading } = useFoodPreferencesPageQuery({
        onError: graphqlErrorHandler(true),
    });
    const { allergies, ingredients } = usePreferencesIngredients();

    const [updateUserPreferences, { loading: updateLoading }] =
        useUpdateUserPreferencesMutation();

    const handleUpdateUserPreferences = <
        T extends keyof UserUpdatePreferencesInput,
    >({
        callback,
        name,
        value,
    }: UpdateUserPreferencesArgs<T>): void => {
        if (!preferences) return;

        let payload;

        if (name !== 'spiciness') {
            const chipsValue = value as AccordionChipType[];
            const notPreffered = chipsValue
                .filter(({ variant }) => variant === 'remove')
                .map(({ value }) => value);
            const preffered = chipsValue
                .filter(({ variant }) => variant === 'add')
                .map(({ value }) => value);

            if (name === 'allergies') {
                payload = notPreffered?.map((id) => id) || [];
            } else {
                payload = {
                    notPreffered,
                    preffered,
                } as UserUpdatePreferencesInput[T];
            }
        } else {
            payload = value as SpicinessRangeInput;
        }

        payload &&
            updateUserPreferences({
                variables: {
                    input: {
                        payload: {
                            preferences: {
                                [name]: payload,
                            },
                        },
                    },
                },
                onCompleted: () => {
                    toast.dark('Preferencje zostały zaktualizowane 👏');
                    callback?.();
                },
                onError: graphqlErrorHandler(true),
            });
    };

    const handleResetUserPreferences = (): void => {
        const resetPayload = {
            notPreffered: [],
            preffered: [],
        };

        updateUserPreferences({
            variables: {
                input: {
                    payload: {
                        preferences: {
                            cookMethod: resetPayload,
                            cuisine: resetPayload,
                            diet: resetPayload,
                            flavor: resetPayload,
                            ingredients: resetPayload,
                            allergies: [],
                            spiciness: {
                                min: 0,
                                max: 5,
                            },
                        },
                    },
                },
            },
            onCompleted: () => {
                toast.dark('Preferencje zostały zresetowane');
            },
            onError: graphqlErrorHandler(true),
        });
    };

    const { personal, preferences } = data?.user || {};

    return {
        allergies,
        ingredients,
        handleResetUserPreferences,
        handleUpdateUserPreferences,
        loading,
        personal,
        preferences,
        updateLoading,
    };
};

export default useFoodPreferences;
