import React, { useEffect, useState } from 'react';

export const useVisibleComponent = (
    element: React.RefObject<Element>,
    rootMargin: string,
) => {
    const [visibleElement, setVisibleElement] =
        useState<IntersectionObserverEntry>();

    useEffect(() => {
        const current = element?.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setVisibleElement(entry);
            },
            { rootMargin },
        );
        current && observer?.observe(current);

        return () => {
            current && observer.unobserve(current);
        };
    }, [element, rootMargin]);

    return visibleElement;
};
