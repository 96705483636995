import { Grid } from '@material-ui/core';
import { SectionHeadline } from '../../../../components/Elements';
import { sDescription, sGrid } from '../../../../style/style';
import { s } from '../../../../style';
import { Controller, useFormContext } from 'react-hook-form';
import { SPICINESS_LEVELS } from '@constants/spicinessLevels';
import { RangeSlider } from '@/componentsV2/molecules/RangeSlider/RangeSlider';

export const ProductSpiciness = () => {
    const { control } = useFormContext();

    return (
        <Grid container css={sGrid}>
            <SectionHeadline noPaddingLeft>Skala ostrości</SectionHeadline>
            <Grid item xs={12}>
                <p css={[sDescription, { marginBottom: '0rem' }]}>
                    Wybierz stopień ostrości Twoich dań.
                </p>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                // TODO: refactor inline styles
                css={{ marginTop: '1rem', [s.md]: { paddingRight: '0.75rem' } }}
            >
                <Controller
                    name="spiciness"
                    control={control}
                    render={({ field, fieldState }) => (
                        <RangeSlider
                            {...field}
                            isDirty={fieldState.isDirty}
                            marks={SPICINESS_LEVELS}
                            max={5}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};
