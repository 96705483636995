import { css } from '@emotion/core';

import CircularProgress, {
    CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import styled from '@emotion/styled';
import { colors } from '@style/index';

type SpinnerVariant = 'default' | 'container' | 'absolute';

interface SpinnerProps extends CircularProgressProps {
    className?: string;
    inline?: boolean;
    spinnerColor?: string;
    spinnerVariant?: SpinnerVariant;
}

export const Spinner = ({
    className,
    inline = false,
    spinnerColor,
    spinnerVariant = 'default',
    ...props
}: SpinnerProps) => {
    return (
        <Wrapper
            className={className}
            spinnerColor={spinnerColor}
            inline={inline}
            spinnerVariant={spinnerVariant}
        >
            <CircularProgress color="inherit" thickness={5} {...props} />
        </Wrapper>
    );
};

const Wrapper = styled.div<{
    inline?: boolean;
    spinnerColor?: string;
    spinnerVariant: SpinnerVariant;
}>(
    ({ inline, spinnerColor, spinnerVariant }) => css`
        color: ${spinnerColor ?? colors.text};
        display: flex;
        justify-content: center;

        ${inline &&
        css`
            display: inline-flex;
        `}

        ${spinnerVariant === 'container' &&
        css`
            align-items: center;
            height: 100vh;
            left: 0;
            position: absolute;
            top: 0;
            width: 100vw;
        `}

        ${spinnerVariant === 'absolute' &&
        css`
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        `}
    `,
);
