/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

const OrdersAmount = ({ orderAmount }) => (
    <span
        css={{
            width: '50vh',
            maxWidth: '50vw',
            height: '50vh',
            color: '#ffffff',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '25vh',
            fontWeight: 700,
        }}
    >
        {orderAmount}
    </span>
);

export default OrdersAmount;
