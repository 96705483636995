import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    useSearchPageProductsLazyQuery,
    useSearchPageRestaurantsLazyQuery,
} from '@graphql/generated';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { SPECIAL_CHARS_REGEX } from '@constants/defaults.constants';
import { isEmpty } from 'lodash';
import { SearchFilterFormSchemaValues } from '@/EZC/domains/Search/SearchFilterFormProvider/SearchFilterFormProvider';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';
import { DEFAULTS_CONSTANTS } from '../constants/defaults.constants';

export const PAGE_SIZE = 20;

type GetDataOptions = {
    initialPage?: boolean;
};

export const useSearchQueries = () => {
    const { search } = useLocation();
    const methods = useFormContext<SearchFilterFormSchemaValues>();

    const { localizedBannerEnabled, restaurantsForLocalizedHomepage } =
        useServerConfigStoreShallow([
            'restaurantsForLocalizedHomepage',
            'localizedBannerEnabled',
        ]);

    const [getProducts, productsQuery] = useSearchPageProductsLazyQuery({
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true,
        onError: graphqlErrorHandler(true),
    });

    const [getRestaurants, restaurantsQuery] =
        useSearchPageRestaurantsLazyQuery({
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true,
            onError: graphqlErrorHandler(true),
        });

    const getProductsData = (options?: GetDataOptions) => {
        const values = methods.getValues();
        const page = productsQuery.data?.productList.metadata?.currentPage ?? 0;

        const filteredValues = Object.fromEntries(
            Object.entries(values).filter(([, value]) => !isEmpty(value)),
        ) as Partial<SearchFilterFormSchemaValues>;

        if (search) {
            getProducts({
                variables: {
                    pagination: {
                        page: options?.initialPage ? 1 : page + 1,
                        pageSize: PAGE_SIZE,
                    },
                    input: {
                        payload: {
                            name: filteredValues?.query?.replace(
                                SPECIAL_CHARS_REGEX,
                                '',
                            ),
                            isBrochure: values.showBrochures ?? false,
                            applyUserPreferences:
                                values.applyUserPreferences ?? false,
                            preferableCookMethods: filteredValues?.cookMethod,
                            preferableCuisines: filteredValues?.cuisine,
                            preferableDiets: filteredValues?.diet,
                            preferableDishes: filteredValues?.dish,
                            preferableMealTimes: filteredValues?.mealTime,
                            preferableFlavors: filteredValues?.flavour,
                            preferableIngredients:
                                filteredValues?.ingredients || [],
                            price: filteredValues.price
                                ? filteredValues.price === '120+'
                                    ? { min: 120, max: 0 }
                                    : {
                                          min: 0,
                                          max: parseInt(filteredValues.price),
                                      }
                                : undefined,
                            calories: filteredValues.calories
                                ? filteredValues.calories === '2000+'
                                    ? { min: 2000, max: 0 }
                                    : {
                                          min: 0,
                                          max: parseInt(
                                              filteredValues.calories,
                                          ),
                                      }
                                : undefined,
                            cookingTime: filteredValues.cookingTime
                                ? filteredValues.cookingTime === '60+'
                                    ? { min: 60, max: 0 }
                                    : {
                                          min: 0,
                                          max: parseInt(
                                              filteredValues.cookingTime,
                                          ),
                                      }
                                : undefined,
                            spiciness: filteredValues?.spiciness
                                ? {
                                      values: filteredValues.spiciness.map(
                                          (item) => parseInt(item),
                                      ),
                                  }
                                : undefined,
                            location: {
                                coordinates: [
                                    values.geolocation.lng,
                                    values.geolocation.lat,
                                ],
                            },
                            maxDistance:
                                (parseInt(filteredValues?.distance ?? '') ||
                                    0) * 1000 ||
                                DEFAULTS_CONSTANTS.distanceInMeters,
                            group: filteredValues?.groups,
                            consumptionTypes: filteredValues?.consumptionTypes,
                            notPreferableIngredients:
                                filteredValues?.notPreffered ?? [],
                            allergies: filteredValues?.allergy ?? [],
                        },
                        restaurantIds: localizedBannerEnabled
                            ? restaurantsForLocalizedHomepage
                            : undefined,
                    },
                },
            });
        }
    };

    const getRestaurantsData = (options?: GetDataOptions) => {
        const page =
            restaurantsQuery.data?.restaurantList?.metadata?.currentPage ?? 0;
        const values = methods.getValues();

        const filteredValues = Object.fromEntries(
            Object.entries(values).filter(([, value]) => !isEmpty(value)),
        ) as Partial<SearchFilterFormSchemaValues>;

        if (search) {
            getRestaurants({
                variables: {
                    pagination: {
                        page: options?.initialPage ? 1 : page + 1,
                        pageSize: PAGE_SIZE,
                    },
                    input: {
                        payload: {
                            name: filteredValues?.query?.replace(
                                SPECIAL_CHARS_REGEX,
                                '',
                            ),
                            maxDistance:
                                (parseInt(filteredValues?.distance ?? '') ||
                                    0) * 1000 ||
                                DEFAULTS_CONSTANTS.distanceInMeters,
                            location: {
                                coordinates: [
                                    values.geolocation.lng,
                                    values.geolocation.lat,
                                ],
                            },
                            consumptionTypes: filteredValues?.consumptionTypes,
                            isBrochure: values?.showBrochures,
                            isActive: true,
                        },
                        restaurantIds: localizedBannerEnabled
                            ? restaurantsForLocalizedHomepage
                            : undefined,
                    },
                },
            });
        }
    };

    const getData = (options?: GetDataOptions) => {
        getProductsData(options);
        getRestaurantsData(options);
    };

    return {
        getData,
        getProductsData,
        getRestaurantsData,
        loading: productsQuery.loading || restaurantsQuery.loading,
        productsQuery,
        restaurantsQuery,
    };
};
