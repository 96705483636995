import { CSSProperties } from 'react';
import {
    Checkbox as MuiCheckbox,
    CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors } from '@/style';
import { CheckIcon } from '@/svgs/CheckIcon';
import { UncheckIcon } from '@/svgs/UncheckIcon';

type FillColor = keyof typeof colors;

export interface CheckboxProps extends MuiCheckboxProps {
    children?: React.ReactNode;
    fillColor?: FillColor;
    borderColor?: FillColor;
    reverse?: boolean;
    width?: CSSProperties['width'];
}

export const Checkbox = ({
    children,
    disabled,
    fillColor = 'green',
    borderColor = 'green',
    reverse,
    width,
    ...props
}: CheckboxProps) => {
    return (
        <Wrapper reverse={!!reverse} disabled={disabled} width={width}>
            {children}
            <CheckboxWrapper
                fillColor={fillColor}
                disabled={disabled}
                reverse={reverse}
            >
                <MuiCheckbox
                    {...props}
                    disabled={disabled}
                    icon={<UncheckIcon color={colors[borderColor]} />}
                    checkedIcon={<CheckIcon color={colors[fillColor]} />}
                />
            </CheckboxWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.label<{
    reverse: boolean;
    disabled?: boolean;
    width?: CSSProperties['width'];
}>(
    ({ disabled, reverse, width }) => css`
        cursor: ${disabled ? 'default' : 'pointer'};
        display: inline-grid;
        grid-template-columns: ${reverse ? 'auto 1fr' : '1fr auto'};
        align-items: center;
        width: ${width ? width : 'auto'};
        user-select: none;
        gap: 8px;
        pointer-events: ${disabled ? 'none' : 'unset'};
    `,
);

const CheckboxWrapper = styled.span<{
    fillColor: FillColor;
    disabled?: boolean;
    reverse?: boolean;
}>(
    ({ fillColor, disabled, reverse }) => css`
        align-items: center;
        display: flex;
        justify-content: center;
        opacity: ${disabled ? 0.3 : 1};
        order: ${reverse ? '-1' : '0'};

        .MuiCheckbox-root {
            color: ${colors[fillColor]};
            padding: 0;
        }

        .PrivateSwitchBase-root-1 {
            padding: 0;
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
            color: ${colors[fillColor]};
        }
    `,
);
