/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom';

import { useOrderInCartByRestaurant } from '@/EZC/context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider';

// Components
import PageLoader from '@components/PageLoader/PageLoader';
import OrderInfo from '@/EZC/components/BottomOrderInfo/BottomOrderInfo.component';

// Views

import { s, colors, globals, alpha } from '@style';

import { ButtonBase, Grid } from '@material-ui/core';
import BackgroundBlur from '@/EZC/views/ProductPage/components/BackgroundBlur';
import Intro from '@/EZC/views/ProductPage/components/Intro';
import Location from '@/EZC/views/ProductPage/components/Location';
import Availability from '@/EZC/views/ProductPage/components/Availability';
import Delivery from '@/EZC/views/ProductPage/components/Delivery';
import ProductDetails from '@/EZC/views/ProductPage/components/ProductDetails';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import Carousel from '@/EZC/components/Carousel/Carousel.component';
import RestContainer from '@/EZC/views/ProductPage/components/RestContainer';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import ConsumptionTypes from '@/EZC/views/ProductPage/components/ConsumptionTypes';
import { useAuthService } from '@/services/AuthService';
import {
    useAllProductsLazyQuery,
    useProductPageQuery,
} from '@graphql/generated';
import { priceFormat } from '@helpers/priceFormat';
import { productHelpers } from '@helpers/product.helpers';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { ProdItemMiniV2 as ProdItemMini } from '@/EZC/components/ProdItem/ProdItemMiniV2.component';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useCommonStore } from '@/CommonStore';
import { ProductAvatar } from '@/svgs/ProductAvatar';
import { Metadata } from '@/componentsV2/atoms/Metadata/Metadata';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { EatzonIcon } from '@/componentsV2/atoms/EatzonIcon/EatzonIcon';

const Product = () => {
    const prodId = useParams().id;
    const history = useHistory();
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const { addProductsBrowsingHistory } = useCommonStore();

    const { data, loading } = useProductPageQuery({
        variables: {
            product: { productId: prodId },
        },
        onCompleted: (data) => {
            addProductsBrowsingHistory(data.product.id);
        },
    });

    const [getAllProducts, { data: allProducts }] = useAllProductsLazyQuery();

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination, input) =>
            getAllProducts({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input,
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: allProducts?.productList?.metadata,
        pageSize: 20,
        input: {
            payload: {
                applyUserPreferences: false,
                restaurantId: data?.product?.restaurant?.id,
            },
        },
    });

    const {
        order,
        loadingOrders,
        setRestId,
        handleAddProd,
        handleDeleteProduct,
    } = useOrderInCartByRestaurant();

    const { top } = useSafeAreaInsets();

    useEffect(() => {
        if (data) setRestId(data.product.restaurant.id);
    }, [data]);

    const productInCart =
        order && order?.lines?.find((line) => line.product.id === prodId);

    useEffect(() => {
        AnalyticsHelpers.createCustomEvent({
            name: 'add_to_cart',
            params: { type: 'product' },
        });
    }, [!!productInCart?.quantity]);

    useEffect(() => {
        AnalyticsHelpers.createCustomEvent({
            name: 'view_item',
            params: { type: 'product' },
        });
    }, []);

    if (loading || loadingOrders) return <PageLoader />;

    if (!data) return <React.Fragment>Coś poszło nie tak...</React.Fragment>;

    const { product } = data;

    const {
        name,
        consumptionTypes,
        description,
        photoUrl,
        flavors,
        cookMethods,
        cookTime,
        price,
        ingredients,
        availableHours,
        restaurant,
    } = product;

    const isInactive = restaurant?.isBrochure;

    if (!product) {
        history.push('/404');
        return null;
    }

    let noAuthRestData;
    let noAuthProdData;
    if (!isLoggedIn) {
        noAuthRestData = {
            id: restaurant.id,
            name: restaurant.name,
            slug: restaurant.slug,
            avatarUrl: restaurant.avatarUrl,
            address: restaurant.address,
        };
        noAuthProdData = {
            id: prodId,
            price: product.price,
            packagingPrice: product.packagingPrice,
            name: product.name,
            photoUrl: product.photoUrl,
            type: product.consumptionTypes.find((type) =>
                restaurant?.consumptionTypes.includes(type),
            ),
        };
    }

    const getHoursAvailability = (firstHours, secondHours) => {
        const result = {};
        for (const day in firstHours) {
            if (
                firstHours.hasOwnProperty(day) &&
                secondHours.hasOwnProperty(day)
            ) {
                const openMax = Math.max(
                    firstHours[day].open,
                    secondHours[day].open,
                );
                const closeMin = Math.min(
                    firstHours[day].close,
                    secondHours[day].close,
                );
                result[day] = { open: openMax, close: closeMin };
            }
        }
        return result;
    };

    const productAvailableHours = getHoursAvailability(
        convertTimestamps.fromISO(availableHours),
        convertTimestamps.fromISO(restaurant.openingHours),
    );

    const sum = order?.lines
        ?.map(({ quantity, product: { price } }) => quantity * price)
        ?.reduce((product1, product2) => product1 + product2, 0);

    const categoriesShow = productHelpers.getCategories(product);

    return (
        // <Layout darknav navNoBRadius>
        <React.Fragment>
            <Metadata
                title={`Eatzon - ${restaurant?.name} - ${name}`}
                description={description}
                ogImage={photoUrl}
            />
            <TopNav
                title={name}
                subtitle={
                    <>
                        {/* <Hidden smDown> */}
                        &nbsp;- {restaurant?.name}
                        {/* </Hidden> */}
                    </>
                }
                textCss={{ maxWidth: 'calc(100vw - 14rem)' }}
                photo={photoUrl}
            ></TopNav>

            <BackgroundBlur title={name} photo={photoUrl} />

            {/* Intro */}
            <Intro
                product={product}
                title={name}
                photo={photoUrl}
                photoAvatar={
                    photoUrl ? (
                        <img src={photoUrl} alt={name} loading="lazy" />
                    ) : (
                        <ProductAvatar />
                    )
                }
                categories={categoriesShow}
                restAvatar={restaurant?.avatarUrl}
                description={description}
                isBrochure={isInactive}
            >
                <div
                    css={{
                        transition:
                            'background-color 0.3s ease-out, transform 0.3s ease-out, box-shadow 0.3s ease-out',
                        display: 'flex',
                        borderRadius: '2rem',
                        height: 48,
                        minWidth: 300,
                        position: 'relative',
                        backgroundColor: colors.text,
                        width: 'max-content',
                        color: '#ffffff',
                        fontSize: '15px',
                        fontWeight: 700,
                        letterSpacing: '0.05em',
                        [s.sss]: {
                            minWidth: 272,
                            maxWidth: 272,
                        },
                        [s.sm_down]: { width: '100%' },
                        '&:hover': {
                            [s.hover]: { backgroundColor: colors.green800 },
                        },
                    }}
                >
                    <ButtonBase
                        disabled={product?.deleted}
                        onClick={(e) => {
                            if (!productInCart || productInCart.quantity === 0)
                                handleAddProd(
                                    e,
                                    prodId,
                                    noAuthProdData,
                                    noAuthRestData,
                                );
                        }}
                        css={[
                            sCartButtonNoItemButton,
                            productInCart?.quantity > 0 && { opacity: 0 },
                        ]}
                    >
                        <span css={{ fontSize: '10px' }}> do koszyka </span>
                        &nbsp;+ {price.toFixed(2)} zł
                    </ButtonBase>
                    {productInCart?.quantity >= 1 && (
                        <ButtonBase
                            css={[
                                sCartButtonAddRemoveBase,
                                { borderRadius: 16 },
                            ]}
                        >
                            <div
                                css={sCartButtonAddRemove}
                                onClick={() => {
                                    if (productInCart?.quantity >= 1)
                                        handleDeleteProduct({
                                            orderId: order.id,
                                            prodId,
                                        });
                                }}
                            >
                                <RemoveIcon />
                            </div>
                            {productInCart?.quantity} x {price.toFixed(2)} zł
                            <div
                                css={sCartButtonAddRemove}
                                onClick={(e) =>
                                    handleAddProd(
                                        e,
                                        prodId,
                                        noAuthProdData,
                                        noAuthRestData,
                                    )
                                }
                            >
                                <AddIcon />
                            </div>
                        </ButtonBase>
                    )}
                </div>
            </Intro>

            {/* Location */}
            <Grid container css={sTabs}>
                {/* Availability */}
                {restaurant && (
                    <Grid item xs={12} md={6}>
                        <Availability
                            hours={productAvailableHours}
                            type="product"
                            isRestActive={restaurant.isActive}
                            isProdArchived={product?.deleted}
                        />
                    </Grid>
                )}
                {restaurant && !isInactive && (
                    <Grid item xs={12} md={6}>
                        <Delivery
                            restId={restaurant?.id}
                            isDelivery={restaurant?.consumptionTypes?.includes(
                                'DELIVERY',
                            )}
                            isRestActive={restaurant?.isActive}
                        />
                    </Grid>
                )}
                {restaurant && (
                    <Grid item xs={12} md={6}>
                        <ConsumptionTypes
                            left={!isInactive}
                            right={isInactive}
                            consumptionTypes={consumptionTypes.filter((type) =>
                                restaurant?.consumptionTypes?.includes(type),
                            )}
                        />
                    </Grid>
                )}
                {restaurant && (
                    <Grid item xs={12} md={6}>
                        <Location
                            right={!isInactive}
                            left={isInactive}
                            id={restaurant.id}
                            address={restaurant.address}
                            location={restaurant.location}
                        />
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    <ProductDetails
                        left={!isInactive}
                        right={isInactive}
                        ingredients={ingredients}
                        cookMethod={cookMethods}
                        cookTime={cookTime}
                        flavors={flavors}
                        openAlready
                    />
                </Grid>
            </Grid>

            {/* <RestItemLarge data={restaurant} /> */}

            {restaurant && <RestContainer data={restaurant} />}

            <div
                css={{
                    width: '100%',
                    maxWidth: globals.maxWidthMediumCarousel,
                    margin: '75px auto 0',
                    padding: '0 0 48px',
                }}
            >
                {allProducts?.productList?.data?.length > 1 && (
                    <Carousel
                        data={allProducts?.productList?.data?.filter(
                            (el) => el?.id !== prodId,
                        )}
                        CarouselItem={ProdItemMini}
                        link="/product/"
                        scrollButtons={182 * 3}
                        hasMore={hasMore}
                        getMoreData={getMoreData}
                    />
                )}
            </div>

            {order && !isInactive && (
                <OrderInfo
                    mainText={priceFormat.standardPrice(sum)}
                    btnOnClick={() =>
                        history.push(`/cart-checkout/${order?.id}`)
                    }
                    isShow={order}
                />
            )}

            <div
                css={{
                    zIndex: 1001,
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: '100vw',
                    pointerEvents: 'none',
                    [s.sm_down]: {
                        paddingTop: 50,
                        height: globals.topnavHeight.desktop + 50,
                        transform: 'translateY(-50px)',
                        top: top - 14,
                    },
                }}
            >
                <div
                    css={{
                        width: '100%',
                        maxWidth: globals.maxWidthMedium,
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        [s.sm_down]: {
                            height: globals.topnavHeight.desktop,
                        },
                        height: globals.topnavHeight.desktop,
                        padding: '0 1.5rem',
                    }}
                >
                    {isInactive ? (
                        <EatzonIcon
                            isInactive={isInactive}
                            size={18}
                            space={6}
                        />
                    ) : (
                        <div
                            className="product-buy"
                            css={{
                                pointerEvents: 'auto',
                                position: 'relative',
                                transition: 'background-color 0.3s ease-out',
                                height: 32,
                                minWidth: 90,
                                width: 'max-content',
                                whiteSpace: 'nowrap',
                                // padding: '0 0.5rem',
                                fontWeight: 700,
                                backgroundColor: colors.white.concat(alpha[30]),
                                borderRadius: 16,
                                fontSize: '15px',
                                '&:hover': {
                                    [s.hover]: {
                                        backgroundColor: colors.gray400,
                                    },
                                },
                            }}
                        >
                            <ButtonBase
                                disabled={product?.deleted}
                                onClick={(e) => {
                                    if (
                                        !productInCart ||
                                        productInCart.quantity === 0
                                    )
                                        handleAddProd(
                                            e,
                                            prodId,
                                            noAuthProdData,
                                            noAuthRestData,
                                        );
                                }}
                                css={[
                                    sCartButtonNoItemButton,
                                    productInCart?.quantity > 0 && {
                                        opacity: 0,
                                    },
                                    {
                                        fontWeight: 700,
                                        fontSize: '13px',
                                        color: 'currentColor',
                                        letterSpacing: '0em',
                                    },
                                ]}
                            >
                                + {price.toFixed(2)} zł
                            </ButtonBase>
                            {productInCart?.quantity >= 1 && (
                                <ButtonBase
                                    css={[
                                        sCartButtonAddRemoveBase,
                                        {
                                            color: 'currentColor',
                                            borderRadius: 16,
                                        },
                                    ]}
                                >
                                    <div
                                        css={[sTopNavAddRemove]}
                                        onClick={() => {
                                            if (productInCart?.quantity >= 1)
                                                handleDeleteProduct({
                                                    orderId: order.id,
                                                    prodId,
                                                });
                                        }}
                                    >
                                        <RemoveIcon />
                                    </div>
                                    {productInCart.quantity}
                                    <div
                                        css={[sTopNavAddRemove]}
                                        onClick={(e) =>
                                            handleAddProd(
                                                e,
                                                prodId,
                                                noAuthProdData,
                                                noAuthRestData,
                                            )
                                        }
                                    >
                                        <AddIcon />
                                    </div>
                                </ButtonBase>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

const sTabs = {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    maxWidth: globals.maxWidthMedium,
    margin: '0 auto',
    paddingBottom: 0,
    [s.md]: { flexDirection: 'row', paddingTop: '2.5rem' },
};

const sCartButtonAddRemove = {
    transition: 'background-color 0.3s ease-out',
    height: 48,
    width: 48,
    borderRadius: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        [s.hover]: { backgroundColor: colors.green400.concat(alpha[30]) },
    },
};

const sTopNavAddRemove = {
    height: 32,
    width: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const sCartButtonAddRemoveBase = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 700,
    letterSpacing: '0.05em',
};

const sCartButtonNoItemButton = {
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    jsutifyContent: 'center',
    alignItems: 'center',
    transition:
        'color 0.3s ease-out, background-color 0.3s ease-out, opacity 0.3s ease-out',
    textAlign: 'center',
    borderRadius: 24,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '0.05em',
};

export default Product;
