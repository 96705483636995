import { MenuItem } from '@/EZC/pages/account/Account.page';
import { LocationWithBook } from '@/svgs/LocationWithBook';
import React from 'react'; // eslint-disable-line no-unused-vars

export const SettingsAddressBook = () => {
    return (
        <MenuItem
            id={1}
            to={`/account/address-book`}
            name={'Książka adresowa'}
            description={`Adresy dostawy`}
            AvatarIcon={LocationWithBook}
            badgeContent={undefined}
            showBadge={false}
            action={undefined}
            smallIcon
        />
    );
};
