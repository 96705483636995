import {
    CookMethod,
    Cuisine,
    Diet,
    Dish,
    Flavor,
    MealTime,
} from '@graphql/generated';
import { COOK_METHOD } from './fromGraphql/CookMethod.constants';
import { CUISINE } from './fromGraphql/Cuisine.constants';
import { DIET } from './fromGraphql/Diet.constants';
import { DISH } from './fromGraphql/Dish.constants';
import { FLAVOUR } from './fromGraphql/Flavor.constants';
import { MEAL_TIME } from './fromGraphql/MealTime.constants';

export type Category = CookMethod | Cuisine | Diet | Dish | Flavor | MealTime;

export const CATEGORIES: Record<Category, { name: string }> = {
    ...COOK_METHOD,
    ...CUISINE,
    ...DIET,
    ...DISH,
    ...FLAVOUR,
    ...MEAL_TIME,
};
