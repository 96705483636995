import { useState } from 'react';
import { usePlacesAutocomplete } from '../../../../components/GooglePlacesAutocomplete';

export default () => {
    const [restaurantInfo, setRestaurantInfo] = useState(initRestaurantInfo);

    const handleRestaurantInfo = (e) => {
        setRestaurantInfo({
            ...restaurantInfo,
            [e.target.name]: e.target.value,
        });
    };

    // Google API Address
    const { onSelectAddress } = usePlacesAutocomplete();
    const handleChangeAddres = (address) =>
        setRestaurantInfo({ ...restaurantInfo, address });
    const handleSelectAddress = (address) => {
        onSelectAddress(address).then(({ lng, lat }) =>
            setRestaurantInfo({
                ...restaurantInfo,
                address,
                location: { coordinates: [lng, lat] },
            }),
        );
    };

    return {
        restaurantInfo,
        setRestaurantInfo,
        handleRestaurantInfo,
        handleChangeAddres,
        handleSelectAddress,
    };
};

export const initRestaurantInfo = {
    name: '',
    description: '',
    address: '',
    consumptionTypes: ['IN', 'TAKEAWAY', 'DELIVERY'],
    layoutPdfUrl: null,
};
