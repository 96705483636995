import styled from '@emotion/styled';
import { ReactElement, ReactNode } from 'react';
import { colors } from '@style/index';

export interface PreferencesBlockProps {
    children: ReactNode;
    description?: string;
    icon?: ReactElement;
    title: string;
}

export const PreferencesBlock = ({
    description,
    icon,
    title,
    children,
}: PreferencesBlockProps) => {
    return (
        <ContentWrapper>
            <TitleWrapper>
                <PreferenceTitle>{title}</PreferenceTitle>
                {icon}
            </TitleWrapper>
            {description && <Description>{description}</Description>}
            <div>{children}</div>
        </ContentWrapper>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const TitleWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
`;

const PreferenceTitle = styled.h2`
    font-size: 20px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
`;
