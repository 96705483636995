import React from 'react';
import styled from '@emotion/styled';
import MaterialStarIcon from '@material-ui/icons/Star';
import { mediaQueries } from '@style/breakpoints';

export interface AverageRatingProps {
    average: number;
    ratings: number;
}

export const AverageRating = ({ average, ratings }: AverageRatingProps) => {
    if (!ratings) return null;

    return (
        <AverageRatingWrapper>
            <Icon />
            <AverageNumber>{average.toFixed(2)}</AverageNumber>
            <RatingsNumber>({ratings})</RatingsNumber>
        </AverageRatingWrapper>
    );
};

const AverageRatingWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 4px;
`;

const Icon = styled(MaterialStarIcon)`
    font-size: 15px;

    ${mediaQueries.xs} {
        margin-top: -2px;
    }
`;

const AverageNumber = styled.span`
    color: #343340;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
`;

const RatingsNumber = styled.span`
    color: #343340;
    font-size: 12px;
    line-height: 15px;
`;
