import React, { useState } from 'react';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import styled from '@emotion/styled';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import {
    OrderItemProductPayoffFragment,
    ProductLabel,
} from '@graphql/generated';
import { ProductStatusSelect } from '@/componentsV2/molecules/ProductStatusSelect/ProductStatusSelect';
import { colors, s } from '@style/index';
import { ProductStatusBadge } from '@/componentsV2/atoms/ProductStatusBadge/ProductStatusBadge';
import { productHelpers } from '@helpers/product.helpers';
import { ItemWithCheckbox } from '@/componentsV2/atoms/ItemWithCheckbox/ItemWithCheckbox';
import { css } from '@emotion/react';
import { Badge } from '@components/Elements';

interface ProductTileChangeProductStatusProps {
    changeableStatus?: boolean;
    /**
     * If you have the same poduct in items, combine them and add to this prop.
     * If you have only one item add single element `items={[item]}`
     */
    items: OrderItemProductPayoffFragment[];
    onChangeLabel: (id: string, label: ProductLabel) => void;
    loading?: boolean;
    checkbox?: boolean;
    checkedValues?: string[];
    onChange?: (item: string[], checked: boolean) => void;
}

export const ProductTileChangeProductStatus = ({
    changeableStatus,
    items,
    onChangeLabel,
    loading,
    checkbox = false,
    checkedValues,
    onChange,
}: ProductTileChangeProductStatusProps) => {
    const [open, setOpen] = useState(items.length > 1);

    const firstItem = items[0];
    const firstProduct = firstItem.product;

    const multipleItems = items.length > 1 && changeableStatus;

    const [changedItem, setChangetItem] = useState('');

    const changeStatus = (id: string, label: ProductLabel) => {
        setChangetItem(id);
        onChangeLabel(id, label);
    };

    const renderStatus = (
        item: OrderItemProductPayoffFragment,
        lighter?: boolean,
    ) =>
        item.label === 'CANCELED' ? (
            <ProductStatusBadge value={item.label} />
        ) : (
            <ProductStatusSelect
                loading={(loading || false) && item.id === changedItem}
                onChange={(label) => changeStatus(item.id, label)}
                value={item.label}
                lighter={lighter}
            />
        );

    const activeItems = items.filter((item) => item.label !== 'CANCELED');

    const showBadge = (item: OrderItemProductPayoffFragment) =>
        !!item.fromOrderAddition &&
        !item.orderAdditionAddedByWaiter &&
        item.label === 'PENDING';

    return (
        <Accordion
            headerElement={
                <ProductTile
                    average={firstProduct.rating}
                    categories={productHelpers.getCategories(firstProduct)}
                    imageUrl={firstProduct.photoUrl ?? ''}
                    name={firstProduct.name}
                    price={firstProduct?.price * activeItems.length}
                    productPrice={firstProduct.price}
                    quantity={activeItems.length}
                    ratings={firstProduct.ratingsCount}
                    showBagde={items.some(showBadge)}
                    //TODO it is temporary solution EAT-590
                    height="174px"
                    onClick={
                        multipleItems
                            ? () => setOpen((prevState) => !prevState)
                            : undefined
                    }
                    rightElement={
                        multipleItems || !changeableStatus
                            ? undefined
                            : renderStatus(firstItem, checkbox)
                    }
                />
            }
            headerPadding={'0'}
            isOpen={open}
            preventCollapse
            removeExpandIcon={!multipleItems || !changeableStatus}
            iconPosition="bottom"
            collapsedPadding={checkbox ? '6px 0 6px 6px' : '16px 24px'}
        >
            <List checkboxItem={checkbox}>
                {items?.map((item, index) => (
                    <React.Fragment key={index}>
                        {checkbox && !!onChange ? (
                            <Item checkboxItem>
                                <ItemWithCheckbox
                                    disabled={item?.label === 'CANCELED'}
                                    indeterminate={item?.label === 'CANCELED'}
                                    checked={checkedValues?.includes(item.id)}
                                    onChange={(_, checked) => {
                                        onChange([item.id], checked);
                                    }}
                                >
                                    <ItemWrapper moreColumns={showBadge(item)}>
                                        <Text>
                                            {index + 1}. {item.product.name}{' '}
                                        </Text>
                                        {showBadge(item) && (
                                            <BadgeWrapper>
                                                {/* @ts-expect-error migrate to ts */}
                                                <Badge
                                                    color={colors.red}
                                                    show
                                                    showAnimation
                                                    small
                                                    left
                                                    extraCss={{
                                                        [s.sm_down]: {
                                                            left: '-12px',
                                                        },
                                                    }}
                                                />
                                            </BadgeWrapper>
                                        )}
                                        {renderStatus(item, checkbox)}
                                    </ItemWrapper>
                                </ItemWithCheckbox>
                            </Item>
                        ) : (
                            <Item>
                                <Text>
                                    {index + 1}. {item.product.name}{' '}
                                </Text>
                                {showBadge(item) && (
                                    <BadgeWrapper>
                                        {/* @ts-expect-error migrate to ts */}
                                        <Badge
                                            color={colors.red}
                                            show
                                            showAnimation
                                            small
                                            left
                                        />
                                    </BadgeWrapper>
                                )}
                                {renderStatus(item)}
                            </Item>
                        )}
                    </React.Fragment>
                ))}
            </List>
        </Accordion>
    );
};

const List = styled.ul<{ checkboxItem?: boolean }>(
    ({ checkboxItem }) => css`
        display: flex;
        flex-direction: column;
        gap: ${checkboxItem ? 0 : '8px'};
    `,
);

const Item = styled.li<{ checkboxItem?: boolean }>(
    ({ checkboxItem }) => css`
        align-items: center;
        display: flex;
        gap: 16px;
        justify-content: space-between;
        color: ${colors.gray600};
        font-size: 12px;

        ${checkboxItem &&
        css`
            padding: 6px 16px 10px 6px;
        `}
    `,
);

const Text = styled.span`
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const BadgeWrapper = styled.div`
    flex: 1;
    height: 12px;
    position: relative;
`;

const ItemWrapper = styled.div<{ moreColumns?: boolean }>(
    ({ moreColumns }) => css`
        display: grid;
        grid-template-columns: ${moreColumns ? 'auto 1fr 1fr' : 'auto 1fr'};
        align-items: center;
        grid-gap: 8px;
    `,
);
