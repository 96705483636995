import styled from '@emotion/styled';
import { colors } from '@style/index';
import { Grid } from '@material-ui/core';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { ServingTime } from '@/svgs/ServingTime';
import { ButtonSecondary } from '@/componentsV2/atoms/ButtonSecondary/ButtonSecondary';
import EventIcon from '@material-ui/icons/Event';
import { TableChairIcon } from '@/svgs/TableChairIcon';
import { css } from '@emotion/react';
import { mediaQueriesMaterial } from '@style/breakpoints';

export interface CartServingTimeProps {
    serveOnTime: boolean;
    onChangeServeOnTime: (value: boolean) => void;
    padding?: string;
}

export const CartServingTime = ({
    serveOnTime,
    onChangeServeOnTime,
    padding,
}: CartServingTimeProps) => {
    return (
        <Grid item xs={12} md={6}>
            <Container padding={padding}>
                <Accordion
                    headerElement={
                        <ContentWrapper>
                            <IconWrapper>
                                <ServingTime />
                            </IconWrapper>
                            <InfoWrapper>
                                <Title>Czas podania</Title>
                                <Description>'Wybierz czas podania</Description>
                            </InfoWrapper>
                        </ContentWrapper>
                    }
                >
                    <Wrapper>
                        <TimeServeInfo>
                            Jeśli wybierasz opcję “Gotowe na konkretną godzinę”,
                            zamówienie będzie podane o godzinie, którą podałeś w
                            sekcji “Termin”.
                        </TimeServeInfo>
                        <ButtonSecondary
                            small
                            icon={<EventIcon />}
                            variant={serveOnTime ? 'green' : 'default'}
                            title="Gotowe na konkretną godzinę"
                            onClick={() => onChangeServeOnTime(true)}
                        />
                        <ButtonSecondary
                            small
                            icon={
                                <TableChairIcon
                                    color={
                                        serveOnTime
                                            ? colors.grayDark
                                            : colors.green
                                    }
                                />
                            }
                            variant={serveOnTime ? 'default' : 'green'}
                            title="Rozpoczęcie przygotowania po przybyciu na miejsce"
                            onClick={() => onChangeServeOnTime(false)}
                        />
                    </Wrapper>
                </Accordion>
            </Container>
        </Grid>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const Container = styled.div<{ padding?: string }>(
    ({ padding }) => css`
        padding: ${padding ? padding : 0};

        ${mediaQueriesMaterial.sm} {
            padding: ${padding ? '0 0 16px' : 0};
        }
    `,
);

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const TimeServeInfo = styled.p`
    color: ${colors.blueZodiac};
    font-size: 13px;
    padding-bottom: 8px;
`;
