import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { alpha, colors } from '@style/index';
import {
    ButtonBase,
    Collapse,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { css } from '@emotion/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import styled from '@emotion/styled';
import { mediaQueriesMaterial } from '@style/breakpoints';

export interface AccordionProps {
    borderRadius?: CSSProperties['borderRadius'];
    children: ReactNode;
    collapsedPadding?: CSSProperties['padding'];
    headerElement: ReactNode;
    headerPadding?: CSSProperties['padding'];
    iconPosition?: 'bottom';
    isOpen?: boolean;
    preventCollapse?: boolean;
    removeExpandIcon?: boolean;
    removeTopDivider?: boolean;
    setIsOpen?: (value: boolean) => void;
}

export const Accordion = ({
    borderRadius,
    children,
    collapsedPadding,
    headerElement,
    headerPadding,
    iconPosition,
    isOpen = false,
    preventCollapse = false,
    removeExpandIcon,
    removeTopDivider,
    setIsOpen,
}: AccordionProps) => {
    const [open, setOpen] = useState(isOpen);

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    const defaultHeaderPadding = headerPadding || (isMd ? '32px' : '16px');
    const defaultCollapsedPadding =
        collapsedPadding || (isMd ? '24px 32px' : '16px 24px');

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    return (
        <AccordionWrapper
            borderRadius={borderRadius}
            className="accordion_wrapper"
        >
            <AccordionButton
                disableRipple={preventCollapse}
                onClick={() => {
                    !preventCollapse && setOpen((prevState) => !prevState);
                    !preventCollapse && setIsOpen?.(!isOpen);
                }}
            >
                <AccordionInner
                    collapsible={!preventCollapse}
                    padding={defaultHeaderPadding}
                >
                    <AccordionHeader>
                        <AccordionHeaderElement>
                            {headerElement}
                        </AccordionHeaderElement>
                        {!removeExpandIcon && (
                            <ExpandMoreIcon
                                $isOpen={open}
                                position={iconPosition}
                            />
                        )}
                    </AccordionHeader>
                </AccordionInner>
            </AccordionButton>
            <Collapse in={open}>
                {!removeTopDivider && <CollapseDivider />}
                <AccordionInner padding={defaultCollapsedPadding}>
                    {children}
                </AccordionInner>
            </Collapse>
        </AccordionWrapper>
    );
};

const AccordionWrapper = styled.div<{
    borderRadius: CSSProperties['borderRadius'];
}>(
    ({ borderRadius }) => css`
        background: linear-gradient(
            90deg,
            ${colors.gray100} 0%,
            ${colors.gray200} 100%
        );
        border-radius: ${borderRadius ?? '16px'};
        overflow: hidden;
        width: 100%;
    `,
);

// HEADER
const AccordionButton = styled(ButtonBase)(
    ({ disableRipple }) => css`
        cursor: ${disableRipple ? 'default' : 'pointer'};
        display: block;
        width: 100%;
    `,
);

export const AccordionHeader = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: space-between;
`;

export const AccordionHeaderElement = styled.div`
    flex: 1;
    width: 100%;
`;

const ExpandMoreIcon = styled(ExpandMore, {
    shouldForwardProp: (prop) => prop !== '$isOpen',
})<{ $isOpen: boolean; position: AccordionProps['iconPosition'] }>(
    ({ $isOpen, position }) => css`
        transform: rotate(${$isOpen ? 180 : 0}deg);

        ${position === 'bottom' &&
        css`
            bottom: 32px;
            position: absolute;
            right: 32px;

            ${mediaQueriesMaterial.sm} {
                bottom: 16px;
                right: 16px;
            }
        `}
    `,
);

// HEADER & COLLAPSED CONTENT
const CollapseDivider = styled.div`
    background-color: ${colors.gray400};
    height: 1px;
    width: 100%;
`;

const AccordionInner = styled.div<{
    collapsible?: boolean;
    padding: CSSProperties['padding'];
}>(
    ({ collapsible, padding }) => css`
        padding: ${padding};
        position: relative;
        text-align: left;
        transition: background-color 0.3s ease-out;

        ${collapsible &&
        `
            cursor: pointer;

            &:hover {
                background-color: ${colors.gray300.concat(alpha[100])};
            }
        `}
    `,
);
