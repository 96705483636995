import styled from '@emotion/styled';
import { colors } from '@/style';
import { CSSProperties, ReactNode } from 'react';
import { css } from '@emotion/core';
import { createPortal } from 'react-dom';

export interface BasePopupProps {
    children: ReactNode;
    className?: string;
    containerPadding?: CSSProperties['padding'];
    containerMaxWidth?: CSSProperties['maxWidth'];
    contentMargin?: CSSProperties['margin'];
    footer?: ReactNode;
    header?: ReactNode;
    overlayBackground?: boolean;
}

/** @deprecated use BasePopupNew */
export const BasePopup = ({
    children,
    className,
    containerMaxWidth = '100%',
    containerPadding = '32px 24px',
    contentMargin = '24px 0',
    footer,
    header,
    overlayBackground = true,
}: BasePopupProps) => {
    return createPortal(
        <BasePopupWrapper overlayBackground={overlayBackground}>
            <BasePopupContainer
                className={className}
                maxWidth={containerMaxWidth}
                padding={containerPadding}
            >
                {header && <Header>{header}</Header>}
                <Content margin={contentMargin}>{children}</Content>
                {footer && <Footer>{footer}</Footer>}
            </BasePopupContainer>
        </BasePopupWrapper>,
        document.body,
    );
};

export const BasePopupWrapper = styled.div<{ overlayBackground: boolean }>(
    ({ overlayBackground }) => css`
        ${overlayBackground && `background-color: ${colors.text}`};
        align-content: flex-end;
        display: grid;
        min-height: 100vh;
        padding-top: 70px;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9998; //becouse toast has z-index: 9999
    `,
);

export const BasePopupContainer = styled.div<{
    maxWidth: CSSProperties['maxWidth'];
    padding: CSSProperties['padding'];
}>(
    ({ maxWidth, padding }) => css`
        background-color: ${colors.white};
        border-radius: 32px 32px 0 0;
        box-shadow: 0 -4px 24px 0 rgba(50, 50, 50, 0.08);
        display: grid;
        grid-template-rows: auto 1fr auto;
        max-width: ${maxWidth};
        padding: ${padding};
        width: 100%;
    `,
);

const Header = styled.div``;

const Content = styled.div<{ margin: CSSProperties['margin'] }>(
    ({ margin }) => css`
        font-size: 16px;
        margin: ${margin};
    `,
);

const Footer = styled.div``;
