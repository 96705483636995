import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { Button } from '@/componentsV2/molecules/Button/Button';
import {
    SplitPaymentUser,
    SplitPaymentUserProps,
} from '@/componentsV2/atoms/SplitPaymentUser/SplitPaymentUser';
import Add from '@material-ui/icons/Add';
import Check from '@material-ui/icons/Check';
import { IconButton } from '@material-ui/core';
import { OrderCounter } from '@/EZC/components/OrderCounter/OrderCounter';
import { toast } from 'react-toastify';

interface AssignOrderContentProps {
    assignedOrders: string[];
    itemId: string;
    onChange: (event: 'minus' | 'plus', userId: string) => void;
    onReset: () => void;
    onSave: () => void;
    quantity: number;
    userRequests: Record<string, string[]>;
    users?: SplitPaymentUserProps[];
}

export const AssignOrderContent = ({
    assignedOrders,
    onChange,
    onReset,
    onSave,
    users,
    quantity,
    userRequests,
    itemId,
}: AssignOrderContentProps) => {
    return (
        <>
            <SplitOrderTitle>Przydziel płatność</SplitOrderTitle>
            <SplitOrderDescription>
                {quantity > 1
                    ? 'Wybierz osoby, do których chcesz przydzielić płatność'
                    : 'Wybierz jedną osobę, do której chcesz przydzielić płatność'}
            </SplitOrderDescription>
            <SplitPaymentList>
                {users?.map((user) => {
                    const currentUserRequests = userRequests[user.id];
                    const userQuantity = currentUserRequests?.length ?? 0;

                    return (
                        <SplitPaymentItem key={user.id}>
                            <SplitPaymentUser {...user} />
                            {quantity > 1 ? (
                                <OrderCounter
                                    disabled={
                                        assignedOrders.length === quantity
                                    }
                                    max={quantity ?? 0}
                                    onChange={(_, event) =>
                                        onChange(event, user.id)
                                    }
                                    onDisabledClick={() => {
                                        toast.error(
                                            'Wszystkie porcje tego produktu zostały już wybrane',
                                        );
                                    }}
                                    value={userQuantity ?? 0}
                                />
                            ) : currentUserRequests.includes(itemId) ? (
                                <CheckButton
                                    aria-label="checked"
                                    onClick={() => onChange('minus', user.id)}
                                >
                                    <CheckIcon />
                                </CheckButton>
                            ) : (
                                <AddButton
                                    aria-label="add"
                                    onClick={() => onChange('plus', user.id)}
                                >
                                    <AddIcon />
                                </AddButton>
                            )}
                        </SplitPaymentItem>
                    );
                })}
            </SplitPaymentList>
            <ButtonsWrapper>
                <Button onClick={onReset} variant={'gray'}>
                    Odrzuć
                </Button>
                <Button
                    disabled={!assignedOrders.includes(itemId)}
                    onClick={onSave}
                    variant={'black'}
                >
                    Zapisz
                </Button>
            </ButtonsWrapper>
        </>
    );
};

const SplitOrderTitle = styled.h2`
    color: ${colors.text};
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 16px;
`;

const SplitOrderDescription = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
    line-height: 16px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const SplitPaymentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 0;
`;

const SplitPaymentItem = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const AddButton = styled(IconButton)`
    background: ${colors.littleDipper};
    height: 32px;
    width: 32px;
`;

const AddIcon = styled(Add)`
    color: ${colors.gray400};
    font-size: 16px;
`;

const CheckButton = styled(IconButton)`
    background: ${colors.blueRomance};
    height: 32px;
    width: 32px;

    &:hover {
        background: ${colors.blueRomance};
    }
`;

const CheckIcon = styled(Check)`
    color: ${colors.green};
    font-size: 16px;
`;
