import { OrderStatus } from '@/graphql/generated';

export const ORDER_STATUS_ENUM: Record<OrderStatus, OrderStatus> = {
    PENDING: 'PENDING',
    SENT_TO_RESTAURANT: 'SENT_TO_RESTAURANT',
    REJECTED: 'REJECTED',
    CANCELED: 'CANCELED',
    ACCEPTED: 'ACCEPTED',
    PREPARING: 'PREPARING',
    PREPARED: 'PREPARED',
    COMPLETED: 'COMPLETED',
    CLOSED: 'CLOSED',
    DELIVERY: 'DELIVERY',
    PARTIAL_ACCEPTANCE: 'PARTIAL_ACCEPTANCE',
    VERIFICATION: 'VERIFICATION',
};

export const ORDER_STATUS: Record<
    OrderStatus,
    {
        color: string;
        description: string;
        name: string;
    }
> & {
    COMPLETED: {
        delivery: { color: string; description: string; name: string };
    };
} = {
    CANCELED: {
        description:
            'Zamówienie anulowane. W przypadku opłaconych produktów, wszystkie środki zostają zwrócone na konto Klienta.',
        name: 'Anulowane',
        color: '#800000',
    },
    CLOSED: {
        description: 'Zamówienie zakończone.',
        name: 'Zakończone',
        color: '#343340',
    },
    PENDING: {
        description:
            'Zamówienie zostało przesłane do restauracji i czeka na akceptację. Obsługa restauracji może zaakceptować lub odrzucić zamówienie.',
        name: 'Oczekujące',
        color: '#B9BCC4',
    },
    PREPARING: {
        description: 'Zamówienie jest w trakcie realizacji.',
        name: 'Realizowane',
        color: '#FF8000',
    },
    PREPARED: {
        name: 'Przygotowane',
        color: '#0D9488',
        description:
            'Zamówienie zostało przygotowane i wkrótce zostanie dostarczone.',
    },
    COMPLETED: {
        description: 'Zamówienie zostało wydane.',
        name: 'Zrealizowane',
        color: '#00B30F',
        delivery: {
            name: 'Dostarczone',
            color: '#00B30F',
            description: 'Zamówienie zostało dostarczone.',
        },
    },
    REJECTED: {
        description:
            'Zamówienie odrzucone. W przypadku opłaconych produktów, wszystkie środki zostają zwrócone na konto Klienta.',
        name: 'Odrzucone',
        color: '#800000',
    },
    SENT_TO_RESTAURANT: {
        description:
            'Zamówienie zostało przesłane do restauracji i czeka na akceptację. Obsługa restauracji może zaakceptować lub odrzucić zamówienie.',
        name: 'Oczekujące',
        color: '#B9BCC4',
    },
    DELIVERY: {
        description:
            'Twoje zamówienie jest gotowe i zostało odebrane przez kuriera.',
        name: 'W dostawie',
        color: '#2563EB',
    },
    ACCEPTED: {
        description:
            'Zamówienie i jego produkty zostały w pełni zaakceptowane.',
        name: 'Zaakceptowane',
        color: '#FFD500',
    },
    PARTIAL_ACCEPTANCE: {
        description:
            'Zamówienie częściowo zaakceptowane, czyli niektóre z zamówionych produktów zostały odrzucone.',
        name: 'Oczekujące',
        color: '#B9BCC4',
    },
    VERIFICATION: {
        description:
            'Zamówienie zostało przesłane do restauracji i czeka na akceptację. Obsługa restauracji może zaakceptować lub odrzucić zamówienie.',
        name: 'Oczekujące',
        color: '#B9BCC4',
    },
};

export const ORDER_STATUS_NAME: Partial<Record<OrderStatus, string>> =
    Object.entries(ORDER_STATUS).reduce(
        (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
        {},
    );

export const ORDER_STATUS_NAME_VALUES = Object.entries(ORDER_STATUS_NAME).map(
    ([id, name]) => ({ id, name }),
) as {
    id: OrderStatus;
    name: string;
}[];
