import { useState } from 'react';
import { toast } from 'react-toastify';
import MoneyEz from '@helpers/MoneyEz';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useChargeRestaurantMutation, OPERATIONS } from '@graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export const useChargeRestaurant = () => {
    const { control, handleSubmit, reset } =
        useForm<ChargeRestaurantSchemaValues>({
            resolver: yupResolver(ChargeRestaurantSchema),
            defaultValues: {
                amount: 0,
            },
        });

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState<string>('');

    const [chargeRestaurant] = useChargeRestaurantMutation();

    const handleRestaurantChoose = (restaurantId: string) => {
        setSelectedRestaurant(restaurantId);
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        reset();
        setIsPopupOpen(false);
    };

    const onChargeRestaurant = handleSubmit(
        (values) => {
            chargeRestaurant({
                variables: {
                    input: {
                        restaurantId: selectedRestaurant,
                        coins: values.amount,
                    },
                },
                onCompleted: () => {
                    const money = MoneyEz(parseFloat(`${values.amount}`));
                    toast.success(
                        `Konto restauracji zostało obciążone na ${money.getAmountWithFullPrecisionAndZl()}
            `,
                    );
                },
                onError: graphqlErrorHandler(true),
                refetchQueries: [OPERATIONS.Query.FindRestaurant],
            });

            handlePopupClose();
        },
        (error) => {
            toast.error(error.amount?.message);
        },
    );

    return {
        handleRestaurantChoose,
        isPopupOpen,
        handlePopupClose,
        onChargeRestaurant,
        control,
    };
};

const ChargeRestaurantSchema = yup.object({
    amount: yup
        .number()
        .positive('Kwota musi być dodatnia')
        .required('Kwota musi być podana')
        .typeError('Kwota musi być podana'),
});

export interface ChargeRestaurantSchemaValues
    extends yup.InferType<typeof ChargeRestaurantSchema> {}
