/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { ButtonBase } from '@material-ui/core';
import { colors, s } from '../../../style';

const AddRemoveNew = ({ amount, onAdd, onDelete }) => {
    return (
        <div
            className="prod-item-button"
            css={[
                {
                    position: 'relative',
                    transition:
                        'background-color 0.3s ease-out, width 0.3s ease-out',
                    willChange: 'width',
                    height: 32,
                    width: 32,
                    // width: 'max-content',
                    whiteSpace: 'nowrap',
                    fontWeight: 700,
                    backgroundColor: colors.gray300,
                    borderRadius: 16,
                    color: colors.text,
                    overflow: 'hidden',
                    fontSize: '15px',
                    [s.md]: {
                        height: 40,
                        width: 40,
                        borderRadius: 20,
                    },
                    '&:hover': {
                        [s.hover]: {
                            backgroundColor: colors.gray400,
                            color: colors.text,
                        },
                    },
                },
                !!amount && {
                    width: 96, // was 72 same as picture
                    [s.md]: { width: 96 },
                    // backgroundColor: colors.green300,
                    // color: colors.text,
                },
            ]}
        >
            <ButtonBase
                onClick={onAdd}
                css={[
                    sCartButtonNoItemButton,
                    !!amount > 0 && { opacity: 0 },
                    {
                        fontWeight: 700,
                        fontSize: '13px',
                        color: 'currentColor',
                        letterSpacing: '0em',
                    },
                ]}
            >
                <AddIcon />
            </ButtonBase>
            {!!amount && (
                <ButtonBase
                    css={[
                        sCartButtonAddRemoveBase,
                        {
                            borderRadius: 16,
                            [s.md]: { borderRadius: 20 },
                            // backgroundColor: colors.green,
                            color: colors.text,
                        },
                    ]}
                >
                    <div css={[sTopNavAddRemove]} onClick={onDelete}>
                        <RemoveIcon />
                    </div>
                    {amount}
                    <div css={[sTopNavAddRemove]} onClick={onAdd}>
                        <AddIcon />
                    </div>
                </ButtonBase>
            )}
        </div>
    );
};

const sCartButtonAddRemoveBase = {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: '15px',
    fontWeight: 700,
    letterSpacing: '0.05em',
};

const sCartButtonNoItemButton = {
    willChange: 'opacity',
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    jsutifyContent: 'center',
    alignItems: 'center',
    transition:
        'color 0.3s ease-out, background-color 0.3s ease-out, opacity 0.3s ease-out',
    textAlign: 'center',
    borderRadius: 24,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    color: '#ffffff',
    fontSize: '16px',
    fontWeight: 700,
    letterSpacing: '0.05em',
};

const sTopNavAddRemove = {
    height: 32,
    width: 32,
    borderRadius: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [s.md]: {
        height: 40,
        width: 40,
        borderRadius: 20,
    },
};

export default AddRemoveNew;
