import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import endWordHelper from '@helpers/endWord.helper';
import Carousel from '@/EZC/components/Carousel/Carousel.component';
import { RestItemLarge } from '@/EZC/components/RestItem';
import CarouselViewIcon from '@material-ui/icons/ViewModule';
import ListViewIcon from '@material-ui/icons/ViewStream';
import { ProdItemMiniV2 } from '@/EZC/components/ProdItem/ProdItemMiniV2.component';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { colors } from '@style/index';
import {
    ProductListItem,
    ProductType,
} from '@/componentsV2/molecules/ProductListItem/ProductListItem';
import { Restaurant } from '@graphql/generated';
import { ButtonIcon } from '@/componentsV2/atoms/ButtonIcon/ButtonIcon';
import { TabValueType } from '@/EZC/domains/Search/molecules/SearchTabs/SearchTabs';
import { mediaQueries, mediaQueriesMaterial } from '@style/breakpoints';
import { Spinner } from '@/componentsV2/molecules/Spinner/Spinner';
import useInfiniteScroll from 'react-infinite-scroll-hook';

type RestaurantType = Pick<
    Restaurant,
    | 'address'
    | 'avatarUrl'
    | 'description'
    | 'name'
    | 'photoUrl'
    | 'isActive'
    | 'distance'
    | 'isBrochure'
    | 'slug'
>;

interface RenderItemsProps<T> {
    data?: T[];
    disabled?: boolean;
    hasNextPage: boolean;
    listView: boolean;
    loading: boolean;
    onLoadMore: () => void;
}

interface SearchResultsProps {
    activeTabs: TabValueType;
    isDesktop: boolean;
    products: Omit<RenderItemsProps<ProductType>, 'listView'>;
    restaurants: Omit<RenderItemsProps<RestaurantType>, 'listView'>;
}

export const SearchResults = ({
    activeTabs,
    isDesktop,
    products,
    restaurants,
}: SearchResultsProps) => {
    const [listView, setListView] = useState(true);

    useEffect(() => {
        setListView(true);
    }, [activeTabs]);

    return (
        <>
            <ResultTextWrapper>
                <ResultText>
                    <span>
                        {endWordHelper(
                            'wynik',
                            activeTabs === '1'
                                ? products.data?.length
                                : restaurants.data?.length,
                        )}
                    </span>
                    {activeTabs === '1' ? ' w produktach' : ' w restauracjach'}
                </ResultText>
                {((products.data?.length && activeTabs === '1') ||
                    (restaurants.data?.length && activeTabs === '2') ||
                    (activeTabs === '2' && isDesktop)) && (
                    <ListSwitchButton
                        icon={
                            listView ? (
                                <ListViewIcon fontSize="inherit" />
                            ) : (
                                <CarouselViewIcon fontSize="inherit" />
                            )
                        }
                        onClick={() => {
                            setListView((prevState) => !prevState);
                        }}
                    />
                )}
            </ResultTextWrapper>
            <>
                {activeTabs === '1' && (
                    <>
                        <RenderProducts listView={listView} {...products} />
                    </>
                )}
                {activeTabs === '2' && (
                    <>
                        <RenderRestaurants
                            listView={listView}
                            {...restaurants}
                        />
                    </>
                )}
            </>
        </>
    );
};

const RenderProducts = ({
    data,
    disabled,
    onLoadMore,
    listView,
    loading,
    hasNextPage,
}: RenderItemsProps<ProductType>) => {
    useEffect(() => {
        AnalyticsHelpers.createCustomEvent({
            name: 'view_item_list',
            params: { type: 'product' },
        });
    }, []);

    const [sentryRef] = useInfiniteScroll({
        disabled,
        hasNextPage,
        loading,
        onLoadMore,
        rootMargin: '0px 0px 50px 0px',
    });

    return (
        <div>
            {!listView && !!data?.length ? (
                //  @ts-expect-error migrate to ts
                <Carousel
                    data={data}
                    CarouselItem={ProdItemMiniV2}
                    link="/product/"
                    carouselItemProps={{
                        showBadge: false,
                        removeMargin: true,
                    }}
                    getMoreData={onLoadMore}
                    hasMore={hasNextPage}
                />
            ) : (
                <>
                    <ItemsWrapper>
                        {data?.map((product, id) => {
                            const distance = product?.distance ?? 0;
                            return (
                                <ProductListItem
                                    key={id}
                                    id={product?.id}
                                    accuracy={product?.accuracy}
                                    distance={parseFloat(
                                        (distance / 1000).toFixed(1),
                                    )}
                                    name={product?.name}
                                    photoUrl={product?.photoUrl}
                                    price={product?.price}
                                    rating={product?.rating ?? 0}
                                    ratingsCount={product?.ratingsCount ?? 0}
                                    isBrochure={product?.isBrochure}
                                />
                            );
                        })}
                    </ItemsWrapper>
                    {loading && (
                        <LoadingWrapper>
                            <Spinner />
                        </LoadingWrapper>
                    )}
                    <div ref={sentryRef} />
                </>
            )}
        </div>
    );
};

const RenderRestaurants = ({
    data,
    disabled,
    onLoadMore,
    listView,
    loading,
    hasNextPage,
}: RenderItemsProps<RestaurantType>) => {
    const [sentryRef] = useInfiniteScroll({
        disabled,
        hasNextPage,
        loading,
        onLoadMore,
        rootMargin: '0px 0px 50px 0px',
    });

    return (
        <ItemsRestaurantWrapper>
            {listView ? (
                <>
                    {data?.map((restaurant, id) => {
                        return (
                            // @ts-expect-error migrate to ts
                            <RestItemLarge
                                key={id}
                                id={id + 1}
                                data={restaurant}
                                searchView
                            />
                        );
                    })}
                    {loading && (
                        <LoadingWrapper>
                            <Spinner />
                        </LoadingWrapper>
                    )}
                    <div ref={sentryRef} />
                </>
            ) : (
                // @ts-expect-error migrate to ts
                <Carousel
                    data={data}
                    CarouselItem={RestItemLarge}
                    link="/product/"
                    carouselItemProps={{
                        removeMargin: true,
                    }}
                    getMoreData={onLoadMore}
                    hasMore={hasNextPage}
                />
            )}
        </ItemsRestaurantWrapper>
    );
};

const ResultTextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 24px;
`;

const ItemsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    gap: 20px;

    ${mediaQueriesMaterial.md} {
        display: grid;
        gap: 16px;
        grid-template-columns: repeat(3, 1fr);
    }

    ${mediaQueries.sm} {
        gap: 7px;
        grid-template-columns: 1fr 1fr;
    }
`;

const ItemsRestaurantWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ResultText = styled.p`
    color: ${colors.dust};
    font-size: 14px;
    line-height: 21px;
`;

const ListSwitchButton = styled(ButtonIcon)`
    background: ${colors.gray200};
    padding: 8px;
    border-radius: 50%;
`;

const LoadingWrapper = styled.div`
    padding-top: 40px;
`;
