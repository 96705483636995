/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import { useOrderInCartByRestaurant } from '@/EZC/context/OrderInCartByRestaurant/OrderInCartByRestaurant.provider';
import { ProdItemMenuList } from '@/EZC/components/ProdItem';
import { Grid } from '@material-ui/core';
import { s, globals } from '@style';

import { Element as ElementScroll } from 'react-scroll';
import styled from '@emotion/styled';

const RestaurantMenuSections = ({
    menuSections,
    measuredRef,
    restaurant,
    isBrochure,
    menuDate,
}) => {
    const {
        order,
        restaurantsOrderInCartId,
        handleAddProd,
        handleDeleteProduct,
    } = useOrderInCartByRestaurant();

    if (!menuSections.length) return null;

    return (
        <div
            ref={measuredRef}
            css={[
                sOuter,
                { paddingBottom: 40 },
                isBrochure && { paddingTop: 10, paddingBottom: 24 },
            ]}
        >
            {isBrochure && menuDate}
            {menuSections.map((section, sectionId) => (
                <ElementScroll
                    name={encodeURIComponent(section.name)}
                    key={sectionId}
                >
                    <SectionWrapper
                        key={sectionId}
                        smallPadding={sectionId === 0 && isBrochure}
                    >
                        <p
                            css={{
                                fontSize: '22px',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                whiteSpace: 'nowrap',
                                letterSpacing: '-0.05em',
                                marginBottom: '1.5rem',
                                whiteSpace: 'initial',
                                [s.xs]: { fontSize: '22px' },
                                [s.md]: { paddingLeft: '2rem' },
                            }}
                        >
                            {section.name}
                        </p>
                        <Grid container>
                            {section.products.map((product, productId) => {
                                const onDeleteProduct = () =>
                                    handleDeleteProduct({
                                        orderId: restaurantsOrderInCartId,
                                        prodId: product.id,
                                    });

                                const onAddProduct = (e) =>
                                    handleAddProd(
                                        e,
                                        product.id,
                                        {
                                            id: product.id,
                                            price: product.price,
                                            name: product.name,
                                            photoUrl: product.photoUrl,
                                        },
                                        {
                                            id: restaurant.id,
                                            name: restaurant.name,
                                            slug: restaurant.slug,
                                            avatarUrl: restaurant.avatarUrl,
                                            address: restaurant.address,
                                        },
                                    );

                                return (
                                    <ProdItemMenuList
                                        id={productId}
                                        key={productId}
                                        data={product}
                                        handleAddProduct={
                                            isBrochure ? null : onAddProduct
                                        }
                                        handleDeleteProduct={
                                            isBrochure ? null : onDeleteProduct
                                        }
                                        productInOrder={order?.lines?.find(
                                            (line) =>
                                                line.product.id === product.id,
                                        )}
                                    />
                                );
                            })}
                        </Grid>
                    </SectionWrapper>
                </ElementScroll>
            ))}
        </div>
    );
};

export const sOuter = {
    width: '100vw',
    maxWidth: globals.maxWidthMedium,
    margin: '0 auto',
    padding: '0 1.5rem',
    paddingTop: '1.5rem',
    [s.md]: {
        paddingTop: '4rem',
    },
};

const SectionWrapper = styled.div(
    ({ smallPadding }) => css`
        padding-top: 48px;
        padding-top: ${smallPadding ? '24px' : '48px'};

        ${s.md} {
            padding-top: 64px;
        }
    `,
);

export default RestaurantMenuSections;
