import { useEffect } from 'react';
import { App, AppState } from '@capacitor/app';

export const useHandleBackFromBackground = (callback: () => void) => {
    useEffect(() => {
        const handleAppStateChange = async (state: AppState) => {
            if (state.isActive) {
                callback();
            }
        };
        App.addListener('appStateChange', handleAppStateChange);

        return () => {
            App.removeAllListeners();
        };
    }, []);
};
