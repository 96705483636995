import React from 'react'; // eslint-disable-line no-unused-vars

import {
    ChatContainer,
    InputArea,
    Messages,
    MessagesContainer,
} from './components';
import eatzonIcon from '../../../style/assets/eatzonIcon';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { FIREBASE_ENV } from '@/services/FirebaseChatService/FirebaseChatService.config';
import {
    useChatMarkAsReadMessage,
    useChatSendMessage,
} from '@/services/FirebaseChatService/chats/common';
import { useAdminChatForUser } from '@/services/FirebaseChatService/chats/admin';
import PageLoader from '@components/PageLoader/PageLoader';
import { useFirebaseChatService } from '@/services/FirebaseChatService/FirebaseChatService';
import { useChat } from '@hooks/useChat';

const AdminChatClient = () => {
    const userId = useFirebaseChatService((state) => state.userId);

    const { messages, loading } = useAdminChatForUser();
    const { message, sendMessage, setMessage } = useChatSendMessage(
        `env/${FIREBASE_ENV}/adminChats/${userId}`,
    );
    const { measuredRef, scrollToBottom } = useChat(messages?.length);

    useChatMarkAsReadMessage(
        `env/${FIREBASE_ENV}/adminChats/${userId}`,
        messages,
    );

    const handleSend = () => {
        sendMessage();
        scrollToBottom();
    };

    if (loading) return <PageLoader />;

    return (
        <ChatContainer>
            <ChatWindow>
                <TopNav
                    isScrolledAlready
                    icon={eatzonIcon}
                    title={'Eatzon'}
                    subtitle={` -  Czat`}
                />
                <MessagesContainer measuredRef={measuredRef}>
                    <Messages userId={userId} messages={messages} />
                </MessagesContainer>

                <InputArea
                    newMssg={message}
                    setNewMssg={setMessage}
                    onSend={handleSend}
                />
            </ChatWindow>
        </ChatContainer>
    );
};

const ChatWindow = ({ children }) => (
    <div
        css={{
            flexGrow: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}
    >
        {children}
    </div>
);

export default AdminChatClient;
