import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style/index';
import { ButtonBase } from '@material-ui/core';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import PhoneIcon from '@material-ui/icons/Phone';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { addSpacesToPhoneNumber } from '@helpers/addSpacesToPhoneNumber';
import { MOBILE } from '@constants/common.constants';
import { Keyboard } from '@capacitor/keyboard';

export interface PhoneNumberProps {
    onChangeNumber: (value?: string) => void;
    phoneNumber?: string;
    required: boolean;
    setPhoneError: (value?: string) => void;
}

export const PhoneNumber = ({
    onChangeNumber,
    phoneNumber,
    required = false,
    setPhoneError,
}: PhoneNumberProps) => {
    const methods = useForm({
        resolver: yupResolver(PhoneNumberSchema(required)),
    });

    const {
        field: { onChange, value },
    } = useController({
        name: 'phone',
        control: methods.control,
        defaultValue: phoneNumber,
    });

    useEffect(() => {
        if (methods.formState.errors.phone) {
            setPhoneError(methods.formState.errors.phone.type);
        } else {
            setPhoneError(undefined);
        }
    }, [methods.formState.errors.phone]);

    return (
        <Accordion
            collapsedPadding="24px 32px"
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <PhoneIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>Numer telefonu</Title>
                        <Description>
                            {phoneNumber
                                ? addSpacesToPhoneNumber(phoneNumber)
                                : 'Wprowadź swój numer telefonu'}
                        </Description>
                    </InfoWrapper>
                </ContentWrapper>
            }
        >
            <Wrapper>
                <Text>
                    Podaj swój numer telefonu, aby Restauracja mogła
                    skontaktować się z Tobą w przypadku problemów z zamówieniem
                    lub dostawą.
                </Text>
                <PhoneWrapper>
                    <PhoneInput
                        onFocus={() => {
                            const currentValue = value;
                            if (!currentValue) {
                                onChange('+48');
                            }
                        }}
                        placeholder="Numer telefonu"
                        onChange={(e) => {
                            onChange(e.target.value);
                            onChangeNumber(e.target.value);
                        }}
                        onBlur={async () => {
                            const currentValue = value;
                            if (currentValue && currentValue.length < 4) {
                                onChange('');
                                onChangeNumber('');
                            }
                            await methods.trigger('phone');
                        }}
                        value={value}
                        required={required}
                    />
                </PhoneWrapper>
                {methods.formState.errors.phone && (
                    <ErrorText>
                        <ErrorIcon />
                        {methods.formState.errors.phone?.message}
                    </ErrorText>
                )}
            </Wrapper>
        </Accordion>
    );
};

const Wrapper = styled.div`
    color: ${colors.gray600};
    display: grid;
    font-size: 13px;
    grid-gap: 16px;
    padding: 0;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const Text = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
`;

const PhoneWrapper = styled(ButtonBase)`
    width: 100%;
    background-color: ${colors.gray250};
    border-radius: 25px;
    margin-top: 24px;
`;

const ErrorIcon = styled(ErrorOutlineIcon)`
    font-size: 16px;
`;

const PhoneInput = styled.input`
    width: 100%;
    padding: 16px 24px;
    border: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: bold;
    font-family: Poppins;

    &:-internal-autofill-selected {
        border-radius: 25px;
    }
`;

const ErrorText = styled.p`
    color: ${colors.red};
    display: flex;
    font-size: 12px;
    gap: 8px;
    margin-top: 8px;
`;

export const PhoneNumberSchema = (isRequired: boolean) =>
    yup.object({
        phone: isRequired
            ? yup
                  .string()
                  .trim()
                  .required('To pole nie może być puste.')
                  .matches(
                      /^\+48\d{9}$/,
                      'Niepoprawny format numeru telefonu. Wprowadź numer telefonu w formacie 9 cyfr.',
                  )
            : yup
                  .string()
                  .trim()
                  .test(
                      'isValidPhone',
                      'Niepoprawny format numeru telefonu. Wprowadź numer telefonu w formacie 9 cyfr.',
                      (value) => {
                          if (!value) return true;
                          if (typeof value === 'string') {
                              return /^\+48\d{9}$/.test(value);
                          }
                          return false;
                      },
                  ),
    });
