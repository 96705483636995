import { forwardRef } from 'react';
import { Grid } from '@material-ui/core';
import { Collapsable } from '@components/Elements';

const ImagePicker = forwardRef(
    (
        { title, desc, image, imageUrl, left, right, name, onChange, ...props },
        ref,
    ) => {
        return (
            <Grid item xs={12} md={6} css={{ position: 'relative' }}>
                <Collapsable
                    left={left}
                    right={right}
                    noPadding
                    oneLiner
                    title={title}
                    image={image || imageUrl}
                    description={desc}
                ></Collapsable>
                <input
                    {...props}
                    ref={ref}
                    accept="image/*"
                    css={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        appearance: 'none',
                        opacity: 0,
                    }}
                    id={'contained-button-'.concat(title)}
                    type="file"
                    name={name}
                    onChange={onChange}
                />
            </Grid>
        );
    },
);

export default ImagePicker;
