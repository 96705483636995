import React from 'react';
import styled from '@emotion/styled';
import { ChipsPreferencesBlock } from '@/EZC/domains/Account/organisms/ChipsPreferencesBlock/ChipsPreferencesBlock';
import { css } from '@emotion/core';
import { ButtonBase } from '@material-ui/core';
import { colors } from '@style/index';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { useScrollCondition } from '@hooks/useScrollCondition';
import { usePreferencesPopup } from './usePreferencesPopup';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { usePreferencesPopupData } from './usePreferencesPopupData';
import { useScrollBottom } from '@hooks/useScrollBottom';
import { DIET_NAME_VALUES } from '@constants/fromGraphql/Diet.constants';
import { CUISINE_NAME_VALUES } from '@constants/fromGraphql/Cuisine.constants';
import { DISH_NAME_VALUES } from '@constants/fromGraphql/Dish.constants';
import { FLAVOUR_NAME_VALUES } from '@constants/fromGraphql/Flavor.constants';
import { RangePreferencesBlock } from '@/EZC/domains/Account/organisms/RangePreferencesBlock/RangePreferencesBlock';
import { SearchablePreferencesBlock } from '@/EZC/domains/Account/organisms/SearchablePreferencesBlock/SearchablePreferencesBlock';
import NiceModal from '@ebay/nice-modal-react';

export const PreferencesPopup = NiceModal.create(() => {
    const isScrollAtBottom = useScrollBottom();
    const sticky = useScrollCondition(90);

    const {
        allergies,
        control,
        handleFormSubmit,
        igredientPopularList,
        igredientSuggestedList,
        ingredients,
        isDirty,
        loading,
        reset,
        updateLoading,
        popup,
    } = usePreferencesPopup();

    const {
        allergiesField,
        cuisineField,
        dietField,
        dishField,
        flavorField,
        ingredientsField,
        spicinessController,
    } = usePreferencesPopupData(control);

    return (
        <BasePopup
            contentMargin={'40px 0 74px'}
            containerPadding={isDirty ? '32px 24px 96px' : undefined}
            header={
                <>
                    <ModalHeader>
                        <ModalTitle>Preferencje żywieniowe</ModalTitle>
                        <div onClick={() => popup.remove()}>
                            <ButtonBaseElement>Pomiń</ButtonBaseElement>
                        </div>
                    </ModalHeader>
                    <DescriptionWrapper>
                        <Description>
                            Zaznacz kategorie i składniki, które lubisz.
                        </Description>
                        <Description>
                            Kliknij dwukrotnie, aby zaznaczyć niechciane
                            elementy.
                        </Description>
                    </DescriptionWrapper>
                    {sticky && (
                        <ModalHeaderSticky>
                            <ModalTitle $small>
                                Preferencje żywieniowe
                            </ModalTitle>
                            <div onClick={() => popup.remove()}>
                                <ButtonBaseElement $small>
                                    Pomiń
                                </ButtonBaseElement>
                            </div>
                        </ModalHeaderSticky>
                    )}
                </>
            }
            footer={
                <>
                    <Button
                        disabled={updateLoading}
                        onClick={() => reset()}
                        variant={'gray'}
                        width={'100%'}
                    >
                        RESETUJ
                    </Button>
                    {isDirty && (
                        <WrapperStickyButton
                            isScrollAtBottom={isScrollAtBottom}
                        >
                            <Button
                                loading={updateLoading}
                                onClick={handleFormSubmit}
                                variant={'black'}
                                width={'100%'}
                            >
                                ZAPISZ PREFERENCJE
                            </Button>
                        </WrapperStickyButton>
                    )}
                </>
            }
        >
            <ContentWrapper>
                <ChipsPreferencesBlock
                    title={'Preferowana dieta'}
                    chipsProps={{
                        loading: loading,
                        onChange: dietField.onChange,
                        options: DIET_NAME_VALUES,
                        preffered: dietField.value.preffered,
                    }}
                />
                <ChipsPreferencesBlock
                    title={'Kuchnia'}
                    chipsProps={{
                        loading: loading,
                        notPreffered: cuisineField.value.notPreffered,
                        onChange: cuisineField.onChange,
                        options: CUISINE_NAME_VALUES,
                        preffered: cuisineField.value.preffered,
                    }}
                />
                <ChipsPreferencesBlock
                    title={'Danie'}
                    chipsProps={{
                        loading: loading,
                        notPreffered: dishField.value.notPreffered,
                        onChange: dishField.onChange,
                        options: DISH_NAME_VALUES,
                        preffered: dishField.value.preffered,
                    }}
                />
                <ChipsPreferencesBlock
                    title={'Smak'}
                    chipsProps={{
                        loading: loading,
                        notPreffered: flavorField.value.notPreffered,
                        onChange: flavorField.onChange,
                        options: FLAVOUR_NAME_VALUES,
                        preffered: flavorField.value.preffered,
                    }}
                />
                <SearchablePreferencesBlock
                    description="Wpisz nazwę lub fragment nazwy składnika, aby rozpocząć wyszukiwanie."
                    onSearch={ingredients.setIngredientsInput}
                    placeholder={'Wyszukaj składnik'}
                    prefferedText="POPULARNE"
                    title={'Składniki'}
                    value={ingredients.ingredientsInput}
                    chipsProps={{
                        loading: loading || ingredients.loading,
                        onChange: ingredientsField.onChange,
                        options:
                            (!ingredients.ingredientsInput
                                ? igredientPopularList.data?.list
                                : ingredients.data?.list.data) || [],
                        preffered: ingredientsField.value.preffered,
                        notPreffered: ingredientsField.value.notPreffered,
                    }}
                />
                <SearchablePreferencesBlock
                    description="Zaznacz składniki, na które masz uczulenie."
                    icon={<NotInterestedIcon color="error" />}
                    onSearch={allergies.setAllergiesInput}
                    placeholder={'Wyszukaj składnik'}
                    prefferedText="SUGEROWANE"
                    title={'Alergie'}
                    value={allergies.allergiesInput}
                    chipsProps={{
                        loading: loading || allergies.loading,
                        onChange: allergiesField.onChange,
                        options:
                            (!allergies.allergiesInput
                                ? igredientSuggestedList.data?.list
                                : allergies.data?.list.data) || [],
                        notPreffered: allergiesField.value.notPreffered,
                    }}
                />
                <RangePreferencesBlock
                    description="Wybierz stopień ostrości Twoich dań."
                    isDirty={spicinessController.fieldState.isDirty}
                    loading={loading}
                    onChange={spicinessController.field.onChange}
                    title={'Skala ostrości'}
                    value={spicinessController.field.value}
                />
            </ContentWrapper>
        </BasePopup>
    );
});

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 56px;
`;

const ButtonBaseElement = styled(ButtonBase)<{ $small?: boolean }>(
    ({ $small }) => css`
        border-radius: 12px;
        box-shadow: inset 0px 0px 0px 3.5px ${colors.gray400};
        color: ${colors.gray600};
        font-size: 13px;
        font-weight: 700;
        height: 30px;
        padding: 9px 12px 8px;
        transition: all 0.3s ease-out;

        &:hover {
            background-color: ${colors.gray300};
            box-shadow: inset 0px 0px 0px 3.5px ${colors.gray300};
            color: ${colors.text};
        }

        ${$small &&
        css`
            box-shadow: unset;
            color: ${colors.text};
            font-size: 11px;
            font-weight: 400;
            height: auto;
            padding: 0;
            text-decoration: underline;

            &:hover {
                background-color: transparent;
                box-shadow: unset;
                color: ${colors.gray600};
            }
        `}
    `,
);

const ModalTitle = styled.h1<{ $small?: boolean }>(
    ({ $small }) => css`
        color: ${colors.text};
        font-size: 28px;
        line-height: 35px;
        transition: all 0.3s ease-out;

        ${$small &&
        css`
            font-size: 14px;
            line-height: 16px;
        `}
    `,
);

const ModalHeader = styled.header`
    align-items: center;
    display: flex;
    justify-content: space-between;
`;

const ModalHeaderSticky = styled.div`
    align-items: center;
    animation: opacity 0.5s ease-out;
    background-color: ${colors.white};
    box-shadow: 0px 4px 14px 0px rgba(50, 50, 50, 0.08);
    display: flex;
    height: 66px;
    justify-content: space-between;
    left: 0;
    padding: 24px 25px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    @keyframes opacity {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 24px;
`;

export const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
`;

const WrapperStickyButton = styled.div<{ isScrollAtBottom?: boolean }>(
    ({ isScrollAtBottom }) => css`
        background-color: ${isScrollAtBottom ? 'transparent' : colors.white};
        bottom: 0;
        left: 0;
        padding: 16px 24px 34px;
        position: fixed;
        width: 100%;
        z-index: 9;
        ${!isScrollAtBottom &&
        'box-shadow: 0px -4px 14px 0px rgba(50, 50, 50, 0.08);'}
    `,
);
