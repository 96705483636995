import React from 'react';
import {
    FindRestaurant,
    Button,
    SearchResultsRestaurants,
} from '@/EZAdmin/components';
import { useFindRestaurant } from '@/EZAdmin/hooks/useFindRestaurant';
import styled from '@emotion/styled';
import {
    OPERATIONS,
    useRestaurantChangeIsBrochureMutation,
    useRestaurantsBrochureImportMutation,
} from '@graphql/generated';
import { Switch } from '@/componentsV2/atoms/Switch/Switch';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

interface ItemHelperComponentProps {
    restaurantId: string;
}

export const ChangeRestaurantBrochure = () => {
    const [change] = useRestaurantChangeIsBrochureMutation();
    const [googleSheetImport, { loading: importRestaurantsLoading }] =
        useRestaurantsBrochureImportMutation({
            onError: graphqlErrorHandler(true),
        });

    const importRestaurants = async () => {
        try {
            const { data } = await googleSheetImport();

            const menus = data?.googleSheetImport?.menus;
            const products = data?.googleSheetImport?.products;
            const restaurants = data?.googleSheetImport?.restaurants;

            if (
                products?.errors.length ||
                restaurants?.errors.length ||
                menus?.errors.length
            ) {
                toast.error(
                    'Wystąpiły błędy podczas importowania. Sprawdź konsolę, aby uzyskać szczegóły.',
                );

                if (products?.errors.length) {
                    console.error(
                        'Błędy w importowaniu produktów:',
                        products.errors.join('\n'),
                    );
                }

                if (restaurants?.errors.length) {
                    console.error(
                        'Błędy w importowaniu restauracji:',
                        restaurants.errors.join('\n'),
                    );
                }

                if (menus?.errors.length) {
                    console.error(
                        'Błędy w importowaniu menu:',
                        menus.errors.join('\n'),
                    );
                }
            } else {
                toast.success('Dane zaimportowane poprawnie');
            }
        } catch (error) {
            console.error('Wystąpiły błędy podczas importu:', error);
            toast.error('Nie udało się zaimportować restauracji');
        }
    };

    const {
        getRestaurants,
        data,
        loading,
        hasMore,
        findRestaurant,
        setSearch,
    } = useFindRestaurant(false);

    const ItemHelperComponent = ({
        restaurantId,
    }: ItemHelperComponentProps) => {
        const isBrochure = data?.restaurantList.data?.find(
            (restaurant) => restaurant?.id === restaurantId,
        )?.isBrochure;

        return (
            <div>
                <Switch
                    onChange={() => {
                        change({
                            variables: {
                                input: {
                                    restaurantId,
                                    isBrochure: !isBrochure,
                                },
                            },
                            refetchQueries: [OPERATIONS.Query.FindRestaurant],
                            onError: graphqlErrorHandler(true),
                        });
                    }}
                    checked={isBrochure}
                />{' '}
                Ustaw jako wizytówkę
            </div>
        );
    };

    return (
        <React.Fragment>
            <ButtonImport
                onClick={() => importRestaurants()}
                disabled={importRestaurantsLoading}
            >
                {importRestaurantsLoading ? (
                    <CircularProgress color="inherit" thickness={5} size={20} />
                ) : (
                    'Importuj restauracje'
                )}
            </ButtonImport>
            <FindRestaurant
                getRestaurant={getRestaurants}
                loading={loading}
                findRestaurant={findRestaurant}
                setSearch={setSearch}
                isActive={false}
            />
            <ListWrapper>
                <SearchResultsRestaurants
                    restaurants={data?.restaurantList.data}
                    ItemHelperComponent={ItemHelperComponent}
                />
                {hasMore && (
                    <ButtonShowMore onClick={() => getRestaurants()}>
                        Pokaż więcej
                    </ButtonShowMore>
                )}
            </ListWrapper>
        </React.Fragment>
    );
};

const ListWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 70%;
`;

const ButtonShowMore = styled(Button)`
    margin: 16px 0 32px;
`;

const ButtonImport = styled(Button)`
    height: 40px;
    margin: 52px 0 32px;
    width: 255px;
`;
