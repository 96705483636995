import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Collapse } from '@material-ui/core';
import styled from '@emotion/styled';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { css } from '@emotion/react';
import { ButtonText } from '@/componentsV2/atoms/ButtonText/ButtonText';
import { Skeleton } from '@material-ui/lab';
import {
    ChipSecondary,
    ChipSecondaryVariant,
} from '@/componentsV2/atoms/ChipSecondary/ChipSecondary';
import { Separator } from '@/componentsV2/atoms/Separator/Separator';
import { colors } from '@style/index';
import React from 'react';

export interface SearchFilterSelectorValue {
    name: string;
    id: string;
    variant?: ChipSecondaryVariant;
}

export interface SearchFilterSelectorProps {
    accordion?: boolean;
    children?: ReactNode;
    contentPadding?: CSSProperties['padding'];
    display?: boolean;
    loading?: boolean;
    onChange?: (selected: SearchFilterSelectorValue[]) => void;
    onClick?: () => void;
    reset?: () => void;
    selected?: SearchFilterSelectorValue[];
    separator?: boolean;
    title: ReactNode;
    onEnter?: () => void;
    isOpen?: boolean;
}

export const SearchFilterSelector = ({
    accordion = false,
    children,
    contentPadding = '0',
    display = true,
    loading = false,
    onChange,
    onClick,
    reset,
    selected,
    separator,
    title,
    onEnter,
    isOpen = false,
}: SearchFilterSelectorProps) => {
    const [open, setOpen] = useState(isOpen);

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    if (!display && !loading) return null;

    return (
        <>
            <ContentWrapper onClick={onClick} padding={contentPadding}>
                <TitleWrapper
                    onClick={
                        !!onClick
                            ? onClick
                            : () => {
                                  setOpen(!open);
                              }
                    }
                >
                    {title}
                    {accordion ? (
                        <ArrowWrapper>
                            {!!selected?.length && reset && !open && (
                                <ButtonText
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        reset();
                                    }}
                                    variant="black"
                                    text="Wyczyść"
                                />
                            )}
                            <ExpandMoreIcon $isOpen={open} />
                        </ArrowWrapper>
                    ) : (
                        !!onClick && <ArrowIcon />
                    )}
                </TitleWrapper>
                {loading ? (
                    <ChipsWrapper>
                        {Array(4)
                            .fill(0)
                            .map((_, index) => {
                                return (
                                    <ChipSkeleton
                                        key={index}
                                        animation="wave"
                                        height={'32px'}
                                        variant="circle"
                                        width={'100px'}
                                    />
                                );
                            })}
                    </ChipsWrapper>
                ) : (
                    <>
                        <Collapse in={!open} unmountOnExit>
                            <CollapseContent open={open}>
                                {!!selected?.length && !open && (
                                    <ChipsWrapper>
                                        {selected?.map((item) => (
                                            <ChipSecondary
                                                key={item.id}
                                                onClick={() => {}}
                                                onDelete={(value) => {
                                                    onChange?.(
                                                        selected?.filter(
                                                            ({ id }) =>
                                                                value.id !== id,
                                                        ),
                                                    );
                                                }}
                                                size="default"
                                                text={item.name}
                                                value={item}
                                                variant={
                                                    item?.variant ?? 'default'
                                                }
                                            />
                                        ))}
                                    </ChipsWrapper>
                                )}
                            </CollapseContent>
                        </Collapse>
                        {accordion ? (
                            <Collapse in={open} onEnter={onEnter} unmountOnExit>
                                <CollapseContent open={open}>
                                    {children}
                                </CollapseContent>
                            </Collapse>
                        ) : (
                            <>{!onClick && children}</>
                        )}
                    </>
                )}
            </ContentWrapper>
            {separator && <Separator color={colors.porcelainGray} />}
        </>
    );
};

const ContentWrapper = styled.div<{ padding?: CSSProperties['padding'] }>(
    ({ padding }) => css`
        display: grid;
        padding: ${padding};
    `,
);

const ArrowWrapper = styled.div`
    display: flex;
    gap: 22px;
`;

const ChipSkeleton = styled(Skeleton)`
    border-radius: 16px;
`;

const CollapseContent = styled.div<{ open: boolean }>(
    ({ open }) => css`
        padding-bottom: ${open ? '8px' : '0'};
    `,
);

const ArrowIcon = styled(ArrowForwardIosRoundedIcon)`
    margin-right: 16px;
    width: 18px;
`;

const TitleWrapper = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: space-between;
    line-height: 24px;
    padding: 12px 0;
`;

const ChipsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const ExpandMoreIcon = styled(ExpandMore, {
    shouldForwardProp: (prop) => prop !== '$isOpen',
})<{ $isOpen: boolean }>(
    ({ $isOpen }) => css`
        transition: all 0.5s;
        transform: rotate(${$isOpen ? 180 : 0}deg);
    `,
);
