import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { SectionHeadline } from '../../../../components/Elements';
import { sDescription, sGrid } from '../../../../style/style';
import { colors, s } from '../../../../style';
import { sInput } from '../../../style/input.style';
import { VAT, VAT_NAME } from '@constants/fromGraphql/Vat.constants';
import { Controller, useFormContext } from 'react-hook-form';
import styled from '@emotion/styled';

const Price = () => {
    const { control } = useFormContext();

    return (
        <Grid container css={sGrid}>
            <SectionHeadline noPaddingLeft>Cena</SectionHeadline>
            <Grid item xs={12}>
                <p css={[sDescription, { marginBottom: '0rem' }]}>
                    Ustal wartość produktu oraz stawkę podatku VAT.
                </p>
            </Grid>
            <GridItem item xs={12} md={6}>
                <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            required
                            label="Cena brutto (zł)"
                            name="price"
                            fullWidth
                            css={[
                                sInput,
                                {
                                    '.MuiInputLabel-formControl': { top: 15 },
                                    '.MuiInputBase-root': {
                                        minHeight: '25px',
                                    },
                                },
                            ]}
                            type="number"
                        />
                    )}
                />
            </GridItem>
            <GridItem item xs={12} md={6}>
                <Controller
                    name="packagingPrice"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            name="packagingPrice"
                            label="KOSZT OPAKOWANIA (ZŁ)"
                            fullWidth
                            css={[
                                sInput,
                                {
                                    '.MuiInputLabel-formControl': { top: 15 },
                                    '.MuiInputBase-root': {
                                        minHeight: '25px',
                                    },
                                },
                            ]}
                            type="number"
                            onChange={(event) => {
                                field.onChange(
                                    event.target.value === ''
                                        ? null
                                        : +event.target.value,
                                );
                            }}
                        />
                    )}
                />
            </GridItem>
            <GridItem item xs={12} md={6}>
                <SelectWrapper>
                    <Label>Stawka VAT</Label>
                    <Controller
                        name="vat"
                        control={control}
                        render={({ field }) => {
                            const isVatFieldExist =
                                control._defaultValues.hasOwnProperty('vat');

                            if (!field.value && isVatFieldExist) {
                                field.onChange(control._defaultValues.vat);
                            }

                            return (
                                <VatSelect
                                    {...field}
                                    value={
                                        field.value ??
                                        (isVatFieldExist
                                            ? control._defaultValues.vat
                                            : '')
                                    }
                                    fullWidth
                                    name="vat"
                                    required
                                    SelectDisplayProps={{
                                        style: {
                                            padding: '34px 24px 12px',
                                        },
                                    }}
                                >
                                    <MenuItem value={VAT.VAT_0}>
                                        {VAT_NAME.VAT_0}
                                    </MenuItem>
                                    <MenuItem value={VAT.VAT_5}>
                                        {VAT_NAME.VAT_5}
                                    </MenuItem>
                                    <MenuItem value={VAT.VAT_8}>
                                        {VAT_NAME.VAT_8}
                                    </MenuItem>
                                    <MenuItem value={VAT.VAT_23}>
                                        {VAT_NAME.VAT_23}
                                    </MenuItem>
                                </VatSelect>
                            );
                        }}
                    />
                </SelectWrapper>
            </GridItem>
        </Grid>
    );
};

const GridItem = styled(Grid)`
    ${s.md} {
        padding-right: 12px;
    }
`;

const SelectWrapper = styled(FormControl)`
    background-color: ${colors.gray200};
    border-radius: 16px;
    width: 100%;

    &:hover {
        background-color: ${colors.gray300};
    }

    &:focus {
        background-color: ${colors.gray400};
    }

    & label + .MuiInput-formControl {
        margin: 0;
    }
`;

const Label = styled(InputLabel)`
    color: ${colors.gray};
    font-size: 14px;
    font-weight: 700;
    padding: 0 0 0 24px;
    text-transform: uppercase;
    z-index: 9;

    &.MuiFormLabel-root.Mui-focused {
        color: ${colors.gray};
        padding: 14px 0 0 30px;
    }

    &.MuiInputLabel-shrink {
        padding: 14px 0 0 30px;
    }
`;

const VatSelect = styled(Select)`
    background-color: transparent;
    border-radius: 40px;
    font-size: 13px;
    font-weight: 700;

    &:focus {
        &.MuiInput-underline:before,
        &.MuiInput-underline:after {
            border: none;
        }
    }

    &:hover {
        &.MuiInput-underline:before,
        &.MuiInput-underline:after {
            border: none;
        }
    }

    &.MuiInput-underline:before,
    &.MuiInput-underline:after {
        border: none;
    }

    &.MuiInputBase-root .MuiSelect-icon {
        right: 24px;
    }

    &.MuiInputBase-root .MuiSelect-select:focus {
        background-color: unset;
    }
`;

export default Price;
