import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRoutesEzAdmin } from '@/routes';
import { Layout } from '@/EZAdmin/components';
import { useAuthService } from '@/services/AuthService';
import { useFirebaseChatServiceLogin } from '@/services/FirebaseChatService/useFirebaseChatServiceLogin';
import { UserPollingProvider } from '@/EZC/services/UserPollingService/UserPollingProvider';

const EzAdminContainer = () => {
    useFirebaseChatServiceLogin();

    const history = useHistory();
    const routes = useRoutesEzAdmin();

    const userType = useAuthService((state) => state.user?.userType);

    if (userType !== 'Admin') {
        history.push('/404');
        return null;
    }

    return (
        <>
            <Layout>{routes}</Layout>
            {userType === 'Admin' && <UserPollingProvider />}
        </>
    );
};

export default EzAdminContainer;
