import { useEffect } from 'react';
import { useAuthService } from '@/services/AuthService';
import { usePhoneBookService } from '@/services/PhoneBookService/PhoneBookService';
import { useUpsertPhoneBookMutation } from '@/graphql/generated';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { MOBILE } from '@constants/common.constants';

export const usePhoneBookInit = () => {
    const { isLoggedIn, isPhoneBookSent, setIsPhoneBookSent } = useAuthService(
        (state) => state,
    );

    const { getContacts } = usePhoneBookService();
    const [sendContacts] = useUpsertPhoneBookMutation();

    useEffect(() => {
        if (isPhoneBookSent || !isLoggedIn || !MOBILE) return;

        const sendPhoneBook = async () => {
            try {
                const phoneContacts = await getContacts();

                sendContacts({
                    variables: { input: { payload: { phoneContacts } } },
                    onCompleted: () => {
                        setIsPhoneBookSent(true);
                    },
                    onError: graphqlErrorHandler(true),
                });
            } catch (error) {
                console.error(error);
            }
        };

        sendPhoneBook();
    }, [isLoggedIn, isPhoneBookSent]);
};
