/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useRestaurantContext } from '../context/restaurant.provider';
import ImagePicker from '@/EZR/components/ImagePicker';

const ImageLogo = () => {
    const { logoPreview, handleAddLogo, logoUrl } = useRestaurantContext();
    return (
        <ImagePicker
            left
            onChange={handleAddLogo}
            image={logoPreview}
            imageUrl={logoUrl}
            title="Logo"
            desc="Zdjęcie profilowe"
            name="photofileLogo"
        />
    );
};

export default ImageLogo;
