import {
    ChipsBlock,
    ChipsBlockProps,
} from '@/EZC/domains/Account/molecules/ChipsBlock/ChipsBlock';
import {
    PreferencesBlock,
    PreferencesBlockProps,
} from '@/EZC/domains/Account/molecules/PreferencesBlock/PreferencesBlock';

export interface ChipsPreferencesBlockProps
    extends Omit<PreferencesBlockProps, 'children'> {
    chipsProps: ChipsBlockProps;
}

export const ChipsPreferencesBlock = ({
    chipsProps,
    ...props
}: ChipsPreferencesBlockProps) => (
    <PreferencesBlock {...props}>
        <ChipsBlock defaultStep="defaultLight" {...chipsProps} />
    </PreferencesBlock>
);
