/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import {
    consumptionTypeTypes,
    consumptionTypeNames,
    consumptionTypeIcons,
} from '../../../../constants/types';
import { ButtonBase } from '@material-ui/core';

import { s, colors, alpha } from '../../../../style';

const ChoseType = (props) => (
    <div
        css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            [s.md_down]: {
                flexDirection: 'column',
                width: '100%',
                padding: '1.5rem 1rem',
            },
            [s.md]: { padding: '2rem', gap: 7 },
            [s.lg]: { padding: '2rem', gap: '2rem' },
        }}
    >
        <TypeDiv value={consumptionTypeTypes.IN} {...props} />
        <TypeDiv value={consumptionTypeTypes.TAKEAWAY} {...props} />
        <TypeDiv value={consumptionTypeTypes.DELIVERY} {...props} />
    </div>
);

export default ChoseType;

const TypeDiv = ({ consumptionType, onChange, value, availableTypes }) => (
    <ButtonBase
        css={[
            {
                display: 'flex',
                width: '100%',
                borderRadius: '1.5rem',
                transition:
                    'background-color 0.3s ease-out, color 0.3s ease-out',
                [s.md_down]: {
                    marginBottom: '1rem',
                    '&:last-of-type': { marginBottom: 0 },
                },
            },
            value === consumptionType
                ? {
                      backgroundColor: colors.green.concat(alpha[16]),
                      color: colors.green,
                  }
                : {
                      backgroundColor: colors.text.concat(alpha[8]),
                      color: colors.text.concat(alpha[60]),
                  },

            !availableTypes.includes(value) && {
                backgroundColor: colors.text.concat(alpha[0]),
                color: colors.text.concat(alpha[16]),
            },
        ]}
    >
        <div
            onClick={() => {
                if (availableTypes.includes(value)) {
                    onChange({ target: { name: 'type', value } });
                } else {
                    toast.info(
                        'Wybrany typ zamówienia nie jest obsługiwany przez restaurację',
                    );
                }
            }}
            css={{
                padding: '1rem 1.5rem',
                width: '100%',
                borderRadius: '1rem',
                fontWeight: 700,
                fontSize: '15px',
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                // transition: 'background-color 0.3s ease-out, color 0.3s ease-out',
                '.checkout-type-item-icon': {
                    [s.md_down]: { marginRight: '0.5rem' },
                    [s.lg]: { marginBottom: '0.5rem', fontSize: '30px' },
                    fontSize: '18px',
                    svg: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                },
                [s.md_down]: {
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                },
                [s.md_only]: { height: 54 },
            }}
        >
            <span className="checkout-type-item-icon">
                {consumptionTypeIcons[value]}
            </span>
            <p>{consumptionTypeNames[value]}</p>
        </div>
    </ButtonBase>
);
