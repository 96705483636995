import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { SectionHeadline } from '../../../../components/Elements';
import { sDescription, sGrid } from '../../../../style/style';

import { colors, s, alpha } from '../../../../style';
import { useController, useFormContext } from 'react-hook-form';
import {
    CONSUMPTION_TYPE_ENUM,
    CONSUMPTION_TYPE,
} from '@constants/fromGraphql/ConsumptionType.constants';
import { useRestaurantConsumptionTypesQuery } from '@graphql/generated';
import { useAuthService } from '@/services/AuthService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

const ConsumptionTypes = ({ newProduct }) => {
    const { control } = useFormContext();
    const { field } = useController({ control, name: 'consumptionTypes' });
    const value = field.value ?? [];

    const { restaurantId } = useAuthService();

    const { data } = useRestaurantConsumptionTypesQuery({
        variables: {
            input: { restaurantId },
        },
        onError: graphqlErrorHandler(true),
    });

    const onChange = (type) => {
        const newValue = value?.includes(type)
            ? value?.filter((el) => el !== type)
            : [...value, type];

        field.onChange(newValue);
    };

    const consumptionType = data?.restaurant?.consumptionTypes;

    useEffect(() => {
        newProduct && field.onChange(consumptionType);
    }, [data]);

    return (
        <Grid container css={[sGrid]}>
            <SectionHeadline noPaddingLeft>Typy Zamówień</SectionHeadline>
            <p css={sDescription}>
                Zaznacz w jaki sposób można zamówić ten produkt.
            </p>
            <Grid item xs={12} css={sContainer}>
                <div
                    css={[
                        sTypeButton,
                        value?.includes(CONSUMPTION_TYPE_ENUM.IN) &&
                            sTypeButtonActive,
                        !consumptionType?.includes(CONSUMPTION_TYPE_ENUM.IN) &&
                            sTypeButtonDisabled,
                    ]}
                    onClick={() =>
                        consumptionType?.includes(CONSUMPTION_TYPE_ENUM.IN) &&
                        onChange(CONSUMPTION_TYPE_ENUM.IN)
                    }
                >
                    {CONSUMPTION_TYPE[CONSUMPTION_TYPE_ENUM.IN]?.icon}
                    <p>{CONSUMPTION_TYPE[CONSUMPTION_TYPE_ENUM.IN]?.name}</p>
                </div>
                <div
                    css={[
                        sTypeButton,
                        value?.includes(CONSUMPTION_TYPE_ENUM.TAKEAWAY) &&
                            sTypeButtonActive,
                        !consumptionType?.includes(
                            CONSUMPTION_TYPE_ENUM.TAKEAWAY,
                        ) && sTypeButtonDisabled,
                        { [s.md]: { margin: '0 1.5rem' } },
                    ]}
                    onClick={() =>
                        consumptionType?.includes(
                            CONSUMPTION_TYPE_ENUM.TAKEAWAY,
                        ) && onChange(CONSUMPTION_TYPE_ENUM.TAKEAWAY)
                    }
                >
                    {CONSUMPTION_TYPE[CONSUMPTION_TYPE_ENUM.TAKEAWAY]?.icon}
                    <p>
                        {CONSUMPTION_TYPE[CONSUMPTION_TYPE_ENUM.TAKEAWAY]?.name}
                    </p>
                </div>

                <div
                    css={[
                        sTypeButton,
                        value?.includes(CONSUMPTION_TYPE_ENUM.DELIVERY) &&
                            sTypeButtonActive,
                        !consumptionType?.includes(
                            CONSUMPTION_TYPE_ENUM.DELIVERY,
                        ) && sTypeButtonDisabled,
                    ]}
                    onClick={() =>
                        consumptionType?.includes(
                            CONSUMPTION_TYPE_ENUM.DELIVERY,
                        ) && onChange(CONSUMPTION_TYPE_ENUM.DELIVERY)
                    }
                >
                    {CONSUMPTION_TYPE[CONSUMPTION_TYPE_ENUM.DELIVERY]?.icon}
                    <p>
                        {CONSUMPTION_TYPE[CONSUMPTION_TYPE_ENUM.DELIVERY]?.name}
                    </p>
                </div>
            </Grid>
        </Grid>
    );
};

const sContainer = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2rem',
    [s.md]: {
        justifyContent: 'flex-start',
    },
};

const sTypeButton = {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease-out, color 0.3s ease-out',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '30px',
    backgroundColor: colors.gray200,
    color: colors.gray500,
    padding: '1rem',
    borderRadius: '1rem',
    width: 160,
    [s.xs]: {
        width: '30%',
        maxWidth: '30%',
        flexBasis: '30%',
    },
    p: {
        textAlign: 'center',
        marginTop: '0.5rem',
        fontSize: '0.675rem',
        textTransform: 'uppercase',
        fontWeight: 700,
        [s.md]: {
            fontSize: '0.75rem',
        },
    },
};

const sTypeButtonActive = {
    backgroundColor: colors.orange.concat(alpha[16]),
    color: colors.orange,
};

const sTypeButtonDisabled = {
    backgroundColor: colors.gray200,
    color: colors.gray500,
    PointerEvent: 'none',
    cursor: 'default',
};

export default ConsumptionTypes;
