import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

import {
    useAdminFindUsersLazyQuery,
    useAdminUpdateUserMutation,
} from '@graphql/generated';

const useMakeRestaurateur = () => {
    const [adminUpdateUser, { loading: updateUserLoading }] =
        useAdminUpdateUserMutation();
    const [
        adminFindUsers,
        { data, error, refetch, loading: findUsersLoading },
    ] = useAdminFindUsersLazyQuery();

    const handleGetUser = (email) => {
        adminFindUsers({
            variables: { input: { query: { email } } },
            onCompleted: (data) => {
                if (!data?.adminFindUsers?.data?.length) {
                    toast.error('Użytkownik nie został odnaleziony');
                }
            },
        });
    };

    if (error) graphqlErrorHandler(true);

    const handleChangeRole = async () => {
        try {
            const { id, type } = data.adminFindUsers.data[0];
            const newType = type === 'Restaurateur' ? 'User' : 'Restaurateur';
            await adminUpdateUser({
                variables: {
                    input: {
                        userId: id,
                        payload: { type: newType },
                    },
                },
                onCompleted: () => {
                    toast.info('Rola użytkownika została zmieniona');
                    refetch();
                },
                onError: graphqlErrorHandler(true),
            });
        } catch (error) {
            console.error(error);
        }
    };

    return {
        handleGetUser,
        data,
        findUsersLoading,
        updateUserLoading,
        handleChangeRole,
    };
};

export default useMakeRestaurateur;
