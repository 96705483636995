export const FilterIcon = () => {
    return (
        <svg
            width="36"
            height="36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.25 10.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.646.5a2.751 2.751 0 1 1 0 1.5H9.75a.75.75 0 0 1 0-1.5h11.854ZM14 16.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.646.5a2.751 2.751 0 0 1 5.293 0h9.603a.75.75 0 0 1 0 1.5h-9.604a2.751 2.751 0 0 1-5.293 0H9.75a.75.75 0 0 1 0-1.5h1.604ZM19.75 23a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.646.5a2.751 2.751 0 0 1 5.293 0h3.853a.75.75 0 0 1 0 1.5h-3.854a2.751 2.751 0 0 1-5.293 0H9.75a.75.75 0 0 1 0-1.5h7.354Z"
                fill="#343340"
            />
        </svg>
    );
};
