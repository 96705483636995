/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import Headline from '../../../components/Headline/Headline';
import { Input } from '../Auth/components';
import Spinner from '../../../components/Spinner';
import { ButtonBorder, Button } from '../../../components/Elements';
import { sGrid, sDescription } from '../../../style/style';
import { s } from '../../../style';
import { useGetAuthGoogleOauthRedirectUrlQuery } from '@graphql/generated';
import { TwoActionButtons } from '@/componentsV2/molecules/TwoActionButtons/TwoActionButtons';
import { useLoginWithGoogle } from '@/EZC/pages/LoginWithGoogle/useLoginWithGoogle';
import { MOBILE } from '@constants/common.constants';
import { GoogleIcon } from '@/svgs/GoogleIcon';
import { GoogleButtonContent } from '@/EZC/views/Auth/Login.view';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';

const initValues = {
    email: '',
    password: '',
};

const LoginWidget = ({ onSubmit, setAuthType }) => {
    const [inputValues, setInputValues] = useState(initValues);
    const [isLoading, setIsLoading] = useState(false);
    const onChange = (e) =>
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });

    const setWaiterView = useWaiterService((state) => state.setWaiterView);

    const handleAction = () => setAuthType('SIGNUP');
    const handleSubmit = () => {
        setIsLoading(true);
        setWaiterView(false);
        onSubmit(inputValues, () => setIsLoading(false));
    };
    const { data, loading: googleRedirectLoading } =
        useGetAuthGoogleOauthRedirectUrlQuery();
    const { handleLoginWithGoogle } = useLoginWithGoogle();

    const onEnterSubmit = (e) => e.key === 'Enter' && onSubmit();
    return (
        <div>
            <Headline
                extraCss={sHeadline}
                itemChildren={
                    <ButtonBorder action={handleAction}>
                        Zarejestruj
                    </ButtonBorder>
                }
            >
                Logowanie
            </Headline>
            <div css={[sGrid, sGridOverride]}>
                <p css={[sDescription, { fontSize: '13px' }]}>
                    Zaloguj się do swojego konta Eatzon i poznaj kulinarny świat
                </p>
            </div>
            <div css={[sFormContainer]}>
                <Input
                    onChange={onChange}
                    name="email"
                    type="email"
                    value={inputValues.email}
                    placeholder="Email"
                    onKeyUp={onEnterSubmit}
                    autocomplete="email"
                />

                <Input
                    onChange={onChange}
                    name="password"
                    value={inputValues.password}
                    type="password"
                    placeholder="Hasło"
                    onKeyUp={onEnterSubmit}
                    autocomplete="current-password"
                />

                <TwoActionButtons
                    buttonFirst={{
                        onClick: handleSubmit,
                        disabled: isLoading,
                        loading: isLoading,
                        children: 'Zaloguj',
                        variant: 'black',
                    }}
                    buttonSecond={{
                        onClick: async () => {
                            setWaiterView(false);
                            if (MOBILE) {
                                handleLoginWithGoogle();
                            } else {
                                window.location.href = data?.url;
                            }
                        },
                        children: (
                            <GoogleButtonContent>
                                <GoogleIcon /> Zaloguj przez Google
                            </GoogleButtonContent>
                        ),
                        disabled: !data,
                        loading: googleRedirectLoading,
                        variant: 'black',
                    }}
                    separatorText="lub"
                />
            </div>
        </div>
    );
};

const sHeadline = {
    [s.sm_down]: { padding: '2rem 2rem 1rem' },
    [s.md]: { padding: '2rem 4rem 2rem' },
};

const sGridOverride = {
    [s.sm_down]: { padding: '0 2rem' },
    [s.md]: { padding: '0 4rem' },
};

const sFormContainer = {
    [s.sm_down]: { padding: '0 1.5rem 2rem' },
    [s.md]: { padding: '0.5rem 3rem 3rem' },
};

export default LoginWidget;
