import {
    ChatContainer,
    ChatWindow,
    InputArea,
    Messages,
    MessagesContainer,
} from './components';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { useOrderChat } from '@/services/FirebaseChatService/chats/order';
import PageLoader from '@components/PageLoader/PageLoader';
import { FIREBASE_ENV } from '@/services/FirebaseChatService/FirebaseChatService.config';
import {
    useChatMarkAsReadMessage,
    useChatSendMessage,
} from '@/services/FirebaseChatService/chats/common';
import { useChat } from '@hooks/useChat';
import { useFirebaseChatService } from '@/services/FirebaseChatService/FirebaseChatService';

const OrderChat = ({ orderId, name, avatarUrl, isWaiter, secondUser }) => {
    const userId = useFirebaseChatService((state) => state.userId);

    const { messages, loading } = useOrderChat(orderId, isWaiter);
    const { message, sendMessage, setMessage } = useChatSendMessage(
        `env/${FIREBASE_ENV}/chats/${orderId}`,
    );
    const { measuredRef, scrollToBottom } = useChat(messages.length);

    useChatMarkAsReadMessage(`env/${FIREBASE_ENV}/chats/${orderId}`, messages);

    const handleSend = () => {
        sendMessage();
        scrollToBottom();
    };

    if (loading) return <PageLoader />;

    return (
        <ChatContainer>
            <ChatWindow>
                <TopNav
                    title={name}
                    subtitle={` - Czat`}
                    photo={avatarUrl !== 'undefined' ? avatarUrl : false}
                    isScrolledAlready
                />
                <MessagesContainer measuredRef={measuredRef}>
                    <Messages
                        isWaiter={isWaiter}
                        userId={userId}
                        messages={messages.map((message) => ({
                            ...message,
                            author:
                                message.sender === userId ? secondUser : name,
                        }))}
                        // TODO
                        // subscribeToNewMssgs={() =>
                        //     subscribeToMore({
                        //         document: SUBS_ORDER_MESSAGES,
                        //         variables: { orderId },
                        //         updateQuery: (prev, { subscriptionData }) => {
                        //             if (!subscriptionData.data) return prev;
                        //             const newMssg =
                        //                 subscriptionData.data.addMssgToOrder;
                        //             return Object.assign({}, prev, {
                        //                 orderMessages: [
                        //                     ...prev.orderMessages,
                        //                     newMssg,
                        //                 ],
                        //             });
                        //         },
                        //     })
                        // }
                    />
                </MessagesContainer>
                <InputArea
                    newMssg={message}
                    setNewMssg={setMessage}
                    onSend={handleSend}
                />
            </ChatWindow>
        </ChatContainer>
    );
};

export default OrderChat;
