import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';
import { colors } from '@style/index';
import { ButtonSecondary } from '@/componentsV2/atoms/ButtonSecondary/ButtonSecondary';
import { Grid } from '@material-ui/core';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { EatzonIcon } from '@/svgs/EatzonIcon';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

export interface PaymentMethodProps {
    paymentMethod: string;
    setPaymentMethod: (value: string) => void;
    inPaymentDisabled?: boolean;
}

export const PaymentMethod = ({
    paymentMethod,
    setPaymentMethod,
    inPaymentDisabled,
}: PaymentMethodProps) => {
    return (
        <Grid item xs={12} md={6}>
            <Accordion
                headerElement={
                    <ContentWrapper>
                        <IconWrapper>
                            {paymentMethod === 'in' ? (
                                <EatzonIcon size="24px" />
                            ) : (
                                <AttachMoneyIcon fontSize="medium" />
                            )}
                        </IconWrapper>
                        <InfoWrapper>
                            <Title>
                                {paymentMethod === 'in'
                                    ? 'Płatność Eatcoin'
                                    : 'Płatność gotówką lub kartą'}
                            </Title>
                            <Description>Wybierz sposób płatności</Description>
                        </InfoWrapper>
                    </ContentWrapper>
                }
            >
                <ButtonsWrapper>
                    <ButtonSecondary
                        icon={
                            <EatzonIcon
                                color={
                                    paymentMethod === 'in'
                                        ? '#00B30F'
                                        : '#A3A5B0'
                                }
                                size="16px"
                            />
                        }
                        variant={paymentMethod === 'in' ? 'green' : 'default'}
                        title="Płatność Eatcoin"
                        onClick={() => setPaymentMethod('in')}
                        disabled={inPaymentDisabled}
                    />
                    <ButtonSecondary
                        icon={<MoneyIcon fontSize="inherit" />}
                        variant={paymentMethod !== 'in' ? 'green' : 'default'}
                        title="Płatność gotówką lub kartą"
                        onClick={() => setPaymentMethod('out')}
                        disabled={inPaymentDisabled}
                    />
                </ButtonsWrapper>
            </Accordion>
        </Grid>
    );
};

const ButtonsWrapper = styled.div`
    display: grid;
    gap: 16px;
    padding: 32px;

    ${mediaQueries.sm} {
        padding: 24px 16px;
    }
`;
const MoneyIcon = styled(AttachMoneyIcon)`
    font-size: 18px;
`;

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr 112px;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;
