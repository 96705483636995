import React from 'react';
import styled from '@emotion/styled';
import { mediaQueriesMaterial } from '@style/breakpoints';
//@ts-expect-error FIXME
import image from '@/style/assets/europeanfound.jpg';

export const EuropeanFundsInfo = () => {
    return (
        <Wrapper>
            <Link
                href="https://eatzon.pl/program-polska-wschodnia/"
                target="_blank"
            >
                <Image src={image} alt={'European founds'} />
            </Link>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    ${mediaQueriesMaterial.sm} {
        padding: 20px 0;
    }
`;
const Link = styled.a`
    cursor: pointer;
`;
const Image = styled.img`
    width: 100%;
    max-width: 800px;
`;
