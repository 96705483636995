/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import useMakeRestaurateur from './useMakeRestaurateur';
import { Button, FindUserByEmail, UserItem } from '@/EZAdmin/components';

const EzAdminMakeRestaurateur = () => {
    const {
        handleGetUser,
        data,
        findUsersLoading,
        updateUserLoading,
        handleChangeRole,
    } = useMakeRestaurateur();

    return (
        <div>
            <FindUserByEmail
                getUser={handleGetUser}
                loading={findUsersLoading}
            />
            {data?.adminFindUsers?.data?.length > 0 && (
                <UserItem
                    {...data.adminFindUsers.data[0]}
                    helperComponent={
                        <Button
                            onClick={handleChangeRole}
                            disabled={updateUserLoading}
                        >
                            Zmień rolę
                        </Button>
                    }
                />
            )}
        </div>
    );
};

export default EzAdminMakeRestaurateur;
