import { useEffect } from 'react';
import { useAuthService } from '../AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { useHandleNotificationsService } from './NotificationService.helpers';
import { usePushNotificationInitialize } from './usePushNotificationInitialize';
import { PushNotifications } from '@capacitor/push-notifications';
import { ANDROID } from '@constants/common.constants';
import { useHandleBackFromBackground } from '@hooks/useHandleBackFromBackground';
import PopupWaiter from '@/EZW/components/PopupWaiter';

export const PushNotificationProvider = () => {
    usePushNotificationInitialize();
    const setUserFcmToken = useAuthService((state) => state.setFcmToken);
    const setWaiterFcmToken = useWaiterService((state) => state.setFcmToken);
    const { isNewOrdersPopupOpen, setIsNewOrdersPopupOpen } =
        useWaiterService();

    const {
        handleAppClosedNotification,
        handleAppOpenNotification,
        addNotificationsChannels,
        handleNotificationsTopics,
    } = useHandleNotificationsService();

    useEffect(() => {
        if (ANDROID) {
            addNotificationsChannels();
        }

        PushNotifications.addListener('registration', (token) => {
            setUserFcmToken(token.value);
            setWaiterFcmToken(token.value);
        });

        PushNotifications.addListener('registrationError', (error) =>
            console.error(error),
        );

        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification) => {
                handleAppOpenNotification({
                    type: notification.data.type,
                    body: notification.body ?? '',
                    orderId: notification.data.order ?? '',
                    ownerId: notification.data.ownerId,
                    senderId: notification.data.sender,
                    restaurantId: notification.data.restaurant,
                    groupId: notification.data.groupId,
                    cartId: notification.data.cartId,
                });
            },
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (action) => {
                handleAppClosedNotification(action.notification.data);
            },
        );

        handleNotificationsTopics();

        return () => {
            PushNotifications.removeAllListeners();
        };
    }, []);

    useHandleBackFromBackground(async () => {
        try {
            const deliveredNotifications =
                await PushNotifications.getDeliveredNotifications();

            if (deliveredNotifications.notifications.length) {
                PushNotifications.removeAllDeliveredNotifications();
            }
        } catch (e) {
            console.error(e);
        }
    });

    return isNewOrdersPopupOpen ? (
        <PopupWaiter
            onHide={() => {
                setIsNewOrdersPopupOpen(false);
            }}
        />
    ) : null;
};
