/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Bullet, Collapsable } from '../../../../components/Elements';
import { s, colors } from '../../../../style';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import { Grid } from '@material-ui/core';
import { FLAVOUR_NAME } from '@constants/fromGraphql/Flavor.constants';
import { COOK_METHOD_NAME } from '@constants/fromGraphql/CookMethod.constants';

const polishIsHard = (n) => {
    if (n === 0) return 'ów';
    if (n === 1) return '';
    if (n > 1 && n < 5) return 'i';
    if (n >= 5) return 'ów';
};

const ProductDetails = ({
    cookTime,
    cookMethod,
    flavors,
    ingredients,
    openAlready,
    left = true,
    right,
}) => {
    return (
        <Collapsable
            left={left}
            right={right}
            openAlready={openAlready}
            noPadding
            oneLiner
            title={'Przyrządzenie'}
            description={
                <div css={{ overflowX: 'scroll' }}>
                    <span css={{ color: colors.text }}>
                        ok. {cookTime} minut
                    </span>
                    {ingredients && (
                        <React.Fragment>
                            <Bullet />
                            {ingredients.length} składnik
                            {polishIsHard(ingredients.length)}
                        </React.Fragment>
                    )}
                    {flavors && (
                        <React.Fragment>
                            <Bullet />
                            {flavors.length} smak{polishIsHard(flavors.length)}
                        </React.Fragment>
                    )}
                </div>
            }
            icon={<LocalPizzaIcon />}
            sOuterCss={{
                paddingBottom: '1.5rem',
            }}
        >
            <Grid container css={sContainer}>
                {!!ingredients?.length && (
                    <Grid item xs={12}>
                        <p css={sTitle}>SKŁADNIKI</p>
                        <div css={sItemContainer}>
                            {ingredients &&
                                ingredients.map((ingredient, id) => (
                                    <div css={sItem} key={id}>
                                        {ingredient?.name}
                                    </div>
                                ))}
                        </div>
                    </Grid>
                )}
                {!!flavors?.length && (
                    <Grid item xs={6}>
                        <p css={sTitle}>SMAKI</p>
                        <div css={sItemContainer}>
                            {flavors.map((flavor, id) => (
                                <div css={sItem} key={id}>
                                    {FLAVOUR_NAME[flavor]}
                                </div>
                            ))}
                        </div>
                    </Grid>
                )}
                {!!cookMethod?.length && (
                    <Grid item xs={6}>
                        <p css={sTitle}>METODA</p>
                        <div css={sItemContainer}>
                            {cookMethod.map((method, id) => (
                                <div css={sItem} key={id}>
                                    {COOK_METHOD_NAME[method]}
                                </div>
                            ))}
                        </div>
                    </Grid>
                )}
            </Grid>
        </Collapsable>
    );
};

const sContainer = {
    padding: '1.5rem 1rem 0rem',
    [s.md]: { padding: '2rem 2rem 0.5rem' },
};

const sTitle = {
    paddingLeft: '0.75rem',
    marginBottom: '0.5rem',
    fontSize: '11px',
    color: colors.gray500,
    letterSpacing: '0.1em',
    fontWeight: 700,
    [s.md]: { marginBottom: '1rem' },
};

const sItemContainer = {
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: '1.5rem',
};

const sItem = {
    transition: 'background-color 0.3s ease-out',
    padding: '0.25rem 0.75rem',
    borderRadius: '2rem',
    margin: '0 0.5rem 0.5rem 0',
    backgroundColor: colors.gray300,
    fontWeight: 700,
    fontSize: '13px',
    textAlign: 'center',

    '&:hover': {
        backgroundColor: colors.gray400,
    },
};

export default ProductDetails;
