import React, { ChangeEvent } from 'react';
import { ListItemCheckbox } from '@/componentsV2/molecules/ListItemCheckbox/ListItemCheckbox';
import { ConsumptionType } from '@/graphql/generated';
import { CONSUMPTION_TYPE_NAME_VALUES } from '@constants/fromGraphql/ConsumptionType.constants';

export interface PreferencesBoxProps {
    onChange: (value: ConsumptionType[]) => void;
    value: ConsumptionType[];
}

export const PreferencesBox = ({ onChange, value }: PreferencesBoxProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const changedValue = event.currentTarget.value as ConsumptionType;

        const updatedPreferences = value.includes(changedValue)
            ? value.filter((val) => val !== changedValue)
            : [...value, changedValue];

        onChange(updatedPreferences);
    };

    return (
        <>
            {CONSUMPTION_TYPE_NAME_VALUES.map((item) => (
                <ListItemCheckbox
                    checked={value.includes(item.id)}
                    key={item.id}
                    onChange={handleChange}
                    separator
                    title={item.name}
                    value={item.id}
                    disabled={value.includes(item.id) && value.length <= 1}
                />
            ))}
        </>
    );
};
