import React from 'react';
import { Button, ButtonProps } from '@/componentsV2/molecules/Button/Button';
import { DoubleSeparator } from '@/componentsV2/molecules/DoubleSeparator/DoubleSeparator';
import styled from '@emotion/styled';
import { MOBILE } from '@constants/common.constants';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';

interface TwoActionButtonsProps {
    buttonFirst: ButtonProps;
    buttonSecond: ButtonProps;
    separatorText: string;
}

export const TwoActionButtons = ({
    buttonFirst,
    buttonSecond,
    separatorText,
}: TwoActionButtonsProps) => {
    const { googleSsoStatus } = useServerConfigStoreShallow([
        'googleSsoStatus',
    ]);

    return (
        <ButtonsWrapper>
            <ActionButton
                width="100%"
                onClick={buttonFirst.onClick}
                disabled={buttonFirst.disabled}
                loading={buttonFirst.loading}
                variant={buttonFirst.variant}
            >
                {buttonFirst.children}
            </ActionButton>

            {(!MOBILE || googleSsoStatus) && (
                <>
                    <DoubleSeparator text={separatorText} />
                    <ActionButton
                        width="100%"
                        onClick={buttonSecond.onClick}
                        disabled={buttonSecond.disabled}
                        loading={buttonSecond.loading}
                        variant={buttonSecond.variant}
                    >
                        {buttonSecond.children}
                    </ActionButton>
                </>
            )}
        </ButtonsWrapper>
    );
};

const ButtonsWrapper = styled.div`
    display: grid;
    grid-gap: 12px;
    margin-top: 8px;
`;

const ActionButton = styled(Button)`
    font-size: 13px;
    padding: 0;
`;
