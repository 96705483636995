/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid, Collapse } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Collapsable, Row } from '@components/Elements';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { colors } from '@style';
import { showZlotyWithPrecision } from '@helpers/MoneyEz/utils/converters';
import { useAuthService } from '@/services/AuthService';
import { ShoppingBag } from '@/svgs/ShoppingBag';
import styled from '@emotion/styled';
import { mediaQueries } from '@style/breakpoints';

const OrderPayment = ({
    deliveryCost,
    left,
    isDelivery,
    productsCost,
    packageCost,
    right,
    showDeliveryCost,
    totalCost,
    toTakeAway,
}) => {
    const { isLoggedIn } = useAuthService();

    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                right={isLoggedIn ? right : toTakeAway}
                left={isLoggedIn ? left : !toTakeAway}
                noPadding
                oneLiner
                title={'Płatność'}
                icon={<MonetizationOnIcon />}
                description={
                    <>
                        <span css={{ color: colors.text }}>
                            {showZlotyWithPrecision(parseFloat(totalCost))}
                        </span>
                        &nbsp;zł
                    </>
                }
            >
                <Row
                    desc={'Produkty'}
                    icon={<FastfoodIcon fontSize="inherit" />}
                >
                    {productsCost} zł
                </Row>
                {!!packageCost && (
                    <PackageCostWrapper>
                        <TextWrapper>
                            <ShoppingBag />
                            Opakowanie
                        </TextWrapper>
                        <Amount>
                            {showZlotyWithPrecision(parseFloat(packageCost))} zł
                        </Amount>
                    </PackageCostWrapper>
                )}
                <Collapse in={isDelivery}>
                    <Row
                        desc={'Dostawa'}
                        icon={<LocalShippingIcon fontSize="inherit" />}
                    >
                        {showDeliveryCost
                            ? `${deliveryCost} zł`
                            : 'Niedostępna'}
                    </Row>
                </Collapse>
                <Row
                    desc={'Łącznie'}
                    icon={<AttachMoneyIcon fontSize="inherit" />}
                    focus
                >
                    {showZlotyWithPrecision(parseFloat(totalCost))} zł
                </Row>
            </Collapsable>
        </Grid>
    );
};

const PackageCostWrapper = styled.div`
    align-items: center;
    color: ${colors.blueZodiac};
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    padding: 16px 32px 16px 32px;

    ${mediaQueries.xs} {
        padding: 16px 24px 16px 14px;
    }
`;

const TextWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 16px;
    font-weight: 500;
`;

const Amount = styled.span`
    color: ${colors.text};
    font-size: 14px;
    font-weight: 700;
`;

export default OrderPayment;
