import { useState } from 'react';
import { subDays } from 'date-fns';
import { dateFormat } from '@helpers/dateFormat';
import { Range } from 'react-date-range';

const initialDateRange = [
    {
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
        key: 'selection',
    },
];

export const useCalendarPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const [state, setState] = useState<Range[]>([]);

    const onPopupOpen = () => {
        setState(initialDateRange);
        setIsPopupOpen(true);
    };

    const onPopupClose = () => {
        setIsPopupOpen(false);
    };

    const { standardDate } = dateFormat;

    return {
        startDate: standardDate(
            state[0]?.startDate || initialDateRange[0].startDate,
        ),
        endDate: standardDate(state[0]?.endDate || initialDateRange[0].endDate),
        state,
        setState,
        onPopupOpen,
        isPopupOpen,
        onPopupClose,
    };
};
