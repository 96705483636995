import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAuthService } from '@/services/AuthService';

import {
    MenuDocument,
    useMenuCategoryCreateMutation,
    useMenuCategoryDeleteMutation,
    useMenuCategoryUpdateMutation,
    useMenuQuery,
} from '@graphql/generated';
import _ from 'lodash';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';

export default () => {
    const restaurantId = useAuthService((state) => state.restaurantId);

    const { data, loading, error, refetch } = useMenuQuery({
        variables: { input: { restaurantId } },
    });
    const [categoryCreate] = useMenuCategoryCreateMutation();
    const [categoryUpdate] = useMenuCategoryUpdateMutation();
    const [categoryDelete] = useMenuCategoryDeleteMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [menuSections, setMenuSections] = useState([]);

    const [wasChanges, setWasChanges] = useState(false);
    const [activeSectionId, setActiveSectionId] = useState('');
    const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);
    const [singleSectionProducts, setSingleSectionProducts] = useState([]);

    useEffect(() => {
        if (data?.restaurant) {
            const sortedMenuSections = [...data.restaurant?.menu];
            sortedMenuSections.sort((a, b) => a?.order - b?.order);

            setMenuSections(sortedMenuSections);
        }
    }, [data]);

    const handleAddProductsOpen = (sectionId) => {
        setActiveSectionId(sectionId);
        setIsAddProductsOpen(true);
    };

    const handleAddProductsClose = () => setIsAddProductsOpen(false);

    const onAddSection = () => {
        if (!wasChanges) setWasChanges(true);

        const newSection = {
            id: '' + menuSections.length + Math.random(),
            order: 0,
            name: '',
            products: [],
        };

        const updatedSections = menuSections.map((section) => ({
            ...section,
            order: section.order + 1,
        }));

        setMenuSections([newSection, ...updatedSections]);

        toast.info(
            'Sekcja została dodana. Nadaj nazwę sekcji oraz dodaj produkty',
        );
    };

    const onDeleteSection = (id) => {
        if (!wasChanges) setWasChanges(true);
        const updatedSections = menuSections.filter(
            (section) => section.id !== id,
        );
        const reorderedSections = updatedSections.map((section, index) => ({
            ...section,
            order: index,
        }));
        setMenuSections(reorderedSections);
    };

    const onDeleteProduct = (sectionId) => (prodId) => {
        if (!wasChanges) setWasChanges(true);
        setMenuSections(
            menuSections.map((section) =>
                section.id === sectionId
                    ? {
                          ...section,
                          products: section.products.filter(
                              (product) => product.id !== prodId,
                          ),
                      }
                    : section,
            ),
        );
    };

    const onNameChange = (sectionId) => (e) => {
        if (!wasChanges) setWasChanges(true);
        setMenuSections(
            menuSections.map((section) =>
                section.id === sectionId
                    ? { ...section, name: e.target.value }
                    : section,
            ),
        );
    };

    const generateToastMessage = (message) => {
        const regex = /\[productId=(\w+), menuId=(\w+)\]/;
        const match = message.match(regex);

        if (match) {
            const productId = match[1];
            const menuId = match[2];

            const menu = menuSections.find((section) => section.id === menuId);

            const product = menu.products.find(
                (product) => product.id === productId,
            );

            return `Produkt: ${product?.name} jest już dodany do sekcji: ${menu?.name}`;
        } else {
            return 'Brak możliwości dodania produktu do więcej niż jednej kategorii w menu';
        }
    };

    const onSaveAddedProducts = () => {
        if (!wasChanges) setWasChanges(true);
        setMenuSections(
            menuSections.map((section) =>
                section.id === activeSectionId
                    ? { ...section, products: [...singleSectionProducts] }
                    : section,
            ),
        );
        setSingleSectionProducts([]);
        handleAddProductsClose();
    };

    const isNewSection = menuSections.some(
        (section) => section.id === activeSectionId,
    );

    const onSave = async () => {
        try {
            setIsLoading(true);
            let notify = false;

            for (const section of menuSections) {
                if (!section.name.length) {
                    throw new Error('Nazwa sekcji jest wymagana');
                }

                const category = data?.restaurant?.menu.find(
                    (category) => category.id === section.id,
                );

                if (category) {
                    if (
                        category?.name !== section.name ||
                        !_.isEqual(category.products, section.products) ||
                        category.order !== section.order
                    ) {
                        await categoryUpdate({
                            variables: {
                                input: {
                                    restaurantId,
                                    menuId: section.id,
                                    order: section.order,
                                    payload: {
                                        name: section.name,
                                        products: section.products.map(
                                            (product) => product.id,
                                        ),
                                    },
                                },
                            },
                            onCompleted: () => (notify = true),
                            onError: (error) => {
                                graphqlErrorHandler({
                                    customCodeMessages: {
                                        MENU_CATEGORY_PRODUCT_ALREADY_ADDED_TO_ANOTHER_ERROR_CODE:
                                            generateToastMessage(error.message),
                                    },
                                })(error);
                            },
                        });
                    }
                } else {
                    await categoryCreate({
                        variables: {
                            input: {
                                restaurantId,
                                order: section.order,
                                name: section.name,
                                products: section.products.map(
                                    (product) => product.id,
                                ),
                            },
                        },

                        onCompleted: () => (notify = true),
                        onError: graphqlErrorHandler(true),
                    });
                }
            }

            for (const category of data?.restaurant?.menu) {
                if (
                    !menuSections.some((section) => section.id === category.id)
                ) {
                    await categoryDelete({
                        variables: {
                            input: {
                                restaurantId,
                                menuId: category.id,
                            },
                        },

                        onCompleted: () => (notify = true),
                        onError: graphqlErrorHandler(true),
                    });
                }
            }
            notify && toast.dark('Dane restauracji zostały zaktualizowane');
            setWasChanges(false);
            refetch();
        } catch (err) {
            toast.warning(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return {
        restaurantName: data?.restaurant?.name,

        loading,
        error,
        isLoading,

        menuSections,
        wasChanges,
        setWasChanges,
        isNewSection,
        activeSectionId,
        isAddProductsOpen,
        singleSectionProducts,

        setMenuSections,
        setSingleSectionProducts,

        handleAddProductsOpen,
        handleAddProductsClose,
        onAddSection,
        onDeleteSection,
        onDeleteProduct,
        onNameChange,
        onSaveAddedProducts,
        onSave,
    };
};
