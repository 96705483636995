import { ProductStatusWithIcon } from '@/componentsV2/atoms/ProductStatus/ProductStatusWithIcon';
import {
    PRODUCT_LABEL,
    PRODUCT_LABEL_ORDER,
} from '@constants/fromGraphql/ProductLabel.constans';
import { ProductLabel } from '@graphql/generated';
import styled from '@emotion/styled';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { colors, s } from '@style/index';
import { css } from '@emotion/core';

export interface ProductStatusSelectProps {
    darkFont?: boolean;
    disabled?: boolean;
    lighter?: boolean;
    loading: boolean;
    onChange: (value: ProductLabel) => void;
    showNoSelectableValue?: boolean;
    value: ProductLabel;
}

export const ProductStatusSelect = ({
    darkFont,
    disabled,
    lighter,
    loading,
    onChange,
    showNoSelectableValue,
    value,
}: ProductStatusSelectProps) => {
    const handleStatusChange = (
        event: React.ChangeEvent<{ value: unknown }>,
    ) => {
        const selectedStatus = event.target.value as ProductLabel;
        onChange(selectedStatus);
    };

    return (
        <StatusSelectWrapper disabled={disabled || loading || lighter}>
            <StatusSelect
                name="status"
                value={value}
                onChange={handleStatusChange}
                status={value}
                lighter={lighter}
                darkFont={darkFont}
            >
                {showNoSelectableValue && (
                    <MenuItem disabled value="">
                        <NonProductStatus>Wybierz status</NonProductStatus>
                    </MenuItem>
                )}
                {Object.values(PRODUCT_LABEL_ORDER).map((value) =>
                    value === 'NOTSET' ? (
                        <MenuItem
                            key={value}
                            value={value}
                            style={{ display: 'none' }}
                        >
                            <ProductStatusWithIcon
                                loading={loading}
                                value={value as ProductLabel}
                            />
                        </MenuItem>
                    ) : (
                        <MenuItem key={value} value={value}>
                            <ProductStatusWithIcon
                                loading={loading}
                                value={value as ProductLabel}
                            />
                        </MenuItem>
                    ),
                )}
            </StatusSelect>
        </StatusSelectWrapper>
    );
};

const StatusSelectWrapper = styled(FormControl)`
    justify-self: end;
    min-width: unset;
    width: max-content;
`;

const StatusSelect = styled(Select)<{
    status?: ProductLabel;
    lighter?: boolean;
    darkFont?: boolean;
}>(
    ({ status, lighter, darkFont }) => css`
        border-radius: 40px;
        color: ${darkFont ? colors.black : colors.white};
        background-color: ${!status
            ? colors.littleDipper
            : status && PRODUCT_LABEL[status].color};
        padding: 0 4px 0 8px;
        opacity: ${lighter ? '65%' : 'unset'};

        &:focus {
            background-color: ${colors.gray400};

            ${s.touch} {
                background-color: ${colors.gray400};
            }

            &.MuiInput-underline:before,
            &.MuiInput-underline:after {
                border: none;
            }
        }

        &:hover {
            &.MuiInput-underline:before,
            &.MuiInput-underline:after {
                border: none;
            }
        }

        &.MuiInput-underline:before,
        &.MuiInput-underline:after {
            border: none;
        }

        &.MuiInputBase-root .MuiSelect-icon {
            padding-right: 8px;
        }

        &.MuiInputBase-root .MuiSelect-select:focus {
            background-color: unset;
        }

        .MuiSelect-icon {
            color: inherit;
        }

        &.MuiInputBase-root.Mui-disabled {
            color: ${lighter ? colors.white : colors.gray600};
        }

        &.MuiMenuItem-root {
            color: pink;
        }
    `,
);

const NonProductStatus = styled.div`
    align-items: center;
    border-radius: 16px;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    gap: 4px;
    width: max-content;
`;
