import { useState } from 'react';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import styled from '@emotion/styled';
import { SplitPaymentUserProps } from '@/componentsV2/atoms/SplitPaymentUser/SplitPaymentUser';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { OrderItemProductPayoffFragment, User } from '@graphql/generated';
import { AssignOrderContent } from '@/EZC/domains/Orders/organisms/ProductTileSplitOrder/AssignOrderContent';
import { DetailsOrderContent } from '@/EZC/domains/Orders/organisms/ProductTileSplitOrder/DetailsOrderContent';
import { SingleProductItem } from './SingleProductItem';
import Loader from '@components/Spinner';
import { productHelpers } from '@helpers/product.helpers';
import { colors } from '@style/index';
import { uniqBy } from 'lodash';
import { css } from '@emotion/react';

export interface ProductTileSplitOrderProps {
    loading?: boolean;
    onCancel: (
        value: Record<User['id'], OrderItemProductPayoffFragment['id'][]>,
    ) => void;
    onSave: (
        value: Record<User['id'], OrderItemProductPayoffFragment['id'][]>,
    ) => void;
    showItemsOneByOne?: boolean;
    showSplitPayment?: boolean;
    /**
     * If you have the same poduct in items, combine them and add to this prop.
     * If you have only one item add single element `items={[item]}`
     */
    groupedItems?: OrderItemProductPayoffFragment[];
    users?: SplitPaymentUserProps[];
}

export const ProductTileSplitOrder = ({
    groupedItems = [],
    loading,
    onCancel,
    onSave,
    showItemsOneByOne,
    showSplitPayment,
    users,
}: ProductTileSplitOrderProps) => {
    const [open, setOpen] = useState(false);
    const [openItems, setOpenItems] = useState<string[]>([]);

    const firstItem = groupedItems[0];
    const product = firstItem.product;
    const quantity = groupedItems.length;

    /**
     * When there is quantity < 1 even if showItemsOneByOne is true we will display single mode
     */
    const oneByOneMode = showItemsOneByOne && quantity > 1;

    const [userItemRequests, setUserItemRequests] = useState<
        Record<User['id'], OrderItemProductPayoffFragment['id'][]>
    >(
        users?.reduce(
            (prevUsers, currentUser) => ({
                ...prevUsers,
                [currentUser.id]: [],
            }),
            {},
        ) ?? {},
    );

    const payoffRequests = uniqBy(
        groupedItems.reduce(
            (prevItems, currentItem) => {
                const payoffRequest = currentItem.payoffRequest;

                return payoffRequest
                    ? [payoffRequest, ...prevItems]
                    : prevItems;
            },
            [] as OrderItemProductPayoffFragment['payoffRequest'][],
        ),
        'id',
    );

    const assignedOrders = Object.values(userItemRequests).reduce(
        (prevItems, currentItem) => [...prevItems, ...currentItem],
        [],
    );

    /**
     * Add `itemId` if it's single mode
     */
    const handleChange = ({
        assignedOrders,
        event,
        itemId,
        userId,
    }: {
        assignedOrders: string[];
        event: 'plus' | 'minus';
        itemId?: string;
        userId: string;
    }) => {
        const freeItemId =
            itemId ??
            groupedItems.filter((item) => !assignedOrders.includes(item.id))[0]
                ?.id;

        setUserItemRequests((prevState) => {
            return {
                ...Object.keys(prevState).reduce((prevUsers, key) => {
                    return {
                        ...prevUsers,
                        // @ts-expect-error FIXME
                        [key]: prevState[key].includes(itemId)
                            ? []
                            : prevState[key],
                    };
                }, {}),
                [userId]:
                    event === 'minus'
                        ? prevState[userId].slice(0, -1)
                        : [...prevState[userId], freeItemId],
            };
        });
    };

    /**
     * Add `itemId` if it's single mode
     */
    const handleReset = (itemId?: string) => {
        onCancel(userItemRequests);

        if (itemId) {
            setOpenItems((prevState) =>
                prevState.filter((openItem) => openItem !== itemId),
            );

            setUserItemRequests((prevState) =>
                Object.fromEntries(
                    Object.entries(prevState).map(([key, value]) => [
                        key,
                        value.includes(itemId) ? [] : value,
                    ]),
                ),
            );
        } else {
            setOpen(false);

            setUserItemRequests((prevState) =>
                Object.fromEntries(
                    Object.entries(prevState).map(([key]) => [key, []]),
                ),
            );
        }
    };

    /**
     * Add `itemId` if it's single mode
     */
    const handleSave = (itemId?: string) => {
        onSave(userItemRequests);

        if (itemId) {
            setOpenItems((prevState) =>
                prevState.filter((openItem) => openItem !== itemId),
            );
        } else {
            setOpen(false);
        }
    };

    const activeItems = groupedItems.filter(
        (item) => item.label !== 'CANCELED',
    );

    return (
        <Accordion
            headerElement={
                <ProductTile
                    average={product.rating}
                    categories={productHelpers.getCategories(product)}
                    imageUrl={product.photoUrl ?? ''}
                    name={product.name}
                    price={
                        activeItems.length !== 0
                            ? product?.price * activeItems.length
                            : 0
                    }
                    ratings={product.ratingsCount}
                    productLabel={
                        showItemsOneByOne && !oneByOneMode
                            ? firstItem.label
                            : undefined
                    }
                    // @ts-expect-error FIXME
                    payoffRequests={payoffRequests}
                    quantity={activeItems.length}
                    productPrice={product.price}
                    //TODO it is temporary solution EAT-590
                    height="174px"
                    splitPayment={
                        showSplitPayment && users?.length && !oneByOneMode
                            ? () => setOpen((prevState) => !prevState)
                            : undefined
                    }
                    onClick={
                        oneByOneMode
                            ? () => setOpen((prevState) => !prevState)
                            : undefined
                    }
                />
            }
            headerPadding={'0'}
            isOpen={open}
            preventCollapse
            removeExpandIcon={!oneByOneMode}
            iconPosition="bottom"
        >
            <ProductTileSplitOrderContent>
                {loading ? (
                    <LoaderWrapper>
                        {/* @ts-expect-error migrate to TS */}
                        <Loader size={2} />
                    </LoaderWrapper>
                ) : oneByOneMode ? (
                    <List>
                        {groupedItems?.map((item, index) => {
                            const payoffRequest = item.payoffRequest;

                            return (
                                <Accordion
                                    key={item.id}
                                    headerElement={
                                        <SingleProductItem
                                            index={index}
                                            item={item}
                                            splitPayment={
                                                showSplitPayment &&
                                                users?.length &&
                                                item.label !== 'CANCELED'
                                                    ? (id) => {
                                                          setOpenItems(
                                                              (prevState) => {
                                                                  if (
                                                                      prevState.includes(
                                                                          id,
                                                                      )
                                                                  )
                                                                      return prevState.filter(
                                                                          (
                                                                              openItem,
                                                                          ) =>
                                                                              openItem !==
                                                                              id,
                                                                      );

                                                                  return [
                                                                      ...prevState,
                                                                      id,
                                                                  ];
                                                              },
                                                          );
                                                      }
                                                    : undefined
                                            }
                                        />
                                    }
                                    headerPadding={'0'}
                                    collapsedPadding={'24px 0 0'}
                                    isOpen={openItems.includes(item.id)}
                                    preventCollapse
                                    removeExpandIcon
                                    removeTopDivider
                                    borderRadius={0}
                                >
                                    {payoffRequest ? (
                                        <DetailsOrderContent
                                            payoffRequests={
                                                payoffRequest
                                                    ? [payoffRequest]
                                                    : []
                                            }
                                            users={users}
                                            productId={product.id}
                                        />
                                    ) : (
                                        <AssignOrderContent
                                            assignedOrders={assignedOrders}
                                            onChange={(event, userId) => {
                                                handleChange({
                                                    event,
                                                    itemId: item.id,
                                                    userId,
                                                    assignedOrders,
                                                });
                                            }}
                                            onReset={() => handleReset(item.id)}
                                            onSave={() => handleSave(item.id)}
                                            users={users}
                                            quantity={1}
                                            userRequests={userItemRequests}
                                            itemId={item.id}
                                        />
                                    )}
                                    {groupedItems.length !== index + 1 && (
                                        <AccordionDivider
                                            marginTop={!payoffRequest}
                                        />
                                    )}
                                </Accordion>
                            );
                        })}
                    </List>
                ) : payoffRequests?.length ? (
                    <DetailsOrderContent
                        payoffRequests={payoffRequests}
                        users={users}
                        productId={product.id}
                    />
                ) : (
                    <AssignOrderContent
                        assignedOrders={assignedOrders}
                        onChange={(event, userId) => {
                            handleChange({
                                event,
                                userId,
                                itemId: quantity > 1 ? undefined : firstItem.id,
                                assignedOrders,
                            });
                        }}
                        onReset={() => handleReset()}
                        onSave={() => handleSave()}
                        users={users}
                        quantity={quantity}
                        userRequests={userItemRequests}
                        itemId={firstItem.id}
                    />
                )}
            </ProductTileSplitOrderContent>
        </Accordion>
    );
};

const ProductTileSplitOrderContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const LoaderWrapper = styled.div`
    margin: 0 auto;
`;
const List = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const AccordionDivider = styled.div<{
    marginTop?: boolean;
}>(
    ({ marginTop }) => css`
        background-color: ${colors.gray400};
        height: 1px;
        margin-bottom: 16px;
        margin-top: ${marginTop ? 16 : 0}px;
        width: 100%;
    `,
);
