import { Switch as MuiSwitch, SwitchProps } from '@material-ui/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';

export const Switch = (props: SwitchProps) => (
    <SwitchElement
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
    />
);

const SwitchElement = styled(MuiSwitch)`
    width: 36px;
    height: 20px;
    padding: 0px;

    & .MuiSwitch-switchBase {
        padding: 0px;
        margin: 2px;
        transition-duration: 300ms;

        &.Mui-checked {
            transform: translateX(16px);
            color: ${colors.white};

            & + .MuiSwitch-track {
                background-color: ${colors.green};
                opacity: 1;
                border: 0;
            }
            &.Mui-disabled + .MuiSwitch-track {
                opacity: 0.5;
            }
        }
        &.Mui-focusVisible .MuiSwitch-thumb {
            color: ${colors.green};
            border: 6px solid ${colors.white};
        }
        &.Mui-disabled .MuiSwitch-thumb {
            color: ${colors.gray600};
        }
        &.Mui-disabled + .MuiSwitch-track {
            opacity: 0.7;
        }
    }
    & .MuiSwitch-thumb {
        box-sizing: border-box;
        width: 16px;
        height: 16px;
    }
    & .MuiSwitch-track {
        border-radius: 13px;
        background-color: ${colors.gray500};
        opacity: 1;
    }
`;
