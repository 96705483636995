import EzAdminChangeRestaurantOwner from './change-restaurant-owner/ezadmin-change-restaurant-owner.page';
import EzAdminChargeRestaurant from './charge-restaurant/ezadmin-charge-restaurant.page';
import EzAdminChat from './chat/ezadmin-chat.page';
import EzAdminContainer from './_container/ezadmin-container';
import { EZAdminIngredientsPage } from 'src/EZAdmin/domains/Ingredients/pages/EZAdminIngredientsPage';
import EzAdminMakeRestaurateur from './make-restaurateur/ezadmin-make-restaurateur.page';
import EzAdminTransactionsByRestaurants from './transactions-by-restaurants/ezadmin-transactions-by-restaurants.page';

import { ezAdminRoutePrefix } from './settings';
import { EzAdminExportRestaurantPage } from './export-restaurant/EzAdminExportRestaurantPage';
import { EzAdminSendNotification } from './notifications/EzAdmin-send-notification.page';
import { ChangeRestaurantBrochure } from '../domains/Ingredients/pages/ChangeRestaurantBrochure/charge-restaurant/ChangeRestaurantBrochure';

const q = (route) => ezAdminRoutePrefix.concat(route);

const ezAdminRoutes = [
    {
        path: q('/change-restaurant-owner'),
        component: EzAdminChangeRestaurantOwner,
    },
    {
        path: q('/charge-restaurant'),
        component: EzAdminChargeRestaurant,
    },
    {
        path: q('/brochure-restaurants'),
        component: ChangeRestaurantBrochure,
    },
    {
        path: q('/export-restaurant'),
        component: EzAdminExportRestaurantPage,
    },
    {
        path: q('/chat'),
        component: EzAdminChat,
    },
    {
        path: q('/make-restaurateur'),
        component: EzAdminMakeRestaurateur,
    },
    {
        path: q('/transactions-by-restaurants'),
        component: EzAdminTransactionsByRestaurants,
    },
    {
        path: q('/ingredients'),
        component: EZAdminIngredientsPage,
    },
    {
        path: q('/notifications'),
        component: EzAdminSendNotification,
    },
];

export { ezAdminRoutePrefix, ezAdminRoutes, EzAdminContainer };
