import { Cuisine } from '@/graphql/generated';

export const CUISINE_ENUM: Record<Cuisine, Cuisine> = {
    AMERICAN: 'AMERICAN',
    ASIAN: 'ASIAN',
    EUROPEAN: 'EUROPEAN',
    ITALIAN: 'ITALIAN',
    POLISH: 'POLISH',
    FUSION: 'FUSION',
    HOME: 'HOME',
    MILITARY: 'MILITARY',
    MOLECULAR: 'MOLECULAR',
    VEGETARIAN: 'VEGETARIAN',
    CHINESE: 'CHINESE',
    FRENCH: 'FRENCH',
    GEORGIAN: 'GEORGIAN',
    GREEK: 'GREEK',
    INDIAN: 'INDIAN',
    JAPANESE: 'JAPANESE',
    MEDITERRANEAN: 'MEDITERRANEAN',
    MEXICAN: 'MEXICAN',
    SPANISH: 'SPANISH',
    THAI: 'THAI',
    TURKISH: 'TURKISH',
    UKRAINIAN: 'UKRAINIAN',
    VEGAN: 'VEGAN',
    LITHUANIAN: 'LITHUANIAN',
    BELGIAN: 'BELGIAN',
};

export const CUISINE: Record<Cuisine, { name: string }> = {
    AMERICAN: { name: 'Amerykańska' },
    ASIAN: { name: 'Azjatycka' },
    EUROPEAN: { name: 'Europejska' },
    FUSION: { name: 'Fusion' },
    HOME: { name: 'Domowa' },
    ITALIAN: { name: 'Włoska' },
    MILITARY: { name: 'Wojskowa' },
    MOLECULAR: { name: 'Molekularna' },
    POLISH: { name: 'Polska' },
    VEGETARIAN: { name: 'Wegetariańska' },
    CHINESE: {
        name: 'Chińska',
    },
    FRENCH: {
        name: 'Francuska',
    },
    GEORGIAN: {
        name: 'Gruzińska',
    },
    GREEK: {
        name: 'Grecka',
    },
    INDIAN: {
        name: 'Indyjska',
    },
    JAPANESE: {
        name: 'Japońska',
    },
    MEDITERRANEAN: {
        name: 'Śródziemnomorska',
    },
    MEXICAN: {
        name: 'Meksykańska',
    },
    SPANISH: {
        name: 'Hiszpańska',
    },
    THAI: {
        name: 'Tajska',
    },
    TURKISH: {
        name: 'Turecka',
    },
    UKRAINIAN: {
        name: 'Ukraińska',
    },
    VEGAN: {
        name: 'Wegańska',
    },
    LITHUANIAN: {
        name: 'Litewska',
    },
    BELGIAN: { name: 'Belgijska' },
};

export const CUISINE_NAME: Partial<Record<Cuisine, string>> = Object.entries(
    CUISINE,
).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [key]: value.name }),
    {},
);

export const CUISINE_NAME_VALUES = Object.entries(CUISINE_NAME).map(
    ([id, name]) => ({ id, name }),
) as { id: Cuisine; name: string }[];

export const CUISINE_KEYS = Object.keys(CUISINE) as Cuisine[];
