import { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import Skeleton from '@material-ui/lab/Skeleton';

import Carousel from '@/EZC/components/Carousel/Carousel.component';
import { CarouselItemList } from '@/EZC/components/CarouselItem';

import { s, globals, colors } from '@style';
import {
    useProdCarouselLazyQuery,
    useUserPreferencesTabsQuery,
} from '@graphql/generated';
import { ProdItemMiniV2 } from '@/EZC/components/ProdItem/ProdItemMiniV2.component';
import { useShowMorePagination } from '@graphql/hooks/useShowMorePagination';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';
import { useCarouselProductTileWidth } from '@hooks/useCarouselProductTileWidth';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';

const ProductsCarousel = ({ payloadKey, tabs }) => {
    const {
        consumptionTypes,
        distance,
        geolocation,
        applyUserPreferences,
        showBrochures,
    } = useUserSettingsStoreShallow([
        'consumptionTypes',
        'distance',
        'geolocation',
        'applyUserPreferences',
        'showBrochures',
    ]);

    const { localizedBannerEnabled, restaurantsForLocalizedHomepage } =
        useServerConfigStoreShallow([
            'restaurantsForLocalizedHomepage',
            'localizedBannerEnabled',
        ]);

    const {
        data: { user: { preferences } = {} } = {},
        loading: preferencesLoading,
    } = useUserPreferencesTabsQuery({
        onError: graphqlErrorHandler(true),
        skip: !applyUserPreferences,
    });

    const notPreferredTabs = [
        ...(preferences?.dish?.notPreffered ?? []),
        ...(preferences?.cuisine?.notPreffered ?? []),
    ];

    const [categoryId, setCategoryId] = useState(tabs[0]._id);

    useEffect(() => {
        const foundTab = tabs.find(
            (tab) => !notPreferredTabs.includes(tab._id),
        );
        setCategoryId(foundTab ? foundTab._id : null);
    }, [preferences]);

    const [getProducts, { data, error, loading }] = useProdCarouselLazyQuery();

    const { getMoreData, hasMore } = useShowMorePagination({
        callback: (pagination, input) =>
            getProducts({
                fetchPolicy: 'cache-and-network',
                variables: {
                    input,
                    pagination,
                },
                onError: graphqlErrorHandler(true),
            }),
        metadata: data?.productList?.metadata,
        pageSize: 20,
        input: {
            payload: {
                [payloadKey]: [categoryId],
                notPreferableDishes:
                    payloadKey === 'preferableMealTimes'
                        ? ['HOT_DRINK', 'DRINK', 'COLD_DRINK', 'ALCOHOL']
                        : [],
                applyUserPreferences,
                location: {
                    coordinates: [geolocation.lng, geolocation.lat],
                },
                consumptionTypes,
                maxDistance:
                    (parseInt(distance ?? '') || 0) * 1000 || undefined,
                isBrochure: showBrochures,
            },
            restaurantIds: localizedBannerEnabled
                ? restaurantsForLocalizedHomepage
                : undefined,
        },
    });

    const categoryData = tabs
        .filter((el) => {
            return !notPreferredTabs.includes(el._id);
        })
        .map((el) => ({
            ...el,
            name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
        }));

    if ((!data && !loading) || !categoryData.length) return null;

    return (
        <div css={styles}>
            <div
                css={{
                    maxWidth: globals.maxWidthMedium,
                    width: '100%',
                    margin: '0 auto',
                }}
            >
                <Carousel
                    data={categoryData}
                    CarouselItem={CarouselItemList}
                    onClickHandler={setCategoryId}
                    categoryId={categoryId}
                    shouldHandleScrollChange={false}
                />
            </div>

            <div
                css={{
                    maxWidth: globals.maxWidthMediumCarousel,
                    margin: '0 auto',
                }}
            >
                <ProductsByCategoryId
                    data={data}
                    categoryId={categoryId}
                    loading={loading || preferencesLoading}
                    error={error}
                    getMoreData={getMoreData}
                    hasMore={hasMore}
                />
            </div>
        </div>
    );
};

const ProductsByCategoryId = ({
    categoryId,
    data,
    loading,
    getMoreData,
    hasMore,
}) => {
    const list = data?.productList?.data;
    const productItemWidth = useCarouselProductTileWidth();

    if (loading && !hasMore)
        return (
            <div
                css={{
                    display: 'flex',
                    overflowX: 'scroll',
                    width: '100%',
                    span: { marginLeft: 12, borderRadius: 12, flexShrink: 0 },
                    paddingBottom: '1.5rem',
                    [s.md]: { paddingBottom: '2rem' },
                }}
            >
                {new Array(10).fill(0).map((e, id) => (
                    <Skeleton
                        variant="rect"
                        key={id}
                        css={[
                            id === 0 && {
                                [s.sm_down]: {
                                    marginLeft: '1.5rem !important',
                                },
                            },
                            {
                                width: productItemWidth,
                                height: 196,
                                backgroundColor: colors.gray200,
                            },
                        ]}
                    />
                ))}
            </div>
        );

    if (list?.length === 0)
        return (
            <p css={{ opacity: '.3', margin: '24px', fontWeight: 700 }}>
                Produkty w wybranej kategorii nie spełniają kryteriów
                filtrowania.
            </p>
        );

    return (
        <Carousel
            scrollButtons={182 * 3}
            data={list}
            CarouselItem={ProdItemMiniV2}
            link="/product/"
            getMoreData={getMoreData}
            hasMore={hasMore}
        />
    );
};

export default ProductsCarousel;

const styles = {
    margin: '16px 0',

    [s.md]: {
        margin: '24px 0',
    },
};
