import { useEffect } from 'react';
import {
    SEARCH_FILTER_COUNTABLE_KEYS,
    SearchFilterFormSchemaValues,
} from '../../SearchFilterFormProvider/SearchFilterFormProvider';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { popup } from '@/AppPopups';
import { SearchFilterPopupProps } from '../../organisms/SearchFilterPopup/SearchFilterPopup';
import { useServerConfigStoreShallow } from '@/services/ServerConfigService/ServerConfigStore';
import { useSearchQueries } from '@hooks/useSearchQueries';

export const useSearchPage = () => {
    const { search } = useLocation();
    const { initialized, localizedBannerEnabled } = useServerConfigStoreShallow(
        ['initialized', 'localizedBannerEnabled'],
    );
    const searchQueries = useSearchQueries();

    const methods = useFormContext<SearchFilterFormSchemaValues>();

    useEffect(() => {
        initialized && searchQueries.getData({ initialPage: true });
    }, [search, localizedBannerEnabled, initialized]);

    return {
        ...searchQueries,
        filtersCount: methods
            .getValues(SEARCH_FILTER_COUNTABLE_KEYS)
            .flat()
            .filter((value) => !!value).length,
        handleOpenModal: () => {
            popup.show<SearchFilterPopupProps>('SearchFilterPopup', {
                methods,
            });
        },
    };
};
