import styled from '@emotion/styled';
import { colors } from '@style/index';
import { Avatar } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';

interface SearchListItemProps extends LinkProps {
    image?: string | null;
    name: string;
    price?: number;
}

export const SearchListItem = ({
    image,
    name,
    price,
    ...rest
}: SearchListItemProps) => {
    return (
        <Link {...rest}>
            <ListItem>
                <ItemImage alt={name} src={image ?? ''}>
                    {!image && name.slice(0, 1)}
                </ItemImage>

                <ItemName>{name}</ItemName>
                {price && (
                    <PriceWrapper>
                        {price?.toFixed(2)} <Currency>zł</Currency>{' '}
                    </PriceWrapper>
                )}
            </ListItem>
        </Link>
    );
};

const ListItem = styled.div`
    align-items: center;
    background-color: ${colors.gray300};
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    padding: 8px;
    font-size: 11px;
    font-weight: 700;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: ${colors.gray400};
    }
`;

const ItemImage = styled(Avatar)`
    height: 32px;
    font-weight: 400;
    margin-right: 8px;
    width: 32px;
`;

const ItemName = styled.p`
    flex-grow: 1;
`;

const PriceWrapper = styled.span`
    white-space: nowrap;
`;

const Currency = styled.span`
    opacity: 0.3;
`;
