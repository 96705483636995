import { useAuthService } from '@/services/AuthService';
import { AnalyticsHelpers } from '@helpers/analytics.helpers';
import { useEffect } from 'react';

export const useTransactionsGa = () => {
    const isUserLoggedIn = useAuthService((state) => state.isLoggedIn);

    useEffect(() => {
        let isPageReloaded = false;

        if (window.performance && performance.navigation.type == 1) {
            isPageReloaded = true;
        }

        if (
            window.location.href.indexOf('paymentStatus=CONFIRMED') > -1 &&
            !isPageReloaded &&
            isUserLoggedIn
        ) {
            AnalyticsHelpers.createCustomEvent({
                name: 'new_payment',
                params: { type: 'payment' },
            });
        }
    }, []);
};
