import React, { useState, useContext, createContext } from 'react';
import { toast } from 'react-toastify';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { resolveEnv } from '@helpers/resolveEnv';
import { useUserSettingsStoreShallow } from '@/EZC/services/UserSettingsStore';

export const GeolocationContext = createContext({
    geolocation: { lat: 0, lng: 0, address: '' },
    handleLocation: () => {},
    loading: false,
    setGeolocation: () => {},
});

export const GeolocationProvider = ({ children }) => {
    const { geolocation, updateUserSettings } = useUserSettingsStoreShallow([
        'geolocation',
        'updateUserSettings',
    ]);

    const setGeolocation = (value) => {
        updateUserSettings({ geolocation: value });
    };

    const [loading, setLoading] = useState(false);

    async function showPosition(position, customOnChange) {
        const location = {
            lng: position.coords.longitude,
            lat: position.coords.latitude,
        };

        let address = geolocation.address;
        try {
            const addressResponse = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
                    location.lat
                },${location.lng}&key=${resolveEnv(
                    'REACT_APP_GOOGLE_API_KEY',
                )}`,
            );
            const res = await addressResponse.json();
            address = res.results[0]?.formatted_address;
        } catch (error) {
            setLoading(false);
            toast.error('Wystąpił problem z pozyskaniem lokalizacji');
            console.error(error);
        }

        !!customOnChange
            ? customOnChange({ ...location, address })
            : setGeolocation({ ...location, address });

        setLoading(false);
    }

    const handleWebLocation = (showError = false, customOnChange) => {
        if (navigator.geolocation) {
            try {
                if (showError)
                    navigator.geolocation.getCurrentPosition(
                        (position) => showPosition(position, customOnChange),
                        showPositionError,
                    );
                else
                    navigator.geolocation.getCurrentPosition((position) =>
                        showPosition(position, customOnChange),
                    );
            } catch (error) {
                setLoading(false);
                toast.error(error.message);
            }
        } else {
            toast.error('Geolokalizacja nie jest wspierana przez przeglądarkę');
        }

        async function showPositionError(response) {
            let errorMessage = '';
            switch (response.code) {
                case 1:
                    errorMessage = 'Zezwól na udostępnianie lokalizacji';
                    break;
                case 2:
                    errorMessage = 'Pozyskanie lokalizacji jest niemożliwe';
                    break;
                case 3:
                    errorMessage = 'Pobieranie lokalizacji trwa zbyt długo';
                    break;
                default:
                    errorMessage = response.message || 'Wystąpił nieznany błąd';
                    break;
            }
            setLoading(false);
            return toast.error(errorMessage);
        }
    };

    const handleNativeLocation = (customOnChange) => {
        Geolocation.getCurrentPosition({
            enableHighAccuracy: true,
        })
            .then((coordinates) => {
                showPosition(coordinates, customOnChange);
            })
            .catch(() => {
                toast.error('Wystąpił problem z pozyskaniem lokalizacji');
            })
            .finally(() => setLoading(false));
    };

    const handleLocation = (showError = false, customOnChange) => {
        setLoading(true);

        if (Capacitor.isNativePlatform()) {
            handleNativeLocation(customOnChange);
        } else {
            handleWebLocation(showError, customOnChange);
        }
    };

    return (
        <GeolocationContext.Provider
            value={{
                geolocation,
                handleLocation,
                loading,
                setGeolocation,
            }}
        >
            {children}
        </GeolocationContext.Provider>
    );
};

export const useGeolocationContext = () => useContext(GeolocationContext);
