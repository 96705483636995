import { ApolloError } from '@apollo/client';
import {
    GRAPHQL_ERROR_MESSAGES,
    GraphqlErrorCode,
} from '@constants/errors/graphqlErrorMessages';
import { TypeOptions, toast } from 'react-toastify';

interface ErrorNotifyOptions {
    /** Callback with additional action for specific error codes */
    callbackOnErrorCode?: Partial<Record<GraphqlErrorCode, () => void>>;
    /** Callback with additional action for click toast */
    callbackOnClickToast?: Partial<Record<GraphqlErrorCode, () => void>>;
    /** Custom messages for specific error codes */
    customCodeMessages?: Partial<Record<GraphqlErrorCode, string>>;
    /** Error codes you want to omit */
    omitErrorCodes?: GraphqlErrorCode[];
    /** Type of toast */
    type?: TypeOptions;
}

export const graphqlErrorHandler =
    (errorNotify: boolean | ErrorNotifyOptions) =>
    ({ graphQLErrors }: ApolloError) => {
        if (!errorNotify) return;

        const isDefaultNotify = errorNotify === true;

        const messages = isDefaultNotify
            ? GRAPHQL_ERROR_MESSAGES
            : { ...GRAPHQL_ERROR_MESSAGES, ...errorNotify?.customCodeMessages };

        graphQLErrors?.forEach((error) => {
            if (error?.extensions?.code === 'UNAUTHENTICATED') return;

            const code = error?.extensions?.code as GraphqlErrorCode;
            const message = messages[code] || code;

            if (isDefaultNotify) {
                toast(message, {
                    type: 'error',
                });

                return;
            }

            if (!errorNotify?.omitErrorCodes?.includes(code)) {
                toast(message, {
                    type: errorNotify?.type ?? 'error',
                    onClick: () => errorNotify.callbackOnClickToast?.[code]?.(),
                });
            }
            errorNotify.callbackOnErrorCode?.[code]?.();
        });
    };
