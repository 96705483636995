import { PartialPick } from '@/types/CustomUtilityTypes';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

interface KeyboardStoreState {
    keyboardHeight: number;
    setKeyboardHeight: (keyboardHeight: number) => void;
}

export const useKeyboardStore = create<KeyboardStoreState>()(
    devtools(
        (set) => ({
            keyboardHeight: 0,
            setKeyboardHeight: (keyboardHeight) => {
                set({
                    keyboardHeight,
                });
            },
        }),
        {
            name: 'keyboardStoreState',
        },
    ),
);

export const useKeyboardStoreShallow = <K extends keyof KeyboardStoreState>(
    keys: K[],
): PartialPick<KeyboardStoreState, K> =>
    useKeyboardStore(
        useShallow((state) =>
            keys.reduce(
                (prevObject, currentKey) => ({
                    ...prevObject,
                    [currentKey]: state[currentKey],
                }),
                {} as PartialPick<KeyboardStoreState, K>,
            ),
        ),
    );

export const getKeyboardStore = useKeyboardStore.getState;
