import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { filterKeysFromObject } from '@helpers/filterKeysFromObject';
import { ConsumptionType } from '@graphql/generated';
import { useShallow } from 'zustand/react/shallow';
import { PartialPick } from '@/types/CustomUtilityTypes';
import { GeolocationType } from '@/types/Geolocalization';
import { DEFAULTS_CONSTANTS } from '@constants/defaults.constants';

// TODO: prepare store for Next.js
// www.youtube.com/watch?v=BxohoXjbhKc

export interface UserSettingsStoreState {
    consumptionTypes: ConsumptionType[];
    distance: string | null;
    geolocation: GeolocationType;
    applyUserPreferences: boolean;
    showBrochures: boolean;
}

interface UserSettingsStore extends UserSettingsStoreState {
    updateUserSettings: (value: Partial<UserSettingsStoreState>) => void;
}

export const userSettingsStoreDeafultState: UserSettingsStoreState = {
    distance: DEFAULTS_CONSTANTS.distance,
    consumptionTypes: DEFAULTS_CONSTANTS.consumptionTypes,
    geolocation: DEFAULTS_CONSTANTS.geolocation,
    applyUserPreferences: false,
    showBrochures: false,
};

/**
 * For performance optimization, it is recommended to use `useUserSettingsStoreShallow` instead,
 * which returns a shallow copy of the state and prevents unnecessary re-renders.
 */
export const useUserSettingsStore = create<UserSettingsStore>()(
    devtools(
        persist(
            (set) => ({
                ...userSettingsStoreDeafultState,
                updateUserSettings: (value) => {
                    set((prevState) => ({ ...prevState, ...value }));
                },
            }),
            {
                name: 'userSettingsStore',
                version: 1,
                migrate(persistedState) {
                    const state = persistedState as
                        | UserSettingsStoreState
                        | undefined;

                    return {
                        ...state,
                        distance:
                            state?.distance ||
                            userSettingsStoreDeafultState.distance,
                    };
                },
                partialize: (state) => filterKeysFromObject(state, []),
            },
        ),
    ),
);

/**
 * A hook that returns a shallow copy of the UserSettingsStore state.
 * Shallow copies are used to optimize performance by preventing unnecessary re-renders in Zustand.
 *
 * @param keys - An array of keys to be included in the returned state.
 * @returns - A shallow copy of the UserSettingsStore state that only includes the specified keys.
 */
export const useUserSettingsStoreShallow = <K extends keyof UserSettingsStore>(
    keys: K[],
): PartialPick<UserSettingsStore, K> =>
    useUserSettingsStore(
        useShallow((state) =>
            keys.reduce(
                (prevObject, currentKey) => ({
                    ...prevObject,
                    [currentKey]: state[currentKey],
                }),
                {} as PartialPick<UserSettingsStore, K>,
            ),
        ),
    );

/**
 * A utility function to get the current state of the UserSettingsStore.
 * This function is particularly useful when you need to access the state outside of a React component.
 *
 * @returns - The current state of the UserSettingsStore.
 */
export const getUserSettingsStore = useUserSettingsStore.getState;
