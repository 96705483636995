import React from 'react';
import { Grid, Collapse as MaterialCollapse } from '@material-ui/core';
import styled from '@emotion/styled';
import { colors } from '@style/index';
import {
    UserFriendInfo,
    UserFriendInfoProps,
} from '@/EZC/domains/Account/molecules/UserFriendInfo/UserFriendInfo';
import { Controller, useForm } from 'react-hook-form';
import { SearchInput } from '@/componentsV2/molecules/SearchInput/SearchInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';
import { useUserFindUsersLazyQuery } from '@graphql/generated';
import Loader from '@components/Spinner';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { css } from '@emotion/core';

export interface FindAndAddUserProps {
    onClick: (value: UserFriendInfoProps) => void;
    open: boolean;
    setOpen: (value: boolean) => void;
    buttonText: string;
    placeholder: string;
    notFoundElement: (email?: string) => React.ReactNode;
    title?: string;
    description?: string;
    backgroundColor?: string;
    waiter?: boolean;
}

export const FindAndAddUser = ({
    open,
    onClick,
    setOpen,
    buttonText,
    placeholder,
    notFoundElement,
    title,
    description,
    backgroundColor,
    waiter,
}: FindAndAddUserProps) => {
    const { control, handleSubmit, getValues } = useForm<EmailSchemaValues>({
        resolver: yupResolver(EmailSchema),
        defaultValues: {
            email: '',
        },
    });

    const [findUsers, { data, loading }] = useUserFindUsersLazyQuery({
        context: { waiter },
    });

    const handleFormSubmit = handleSubmit(
        (value) => {
            findUsers({
                variables: {
                    input: {
                        query: {
                            email: value.email,
                        },
                    },
                },
                onCompleted: () => setOpen(true),
                onError: graphqlErrorHandler(true),
            });
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );

    const userData = data?.userFindUsers?.data[0];
    const userInfo: UserFriendInfoProps = {
        name:
            userData?.personal?.name + ' ' + userData?.personal?.surname ?? '',
        id: userData?.id ?? '',
        avatar: userData?.personal?.avatarUrl ?? '',
        email: userData?.email ?? '',
    };

    return (
        <>
            <Grid item xs={12}>
                {title && <Text>{title}</Text>}
                <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <SearchInput
                            onReset={() => setOpen(false)}
                            onChange={(value) => {
                                onChange(value);
                                setOpen(false);
                            }}
                            onSubmit={() => handleFormSubmit()}
                            placeholder={placeholder}
                            value={value}
                            variant={'default'}
                        />
                    )}
                />
                {open && (
                    <Collapse in={open} backgroundColor={backgroundColor}>
                        {userData?.id ? (
                            <>
                                <UserFriendInfo {...userInfo} />
                                <ButtonElement
                                    variant="black"
                                    onClick={() => onClick({ ...userInfo })}
                                    loading={loading}
                                    width="100%"
                                >
                                    {buttonText}
                                </ButtonElement>
                            </>
                        ) : (
                            <>{notFoundElement(getValues('email'))}</>
                        )}
                    </Collapse>
                )}
                {loading && (
                    <SpinnerWrapper>
                        {/* @ts-expect-error migrate to TS */}
                        <Loader size={2} />
                    </SpinnerWrapper>
                )}
            </Grid>
            {description && <Description>{description}</Description>}
        </>
    );
};

const EmailSchema = yup.object({
    email: yup
        .string()
        .trim()
        .email(
            'Nieprawidłowy adres e-mail. Poprawny format to: nazwa@example.com',
        )
        .required(
            'Nieprawidłowy adres e-mail. Poprawny format to: nazwa@example.com',
        ),
});

export interface EmailSchemaValues extends yup.InferType<typeof EmailSchema> {}

const Collapse = styled(MaterialCollapse)<{ backgroundColor?: string }>(
    ({ backgroundColor }) => css`
        background: ${backgroundColor ? backgroundColor : colors.gray100};
        border-radius: 0 0 24px 24px;
        padding: 24px;
        position: relative;

        &:before {
            background: ${backgroundColor ? backgroundColor : colors.gray100};
            border-radius: 30px 30px 0 0;
            content: '';
            height: 52px;
            left: 0;
            position: absolute;
            top: -52px;
            width: 100%;
        }

        .MuiCollapse-wrapperInner {
            display: grid;
            grid-gap: 24px;
        }
    `,
);

const Description = styled.p`
    color: ${colors.dust};
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    margin-top: 16px;
`;

const ButtonElement = styled(Button)`
    padding: 0 20px;
`;

const Text = styled.p`
    color: ${colors.text};
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 16px;
`;

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 24px;
    height: 60px;
    width: 100%;
`;
