/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Collapsable, Row } from '../../../../components/Elements';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import StorageIcon from '@material-ui/icons/Storage';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import { s } from '../../../../style';

const OrderID = ({ shortId, orderId }) => {
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                noPadding
                oneLiner
                title={'Identyfikator'}
                icon={<FingerprintIcon />}
                description={<>{shortId}</>}
            >
                <Row
                    desc={'ID Numer Zamówienia'}
                    icon={<RestaurantMenuIcon fontSize="inherit" />}
                >
                    {shortId}
                </Row>
            </Collapsable>
        </Grid>
    );
};

export default OrderID;
