import { ApolloClient, ApolloLink, from } from '@apollo/client';
import { cache, localTypeDefs } from '@graphql/cache';
import { createRefreshLink } from '@/links/createRefreshLink';
import {
    fetchNewUserToken,
    fetchNewWaiterToken,
} from '@graphql/fetchNewSessionToken';
import {
    authLink,
    directionalLink,
    errorSentryLink,
} from '@graphql/apollo/links';
import { useAuthService } from '@/services/AuthService';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { sentry } from '@/services/SentryService';

function omitTypename(key, value) {
    return key === '__typename' ? undefined : value;
}

const createOmitTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = JSON.parse(
            JSON.stringify(operation.variables),
            omitTypename,
        );
    }

    return forward(operation);
});

export const client = new ApolloClient({
    cache,
    typeDefs: localTypeDefs,
    link: from([
        errorSentryLink,
        createRefreshLink({
            fetchNewSessionToken: fetchNewUserToken,
            getRefreshToken: () => useAuthService.getState().refreshToken,
            getSessionToken: () => useAuthService.getState().sessionToken,
            onRefreshError: () => {
                useAuthService.getState().logout(client, sentry);
            },
            onTokenExpiredNoRefresh: () => {
                useAuthService.getState().logout(client, sentry);
            },
            setContextCodition: (context) => !context.waiter,
        }),
        createRefreshLink({
            fetchNewSessionToken: fetchNewWaiterToken,
            getRefreshToken: () => useWaiterService.getState().refreshToken,
            getSessionToken: () => useWaiterService.getState().sessionToken,
            onRefreshError: () => {
                useWaiterService.getState().logout(client, sentry);
            },
            onTokenExpiredNoRefresh: () => {
                useWaiterService.getState().logout(client, sentry);
            },
            setContextCodition: (context) => context.waiter,
        }),
        createOmitTypenameLink,
        authLink,
        directionalLink,
    ]),
    connectToDevTools: true,
});
