/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useRef, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { useHistory } from 'react-router-dom';
import { s, globals, colors } from '../../../style';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Hidden, ButtonBase } from '@material-ui/core';
import ArrowR from '@material-ui/icons/ArrowForwardIos';

const Carousel = ({
    data,
    CarouselItem,
    carouselItemProps,
    link,
    onClickHandler,
    categoryId,
    scrollButtons = 0,
    getMoreData,
    hasMore,
    shouldHandleScrollChange = true,
}) => {
    const history = useHistory();
    const [mouseMoved, setMouseMoved] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    const refElement = useRef(null);
    const containerWidth = refElement?.current?.clientWidth;
    const totalScrollWidth = refElement?.current?.scrollWidth;

    const [lastItemVisible, setLastItemVisible] = useState(
        scrollLeft + containerWidth >= totalScrollWidth - scrollButtons,
    );

    const handleLinkClick = (id) => {
        if (!mouseMoved) link ? history.push(link + id) : onClickHandler(id);
    };

    const handleScrollChange = () => {
        const currentScrollLeft = refElement.current
            ? refElement.current.scrollLeft
            : 0;
        setScrollLeft(currentScrollLeft);
        setLastItemVisible(
            currentScrollLeft + containerWidth >=
                totalScrollWidth - scrollButtons,
        );
    };

    useEffect(() => {
        if (lastItemVisible && hasMore) {
            getMoreData();
        }
    }, [lastItemVisible]);

    const handleScrollRight = () => {
        refElement?.current?.scrollTo({
            left: scrollLeft - scrollButtons,
            behavior: 'smooth',
        });
    };

    const handleScrollLeft = () => {
        refElement?.current?.scrollTo({
            left: scrollLeft + scrollButtons,
            behavior: 'smooth',
        });
    };

    const renderCarousel = () => {
        return data?.map((el, id) => (
            <CarouselItem
                key={id}
                id={id}
                last={id === data?.length - 1 ? 1 : 0}
                onMouseMove={() => setMouseMoved(true)}
                onMouseDown={() => setMouseMoved(false)}
                onMouseUp={() => handleLinkClick(el?._id)}
                onClick={() => handleLinkClick(el?._id)}
                data={el}
                categoryId={categoryId}
                {...carouselItemProps}
            />
        ));
    };

    return (
        <>
            <Hidden xsDown>
                <div css={{ position: 'relative', display: 'grid' }}>
                    <ScrollContainer
                        css={[sContainer, { paddingBottom: '12px' }]}
                        innerRef={refElement}
                        onScroll={handleScrollChange}
                    >
                        {data?.map((el, id) => (
                            <CarouselItem
                                key={id}
                                id={id}
                                last={id === data?.length - 1 ? 1 : 0}
                                onMouseMove={() => setMouseMoved(true)}
                                onMouseDown={() => setMouseMoved(false)}
                                onMouseUp={() => handleLinkClick(el?._id)}
                                data={el}
                                categoryId={categoryId}
                                {...carouselItemProps}
                            />
                        ))}
                    </ScrollContainer>
                    {!!scrollButtons && (
                        <div
                            css={{
                                position: 'absolute',
                                top: -64,
                                right: 24,
                                display: 'flex',
                                [s.sm_down]: {
                                    top: -48,
                                },
                            }}
                        >
                            <ButtonBase
                                css={sButtonSmall}
                                onClick={() => handleScrollRight()}
                            >
                                <ArrowR
                                    fontSize="inherit"
                                    css={{ transform: 'rotate(180deg)' }}
                                />
                            </ButtonBase>
                            <span css={{ marginLeft: '0.5rem' }} />
                            <ButtonBase
                                css={sButtonSmall}
                                onClick={() => handleScrollLeft()}
                            >
                                <ArrowR fontSize="inherit" />
                            </ButtonBase>
                        </div>
                    )}
                </div>
            </Hidden>
            <Hidden smUp>
                <div css={[sContainer, { scrollSnapType: 'x mandatory' }]}>
                    {shouldHandleScrollChange ? (
                        <ScrollContainer
                            css={sContainer}
                            innerRef={refElement}
                            onScroll={handleScrollChange}
                        >
                            {renderCarousel()}
                        </ScrollContainer>
                    ) : (
                        <div css={[sContainer, { paddingBottom: '12px' }]}>
                            {renderCarousel()}
                        </div>
                    )}
                </div>
            </Hidden>
        </>
    );
};

const sContainer = {
    display: 'flex',
    width: '100%',
    // overflowX: 'scroll',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    alignItems: 'center',

    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    [s.touch]: { scrollSnapType: 'x mandatory' },

    // scrollBehavior: 'smooth',
    // WebkitOverflowScrolling: 'touch',
};

export const sButtonSmall = {
    fontSize: '15px',
    width: '2rem',
    height: '2rem',
    ...globals.center,
    backgroundColor: colors.gray200,
    color: colors.text,
    transition: 'color 0.3s ease-out, background-color 0.3s ease-out',
    borderRadius: '50%',

    '&:hover': {
        [s.hover]: {
            backgroundColor: colors.gray300,
        },
    },
};

export default Carousel;
