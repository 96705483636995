import { useEffect, useState } from 'react';
import { useFirebaseChatService } from '../FirebaseChatService';
import { ChatDocumentData, FirebaseEnv } from '../FirebaseChatService.types';
import {
    onSnapshot,
    doc,
    Unsubscribe,
    collection,
    query,
    where,
    updateDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import {
    useAdminChatCreateMutation,
    useAdminChatUserLazyQuery,
} from '@graphql/generated';
import { graphqlErrorHandler } from '../../GraphqlErrorService';
import { CLIENT_ERROR_MESSAGES } from '@constants/errors/clientErrorMessages';
import { useAuthService } from '../../AuthService';
import { FirebaseChatServiceHelpers } from '../FirebaseChatService.helpers';
import { FIREBASE_ENV } from '../FirebaseChatService.config';
import { useChatMessages } from './common';

interface ChatAdminActiveDocumentData extends ChatDocumentData {
    avatarUrl?: string | null;
    name?: string;
    surname?: string;
    unreadMessages: number;
}

export const useAdminActiveChats = () => {
    const env: FirebaseEnv = `env/${FIREBASE_ENV}/adminChats`;

    const { firestore, isLoggedIn, userId } = useFirebaseChatService();

    const [loading, setLoading] = useState<boolean>(true);
    const [activeChatId, setActiveChatId] = useState<string | undefined>(
        undefined,
    );
    const [chats, setChats] = useState<ChatAdminActiveDocumentData[]>([]);
    const activeChat = chats.find((chat) => chat.owner === activeChatId);

    const [getUser] = useAdminChatUserLazyQuery({
        onError: graphqlErrorHandler(false),
    });

    useEffect(() => {
        if (
            useAuthService.getState().user?.userType !== 'Admin' &&
            useAuthService.getState().user?.userType !== 'Support'
        ) {
            return;
        }

        let unsubscribe: Unsubscribe;

        if (firestore && isLoggedIn) {
            const collectionRef = collection(firestore, env);

            unsubscribe = onSnapshot(
                query(collectionRef, where('messages', '!=', [])),
                async (snapshot) => {
                    const results: ChatAdminActiveDocumentData[] =
                        await Promise.all(
                            snapshot.docs.map(
                                async (
                                    doc,
                                ): Promise<ChatAdminActiveDocumentData> => {
                                    const data = doc.data() as ChatDocumentData;

                                    const unreadMessages =
                                        FirebaseChatServiceHelpers.getUnreadMessages(
                                            data.messages,
                                            userId,
                                        );

                                    try {
                                        const { data: userData } =
                                            await getUser({
                                                variables: {
                                                    input: {
                                                        userId: data.owner,
                                                    },
                                                },
                                            });

                                        return {
                                            ...data,
                                            avatarUrl:
                                                userData?.user.personal
                                                    .avatarUrl,
                                            name: userData?.user.personal.name,
                                            surname:
                                                userData?.user.personal.surname,
                                            unreadMessages,
                                        };
                                    } catch {
                                        return { ...data, unreadMessages };
                                    }
                                },
                            ),
                        );

                    setChats(results.filter((item) => !item.finishedAt));
                    setLoading(false);
                },
                (error) => {
                    console.error('useAdminActiveChats : onSnapshot ::', error);

                    toast.error(error.message);
                },
            );
        }

        return () => {
            unsubscribe?.();
        };
    }, [firestore, isLoggedIn]);

    useEffect(() => {
        if ((!activeChat?.messages.length || !activeChatId) && !!chats.length)
            setActiveChatId(chats[0].owner);
    }, [activeChat, activeChatId, chats]);

    return {
        activeChat,
        chats,
        loading,
        setActiveChatId,
    };
};

export const useAdminChatFinish = () => {
    const { firestore } = useFirebaseChatService();
    const [loading, setLoading] = useState<boolean>(false);

    const finishChat = async (id: string) => {
        const env: FirebaseEnv = `env/${FIREBASE_ENV}/adminChats/${id}`;

        if (!firestore) return;

        setLoading(true);

        const documentRef = doc(firestore, env);

        try {
            await updateDoc(documentRef, {
                finishedAt: new Date(),
            });

            toast.warn('Czat został zamknięty');
        } catch (error) {
            console.error('useOrderChat : finishChat ::', error);

            toast.error(CLIENT_ERROR_MESSAGES.SOMETHING_WENT_WRONG);
        } finally {
            setLoading(false);
        }
    };

    return { loading, finishChat };
};

const useChatAdminCreate = () => {
    const [chatCreate, { called, loading }] = useAdminChatCreateMutation();

    useEffect(() => {
        chatCreate({
            onError: graphqlErrorHandler(false),
        });
    }, []);

    return { called, loading };
};

export const useAdminChatForUser = () => {
    const userId = useFirebaseChatService((state) => state.userId);
    const env: FirebaseEnv = `env/${FIREBASE_ENV}/adminChats/${userId}`;

    const { called: createCalled, loading: createLoading } =
        useChatAdminCreate();
    const { loading, messages } = useChatMessages(
        env,
        createCalled && !createLoading,
    );

    return { loading, messages };
};
