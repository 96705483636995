import styled from '@emotion/styled';
import { colors } from '@/style';
import { ProductStatusBadge } from '@/componentsV2/atoms/ProductStatusBadge/ProductStatusBadge';
import { SplitPaymentStatus } from '@/componentsV2/atoms/SplitPaymentStatus/SplitPaymentStatus';
import { OrderItemProductPayoffFragment } from '@graphql/generated';

interface SingleProductItemProps {
    item: OrderItemProductPayoffFragment;
    index: number;
    splitPayment?: (id: string) => void;
}

export const SingleProductItem = ({
    item,
    index,
    splitPayment,
}: SingleProductItemProps) => {
    const showStatus =
        item.payoffRequest?.status && item.payoffRequest?.status !== 'PENDING';
    const isPending = item.payoffRequest?.status === 'PENDING';
    const status = item.payoffRequest?.status;

    return (
        <Item>
            <ItemInner>
                <Text>
                    {index + 1}. {item.product.name}
                </Text>
                <ProductStatusBadge value={item.label} />
            </ItemInner>
            {!!splitPayment && !status && (
                <SplitPayment onClick={() => splitPayment(item.id)}>
                    Przydziel płatność
                </SplitPayment>
            )}
            {!!splitPayment && (status || isPending) && (
                <StatusDetails>
                    {status && showStatus && (
                        <SplitPaymentStatus status={status} />
                    )}
                    {isPending && <SplitPaymentStatus status={'PENDING'} />}
                    {status && (
                        <SplitPayment onClick={() => splitPayment(item.id)}>
                            Sprawdź szczegóły
                        </SplitPayment>
                    )}
                </StatusDetails>
            )}
        </Item>
    );
};

const Item = styled.li`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ItemInner = styled.li`
    align-items: flex-start;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    color: ${colors.gray600};
`;

const Text = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const SplitPayment = styled.span`
    color: ${colors.text};
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
`;

const StatusDetails = styled.p`
    display: flex;
    gap: 16px;
    width: 100%;
`;
