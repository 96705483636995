/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import {
    FindRestaurant,
    Button,
    PopupContainer,
    SearchResultsRestaurants,
} from '@/EZAdmin/components';
import FindUser from './views/FindUser';
import useChangeRestaurantOwner from './useChangeRestaurantOwner';
import { useFindRestaurant } from '@/EZAdmin/hooks/useFindRestaurant';
import styled from '@emotion/styled';

const EzAdminChangeRestaurantOwner = () => {
    const {
        onRestaurantChoose,
        isPopupOpen,
        onPopupClose,
        onChangeOwner,
        onChoseOwner,
        onResetOwner,
    } = useChangeRestaurantOwner();

    const {
        getRestaurants,
        data,
        loading,
        hasMore,
        setSearch,
        findRestaurant,
    } = useFindRestaurant(true);

    const ItemHelperComponent = ({ restaurantId }) => (
        <Button onClick={() => onRestaurantChoose(restaurantId)}>
            Wybierz
        </Button>
    );

    return (
        <React.Fragment>
            <div
                css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '70%',
                }}
            >
                <FindRestaurant
                    getRestaurant={getRestaurants}
                    findRestaurant={findRestaurant}
                    setSearch={setSearch}
                    loading={loading}
                    isActive
                />
                <SearchResultsRestaurants
                    restaurants={data?.restaurantList.data}
                    ItemHelperComponent={ItemHelperComponent}
                />
                {hasMore && (
                    <ButtonShowMore onClick={() => getRestaurants()}>
                        Pokaż więcej
                    </ButtonShowMore>
                )}
            </div>

            <PopupContainer
                isOpen={isPopupOpen}
                onClose={onPopupClose}
                headerText="Wybierz nowego właściciela"
                btnText="Zapisz"
                btnOnClick={onChangeOwner}
            >
                <FindUser onChoseOwner={onChoseOwner} />
            </PopupContainer>
        </React.Fragment>
    );
};

const ButtonShowMore = styled(Button)`
    margin: 16px 0 32px;
`;

export default EzAdminChangeRestaurantOwner;
