/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Bullet, Collapsable } from '../../../../components/Elements';
import SettingsIcon from '@material-ui/icons/Settings';
import { s, colors } from '../../../../style';
import {
    consumptionTypeNames,
    consumptionTypeIcons,
} from '../../../../constants/types';
import styled from '@emotion/styled';

const ConsumptionTypes = ({ consumptionTypes, left = true, right }) => {
    return (
        <Collapsable
            left={left}
            right={right}
            noPadding
            oneLiner
            title={'Realizacja'}
            description={
                <div css={{ overflowX: 'scroll' }}>
                    {consumptionTypes?.map((type, id) => (
                        <React.Fragment key={id}>
                            {id !== 0 && <Bullet />}
                            {consumptionTypeNames[type]}
                        </React.Fragment>
                    ))}
                </div>
            }
            icon={<SettingsIcon />}
        >
            <>
                <div
                    css={{
                        padding: '1rem 1rem 2rem',
                        [s.md]: { padding: '2rem' },
                    }}
                >
                    <p
                        css={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: '18px',
                            letterSpacing: '-0.03em',
                            [s.xs]: {
                                fontSize: '17px',
                                paddingTop: '1rem',
                            },
                        }}
                    >
                        Dostępne opcje obsługi
                    </p>
                    <ConsumptionTypesWrapper>
                        {consumptionTypes.map((type, id) => (
                            <div
                                key={id}
                                css={[
                                    {
                                        flexDirection: 'column',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    },
                                ]}
                            >
                                <span
                                    css={{
                                        fontSize: '1.5rem',
                                        [s.md]: { fontSize: '2rem' },
                                    }}
                                >
                                    {consumptionTypeIcons[type]}
                                </span>
                                <p
                                    css={{
                                        fontSize: '0.675rem',
                                        fontWeight: 600,
                                        textTransform: 'uppercase',
                                        [s.md]: { fontSize: '0.75rem' },
                                    }}
                                >
                                    {consumptionTypeNames[type]}
                                </p>
                            </div>
                        ))}
                    </ConsumptionTypesWrapper>
                </div>
            </>
        </Collapsable>
    );
};

const ConsumptionTypesWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 32px;
    justify-content: center;
    padding-top: 32px;
    width: 100%;

    ${s.md} {
        gap: 48px;
    }
`;

export default ConsumptionTypes;
