import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import { Button } from '@/componentsV2/molecules/Button/Button';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import NiceModal from '@ebay/nice-modal-react';
import { usePopup } from '@/AppPopups';

interface MarkAllAsServedPopupProps {
    onChangeStatuses: () => void;
}

export const MarkAllAsServedPopup = NiceModal.create(
    ({ onChangeStatuses }: MarkAllAsServedPopupProps) => {
        const popup = usePopup();
        return (
            <BasePopup
                header={
                    <PopupHeader>
                        <Title>Oznacz wszystko jako wydane</Title>
                    </PopupHeader>
                }
                footer={
                    <ButtonsWrapper>
                        <Button
                            variant="gray"
                            width={'100%'}
                            onClick={() => {
                                popup.remove();
                            }}
                        >
                            WRÓĆ DO ZAMÓWIENIA
                        </Button>
                        <Button
                            variant="black"
                            width={'100%'}
                            onClick={onChangeStatuses}
                        >
                            OZNACZ WSZYSTKO JAKO WYDANE
                        </Button>
                    </ButtonsWrapper>
                }
                children={
                    <Description>
                        Czy na pewno chcesz oznaczyć wszystkie produkty jako
                        wydane?
                    </Description>
                }
            ></BasePopup>
        );
    },
);

const PopupHeader = styled.header`
    display: grid;
    grid-gap: 24px;
`;
const Title = styled.h2`
    font-size: 28px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
