import React, { useState, useEffect, useContext, createContext } from 'react';
import useOrdersMutations from '../../hooks/useOrders.mutations';
import { useNoAuthOrdersContext } from '../NoAuthOrders/NoAuthOrders.provider';
import { useAuthService } from '@/services/AuthService';
import {
    AllCartOrdersDocument,
    CartByRestaurantDocument,
    CartCountDocument,
    useCartAddMutation,
    useCartByRestaurantQuery,
    useCartUpdateMutation,
} from '@graphql/generated';
import { dateFormat } from '@helpers/dateFormat';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { storageName } from '@/EZC/context/NoAuthOrders/useNoAuthOrders';
import { DEFAULTS_CONSTANTS } from '@constants/defaults.constants';

export const OrderInCartByRestaurantContext = createContext({
    setRestId: () => {},
    handleAddProd: () => {},
    handleDeleteProduct: () => {},
    restaurantsOrderInCartId: '',
    loadingOrders: undefined,
    errorOrders: undefined,
    order: {},
});

export const useOrderInCartByRestaurant = () =>
    useContext(OrderInCartByRestaurantContext);

export const OrderInCartByRestaurantIdProvider = ({ children }) => {
    const [restaurantsOrderInCartId, setOrderInCartByRestaurantId] =
        useState('');
    const { handleAddProduct, handleCreateOrderAddProd, handleDeleteProduct } =
        useOrdersMutations();

    const isLoggedIn = useAuthService((state) => state.isLoggedIn);

    const { data, error } = useCartByRestaurantQuery({
        variables: { pagination: { page: 1, pageSize: 100 } },
        skip: !isLoggedIn,
    });

    const [cartAdd] = useCartAddMutation();
    const [cartUpdate] = useCartUpdateMutation();

    const { ordersNoAuth, setOrdersNoAuth } = useNoAuthOrdersContext();

    const [restId, setRestId] = useState('');
    const [loading, setLoading] = useState(false);

    const findOrderCb = (order) => order.restaurant.id === restId;
    const findOrderCbNoAuth = (order) => order.restId.id === restId;

    useEffect(() => {
        const createCarts = async () => {
            if (isLoggedIn && !!ordersNoAuth.length) {
                setLoading(true);
                for (const order of ordersNoAuth) {
                    const date = dateFormat.addMinutesToDate(30);
                    const orderDate = order.date
                        ? dateFormat.dateTimeToIso(order.date, order.time)
                        : date;

                    const cartDetail = {
                        consumptionDetails: {
                            date: orderDate,
                            guests: order.guests,
                            type: order.type,
                        },
                        deliveryAddress: order.deliveryAddress,
                        deliveryAddressId: null,
                        deliveryAddressName: order.deliveryAddress
                            ? DEFAULTS_CONSTANTS.defaultAddressName
                            : '',
                        deliveryDetails: order.deliveryDetails,
                        deliveryLocation: order.deliveryLocation,
                        note: order.note,
                        invoiceDetails: order.invoiceDetails,
                        hasInvoice: order.isInvoice,
                        serveOnTime: order.serveOnTime,
                        table: order.table,
                    };
                    let cartId = null;

                    for (const line of order.lines) {
                        try {
                            await cartAdd({
                                variables: {
                                    input: {
                                        productId: line.product.id,
                                        quantity: line.quantity,
                                    },
                                },
                                refetchQueries: [
                                    CartByRestaurantDocument,
                                    CartCountDocument,
                                ],
                                onCompleted: (data) => {
                                    cartId = data.item.id;
                                },
                                onError: () => {
                                    graphqlErrorHandler(true);
                                    setLoading(false);
                                },
                            });
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    try {
                        await cartUpdate({
                            variables: {
                                input: {
                                    cartId: cartId,
                                    payload: cartDetail,
                                },
                            },
                            refetchQueries: [AllCartOrdersDocument],
                            onError: () => {
                                graphqlErrorHandler(true);
                                setLoading(false);
                            },
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }
                localStorage.removeItem(storageName);
                setOrdersNoAuth([]);
                setLoading(false);
            }
        };

        createCarts();
    }, [isLoggedIn, ordersNoAuth]);

    const order =
        restId &&
        (isLoggedIn
            ? data?.cartList?.data?.find(findOrderCb)
            : ordersNoAuth.find(findOrderCbNoAuth));

    useEffect(() => {
        if (order) {
            setOrderInCartByRestaurantId(order.id);
        } else {
            if (restaurantsOrderInCartId) {
                setOrderInCartByRestaurantId('');
            }
        }
    }, [order, restId]);

    const handleAddProd = (
        e,
        prodId,
        noAuthProdData = {},
        noAuthRestData = {},
    ) => {
        e.preventDefault();
        const { price, name, photoUrl, id, type, packagingPrice } =
            noAuthProdData;

        if (restaurantsOrderInCartId) {
            handleAddProduct(
                { orderId: restaurantsOrderInCartId, prodId },
                null,
                { price, name, photoUrl, packagingPrice },
            );
        } else {
            handleCreateOrderAddProd(
                { prodId, restId },
                null,
                { id, price, name, photoUrl, type, packagingPrice },
                noAuthRestData,
            );
        }
    };

    return (
        <OrderInCartByRestaurantContext.Provider
            value={{
                setRestId,
                handleAddProd,
                handleDeleteProduct,
                restaurantsOrderInCartId,
                loadingOrders: loading,
                errorOrders: error,
                order,
            }}
        >
            {children}
        </OrderInCartByRestaurantContext.Provider>
    );
};
