/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState, useCallback, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars

import SendIcon from '@material-ui/icons/Send';
import Input from '../../Auth/components/Input.component';
import { ButtonBase } from '@material-ui/core';

import { s, colors, alpha, globals } from '../../../../style';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { MOBILE, ANDROID } from '@constants/common.constants';
import { Keyboard } from '@capacitor/keyboard';

const InputArea = ({ newMssg, setNewMssg, onSend, onReadMssgs, isAdmin }) => {
    const [textarea, setTextarea] = useState(null);
    const [inputRows, setInputRows] = useState(1);

    const isMsg = newMssg?.trim();

    const textareaRef = useCallback((el) => {
        if (el !== null) setTextarea(el);
    }, []);

    const { bottom } = useSafeAreaInsets();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isMsg) {
            setNewMssg('');
            onSend();
            setInputRows(1);
        }
    };

    useEffect(() => {
        if (!MOBILE || ANDROID) return;
        Keyboard.setResizeMode({ mode: 'native' });

        return () => {
            Keyboard.setResizeMode({ mode: 'none' });
        };
    }, []);

    return (
        <div
            css={[
                {
                    position: 'fixed',
                    bottom: 72 + 24,
                    padding: '0 1.5rem',
                    [s.md]: { bottom: 72 + 32, padding: '0 2rem' },
                    width: '100%',
                    maxWidth: globals.maxWidthMedium,
                    left: '50%',
                    transform: 'translateX(-50%)',
                },
                isAdmin && {
                    bottom,
                    [s.sm]: { bottom: bottom / 2 + 20 },
                },
            ]}
        >
            {/* <div css={sGradient} /> */}
            <form onSubmit={handleSubmit}>
                <Input
                    numberOfRows={MOBILE && inputRows.toString()}
                    placeholder="Wiadomość..."
                    onChange={(e) => setNewMssg(e.target.value)}
                    value={newMssg}
                    // ref={textareaRef}
                    refCustom={textareaRef}
                    sBaseExtra={{ marginBottom: 0 }}
                    sInputExtra={{ paddingRight: 52 }}
                    onKeyDown={(e) => {
                        setInputRows(e.target.value.split(/\r\n|\r|\n/).length);
                        if (MOBILE && e.key === 'Enter') {
                            e.preventDefault();
                            setInputRows(inputRows + 1);
                            setNewMssg(`${e.target.value}\n`);
                        }
                    }}
                    // ???
                    onFocus={() => {
                        if (typeof onReadMssgs === 'function') onReadMssgs();
                    }}
                />
            </form>

            {/* <textarea
        ref={textareaRef}
        css={styles.textarea}
        placeholder='Wpisz wiadomość...'
        onChange={(e) => setNewMssg(e.target.value)}
        value={newMssg}
        onKeyDown={(e) => {
          if (
            (e.metaKey && e.key === 'Enter') ||
            (e.ctrlKey && e.key === 'Enter')
          )
            onSend()
          if (typeof onReadMssgs === 'function') onReadMssgs()
        }}
        onFocus={() => {
          if (typeof onReadMssgs === 'function') onReadMssgs()
        }}
      /> */}

            <ButtonBase
                onClick={(e) => {
                    if (textarea) textarea.focus();
                    handleSubmit(e);
                }}
                css={[sSendButton(isMsg)]}
            >
                <SendIcon fontSize="inherit" />
            </ButtonBase>
        </div>
    );
};

const sSendButton = (isMsg) => ({
    transition:
        'background-color 0.3s ease-out, color 0.3s ease-out, filter 0.3s ease-out',
    position: 'absolute',
    top: '50%',
    right: 24,
    transform: 'translateY(-50%)',
    borderRadius: 24,
    height: 50,
    width: 50,
    fontSize: '15px',
    [s.md]: {
        fontSize: '18px',
        height: 52,
        width: 52,
        right: 32,
        backgroundColor: isMsg ? colors.gray300 : 'transparent',
    },
    color: isMsg ? colors.text : colors.text.concat(alpha[30]),
    backgroundColor: isMsg ? colors.gray400 : 'transparent',
    '&:hover': {
        [s.hover]: {
            backgroundColor: isMsg ? colors.gray400 : 'transparent',
            color: isMsg ? colors.text : colors.gray600,
            filter: isMsg ? 'brightness(1.1)' : 'brightness(1)',
            cursor: isMsg ? 'pointer' : 'not-allowed',
        },
    },
});

// prettier-ignore
// const sGradient = {
//   position: 'absolute',
//   top: '-4rem',
//   height: '4rem',
//   left: 0,
//   [s.md]: { top: '-4rem', height: '4rem' },
//   background: `linear-gradient(to bottom,
//     ${colors.white.concat(alpha[0])},

//     ${colors.white.concat(alpha[100])} 90%
//     )`,
//   width: '100%',
// }

export default InputArea
