import { InMemoryCache, makeVar, gql } from '@apollo/client';
import { showMorePagination } from './helpers/showMorePagination';
import { StrictTypedTypePolicies } from './generated';
import currency from 'currency.js';
import { useAuthService } from '@/services/AuthService';

export const localTypeDefs = gql`
    extend type Query {
        isLoggedIn: Boolean!
        me: String!
        activeRestaurant: String
    }
    extend type User {
        coinsAvailable: Int!
    }
`;

const typePolicies: StrictTypedTypePolicies = {
    Query: {
        fields: {
            isLoggedIn() {
                return isLoggedInVar();
            },
            me() {
                return userVar();
            },
            activeRestaurant() {
                return activeRestaurantVar();
            },
            restaurantList: showMorePagination([
                'input',
                [
                    'payload',
                    [
                        'name',
                        'location',
                        'isActive',
                        'consumptionTypes',
                        'maxDistance',
                        'isBrochure',
                    ],
                    'restaurantIds',
                ],
            ]),
            orders: {
                keyArgs: ['input', ['filters']],
                read: (existing, options) => {
                    if (options?.variables?.pagePagination) return existing;

                    return showMorePagination().read?.(existing, options);
                },
                merge: (existing, incoming, options) => {
                    //this is necessary to avoid duplicate items after remove
                    if (
                        incoming?.data?.length < existing?.data?.length &&
                        incoming?.metadata?.currentPage === 1
                    ) {
                        return incoming;
                    }

                    if (options?.variables?.pagePagination) {
                        return incoming;
                    }

                    //@ts-expect-error always return function
                    return showMorePagination().merge?.(
                        existing,
                        incoming,
                        options,
                    );
                },
            },
            inAppNotificationListGroupedByOrder: showMorePagination(),
            ingredientList: {
                keyArgs: ['input'],
                read: (existing, options) => {
                    if (options?.variables?.pagePagination) return existing;

                    return showMorePagination().read?.(existing, options);
                },
                merge: (existing, incoming, options) => {
                    if (options?.variables?.pagePagination) {
                        return incoming;
                    }

                    //@ts-expect-error always return function
                    return showMorePagination().merge?.(
                        existing,
                        incoming,
                        options,
                    );
                },
            },
            payoffs: showMorePagination(),
            transactionList: showMorePagination(),
            transactionRestaurantList: showMorePagination([
                'input',
                ['restaurantId', 'sort'],
            ]),
            groups: {
                keyArgs: ['input'],
                read: (existing, options) => {
                    if (options?.variables?.pagePagination) return existing;

                    return showMorePagination().read?.(existing, options);
                },
                merge: (existing, incoming, options) => {
                    if (options?.variables?.pagePagination) {
                        return incoming;
                    }

                    //@ts-expect-error always return function
                    return showMorePagination().merge?.(
                        existing,
                        incoming,
                        options,
                    );
                },
            },
            productList: {
                keyArgs: ['input'],
                read: (existing, options) => {
                    if (options?.variables?.pagePagination) return existing;

                    return showMorePagination().read?.(existing, options);
                },
                merge: (existing, incoming, options) => {
                    if (options?.variables?.pagePagination) {
                        return incoming;
                    }

                    //@ts-expect-error always return function
                    return showMorePagination().merge?.(
                        existing,
                        incoming,
                        options,
                    );
                },
            },
        },
    },
    User: {
        fields: {
            coinsAvailable(_, { readField }) {
                const coinsBalance = readField<number>('coinsBalance') ?? 0;
                const coinsBlocked = readField<number>('coinsBlocked') ?? 0;

                return currency(coinsBalance).subtract(coinsBlocked).value;
            },
            personal: { merge: true },
        },
    },
};

export const cache = new InMemoryCache({
    typePolicies,
});

export const activeRestaurantVar = makeVar(
    useAuthService.getState().restaurantId,
);
export const userVar = makeVar(localStorage.getItem('userId'));
export const isLoggedInVar = makeVar(!!localStorage.getItem('token'));
