import { resolveEnv } from '@helpers/resolveEnv';
import ReactGA from 'react-ga4';
import { GA4 } from 'react-ga4/types/ga4';

interface GaServiceBase {
    event: GA4['event'];
    initializeWeb: () => void;
}

const GOOGLE_ANALYTICS_ID = resolveEnv('REACT_APP_GOOGLE_ANALYTICS_WEB');

export const GaService: GaServiceBase = {
    event: (...args) => ReactGA.event(...args),
    initializeWeb: () =>
        GOOGLE_ANALYTICS_ID && ReactGA.initialize(GOOGLE_ANALYTICS_ID),
};
