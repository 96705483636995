export const LocationWithBook = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_1324_10239)">
                <path
                    d="M1 6V20.65C1 20.9 1.25 21.15 1.5 21.15C1.6 21.15 1.65 21.1 1.75 21.1C3.1 20.45 5.05 20 6.5 20C8.45 20 10.55 20.4 12 21.5V6C10.55 4.9 8.45 4.5 6.5 4.5C4.55 4.5 2.45 4.9 1 6ZM23 19.5V6C22.4 5.55 21.75 5.25 21 5V18.5C19.9 18.15 18.7 18 17.5 18C15.8 18 13.35 18.65 12 19.5V21.5C13.35 20.65 15.8 20 17.5 20C19.15 20 20.85 20.3 22.25 21.05C22.35 21.1 22.4 21.1 22.5 21.1C22.75 21.1 23 20.85 23 20.6V19.5Z"
                    fill="#343340"
                />
                <g clip-path="url(#clip1_1324_10239)">
                    <path
                        d="M16.4987 1.83301C14.8862 1.83301 13.582 3.13717 13.582 4.74967C13.582 6.93717 16.4987 10.1663 16.4987 10.1663C16.4987 10.1663 19.4154 6.93717 19.4154 4.74967C19.4154 3.13717 18.1112 1.83301 16.4987 1.83301ZM16.4987 5.79134C15.9237 5.79134 15.457 5.32467 15.457 4.74967C15.457 4.17467 15.9237 3.70801 16.4987 3.70801C17.0737 3.70801 17.5404 4.17467 17.5404 4.74967C17.5404 5.32467 17.0737 5.79134 16.4987 5.79134Z"
                        fill="#323232"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1324_10239">
                    <rect width="24" height="24" rx="4" fill="white" />
                </clipPath>
                <clipPath id="clip1_1324_10239">
                    <rect
                        width="10"
                        height="10"
                        fill="white"
                        transform="translate(11.5 1)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
