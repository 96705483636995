import { SplitPaymentUserProps } from '@/componentsV2/atoms/SplitPaymentUser/SplitPaymentUser';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import {
    OPERATIONS,
    OrderItem,
    OrderItemProductPayoffFragment,
    Product,
    User,
    usePayoffBulkRequestPaymentMutation,
} from '@graphql/generated';
import { difference, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const usePayoffRequests = ({
    items,
    users,
}: {
    items?: OrderItemProductPayoffFragment[];
    users?: SplitPaymentUserProps[];
}) => {
    const orderId = useParams<{ id: string }>().id;

    const [payoffBulkRequestPayment, { loading }] =
        usePayoffBulkRequestPaymentMutation({
            onError: graphqlErrorHandler(true),
        });

    const [userRequests, setUserRequests] = useState<
        Record<User['id'], OrderItem['id'][]>
    >({});

    const groupedItemsByProduct = items?.reduce<
        Record<
            Product['id'],
            { groupedItems: OrderItemProductPayoffFragment[] }
        >
    >((prevItems, currentItem) => {
        const productId = currentItem.product.id;

        if (!prevItems[productId])
            return {
                ...prevItems,
                [productId]: {
                    groupedItems: [currentItem],
                },
            };

        return {
            ...prevItems,
            [productId]: {
                ...prevItems[productId],
                groupedItems: [
                    ...prevItems[productId].groupedItems,
                    currentItem,
                ],
            },
        };
    }, {});

    const assignedItemsIds = Object.values(userRequests).reduce(
        (prevItems, currentItem) => [...prevItems, ...currentItem],
        [],
    );

    const payoffRequestsSend = () => {
        payoffBulkRequestPayment({
            variables: {
                input: {
                    orderId: orderId,
                    payload: Object.entries(userRequests)
                        .map(([userId, itemsIds]) =>
                            itemsIds.map((itemId) => ({
                                itemsIds: [itemId],
                                recipient: userId,
                            })),
                        )
                        .flat(),
                },
            },
            onCompleted: () => {
                toast.dark('Prośba o rozliczenie płatności została wysłana');

                setUserRequests((prevState) =>
                    Object.fromEntries(
                        Object.entries(prevState).map(([key]) => [key, []]),
                    ),
                );
            },
            refetchQueries: [OPERATIONS.Query.OrderActivePage],
            onError: graphqlErrorHandler(true),
        });
    };

    const handleSave = (value: Record<User['id'], OrderItem['id'][]>) => {
        setUserRequests((prevState) =>
            Object.fromEntries(
                Object.entries(prevState).map(([userId, itemIds]) => [
                    userId,
                    uniq([...itemIds, ...value[userId]]),
                ]),
            ),
        );
    };

    const handleCancel = (value: Record<User['id'], OrderItem['id'][]>) => {
        setUserRequests((prevState) =>
            Object.fromEntries(
                Object.entries(prevState).map(([userId, itemIds]) => [
                    userId,
                    difference(itemIds, value[userId]),
                ]),
            ),
        );
    };

    useEffect(() => {
        if (users?.length && !Object.keys(userRequests).length)
            setUserRequests(
                users?.reduce(
                    (prevUsers, currentUser) => ({
                        ...prevUsers,
                        [currentUser.id]: [],
                    }),
                    {},
                ) ?? {},
            );
    }, [users]);

    return {
        handleSave,
        handleCancel,
        assignedItemsPrice: assignedItemsIds.reduce(
            (prevPrice, currentItemId) => {
                return (
                    prevPrice +
                    (items?.find((item) => item.id === currentItemId)?.price ??
                        0)
                );
            },
            0,
        ),
        payoffRequestsSend,
        payoffRequestsLoading: loading,
        groupedItemsByProduct,
    };
};
