import React from 'react';
import SingleCartOrder from './views/SingleCartOrder';
import SingleCartOrderNoAuth from './views/SingleCartOrderNoAuth';
import { useAuthService } from '@/services/AuthService';

export default () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    if (isLoggedIn) return <SingleCartOrder />;
    else return <SingleCartOrderNoAuth />;
};
