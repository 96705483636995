/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import AllActiveOrders from './views/AllActiveOrders';
import Headline from '../../../components/Headline/Headline';
import { sOuter, sGrid } from '../../../style/style';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';

const Orders = () => (
    <React.Fragment>
        <TopNav title={'Zamówienia'} />
        <div css={sOuter}>
            <Headline>Zamówienia</Headline>
            <Grid container css={sGrid}>
                <AllActiveOrders />
            </Grid>
        </div>
    </React.Fragment>
);

export default Orders;
