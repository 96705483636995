/** @jsx jsx */ /** @jsxRuntime classic */
import React from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { Grid } from '@material-ui/core';
import { Collapsable, Bullet, Row } from '../../../../components/Elements';
import PersonPinIcon from '@material-ui/icons/PersonPinCircle';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import HomeIcon from '@material-ui/icons/Home';

const OrderAddress = ({ deliveryAddress, deliveryDetails }) => {
    return (
        <Grid item xs={12} md={6}>
            <Collapsable
                // right
                noPadding
                oneLiner
                title={'Adres'}
                icon={<PersonPinIcon />}
                description={
                    <div>
                        {deliveryAddress}
                        {deliveryDetails && (
                            <>
                                <Bullet />
                                {deliveryDetails}
                            </>
                        )}
                    </div>
                }
            >
                <Row icon={<LocationCityIcon fontSize="inherit" />}>
                    {deliveryAddress}
                </Row>
                {deliveryDetails && (
                    <Row icon={<HomeIcon fontSize="inherit" />}>
                        {deliveryDetails}
                    </Row>
                )}
            </Collapsable>
        </Grid>
    );
};

export default OrderAddress;
