import React, { useEffect, useState } from 'react'; // eslint-disable-line no-unused-vars
import { useParams, useHistory } from 'react-router-dom';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sDescription, sGrid, sOuter } from '../../../style/style';
import Headline from '../../../components/Headline/Headline';
import { Grid } from '@material-ui/core';
import SkeletonOrder from '../../../components/SkeletonOrder/SkeletonOrder';
import { toast } from 'react-toastify';
import { ButtonBorder } from '../../../components/Elements';
import { useTopNavContext } from '../../../context/topnav.context';
import NavRestMenu from '../../../EZC/views/RestaurantPage/components/NavRestMenu';
import { Element as ElementScroll } from 'react-scroll';
import { globals, s } from '../../../style';
import { ProdItemMenuList } from '../../../EZC/components/ProdItem/';
import {
    useOrderAddMenuQuery,
    useOrderWaiterItemAddMutation,
} from '@graphql/generated';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { Button } from '@/componentsV2/molecules/Button/Button';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';

const OrderAdd = () => {
    const history = useHistory();
    const orderId = useParams().id;
    const { restaurantId } = useWaiterService();
    const { setAnimBack } = useTopNavContext();
    const [cart, setCart] = useState([]);
    const { bottomNavigation } = useSafeAreaInsets();

    const { data, loading, error } = useOrderAddMenuQuery({
        context: { waiter: true },
        fetchPolicy: 'cache-and-network',
        onError: graphqlErrorHandler(true),
        variables: {
            orderInput: { orderId },
            restaurantInput: { restaurantId },
        },
    });

    const [updateOrder, { loading: updateLoading }] =
        useOrderWaiterItemAddMutation();

    const owner = data?.order.owner;
    const menu = data?.restaurant.menu
        .slice()
        .sort((a, b) => a?.order - b?.order);
    const status = data?.order.status;
    const consumptionType = data?.order.details.type;

    const addMoreAvailable =
        consumptionType === 'IN' &&
        (status !== 'CLOSED' || status !== 'CANCELED');

    const onSendProducts = () => {
        if (!cart?.length) {
            toast.warning('Dodaj produkty do zamówienia');
            return;
        }

        updateOrder({
            context: { waiter: true },
            variables: {
                input: {
                    orderId: orderId,
                    productIds: cart.flatMap((product) =>
                        Array.from(
                            { length: product.quantity },
                            () => product.id,
                        ),
                    ),
                },
            },
            onError: graphqlErrorHandler({
                customCodeMessages: {
                    CANNOT_CREATE_ORDER_ADDITION_ERROR:
                        'Niewystarczająca ilość Eatcoinów na koncie klienta. Proszę zaczekać, aż klient doładuje swoje konto.',
                },
            }),
            onCompleted: () => {
                history.push(`/waiter/order/${orderId}`);
            },
        });
    };

    const addProduct = (product) => {
        const { id, name, price, photoUrl } = product;
        let isProductExist = cart.find((product) => product.id === id);
        if (!isProductExist) {
            setCart([...cart, { id, name, price, photoUrl, quantity: 1 }]);
            return;
        }

        setCart(
            cart.map((product) =>
                product.id !== id
                    ? product
                    : { ...product, quantity: product.quantity + 1 },
            ),
        );
    };

    const deleteProduct = ({ id }) => {
        let product = cart.find((product) => product.id === id);

        if (product.quantity === 1) {
            setCart(cart.filter((prod) => prod.id !== id));
        } else {
            setCart(
                cart.map((product) =>
                    product.id !== id
                        ? product
                        : { ...product, quantity: product.quantity - 1 },
                ),
            );
        }
    };

    useEffect(() => {
        if (!loading && !addMoreAvailable) {
            toast.info('Nie możesz dodawać produktów do tego zamówienia');
            history.push(`/waiter/order/${orderId}`);
        }
    }, [addMoreAvailable, loading]);

    if (loading) {
        return (
            <React.Fragment>
                <div css={sOuter}>
                    <Headline>Menu</Headline>
                    <Grid container css={sGrid}>
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    if (!orderId || error || !data) {
        return (
            <React.Fragment>
                <div css={sOuter}>
                    <Headline>Menu</Headline>
                    <p css={sDescription}>Nie odnaleziono menu restauracji</p>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <TopNav
                isScrolledAlready
                title={`${owner?.personal.name ?? ''} ${
                    owner?.personal.surname ?? ''
                }`.trim()}
                photo={owner?.personal.avatarUrl}
                textCss={{ maxWidth: `calc(100vw - ${48 + 32 + 30 + 36}px)` }}
                extraCssOuter={{ boxShadow: 'none' }}
            >
                <ButtonBorder
                    extraCss={{ backgroundColor: 'white' }}
                    action={() => {
                        setAnimBack(true);
                        history.push(`/waiter/order/${orderId}`);
                    }}
                >
                    Wróć
                </ButtonBorder>
            </TopNav>
            <NavRestMenu items={menu.map((section) => section.name)} />

            <div css={[sOuter, { marginTop: '6rem', padding: '0 1.5rem' }]}>
                {menu.map((section) => (
                    <ElementScroll
                        name={encodeURIComponent(section.name)}
                        key={section.id}
                    >
                        <div
                            key={section.id}
                            css={{
                                paddingTop: '3rem',
                                [s.md]: { paddingTop: '4rem' },
                            }}
                        >
                            <p
                                css={{
                                    fontSize: '22px',
                                    fontWeight: 700,
                                    whiteSpace: 'nowrap',
                                    letterSpacing: '-0.05em',
                                    paddingLeft: '1rem',
                                    marginBottom: '1.5rem',
                                    [s.xs]: { fontSize: '22px' },
                                    [s.md]: { paddingLeft: '2rem' },
                                }}
                            >
                                {section.name}
                            </p>
                            <Grid container>
                                {section.products.map((product, index) => {
                                    const onAddProduct = () => {
                                        addProduct(product);
                                    };
                                    const onDeleteProduct = () => {
                                        deleteProduct({
                                            id: product.id,
                                        });
                                    };
                                    return (
                                        <ProdItemMenuList
                                            id={index}
                                            key={product.id}
                                            data={product}
                                            handleAddProduct={onAddProduct}
                                            handleDeleteProduct={
                                                onDeleteProduct
                                            }
                                            productInOrder={cart?.find(
                                                (prod) =>
                                                    prod.id === product.id,
                                            )}
                                            orderI={orderId}
                                            noLink
                                        />
                                    );
                                })}
                            </Grid>
                        </div>
                    </ElementScroll>
                ))}

                <ButtonWrapper css={{ bottom: bottomNavigation }}>
                    <Button
                        width={'100%'}
                        onClick={onSendProducts}
                        variant="yellowGradient"
                        loading={updateLoading}
                    >
                        Domów{' '}
                        {cart.length > 0 && (
                            <span>
                                &nbsp; +{' '}
                                {cart
                                    .reduce(
                                        (sum, curr) =>
                                            curr.price * curr.quantity + sum,
                                        0,
                                    )
                                    .toFixed(2)}{' '}
                                PLN
                            </span>
                        )}
                    </Button>
                </ButtonWrapper>
            </div>
        </React.Fragment>
    );
};

export const ButtonWrapper = styled.div(
    ({ bottom }) => css`
        width: 100%;
        padding: 0 32px;
        max-width: ${globals.maxWidthMedium}px;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: ${bottom ? `${bottom}px` : '72px'};
    `,
);

export default OrderAdd;
