import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style/index';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import * as yup from 'yup';
import { GeolocationType } from '@/types/Geolocalization';
import { ChipSecondary } from '@/componentsV2/atoms/ChipSecondary/ChipSecondary';
import { Button } from '@/componentsV2/molecules/Button/Button';
import {
    AddressForm,
    AddressFormSchemaValues,
} from '@/EZC/domains//Account/molecules/AddressForm/AddressForm';
import { Checkbox } from '@/componentsV2/atoms/Checkbox/Checkbox';
import { toast } from 'react-toastify';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useUserCreateAddressFromCartMutation } from '@graphql/generated';
import { css } from '@emotion/core';

interface AddressType {
    name: string;
    address: GeolocationType['address'];
    coordinates: [GeolocationType['lng'], GeolocationType['lat']];
    additionalInfo?: string | null;
    isDefault?: boolean;
    id?: string;
}

export interface CartAddressesProps {
    addresses?: AddressType[];
    children: ReactNode;
    deliveryAddress: AddressType;
    onChangeAddress: (value?: AddressType) => void;
    setAddressError: (value?: boolean) => void;
}

export const CartAddresses = ({
    addresses,
    children,
    deliveryAddress,
    onChangeAddress,
    setAddressError,
}: CartAddressesProps) => {
    const [expandedAddress, setExpandedAddress] = useState(
        (deliveryAddress.name && !deliveryAddress.id) ||
            !deliveryAddress.address,
    );
    const [saveInAddressBook, setSaveInAddressBook] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [isEdited, setIsEdited] = useState(false);

    const [createAddress, { loading }] = useUserCreateAddressFromCartMutation({
        onError: graphqlErrorHandler(true),
    });

    const handleSubmit = async (values: AddressFormSchemaValues) => {
        if (saveInAddressBook) {
            await createAddress({
                variables: {
                    input: {
                        name: values.addressName,
                        address: values.address.address,
                        additionalInfo: values.additionalAddress,
                        coordinates: [values.address.lng, values.address.lat],
                        isDefault: false,
                    },
                },
                onCompleted: (data) => {
                    const newAddress = data?.user?.addresses?.find(
                        (address) => address?.name === values.addressName,
                    );
                    toast.dark(
                        'Adres został zapisany i dodany do listy adresów',
                    );
                    setExpandedAddress(false);
                    onChangeAddress({
                        name: newAddress?.name ?? '',
                        address: newAddress?.address ?? '',
                        additionalInfo: newAddress?.additionalInfo,
                        id: newAddress?.id,
                        coordinates: [
                            newAddress?.coordinates?.[0] ?? 0,
                            newAddress?.coordinates?.[1] ?? 0,
                        ],
                        isDefault: false,
                    });
                },
            });
        } else {
            onChangeAddress({
                name: values.addressName,
                address: values.address.address,
                additionalInfo: values.additionalAddress,
                coordinates: [values.address.lng, values.address.lat],
                isDefault: false,
                id: undefined,
            });
        }
        setSaveInAddressBook(false);
        setShowButton(false);
    };

    return (
        <Accordion
            collapsedPadding="24px 32px"
            headerElement={
                <ContentWrapper>
                    <IconWrapper>
                        <PersonPinCircleIcon />
                    </IconWrapper>
                    <InfoWrapper>
                        <Title>Adres</Title>
                        <DescriptionWrapper>
                            <Description>
                                {!!deliveryAddress?.address
                                    ? deliveryAddress?.address
                                    : 'Wprowadź adres dostawy'}
                            </Description>
                        </DescriptionWrapper>
                    </InfoWrapper>
                </ContentWrapper>
            }
        >
            <Wrapper bottomPadding={!!children}>
                <Text>
                    {deliveryAddress?.address
                        ? 'Wybierz adres dostawy lub wprowadź nowy.'
                        : 'Wypełnij adres dostawy. Obowiązkowe pola to nazwa adresu, ulica i numer budynku. W polu „Numer mieszkania, piętro...” możesz również podać dodatkowe informacje.'}
                </Text>
                {deliveryAddress.address && (
                    <Addressess>
                        {!expandedAddress && (
                            <SelectedAddress>{`${deliveryAddress?.name}: ${deliveryAddress?.address}`}</SelectedAddress>
                        )}
                        <ChipsWrapper>
                            {addresses?.map((address) => (
                                <ChipSecondary
                                    value={{
                                        name: address?.name,
                                        id: address?.id ?? '',
                                    }}
                                    size="default"
                                    text={address?.name}
                                    variant={
                                        address?.id === deliveryAddress?.id &&
                                        !expandedAddress
                                            ? 'default'
                                            : 'gray'
                                    }
                                    onClick={() => {
                                        setExpandedAddress(false);
                                        onChangeAddress(address);
                                    }}
                                />
                            ))}
                        </ChipsWrapper>
                    </Addressess>
                )}
                {!expandedAddress && (
                    <Button
                        variant="gray"
                        children="DODAJ INNY ADRES"
                        onClick={() => setExpandedAddress(true)}
                    />
                )}
                {expandedAddress && (
                    <>
                        <CheckboxWrapper>
                            <Checkbox
                                onChange={() => {
                                    setSaveInAddressBook(!saveInAddressBook);
                                    setIsEdited(!isEdited);
                                }}
                                name="defaultAddress"
                                checked={saveInAddressBook}
                                borderColor={'gray'}
                                fillColor={'green'}
                            />
                            Dodaj ten adres do listy adresów w ustawieniach
                        </CheckboxWrapper>
                        <AddressForm
                            formVariant="cart"
                            defaultValues={
                                !deliveryAddress.id
                                    ? {
                                          addressName: deliveryAddress.name,
                                          address: {
                                              address: deliveryAddress.address,
                                              lng: deliveryAddress
                                                  ?.coordinates[0],
                                              lat: deliveryAddress
                                                  ?.coordinates[1],
                                          },
                                          additionalAddress:
                                              deliveryAddress?.additionalInfo,
                                          defaultAddress: false,
                                      }
                                    : undefined
                            }
                            setAddressError={setAddressError}
                            setIsEdited={setShowButton}
                            children={(error) =>
                                showButton ||
                                isEdited ||
                                !deliveryAddress.address ? (
                                    <ButtonSave
                                        variant={error ? 'gray' : 'black'}
                                        type="submit"
                                        disabled={error}
                                        loading={loading}
                                    >
                                        ZAPISZ ADRES
                                    </ButtonSave>
                                ) : (
                                    <></>
                                )
                            }
                            onSubmit={handleSubmit}
                        />
                    </>
                )}
                {children}
            </Wrapper>
        </Accordion>
    );
};

const Wrapper = styled.div<{ bottomPadding: boolean }>(
    ({ bottomPadding }) => css`
        color: ${colors.gray600};
        display: grid;
        font-size: 13px;
        grid-gap: 24px;
        padding: ${bottomPadding ? '0 0 80px' : 0};
    `,
);

const IconWrapper = styled.div`
    align-items: center;
    background-color: ${colors.gray400};
    border-radius: 50%;
    display: flex;
    font-size: 20px;
    height: 40px;
    justify-content: center;
    width: 40px;
`;

const DescriptionWrapper = styled.div`
    white-space: nowrap;
    overflow-x: auto;
`;

const ContentWrapper = styled.div`
    align-items: center;
    display: grid;
    grid-template-columns: 40px 1fr;
    margin-right: 16px;
    width: 100%;
`;

const InfoWrapper = styled.div`
    margin-left: 16px;
    overflow: hidden;
`;

const Title = styled.p`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
`;

const Description = styled.p`
    color: ${colors.gray};
    font-size: 13px;
`;

const Text = styled.p`
    color: ${colors.blueZodiac};
    font-size: 14px;
`;

const ButtonSave = styled(Button)`
    margin-top: 10px;
`;

const Addressess = styled.div`
    display: grid;
    gap: 12px;
`;

const ChipsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

const SelectedAddress = styled.p`
    color: ${colors.text};
    font-size: 12px;
    line-height: 18px;
`;

const CheckboxWrapper = styled.div`
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: ${colors.text};
    padding: 8px 0;

    .MuiCheckbox-root {
        color: ${colors.gray400};
        padding: 0 8px 0 0;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: ${colors.green};
        border-radius: 4px;
    }
`;

export const AddressSchema = (isRequired: boolean) =>
    yup.object({
        phone: isRequired
            ? yup
                  .string()
                  .trim()
                  .required('To pole nie może być puste.')
                  .matches(
                      /^\+48\d{9}$/,
                      'Niepoprawny format numeru telefonu. Wprowadź numer telefonu w formacie 9 cyfr.',
                  )
            : yup
                  .string()
                  .trim()
                  .test(
                      'isValidPhone',
                      'Niepoprawny format numeru telefonu. Wprowadź numer telefonu w formacie 9 cyfr.',
                      (value) => {
                          if (!value) return true;
                          if (typeof value === 'string') {
                              return /^\+48\d{9}$/.test(value);
                          }
                          return false;
                      },
                  ),
    });
