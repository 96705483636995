import React from 'react';

export const SingleModeIcon = () => (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.0007 3.16699H2.33398V5.83366V8.50033V11.167V13.8337H5.00065L13.0007 5.83366V3.16699Z"
            fill="#A3A5B0"
        />
        <path
            d="M13.5873 8.02699L12.8073 7.24699C12.7007 7.14033 12.5273 7.14033 12.4207 7.24699L11.834 7.83366L13.0007 9.00033L13.5873 8.41366C13.694 8.30699 13.694 8.13366 13.5873 8.02699Z"
            fill="#A3A5B0"
        />
        <path
            d="M7 12.6664V13.8331H8.16667L12.6133 9.38639L11.4467 8.21973L7 12.6664Z"
            fill="#A3A5B0"
        />
    </svg>
);
