import React from 'react';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { Button } from '@/componentsV2/molecules/Button/Button';
import {
    useCancelOrderOrProductMutation,
    useOrderItemsPopupQuery,
    CancelOrderItemFragment,
    OPERATIONS,
} from '@graphql/generated';
import { ItemWithCheckbox } from '@/componentsV2/atoms/ItemWithCheckbox/ItemWithCheckbox';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import NiceModal from '@ebay/nice-modal-react';
import { useForm, useFieldArray } from 'react-hook-form';
import { popup } from '@/AppPopups';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { toast } from 'react-toastify';

export interface CancelOrderOrProductPopupInnerProps {
    items?: CancelOrderItemFragment[];
    onCancel: (items: string[]) => void;
}

interface CancelOrderOrProductPopupProps {
    orderId: string;
}

export const CancelOrderOrProductPopup = NiceModal.create(
    ({ orderId }: CancelOrderOrProductPopupProps) => {
        const [cancelOrderOrProduct] = useCancelOrderOrProductMutation({});

        const { data } = useOrderItemsPopupQuery({
            context: { waiter: true },
            variables: { input: { orderId: orderId } },
        });

        const handleCancelOrderOrProducts = (items: string[]) => {
            const selectedItemsIds = items.map((itemId) => itemId);
            const isPartialAccepted =
                selectedItemsIds.length !== data?.order.items.length &&
                selectedItemsIds.length !== 0;

            cancelOrderOrProduct({
                context: { waiter: true },
                variables: {
                    input: {
                        orderId,
                        payload: {
                            rejectedProductsIds: selectedItemsIds,
                        },
                        status: isPartialAccepted
                            ? 'PARTIAL_ACCEPTANCE'
                            : 'REJECTED',
                    },
                },
                onCompleted: () => {
                    isPartialAccepted
                        ? toast.info(
                              'Zamówienie zostało częściowo zaakceptowane',
                          )
                        : toast.error('Zamówienie zostało odrzucone');
                    popup.remove('CancelOrderOrProductPopup');
                },
                onError: () => graphqlErrorHandler(true),
                refetchQueries: [
                    OPERATIONS.Query.ActivePage,
                    OPERATIONS.Query.OrderPage,
                ],
            });
        };

        return (
            <CancelOrderOrProductPopupInner
                items={data?.order.items}
                onCancel={handleCancelOrderOrProducts}
            />
        );
    },
);

export const CancelOrderOrProductPopupInner = ({
    items,
    onCancel,
}: CancelOrderOrProductPopupInnerProps) => {
    const { control, watch, handleSubmit } = useForm<{ items: string[] }>({
        defaultValues: {
            items: [],
        },
    });

    const submitForm = handleSubmit((value) => {
        onCancel(value.items);
    });

    const { fields, remove, append } = useFieldArray<{ items: string[] }>({
        control,
        name: 'items' as never,
    });

    const productsGroupedById = items?.reduce(
        (result, obj) => {
            const productId = obj.product.id;

            if (!result[productId]) {
                result[productId] = [];
            }
            result[productId].push(obj);

            return result;
        },
        {} as Record<string, CancelOrderItemFragment[]>,
    );

    const groupedProducts = Object.keys(productsGroupedById ?? {});

    return (
        <BasePopup
            children={
                <div>
                    <Description>
                        Odrzucenie zamówienia jest bezpowrotne. Klient zostanie
                        poinformowany o Twojej decyzji. W przypadku zamówienia
                        opłaconego, wszystkie środki zostaną zwrócone na konto
                        klienta, a zamówienie trafi do Odrzuconych
                    </Description>

                    {groupedProducts?.map((productId, productIdIndex) => {
                        const isSeparatorVisible =
                            productsGroupedById &&
                            (productsGroupedById?.[productId]?.length > 1 ||
                                productsGroupedById?.[
                                    groupedProducts[productIdIndex + 1]
                                ]?.length > 1) &&
                            groupedProducts?.length !== productIdIndex + 1;
                        return (
                            <React.Fragment key={productId + productIdIndex}>
                                {productsGroupedById?.[productId].map(
                                    (item) => (
                                        <ItemWrapper key={item.id}>
                                            <ItemWithCheckbox
                                                children={
                                                    <ProductTile
                                                        name={item.product.name}
                                                        imageUrl={
                                                            item.product
                                                                .photoUrl || ''
                                                        }
                                                        average={0}
                                                        ratings={0}
                                                        categories={[]}
                                                        productPrice={
                                                            item.product.price
                                                        }
                                                    />
                                                }
                                                onChange={(_, checked) => {
                                                    checked
                                                        ? append(item.id)
                                                        : remove(
                                                              fields.findIndex(
                                                                  (product) =>
                                                                      product.id ===
                                                                      item.id,
                                                              ),
                                                          );
                                                }}
                                            />
                                        </ItemWrapper>
                                    ),
                                )}
                                {isSeparatorVisible && <Separator />}
                            </React.Fragment>
                        );
                    })}
                </div>
            }
            footer={
                <Footer>
                    <Button
                        variant={'gray'}
                        onClick={() =>
                            popup.remove('CancelOrderOrProductPopup')
                        }
                    >
                        Zamknij
                    </Button>
                    <Button variant={'redDark'} onClick={submitForm}>
                        {watch('items').length > 0
                            ? `Odrzuć produkt (${watch('items').length})`
                            : 'Odrzuć wszystkie produkty'}
                    </Button>
                </Footer>
            }
            header={<Header>Odrzucanie</Header>}
            overlayBackground
        />
    );
};

const Header = styled.h2`
    color: ${colors.text};
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ItemWrapper = styled.div`
    padding: 5px;
`;

const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.text};
    opacity: 0.2;
    margin: 15px 0;
`;
