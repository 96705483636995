/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';

import { daysOfWeek } from '@constants/daysOfWeek';
import { useRestaurantContext } from '@/EZR/pages/Restaurant/context/restaurant.provider';

import deliverySettingsCheck from '@/EZR/pages/Restaurant/helpers/deliverySettingsCheck';
import deliveryParseFloatAmount from '@/EZR/pages/Restaurant/helpers/deliveryParseFloatAmounts';

import { sButtonFixed } from '@/EZW/pages/Order/buttons/FixedButtons';
import { Button } from '@components/Elements';
import {
    RestaurantDocument,
    useRestaurantUpdateMutation,
} from '@graphql/generated';
import { useUploadImg } from '@/EZR/pages/Restaurant/hooks/useUploadImage';
import { getFileType } from '@helpers/getFileType';
import { useSafeAreaInsets } from '@/EZC/hooks/useSafeAreaInsets';
import { useAuthService } from '@/services/AuthService';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { useHistory } from 'react-router-dom';
import { restaurantNameSchema } from '../helpers/restaurantNameSchema';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useKeyboardStoreShallow } from '@/services/KeyboardService/KeyboardStore';
import { IOS } from '@constants/common.constants';

export default () => {
    const restaurantId = useAuthService((state) => state.restaurantId);
    const { uploadImgAndGetURL } = useUploadImg();
    const [restaurantUpdate, { loading }] = useRestaurantUpdateMutation();
    const history = useHistory();

    const {
        openningHours,
        restaurantInfo,
        logoFile,
        backgroundFile,
        deliverySettings,
        logoUrl,
        logoPreview,
        backgroundUrl,
        backgroundPreview,
    } = useRestaurantContext();

    const { bottomNavigation } = useSafeAreaInsets();

    const { keyboardHeight } = useKeyboardStoreShallow(['keyboardHeight']);

    const handleSubmit = async () => {
        try {
            const a = deleteProperty__typename(openningHours);

            const i = Object.entries(a).map((el) => ({
                day: el[0],
                close: el[1].close,
                open: el[1].open,
            }));
            const openningHoursToSend = {};
            i.forEach(({ day, open, close }) => {
                if (open > close) {
                    throw new Error(
                        `${daysOfWeek[day]} - godzina otwarcia nie może być późniejsza od godziny zamknięcia`,
                    );
                }
                openningHoursToSend[day] = {
                    open,
                    close,
                };
            });

            deliverySettings.availableHours = deleteProperty__typename(
                deliverySettings.availableHours,
            );
            Object.keys(deliverySettings.availableHours).forEach((day) => {
                deliverySettings.availableHours[day] = deleteProperty__typename(
                    deliverySettings.availableHours[day],
                );
            });

            if (restaurantInfo?.consumptionTypes.includes('delivery')) {
                const deliverySettingsErrors =
                    deliverySettingsCheck(deliverySettings);
                if (deliverySettingsErrors.length > 0) {
                    const errors = new Error();
                    errors.array = deliverySettingsErrors;
                    throw errors;
                }
                deliveryParseFloatAmount(deliverySettings);
            }

            const errors = [];
            if (!restaurantInfo.name) errors.push('Podaj nazwę');
            if (!restaurantInfo.address) errors.push('Podaj adres');
            if (restaurantInfo.name.length > 51)
                errors.push(
                    'Nazwa restauracji jest zbyt długa (maks. 50 znaków)',
                );
            if (!restaurantInfo.description) errors.push('Podaj opis');
            if (!restaurantNameSchema.isValidSync(restaurantInfo.name))
                errors.push('Nazwa restauracji zawiera niedozwolone znaki');
            if (errors.length) {
                errors.forEach((el) => toast.error(el));
                return;
            }

            const s3LogoUrl = await uploadImgAndGetURL(
                logoFile,
                getFileType(logoFile),
                '/240x240',
            );

            const s3BackgroundUrl = await uploadImgAndGetURL(
                backgroundFile,
                getFileType(backgroundFile),
                '/1200x900',
            );

            await restaurantUpdate({
                variables: {
                    input: {
                        restaurantId,
                        payload: {
                            name: restaurantInfo.name,
                            address: restaurantInfo.address,
                            description: restaurantInfo.description,
                            consumptionTypes: restaurantInfo?.consumptionTypes,
                            location: restaurantInfo?.location,
                            openingHours:
                                convertTimestamps.toISO(openningHoursToSend),
                            isActive: restaurantInfo?.isActive,
                            deliverySettings: {
                                availableHours: convertTimestamps.toISO(
                                    deliverySettings.availableHours,
                                ),
                                deliveryRadius: deliverySettings.deliveryRadius,
                                minAmount: deliverySettings.minAmount,
                                freeDelivery: !!deliverySettings.freeDelivery,
                                freeDeliveryAmount: parseFloat(
                                    deliverySettings.freeDeliveryAmount,
                                ),
                                deliveryCost: deliverySettings.deliveryCost.map(
                                    (item) => ({
                                        deliveryRadius: parseFloat(item[0]),
                                        amount: parseFloat(item[1]),
                                    }),
                                ),
                            },
                            photoUrl: backgroundPreview
                                ? s3BackgroundUrl
                                : backgroundUrl,
                            avatarUrl: logoPreview ? s3LogoUrl : logoUrl,
                        },
                    },
                },

                onCompleted: () => {
                    toast.dark('Dane restauracji zostały zaktualizowane');
                    history.push('/rest');
                },
                onError: graphqlErrorHandler(true),
                refetchQueries: [
                    {
                        query: RestaurantDocument,
                        variables: { input: { restaurantId } },
                    },
                ],
            });
        } catch (err) {
            if (err.array && err.array.length > 0) {
                err.array.forEach((e) => toast.warn(e));
            } else {
                toast.warn(err.message);
            }
        }
    };

    return (
        <div
            css={[
                sButtonFixed,
                { bottom: bottomNavigation },
                {
                    ...(IOS &&
                        keyboardHeight && {
                            position: 'relative',
                            bottom: 0,
                        }),
                },
            ]}
        >
            <Button
                w100
                w100Desktop
                buttonTransparent={true}
                action={handleSubmit}
                disabled={loading}
                loadingSpinner={loading}
                loading={loading}
            >
                Zapisz zmiany
            </Button>
        </div>
    );
};

function deleteProperty__typename(obj) {
    const { __typename, ...a } = obj;
    return a;
}
