import { PaginationInput, PaginationMetadata } from '@graphql/generated';
import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { isEqual } from 'lodash';

const DEFAULT_PAGE_SIZE = 10;

interface UseFindRestaurantOptions<T> {
    callback: (pagination: PaginationInput, input?: T) => void;
    metadata?: PaginationMetadata;
    /**
     * Default: 10
     */
    pageSize?: number;
    input?: T;
}

export const useShowMorePagination = <T = unknown>({
    callback,
    metadata,
    pageSize = DEFAULT_PAGE_SIZE,
    input,
}: UseFindRestaurantOptions<T>) => {
    const prevInput = usePrevious(input);

    const hasMore = (metadata?.currentPage ?? 0) < (metadata?.totalPages ?? 0);
    const page = metadata?.currentPage ?? 0;

    useEffect(() => {
        callback({ page: 1, pageSize }, input);
    }, []);

    useEffect(() => {
        if (!isEqual(input, prevInput)) {
            callback({ page: 1, pageSize }, input);
        }
    }, [prevInput, input]);

    return {
        currentPage: page,
        getMoreData: () => callback({ page: page + 1, pageSize }, input),
        hasMore,
    };
};
