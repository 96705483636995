/** @jsx jsx */ /** @jsxRuntime classic */
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { jsx, css } from '@emotion/core'; // eslint-disable-line no-unused-vars
import { ButtonBorder } from '@components/Elements';
import Headline from '@components/Headline/Headline';
import { TopNav } from '@components/TopNavFixed/TopNavFixed';
import { sOuter, sGrid, sDescription, sPaddingTop } from '@style/style';
import { Grid, Collapse } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import RestaurantProvider, {
    useRestaurantContext,
} from './context/restaurant.provider';

import Details from './components/Details';
import ConsumptionTypes from './components/ConsumptionTypes';
import OpenHours from './components/OpenHours';
import DeliverySettings from './components/DeliverySettings';
import DeliveryHours from './components/DeliveryHours';
import DeliveryCosts from './components/DeliveryCosts';
import SkeletonOrder from '@components/SkeletonOrder/SkeletonOrder';
import ButtonSave from './components/ButtonSave';
import { useRestaurantQuery } from '@graphql/generated';
import { useAuthService } from '@/services/AuthService';
import { convertTimestamps } from '@helpers/convertTimestamps';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { s } from '@style/index';

const RestaurantPage = () => {
    const restaurantId = useAuthService((state) => state.restaurantId);
    const { data, loading } = useRestaurantQuery({
        variables: {
            input: { restaurantId: restaurantId },
        },

        onError: graphqlErrorHandler(true),
    });

    const {
        setBackgroundUrl,
        setLogoUrl,
        setOpenningHours,
        setRestaurantInfo,
        setDeliverySettings,
        restaurantInfo,
    } = useRestaurantContext();

    useEffect(() => {
        if (data?.restaurant) {
            const restaurant = data.restaurant;
            setLogoUrl(restaurant.avatarUrl);
            setBackgroundUrl(restaurant.photoUrl);
            setOpenningHours(
                convertTimestamps.fromISO(restaurant.openingHours),
            );

            const {
                name,
                description,
                address,
                location: { coordinates },
                consumptionTypes,
                deliverySettings,
                isActive,
                layoutPdfUrl,
            } = restaurant;

            setDeliverySettings({
                availableHours: convertTimestamps.fromISO(
                    deliverySettings.availableHours,
                ),
                deliveryRadius: deliverySettings.deliveryRadius,
                minAmount: deliverySettings.minAmount,
                freeDelivery: !!deliverySettings.freeDelivery,
                freeDeliveryAmount: deliverySettings.freeDeliveryAmount,
                deliveryCost: deliverySettings?.deliveryCost.map((item) => [
                    item.deliveryRadius,
                    item.amount,
                ]),
            });

            setRestaurantInfo({
                name,
                description,
                address,
                location: { coordinates },
                consumptionTypes,
                isActive,
                layoutPdfUrl,
            });
        }
    }, [data]);

    if (loading)
        return (
            <React.Fragment>
                <div css={sOuter}>
                    <Headline>Restauracja</Headline>
                    <Grid container css={sGrid}>
                        <p css={sDescription}>
                            Modyfikuj szczegóły restauracji, aktywuj kolejne
                            typy zamówień, zarządzaj godzinami otwarcia oraz
                            ustawieniami dostaw.
                        </p>
                    </Grid>
                    <Grid container css={[sGrid, sPaddingTop]}>
                        <SkeletonOrder />
                        <SkeletonOrder />
                    </Grid>
                </div>
            </React.Fragment>
        );

    return (
        <React.Fragment>
            <TopNav title={'Restauracja'} offset={60} />
            <div
                css={
                    (sOuter,
                    {
                        padding: '88px 0 48px',
                        [s.md]: { padding: '144px 0 48px' },
                    })
                }
            >
                <Headline
                    textWrap
                    itemChildren={
                        <ButtonBorder
                            link={`/restaurant/${data?.restaurant?.slug}`}
                        >
                            <VisibilityIcon fontSize="inherit" />
                            &nbsp;&nbsp;Wyświetl
                        </ButtonBorder>
                    }
                >
                    {restaurantInfo.name}
                </Headline>
                <Details />
                <ConsumptionTypes />
                <OpenHours />
                <Collapse
                    in={restaurantInfo.consumptionTypes.includes('DELIVERY')}
                >
                    <DeliverySettings />
                    <DeliveryCosts />
                    <DeliveryHours />
                </Collapse>
            </div>
            <ButtonSave />
        </React.Fragment>
    );
};

export default () => (
    <RestaurantProvider>
        <RestaurantPage />
    </RestaurantProvider>
);
