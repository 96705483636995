import React from 'react';
import { useRoutesEZW } from '../../../routes';
import Layout from '../../components/Layout';
import LoginPage from '../Login/LoginPage';
import { useInitializeWaiter } from '@/EZW/services/WaiterService/useInitializeWaiter';
import { useWaiterService } from '@/EZW/services/WaiterService/useWaiterService';
import { WaiterPollingProvider } from '@/EZW/services/WaiterPollingService/WaiterPollingProvider';
import { useFirebaseChatServiceLogin } from '@/services/FirebaseChatService/useFirebaseChatServiceLogin';

const IndexPage = () => {
    const { loading } = useInitializeWaiter();

    if (loading) return null;

    return <EZWContainer />;
};

const EZWContainer = () => {
    useFirebaseChatServiceLogin(true);

    const { isLoggedIn } = useWaiterService();
    const routes = useRoutesEZW(isLoggedIn);

    return isLoggedIn ? (
        <>
            <Layout>{routes}</Layout>
            <WaiterPollingProvider />
        </>
    ) : (
        <Layout>
            <LoginPage />
        </Layout>
    );
};

export default IndexPage;
