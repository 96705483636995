import {
    useUserAddressBookQuery,
    useUserUpdateAddressMutation,
} from '@graphql/generated';
import { AddressFormSchemaValues } from '../../molecules/AddressForm/AddressForm';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

export const useAddressBookPage = () => {
    const { data, loading } = useUserAddressBookQuery();

    const [updateAddress, { loading: updatedLoading }] =
        useUserUpdateAddressMutation();

    const firstAddress = data?.user?.addresses?.[0];

    useEffect(() => {
        firstAddress &&
            !firstAddress?.isDefault &&
            updateAddress({
                variables: {
                    input: {
                        name: firstAddress?.name,
                        address: firstAddress?.address,
                        additionalInfo: firstAddress?.additionalInfo,
                        coordinates: [
                            firstAddress?.coordinates?.[0] ?? 0,
                            firstAddress?.coordinates?.[1] ?? 0,
                        ],
                        isDefault: true,
                        addressId: firstAddress?.id,
                    },
                },
            });
    }, [firstAddress]);

    const handleSubmit = (values: AddressFormSchemaValues, id?: string) => {
        updateAddress({
            variables: {
                input: {
                    name: values.addressName,
                    address: values.address.address,
                    additionalInfo: values.additionalAddress,
                    coordinates: [values.address.lng, values.address.lat],
                    isDefault: values.defaultAddress,
                    addressId: id,
                },
            },
            onCompleted: () => {
                toast.dark('Adres został zaktualizowany');
            },
        });
    };

    return { data, loading, handleSubmit, updatedLoading };
};
