import { useState, useRef, ChangeEvent } from 'react';
import { useRestaurantContext } from '@/EZR/pages/Restaurant/context/restaurant.provider';
import { useUploadFile } from '@hooks/useUploadFile';
import { getFileType } from '@helpers/getFileType';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { MAX_FILE_SIZE } from '@constants/files';
import { toast } from 'react-toastify';
import {
    useTableUploadRestaurantUpdateMutation,
    useUploadMediaFileLazyQuery,
} from '@graphql/generated';
import { useAuthService } from '@/services/AuthService';

export const useRestaurantTableUpload = (isNewRestaurant: boolean) => {
    const { restaurantInfo, setRestaurantInfo } = useRestaurantContext();
    const restaurantId = useAuthService((state) => state.restaurantId);

    const [file, setFile] = useState<File | undefined>(undefined);
    const [showButtons, setShowButtons] = useState({
        save: false,
        select: true,
    });
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [productFileUpload] = useUploadMediaFileLazyQuery();
    const [restaurantUpdate, { loading }] =
        useTableUploadRestaurantUpdateMutation();

    const { loading: loadingPdfUrl, uploadFile } =
        useUploadFile(productFileUpload);

    const handleFileReset = async () => {
        setFile(undefined);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }

        if (isNewRestaurant) {
            // @ts-expect-error migrate to TS
            setRestaurantInfo({
                ...restaurantInfo,
                layoutPdfUrl: null,
            });
            toast.dark('Układ sali został usunięty');
            setShowButtons({ ...showButtons, select: true });
        } else {
            await restaurantUpdate({
                variables: {
                    input: {
                        restaurantId: restaurantId || '',
                        payload: {
                            layoutPdfUrl: null,
                        },
                    },
                },

                onCompleted: () => {
                    toast.dark('Układ sali został usunięty');
                    setShowButtons({ ...showButtons, select: true });
                },
                onError: (error) => graphqlErrorHandler(true)(error),
            });
        }
    };

    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event?.target?.files?.[0];
        const selectedFileType = getFileType(selectedFile);
        const selectedFileSize = selectedFile?.size;

        if (!selectedFile || !selectedFileSize) return;
        if (selectedFileType !== 'pdf') {
            toast.error(
                'Typ pliku, który próbujesz przesłać, nie jest obsługiwany. Prześlij plik PDF',
            );

            return;
        }

        if (selectedFileSize > MAX_FILE_SIZE) {
            toast.error(
                'Rozmiar pliku, który próbujesz przesłać, przekracza dopuszczalny limit 5 MB',
            );

            return;
        }
        setShowButtons({ ...showButtons, save: true });
        setFile(selectedFile);

        try {
            const url = await uploadFile(selectedFile);

            // @ts-expect-error migrate to TS
            setRestaurantInfo({
                ...restaurantInfo,
                layoutPdfUrl: url,
            });
        } catch (error) {
            setFile(undefined);
            setShowButtons({ ...showButtons, save: false });

            // @ts-expect-error migrate to TS
            setRestaurantInfo({
                ...restaurantInfo,
                layoutPdfUrl: null,
            });

            toast.error('Błąd podczas przesyłania pliku.');
        }
    };

    const handleFileSave = async () => {
        await restaurantUpdate({
            variables: {
                input: {
                    restaurantId: restaurantId || '',
                    payload: {
                        layoutPdfUrl: restaurantInfo.layoutPdfUrl,
                    },
                },
            },

            onCompleted: () => {
                toast.dark('Układ sali został dodany');
                setShowButtons({ ...showButtons, save: false, select: false });
            },
            onError: graphqlErrorHandler(true),
        });
    };

    return {
        file,
        fileInputRef,
        handleFileChange,
        handleFileReset,
        handleFileSave,
        loading: loading || loadingPdfUrl,
        restaurantInfo,
        layoutPdfUrl: restaurantInfo.layoutPdfUrl,
        showButtons,
    };
};
