import React from 'react';
import { BasePopup } from '@/componentsV2/organisms/BasePopup/BasePopup';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { Button } from '@/componentsV2/molecules/Button/Button';
import {
    useAcceptCartItemsQuery,
    useAcceptCreatedCartMutation,
    useRejectCreatedCartMutation,
    CartLineFragment,
} from '@graphql/generated';
import { ProductTile } from '@/componentsV2/molecules/ProductTile/ProductTile';
import { popup } from '@/AppPopups';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import NiceModal from '@ebay/nice-modal-react';
import { toast } from 'react-toastify';
import { DIET } from '@constants/fromGraphql/Diet.constants';
import { useHistory } from 'react-router-dom';

interface AcceptCartPopupInnerProps {
    items: CartLineFragment[];
    onAcceptOrder: () => void;
    onRejectOrder: () => void;
    buttonsLoading: boolean;
}

interface AcceptCartPopupProps {
    cartId: string;
}

export const AcceptCartPopup = NiceModal.create(
    ({ cartId }: AcceptCartPopupProps) => {
        const { data } = useAcceptCartItemsQuery({
            fetchPolicy: 'cache-and-network',
            onError: graphqlErrorHandler(true),
            variables: { input: { cartId } },
        });

        const history = useHistory();

        const [acceptCart, { loading: acceptLoading }] =
            useAcceptCreatedCartMutation({
                variables: {
                    input: {
                        cartId,
                    },
                },
                onCompleted: ({ orderCreate: { id } }) => {
                    toast.success('Zamówienie zostało zaakceptowane');
                    history.push(`/order/${id}`);
                },
                onError: graphqlErrorHandler(true),
            });

        const [rejectCart, { loading: rejectLoading }] =
            useRejectCreatedCartMutation({
                variables: {
                    input: {
                        cartId,
                    },
                },
                onCompleted: () => toast.error('Zamówienie zostało odrzucone'),
                onError: graphqlErrorHandler(true),
            });

        return (
            <AcceptCartPopupInner
                items={data?.cart.lines || []}
                onAcceptOrder={() => {
                    acceptCart();
                    popup.remove('AcceptCartPopup');
                }}
                onRejectOrder={() => {
                    rejectCart();
                    popup.remove('AcceptCartPopup');
                }}
                buttonsLoading={acceptLoading || rejectLoading}
            />
        );
    },
);

export const AcceptCartPopupInner = ({
    items,
    onAcceptOrder,
    onRejectOrder,
    buttonsLoading,
}: AcceptCartPopupInnerProps) => {
    return (
        <BasePopup
            children={
                <div>
                    <Description>
                        Kelner stworzył nowe zamówienie dla Ciebie. Proszę,
                        potwierdź, czy wszystko jest zgodne z Twoimi
                        oczekiwaniami, zanim zamówienie zostanie realizowane.
                    </Description>

                    {items?.map((item) => {
                        const itemsQuantity = item.quantity;

                        return (
                            <>
                                {new Array(itemsQuantity).fill(0).map(() => (
                                    <ItemWrapper>
                                        <ProductTile
                                            name={item?.product.name || ''}
                                            imageUrl={
                                                item?.product.photoUrl || ''
                                            }
                                            productPrice={
                                                item?.product.price || 0
                                            }
                                            ratings={
                                                item?.product.ratingsCount || 0
                                            }
                                            average={item?.product.rating || 0}
                                            categories={
                                                item?.product.diets.map(
                                                    (diet) => DIET[diet].name,
                                                ) || []
                                            }
                                        />
                                    </ItemWrapper>
                                ))}
                            </>
                        );
                    })}
                </div>
            }
            footer={
                <Footer>
                    <Button
                        loading={buttonsLoading}
                        variant={'red'}
                        onClick={onRejectOrder}
                    >
                        Rezygnuję z zamówienia
                    </Button>
                    <Button
                        loading={buttonsLoading}
                        variant={'black'}
                        onClick={onAcceptOrder}
                    >
                        Akceptuję
                    </Button>
                </Footer>
            }
            header={<Header>Zaakceptuj zamówienie</Header>}
            overlayBackground
        />
    );
};

const Header = styled.h2`
    color: ${colors.text};
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
`;

const Description = styled.p`
    color: ${colors.blueZodiac};
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 25px;
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const ItemWrapper = styled.div`
    padding: 5px;
`;
