import Headline from '../../../components/Headline/Headline';
import { TopNav } from '../../../components/TopNavFixed/TopNavFixed';
import { sOuter } from '../../../style/style';
import Details from './components/Details';
import Price from './components/Price';
import ConsumptionTypes from './components/ConsumptionTypes';
import OpenHours from './components/OpenHours';
import ButtonAdd from './components/ButtonAdd';
import { FormProvider } from 'react-hook-form';
import { useProductForm } from './hooks/useProductForm/useProductForm';
import { ProductSpiciness } from './components/ProductSpiciness';
import { s } from '@style/index';

const ProductNewPage = () => {
    const { handleFormSubmit, loading, methods } = useProductForm();

    return (
        <FormProvider {...methods}>
            <div>
                <TopNav title={'Nowy Produkt'} offset={60} />
                <div
                    css={
                        (sOuter,
                        {
                            padding: '88px 0 48px',
                            [s.md]: { padding: '144px 0 48px' },
                        })
                    }
                >
                    <Headline>Nowy Produkt</Headline>
                    <Details />
                    <ProductSpiciness />
                    <Price />
                    <ConsumptionTypes newProduct />
                    <OpenHours />
                </div>
                <ButtonAdd onSubmit={handleFormSubmit} loading={loading} />
            </div>
        </FormProvider>
    );
};

export default () => <ProductNewPage />;
