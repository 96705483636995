import { popup } from '@/AppPopups';
import { SearchFilterSelector } from '@/EZC/domains/Search/molecules/SearchFilterSelector/SearchFilterSelector';
import { YourLocalizationBox } from '@/componentsV2/molecules/YourLocalizationBox/YourLocalizationBox';
import { DistanceBox } from '@/componentsV2/molecules/DistanceBox/DistanceBox';
import styled from '@emotion/styled';
import React from 'react';
import { colors } from '@style/index';
import { css } from '@emotion/react';
import {
    ChipSecondary,
    ChipSecondaryVariant,
} from '@/componentsV2/atoms/ChipSecondary/ChipSecondary';
import { SearchFilterFormSchemaValues } from '@/EZC/domains/Search/SearchFilterFormProvider/SearchFilterFormProvider';
import { Controller, useController, useFormContext } from 'react-hook-form';
import {
    CUISINE_NAME_VALUES,
    CUISINE_NAME,
} from '@constants/fromGraphql/Cuisine.constants';
import {
    DISH_NAME,
    DISH_NAME_VALUES,
} from '@constants/fromGraphql/Dish.constants';
import {
    DIET_NAME,
    DIET_NAME_VALUES,
} from '@constants/fromGraphql/Diet.constants';
import {
    FLAVOUR_NAME,
    FLAVOUR_NAME_VALUES,
} from '@constants/fromGraphql/Flavor.constants';
import {
    COOK_METHOD_NAME,
    COOK_METHOD_NAME_VALUES,
} from '@constants/fromGraphql/CookMethod.constants';
import { PRICE_LEVEL_VALUES } from '@constants/priceLevels';
import { MEAL_TIME_NAME_VALUES } from '@constants/fromGraphql/MealTime.constants';
import { CALORIES_LEVEL_VALUES } from '@constants/caloriesLevels';
import { COOKING_TIME_VALUES } from '@constants/cookingTimeLevels';
import { SPICINESS_LEVEL_VALUES } from '@constants/spicinessLevels';
import { useAuthService } from '@/services/AuthService';
import {
    Ingredient,
    useGetAllIngredientsQuery,
    useGetUserIngredientsQuery,
    useSearchFilterGroupListQuery,
} from '@graphql/generated';
import { SearchFilterIngredientsPopupProps } from '../SearchFilterIngredientsPopup/SearchFilterIngredientsPopup';
import { difference } from 'lodash';

export const SearchFilterPopupFields = () => {
    const isLoggedIn = useAuthService((state) => state.isLoggedIn);
    const { control, resetField, getValues } =
        useFormContext<SearchFilterFormSchemaValues>();

    const ingredients = useGetAllIngredientsQuery();
    const userIngredients = useGetUserIngredientsQuery({
        skip: !isLoggedIn,
    });
    const userPreferencesIngredients = [
        ...(userIngredients.data?.userMe.preferences.ingredients?.preffered ??
            []),
        ...(userIngredients.data?.userMe.preferences.ingredients
            ?.notPreffered ?? []),
    ];
    const parsedIngredients: (Ingredient & {
        disabled?: boolean;
    })[] =
        ingredients.data?.list.map((ingredient) => ({
            ...ingredient,
            disabled: getValues('applyUserPreferences')
                ? userPreferencesIngredients.some(
                      (item) => item.id === ingredient.id,
                  )
                : false,
        })) ?? [];

    const groups = useSearchFilterGroupListQuery({
        skip: !isLoggedIn,
    });

    const { field: distance } = useController({
        name: 'distance',
        control,
    });

    const { field: geolocation } = useController({
        name: 'geolocation',
        control,
    });

    const { field: groupsField } = useController({
        control,
        name: 'groups',
    });

    return (
        <>
            <SearchFilterSelector
                children={
                    <YourLocalizationBox
                        onChange={geolocation.onChange}
                        value={geolocation.value}
                    />
                }
                title={<Title padding>Twoja lokalizacja</Title>}
                contentPadding={'0 0 16px 0'}
                separator
            />
            <SearchFilterSelector
                children={
                    <DistanceBox
                        onChange={distance.onChange}
                        value={distance.value}
                    />
                }
                title={
                    <Title>
                        Odległość
                        <TitleInfo>w km od lokalizacji</TitleInfo>
                    </Title>
                }
                contentPadding={'0 0 16px 0'}
                separator
            />
            <SearchFilterSelector
                loading={groups.loading}
                title={<Title padding>Grupy</Title>}
                reset={() => resetField('groups')}
                contentPadding={'0 0 16px 0'}
                display={!!groups.data?.list?.data.length}
                separator
            >
                <ChipsWrapper>
                    {groups.data?.list.data?.map((item) => {
                        return (
                            <ChipSecondary
                                key={item.id}
                                onClick={() =>
                                    groupsField.onChange(
                                        groupsField.value === item.id
                                            ? null
                                            : item.id,
                                    )
                                }
                                size="default"
                                text={item.name}
                                value={item}
                                variant={
                                    groupsField.value === item.id
                                        ? 'default'
                                        : 'gray'
                                }
                            />
                        );
                    })}
                </ChipsWrapper>
            </SearchFilterSelector>
            <Controller
                control={control}
                name="cuisine"
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: CUISINE_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Kuchnia</Title>}
                        reset={() => resetField('cuisine')}
                        onClick={() => {
                            popup.show('SearchFilterSecondPopup', {
                                searchFilteredList: {
                                    list: CUISINE_NAME_VALUES,
                                    emptyResultText:
                                        'Nie znaleziono pasującej kuchnii',
                                },
                                onSave: (value: string[]) => {
                                    field.onChange(value);

                                    popup.hide('SearchFilterSecondPopup');
                                },
                                title: 'Kuchnia',
                                checked: field.value,
                            });
                        }}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                    />
                )}
            />
            <Controller
                control={control}
                name="dish"
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: DISH_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Danie</Title>}
                        reset={() => resetField('dish')}
                        onClick={() => {
                            popup.show('SearchFilterSecondPopup', {
                                searchFilteredList: {
                                    list: DISH_NAME_VALUES,
                                    emptyResultText:
                                        'Nie znaleziono pasującego dania',
                                },
                                onSave: (value: string[]) => {
                                    field.onChange(value);

                                    popup.hide('SearchFilterSecondPopup');
                                },
                                title: 'Danie',
                                checked: field.value,
                            });
                        }}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                    />
                )}
            />
            <Controller
                control={control}
                name="diet"
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: DIET_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Dieta</Title>}
                        reset={() => resetField('diet')}
                        onClick={() => {
                            popup.show('SearchFilterSecondPopup', {
                                searchFilteredList: {
                                    list: DIET_NAME_VALUES,
                                    emptyResultText:
                                        'Nie znaleziono pasującej diety',
                                },
                                onSave: (value: string[]) => {
                                    field.onChange(value);

                                    popup.hide('SearchFilterSecondPopup');
                                },
                                title: 'Dieta',
                                checked: field.value,
                            });
                        }}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                    />
                )}
            />
            <Controller
                control={control}
                name="flavour"
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: FLAVOUR_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Smak</Title>}
                        reset={() => resetField('flavour')}
                        onClick={() => {
                            popup.show('SearchFilterSecondPopup', {
                                searchFilteredList: {
                                    list: FLAVOUR_NAME_VALUES,
                                    emptyResultText:
                                        'Nie znaleziono pasującego smaku',
                                },
                                onSave: (value: string[]) => {
                                    field.onChange(value);

                                    popup.hide('SearchFilterSecondPopup');
                                },
                                title: 'Smak',
                                checked: field.value,
                            });
                        }}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                    />
                )}
            />
            <Controller
                control={control}
                name="cookMethod"
                render={({ field }) => (
                    <SearchFilterSelector
                        selected={field.value.map((id) => ({
                            id,
                            name: COOK_METHOD_NAME[id]!,
                        }))}
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Metoda gotowania</Title>}
                        reset={() => resetField('cookMethod')}
                        onClick={() => {
                            popup.show('SearchFilterSecondPopup', {
                                searchFilteredList: {
                                    list: COOK_METHOD_NAME_VALUES,
                                    emptyResultText:
                                        'Nie znaleziono pasującej metody gotowania',
                                },
                                onSave: (value: string[]) => {
                                    field.onChange(value);

                                    popup.hide('SearchFilterSecondPopup');
                                },
                                title: 'Metoda gotowania',
                                checked: field.value,
                            });
                        }}
                        contentPadding={field.value.length ? '0 0 16px 0' : '0'}
                        separator
                    />
                )}
            />
            <FilterSelectorIngredientsBased
                emptyResultText="Nie znaleziono pasującego składnika"
                ingredients={parsedIngredients ?? []}
                loading={ingredients.loading}
                name="ingredients"
                title="Składniki"
            />
            <FilterSelectorIngredientsBased
                emptyResultText="Nie znaleziono pasującego składnika"
                ingredients={parsedIngredients ?? []}
                loading={ingredients.loading}
                name="allergy"
                title="Alergie"
            />
            <FilterSelectorIngredientsBased
                emptyResultText="Nie znaleziono pasującego składnika"
                ingredients={parsedIngredients ?? []}
                loading={ingredients.loading}
                name="notPreffered"
                title="Niechciane składniki"
            />

            <Controller
                control={control}
                name="price"
                render={({ field }) => (
                    <SearchFilterSelector
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={
                            <Title>
                                Cena
                                <TitleInfo>w zł</TitleInfo>
                            </Title>
                        }
                        reset={() => resetField('price')}
                        contentPadding={'0 0 16px 0'}
                        separator
                    >
                        <ChipsWrapper>
                            {PRICE_LEVEL_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value === item.id
                                                ? null
                                                : item.id,
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value === item.id
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />
            <Controller
                control={control}
                name="mealTime"
                render={({ field }) => (
                    <SearchFilterSelector
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Pora dnia</Title>}
                        reset={() => resetField('mealTime')}
                        contentPadding={'0 0 16px 0'}
                        separator
                    >
                        <ChipsWrapper>
                            {MEAL_TIME_NAME_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value.includes(item.id)
                                                ? field.value.filter(
                                                      (current) =>
                                                          current !== item.id,
                                                  )
                                                : [...field.value, item.id],
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value.includes(item.id)
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />
            <Controller
                control={control}
                name="calories"
                render={({ field }) => (
                    <SearchFilterSelector
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={
                            <Title>
                                Kaloryczność
                                <TitleInfo>w kcal</TitleInfo>
                            </Title>
                        }
                        reset={() => resetField('calories')}
                        contentPadding={'0 0 16px 0'}
                        separator
                    >
                        <ChipsWrapper>
                            {CALORIES_LEVEL_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value === item.id
                                                ? null
                                                : item.id,
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value === item.id
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />
            <Controller
                control={control}
                name="cookingTime"
                render={({ field }) => (
                    <SearchFilterSelector
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Czas przygotowania</Title>}
                        reset={() => resetField('cookingTime')}
                        contentPadding={'0 0 16px 0'}
                        separator
                    >
                        <ChipsWrapper>
                            {COOKING_TIME_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() => field.onChange(item.id)}
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value === item.id
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />
            <Controller
                control={control}
                name="spiciness"
                render={({ field }) => (
                    <SearchFilterSelector
                        onChange={(selected) =>
                            field.onChange(selected.map((select) => select.id))
                        }
                        title={<Title>Skala ostrości</Title>}
                        reset={() => resetField('spiciness')}
                        contentPadding={'0 0 16px 0'}
                        separator
                    >
                        <ChipsWrapper>
                            {SPICINESS_LEVEL_VALUES?.map((item) => (
                                <ChipSecondary
                                    key={item.id}
                                    onClick={() =>
                                        field.onChange(
                                            field.value.includes(item.id)
                                                ? field.value.filter(
                                                      (current) =>
                                                          current !== item.id,
                                                  )
                                                : [...field.value, item.id],
                                        )
                                    }
                                    size="default"
                                    text={item.name}
                                    value={item}
                                    variant={
                                        field.value.includes(item.id)
                                            ? 'default'
                                            : 'gray'
                                    }
                                />
                            ))}
                        </ChipsWrapper>
                    </SearchFilterSelector>
                )}
            />
        </>
    );
};

const FilterSelectorIngredientsBased = ({
    name,
    title,
    loading,
    emptyResultText,
    ingredients,
}: {
    name: Extract<
        keyof SearchFilterFormSchemaValues,
        'allergy' | 'ingredients' | 'notPreffered'
    >;
    title: string;
    loading: boolean;
    emptyResultText: string;
    ingredients: (Ingredient & {
        disabled?: boolean;
    })[];
}) => {
    const { control, resetField, setValue, getValues } =
        useFormContext<SearchFilterFormSchemaValues>();
    const { field } = useController({
        name,
        control,
    });

    const chipsVariant: { [key: string]: ChipSecondaryVariant } = {
        allergy: 'red',
        notPreffered: 'yellow',
        ingredients: 'default',
    };

    return (
        <SearchFilterSelector
            selected={field.value.map((id) => ({
                id,
                name:
                    ingredients.find((ingredient) => ingredient.id === id)
                        ?.name ?? '',
                variant: chipsVariant[name],
            }))}
            onChange={(selected) =>
                field.onChange(selected.map((select) => select.id))
            }
            title={<Title>{title}</Title>}
            reset={() => resetField(name)}
            onClick={() => {
                popup.show('SearchFilterIngredientsPopup', {
                    listAlphabetically: {
                        emptyResultText,
                        ingredients,
                        loading,
                    },
                    onSave: (value: string[]) => {
                        field.onChange(value);

                        field.name !== 'allergy' &&
                            setValue(
                                'allergy',
                                difference(getValues('allergy'), value),
                            );
                        field.name !== 'ingredients' &&
                            setValue(
                                'ingredients',
                                difference(getValues('ingredients'), value),
                            );
                        field.name !== 'notPreffered' &&
                            setValue(
                                'notPreffered',
                                difference(getValues('notPreffered'), value),
                            );

                        popup.hide('SearchFilterIngredientsPopup');
                    },
                    title,
                    defaultChecked: field.value,
                } as SearchFilterIngredientsPopupProps);
            }}
            contentPadding={field.value.length ? '0 0 16px 0' : '0'}
            separator
        />
    );
};

export const Title = styled.p<{ padding?: boolean }>(
    () => css`
        align-items: baseline;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        gap: 2px;
    `,
);

const ChipsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;

export const TitleInfo = styled.span`
    font-size: 11px;
    font-weight: 400;
    color: ${colors.gray600};
`;
