import * as Sentry from '@sentry/capacitor';

import * as SentrySibling from '@sentry/react';
import { resolveEnv } from '@helpers/resolveEnv';
import { DENY_URLS, IGNORE_ERRORS } from '@constants/sentry';
import { Route } from 'react-router-dom';
import { history } from '..';
import { useEffect } from 'react';
import { AuthService, useAuthService } from './AuthService';
import {
    WaiterService,
    useWaiterService,
} from '@/EZW/services/WaiterService/useWaiterService';
import { Replay } from '@sentry/replay';
import { GraphQLErrors } from '@apollo/client/errors';
import { Operation } from '@apollo/client';
import { GraphqlErrorCode } from '@constants/errors/graphqlErrorMessages';

interface SentryServiceOptions {
    dsn: string;
    environment: string;
    normalizeDepth: string;
    projectName: string;
    replaysOnErrorSampleRate: string;
    replaysSessionSampleRate: string;
    tracesSampleRate: string;
    tracingOrigin: string;
    version: string;
}

const UNEXPECTED_GRAPHQL_ERRORS: GraphqlErrorCode[] = ['BAD_USER_INPUT'];

class SentryService {
    static _instance: SentryService | null = null;
    private options: SentryServiceOptions | null = null;
    private isInitialized = false;

    constructor(options: SentryServiceOptions) {
        if (!SentryService._instance) {
            this.options = options;
            SentryService._instance = this;
        }

        return SentryService._instance;
    }

    init = () => {
        if (!this.isInitialized && this.options?.dsn) {
            const {
                dsn,
                environment,
                normalizeDepth,
                projectName,
                replaysOnErrorSampleRate,
                replaysSessionSampleRate,
                tracesSampleRate,
                tracingOrigin,
                version,
            } = this.options;

            Sentry.init(
                {
                    denyUrls: DENY_URLS,
                    dsn,
                    environment: environment || 'dev',
                    ignoreErrors: IGNORE_ERRORS,
                    replaysSessionSampleRate: parseFloat(
                        replaysSessionSampleRate || '0',
                    ),
                    replaysOnErrorSampleRate: parseFloat(
                        replaysOnErrorSampleRate || '1.0',
                    ),
                    integrations: [
                        {
                            name: 'Replay',
                            setupOnce: () =>
                                new Replay({
                                    blockAllMedia: true,
                                    maskAllText: true,
                                }),
                        },
                        {
                            name: 'BrowserTracing',
                            setupOnce: () =>
                                new SentrySibling.BrowserTracing({
                                    routingInstrumentation:
                                        SentrySibling.reactRouterV5Instrumentation(
                                            history,
                                        ),
                                    tracePropagationTargets: [tracingOrigin],
                                }),
                        },
                    ],
                    normalizeDepth: normalizeDepth
                        ? parseInt(normalizeDepth)
                        : 8,
                    release: `${projectName}@${version || 'dev'}`,
                    tracesSampleRate: parseFloat(tracesSampleRate || '1.0'),
                },
                SentrySibling.init,
            );

            this.isInitialized = true;
        }
    };

    setUser = async () => {
        const authUser = AuthService().user;
        const authWaiter = WaiterService().user;
        Sentry.setUser({
            standardUser: authUser,
            waiterUser: authWaiter,
        });

        authUser && Sentry.setContext('sessionUser', authUser);
        authWaiter && Sentry.setContext('sessionWaiter', authWaiter);
    };

    captureMessage = (...args: Parameters<typeof Sentry.captureMessage>) =>
        Sentry.captureMessage(...args);

    captureException = (...args: Parameters<typeof Sentry.captureException>) =>
        Sentry.captureException(...args);

    captureGraphQlErrors = (errors: GraphQLErrors, operation: Operation) => {
        const operationName = operation.operationName;

        errors.map((error) => {
            const code = error?.extensions?.code as GraphqlErrorCode;
            const filteredError = UNEXPECTED_GRAPHQL_ERRORS.includes(code);

            filteredError &&
                Sentry.captureException(
                    new Error(`${operationName}: ${error.message}`),
                    {
                        extra: {
                            error,
                            operation: {
                                operationName,
                            },
                        },
                        level: 'error',
                    },
                );
        });
    };

    clearScope = () => Sentry.configureScope((scope) => scope.clear());
}

export const sentry = new SentryService({
    dsn: resolveEnv('REACT_APP_SENTRY_DSN'),
    environment: resolveEnv('REACT_APP_SENTRY_ENVIRONMENT'),
    normalizeDepth: resolveEnv('REACT_APP_SENTRY_NORMALIZATION_DEPTH'),
    projectName:
        resolveEnv('REACT_APP_SENTRY_PROJECT_NAME') || 'eatzon-frontend',
    replaysOnErrorSampleRate: resolveEnv(
        'REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE',
    ),
    replaysSessionSampleRate: resolveEnv(
        'REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE',
    ),
    tracesSampleRate: resolveEnv('REACT_APP_SENTRY_TRACES_SAMPLE_RATE'),
    tracingOrigin: resolveEnv('REACT_APP_SERVER_URI'),
    version: resolveEnv('REACT_APP_SENTRY_VERSION'),
});

export const SentryRoute = SentrySibling.withSentryRouting(Route);

export const useSentryInitialize = () => {
    const { user: sessionTokenUser } = useAuthService((state) => state);
    const { user: sessionTokenWaiter } = useWaiterService((state) => state);

    useEffect(() => {
        sentry.init();
        sentry.setUser();
    }, [sessionTokenUser, sessionTokenWaiter]);
};
