import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@/style';
import { ButtonBorder } from '@/componentsV2/molecules/ButtonBorder/ButtonBorder';
import { Accordion } from '@/componentsV2/molecules/Accordion/Accordion';
import { css } from '@emotion/core';
import { PayoffStatus } from '@graphql/generated';
import { dateFormat } from '@helpers/dateFormat';
import { Badge } from '@components/Elements';

interface SplitOrderRequestProps {
    loading: boolean;
    order: {
        badge?: boolean;
        date: string;
        imageUrl?: string;
        name: string;
        personRequest: string;
        price: string;
    };
    products: {
        id: string;
        imageUrl: string;
        name: string;
        paymentId: string;
        price: string;
        status: PayoffStatus | null;
    }[];
    paymentLoading?: boolean;
    onCancel: (paymentId: string) => void;
    onSettle: (paymentId: string, price: string) => void;
}

export const SplitOrderRequest = ({
    onCancel,
    onSettle,
    order,
    paymentLoading,
    products,
}: SplitOrderRequestProps) => {
    return (
        <Accordion
            headerElement={
                <SplitOrderRequestHeader>
                    <ImageWrapper>
                        <OrderImage imageUrl={order.imageUrl} />
                        {/* @ts-expect-error migrate to ts */}
                        <Badge
                            color={colors.orange}
                            show={order.badge}
                            showAnimation
                            small
                        />
                    </ImageWrapper>
                    <OrderDetailsWrapper>
                        <OrderName>Zamówienie {order.name}</OrderName>
                        <OrderDetails>
                            <span>{dateFormat.standardDate(order.date)}</span>
                            <span>•</span>
                            <span>{order.price} zł</span>
                        </OrderDetails>
                    </OrderDetailsWrapper>
                </SplitOrderRequestHeader>
            }
        >
            <SplitOrderRequestContent>
                <OrderPersonRequest>
                    <strong>{order.personRequest}</strong> poprosił(a) o
                    płatność:
                </OrderPersonRequest>
                <OrderProductList>
                    {products.map((product, index) => (
                        <OrderProductListItem key={product.id + index}>
                            {product.status === null ? (
                                <OrderProductDetails>
                                    <OrderProductDetailsInner>
                                        {product.imageUrl && (
                                            <OrderImage
                                                imageUrl={product.imageUrl}
                                            />
                                        )}
                                        <OrderName>{product.name}</OrderName>
                                    </OrderProductDetailsInner>
                                    <OrderProductPrice>
                                        {product.price} <span>zł</span>
                                    </OrderProductPrice>
                                </OrderProductDetails>
                            ) : (
                                <OrderProductDetails>
                                    <OrderProductDetailsInner>
                                        {product.imageUrl && (
                                            <OrderImage
                                                imageUrl={product.imageUrl}
                                            />
                                        )}
                                        <OrderName>{product.name}</OrderName>
                                        <OrderProductPrice>
                                            {product.price} <span>zł</span>
                                        </OrderProductPrice>
                                    </OrderProductDetailsInner>
                                    {product.status === 'ACCEPTED' && (
                                        <PaidStatus>Rozliczone</PaidStatus>
                                    )}
                                    {product.status === 'REJECTED' && (
                                        <RejectedStatus>
                                            Odrzucone
                                        </RejectedStatus>
                                    )}
                                </OrderProductDetails>
                            )}
                            {product.status === 'PENDING' && (
                                <ButtonsWrapper>
                                    <ButtonBorder
                                        loading={paymentLoading}
                                        onClick={() =>
                                            onSettle(
                                                product.paymentId,
                                                product.price,
                                            )
                                        }
                                        variant={'green'}
                                    >
                                        Rozlicz
                                    </ButtonBorder>
                                    <ButtonBorder
                                        loading={paymentLoading}
                                        onClick={() =>
                                            onCancel(product.paymentId)
                                        }
                                        variant={'red'}
                                    >
                                        Odrzuć
                                    </ButtonBorder>
                                </ButtonsWrapper>
                            )}
                        </OrderProductListItem>
                    ))}
                </OrderProductList>
            </SplitOrderRequestContent>
        </Accordion>
    );
};

const SplitOrderRequestHeader = styled.div`
    align-items: center;
    display: flex;
    gap: 0 16px;
`;

const OrderImage = styled.div<{ imageUrl?: string }>(
    ({ imageUrl }) => css`
        background-image: url(${imageUrl});
        background-color: ${colors.gray600};
        background-position: center center;
        background-size: contain;
        border-radius: 50%;
        height: 38px;
        overflow: hidden;
        width: 38px;
    `,
);

const ImageWrapper = styled.div`
    position: relative;
`;

const OrderDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const OrderName = styled.h3`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: ${colors.text};
    display: -webkit-box;
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    padding-right: 32px;
`;

const OrderDetails = styled.div`
    align-items: center;
    color: ${colors.blueZodiac};
    display: flex;
    font-size: 12px;
    gap: 4px;
`;

// COLLAPSED CONTENT
const SplitOrderRequestContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const OrderPersonRequest = styled.div`
    border-bottom: 1px solid ${colors.border};
    font-size: 13px;
    margin: 0 -24px;
    padding: 12px 24px 24px;
`;

const OrderProductList = styled.div``;

const OrderProductListItem = styled.div`
    border-bottom: 1px solid ${colors.border};
    display: flex;
    flex-direction: column;
    padding: 24px 0;

    &:last-child {
        border-bottom: none;
    }
`;

const OrderProductDetails = styled.div`
    display: grid;
    gap: 2px;
    grid-template-rows: auto auto;
    justify-items: end;
`;

const OrderProductDetailsInner = styled.div`
    align-items: center;
    display: grid;
    gap: 0 16px;
    grid-template-columns: auto 1fr auto;
    width: 100%;
`;

const OrderProductPrice = styled.div`
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;

    span {
        color: ${colors.gray600};
    }
`;

const PaidStatus = styled.div`
    background: ${colors.green};
    border-radius: 16px;
    color: ${colors.white};
    font-size: 11px;
    font-weight: 700;
    padding: 2px 12px;
    text-transform: uppercase;
`;

const RejectedStatus = styled.div`
    background: ${colors.red};
    border-radius: 16px;
    color: ${colors.white};
    font-size: 11px;
    font-weight: 700;
    padding: 2px 12px;
    text-transform: uppercase;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: 16px;
`;
