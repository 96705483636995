import React from 'react';
import Rating, { RatingProps } from '@material-ui/lab/Rating';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { colors } from '@/style/config/colors.js';

export interface StarsRatingProps extends Pick<RatingProps, 'onChange'> {
    disabled?: boolean;
    iconSize?: number;
    maxStars?: number;
    precision?: number;
    readOnly?: boolean;
    value: number;
}

export const StarsRating = ({
    disabled,
    iconSize = 34,
    maxStars = 5,
    onChange,
    precision = 1,
    readOnly,
    value = 0,
}: StarsRatingProps) => {
    return (
        <RatingStarsElement
            disabled={disabled}
            iconSize={iconSize}
            max={maxStars}
            onChange={onChange}
            precision={precision}
            readOnly={readOnly}
            value={value}
        />
    );
};

const RatingStarsElement = styled(Rating)<{
    iconSize: number;
}>(
    ({ iconSize }) => css`
        color: ${colors.yellowStar};
        font-size: ${iconSize}px;

        &:hover,
        &:focus {
            color: ${colors.yellowStar};
        }

        .MuiRating-label {
            padding: 0 6px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-left: 0;
            }
        }

        .MuiRating-iconEmpty {
            color: ${colors.emptyStar};
        }
    `,
);
