import React, { forwardRef } from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { colors } from '@style/index';
import styled from '@emotion/styled';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { css } from '@emotion/react';

type InputTextNewVariant = 'default' | 'dark';

export const InputTextNew = forwardRef<
    HTMLInputElement,
    TextFieldProps & {
        errorMessage?: string;
        inputVariant?: InputTextNewVariant;
    }
>(({ errorMessage, inputVariant = 'default', ...props }, ref) => (
    <TextFieldElement
        {...props}
        inputRef={ref}
        showError={!!errorMessage}
        inputVariant={inputVariant}
        helperText={
            errorMessage && (
                <HelperText>
                    <ErrorOutlineIcon />
                    {errorMessage}
                </HelperText>
            )
        }
    />
));

const TextFieldElement = styled(MuiTextField)<{
    showError: boolean;
    inputVariant?: InputTextNewVariant;
}>(
    ({ showError, inputVariant }) => css`
        position: relative;
        background-color: ${inputVariant === 'dark'
            ? colors.littleDipper
            : colors.gray200};
        border-bottom: none;
        border-radius: 16px;
        margin-bottom: ${showError ? '26px' : '0'};
        padding: 16px 24px;
        width: 100%;
        height: 62px;
        z-index: 1;
        border: ${showError ? `1px solid ${colors.red}` : `unset`};

        & .MuiInputBase-input {
            color: ${colors.text};
        }

        & label {
            color: ${colors.gray};
            font-weight: 700;
            padding: 0 24px;
            font-size: 14px;
        }

        & label + .MuiInput-formControl {
            margin-top: 22px;
        }

        &:hover {
            background-color: ${colors.gray300};
        }

        &.MuiTextField-root {
            display: flex;
            justify-content: center;
        }

        &.MuiFormControl-root .MuiInput-underline:after,
        &.MuiFormControl-root .MuiInput-underline:before {
            border-bottom: none;
        }

        &.MuiFormControl-root .MuiInputLabel-shrink {
            color: ${colors.gray};
            letter-spacing: 1.4px;
            padding: 12px 32px;
            white-space: nowrap;
        }

        &.MuiFormControl-root .MuiInputBase-input {
            font-weight: 700;
            font-size: 14px;
            padding: 0;
        }

        & .Mui-error {
            color: ${colors.gray};
        }
    `,
);

const HelperText = styled.p`
    align-items: center;
    bottom: -30px;
    color: ${colors.red};
    display: flex;
    font-size: 12px;
    gap: 8px;
    left: 0;
    padding: 0;
    position: absolute;
    z-index: 0;
`;
