import {
    UserOrderGroupListDocument,
    useUserCreateGroupMutation,
    useUserSendInviteMutation,
} from '@graphql/generated';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { graphqlErrorHandler } from '@/services/GraphqlErrorService';
import { useAuthService } from '@/services/AuthService';
import { useGroupService } from '@/services/GroupService';
import { useGeolocationContext } from '@/EZC/context/Geolocation/Geolocation.provider';
import { useCreateGroupCartUpdateMutation } from '@graphql/generated';

export interface UserFriendInfo {
    avatar?: string | null;
    email: string;
    id: string;
    name: string;
    isOwner?: boolean;
}

export const useNewGroupPage = () => {
    const { user } = useAuthService();
    const { redirectToCart, fromCart } = useGroupService();
    const [open, setOpen] = useState(false);
    const [friends, setFriends] = useState<UserFriendInfo[]>([]);

    const { control, handleSubmit } = useForm<NewGroupSchemaValues>({
        resolver: yupResolver(NewGroupSchema),
        defaultValues: {
            groupName: '',
        },
    });

    const history = useHistory();
    const { geolocation } = useGeolocationContext();

    const [createGroup, { loading }] = useUserCreateGroupMutation();
    const [cartUpdate, { data: cartData }] = useCreateGroupCartUpdateMutation({
        onError: graphqlErrorHandler(true),
    });

    const [invite, { loading: inviteLoading }] = useUserSendInviteMutation({
        onError: graphqlErrorHandler(true),
    });

    const handleSendInvitation = (email: string) => {
        invite({
            variables: { input: { emailTo: email } },
            onCompleted: () => {
                toast.dark(
                    'Zaproszenie zostało wysłane. Po rejestracji znajomego dodaj go do grupy ponownie',
                );
                setOpen(false);
            },
        });
    };

    const handleFormSubmit = handleSubmit(
        (values) => {
            if (!friends.length) {
                toast.error(
                    'Grupa nie została dodana. Dodaj co najmniej jedną osobę do grupy',
                );
                return;
            }
            createGroup({
                variables: {
                    input: {
                        name: values.groupName,
                        members: friends.map((friend) => friend.id),
                    },
                },
                onCompleted: async (data) => {
                    toast.dark('Grupa została dodana');

                    if (fromCart) {
                        try {
                            await cartUpdate({
                                variables: {
                                    input: {
                                        cartId: fromCart,
                                        payload: {
                                            groupId: data.group.id,
                                            // TODO it will be removed in new api
                                            deliveryLocation: {
                                                coordinates: [
                                                    cartData?.cartUpdate
                                                        ?.deliveryLocation
                                                        ?.coordinates[0] ||
                                                        geolocation?.lng,
                                                    cartData?.cartUpdate
                                                        ?.deliveryLocation
                                                        ?.coordinates[1] ||
                                                        geolocation?.lat,
                                                ],
                                            },
                                        },
                                    },
                                },
                                refetchQueries: [
                                    {
                                        query: UserOrderGroupListDocument,
                                    },
                                ],
                            });
                        } catch (error) {
                            graphqlErrorHandler(true);
                        }
                        redirectToCart();
                    } else {
                        history.push('/account/my-groups');
                    }
                },
                onError: graphqlErrorHandler(true),
            });
        },
        (errors) => {
            Object.entries(errors).map(([, error]) => {
                toast.error(error.message);
            });
        },
    );

    const handleAddToGroup = (data: UserFriendInfo) => {
        const isAdded = friends.some((friend) => friend.id === data.id);
        if (isAdded) {
            toast.error('Ta osoba jest już dodana do wybranej grupy');
            return;
        }
        if (user?.userId === data.id) {
            toast.error('Nie możesz dołączyć do własnej grupy');
            return;
        }
        setOpen(false);
        setFriends([...friends, data]);
    };

    return {
        control,
        friends,
        handleAddToGroup,
        handleFormSubmit,
        handleSubmit,
        inviteLoading,
        loading,
        handleSendInvitation,
        open,
        setOpen,
    };
};

const NewGroupSchema = yup.object({
    groupName: yup
        .string()
        .max(50, 'Nazwa grupy jest zbyt długa (maks. 50 znaków)')
        .required('Podaj nazwę grupy'),
});
export interface NewGroupSchemaValues
    extends yup.InferType<typeof NewGroupSchema> {}
