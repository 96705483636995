export const xs = '@media screen and (max-width: 599px)';
export const ss = '@media screen and (max-width: 360px)';
export const sss = '@media screen and (max-width: 359px)';
export const sm = '@media screen and (min-width: 600px)';
export const sm_only =
    '@media screen and (min-width: 600px) and (max-width: 843px)';
export const sm_down = '@media screen and (max-width: 843px)';
export const md = '@media screen and (min-width: 844px)';
export const md_only =
    '@media screen and (min-width: 844px) and (max-width: 1279px)';
export const md_down = '@media screen and (max-width: 1279px)';
export const lg = '@media screen and (min-width: 1280px)';
export const lg_1280 =
    '@media screen and (min-width: 1280px) and (max-width: 1344px)';
export const lg_only =
    '@media screen and (min-width: 1280px) and (max-width: 1919px)';
export const xl = '@media screen and (min-width: 1920px)';
export const uhd = '@media screen and (min-width: 2160px)';
export const hover = '@media (hover:hover) and (pointer: fine)';
export const touch = '@media (hover: none)';
export const xlg = '@media screen and (max-width: 1600px)';
export const orders_list_only =
    '@media screen and (min-width: 1345px) and (max-width: 1404px)';

export const s = {
    xs,
    ss,
    sss,
    sm,
    sm_down,
    sm_only,
    md,
    md_down,
    md_only,
    lg,
    xlg,
    lg_only,
    lg_1280,
    xl,
    orders_list_only,
    uhd,
    touch,
    hover,
};
