export type CaloriesLevel = '500' | '1000' | '1500' | '2000' | '2000+';

export const CALORIES_LEVEL_ENUM: Record<CaloriesLevel, CaloriesLevel> = {
    500: '500',
    1000: '1000',
    1500: '1500',
    2000: '2000',
    '2000+': '2000+',
};

export const CALORIES_LEVEL: Record<CaloriesLevel, { name: string }> = {
    500: { name: 'do 500 kcal' },
    1000: { name: 'do 1000 kcal' },
    1500: { name: 'do 1500 kcal' },
    2000: { name: 'do 2000 kcal' },
    '2000+': { name: 'powyżej 2000 kcal' },
};

export const CALORIES_LEVEL_KEYS = Object.keys(
    CALORIES_LEVEL_ENUM,
) as CaloriesLevel[];

export const CALORIES_LEVEL_VALUES = Object.entries(CALORIES_LEVEL).map(
    ([id, value]) => ({
        id,
        ...value,
    }),
) as {
    id: CaloriesLevel;
    name: string;
}[];

/**
 * @deprecated
 */
export const CALORIES_LEVELS = [
    { value: 500, label: '500 kcal' },
    { value: 1000, label: '1000 kcal' },
    { value: 1500, label: '1500 kcal' },
    { value: 2000, label: '2000 kcal' },
    { value: 2500, label: '' },
];
